import { ColorStyle, shapesShared } from '@gamma-app/tldraw'

const important = (value: string | number) => `${value} !important`

export const getTextStyles = (font: string) => {
  return {
    fontFamily: important(font),
  }
}

export const getShapeStyles = (isDark: boolean | undefined) => {
  const styles = {
    // Image styles
    '[data-shape=image]': {
      '.tl-inner-div': {},
    },

    img: {
      // Uploading
      '&[src^="blob:"]': {
        animation: 'loading-pulse-animation 1.5s infinite',
      },
    },
  }
  const colors = Object.values(ColorStyle)
  const theme = isDark ? 'dark' : 'light'
  colors.forEach((color) => {
    styles[`[data-color="${color}"]`] = {
      // Text colors in editor
      color: important(shapesShared.strokes[theme][color]),

      ':not(mask) > [fill], &[fill]': {
        '&:not([fill=none])': {
          // Shape fills
          fill: important(shapesShared.fills[theme][color]),
          // Text colors in SVGs
          'text, tspan': {
            fill: important(shapesShared.strokes[theme][color]),
          },
        },
      },
      // Stroke colors
      '[stroke]': {
        '&:not([stroke=none])': {
          stroke: important(shapesShared.strokes[theme][color]),
        },
      },
    }
  })
  return styles
}
