import { Checkbox, Text, VStack } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'

const WebpagePanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const { proxy, displayStyle } = currentAttributes
  const url = getMediaSourceUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url || ''}
        updateAttributes={updateAttributes}
        placeholder="Enter a URL here"
      />
      {url && displayStyle && (
        <>
          <Checkbox
            isChecked={proxy}
            onChange={(event) =>
              updateAttributes({
                proxy: event.target.checked,
              })
            }
          >
            Load through a proxy
          </Checkbox>
          <Text fontSize="sm" color="gray.500" mt={-2}>
            Tip: try this setting if pages aren't loading. It works best with
            static web pages, not interactive apps.
          </Text>
        </>
      )}
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const WebpageProvider: MediaProviderType = {
  label: 'Webpage or app',
  description: 'Embed interactive apps and websites',
  key: 'embed.custom',
  icon: duotone('browser'),
  Panel: WebpagePanel,
  keywords: ['webpage', 'website', 'app', 'embed', 'iframe'],
}
