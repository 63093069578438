import {
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor } from '@tiptap/core'
import { NodeSelection, TextSelection } from 'prosemirror-state'
import { useCallback } from 'react'

import { isFootnoteEditor } from 'modules/tiptap_editor/extensions/Footnote/utils'
import {
  addImageComment,
  isNodeInGallery,
} from 'modules/tiptap_editor/extensions/media/Gallery'
import { MediaEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { getEmbedProvider } from '../../../extensions/media/Embed'
import { useMediaZoom } from '../../../extensions/media/Zoomable'
import { useToggleMediaDrawer } from '../../drawers/MediaDrawer/MediaDrawer'
import { ToolbarButton } from '../ToolbarButton'

type EmbedVideoFormattingMenuProps = {
  editor: Editor
  selection: NodeSelection
}

export const EmbedVideoFormattingMenu = ({
  editor,
  selection,
}: EmbedVideoFormattingMenuProps) => {
  const toggleMediaDrawer = useToggleMediaDrawer()
  const editNode = useCallback(
    (ev) => {
      toggleMediaDrawer(true)
      ev.stopPropagation()
    },
    [toggleMediaDrawer]
  )
  const inFootnote = isFootnoteEditor(editor)
  const inGallery = isNodeInGallery(selection.$from)
  const addComment = useCallback(() => {
    addImageComment(selection)
  }, [selection])
  const { enterZoom } = useMediaZoom(selection.node.attrs.id)

  return (
    <ButtonGroup spacing={0} size="sm">
      {!inGallery && (
        <EmbedDisplayDropdown editor={editor} selection={selection} />
      )}
      <ToolbarButton
        label={'Zoom'}
        icon={regular('magnifying-glass-plus')}
        onClick={enterZoom}
      />
      {!inFootnote && (
        <ToolbarButton
          label={'Add comment'}
          icon={regular('comment')}
          onClick={addComment}
        />
      )}
      <ToolbarButton
        label={'Edit'}
        icon={regular('pencil')}
        onClick={editNode}
      />
    </ButtonGroup>
  )
}

const OPTIONS = {
  link: { title: 'Link' },
  preview: { title: 'Preview' },
  inline: { title: 'Inline' },
} as const

type EmbedDisplayDropdownProps = {
  editor: Editor
  selection: TextSelection | NodeSelection
}

export const EmbedDisplayDropdown = ({
  editor,
  selection,
}: EmbedDisplayDropdownProps) => {
  const nodeOrMarkName =
    selection instanceof TextSelection ? 'link' : selection.node.type.name
  const isLink = nodeOrMarkName === 'link'
  const attrs = editor.getAttributes(nodeOrMarkName) as Partial<MediaEmbedAttrs>
  const { embed, source } = attrs
  const provider = getEmbedProvider(source || undefined)
  const useHtmlEmbed = !isLink && provider.preferHtml && embed?.html

  const onChange = useCallback(
    (newStyle: keyof typeof OPTIONS) => {
      if (nodeOrMarkName === 'link' && newStyle !== 'link') {
        editor.commands.convertLinkToMedia(newStyle)
      } else if (newStyle === 'link') {
        editor.commands.convertMediaToLink()
        return
      }
      editor.commands.updateAttributes(nodeOrMarkName, {
        displayStyle: newStyle,
      })
    },
    [editor, nodeOrMarkName]
  )

  if (useHtmlEmbed) return null

  const currentDisplayStyle = isLink ? 'link' : attrs.displayStyle || 'inline'

  return (
    <Menu closeOnSelect={true} isLazy autoSelect={false}>
      <MenuButton
        size="sm"
        borderRadius="full"
        as={Button}
        rightIcon={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            transform="shrink-6"
          />
        }
        variant="toolbar"
        pl={2}
        onMouseDown={preventDefaultToAvoidBlur}
        data-testid="display-style-dropdown"
      >
        {OPTIONS[currentDisplayStyle].title}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          type="radio"
          onChange={onChange}
          value={currentDisplayStyle}
        >
          {Object.entries(OPTIONS).map(([key, { title }]) => {
            return (
              <MenuItemOption
                value={key}
                key={key}
                onMouseDown={preventDefaultToAvoidBlur}
                data-testid={`display-style-option-${key}`}
              >
                <Text>{title}</Text>
              </MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
