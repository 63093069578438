/**
 * Utility to slugify a human readable string (make it url safe)
 * From https://gist.github.com/max10rogerio/c67c5d2d7a3ce714c4bc0c114a3ddc6e
 */

// ref: https://gist.github.com/codeguy/6684588#gistcomment-3426313

const MAX_SLUG_LENGTH = 65
export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ')

  return (
    value
      .normalize('NFD') // split an accented letter in the base letter and the accent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      // .toLowerCase() // Allow uppercase letters
      .trim()
      .replace(/[^a-zA-Z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-') // separator
      .substring(0, MAX_SLUG_LENGTH)
  )
}
