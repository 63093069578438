import { Alert, AlertIcon } from '@chakra-ui/alert'
import { VStack } from '@chakra-ui/layout'
import { UppyFile } from '@uppy/core'
import { DragDrop } from '@uppy/react'
import { useMemo, useState } from 'react'

import { ImageType, UploadFileType, UppyUploader } from '../apis/transloadit'
import { ImageUploadResult } from '../types/ImageUpload'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'

export type OnUploadStartParams = {
  tempUrl: string
  file: UppyFile
}

type CustomUploadProps = {
  showTip?: boolean
  afterLinkDropHereText?: string
  customHeight?: string
  customFontSize?: string
}

type UploadBoxProps = {
  orgId: string
  uploadType: UploadFileType
  editType: ImageType
  onUploadStart: (params: OnUploadStartParams) => void
  onUploadSuccess: (
    uploadResult: ImageUploadResult,
    previousUrl?: string
  ) => void
  onUploadFailed?: (message: string) => void
} & CustomUploadProps

// React component for uploading images to Transloadit
// Used by the CustomImagePicker when you drag, paste, or enter URL
export const UploadBox = ({
  orgId,
  uploadType,
  editType,
  onUploadStart,
  onUploadSuccess,
  onUploadFailed,
  showTip = true,
  afterLinkDropHereText,
  customHeight,
  customFontSize,
}: UploadBoxProps) => {
  const [errorMessage, setErrorMessage] = useState('')

  const uppyInstance = useMemo(
    () =>
      UppyUploader.createUppyInstance(
        orgId,
        {
          onUploadStart,
          onOriginalFileUpload: onUploadSuccess,
          onUploadComplete: (...params) => {
            onUploadSuccess(...params)
            // Removes internal list of files, so you can reupload the same thing again
            uppyInstance.reset()
          },
          onUploadFailed,
          setErrorMessage,
        },
        editType,
        uploadType
      ),
    [
      orgId,
      editType,
      uploadType,
      onUploadSuccess,
      onUploadStart,
      onUploadFailed,
      setErrorMessage,
    ]
  )

  const dropHereText = `Drag a file or %{browse}${
    afterLinkDropHereText ? ' ' + afterLinkDropHereText : ''
  }`

  return (
    <VStack
      align="stretch"
      spacing={4}
      fontSize={customFontSize ? customFontSize : undefined}
    >
      <DragDrop
        width="100%"
        height={customHeight ? customHeight : '100%'}
        note={
          showTip
            ? 'Tip: You can also drag or paste images anywhere in a card'
            : ''
        }
        uppy={uppyInstance}
        locale={{
          strings: {
            dropHereOr: dropHereText,
            browse: 'click to upload',
          },
        }}
      />
      {errorMessage && (
        <Alert status="error">
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
    </VStack>
  )
}
