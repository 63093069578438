import { FlexProps } from '@chakra-ui/react'
import React from 'react'

import { TargetType } from 'modules/api'
import { Reactions } from 'modules/reactions/components/Reactions'

type CommentReactionProps = {
  reactions: any
  docId: string
  cardId?: string
  commentId: string
  size: 'xs' | 'sm' | 'md'
  parentCommentId?: string
  placement?: 'right' | 'left'
  usePortal?: boolean
  userCanComment: boolean
} & FlexProps
const CommentReactionsComponent = ({
  docId,
  reactions,
  cardId,
  commentId,
  size,
  parentCommentId,
  placement = 'right',
  usePortal = true,
  userCanComment,
  ...flexProps
}: CommentReactionProps) => {
  return (
    <Reactions
      size={size}
      cardId={cardId}
      usePortal={usePortal}
      placement={placement}
      reactions={reactions}
      parentCommentId={parentCommentId}
      docId={docId}
      commentId={commentId}
      targetType={TargetType.Comment}
      readOnly={!userCanComment}
      {...flexProps}
    />
  )
}

export const CommentReactions = React.memo(CommentReactionsComponent)
