import { round } from 'lodash'
import { ResolvedPos } from 'prosemirror-model'
import { Plugin, Selection, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'

import { dispatchContainerResizeEvent } from 'utils/hooks/useContainerResizing'

import { UpdateNodeAttrsAnnotationEvent } from '../../Annotatable/AnnotationExtension/types'
import {
  ColumnResizePluginKey,
  ColumnResizeState,
  ResizeStateResetEvent,
  ResizeStateSetDraggingEvent,
  ResizeStateSetHandleEvent,
} from './ColumnResizeState'
import { rebalanceColWidths } from './columnUtils'
import { TableMap } from './tablemap'
import { cellAround } from './util'

type ColumnResizingOptions = {
  handleWidth?: number
  colMinPercent?: number
  lastColumnResizable?: boolean
}
type DraggingState = {
  startX: number
  startWidth: number
  colWidths: number[]
  colIndex: number
  tableWidth: number
}

export function columnResizing({
  handleWidth = 5,
  colMinPercent = 10,
  lastColumnResizable = true,
}: ColumnResizingOptions = {}) {
  const plugin = new Plugin({
    key: ColumnResizePluginKey,
    state: {
      init() {
        return new ColumnResizeState()
      },
      apply(tr, resizeState, _oldEditorState, newEditorState) {
        return resizeState.apply(tr, newEditorState)
      },
    },
    props: {
      attributes(state) {
        const pluginState = ColumnResizePluginKey.getState(state)
        const activeHandle = pluginState!.getActiveHandleAbs(state)
        return activeHandle !== null
          ? { class: 'resize-cursor' }
          : { class: '' }
      },

      handleDOMEvents: {
        mousemove(view: EditorView, event: MouseEvent) {
          handleMouseMove(view, event, handleWidth, lastColumnResizable)
          return false
        },
        mouseleave(view: EditorView) {
          handleMouseLeave(view)
          return false
        },
        mousedown(view: EditorView, event: MouseEvent) {
          handleMouseDown(view, event, colMinPercent)
          return false
        },
      },

      decorations(state) {
        const pluginState = ColumnResizePluginKey.getState(state)
        const activeHandle = pluginState!.getActiveHandleAbs(state)
        if (activeHandle !== null) {
          return handleDecorations(state, activeHandle)
        }
        return
      },

      nodeViews: {},
    },
  })
  return plugin
}

function handleMouseMove(
  view: EditorView,
  event: MouseEvent,
  handleWidth: number,
  lastColumnResizable: boolean
) {
  // dont handle mouse move stuff when the doc is not editable
  if (!view.editable) {
    return
  }
  const pluginState = ColumnResizePluginKey.getState(view.state)!

  // no op if already dragging
  if (pluginState.dragging) {
    return
  }

  const target = domCellAround(event.target)
  let cell: number | null = null
  if (target) {
    const { left, right } = target.getBoundingClientRect()
    if (event.clientX - left <= handleWidth) {
      cell = edgeCell(view, event, 'left')
    } else if (right - event.clientX <= handleWidth) {
      cell = edgeCell(view, event, 'right')
    }
  }

  // cell is already the active resize handle, noop

  const activeHandle = pluginState.getActiveHandleAbs(view.state)
  if (cell === activeHandle) {
    return
  }

  // only allow last column resizing if enabled
  if (!lastColumnResizable && cell !== null) {
    const $cell = view.state.doc.resolve(cell)
    if (isCellLastColumn($cell)) {
      return
    }
  }

  updateHandle(view, cell)
}

function handleMouseLeave(view: EditorView) {
  const pluginState = ColumnResizePluginKey.getState(view.state)!
  const activeHandle = pluginState.getActiveHandleAbs(view.state)

  if (activeHandle !== null && !pluginState.dragging) {
    updateHandle(view, null)
  }
}

function handleMouseDown(view: EditorView, event, cellMinPercent) {
  // dont handle mouse move stuff when the doc is not editable
  if (!view.editable) {
    return
  }
  const pluginState = ColumnResizePluginKey.getState(view.state)!
  const activeHandle = pluginState.getActiveHandleAbs(view.state)
  if (activeHandle === null || pluginState.dragging) {
    return false
  }

  const $cell = view.state.doc.resolve(activeHandle)
  const table = $cell.node(-1)
  const tableStart = $cell.start(-1)
  const colWidths = [...table.attrs.colWidths]
  const colIndex = getColIndex($cell)
  const width = currentColWidth(view, activeHandle)
  const tableEl = getTableElement(view, $cell)
  const tableWidth = getTablePxWidth(view, $cell)

  const $insideCell = view.state.doc.resolve(activeHandle + 2)

  const selectionInTable =
    view.state.selection.from > tableStart &&
    view.state.selection.from < tableStart + table.nodeSize

  if (!selectionInTable) {
    view.dispatch(
      view.state.tr.setSelection(Selection.findFrom($insideCell, 1)!)
    )
  }

  const payload = <ResizeStateSetDraggingEvent>{
    setDragging: {
      startX: event.clientX,
      startWidth: width,
      colWidths,
      tableWidth,
      colIndex,
    },
  }
  view.dispatch(view.state.tr.setMeta(ColumnResizePluginKey, payload))
  let resizingColWidths: number[] | null = null

  function finish(event: MouseEvent) {
    window.removeEventListener('mouseup', finish)
    window.removeEventListener('mousemove', move)
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const pluginState = ColumnResizePluginKey.getState(view.state)!

    if (!pluginState.dragging) {
      // never started dragging
      return
    }

    if (resizingColWidths === null) {
      // started click / drag but did not actually move
      view.dispatch(
        view.state.tr.setMeta(ColumnResizePluginKey, { setDragging: null })
      )
      return
    }

    if (pluginState.dragging) {
      try {
        const activeHandle = pluginState.getActiveHandleAbs(view.state)
        dispatchUpdatedColWidths(view, activeHandle!, resizingColWidths)
        view.dispatch(
          view.state.tr.setMeta(ColumnResizePluginKey, { setDragging: null })
        )
      } catch (e) {
        // reset resize plugin state if the table gets deleted.
        const resetPayload = <ResizeStateResetEvent>{
          reset: true,
        }
        view.dispatch(
          view.state.tr.setMeta(ColumnResizePluginKey, resetPayload)
        )
      }
    }

    resizingColWidths = null
  }

  function move(event: MouseEvent) {
    if (!event.which) {
      return finish(event)
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const pluginState = ColumnResizePluginKey.getState(view.state)!
    const activeHandle = pluginState.getActiveHandleAbs(view.state)
    if (!pluginState.dragging || activeHandle === null) {
      return
    }
    const { colIndex, colWidths } = pluginState.dragging
    const percentChange = draggedPercentChange(pluginState.dragging, event)
    resizingColWidths = rebalanceColWidths(
      colWidths,
      colIndex,
      percentChange,
      cellMinPercent
    )
    displayColumnWidth(view, activeHandle, resizingColWidths)
    dispatchContainerResizeEvent(tableEl)
  }

  window.addEventListener('mouseup', finish)
  window.addEventListener('mousemove', move)
  event.preventDefault()
  return true
}

/**
 * Returns cell width in true PX
 */
function currentColWidth(view: EditorView, cellPos: number) {
  const dom = view.domAtPos(cellPos)
  let tableEl: HTMLElement = view.domAtPos(cellPos).node as HTMLElement
  while (tableEl.nodeName != 'TABLE') {
    tableEl = tableEl.parentNode! as HTMLElement
  }

  const tdNode = dom.node.childNodes[dom.offset] as HTMLElement
  return tdNode.scrollWidth
}

function domCellAround(target) {
  while (target && target.nodeName != 'TD' && target.nodeName != 'TH') {
    target = target.classList.contains('ProseMirror') ? null : target.parentNode
  }
  return target
}

/**
 * Figure out based on a mouse move what "cell edge" the mouse is near
 */
function edgeCell(
  view: EditorView,
  event: MouseEvent,
  side: 'right' | 'left'
): number | null {
  const found = view.posAtCoords({ left: event.clientX, top: event.clientY })
  if (!found) {
    return null
  }

  const { inside } = found
  // NOTE(jordan) this used to be resolve(found.pos) but after
  // changing the markup in the NodeView, found.inside + 1 seems to work...
  // not sure what else is this affecting at the moment.
  const $cell = cellAround(view.state.doc.resolve(inside + 1))

  if (!$cell) {
    return null
  }
  if (side === 'right') {
    return $cell.pos
  }
  // gets the left side?
  const map = TableMap.get($cell.node(-1))
  const start = $cell.start(-1)
  const index = map.map.indexOf($cell.pos - start)
  return index % map.width === 0 ? null : start + map.map[index - 1]
}

function draggedPercentChange(dragging: DraggingState, event): number {
  const { tableWidth } = dragging
  const offset = event.clientX - dragging.startX
  return round((100 * offset) / tableWidth, 2)
}

/**
 * Updates the draggable handle for column resizing
 */
function updateHandle(view: EditorView, value: number | null) {
  const payload = <ResizeStateSetHandleEvent>{
    setHandle: value,
  }
  view.dispatch(view.state.tr.setMeta(ColumnResizePluginKey, payload))
}

/**
 *
 * @param view
 * @param cell
 * @param colWidths
 */
function dispatchUpdatedColWidths(
  view: EditorView,
  cell: number,
  colWidths: number[]
) {
  const $cell = view.state.doc.resolve(cell)!
  const before = $cell.before(-1)
  const tr = view.state.tr
  view.dispatch(
    tr
      .setNodeMarkup(before, undefined, { colWidths: [...colWidths] })
      .setMeta('annotationEvent', <UpdateNodeAttrsAnnotationEvent>{
        type: 'update-node-attrs',
        pos: before,
      })
  )
}

function displayColumnWidth(
  view: EditorView,
  cell: number,
  colWidths: number[]
) {
  const $cell = view.state.doc.resolve(cell)
  const tableEl = getTableElement(view, $cell)
  const cols = tableEl.querySelectorAll('colgroup > col.col-width-control')!

  colWidths.forEach((width, ind) => {
    ;(cols.item(ind) as HTMLElement).style.width = `${width}%`
  })
}

function handleDecorations(state, cell) {
  const decorations: Decoration[] = []
  try {
    const $cell = state.doc.resolve(cell)
    const table = $cell.node(-1),
      map = TableMap.get(table),
      start = $cell.start(-1)

    const col = map.colCount($cell.pos - start) + $cell.nodeAfter.attrs.colspan
    for (let row = 0; row < map.height; row++) {
      const index = col + row * map.width - 1
      // For positions that are have either a different cell or the end
      // of the table to their right, and either the top of the table or
      // a different cell above them, add a decoration
      if (
        (col == map.width || map.map[index] != map.map[index + 1]) &&
        (row == 0 || map.map[index - 1] != map.map[index - 1 - map.width])
      ) {
        const cellPos = map.map[index]
        const pos = start + cellPos + table.nodeAt(cellPos).nodeSize - 1
        const dom = document.createElement('div')
        dom.className = 'column-resize-handle'
        decorations.push(Decoration.widget(pos, dom))
      }
    }
    return DecorationSet.create(state.doc, decorations)
  } catch (e) {
    console.error(`(caught) columnResizing error: ${e.message}`)
    return DecorationSet.empty
  }
}

const getColIndex = ($cell: ResolvedPos): number => {
  const table = $cell.node(-1)
  const map = TableMap.get(table)
  const start = $cell.start(-1)
  return map.colCount($cell.pos - start)
}

const isCellLastColumn = ($cell: ResolvedPos): boolean => {
  const table = $cell.node(-1)
  const map = TableMap.get(table)
  const col = getColIndex($cell)

  return col === map.width - 1
}

const getTableElement = (view: EditorView, $cell: ResolvedPos): HTMLElement => {
  let tableEl: HTMLElement = view.domAtPos($cell.start(-1)).node as HTMLElement
  while (tableEl.nodeName != 'TABLE') {
    tableEl = tableEl.parentNode! as HTMLElement
  }
  return tableEl
}

const getTablePxWidth = (view: EditorView, $cell: ResolvedPos): number => {
  return getTableElement(view, $cell).scrollWidth
}
