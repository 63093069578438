import {
  ButtonGroup,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  AlignType,
  DistributeType,
  TDExportType,
  TDSnapshot,
} from '@gamma-app/tldraw'
import * as React from 'react'

import { useUserContext } from '../../user'
import { useTldrawApp } from '../hooks'
import { StylePopover } from './StylePopover'
import { TextAlignPopover } from './TextAlignPopover'
import { ToolbarIcon } from './ToolbarIcon'
import { ToolbarWrapper } from './ToolbarWrapper'

const hasGroupSelectedSelector = (s: TDSnapshot) => {
  return s.document.pageStates[s.appState.currentPageId].selectedIds.some(
    (id) =>
      s.document.pages[s.appState.currentPageId].shapes[id].children !==
      undefined
  )
}

const hasSelectionSelector = (s: TDSnapshot) =>
  s.appState.status === 'idle' &&
  s.document.pageStates[s.appState.currentPageId].selectedIds.length > 0

const numberOfSelectedIdsSelector = (s: TDSnapshot) => {
  return s.document.pageStates[s.appState.currentPageId].selectedIds.length
}

const hasTextSelector = (s: TDSnapshot) => {
  const pageId = s.appState.currentPageId
  const page = s.document.pages[pageId]
  for (const id of s.document.pageStates[pageId].selectedIds) {
    if ('text' in page.shapes[id]) return true
  }
  return false
}

export const EditToolbar = React.memo(function EditToolbar() {
  const app = useTldrawApp()
  const { isGammaOrgUser } = useUserContext()

  const hasSelection = app.useStore(hasSelectionSelector)
  const hasGroupSelected = app.useStore(hasGroupSelectedSelector)
  const numberOfSelectedIds = app.useStore(numberOfSelectedIdsSelector)
  const hasTextSelected = app.useStore(hasTextSelector)

  return (
    <Flex
      w="100%"
      direction="row"
      position="absolute"
      bottom={2}
      align="flex-end"
      justify="center"
      zIndex={1}
    >
      <ToolbarWrapper>
        <HStack spacing={2}>
          {isGammaOrgUser && (
            <ToolbarIcon
              icon={duotone('file-arrow-down')}
              key="download"
              label="Download SVG"
              onClick={() =>
                app.exportImage(TDExportType.SVG, {
                  scale: 1,
                  quality: 1,
                })
              }
            />
          )}
          {hasSelection && (
            <>
              <ToolbarIcon
                icon={duotone('trash')}
                label={'Delete selection'}
                key={'delete'}
                onClick={() => app.delete()}
                data-testid={`drawing-edit-toolbar-delete`}
              />
              {/* <ToolbarIcon
                  icon={duotone('left-right')}
                  label={'Flip horizontal'}
                  key={'fliph'}
                  onClick={() => app.flipHorizontal()}
                />
                <ToolbarIcon
                  icon={duotone('up-down')}
                  label={'Flip vertical'}
                  key={'flipv'}
                  onClick={() => app.flipVertical()}
                /> */}
              <ForwardBackPopover />
              {numberOfSelectedIds >= 2 && <ShapeAlignPopover />}
              {numberOfSelectedIds >= 2 || hasGroupSelected ? (
                <ToolbarIcon
                  icon={duotone('object-group')}
                  label={
                    hasGroupSelected ? 'Ungroup selection' : 'Group selection'
                  }
                  key={'group'}
                  onClick={() =>
                    hasGroupSelected ? app.ungroup() : app.group()
                  }
                  isActive={hasGroupSelected}
                />
              ) : null}
            </>
          )}
          <StylePopover />
          {hasTextSelected && <TextAlignPopover />}
        </HStack>
      </ToolbarWrapper>
    </Flex>
  )
})

const ForwardBackPopover = () => {
  const app = useTldrawApp()
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <ToolbarIcon icon={duotone('bring-forward')} />
      </PopoverTrigger>
      <PopoverContent background="none" shadow="none" w="fit-content">
        <ToolbarWrapper direction="row">
          <ToolbarIcon
            icon={duotone('bring-front')}
            label={'Move to front'}
            key={'front'}
            onClick={() => app.moveToFront()}
          />
          <ToolbarIcon
            icon={duotone('bring-forward')}
            label={'Move forward'}
            key={'forward'}
            onClick={() => app.moveForward()}
          />
          <ToolbarIcon
            icon={duotone('send-backward')}
            label={'Move backward'}
            key={'backward'}
            onClick={() => app.moveBackward()}
          />
          <ToolbarIcon
            icon={duotone('send-back')}
            label={'Move to back'}
            key={'back'}
            onClick={() => app.moveToBack()}
          />
        </ToolbarWrapper>
      </PopoverContent>
    </Popover>
  )
}

const ShapeAlignPopover = () => {
  const app = useTldrawApp()
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <ToolbarIcon icon={duotone('objects-align-left')} />
      </PopoverTrigger>
      <PopoverContent background="none" shadow="none" w="fit-content">
        <ToolbarWrapper direction="column">
          <ButtonGroup>
            <ToolbarIcon
              icon={duotone('objects-align-left')}
              label={'Align left'}
              key={'align-left'}
              onClick={() => app.align(AlignType.Left)}
            />
            <ToolbarIcon
              icon={duotone('objects-align-center-horizontal')}
              label={'Align center'}
              key={'align-center'}
              onClick={() => app.align(AlignType.CenterHorizontal)}
            />
            <ToolbarIcon
              icon={duotone('objects-align-right')}
              label={'Align right'}
              key={'align-right'}
              onClick={() => app.align(AlignType.Right)}
            />
            <ToolbarIcon
              icon={duotone('distribute-spacing-horizontal')}
              label={'Distribute horizontally'}
              key={'distribute-h'}
              onClick={() => app.distribute(DistributeType.Horizontal)}
            />
          </ButtonGroup>
          <ButtonGroup>
            <ToolbarIcon
              icon={duotone('objects-align-top')}
              label={'Align top'}
              key={'align-top'}
              onClick={() => app.align(AlignType.Top)}
            />
            <ToolbarIcon
              icon={duotone('objects-align-center-vertical')}
              label={'Align middle'}
              key={'align-middle'}
              onClick={() => app.align(AlignType.CenterVertical)}
            />
            <ToolbarIcon
              icon={duotone('objects-align-bottom')}
              label={'Align bottom'}
              key={'align-bottom'}
              onClick={() => app.align(AlignType.Bottom)}
            />
            <ToolbarIcon
              icon={duotone('distribute-spacing-vertical')}
              label={'Distribute vertically'}
              key={'distribute-v'}
              onClick={() => app.distribute(DistributeType.Vertical)}
            />
          </ButtonGroup>
        </ToolbarWrapper>
      </PopoverContent>
    </Popover>
  )
}
