import { useEffect, useRef, useState } from 'react'
import * as workerTimers from 'worker-timers'

type RateLimiterResult = [
  // The function for the caller to invoke
  activityFn: () => any,

  // Whether or not the limit has been hit
  isRateLimited: boolean,

  // How many times the limit has been hit
  rateLimitHitCount: number,

  // If the page is backgrounded or not
  isBackgrounded: boolean
]

/**
 * A hook used to monitor the frequency of a given function.
 *
 * The returned activity function is used by the caller to invoke
 * wherever the activity happens that they want to monitor.
 *
 * Once the limit is hit, the counter must return to 0 before
 * the rate limiter is reset and moves on.
 */
export const useRateLimiter = (
  period = 1000,
  allowedCalls = 5
): RateLimiterResult => {
  const counter = useRef<number>(0)
  const intervalId = useRef<number>(0)
  const [isRateLimited, setIsRateLimited] = useState<boolean>(false)
  const [rateLimitHitCount, setHitCount] = useState<number>(0)
  const [isBackgrounded, setBackgrounded] = useState<boolean>(document.hidden)

  const activityFn = useRef<() => any>(() => {
    counter.current++
    const atLimit = counter.current >= allowedCalls
    if (atLimit) {
      console.warn(
        `[useRateLimiter] Limit hit! (${allowedCalls} per ${period / 1000}s)`
      )
      if (!isRateLimited) {
        // Flipped from under threshold to over
        setIsRateLimited(true)
        setHitCount((prev) => prev + 1)
      }
    }

    // Make sure the cooldown interval is running
    if (!intervalId.current) {
      const rate = period / allowedCalls
      intervalId.current = workerTimers.setInterval(() => {
        if (counter.current > 0) {
          counter.current--
        } else {
          // The counter must come back down to 0 before
          // the current rate limiter cycle is reset
          setIsRateLimited(false)
          workerTimers.clearInterval(intervalId.current)
          intervalId.current = 0
        }
      }, rate)
    }
  })

  useEffect(() => {
    const handler = () => {
      setBackgrounded(document.hidden)
      activityFn.current()
    }
    document.addEventListener('visibilitychange', handler)
    return () => document.removeEventListener('visibilitychange', handler)
  }, [])

  return [activityFn.current, isRateLimited, rateLimitHitCount, isBackgrounded]
}
