import { CloseIcon } from '@chakra-ui/icons'
import {
  Collapse,
  Divider,
  HStack,
  IconButton,
  Kbd,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { getShortcutTextForOS } from '@gamma-app/ui'

export const SearchModalTip = ({ onClose, showTip }) => {
  return (
    <Collapse in={showTip}>
      <Divider />
      <HStack p={4} px={6}>
        <Text fontSize="sm" color="gray.600">
          <span role="img" aria-label="Wave">
            👋{' '}
          </span>
          Tip: You can open this anywhere by pressing{' '}
          <Kbd>{getShortcutTextForOS('Mod+K')}</Kbd>
        </Text>
        <Spacer />
        <IconButton
          aria-label="Close"
          icon={<CloseIcon boxSize={2.5} />}
          isRound
          size="xs"
          variant="ghost"
          onClick={onClose}
        />
      </HStack>
    </Collapse>
  )
}
