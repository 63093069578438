import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'

import { getShapeStyles, getTextStyles } from './styles'

type TldrawSvgProps = {
  svg: string | undefined | null
  isDark: boolean | undefined
  font: string
}

export const TldrawSvg = ({ svg, isDark, font }: TldrawSvgProps) => {
  const shapeStyles = useMemo(() => getShapeStyles(isDark), [isDark])
  const textStyles = useMemo(() => getTextStyles(font), [font])

  if (!svg) return null

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: svg }}
      data-testid="drawing-svg"
      sx={{
        svg: {
          width: '100%',
          height: 'auto',
          text: textStyles,
          ...shapeStyles,
        },
      }}
    />
  )
}
