// Copied from https://github.com/ueberdosis/tiptap/tree/main/packages/extension-floating-menu/src
// Tweaked to run inside non-root level nodes (eg in a card)

import {
  Editor,
  Extension,
  findParentNodeClosestToPos,
  posToDOMRect,
} from '@tiptap/core'
import debounce from 'lodash/debounce'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import tippy, { Instance, Props } from 'tippy.js'

import { PanelLifecycle } from 'modules/panels/PanelLifecycle'

import { isCardNode } from '../Card'

export const FloatingMenuPluginKey = new PluginKey('menuFloating')

export interface FloatingMenuPluginState {
  element: HTMLElement | null
  tippyOptions?: Partial<Props>
  panelLifecycle?: PanelLifecycle
  hideTooltips?: () => void
}

export type FloatingMenuViewProps = {
  view: EditorView
  editor: Editor
}

export class FloatingMenuView {
  public editor: Editor

  public element: HTMLElement

  public view: EditorView

  public preventHide = false

  public tippy!: Instance

  // Keep track of the last known editor.isEditable state
  private isEditable: boolean

  private toggleVisibleDebounced: (visible: boolean) => void

  private cleanupPanelLifecycle: () => void

  // function to hide any tooltips that the tippy instance of the FloatMenuView creates
  private hideTooltips?: () => void

  constructor({ editor, view }: FloatingMenuViewProps) {
    this.editor = editor
    this.isEditable = editor.isEditable
    this.view = view
  }

  initialize({
    element,
    tippyOptions,
    panelLifecycle,
    hideTooltips,
  }: FloatingMenuPluginState) {
    if (!element) {
      console.warn('[FloatingMenuView] initialize. No element provided')
      return
    }
    this.hideTooltips = hideTooltips
    this.element = element
    this.element.addEventListener('mousedown', this.mousedownHandler, {
      capture: true,
    })
    this.editor.on('focus', this.focusHandler)
    this.editor.on('blur', this.blurHandler)
    this.createTooltip(tippyOptions)
    this.element.style.visibility = 'visible'
    this.element.style.pointerEvents = 'none'
    this.toggleVisibleDebounced = debounce(this.toggleVisible, 20).bind(this)
    // account for the times where panel lifecycle isn't provided via context
    // such as test environments.  This plugin doesnt NEED the PanelLifecycle to function
    // correctly, so making this optional is okay.
    if (panelLifecycle) {
      this.cleanupPanelLifecycle = panelLifecycle.on('resize', () => {
        this.tippy.popperInstance?.update()
      })
    }
  }

  mousedownHandler = () => {
    this.preventHide = true
  }

  focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
    setTimeout(() => this.update(this.editor.view))
  }

  blurHandler = ({ event }: { event: FocusEvent }) => {
    if (this.preventHide) {
      this.preventHide = false

      return
    }

    if (
      event?.relatedTarget &&
      this.element.parentNode?.contains(event.relatedTarget as Node)
    ) {
      return
    }

    this.hide()
  }

  createTooltip(options: Partial<Props> = {}) {
    const el = this.view.dom.parentElement
    this.tippy = tippy(el!, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: 'manual',
      placement: 'right',
      hideOnClick: 'toggle',
      ...options,
    })
    // Why cant I put hypens in the name here???
    this.tippy.popper.dataset['tippygammatype'] = 'floating-menu-popper'
  }

  update(view: EditorView, oldState?: EditorState) {
    const { state } = view
    const { doc, selection } = state
    const isSame =
      oldState &&
      oldState.doc.eq(doc) &&
      oldState.selection.eq(selection) &&
      this.isEditable === this.editor.isEditable

    if (!this.element) {
      const pluginState = FloatingMenuPluginKey.getState(view.state)
      if (!pluginState.element) {
        return
      }
      this.initialize(pluginState)
    }

    this.isEditable = this.editor.isEditable

    // Always hide the menu if the instance is not editable
    if (!this.editor.isEditable) {
      this.hide()
      return
    }

    if (isSame) {
      return
    }

    const { $anchor, empty, from, to } = selection
    const parentCardDepth =
      findParentNodeClosestToPos(state.doc.resolve(from), isCardNode)?.depth ||
      0
    const isRootDepth = $anchor.depth === parentCardDepth + 1 // Menu should show when you're inside an immediate child (eg paragraph) of the card
    const { parent } = $anchor
    const { firstChild } = parent
    const singleEmptyChild =
      parent.childCount == 1 &&
      firstChild?.isTextblock &&
      !firstChild.textContent // Exclude inline atom nodes like mentions
    const isNodeEmpty =
      !parent.isLeaf &&
      !parent.textContent &&
      (parent.childCount === 0 || singleEmptyChild)
    const isParagraph = $anchor.parent.type.name === 'paragraph'
    const isActive = isRootDepth && isNodeEmpty && isParagraph

    if (!empty || !isActive) {
      this.hide()

      return
    }

    this.tippy.setProps({
      getReferenceClientRect: () => posToDOMRect(view, from, to),
    })
    this.show()
  }

  show() {
    this.toggleVisibleDebounced(true)
  }

  hide() {
    this.toggleVisibleDebounced(false)
    if (this.hideTooltips) {
      this.hideTooltips()
    }
  }

  toggleVisible(visible: boolean) {
    if (visible) {
      this.tippy.show()
    } else {
      this.tippy.hide()
    }
  }

  destroy() {
    this.tippy?.destroy()
    this.element?.removeEventListener('mousedown', this.mousedownHandler)
    this.editor.off('focus', this.focusHandler)
    this.editor.off('blur', this.blurHandler)
    if (this.cleanupPanelLifecycle) {
      this.cleanupPanelLifecycle()
    }
  }
}

export const FloatingMenu = Extension.create({
  name: 'floatingMenu',

  addProseMirrorPlugins() {
    const { editor } = this
    return [
      new Plugin<FloatingMenuPluginState>({
        key: FloatingMenuPluginKey,
        state: {
          init: () => {
            return {
              element: null,
              tippyOptions: {},
            }
          },
          apply(transaction, pluginState) {
            const newPluginState = transaction.getMeta(FloatingMenuPluginKey)

            if (newPluginState) {
              return newPluginState
            }
            return pluginState
          },
        },
        view: (view) => new FloatingMenuView({ view, editor }),
      }),
    ]
  },
})
