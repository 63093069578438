import { clamp } from 'lodash'

// Returns an angle between two points, in radians
export function getAngleRad(x1, y1, x2, y2) {
  return Math.atan2(y2 - y1, x2 - x1)
}

// Converts radians to degrees
export function radiansToDegrees(radians) {
  const pi = Math.PI
  return radians * (180 / pi)
}

export function degreesToRadians(degrees) {
  const pi = Math.PI
  return degrees * (pi / 180)
}

// Gets distance between two points, clamped to the maximum value
export function getDistance(x1, y1, x2, y2, max) {
  const y = x2 - x1
  const x = y2 - y1
  const d = Math.sqrt(x * x + y * y)
  return clamp(d, 0, max)
}
