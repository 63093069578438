import { Editor } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

import { getStore } from 'modules/redux'
import { selectCardIds, selectEditable } from 'modules/tiptap_editor/reducer'
import {
  doesMemoContainGivenCardFromUrl,
  getCardIdFromHash,
} from 'modules/tiptap_editor/utils'

import { navigateToCardLink } from './Link'
export const linkClickPlugin = (editor: Editor) =>
  new Plugin({
    // Adapted from https://github.com/ueberdosis/tiptap/blob/main/packages/extension-link/src/link.ts#L115
    key: new PluginKey('handleCmdClickLink'),
    props: {
      handleDOMEvents: {
        click: (_, event) => {
          const store = getStore()
          const editable = selectEditable(store.getState())
          const link = (event.target as HTMLElement)?.closest('a')
          const url = link?.href

          if (!url || link?.closest('[data-comments-wrapper]')) {
            // Exclude any link occuring inside a child of the comments popper
            return false
          }

          event.preventDefault()

          const isCmdClick = event.ctrlKey || event.metaKey
          const cardIdFromUrl = getCardIdFromHash(url)
          const cardIds = selectCardIds(store.getState())
          const shouldHandleInternalCardLink = doesMemoContainGivenCardFromUrl(
            url,
            cardIds
          )

          if (editable) {
            if (!isCmdClick) return false // don't do anything; let the link formatting menu open.
            if (shouldHandleInternalCardLink) {
              navigateToCardLink(editor, event, cardIdFromUrl)
              return false
            }
          } else {
            if (!isCmdClick && shouldHandleInternalCardLink) {
              navigateToCardLink(editor, event, cardIdFromUrl)
              return false
            }
          }
          window.open(url, '_blank')
          return false
        },
      },
    },
  })
