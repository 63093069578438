import { Content, findChildren, mergeAttributes, Node } from '@tiptap/core'

import { WebpageProvider } from 'modules/media'
import { fetchEmbedAttrsForUrl } from 'modules/media/components/URLFetcher'

import { ReactNodeViewRenderer } from '../../../react'
import { configureJSONAttribute } from '../../../utils'
import { attrsOrDecorationsChanged } from '../../updateFns'
import { MediaSourcesMap } from '../MediaSources'
import { MediaEmbedAttrs } from '../types'
import { generateMediaId } from '../UniqueMediaId'
import { getMediaSourceUrl, getMediaTitle } from '../utils'
import { EmbedView } from './EmbedView'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embed: {
      replaceEmbedsById: (id: string, content: Content) => ReturnType
      insertEmbedAndFetchMetadata: (
        url: string,
        displayStyle?: MediaEmbedAttrs['displayStyle'],
        fallBackToText?: boolean
      ) => ReturnType
    }
  }
}

export const Embed = Node.create({
  name: 'embed',
  group: 'block media',
  atom: true,
  draggable: true,
  selectable: true,

  addNodeView() {
    return ReactNodeViewRenderer(EmbedView, {
      update: attrsOrDecorationsChanged,
    })
  },

  addAttributes() {
    return {
      url: {},
      source: {
        default: WebpageProvider.key,
      },
      thumbnail: {
        ...configureJSONAttribute('thumbnail'),
      },
      embed: {
        ...configureJSONAttribute('embed'),
      },
      meta: {
        ...configureJSONAttribute('meta'),
      },
      proxy: {},
      sourceUrl: {},
      displayStyle: {
        default: 'preview',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=embed]',
      },
    ]
  },
  renderHTML({ HTMLAttributes, node }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'embed' }),
      // This won't be used in our own internal copy paste, but is what shows up
      // when you copy from Gamma into another tool, or generate a comment preview
      [
        'a',
        { href: getMediaSourceUrl(node), target: '_blank' },
        getMediaTitle(node),
      ],
    ]
  },

  addCommands() {
    return {
      replaceEmbedsById:
        (id, content) =>
        ({ editor, chain }) => {
          const matches = findChildren(
            editor.state.doc,
            (n) => n.type.name === 'embed' && n.attrs.id === id
          )
          const updateChain = chain()
          matches.forEach(({ pos, node }) => {
            updateChain.insertContentAt(
              { from: pos, to: pos + node.nodeSize },
              content,
              { updateSelection: false }
            )
          })
          updateChain.run()
          return true
        },
      insertEmbedAndFetchMetadata:
        (url, displayStyle = 'preview', fallBackToText = false) =>
        ({ commands, editor }) => {
          const id = generateMediaId()

          commands.insertContent(
            {
              type: 'embed',
              attrs: {
                id,
                url,
                sourceUrl: url,
                displayStyle,
                meta: {
                  title: 'Loading...',
                },
              },
            },
            { updateSelection: false }
          )

          // Async fetch metadata for it, then replace the placeholder node
          fetchEmbedAttrsForUrl(url)
            .then((attributes) => {
              const source = MediaSourcesMap[attributes.source]
              editor.commands.replaceEmbedsById(id, {
                type: source?.nodeName || 'embed',
                attrs: attributes,
                displayStyle:
                  displayStyle ?? source.nodeName === 'video'
                    ? 'inline'
                    : 'preview',
              })
            })
            .catch((reason) => {
              // If we fail to get the URL (e.g. it's private), revert from embed to text
              console.warn(
                '(caught) insertEmbedAndFetchMetadata error fetching url',
                reason,
                url
              )
              if (!fallBackToText) return
              editor.commands.replaceEmbedsById(id, url)
            })

          return true
        },
    }
  },
})
