import { config } from 'config'
import { ExistingWorkspace } from 'modules/api'
import { slugify } from 'utils/slugify'

// See packages/server/src/common/utils.ts
export const DOC_NANOID_REGEX = /[0-9a-z]{15}$/

export const CARD_HASH_PREFIX = 'card-'

export const generateInviteUrl = (workspace: ExistingWorkspace) => {
  return `${config.FRONTEND_URL}/${workspace.id}/join?code=${workspace.inviteCode}`
}

export const generateDocUrl = (
  {
    docId,
    path = 'docs',
  }: {
    docId: string
    path?: 'docs' | 'example'
  },
  relative = true
) => {
  return `${relative ? '' : config.FRONTEND_URL}/${path}/${docId}`
}

export const generateChannelUrl = ({ id, slug }, relative = true) => {
  return `${relative ? '' : config.FRONTEND_URL}/channels/${slug}-${id}`
}
export const generateExampleUrl = ({ docId }, relative = true) => {
  return `${relative ? '' : config.FRONTEND_URL}/example/${docId}`
}

export const generateSlugifiedDocUrl = ({
  docTitle,
  docId,
  token = undefined,
}: {
  docTitle?: string
  docId?: string
  token?: string
}) => {
  if (!docTitle || !docId) return ''

  const url = new URL(
    `${config.FRONTEND_URL}/docs/${
      docTitle ? `${slugify(docTitle)}-` : ''
    }${docId}`
  )

  if (token) {
    url.searchParams.set(`token`, token)
  }

  return url.toString()
}

export const getCardUrl = ({
  cardId,
  docId,
  isPresentMode = false,
}: {
  cardId: string
  docId?: string
  isPresentMode?: boolean
}) => {
  if (!docId) {
    console.error('[getCardUrl] docId is undefined. Returning empty string')
    return ''
  }
  return `${config.FRONTEND_URL}/docs/${docId}/${
    isPresentMode ? `?mode=present` : ''
  }#${CARD_HASH_PREFIX}${cardId}`
}

export const generateCommentUrl = ({ docId, id }) => {
  return `${config.FRONTEND_URL}/docs/${docId}#comment-${id}`
}

/**
 * Next router doesn't provide a first class way to access the browser hash,
 * so this is an SSR defensive utility to get it from window.location.hash
 */
export const getUrlHash = (url?: string): string | undefined => {
  if (typeof window === 'undefined') return

  const hash = url
    ? new URL(url.startsWith('https') ? url : `${config.FRONTEND_URL}${url}`)
        .hash
    : window.location.hash

  return hash.split('#').pop() || undefined
}

/**
 * Grabs the cardId and commentId from the URL hash if present
 */
export const parseUrlHash = (
  url?: string
): {
  cardId: string | null
  commentId: string | null
} => {
  const hash = getUrlHash(url)
  const cardId = hash?.split(CARD_HASH_PREFIX)?.[1] || null
  const commentId = hash?.split('comment-')?.[1] || null
  return {
    cardId,
    commentId,
  }
}

export const getExistingQueryParams = () => {
  if (typeof window === 'undefined') return {}

  const url = new URL(window.location.href)
  const rest: { [key: string]: string } = {}
  for (const [key, value] of url.searchParams.entries()) {
    rest[key] = value
  }
  return rest
}

const stripTrailingSlash = (str: string) => {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

export const getPathnameParts = (url?: string): [string, string] => {
  if (typeof window === 'undefined') return ['', '']
  const urlObj = new URL(url || window.location.href)
  urlObj.pathname = stripTrailingSlash(urlObj.pathname)

  // Strip trailing slashes to ensure lastPart is correct
  const prefix = urlObj.pathname
    .split('/')
    .slice(0, -1)
    .filter(Boolean) // Filter out any empty strings
    .join('/')
  const lastPart = urlObj.pathname.split('/').slice(-1)[0]

  return [prefix, lastPart]
}

export const isValidHomeUrl = (url) => {
  return (
    !url.startsWith('/docs/') &&
    // We may not need this
    (url.startsWith('/channels/') ||
      url.startsWith('/#examples') ||
      url.startsWith('/#inspiration') ||
      url.startsWith('/#all') ||
      url.startsWith('/#trash'))
  )
}
