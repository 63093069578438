import {
  Box,
  HStack,
  LinkBox,
  LinkOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvatarGroupAutoOverflow } from '@gamma-app/ui'
import { MouseEvent, MutableRefObject } from 'react'

import { SearchChannelResultFragment } from 'modules/api'
import { CHANNELS_ICON } from 'modules/sharing/constants'
import { generateChannelUrl } from 'utils/url'

type ChannelSearchResultItemProps = {
  result: SearchChannelResultFragment
  isActive?: boolean
  focusedItemEl?: MutableRefObject<HTMLDivElement | null>
  onMouseEnter?: (e: MouseEvent) => void
}

const MAX_CHANNEL_AVATARS = 3

export const ChannelSearchResultItem = ({
  result,
  isActive,
  focusedItemEl,
  onMouseEnter,
}: ChannelSearchResultItemProps) => {
  const { name, id, slug, isMember, memberCount, members } = result
  const memberList = members || []
  return (
    <LinkBox>
      <HStack
        ref={isActive ? focusedItemEl : null}
        className="search-result"
        wordBreak="break-word"
        onMouseEnter={onMouseEnter}
        p={3}
        align="flex-start"
        alignItems="center"
        borderRadius="md"
        transitionProperty="common"
        transitionDuration="normal"
        role="group"
        bg={isActive ? 'gray.100' : 'transparent'}
        cursor="pointer"
        color="gray.800"
        borderWidth="1px"
        borderColor={isActive ? 'gray.200' : 'transparent'}
        spacing={{ base: 0, sm: 4 }}
      >
        <Box p={2}>
          <FontAwesomeIcon icon={CHANNELS_ICON} />
        </Box>
        <Stack spacing={2} flex="1">
          <LinkOverlay href={generateChannelUrl({ id, slug })}>
            <Text>{name}</Text>
            <Text fontSize="sm" color="gray.400">
              {memberCount} {memberCount === 1 ? 'member' : 'members'}
              {isMember && ', including you.'}
            </Text>
          </LinkOverlay>
        </Stack>
        <Spacer />
        <AvatarGroupAutoOverflow
          size="xs"
          avatars={memberList}
          max={MAX_CHANNEL_AVATARS}
        />
      </HStack>
    </LinkBox>
  )
}
