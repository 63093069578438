import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

import { DocumentAttrsPluginState } from './DocumentAttrsPluginState'

export const DocumentAttrsPluginKey = new PluginKey<DocumentAttrsPluginState>(
  'documentAttrs'
)

export const DocumentAttrsExtension = Extension.create({
  name: 'documentAttrs',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: DocumentAttrsPluginKey,

        state: {
          init() {
            return new DocumentAttrsPluginState()
          },

          apply(transaction, pluginState, oldEditorState, newEditorState) {
            return pluginState.apply(transaction, newEditorState)
          },
        },
      }),
    ]
  },
})
