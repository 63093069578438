import EudoxusFontLinks from 'scss/fonts/Eudoxus-Sans'

import { Theme } from '../types'
import { getThemeCSSVars } from '../utils'
import { SUPPORTED_GOOGLE_FONTS } from './supportedFonts'

type FontLoaderProps = {
  fonts: string[]
}

export const FontLoader = ({ fonts }: FontLoaderProps) => {
  const hasEudoxus = fonts.includes('Eudoxus Sans')
  const googleFonts = fonts.filter((font) =>
    SUPPORTED_GOOGLE_FONTS.includes(font)
  )
  return (
    <>
      {hasEudoxus ? <EudoxusFontLinks /> : null}
      {googleFonts.length > 0 && (
        <link
          href={`https://fonts.googleapis.com/css2?${googleFonts
            .map(
              (fontName) =>
                // Currently, we don't have a way for themes to specify the font weight
                // Instead we hardcode "normal" (400) and "bold" (700)
                // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/typography.ts#L29
                // A handful of default themes also use 600 manually via CSS, so leaving that in for now too.
                `family=${encodeURIComponent(fontName)}:wght@400;600;700`
            )
            .join('&')}&display=swap`}
          rel="stylesheet"
        />
      )}
    </>
  )
}

type ThemeFontLoaderProps = {
  theme: Theme
}
export const ThemeFontLoader = ({ theme }: ThemeFontLoaderProps) => {
  const cssVars = getThemeCSSVars(theme)
  const fontsNeeded = [cssVars['--heading-font'], cssVars['--body-font']]
  return <FontLoader fonts={fontsNeeded} />
}
