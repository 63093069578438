import {
  callOrReturn,
  ExtendedRegExpMatchArray,
  InputRuleFinder,
} from '@tiptap/core'
import { InputRule } from '@tiptap/react'
import { NodeType } from 'prosemirror-model'
import { TextSelection } from 'prosemirror-state'

import { analytics, NodeInsertMethods, SegmentEvents } from 'modules/segment'
import { findParentNodeOfType } from 'modules/tiptap_editor/prosemirror-utils'

export const disallowParentsFromInputRule = (
  config: {
    find: RegExp
    type: NodeType
    getAttributes?: {
      [key: string]: any
    }
  },
  disallowedParentNodeTypes: NodeType[]
) => {
  const rule = textblockTypeInputRule(config)
  return new InputRule({
    find: config.find,
    handler: (props) => {
      const { state, range } = props
      const selection = new TextSelection(
        state.doc.resolve(range.from),
        state.doc.resolve(range.to)
      )
      const disallowedParent = findParentNodeOfType(disallowedParentNodeTypes)(
        selection
      )

      if (disallowedParent) {
        return null
      }
      // If we didn't find a disallowed parent, run the original rule handler.
      const handled = rule.handler(props)
      if (handled !== null) {
        analytics.track(SegmentEvents.NODE_INSERTED, {
          method: NodeInsertMethods.INPUT_RULE,
          node_name: config.type.name,
        })
      }
      return handled
    },
  })
}

/**
 * Build an input rule that changes the type of a textblock when the
 * matched text is typed into it. When using a regular expresion you’ll
 * probably want the regexp to start with `^`, so that the pattern can
 * only occur at the start of a textblock.
 * Forked from https://github.com/ueberdosis/tiptap/blob/2c1c557a8be845dbdffcb2e1d1f9a33bd0a2a4df/packages/core/src/inputRules/textblockTypeInputRule.ts#L1
 * to support retaining existing attributes
 */
export function textblockTypeInputRule(config: {
  find: InputRuleFinder
  type: NodeType
  getAttributes?:
    | Record<string, any>
    | ((match: ExtendedRegExpMatchArray) => Record<string, any>)
    | false
    | null
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range, match }) => {
      const $start = state.doc.resolve(range.from)
      // Begin Gamma fork - keep existing node attributes when changing type
      const patchAttrs =
        callOrReturn(config.getAttributes, undefined, match) || {}
      const attributes = { ...$start.parent.attrs, ...patchAttrs }
      // End Gamma fork

      if (
        !$start
          .node(-1)
          .canReplaceWith($start.index(-1), $start.indexAfter(-1), config.type)
      ) {
        return
      }

      const { tr } = state
      tr.delete(range.from, range.to).setBlockType(
        range.from,
        range.from,
        config.type,
        attributes
      )
    },
  })
}
