import { useEffect, useRef, useState } from 'react'

const ONE_MIN_IN_MS = 60000

export const useCurrentTime = (lastEdited?: string) => {
  const [time, setTime] = useState(new Date())
  const hasInitialized = useRef(false)

  useEffect(() => {
    if (hasInitialized.current) {
      setTime(new Date())
    }
    hasInitialized.current = true
    const intervalId = setInterval(() => {
      setTime(new Date())
    }, ONE_MIN_IN_MS)

    return () => {
      clearInterval(intervalId)
    }
  }, [lastEdited])

  return time
}
