import { round } from 'lodash'

export const createColumnWidths = (colCount: number): number[] => {
  const eachWidth = round(100 / colCount, 2)
  const res: number[] = []
  for (let i = 0; i < colCount; i++) {
    res.push(eachWidth)
  }
  return res
}

const calcDistributionMapResize = (
  arr: number[],
  ind: number,
  minWidth: number,
  isGrowing: boolean
): number[] => {
  return isGrowing
    ? calcDistributionMapGrow(arr, ind, minWidth)
    : calcDistributionMapShrink(arr, ind)
}

const calcDistributionMapShrink = (arr: number[], ind: number): number[] => {
  // let numToRedistribute = 0
  let denominator = 0
  const indToRedistribute: number[] = []
  for (let i = ind + 1; i < arr.length; i++) {
    const val = arr[i]
    denominator += val
    indToRedistribute.push(i)
  }

  const result: number[] = []
  for (let i = 0; i < arr.length; i++) {
    if (!indToRedistribute.includes(i)) {
      result.push(0)
      continue
    }

    const existingVal = arr[i]
    result.push(existingVal / denominator)
  }
  return result
}

const calcDistributionMapGrow = (
  arr: number[],
  ind: number,
  minWidth: number
): number[] => {
  // let numToRedistribute = 0
  let denominator = 0
  const indToRedistribute: number[] = []
  for (let i = ind + 1; i < arr.length; i++) {
    const val = arr[i]
    if (val > minWidth) {
      // numToRedistribute++
      denominator += val
      indToRedistribute.push(i)
    }
  }

  const result: number[] = []
  for (let i = 0; i < arr.length; i++) {
    if (!indToRedistribute.includes(i)) {
      result.push(0)
      continue
    }

    const existingVal = arr[i]
    result.push(existingVal / denominator)
  }
  return result
}

const calcDistributionMapRemove = (arr: number[]): number[] => {
  const denominator = arr.reduce((a, b) => a + b, 0)

  const res: number[] = []
  for (let i = 0; i < arr.length; i++) {
    res.push(arr[i] / denominator)
  }

  return res
}

const calcDistributionMapInsert = (
  arr: number[],
  ind: number,
  minWidth: number
): number[] => {
  // let numToRedistribute = 0
  let denominator = 0
  const indToRedistribute: number[] = []
  for (let i = 0; i < arr.length; i++) {
    if (i === ind) {
      continue
    }

    const val = arr[i]
    if (val > minWidth) {
      denominator += val
      // numToRedistribute++
      indToRedistribute.push(i)
    }
  }

  const result: number[] = []
  for (let i = 0; i < arr.length; i++) {
    if (i === ind) {
      result.push(0)
      continue
    }

    const val = arr[i]
    if (val <= minWidth) {
      result.push(0)
      continue
    }
    result.push(val / denominator)
  }
  return result
}

/**
 * Distibutes some amount by increasing the specific col that's being dragged
 * then subtracting (or adding if making smaller) the same amount to the columns
 * to the right based on the distributionMap
 *
 * If the amount is positive then it's adding to each based on the distribution map
 *
 *
 * ex:
 * arr=[10,10,10]
 * amount=4
 * distributionMap=[.5,.25,.25]
 * result => [12,11,11]
 */
const distribute = (
  arr: number[],
  amount: number,
  distributionMap: number[],
  colMinPercent: number = 0
): number => {
  let amountRemoved = 0
  for (let i = 0; i < arr.length; i++) {
    const multiplier = distributionMap[i]
    let toAdd = amount * multiplier
    if (toAdd < 0) {
      toAdd = Math.max(colMinPercent - arr[i], toAdd)
    }

    amountRemoved += toAdd
    arr[i] += toAdd
  }
  return amountRemoved
}

export const rebalanceColWidths = (
  colWidths: number[],
  colIndex: number,
  percentChangeRaw: number,
  colMinPercent: number
): number[] => {
  const newColWidths = [...colWidths]
  const currWidth = colWidths[colIndex]
  const maxChangeShrink = currWidth - colMinPercent
  const otherTotal = colWidths.reduce((carry, val, ind) => {
    if (ind < colIndex) {
      return carry + val
    } else if (ind > colIndex) {
      return carry + colMinPercent
    }

    return carry
  }, 0)
  const maxChangeGrow = 100 - otherTotal - currWidth

  // dont allow the cell to be shrunk more than the colMinPercent
  // const percentChange = Math.min(Math.max(-maxChangeShrink, percentChangeRaw))
  const percentChange =
    percentChangeRaw > 0
      ? Math.min(percentChangeRaw, maxChangeGrow)
      : Math.max(-maxChangeShrink, percentChangeRaw)

  // example
  // colWidths = [25, 25, 25, 25]
  // colIndex = 1
  // percentChange = 2
  // result should be [25, 27, 24, 24]

  // redistribute columns to the right
  let map: number[] = calcDistributionMapResize(
    newColWidths,
    colIndex,
    colMinPercent,
    percentChange > 0
  )

  let remaining = Math.abs(percentChange)
  const isAdding = percentChange > 0
  // margin of error for FP numbers
  let i = 0
  while (remaining > 0.001) {
    let amount = Math.min(remaining, 1)
    if (isAdding) {
      amount *= -1
    }
    const distributed = distribute(newColWidths, amount, map, colMinPercent)
    newColWidths[colIndex] -= distributed
    remaining -= Math.abs(distributed)
    map = calcDistributionMapResize(
      newColWidths,
      colIndex,
      colMinPercent,
      percentChange > 0
    )
    i++
    if (i > 1000) {
      break
    }
  }

  fixWidths(newColWidths, colMinPercent)

  return newColWidths
}

export const canAddCol = (
  colWidths: number[],
  newColPercent: number,
  colMinPercent: number
): boolean => {
  const newColWidths = addColWidth(
    colWidths,
    colWidths.length,
    newColPercent,
    colMinPercent
  )
  return newColWidths.length !== colWidths.length
}

export const addColWidth = (
  colWidths: number[],
  insertIndex: number,
  newColPercent: number,
  colMinPercent: number
): number[] => {
  let remaining = newColPercent
  const newWidths = [...colWidths]
  newWidths.splice(insertIndex, 0, 0)

  let map = calcDistributionMapInsert(newWidths, insertIndex, colMinPercent)
  let couldNotInsert = false
  // margin of error for FP numbers
  while (remaining > 0.001) {
    if (map.every((a) => a === 0)) {
      couldNotInsert = true
      break
    }
    const amount = Math.min(remaining, 1)

    const distributed = distribute(newWidths, -amount, map, colMinPercent)
    newWidths[insertIndex] -= distributed

    map = calcDistributionMapInsert(newWidths, insertIndex, colMinPercent)

    remaining -= Math.abs(distributed)
  }
  if (couldNotInsert) {
    return colWidths
  }

  fixWidths(newWidths, colMinPercent)
  return newWidths
}

export const removeColWidth = (
  colWidths: number[],
  indicesToRemove: number[]
): number[] => {
  let amountToRemove = 0
  // must remove right to left
  const toRemove = indicesToRemove.sort((a, b) => b - a)
  const newWidths = [...colWidths]
  toRemove.forEach((ind) => {
    amountToRemove += colWidths[ind]
    newWidths.splice(ind, 1)
  })

  let map = calcDistributionMapRemove(newWidths)
  // margin of error for FP numbers
  while (amountToRemove > 0.001) {
    const amount = Math.min(amountToRemove, 1)

    const distributed = distribute(newWidths, amount, map)
    map = calcDistributionMapRemove(newWidths)

    amountToRemove -= Math.abs(distributed)
  }

  fixWidths(newWidths, 0)
  return newWidths
}

/**
 * Ensure widths always add up to 100
 */
export const fixWidths = (
  arr: number[],
  colMinPercent?: number,
  maxWidth = 100
) => {
  let total = 0
  arr.forEach((val, ind) => {
    const newVal = Math.round(val * 100)
    arr[ind] = newVal
    total += newVal
  })

  const toCorrect = maxWidth * 100 - total

  let toDistribute = Math.abs(toCorrect)
  let ind = 0
  while (toDistribute > 0) {
    const toAdd = toCorrect < 0 ? -1 : 1
    const arrInd = ind % arr.length
    if (
      colMinPercent !== undefined &&
      toAdd < 0 &&
      arr[arrInd] <= colMinPercent * 100
    ) {
      ind++
      continue
    }

    arr[arrInd] += toAdd
    ind++
    toDistribute--
  }
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i] / 100
  }
}
