import { Editor, Extension, findParentNodeClosestToPos } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { Transaction } from 'prosemirror-state'

import { featureFlags } from 'modules/featureFlags'
import {
  setSlideToSlideData,
  startSlideToSlide,
} from 'modules/performance/slideToSlidePerf'
import { getStore } from 'modules/redux'
import { getScrollManager } from 'modules/scroll'
import { setTextSelection } from 'modules/tiptap_editor/prosemirror-utils'
import { selectCardIdMap } from 'modules/tiptap_editor/reducer'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'
import { updateCardHash } from 'modules/tiptap_editor/utils/url'
import {
  getFirstParentWithHeight,
  getOffsetFromParent,
  __DEBUGGING_addDebuggingOutline,
} from 'utils/dom'

import { getNodeDOMNonText, getTopCenterIshNode } from '../../utils'
import {
  BETWEEN_CARD_TRANSITION_TIME,
  EXPAND_CARD_TRANSITION_TIME,
  findCardById,
  findCardNodeClosestToPos,
  findCollapsedCardNodeClosestToPos,
  findTopCardNodeParent,
  getCardBodySelector,
  openParentCards,
} from '../Card'
import { isCardCollapsed, setCardCollapsed } from '../Card/CardCollapse'
import { isExpandableOpen } from '../Expandable/utils'
import {
  SpotlightMeta,
  SpotlightPlugin,
  SpotlightPluginKey,
  SpotlightPluginState,
} from './spotlightPlugin'

const isPresentModeFlat = () => featureFlags.get('presentModeFlat')

/**
 * We are considered spotlighting by block if we have a spotlight pos and it points
 * to a node that is currently spotlightable.
 *
 * Note that cards are only spotlightable when they are direct children of the
 * currently presenting card. As such, the pos for the currently presenting card
 * (spotlight.cardId) is never spotlightable.
 */
export const spotlightingBlock = (editor: Editor, spotlight: SpotlightMeta) => {
  const spotlightNode = spotlight.pos
    ? editor.state.doc.nodeAt(spotlight.pos)
    : null
  return spotlightNode && isNodeSpotlightable(spotlightNode, spotlight.cardId)
}

type SyncSpotlightArgs = {
  spotlight: SpotlightMeta
  scroll?: {
    pos: number | null
    pct: number | null
  }
  isFollowing?: boolean
  scrollOffset?: number
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    spotlight: {
      // Spotlight update controls
      syncSpotlightAndScroll: (data: SyncSpotlightArgs) => ReturnType
      spotlightCardById: (cardId: string) => ReturnType
      spotlightCurrentCard: () => ReturnType
      spotlightNextCard: (
        reverse?: boolean,
        scrollTo?: 'top' | 'bottom' | null
      ) => ReturnType
      ascendUpToParentCard: (shouldWiggle?: boolean) => ReturnType
      descendIntoCurrentCard: (
        pos?: number,
        method?: 'push' | 'replace'
      ) => ReturnType
      spotlightNextBlock: (
        reverse?: boolean,
        from?: { pos: number; cardId: string },
        scrollBehavior?: ScrollBehavior
      ) => ReturnType
      turnOffSpotlight: (clearCardId?: boolean) => ReturnType

      // For present mode no nesting
      spotlightCollapseCard: (pos: number) => ReturnType
      spotlightExpandCard: (pos?: number | null) => ReturnType
      spotlightNextExpandedCard: (reverse?: boolean) => ReturnType

      // Scrolling controls
      scrollToNodeWithPin: (
        scrollToNode: HTMLElement,
        offset?: number
      ) => ReturnType
      scrollToPositionInCard: (
        pos: number,
        scrollPct?: number,
        offset?: number
      ) => ReturnType
    }
  }
}

const updateSpotlightState = ({
  tr,
  editor,
  spotlight,
  method = 'replace',
}: {
  tr: Transaction
  editor: Editor
  spotlight: SpotlightMeta
  method?: 'push' | 'replace'
}) => {
  // Store metadata on this transaction
  tr.setMeta(SpotlightPluginKey, spotlight)
  const lastSpotlight = SpotlightPluginKey.getState(
    editor.state
  ) as SpotlightPluginState
  // Update the card hash if we've navigated to a new card
  if (lastSpotlight.cardId !== spotlight.cardId && spotlight.cardId) {
    // NB: we MUST pass `emitChange: false` to prevent another URL change event
    // This is an "end of the line" update to the URL to keep it in sync with spotlight state
    // (this prevents a circular update loop)
    updateCardHash({ cardId: spotlight.cardId, method, emitChange: false })

    if (spotlight.pos) {
      const pos = spotlight.pos
      setTimeout(() => {
        editor.commands.command(({ tr }) => {
          setTextSelection(pos)(tr)
          return true
        })
      }, 10)
    }
  }

  // Update the awareness bus with our latest spotlight state
  editor.commands.user({ spotlight })
}

/**
 * Applies a "wiggle" to the card node of the cardId provided
 */
const doWiggle = (scrollerSelector: string, cardId?: string | null) => {
  if (!cardId) return
  const cardBodyNode = document.querySelector(
    `${scrollerSelector} ${getCardBodySelector(cardId)}`
  )

  if (!cardBodyNode) return
  cardBodyNode?.classList.add('cardWiggle')
  setTimeout(() => {
    cardBodyNode?.classList.remove('cardWiggle')
  }, 750)
}

/**
 * Helper to determine if were currently spotlighting a block and
 * if so, whether or not it is the first block in the card
 */
const spotlightBlockState = (editor: Editor, lastSpotlight: SpotlightMeta) => {
  const isSpotlightingBlock = spotlightingBlock(editor, lastSpotlight)
  const isSpotlightingFirstBlock =
    isSpotlightingBlock &&
    lastSpotlight.cardId &&
    lastSpotlight.pos &&
    // Were spotlighting a block now - check if its the first by looking for the next
    // node in reverse and checking if its card ID is different than the current one
    findNextNode(
      editor,
      lastSpotlight.cardId,
      lastSpotlight.pos,
      (n) => isNodeSpotlightable(n, lastSpotlight.cardId),
      true
    ).cardId !== lastSpotlight.cardId

  return { isSpotlightingBlock, isSpotlightingFirstBlock }
}

const isNodeSpotlightable = (
  node: Node,
  presentingCardId?: string | null
): boolean => {
  switch (node.type.name) {
    // Text nodes are spotlightable if they aren't empty
    case 'paragraph':
    case 'heading':
    case 'title':
    case 'math_display':
    case 'codeBlock':
      return node.textContent.trim().length > 0
    //  Lists are always spotlightable
    case 'bullet':
    case 'numbered':
    case 'todo':
      return true
    // Media/atom blocks are always spotlightable
    case 'embed':
    case 'video':
    case 'image':
    case 'drawing':
    case 'contributors':
    case 'tableOfContents':
      return true
    case 'expandableSummary':
      return true
    case 'expandableToggle':
      // Collapsed toggles are spotlightable
      // But when expanded, only their content is
      return !isExpandableOpen(node.attrs.id)
    case 'card': {
      const cardIdMap = selectCardIdMap(getStore().getState())
      const thisCardsParent = cardIdMap.parents[node.attrs.id].slice(-1)[0]

      if (isPresentModeFlat()) {
        // When nesting is disabled, cards are spotlightable only if they are
        // collapsed direct child cards of an expanded card
        return (
          isCardCollapsed(node) === true && // This card is collapsed
          isCardCollapsed(thisCardsParent) === false // And its parent is expanded
        )
      }
      // With nesting, cards are spotlightable only if they are direct children of the presenting card

      return thisCardsParent === presentingCardId
    }
    // Entire gridLayout or table is spotlightable (but not its content. See below)
    case 'gridLayout':
    case 'table':
    case 'buttonGroup':
      return true
    // Smart layout parts are spotlightable, but not their content
    case 'smartLayout':
      return false
    case 'smartLayoutCell':
      return true
    default:
      return false
  }
}

// Prevent descending into nodes that have "hidden" content from the perspective of present mode
export const isNodeContentSpotlightable = (node: Node): boolean => {
  switch (node.type.name) {
    case 'expandableToggle':
      return isExpandableOpen(node.attrs.id)
    case 'card':
      return !isCardCollapsed(node)
    case 'footnote':
    case 'gridLayout':
    case 'smartLayoutCell':
    case 'table':
      return false
    default:
      return true
  }
}

export const Spotlight = Extension.create({
  name: 'spotlight',

  addOptions() {
    return {
      scrollerSelector: 'body', // The parent scrolling container
    }
  },

  addCommands() {
    return {
      /**
       * Sync our local spotlight based on the passed values and
       * scroll to the block identified by spotlight.pos or scroll.pos
       * This is used when following someone.
       *
       * A couple notes:
       *  - This method should be idempotent so that theres no
       *    worry about calling it multiple times
       *  - Spotlight.pos takes precedence over scroll.pos
       *  - This method accounts for "back to following" where the
       *    local values may be totally out of sync as well as
       *    incremental updates while following
       */
      syncSpotlightAndScroll:
        ({ spotlight, scroll, scrollOffset, isFollowing = false }) =>
        ({ editor, view, tr }) => {
          const { pos, cardId } = spotlight
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          const cardIdChanged = lastSpotlight.cardId !== cardId

          if (pos) {
            const node = view.state.doc.nodeAt(pos)
            const domNode = view.nodeDOM(pos)

            if (node && domNode instanceof HTMLElement) {
              console.debug(
                `[Spotlight.syncSpotlight] Scrolling to specific card at pos: ${pos}`,
                domNode
              )
              const isPresentingCard =
                isCardNode(node) && node.attrs.id === cardId

              setTimeout(
                () =>
                  requestAnimationFrame(() => {
                    // If the target pos is the presenting card, scroll to the top
                    // because the card itself is the scroller
                    if (isPresentingCard) {
                      scrollToTop({})
                    } else {
                      scrollToBlock({
                        element: domNode,
                      })
                    }
                  }),
                cardIdChanged ? BETWEEN_CARD_TRANSITION_TIME : 0
              )
            }
          } else if (scroll?.pos) {
            const { pos: scrollPos, pct: scrollPct } = scroll
            setTimeout(
              () =>
                requestAnimationFrame(() => {
                  editor.commands.scrollToPositionInCard(
                    scrollPos,
                    scrollPct || undefined,
                    scrollOffset
                  )
                }),
              cardIdChanged ? BETWEEN_CARD_TRANSITION_TIME : 0
            )
          }
          startSlideToSlide({
            following: isFollowing,
          })
          setSlideToSlideData({
            cardId: cardId!,
          })
          updateSpotlightState({
            editor,
            tr,
            spotlight: {
              pos,
              cardId,
            },
          })
          return true
        },
      // Spotlight a specific card by its ID
      spotlightCardById:
        (cardId) =>
        ({ editor, view }) => {
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          const cardNode = findCardById(editor, cardId)
          if (!cardNode) return true
          const $pos = editor.state.doc.resolve(cardNode.pos)
          const isNestedAndPresentingFlat =
            $pos.depth > 1 && isPresentModeFlat()
          const cardToUse = isNestedAndPresentingFlat
            ? findTopCardNodeParent(editor, cardNode.pos)
            : cardNode

          if (cardToUse) {
            const cardIdChanged = lastSpotlight.cardId !== cardId
            if (isNestedAndPresentingFlat) {
              setCardCollapsed(cardId, false)
              openParentCards({
                pos: $pos.pos,
                editor,
              })
              // Scroll the nested expanded card into view since we wont descend into it
              const domNode = view.nodeDOM(cardNode.pos)
              if (domNode instanceof HTMLElement) {
                setTimeout(
                  () =>
                    requestAnimationFrame(() => {
                      scrollToBlock({
                        element: domNode,
                        behavior: 'smooth',
                      })
                    }),
                  cardIdChanged ? BETWEEN_CARD_TRANSITION_TIME : 0
                )
              }
            }
            return editor.commands.syncSpotlightAndScroll({
              spotlight: {
                pos: null,
                cardId: cardToUse.node.attrs.id,
              },
            })
          }
          return true
        },
      // Runs when you enter present mode from doc mode
      spotlightCurrentCard:
        () =>
        ({ editor, tr }) => {
          const fallbackToTop = () => {
            // Fallback to spotlighting the first card
            console.warn(
              '[Spotlight.spotlightCurrentCard] Couldnt find a card to spotlight, so using first card.'
            )
            const pos = 1 // Pos right before first card
            const firstCard = editor.view.state.doc.nodeAt(pos)
            if (!firstCard || !isCardNode(firstCard)) {
              console.error(
                '[Spotlight.spotlightCurrentCard] nodeAt(1) is unexpectedly not a card. Cannot spotlight'
              )
              return true
            }
            updateSpotlightState({
              editor,
              tr,
              spotlight: {
                pos: null,
                cardId: firstCard.attrs.id,
              },
            })
            return true
          }
          // This needs to be the doc root and not the card because we're getting position in the doc
          const rootEl = document.querySelector(this.options.scrollerSelector)
          if (!rootEl) return fallbackToTop()

          // Choose a card to spotlight
          // Start with the selection and jump into that card if it's in view
          // But if it's out of the viewport, use the top visible card
          let posToUse: number | undefined
          const cursorPos = editor.state.selection.from
          const cursorCoords = cursorPos && editor.view.coordsAtPos(cursorPos) // Relative to viewport

          if (
            cursorCoords &&
            cursorCoords.top < window.innerHeight &&
            cursorCoords.bottom > 0
          ) {
            posToUse = cursorPos
            console.debug(
              '%c [Spotlight.spotlightCurrentCard] Using cursor pos',
              'background-color: deeppink',
              { posToUse }
            )
          } else {
            const topCenterNodePos = getTopCenterIshNode(
              editor,
              this.options.scrollerSelector,
              DEFAULT_SCROLL_OFFSET + 10
            ).pos
            posToUse = topCenterNodePos?.pos
            console.debug(
              '%c [Spotlight.spotlightCurrentCard] Using top center pos',
              'background-color: deeppink',
              { posToUse }
            )
          }

          if (!posToUse || posToUse < 2) {
            // Fallback to the first card
            posToUse = 2
            console.warn(
              '%c [Spotlight.spotlightCurrentCard] Using fallback pos',
              'background-color: deeppink',
              { posToUse }
            )
          }

          const card = isPresentModeFlat()
            ? // With nesting off, find the top most parent card from posToUse (which could be right at posToUse)
              findTopCardNodeParent(editor, posToUse)
            : // With nesting on, find the card nearest the posToUse (which could be right at posToUse),
              // skipping collapsed cards
              findCollapsedCardNodeClosestToPos(editor, posToUse, false)

          if (!card || !card.pos) return fallbackToTop()

          if (isPresentModeFlat()) {
            openParentCards({ editor, pos: posToUse })
          }

          updateSpotlightState({
            editor,
            tr,
            spotlight: {
              pos: null,
              cardId: card.node.attrs.id,
            },
          })

          return true
        },

      // Move up one level if were presenting a nested card
      ascendUpToParentCard:
        (shouldWiggle = true) =>
        ({ editor, view, tr }) => {
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          let posToUse: number | null | undefined = lastSpotlight.pos
          if (!posToUse) {
            // Were trying to go up to the parent card without a current spotlight pos.
            const topCenterNodePos = getTopCenterIshNode(
              editor,
              this.options.scrollerSelector,
              window.innerHeight / 2
            ).pos
            posToUse = topCenterNodePos?.pos
          }
          if (!posToUse) return false

          const node = editor.view.state.doc.nodeAt(posToUse)
          // Find the card thats currently being presented (which could be right at posToUse)
          const presentingCard = findCollapsedCardNodeClosestToPos(
            editor,
            posToUse,
            false
          )

          if (isPresentModeFlat()) {
            // Cannot ascend from non nested present mode, so wiggle
            if (presentingCard && shouldWiggle) {
              doWiggle(this.options.scrollerSelector, lastSpotlight.cardId)
              return true
            }
            return false
          }

          if (!presentingCard) return false

          const parentOfPresentingCard = findParentNodeClosestToPos(
            editor.state.doc.resolve(presentingCard.pos),
            isCardNode
          )
          if (parentOfPresentingCard) {
            const domNode = view.nodeDOM(presentingCard.pos) as
              | HTMLElement
              | null
              | undefined
            if (!domNode) {
              console.warn(
                '[Spotlight.ascendUpToParentCard] Cant find dom node',
                {
                  domNode,
                  presentingCard,
                  parentOfPresentingCard,
                  node,
                  lastSpotlight,
                }
              )
              return false
            }

            const cardId = parentOfPresentingCard.node.attrs.id
            const { isSpotlightingBlock } = spotlightBlockState(
              editor,
              lastSpotlight
            )

            if (isSpotlightingBlock) {
              // Maintain spotlight by block mode via choosing the first block of the next card
              return editor.commands.spotlightNextBlock(false, {
                pos: parentOfPresentingCard.pos,
                cardId,
              })
            }

            setSlideToSlideData({
              cardId,
            })
            updateSpotlightState({
              editor,
              tr,
              spotlight: {
                pos: null,
                cardId,
              },
            })

            withDurationPin(
              scrollToBlock,
              EXPAND_CARD_TRANSITION_TIME
            )({
              element: domNode,
            })
          } else {
            if (presentingCard && shouldWiggle) {
              // We tried to ascend up but weren't able to find a card, which means were
              // at one of the ends of the memo. Do a little wiggle to provide this feedback
              doWiggle(
                this.options.scrollerSelector,
                presentingCard.node.attrs.id
              )
            }
            return false
          }

          return true
        },
      // Descend into a nested card by spotlighting it.
      // If the currently spotlighted node is a card that is not the
      // one currently being presented, set it to the active card
      descendIntoCurrentCard:
        (pos?: number, method: 'push' | 'replace' = 'replace') =>
        ({ editor, tr }) => {
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          const posToUse: number | null | undefined = pos || lastSpotlight.pos

          if (isPresentModeFlat()) {
            return editor.commands.spotlightExpandCard(posToUse)
          }

          if (!posToUse) return true

          const node = editor.view.state.doc.nodeAt(posToUse)
          const shouldDescend =
            node && isCardNode(node) && node.attrs.id !== lastSpotlight.cardId

          if (!shouldDescend) return true

          const cardId = node.attrs.id
          const { isSpotlightingBlock } = spotlightBlockState(
            editor,
            lastSpotlight
          )

          console.debug('[Spotlight.descendIntoCurrentCard]', {
            node,
            isSpotlightingBlock,
          })

          if (isSpotlightingBlock) {
            // Maintain spotlight by block mode via choosing the first block of the next card
            return editor.commands.spotlightNextBlock(false, {
              pos: posToUse,
              cardId,
            })
          }

          updateSpotlightState({
            editor,
            tr,
            spotlight: { pos: null, cardId },
            method,
          })

          setTimeout(() => {
            // Were switching cards, so delay the scroll until we get to the next card
            requestAnimationFrame(() => {
              // Scroll to the top
              scrollToTop({})
            })
          }, BETWEEN_CARD_TRANSITION_TIME)

          return true
        },
      spotlightCollapseCard:
        (pos) =>
        ({ editor, tr }) => {
          if (!isPresentModeFlat()) {
            return editor.commands.ascendUpToParentCard()
          }
          const node = editor.view.state.doc.nodeAt(pos)
          if (!node || !isCardNode(node)) {
            console.warn(
              '[Spotlight.spotlightCollapseCard] pos does not resolve to a card node. This is a noop',
              pos
            )
            return true
          }
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          const cardId = node.attrs.id
          setCardCollapsed(cardId, true)

          // Note that we didnt change the spotlight state, but we still call
          // update to trigger the decorations to recompute, which ensures that the
          // card that was just collapsed appears correctly
          updateSpotlightState({
            editor,
            tr,
            spotlight: lastSpotlight,
          })
          return true
        },
      spotlightExpandCard:
        (pos) =>
        ({ editor }) => {
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState

          const node = pos && editor.view.state.doc.nodeAt(pos)
          if (!node || !isCardNode(node)) {
            console.warn(
              '[Spotlight.spotlightExpandCard] pos does not resolve to a card node. This is a noop',
              pos
            )
            return true
          }
          const cardId = node.attrs.id
          const { isSpotlightingBlock } = spotlightBlockState(
            editor,
            lastSpotlight
          )

          setCardCollapsed(cardId, false)

          console.debug('[Spotlight.spotlightExpandCard]', {
            node,
            isSpotlightingBlock,
          })
          // Wait for the transition to complete
          setTimeout(() => {
            if (isSpotlightingBlock) {
              // Maintain spotlight by block mode via choosing the first block of the next card
              editor.commands.spotlightNextBlock(
                false,
                {
                  pos,
                  cardId,
                },
                'smooth'
              )
            } else {
              editor.commands.scrollToPositionInCard(pos)
            }
          }, EXPAND_CARD_TRANSITION_TIME)

          return true
        },
      spotlightNextExpandedCard:
        (reverse) =>
        ({ editor }) => {
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          if (!lastSpotlight.pos || !lastSpotlight.cardId) return true

          const topParentCard = findTopCardNodeParent(editor, lastSpotlight.pos)
          const parentCard = findCardNodeClosestToPos(editor, lastSpotlight.pos)
          if (!topParentCard || !parentCard) return true

          // Find the next expanded card in the direction indicated,
          // which could be a nested card in a different top level card
          const result: NextNodeResult = {
            node: null,
            pos: null,
            cardId: null,
            domNode: null,
          }
          const from = lastSpotlight.pos
          editor.state.doc.descendants((n, pos, _parent) => {
            if (!reverse && result.pos) return false
            const isExpanded = !isCardCollapsed(n)
            const baseMatch =
              isCardNode(n) && // Its a card
              isExpanded && // That is expanded
              (reverse ? pos < from : pos > from) // And is in the range

            // Bail early if the base criteria arent met
            if (!baseMatch) return isExpanded

            // We found an expanded card in range. Lets see if we should skip it or not
            const isDirectParentOfCurrentBlock = n === parentCard.node

            // If it's a different card OR were going forward, its eligible
            let parentCardMatch = !isDirectParentOfCurrentBlock || !reverse
            if (!parentCardMatch) {
              // The match is our parent and were going backwards. It should only be true if its not the first block
              const { isSpotlightingFirstBlock } = spotlightBlockState(editor, {
                pos: from,
                cardId: n.attrs.id,
              })
              parentCardMatch = !isSpotlightingFirstBlock
            }

            if (parentCardMatch) {
              result.node = n
              result.pos = pos
              result.cardId = n.attrs.id
            }
            return isExpanded
          })

          if (!result.pos || !result.cardId) {
            doWiggle(this.options.scrollerSelector, topParentCard.node.attrs.id)
            return true
          }

          // Spotlight the first block of the next expanded card we found
          return editor.commands.spotlightNextBlock(false, {
            pos: result.pos,
            cardId: result.cardId,
          })
        },
      spotlightNextCard:
        (reverse, scrollTo = 'top') =>
        ({ editor, tr }) => {
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          let posToUse: number | null | undefined = lastSpotlight.pos
          const { isSpotlightingBlock, isSpotlightingFirstBlock } =
            spotlightBlockState(editor, lastSpotlight)

          if (isSpotlightingBlock && isPresentModeFlat()) {
            return editor.commands.spotlightNextExpandedCard(reverse)
          }

          if (!posToUse) {
            // Were trying to go to the next card without a current spotlight pos.
            const cardNode = findCardById(editor, lastSpotlight.cardId)
            posToUse = cardNode?.pos
          }
          if (!posToUse) {
            console.warn(
              '[Spotlight.spotlightNextCard] Could not find posToUse',
              {
                lastSpotlight,
              }
            )
            return true
          }

          const node = editor.view.state.doc.nodeAt(posToUse)

          // If were spotlighting a block and want to select the next card in
          // reverse, we should choose the first block of the enclosing card
          const shouldSelectEnclosingCard = Boolean(
            node &&
              reverse && // Going in reverse
              isSpotlightingBlock && // Were spotlighting a block
              !isSpotlightingFirstBlock && // But its not the first block
              node.attrs.id !== lastSpotlight.cardId // The node were spotlighting isnt the currentCard
          )

          const enclosingCard =
            node &&
            lastSpotlight.cardId &&
            node.attrs.id === lastSpotlight.cardId
              ? { node, pos: posToUse }
              : findParentNodeClosestToPos(
                  editor.state.doc.resolve(posToUse),
                  isCardNode
                )

          const next = shouldSelectEnclosingCard
            ? enclosingCard
            : enclosingCard?.pos
            ? findNextDirectSiblingCard(editor, enclosingCard.pos, reverse)
            : null

          if (next && next.pos !== null) {
            console.debug('[Spotlight.spotlightNextCard] Found next card:', {
              next,
              posToUse,
              isSpotlightingBlock,
            })

            const cardId = next.node.attrs.id
            const pos = next.pos

            if (isSpotlightingBlock) {
              return editor.commands.spotlightNextBlock(false, { pos, cardId })
            }

            setSlideToSlideData({ cardId })
            updateSpotlightState({
              editor,
              tr,
              spotlight: { pos: null, cardId },
            })

            // Scroll to the top
            const switchedCards = lastSpotlight.cardId !== cardId
            const scrollFn = scrollTo == 'top' ? scrollToTop : scrollToBottom
            if (scrollTo !== null) {
              setTimeout(
                () => {
                  scrollFn({
                    sync: true,
                    behavior: 'auto',
                  })
                },
                switchedCards ? BETWEEN_CARD_TRANSITION_TIME : 0
              )
            }
          } else {
            console.debug(
              '[Spotlight.spotlightNextCard] No next node. Will attempt to ascendUp',
              { lastSpotlight, posToUse, node }
            )
            // At the end of a list. Navigate back up to the parent if possible
            editor.commands.ascendUpToParentCard()
          }

          return true
        },
      spotlightNextBlock:
        (reverse, from, scrollBehavior) =>
        ({ editor, tr }) => {
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          if (!lastSpotlight.cardId) return true

          const fromCardId = from?.cardId || lastSpotlight.cardId
          const fromPos = from?.pos || lastSpotlight.pos

          const {
            pos,
            cardId: foundCardId,
            domNode,
          } = fromPos
            ? // We have a from pos already, so find the next node from there
              findNextNode(
                editor,
                fromCardId,
                fromPos,
                isNodeSpotlightable,
                reverse
              )
            : // spotlight.pos is null, so pick a node to spotlight using the helper
              pickNodeToSpotlight(
                editor,
                lastSpotlight.cardId,
                this.options.scrollerSelector,
                reverse
              )

          // If we find one, move there
          if (pos !== null && domNode instanceof HTMLElement) {
            const cardId = isPresentModeFlat()
              ? // With nesting off, find the top most parent card from posToUse (which could be right at posToUse)
                findTopCardNodeParent(editor, pos)?.node.attrs.id
              : // With nesting on, find the card nearest the posToUse (which could be right at posToUse),
                // skipping collapsed cards
                foundCardId
            if (!cardId) return true

            setSlideToSlideData({ cardId })
            console.debug(
              '%c [Spotlight.spotlightNextBlock]',
              'background-color: deeppink',
              {
                prevCardId: lastSpotlight.cardId,
                cardId,
                pos,
              }
            )

            // If We've switched cards, wait for transition
            setTimeout(
              () => {
                scrollToBlock({
                  element: domNode,
                  behavior: scrollBehavior,
                })
              },
              lastSpotlight.cardId === cardId ? 0 : BETWEEN_CARD_TRANSITION_TIME
            )

            updateSpotlightState({
              editor,
              tr,
              spotlight: { pos, cardId },
            })
          } else {
            // At the end of a list. Navigate back up to the parent if possible
            editor.commands.ascendUpToParentCard()
          }
          return true
        },
      turnOffSpotlight:
        (clearCardId = false) =>
        ({ editor, tr }) => {
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          updateSpotlightState({
            editor,
            tr,
            spotlight: {
              pos: null,
              cardId: clearCardId ? null : lastSpotlight.cardId,
            },
          })
          return true
        },

      /**
       * Scrolls to a certain node and a certain pct down that node.
       * Used to sync scroll position when following someone.
       * useCardAsScroller controls whether the scrollable container
       * is the card (present mode) or the top level doc (doc mode)
       */
      scrollToPositionInCard:
        (pos, scrollPct = 0, offset = 0) =>
        ({ editor }) => {
          const nodeToScrollTo = getFirstParentWithHeight(
            getNodeDOMNonText(editor, pos)
          )
          const nodeToScrollToRect = nodeToScrollTo?.getBoundingClientRect()

          if (!nodeToScrollTo || !nodeToScrollToRect) return true

          // This adds additional pixels based on the scrollPct
          // E.g. as the presenter scrolls down an image, the follower does too
          const pctOffsetPx = (scrollPct || 0) * nodeToScrollToRect.height

          console.debug(
            '%c [Spotlight.scrollToPositionInCard]',
            'background-color: deeppink',
            JSON.stringify({ pos, offset: offset - pctOffsetPx }),
            nodeToScrollTo
          )

          __DEBUGGING_addDebuggingOutline({
            element: nodeToScrollTo,
            color: 'deeppink',
            requiredCookie: 'spotlightScrollDebug=true',
          })

          scrollToBlock({
            element: nodeToScrollTo,
            offset: offset - pctOffsetPx,
          })
          return true
        },

      scrollToNodeWithPin: (scrollToNode, offset) => () => {
        console.debug(
          '%c [Spotlight.scrollToNodeWithPin] WITH NODE',
          'background-color: deeppink',
          {
            scrollToNode,
          }
        )
        withDurationPin(
          scrollToBlock,
          EXPAND_CARD_TRANSITION_TIME,
          0
        )({
          element: scrollToNode,
          offset,
        })

        return true
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      SpotlightPlugin((node, cardId) => isNodeSpotlightable(node, cardId)),
    ]
  },
})

/**
 * Find the card directly in front or behind the card closest to the
 * given position. Note that the card must be immediately adjacent
 * to the current card (a direct sibling) to be considered a match.
 */
const findNextDirectSiblingCard = (
  editor: Editor,
  pos: number,
  reverse = false
) => {
  const node = editor.view.state.doc.nodeAt(pos)
  const card =
    node && isCardNode(node)
      ? { node, pos }
      : findParentNodeClosestToPos(editor.state.doc.resolve(pos), isCardNode)

  if (!card) return

  const $pos = editor.state.doc.resolve(
    reverse ? card.pos : card.pos + card.node?.nodeSize
  )
  const nextNode = reverse ? $pos.nodeBefore : $pos.nodeAfter

  if (!nextNode) return

  const nextPos = reverse ? $pos.pos - nextNode.nodeSize : $pos.pos
  const nextNodeIsCard = isCardNode(nextNode)

  if (!nextNodeIsCard) return

  console.debug('[Spotlight.findNextDirectSiblingCard]', { nextNode, nextPos })

  return { node: nextNode, pos: nextPos }
}

type NodeDomResult = HTMLElement | null | undefined
interface NextNodeResult {
  node: Node | null
  pos: number | null
  cardId: string | null
  domNode: NodeDomResult
}

/**
 * Finds the node closest to the top center of the viewport and from there,
 * finds the most logical node to spotlight, handling cases where a node is
 * inside a layout element/table or is not spotlightable.
 */
const pickNodeToSpotlight = (
  editor: Editor,
  cardId: string,
  scrollerSelector: string,
  reverse = false
): NextNodeResult => {
  const result: NextNodeResult = {
    node: null,
    pos: null,
    cardId: null,
    domNode: null,
  }

  const topCenterNode = getTopCenterIshNode(
    editor,
    scrollerSelector,
    DEFAULT_SCROLL_OFFSET + 10
  )
  const pos = topCenterNode.pos?.inside || null
  const node = pos ? editor.state.doc.nodeAt(pos) : null
  if (!pos || !node) {
    return result
  }

  if (node.attrs.id === cardId) {
    // If the topCenterNode the presenting card itself, find the next node from there.
    return findNextNode(
      editor,
      cardId,
      pos,
      (n) => isNodeSpotlightable(n, cardId),
      reverse
    )
  }

  const thisPos = editor.state.doc.resolve(pos)
  const containingCard = findCardNodeClosestToPos(editor, thisPos.before())
  if (!containingCard) {
    return result
  }

  // Find the block that our node rolls up to inside the card
  const containingBlockPos = thisPos.before(containingCard.depth + 2)
  const containingBlock = editor.state.doc.nodeAt(containingBlockPos)

  // If the containing block itself is spotlightable, use it!
  if (containingBlock && isNodeSpotlightable(containingBlock, cardId)) {
    const $pos = editor.state.doc.resolve(containingBlockPos)
    result.pos = $pos.pos
    result.node = containingBlock
    result.cardId = containingCard?.node.attrs.id
    result.domNode = editor.view.nodeDOM(result.pos) as NodeDomResult
    return result
  }

  // Otherwise, find the next node from there
  return findNextNode(
    editor,
    cardId,
    containingBlockPos,
    (n) => isNodeSpotlightable(n, cardId),
    reverse
  )
}

/*
 * Finds the next node that matches predicate,
 * starting at the position from
 * or previous if reverse is true
 */
const findNextNode = (
  editor: Editor,
  cardId: string,
  from: number,
  predicate: (node: Node, parentCardId: string) => boolean,
  reverse = false
): NextNodeResult => {
  const result: NextNodeResult = {
    node: null,
    pos: null,
    cardId: null,
    domNode: null,
  }
  const $from = editor.state.doc.resolve(from)
  const node = editor.view.state.doc.nodeAt(from)
  const parentNode = findParentNodeClosestToPos(
    $from,
    (n) => isCardNode(n) && n.attrs.id === cardId
  )

  const card =
    node && isCardNode(node) && node?.attrs.id === cardId
      ? { node, pos: from }
      : parentNode

  if (!card) {
    console.debug('[Spotlight.findNextNode] - No card found:', {
      cardId,
      from,
      node,
    })
    return result
  }

  const findMatchInCard = (cardNode: Node, startingPos: number) => {
    const cardId = cardNode.attrs.id
    cardNode.descendants((n, cardPos) => {
      const pos = startingPos + cardPos + 1
      const match = predicate(n, cardId) && (reverse ? pos < from : pos > from)

      // If we already have a match going forward, bail
      if (!reverse && result.node) return false
      // Check for this node to match
      if (match) {
        result.node = n
        result.pos = pos
        result.cardId = cardNode.attrs.id
        return false
      }

      // Prevent descending into cards to find a match
      return isNodeContentSpotlightable(n)
    })
  }

  // Look for a match in the current card
  findMatchInCard(card.node, card.pos)
  if (result.pos === null) {
    // If we dont find one there, check the next card
    const next = findNextDirectSiblingCard(editor, card.pos, reverse)
    if (next) {
      findMatchInCard(next.node, next.pos)
    }
  }

  if (result.pos !== null) {
    result.domNode = editor.view.nodeDOM(result.pos) as NodeDomResult
  }

  console.debug('[Spotlight.findNextNode]', { result })
  return result
}

const SCROLL_WITH_PIN_TRANSITIONEND_BUFFER = 300

const withDurationPin =
  (method: typeof scrollToBlock, duration: number = 0, delay: number = 0) =>
  (options: ScrollToBlock) => {
    const optionsToUse = {
      ...options,
      // This technique requires synchronous non-smooth scrolling
      sync: true,
      behavior: 'auto' as ScrollBehavior,
    }
    // Scroll immediately with the provided options
    method(optionsToUse)
    // Listen for a transitionend event, but bail if it doesnt happen fast enough
    let transitionEndChecked = false
    let transitionEndCallback: () => any
    Promise.race([
      new Promise((r) =>
        setTimeout(
          () => r(false),
          delay + duration + SCROLL_WITH_PIN_TRANSITIONEND_BUFFER
        )
      ),
      new Promise((r) => {
        transitionEndCallback = () => r(true)
        options.element.addEventListener('transitionend', transitionEndCallback)
      }),
    ])
      .then((result) => {
        console.debug(
          '[scrollTo withDurationPin] transitionend race result',
          result
        )
        transitionEndChecked = true
      })
      .finally(() => {
        options.element.removeEventListener(
          'transitionend',
          transitionEndCallback
        )
      })
    const startTime = +new Date()
    // Keep the element pinned to its scroll position for the
    // duration requested using a recursive requestAnimationFrame loop
    const scrollOnAnimation = () =>
      requestAnimationFrame(() => {
        // The pinning technique must be sync and not smooth
        method(optionsToUse)
        const elapsed = +new Date() - startTime
        // Recursively invoke the scroll until duration+delay has elapsed
        // and we've had enough time to check for the transitionend event
        if (elapsed < duration + delay || !transitionEndChecked) {
          scrollOnAnimation()
        }
      })
    setTimeout(scrollOnAnimation, delay)
  }

interface ScrollToBlock {
  element: HTMLElement
  sync?: boolean
  behavior?: ScrollBehavior
  offset?: number
}

// The ideal position for scrolling a block to the top is the target block being 150px down the page.
// This is roughly the middle of a title block at the top of a card.
// While this is a bit arbitrary, it ensures that this works on varying screen heights.
const DEFAULT_SCROLL_OFFSET = 150

/*
 * Scrolls a block into view at the top of the page, computing the offset
 * from the top of the scroll container for the editor
 */
const scrollToBlock = ({
  element,
  sync = false,
  behavior = 'smooth',
  offset = DEFAULT_SCROLL_OFFSET,
}: ScrollToBlock) => {
  const scrollManager = getScrollManager('editor')
  const offsetFromTopOfScroller = getOffsetFromParent(
    element,
    scrollManager.scrollSelector
  )
  const idealScroll = offsetFromTopOfScroller - offset

  scrollManager.scrollTo({ top: idealScroll, sync, behavior })
}

// Helper to scroll the editor to the top of a card
const scrollToTop = ({
  sync = false,
  behavior = 'smooth',
}: {
  sync?: boolean
  behavior?: ScrollBehavior
}) => {
  getScrollManager('editor').scrollTo({
    top: 0,
    behavior,
    sync,
  })
}

const scrollToBottom = ({
  sync = false,
  behavior = 'smooth',
}: {
  sync?: boolean
  behavior?: ScrollBehavior
}) => {
  const sm = getScrollManager('editor')
  const scroller = sm.scroller
  if (!scroller) return

  sm.scrollTo({
    top: scroller.scrollHeight,
    behavior,
    sync,
  })
}
