import { Box, Button, Flex, VStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeViewProps } from '@tiptap/react'
import { motion } from 'framer-motion'
import { useCallback, useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { getThemeCSSVars } from 'modules/theming'
import { useEditorFocused } from 'modules/tiptap_editor/hooks'
import {
  selectContentEditable,
  selectExpandedDrawingId,
  selectTheme,
} from 'modules/tiptap_editor/reducer'
import { TldrawSvg } from 'modules/tldraw/TldrawSvg'

import { getContainerOptions } from '../../styles/containerStyles'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { getCardWidth } from '../Card/CardPlugin'
import { getFlexAlign } from '../HorizontalAlign'
import { MOVEABLE_WRAPPER_CLASSNAME } from '../media'
import { ResizableControls, useResizeable } from '../media/Resizeable'
import { DrawingEditor } from './DrawingEditor'
import { setDrawingExpanded } from './utils'

import { DrawingAttrs } from '.'

const MotionBox = motion(Box)

export const DrawingView = (nodeViewProps: NodeViewProps) => {
  const { node, updateAttributes, selected, editor, getPos, decorations } =
    nodeViewProps
  const { width, isFullWidth, meta, id, horizontalAlign, svg, page } =
    node.attrs as DrawingAttrs

  const isEditable = useAppSelector(selectContentEditable)
  const isEditorFocused = useEditorFocused(editor)
  const editingEnabled = isEditable && isEditorFocused
  const expandedDrawingId = useAppSelector(selectExpandedDrawingId)
  const isEditing = expandedDrawingId ? expandedDrawingId === id : false
  const [isDrawingEditorActive, setDrawingEditorActive] = useState(false)
  const theme = useAppSelector(selectTheme)
  // todo: get this from a decoration instead so it can be card level
  const { isDark } = getContainerOptions(theme)
  const font = getThemeCSSVars(theme)['--body-font']

  const {
    ref,
    isResizing,
    setIsResizing,
    resizeableSx,
    onLayoutAnimationStart,
    onLayoutAnimationComplete,
  } = useResizeable<HTMLDivElement>(editor)

  // Handle double click
  const openDrawingEditor = useCallback(() => {
    setDrawingExpanded(id, true)
  }, [id])

  const selectNode = useCallback(() => {
    editor.chain().setNodeSelection(getPos()).focus().run()
  }, [editor, getPos])

  const onDrawingEditorClose = useCallback(() => {
    setDrawingExpanded(null, false)
  }, [])

  const updateResizeAttrs = useCallback(
    (resizeAttrs: { width: number }, setFullWidth?: boolean) => {
      updateAttributes({
        ...node.attrs,
        isFullWidth:
          setFullWidth === undefined ? node.attrs.isFullWidth : setFullWidth,
        width: resizeAttrs.width,
      })
    },
    [node.attrs, updateAttributes]
  )

  const { widthPx: cardWidthPx, isFullWidth: isCardFullWidth } =
    getCardWidth(decorations)
  const widthPx = isFullWidth ? cardWidthPx : width || meta?.width
  const clickShouldZoom = !isEditable
  const showPlaceholder = !svg
  const hasShapes = page && Object.keys(page.shapes).length > 0
  // Max size in full width cards
  const isContainedWidth =
    isCardFullWidth && !width && !isFullWidth && !isResizing
  // Default drawings in full width cards to centered
  const wrapperAlign = isCardFullWidth
    ? getFlexAlign(horizontalAlign || 'center')
    : getFlexAlign(horizontalAlign)

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps} as="div">
      <Flex
        w="100%"
        justify={wrapperAlign}
        className={MOVEABLE_WRAPPER_CLASSNAME}
        sx={resizeableSx}
        _focusWithin={{
          shadow: 'outline',
        }}
      >
        <MotionBox
          ref={ref}
          className="drawing"
          borderRadius="var(--block-border-radius)"
          data-content-reference
          w={
            isContainedWidth
              ? `var(--max-text-width)`
              : `calc(${widthPx}px * var(--zoom-factor))`
          }
          h="auto"
          position="relative"
          layout
          layoutDependency={horizontalAlign}
          transition={{
            duration: 0.15,
          }}
          onLayoutAnimationStart={onLayoutAnimationStart}
          onLayoutAnimationComplete={onLayoutAnimationComplete}
          minH={showPlaceholder ? '6em' : 'auto'}
          data-drag-handle
        >
          {editingEnabled && selected && (
            <ResizableControls
              imageWrapperRef={ref}
              setIsResizing={setIsResizing}
              updateResizeAttrs={updateResizeAttrs}
              refreshDeps={[node.attrs]}
              maxWidth={cardWidthPx}
            />
          )}
          {/* Tldraw does a bunch of ID selector checks that break when the SVG is rendered ahead of the editor in the DOM. So we hide the SVG whenever you're actively editing. */}
          {!isDrawingEditorActive && (
            <TldrawSvg font={font} svg={svg} isDark={isDark} />
          )}
          {/* Capture clicks */}
          {!isEditing && !showPlaceholder && (
            <Box
              cursor={clickShouldZoom ? 'zoom-in' : 'default'}
              position="absolute"
              inset="0"
              zIndex="1"
              onClick={clickShouldZoom ? openDrawingEditor : selectNode}
              onDoubleClick={openDrawingEditor}
            />
          )}
          {/* Placeholder */}
          {showPlaceholder && (
            <VStack
              spacing={3}
              justify="center"
              contentEditable={false}
              color={'gray.400'}
              h={32}
              backgroundColor="blackAlpha.50"
              borderRadius="var(--block-border-radius)"
              data-testid="drawing-placeholder"
            >
              <FontAwesomeIcon size="2x" icon={regular('shapes')} />
              {isEditable && (
                <Button size="xs" onClick={openDrawingEditor}>
                  {hasShapes ? 'Open diagram' : 'Add diagram'}
                </Button>
              )}
            </VStack>
          )}
        </MotionBox>
        {isEditing && (
          <DrawingEditor
            {...nodeViewProps}
            isOpen={isEditing}
            isEditable={isEditable}
            onClose={onDrawingEditorClose}
            isDark={isDark}
            font={font}
            isDrawingEditorActive={isDrawingEditorActive}
            setDrawingEditorActive={setDrawingEditorActive}
          />
        )}
      </Flex>
    </AnnotatableNodeViewWrapper>
  )
}
