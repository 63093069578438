import { getSchema } from '@tiptap/core'

import { getBaseExtensions } from '.'

/**
 * A hand maintained version of the schema that we can manually
 * update and sync across clients, forcing them to refresh when
 * a newer one is detected to avoid data loss.
 */
// For DEBUGGING only - Should remove once we verify this works to
// prevent actual docs from indicating unrealistic versions.
const queryParams = new URLSearchParams(
  process.browser ? window.location.search : ''
)
const DEBUG_VERSION = queryParams.get('SCHEMA_VERSION')
  ? Number(queryParams.get('SCHEMA_VERSION'))
  : null

export const SCHEMA_VERSION = DEBUG_VERSION || 55

export const getSerializableSchema = () => {
  const { spec } = getSchema(getBaseExtensions())
  const marks: any[] = []
  const nodes: any[] = []

  // @ts-ignore
  spec.marks?.forEach((key: string, value: any) => {
    // https://prosemirror.net/docs/ref/#model.MarkSpec
    // See comment below on nodes
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { parseDOM, toDOM, excludes, inclusive, ...schemaValues } = value
    /* eslint-enable @typescript-eslint/no-unused-vars */
    marks.push({
      name: key,
      ...schemaValues,
    })
  })

  // @ts-ignore
  spec.nodes?.forEach((key: string, value: any) => {
    // https://prosemirror.net/docs/ref/#model.NodeSpec
    // Not all parts of the node spec are worth refreshing for -
    // only ones that would change the content. This pulls out
    // values that aren't content essential so we only need to
    // bump schema when it's worth forcing a refresh.
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      parseDOM,
      toDOM,
      draggable,
      isolating,
      selectable,
      ...schemaValues
    } = value
    /* eslint-enable @typescript-eslint/no-unused-vars */
    nodes.push({
      name: key,
      ...schemaValues,
    })
  })
  return {
    topNode: spec.topNode,
    marks,
    nodes,
  }
}
