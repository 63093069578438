import {
  BackgroundOptions,
  DEFAULT_DOC_BACKGROUND,
} from '../../../styles/backgroundStyles'

export interface DocumentAttributes {
  docId: string | null
  theme: string | null
  background: BackgroundOptions
}

export const defaultAttrs = {
  docId: null,
  theme: 'classic', // This is the theme we'll backfill for all old docs. New ones will have a value filled via defaultDocs/empty.ts
  background: DEFAULT_DOC_BACKGROUND,
} as const
