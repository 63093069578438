import { useEffect } from 'react'

export const useScriptInnerHTML = (enabled: boolean, innerHTML: string) => {
  useEffect(() => {
    if (!enabled || !innerHTML) return

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = innerHTML
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [enabled, innerHTML])
}

export const useScript = (url: string, callback = () => {}) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.defer = true
    script.onload = callback
    script.onerror = (event: Event) => {
      console.error(
        `Failed to load script from ${(event?.target as HTMLScriptElement).src}`
      )
    }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url, callback])
}
