import Uppy, { UppyOptions } from '@uppy/core'
import Transloadit, { Assembly, Result } from '@uppy/transloadit'
import Url from '@uppy/url'
import mimeTypes from '@uppy/utils/lib/mimeTypes'
import { Dispatch, SetStateAction } from 'react'

import { config } from 'config'
import { EventEmitter } from 'utils/EventEmitter'
import { isDocUpload, isPDFFileType } from 'utils/file'
import { isHEICFileType } from 'utils/image'

import { OnUploadStartParams } from '../components/UploadBox'
import { ImageUploadResult } from '../types/ImageUpload'

// Based on https://gamma.app/docs/9qnli7y5b4fz092
const BACKGROUND_IMAGE_MAX_SIZE = 3840
const NODE_IMAGE_MAX_SIZE = 2560
const ICON_IMAGE_MAX_SIZE = 250

export const UPPY_PARAMS = {
  auth: { key: config.TRANSLOADIT_AUTH_KEY },
  template_id: config.TRANSLOADIT_TEMPLATE_ID,
}

type ImageUploadEvents = {
  uploadComplete: {
    src: string
    meta: Record<string, any>
    name: string
    thumbnail: string
    type: string
  }
}

export const TransloaditEventEmitter = new EventEmitter<ImageUploadEvents>()

// Supported image types
export const imageTypes = ['image/*', mimeTypes.heic, mimeTypes.heif]

// Supported doc types
export const docTypes = [
  mimeTypes.doc,
  mimeTypes.docx,
  mimeTypes.pdf,
  '.ppt',
  '.pptx',
]

export type ImageType = 'background' | 'icon' | 'node'

export type UploadFileType = 'image' | 'doc' | 'all'

const fileToTypes: Record<UploadFileType, string[]> = {
  all: [...docTypes, ...imageTypes],
  doc: docTypes,
  image: imageTypes,
}

export const UPPY_CONFIG: UppyOptions = {
  autoProceed: true,
  restrictions: {
    allowedFileTypes: [],
    maxNumberOfFiles: 1,
  },
}

export type UppyUploadHandlers = {
  onUploadStart?: (params: OnUploadStartParams) => void
  onOriginalFileUpload?: (
    uploadResult: ImageUploadResult,
    previousUrl?: string
  ) => void
  onUploadComplete?: (
    uploadResult: ImageUploadResult,
    previousUrl?: string
  ) => void
  onUploadFailed?: (message?: string) => void
  setErrorMessage?: Dispatch<SetStateAction<string>>
}

// This needs to be exported via UppyUploader to allow stubbing in tests
const createUppyInstance = (
  orgId: string,
  {
    onUploadStart,
    onOriginalFileUpload,
    onUploadComplete,
    onUploadFailed,
    setErrorMessage,
  }: UppyUploadHandlers,
  imageType: ImageType = 'node',
  uploadType?: UploadFileType
) => {
  let resizePx = NODE_IMAGE_MAX_SIZE

  if (imageType === 'background') {
    resizePx = BACKGROUND_IMAGE_MAX_SIZE
  } else if (imageType === 'icon') {
    resizePx = ICON_IMAGE_MAX_SIZE
  }

  // Allow only image files if not provided
  uploadType = uploadType || 'image'
  const uppy = new Uppy({
    ...UPPY_CONFIG,
    restrictions: {
      ...UPPY_CONFIG.restrictions,
      allowedFileTypes: fileToTypes[uploadType],
    },
  })
  uppy.use(Transloadit, {
    params: {
      ...UPPY_PARAMS,
      fields: {
        orgId,
        resizePx,
      },
    },
    waitForEncoding: true,
  })
  uppy.on('file-added', (file) => {
    onUploadStart &&
      onUploadStart({ tempUrl: URL.createObjectURL(file.data), file })
  })
  uppy.on('error', (error: Error) => {
    const msg = `Error uploading to Transloadit: ${error.message}`
    setErrorMessage && setErrorMessage('')
    onUploadFailed && onUploadFailed(msg)
    console.error(msg)
  })
  uppy.on(
    'transloadit:result',
    (stepName: string, result: Result, assembly: Assembly) => {
      // Skip if step is not original_file or if upload type's
      // original file is not supported
      if (
        !onOriginalFileUpload ||
        stepName !== 'original_file' ||
        assembly.uploads.length === 0 ||
        isHEICFileType(assembly.uploads[0].mime) ||
        isDocUpload(assembly.uploads[0].mime, assembly.uploads[0].ext)
      ) {
        return
      }

      if (!result) {
        return
      }

      const { url, meta, name } = result
      setErrorMessage && setErrorMessage('')
      if (url) {
        onOriginalFileUpload({
          src: url,
          // @ts-ignore
          meta,
          name,
        })
      } else {
        onUploadFailed && onUploadFailed('Error uploading to Transloadit')
        console.error('Error uploading to Transloadit')
      }
    }
  )
  uppy.on('transloadit:complete', (assembly: Assembly) => {
    let previousUrl
    let result
    let thumbnail
    const type = assembly.uploads[0].mime

    if (isPDFFileType(assembly.uploads[0].mime)) {
      // For PDF upload only file is the original file
      // We also set the thumbnail URL for PDFs.
      result = assembly.results.original_file[0]
      thumbnail = assembly.results.doc_thumbnail[0].url
    } else if (isDocUpload(assembly.uploads[0].mime, assembly.uploads[0].ext)) {
      // For doc, docx, ppt, pptx upload supported file is the PDF converted file
      // We also set the thumbnail URL for these types of assets.
      result = assembly.results.convert_to_pdf[0]
      thumbnail = assembly.results.doc_thumbnail[0].url
    } else if (!isHEICFileType(assembly.uploads[0].mime)) {
      // Non HEIC image files will have original file uploaded previously
      previousUrl = assembly.results.original_file[0].url
      // Transloadit will sometimes return only an original file, e.g. with SVGs
      result =
        assembly.results.optimized?.[0] || assembly.results.original_file[0]
    } else {
      result = assembly.results.optimized[0]
    }

    const { url, meta, name } = result

    setErrorMessage && setErrorMessage('')
    if (url) {
      const payload = {
        src: url,
        // @ts-ignore
        meta,
        name,
        thumbnail,
        type,
      }
      onUploadComplete && onUploadComplete(payload, previousUrl)

      console.log('UPLOAD(s) Complete!', assembly.results)

      TransloaditEventEmitter.emit('uploadComplete', payload)
    } else {
      onUploadFailed && onUploadFailed('Error uploading to Transloadit')
      console.error('Error uploading to Transloadit')
    }
  })
  return uppy
}

export const UppyUploader = {
  createUppyInstance,
}

export const uploadFile = async (
  file: File,
  orgId: string | undefined,
  uppyUploadHandlers: UppyUploadHandlers,
  imageType: ImageType = 'node',
  uploadType: UploadFileType = 'image'
) => {
  if (!orgId) {
    throw new Error('Tried uplaoding an image with no orgId')
  }

  const uppy = UppyUploader.createUppyInstance(
    orgId,
    uppyUploadHandlers,
    imageType,
    uploadType
  )
  uppy.addFile({ name: file.name, type: file.type, data: file })
  uppy.upload()
}

// Transloadit will do a server-side fetch for the image
export const uploadFileFromUrl = async (
  url: string,
  orgId: string | undefined,
  uppyUploadHandlers: UppyUploadHandlers,
  imageType: ImageType = 'node'
) => {
  if (!orgId) {
    throw new Error('Tried uplaoding an image with no orgId')
  }

  const uppy = UppyUploader.createUppyInstance(
    orgId,
    uppyUploadHandlers,
    imageType
  )
  uppy.use(Url, {
    companionUrl: Transloadit.COMPANION,
  })

  uppy
    .getPlugin('Url')!
    // @ts-ignore
    .addFile(url) // Uppy doesn't expose the type for this in URL plugin :(
}
