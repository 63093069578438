import { Vibe, VibeKey } from './types'

export const VibeOptions: Record<VibeKey, Vibe> = {
  soft: { name: 'Soft', spin: [0, -40, 40], lighten: [0, 0, 0] },
  duo: { name: 'Duo', spin: [0, 180], lighten: [0, 0] },
  crescent: {
    name: 'Crescent',
    spin: [0, -45, 45, -90, 90],
    lighten: [0, 0, 0, 0, 0],
  },
  wisp: { name: 'Wisp', spin: [0, 72, 216], lighten: [0, 0, 0] },
  mono: { name: 'Mono', spin: [0, 0, 0], lighten: [0, 20, 40] },
  cosmos: {
    name: 'Cosmos',
    spin: [0, 72, 72 * 2, 72 * 3, 72 * 4],
    lighten: [0, 0, 0, 0, 0],
  },
}
