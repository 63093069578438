import { EventEmitter } from 'utils/EventEmitter'

export enum EventBusEvent {
  CREATE_COMMENT_FROM_SELECTION = 'createCommentFromSelection',
  OPEN_POPUP_COMMENT = 'openPopupComment',
  POPUP_COMMENT_TOGGLED = 'popupCommentToggled',
}

type TiptapEventPayloads = {
  [EventBusEvent.CREATE_COMMENT_FROM_SELECTION]: {
    selectionPos: number
    parentPos: number
    text: string
  }
  [EventBusEvent.OPEN_POPUP_COMMENT]: {
    commentId: string
    highlightComment?: boolean
  }
  [EventBusEvent.POPUP_COMMENT_TOGGLED]: {
    open: boolean
  }
}

export class EventBus extends EventEmitter<TiptapEventPayloads> {}

export const TiptapEventBus = new EventBus()
