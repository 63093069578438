import { Node, mergeAttributes } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { attrsOrDecorationsChanged } from '../../updateFns'
import { TableRowView } from './TableRow'

export interface TableRowOptions {
  HTMLAttributes: Record<string, any>
}

export const TableRow = Node.create<TableRowOptions>({
  name: 'tableRow',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  // TableRow is not selectable via NodeSelection so we use `false`
  selectable: false,

  content: 'tableCell+',

  tableRole: 'row',

  addNodeView() {
    return ReactNodeViewRenderer(TableRowView, {
      update: attrsOrDecorationsChanged,
    })
  },

  parseHTML() {
    return [{ tag: 'tr' }]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'tr',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },
})
