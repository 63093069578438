import { IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/core'
import React, { memo } from 'react'

import { useAnalytics, SegmentEvents, NodeInsertMethods } from 'modules/segment'
import { preventDefaultAndStopPropagation } from 'utils/handlers'

export const CardDivider = memo(function CardDivider({
  editor,
  getPos,
}: Pick<NodeViewProps, 'editor' | 'getPos'>) {
  const analytics = useAnalytics()

  const appendCard = () => {
    const start = getPos()
    const thisNode = editor.view.state.doc.nodeAt(start)
    if (!thisNode) {
      console.error('[CardDivider] Cannot append card. Node unknown')
      return
    }
    const end = start + thisNode.nodeSize
    const cardToAdd = {
      type: 'card',
      attrs: {},
      content: [
        {
          type: 'heading',
          attrs: { level: 1 },
        },
      ],
    }
    analytics?.track(SegmentEvents.CARD_CREATED, {
      method: NodeInsertMethods.CARD_DIVIDER,
    })
    editor.chain().insertContentAt(end, cardToAdd).focus().run()
  }

  return (
    <GammaTooltip label="Insert card" placement="top">
      <IconButton
        className="insert-card-button"
        variant="plain"
        isRound
        aria-label="Insert card"
        icon={<FontAwesomeIcon icon={regular('plus')} />}
        onClick={appendCard}
        onMouseDown={preventDefaultAndStopPropagation}
        size="sm"
      />
    </GammaTooltip>
  )
})
