import { Circle } from '@chakra-ui/react'
import { memo } from 'react'

export const BlockCommentsCircle = memo(
  ({ nodeName }: { nodeName: string }) => {
    // Account for gridLayouts being wider than others.
    // See corresponding logic for the Controls Wrapper in <BlockCommentsWrapper />.
    const innerPaddingOffset =
      nodeName === 'gridLayout' || nodeName === 'gallery'
        ? '-1 * var(--grid-padding)'
        : '0px'
    return (
      <Circle
        contentEditable={false}
        userSelect="none"
        right={0}
        size={4}
        transform={`translate(calc(${innerPaddingOffset} + var(--card-inner-padding-x) + 50%),calc(50%)) scale(1)`}
        position="absolute"
        bgGradient="linear(to-b, yellow.200, yellow.300)"
        border="1px solid white"
        shadow="0 0 0 1px rgb(0 0 0 / 10%)"
        className="block-comments-circle"
      />
    )
  }
)

BlockCommentsCircle.displayName = 'BlockCommentsCircle'
