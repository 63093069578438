import { Plugin, PluginKey } from 'prosemirror-state'

import { DraftCommentsState } from './DraftCommentsState'

export const DraftCommentsPluginKey = new PluginKey('draftComments')

export const createDraftCommentsPlugin = () =>
  new Plugin({
    key: DraftCommentsPluginKey,

    state: {
      init() {
        return new DraftCommentsState()
      },

      apply(transaction, pluginState, oldEditorState, newEditorState) {
        return pluginState.apply(transaction, newEditorState)
      },
    },

    props: {
      decorations(state) {
        const { decorations } = this.getState(state) as DraftCommentsState

        return decorations
      },
    },
  })
