import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  HStack,
} from '@chakra-ui/react'
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TDSnapshot } from '@gamma-app/tldraw'
import React from 'react'

import { useTldrawApp } from '../hooks/useTldrawApp'
import { ToolbarIcon } from './ToolbarIcon'
import { ToolbarWrapper } from './ToolbarWrapper'

const zoomSelector = (s: TDSnapshot) =>
  s.document.pageStates[s.appState.currentPageId].camera.zoom

const gridSelector = (s: TDSnapshot) => s.settings.showGrid

export const ZoomToolbar = React.memo(function ZoomToolbar() {
  const app = useTldrawApp()
  const showGrid = app.useStore(gridSelector)

  return (
    <Flex position="absolute" top={2} left={2} zIndex={1}>
      <ToolbarWrapper>
        <HStack spacing={2}>
          <ZoomMenu />
          <ToolbarIcon
            icon={solid('grid-5')}
            label={showGrid ? 'No grid' : 'Snap to grid'}
            isActive={showGrid}
            key={'grid'}
            onClick={() => app.setSetting('showGrid', !showGrid)}
          />
        </HStack>
      </ToolbarWrapper>
    </Flex>
  )
})

export const ZoomMenu = React.memo(function ZoomMenu() {
  const app = useTldrawApp()
  const zoom = app.useStore(zoomSelector)

  return (
    <Menu>
      <MenuButton
        as={Button}
        data-test-id="zoom-menu-button"
        size="sm"
        colorScheme="trueblue"
        variant="ghost"
        leftIcon={<FontAwesomeIcon icon={duotone('magnifying-glass')} />}
      >
        {Math.round(zoom * 100)}%
      </MenuButton>
      <MenuList>
        <MenuItem command="#+" onClick={app.zoomIn}>
          Zoom in
        </MenuItem>
        <MenuItem command="#-" onClick={app.zoomOut}>
          Zoom out
        </MenuItem>
        <MenuItem command="⇧0" onClick={app.resetZoom}>
          Zoom to 100%
        </MenuItem>
        <MenuItem
          command="⇧1"
          onClick={app.zoomToFit}
          data-test-id="zoom-to-fit-button"
        >
          Zoom to fit
        </MenuItem>
      </MenuList>
    </Menu>
  )
})
