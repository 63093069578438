// Based on https://github.com/ueberdosis/tiptap/tree/main/demos/src/Experiments/GlobalDragHandle

import { Editor, findChildren, findParentNodeClosestToPos } from '@tiptap/core'
import { Node, ResolvedPos, Slice } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import {
  isBlockNode,
  isCardNode,
} from 'modules/tiptap_editor/utils/nodeHelpers'

import { NESTED_CARD_HOVER_SCALE } from '../Card'
import { isCardCollapsed } from '../Card/CardCollapse'
import { isExpandableSummaryNode } from '../Expandable/utils'
import { isMediaNode } from '../media'
import { isNodeInGallery } from '../media/Gallery'
import { isTableNode } from '../tables/utils/isCellSelection'

// These values should match the styles in globals.scss
export const HANDLE_WIDTH = 18
export const HANDLE_HEIGHT = 16
export const GUTTER_SIZE = 2
export const TABLE_CONTROLS_OFFSET = 22

export const CARD_EDGE_HOVER_THRESHOLD = 50
export const GUTTER_OFFSET = GUTTER_SIZE + HANDLE_WIDTH

export type Coords = { left: number; top: number }

export type RenderedNode = {
  depth: number
  pos: number
  el: HTMLElement | null
  node: Node
}

const isNodeDraggableWithHandle = (
  node: Node,
  pos: number,
  view: EditorView
) => {
  if (isExpandableSummaryNode(node)) return false
  if (!isBlockNode(node)) return false
  if (isMediaNode(node)) {
    const $pos = view.state.doc.resolve(pos)
    return !isNodeInGallery($pos)
  }
  return true
}

export const blockAtCoords = (
  coords: Coords,
  view: EditorView
): RenderedNode | undefined => {
  // Check two possible spots:
  // 1. The exact position you're hovering over
  // 2. GUTTER_OFFSET to the right of it, in case you're in the margin
  // 3. GUTTER_OFFSET + TABLE_CONTROLS_OFFSET to the right of it, in case you're in the margin outside of a table
  // We want whichever is more precise (e.g. prefer an image instead of the layout its inside of),
  // which corresponds to the higher position
  const coordPos = Math.max(
    view.posAtCoords(coords)?.inside || -1,
    view.posAtCoords({ ...coords, left: coords.left + GUTTER_OFFSET })
      ?.inside || -1,
    // check for table drag handle which has additional offset
    view.posAtCoords({
      ...coords,
      left: coords.left + GUTTER_OFFSET + TABLE_CONTROLS_OFFSET,
    })?.inside || -1
  )
  if (coordPos == -1) return

  let $coordPos: ResolvedPos
  try {
    // We've seen this error on Undo, in which case we can just bail
    // and try again on next mousemove event
    $coordPos = view.state.doc.resolve(coordPos)
  } catch (err) {
    return
  }

  // If this node is draggable, return it. Otherwise, find the closest draggable parent
  const nodeAfter = $coordPos.nodeAfter
  const parent =
    nodeAfter && isNodeDraggableWithHandle(nodeAfter, coordPos, view)
      ? { pos: coordPos, depth: $coordPos.depth, node: nodeAfter }
      : findParentNodeClosestToPos($coordPos, (node) =>
          isNodeDraggableWithHandle(node, coordPos, view)
        )
  if (!parent) return
  const { pos, depth, node } = parent
  const el = view.nodeDOM(pos)

  if (!(el instanceof HTMLElement)) {
    return
  }

  // Check for a child element that should be used for positioning the drag handle
  let elToUse = el
  const contentReference = el.querySelector<HTMLElement>(
    '[data-content-reference]'
  )
  if (contentReference) {
    // Make sure the content reference is a direct part of this node, and not a child
    const contentReferencePos = view.posAtDOM(contentReference, 0)
    if (contentReferencePos === pos) {
      elToUse = contentReference
    }
  }

  return { pos, depth, el: elToUse, node }
}

export const getHandleOffset = (node: Node, el: HTMLElement): Coords => {
  const handleOffset = { left: 0, top: 0 }
  if (node.isTextblock) {
    // Find the line height of the text, which could be directly on the node
    // or in a NodeViewContent if it's a nodeview
    const contentEl = el.querySelector('[data-node-view-content]') || el
    const lineHeight = parseInt(window.getComputedStyle(contentEl).lineHeight)
    handleOffset.top = (lineHeight - HANDLE_HEIGHT) / 2
  } else if (isCardNode(node)) {
    const isCollapsed = isCardCollapsed(node)
    if (isCollapsed) {
      handleOffset.left =
        0 - (el.clientWidth * (NESTED_CARD_HOVER_SCALE - 1)) / 2
    } else {
      handleOffset.left = 28
      handleOffset.top = 12
    }
  } else if (isTableNode(node)) {
    // move drag handle outside of table controls
    handleOffset.left = -TABLE_CONTROLS_OFFSET
  }
  return handleOffset
}

export const fixYSyncSelection = (editor: Editor, slice: Slice) => {
  // If the slice we've dropped is a single node with an id attribute,
  // ensure we update the current editor selection where that ID is
  // so that the prosemirror drop handler deletes it correctly.
  // See https://github.com/ProseMirror/prosemirror-view/blob/23e468f8727bb083d671321e972ceac52bad16b1/src/input.js#L655
  //
  // We always return false here because we still want prosemirror-view to
  // handle the drop. We just need to update the selection first.
  // See See https://prosemirror.net/docs/ref/#view.EditorProps.handleDrop
  //
  // This is necessary because the doc state can change while a slice
  // is being dragged via the collaboration (y-sync) plugin and there is
  // a bug where it is not correctly re-mapped at the moment.
  const isNode =
    slice.openStart == 0 && slice.openEnd == 0 && slice.content.childCount == 1
  const draggingNodeId = isNode && slice.content.firstChild?.attrs.id
  const draggingNodeType = isNode && slice.content.firstChild?.type.name
  if (!draggingNodeId || !draggingNodeType) return false

  // Find the current position of the dropped slice in the doc
  const currentDraggingNodes = findChildren(
    editor.state.doc,
    (node) =>
      node.attrs.id === draggingNodeId && node.type.name === draggingNodeType
  )
  if (currentDraggingNodes.length !== 1) {
    // We can't be certain which node is being dragged, so bail
    return false
  }

  const [currentDraggingNode] = currentDraggingNodes
  // Update the current selection to point to that node
  // (it will be deleted by the prosemirror drop handler)
  // See https://github.com/ProseMirror/prosemirror-view/blob/23e468f8727bb083d671321e972ceac52bad16b1/src/input.js#L644-L647
  editor.commands.selectNodeAtPos(currentDraggingNode.pos)
  console.debug(
    '[GlobalDragHandle] handleDrop - Setting selection on drop because node has an ID:',
    {
      draggingNodeId,
      newPos: currentDraggingNode.pos,
    }
  )
  return
}
