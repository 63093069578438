import { Extension } from '@tiptap/core'

import { CellSelection } from '../prosemirror-table'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    tableCommands: {
      /**
       * Select the column starting from the cell at position
       */
      selectColumn: (position: number) => ReturnType
      /**
       * Select the row starting from the cell at position
       */
      selectRow: (position: number) => ReturnType
      /**
       * Select the table starting from the cell at position
       */
      selectTable: (position: number) => ReturnType
    }
  }
}

export const TableCommands = Extension.create({
  name: 'TableCommands',
  addCommands() {
    return {
      selectColumn:
        (position) =>
        ({ tr, dispatch }) => {
          if (!dispatch) return true

          const { doc } = tr
          const $anchorCell = doc.resolve(position)
          const colSelection = CellSelection.colSelection($anchorCell)
          // ProseMirror core's setSelection doesn't know about
          // prosemirror-tables CellSelection tye
          // @ts-ignore
          tr.setSelection(colSelection)

          return true
        },
      selectRow:
        (position) =>
        ({ tr, dispatch }) => {
          if (!dispatch) return true

          const { doc } = tr
          const $anchorCell = doc.resolve(position)
          const rowSelection = CellSelection.rowSelection($anchorCell)
          // @ts-ignore
          tr.setSelection(rowSelection)

          return true
        },
      selectTable:
        (position) =>
        ({ tr, dispatch }) => {
          if (!dispatch) return true

          const { doc } = tr
          const $anchorCell = doc.resolve(position)
          const colSelection = CellSelection.colSelection($anchorCell)
          const tableSelection = CellSelection.rowSelection(
            colSelection.$anchorCell,
            colSelection.$headCell
          )
          // @ts-ignore
          tr.setSelection(tableSelection)

          return true
        },
    }
  },
})
