import { BoxProps } from '@chakra-ui/layout'

import { AnimationConfig } from 'modules/media'
import { Theme } from 'modules/theming'
import { VibeGradient } from 'modules/theming/components/GradientPicker/types'

import { ColorAttrs } from '../components/panels/ColorPanel'
import { ImageAttrs } from '../extensions/media/Image'

export type BackgroundOptions = {
  type: BackgroundType // eg "image"
  source?: string // eg "image.unsplash"
  inside?: boolean
  // Images
  image?: Omit<ImageAttrs, 'horizontalAlign' | 'isFullWidth' | 'id'>
  // Animations
  animation?: AnimationConfig
  // Colors
  color?: ColorAttrs
  // Gradients
  gradient?: VibeGradient
  // Legacy/custom
  css?: BoxProps
}

export enum BackgroundType {
  ANIMATION = 'animation',
  IMAGE = 'image',
  COLOR = 'color',
  GRADIENT = 'gradient',
  NONE = 'none',
}

// By default, docs should inherit from the theme's background
export const DEFAULT_DOC_BACKGROUND = {
  type: BackgroundType.NONE,
  source: undefined,
}

export const DEFAULT_THEME_BACKGROUND = {
  type: BackgroundType.COLOR,
  color: {
    hex: '#FAFAFA',
  },
}

export const getDocOrThemeBackground = (
  theme: Theme,
  docBackground?: BackgroundOptions
): BackgroundOptions =>
  !docBackground || docBackground.type === BackgroundType.NONE
    ? !theme.config.background ||
      theme.config.background.type === BackgroundType.NONE
      ? DEFAULT_THEME_BACKGROUND
      : theme.config.background
    : docBackground

export const getBackgroundProps = (
  background: BackgroundOptions,
  isDark: boolean
): BoxProps => {
  if (!background) return {}
  if (background.type === BackgroundType.ANIMATION) return {}

  if (background.type === BackgroundType.GRADIENT) {
    const css = background.gradient?.css || background.css
    return {
      backgroundColor: isDark ? 'black' : 'white',
      ...css,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
    }
  }

  if (background.type === BackgroundType.IMAGE && background.image) {
    const urls = [background.image.src, background.image.tempUrl]
      .filter((url) => url)
      .map((url) => `url(${url})`)

    return {
      backgroundImage: urls.join(', '),
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
    }
  }

  if (background.type === BackgroundType.COLOR && background.color)
    return {
      // Legacy backgrounds had color specified in color field directly,
      // new ones should all be in color.hex
      backgroundColor:
        typeof background.color === 'string'
          ? background.color
          : background.color.hex,
    }

  return {}
}
