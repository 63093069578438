import { Box, LinkBox, LinkOverlay, Portal, Text } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { NodeViewContent, NodeViewWrapper } from 'modules/tiptap_editor/react'
import { selectEditable } from 'modules/tiptap_editor/reducer'
import { isSafari } from 'utils/deviceDetection'
import { LightPopoverMotionProps, useLightPopover } from 'utils/hooks'

import { MarkViewProps } from '../../react/addMarkViewPlugin'
import { EmbedPreview } from '../media'
import { LinkAttrs } from '../media/types'

const MotionBox = motion(Box)

export const LinkView = ({ node }: MarkViewProps) => {
  const { href, meta } = node.attrs as LinkAttrs
  const editable = useAppSelector(selectEditable)
  const handleLinkClick = useCallback(
    (ev: React.MouseEvent) => {
      if (!editable) return // Use default handler
      ev.preventDefault() // Prevent the default link click behavior
    },
    [editable]
  )

  const {
    popperRef,
    referenceRef,
    isHovering,
    onMouseOver,
    onMouseOut,
    getPopperProps,
  } = useLightPopover()

  return (
    <NodeViewWrapper as="span">
      <Text
        as="a"
        className="link"
        href={href || ''}
        onClick={handleLinkClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        ref={referenceRef}
      >
        <NodeViewContent as="span" />
        {/* Zero-width space to prevents a bug where characters typed at the end would get stuck outside the link, outside of prosemirror. Wrapping it content editable false allows moving your cursor past it. This happens in Chrome but not Safari */}
        {!isSafari && <span contentEditable={false}>&#8203;</span>}
      </Text>

      {isHovering && href && meta ? (
        <Portal>
          <AnimatePresence>
            <MotionBox
              position="relative"
              zIndex="popover"
              ref={popperRef}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              w="min(500px, 80vw)"
              className="link-preview-hover"
              {...getPopperProps()}
              {...LightPopoverMotionProps}
              sx={{
                '.embed-preview': {
                  borderRadius: 'lg',
                  shadow: 'lg',
                },
              }}
            >
              <LinkBox>
                <EmbedPreview node={node} />
                <LinkOverlay href={href} target="_blank" />
              </LinkBox>
            </MotionBox>
          </AnimatePresence>
        </Portal>
      ) : null}
    </NodeViewWrapper>
  )
}
