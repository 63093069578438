import { Extension } from '@tiptap/core'

/*
 * This is a kludge to fix a bug with Tiptap where the `hasRequiredAttrs` method of NodeTypes alway returns false
 * See https://prosemirror.net/docs/ref/#model.NodeType.hasRequiredAttrs for docs
 * This has downstream impacts, like making ProseMirror think it can merge two nodes together when it shouldn't
 * in methods like https://prosemirror.net/docs/ref/#commands.joinBackward
 * This happens because Tiptap's ExtensionManager always sets default: null on the node type in
 * https://github.com/ueberdosis/tiptap/blob/ab4a0e2507b4b92c46d293a0bb06bb00a04af6e0/packages/core/src/helpers/getAttributesFromExtensions.ts#L21
 * Because we can't hook into Tiptap's schema creation directly, this code runs just _afterwards_ and cleans up
 * the hasDefault value on the NodeType used here: https://github.com/ProseMirror/prosemirror-model/blob/b8c5166e9ac5c5cf87da3f13012b0044fd8a4bd9/src/schema.js#L225
 * Tracking a better fix for this within @tiptap/core in https://github.com/ueberdosis/tiptap/pull/2640
 */
export const FixRequiredAttrs = Extension.create({
  name: 'fixRequiredAttrs',

  onBeforeCreate() {
    Object.values(this.editor.schema.nodes).forEach((nodeType) => {
      // @ts-ignore - attrs is not a public method. But if it's ever changed, this won't crash.
      Object.values(nodeType.attrs || {}).forEach((attr: any) => {
        // We don't have a way to make an attr as required in Tiptap yet, so using default: undefined to represent this.
        if (attr.default === undefined) {
          attr.hasDefault = false
        }
      })
    })
  },
})
