import { PluginKey } from 'prosemirror-state'

import { SlashMenuDropdown } from '../components/menus/SlashMenu'
import { ExtensionPriorityMap } from './constants'
import { createSuggestionExtension } from './Suggestion'

const SlashMenuKey = new PluginKey('SlashMenu')

// Based on https://www.tiptap.dev/experiments/commands/#commands
export const SlashMenu = createSuggestionExtension({
  name: 'slashMenu',
  char: '/',
  pluginKey: SlashMenuKey,
  MenuComponent: SlashMenuDropdown,
  priority: ExtensionPriorityMap.SlashMenu,
})
