import {
  Box,
  Divider,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import {
  duotone,
  regular,
  solid,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip, useGammaTooltipHider } from '@gamma-app/ui'
import { Editor, JSONContent } from '@tiptap/core'
import { Slice, ResolvedPos, Fragment } from 'prosemirror-model'
import React, { useCallback, useEffect, useState } from 'react'

import { TransloaditEventEmitter } from 'modules/media/apis/transloadit'
import { useAppSelector } from 'modules/redux'
import { selectCardIdMap, selectDoc } from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'
import { EventEmitter } from 'utils/EventEmitter'
import { useHover } from 'utils/hooks'

import { addAsset, addCard, getMetadata } from './api'
import { VaultPanel } from './VaultPanel'

type VaultEvents = {
  moveCardToVault: {
    cardData: JSONContent
    cardPreviewUrl?: string
    cardTitle?: string
  }
}
export const VaultEventEmitter = new EventEmitter<VaultEvents>()

const archiveIcon = <FontAwesomeIcon icon={duotone('box-archive')} />
const closeIcon = <FontAwesomeIcon icon={regular('right-from-line')} />

export const VaultButton = ({ editor }: { editor: Editor }) => {
  const doc = useAppSelector(selectDoc)
  const { user } = useUserContext()
  const [ref, isHovered] = useHover<HTMLButtonElement>()
  const [isOpen, setOpen] = useState(false)
  const { GammaTooltipHiderContext, hideTooltips } = useGammaTooltipHider()

  const docId = doc?.id
  const userId = user?.id

  useEffect(() => {
    if (!docId || !userId) return
    return VaultEventEmitter.on(
      'moveCardToVault',
      ({ cardData, cardPreviewUrl, cardTitle }) => {
        console.log('moveCardToVault - Adding card', cardTitle, cardPreviewUrl)
        if (!cardData.attrs?.id) return

        const cleanData = JSON.parse(JSON.stringify(cardData))

        addCard({
          docId,
          userId,
          cardId: cardData.attrs.id,
          cardData: cleanData,
          cardPreviewUrl: cardPreviewUrl || '',
          cardTitle: cardTitle || '',
        })
      }
    )
  }, [docId, userId])

  useEffect(() => {
    if (!docId || !userId) return

    return TransloaditEventEmitter.on(
      'uploadComplete',
      ({ src, meta, name, thumbnail, type }) => {
        console.log('[VaultPanel] Transloadit upload detected', {
          src,
          meta,
          name,
          thumbnail,
          type,
        })
        addAsset({
          docId,
          userId,
          type,
          vaultType: 'file',
          url: src,
          name,
          meta,
        })
          .then((asset) => {
            return getMetadata({ docId, userId, recordId: asset.id, url: src })
          })
          .then(() => {
            console.log('Asset upload complete!')
          })
      }
    )
  }, [docId, userId])

  return (
    <Popover
      placement="left-start"
      offset={[-56, 10]}
      trigger="hover"
      isLazy={true}
      lazyBehavior="keepMounted"
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: { padding: 20 },
        },
      ]}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <Box
          mb={2}
          bg="#F9FAFBDD"
          borderWidth="1px"
          borderColor="whiteAlpha.600"
          borderRadius="xl"
        >
          <GammaTooltip
            label={
              <Text display="inline">
                {isOpen ? 'Click to close vault' : 'Click to open vault'}
              </Text>
            }
          >
            <IconButton
              ref={ref}
              draggable={false}
              onClick={() => {
                setOpen((p) => !p)
              }}
              aria-label="vault-toggle"
              size="sm"
              variant=""
              icon={isOpen ? closeIcon : archiveIcon}
              color={'trueblue.600'}
              fontWeight="500"
              background={isOpen ? 'gray.200' : ''}
              borderRadius="md"
              borderWidth="1px"
              backdropFilter="blur(20px)"
              borderColor="transparent"
              _hover={{
                bg: isOpen ? 'gray.200' : 'gray.100',
                borderColor: 'gray.200',
              }}
            />
          </GammaTooltip>
        </Box>
      </PopoverTrigger>

      <Portal>
        <PopoverContent
          bg="#F9FAFBFA"
          width="420px"
          p={1}
          borderWidth="1px"
          borderColor="whiteAlpha.600"
          borderRadius="xl"
          // borderWidth="2px"
          // borderColor="var(--chakra-colors-trueblue-400)"
          // borderStyle="dashed"
          shadow="xl"
          // Show the Popover buttons with a higher zIndex than the widget toolbar
          zIndex="overlay"
          overflow="auto"
          maxH="90vh"
        >
          <GammaTooltipHiderContext>
            <VaultPanel editor={editor} docId={docId} />
          </GammaTooltipHiderContext>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
