import { BoxProps, Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import { cardViewedEmitter } from './CardViewedEmitter'

type CardViewedDebuggerProps = { cardId: string } & BoxProps

export const CardViewedDebugger: React.FC<CardViewedDebuggerProps> = ({
  cardId,
  ...rest
}) => {
  const [count, setCount] = useState(0)
  const [isFlushing, setIsFlushing] = useState(false)
  const [flushedDuration, setFlushedDuration] = useState(0)

  useEffect(() => {
    const unsubCardViewed = cardViewedEmitter.on(
      'cardViewed',
      ({ cardId: eventCardId, interval }) => {
        if (cardId !== eventCardId) {
          return
        }

        setCount((val) => val + interval)
      }
    )

    const unsubFlush = cardViewedEmitter.on('flush', ({ viewed }) => {
      setCount(0)
      if (viewed[cardId]) {
        setIsFlushing(true)
        setFlushedDuration(viewed[cardId])
        setTimeout(() => setIsFlushing(false), 2000)
      }
    })

    return () => {
      unsubCardViewed()
      unsubFlush()
    }
  }, [cardId])

  return (
    <Box {...rest} bg={isFlushing ? '#c3313a' : '#f5ae00'}>
      {!isFlushing && <Box>time on card: {Math.floor(count / 1000)}s</Box>}
      {isFlushing && <Box>Tracked View: {flushedDuration}s</Box>}
    </Box>
  )
}
