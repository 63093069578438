import { CSSObject } from '@chakra-ui/styled-system'

import { ColorAttrs } from '../../components/panels/ColorPanel'

export type HighlightColorOptions = {
  // New colors
  red: ColorAttrs
  orange: ColorAttrs
  yellow: ColorAttrs
  green: ColorAttrs
  blue: ColorAttrs
  purple: ColorAttrs
  gray: ColorAttrs
  // Legacy variants
  primary: ColorAttrs
  secondary: ColorAttrs
  tertiary: ColorAttrs
}

type HighlightColors = {
  light: HighlightColorOptions
  dark: HighlightColorOptions
}

// https://gamma.app/docs/fbv4sdzczgin8ph#card-dekblkko9tn15m7
export const highlightColors: HighlightColors = {
  light: {
    gray: { hex: '#D6D6D6', name: 'Gray' },
    red: { hex: '#FFBDBD', name: 'Red' },
    orange: { hex: '#F8CB96', name: 'Orange' },
    yellow: { hex: '#FBEB8F', name: 'Yellow' },
    green: { hex: '#C9FDCB', name: 'Green' },
    blue: { hex: '#D1E5FD', name: 'Blue' },
    purple: { hex: '#D5CCFB', name: 'Purple' },
    // Deprecated
    primary: { hex: '#FFD300' },
    secondary: { hex: '#FF7979' },
    tertiary: { hex: '#A9FF8B' },
  },
  dark: {
    gray: { hex: '#999999', name: 'Gray' },
    red: { hex: '#EB5252', name: 'Red' },
    orange: { hex: '#DD8404', name: 'Orange' },
    yellow: { hex: '#CAAD07', name: 'Yellow' },
    green: { hex: '#50BD3D', name: 'Green' },
    blue: { hex: '#85BAFA', name: 'Blue' },
    purple: { hex: '#B1A0F8', name: 'Purple' },
    // Deprecated
    primary: { hex: '#856E01' },
    secondary: { hex: '#952222' },
    tertiary: { hex: '#257D04' },
  },
}

const getHighlightStyles = (colorMode: 'light' | 'dark') => {
  return Object.entries(highlightColors[colorMode]).reduce(
    (styles, [key, value]) => {
      const style: CSSObject = {
        backgroundColor: value.hex,
      }
      styles[`&[variant="${key}"]`] = style
      return styles
    },
    {}
  )
}

export const paddedTextBackground = {
  borderRadius: '0.125em',
  px: '.05em',
  mx: '-.05em',
  boxDecorationBreak: 'clone',
}

export const highlightStyles = {
  default: {
    'mark.highlight': {
      color: 'black',
      '--link-color': 'black',
      ...getHighlightStyles('light'),
      ...paddedTextBackground,
    },
  },
  dark: {
    'mark.highlight': {
      color: 'black',
      ...getHighlightStyles('dark'),
    },
  },
}
