import { Flex } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'

import { NodeViewContent } from 'modules/tiptap_editor/react'

import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { isFocusedAndEditable } from '../FocusedNodes'
import { findSmartLayoutDecoration } from './decoration'
import { SmartLayoutFormattingMenu } from './SmartLayoutFormattingMenu'
import { SmartLayoutAttrs } from './types'
import { getSmartLayoutOptions } from './utils'
import { getSmartLayoutVariant } from './variants'

export const SmartLayoutView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations } = nodeViewProps
  const attrs = node.attrs as SmartLayoutAttrs
  // Use the Wrapper component specific to this variant
  const variant = getSmartLayoutVariant(attrs.variantKey)
  const { Wrapper } = variant
  // Decoration has info like # of cells that we pass to the Wrapper
  const deco = findSmartLayoutDecoration(decorations)
  const isFocused = isFocusedAndEditable(decorations)
  const options = getSmartLayoutOptions(attrs)
  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <Wrapper {...deco} options={options}>
        <NodeViewContent data-node-view-content-outer="smartLayout" />
      </Wrapper>
      {isFocused && (
        <Flex
          w="100%"
          align="center"
          direction="column"
          color="black"
          contentEditable={false}
        >
          <SmartLayoutFormattingMenu {...nodeViewProps} />
        </Flex>
      )}
    </AnnotatableNodeViewWrapper>
  )
}
