import { JSONContent } from '@tiptap/core'
import { initializeApp } from 'firebase/app'
import {
  doc,
  getDoc,
  setDoc,
  getDocs,
  deleteDoc,
  collection,
  addDoc,
  getFirestore,
  onSnapshot,
  DocumentData,
  query,
  where,
} from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyA_hS24AVHvfWbqvVzc9iTnLtHo0UWxVLQ',
  authDomain: 'hack-the-vault.firebaseapp.com',
  projectId: 'hack-the-vault',
  storageBucket: 'hack-the-vault.appspot.com',
  messagingSenderId: '813143289197',
  appId: '1:813143289197:web:7e6e7f94355595c5d97e64',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

const getKeypath = (docId: string, userId: string) => {
  return [docId, 'users', userId, 'assets']
}

export const addAsset = async ({
  docId,
  userId,
  url,
  name,
  type,
  vaultType,
  meta = {},
}: {
  docId: string
  userId: string
  url: string
  name: string
  type: string
  vaultType: string
  meta?: Record<string, any>
}) => {
  return addDoc(collection(db, 'docs', ...getKeypath(docId, userId)), {
    name,
    url,
    type,
    vaultType,
    meta,
  })
}

export const addCard = async ({
  docId,
  userId,
  cardId,
  cardData,
  cardPreviewUrl,
  cardTitle,
}: {
  docId: string
  userId: string
  cardId: string
  cardData: JSONContent
  cardPreviewUrl?: string
  cardTitle?: string
}) => {
  const cardRef = doc(db, 'docs', docId, 'users', userId, 'cards', cardId)
  return setDoc(
    cardRef,
    { cardData, cardPreviewUrl, cardTitle },
    { merge: false }
  )
}

export const removeCard = async ({
  docId,
  userId,
  cardId,
}: {
  docId: string
  userId: string
  cardId: string
}) => {
  const cardRef = doc(db, 'docs', docId, 'users', userId, 'cards', cardId)
  await deleteDoc(cardRef)
}

export const removeAsset = async ({
  docId,
  userId,
  recordId,
}: {
  docId: string
  userId: string
  recordId: string
}) => {
  const docRef = doc(db, 'docs', ...getKeypath(docId, userId).concat(recordId))
  await deleteDoc(docRef)
}

export const updateAsset = async ({
  docId,
  userId,
  recordId,
  data,
}: {
  docId: string
  userId: string
  recordId: string
  data: Record<string, any>
}) => {
  const docRef = doc(db, 'docs', ...getKeypath(docId, userId).concat(recordId))
  return setDoc(docRef, data, { merge: true })
}

export const getNote = async ({
  docId,
  userId,
}: {
  docId: string
  userId: string
}) => {
  const noteRef = doc(db, 'docs', docId, 'users', userId, 'notes', 'note1')
  const note = await getDoc(noteRef)
  return note.data()
}

export const updateNote = async ({
  docId,
  userId,
  data,
}: {
  docId: string
  userId: string
  data: Record<string, any>
}) => {
  const noteRef = doc(db, 'docs', docId, 'users', userId, 'notes', 'note1')
  return setDoc(noteRef, data, { merge: true })
}

export const getMetadata = async ({
  docId,
  userId,
  recordId,
  url,
}: {
  docId: string
  userId: string
  recordId: string
  url: string
}) => {
  const docRef = doc(db, 'docs', ...getKeypath(docId, userId).concat(recordId))
  return fetch(`/api/image-metadata?url=${url}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log('[getMetadata] Retrieved data. Updating in firestore', data)
      return setDoc(docRef, data, { merge: true })
    })
    .catch((err) => {
      console.error('[getMetadata] ERROR retrieving metadata', err)
    })
}

export const getAssets =
  (docId: string, userId: string) =>
  (callback: (data: DocumentData[]) => void) => {
    const collectionRef = collection(db, 'docs', ...getKeypath(docId, userId))

    return onSnapshot(collectionRef, (querySnapshot) => {
      const items: DocumentData[] = []
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() })
      })
      callback(items)
    })
  }

export const getCards =
  (docId: string, userId: string) =>
  (callback: (data: DocumentData[]) => void) => {
    const collectionRef = collection(
      db,
      'docs',
      docId,
      'users',
      userId,
      'cards'
    )

    return onSnapshot(collectionRef, (querySnapshot) => {
      const items: DocumentData[] = []
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() })
      })
      callback(items)
    })
  }

export const searchAssets = async ({
  docId,
  userId,
  keyword,
}: {
  docId: string
  userId: string
  keyword: string
}) => {
  const collectionRef = collection(db, 'docs', ...getKeypath(docId, userId))
  const searchResults = query(
    collectionRef,
    where('keywords', 'array-contains-any', [keyword])
  )
  const matchingDocs = await getDocs(searchResults)
  console.log({ matchingDocs })
  return matchingDocs.docs.map((d) => d.id)
}
