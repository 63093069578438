import { Box, HStack } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import { NodeSelection, TextSelection } from 'prosemirror-state'

import { CellSelection } from 'modules/tiptap_editor/extensions/tables/prosemirror-table'

import { BubbleMenu } from '../../../extensions/BubbleMenu/BubbleMenu'
import { isMediaEmbedNode } from '../../../extensions/media'
import { useEditorUpdateDuringSelection } from '../../../hooks'
import { ButtonFormattingMenu } from './ButtonFormattingMenu'
import { CardTOCFormattingMenu } from './CardTOCFormattingMenu'
import { ContributorsFormattingMenu } from './ContributorsFormattingMenu'
import { DrawingFormattingMenu } from './DrawingFormattingMenu'
import { EmbedVideoFormattingMenu } from './EmbedVideoFormattingMenu'
import { ImageFormattingMenu } from './ImageFormattingMenu'
import { LinkFormattingMenu } from './LinkFormattingMenu'
import { TableFormattingMenu } from './TableFormattingMenu'
import { TextFormattingMenu } from './TextFormattingMenu'

type FormattingMenuProps = {
  editor: Editor
  scrollingParentSelector?: string
}

export const FormattingMenu = ({
  editor,
  scrollingParentSelector,
}: FormattingMenuProps) => {
  // Trigger a re-render whenever the selection changes or content is
  // updated while the selection is not empty, as our menus need to re-calc
  // This prevents the menus from re-rendering on every keystroke
  useEditorUpdateDuringSelection(editor)
  const selection = editor.state.selection
  const isContributorsSelection =
    selection instanceof NodeSelection &&
    selection.node.type.name === 'contributors'

  const isDrawingSelection =
    selection instanceof NodeSelection && selection.node.type.name === 'drawing'
  const isImageSelection =
    selection instanceof NodeSelection && selection.node.type.name === 'image'
  const isMediaPlaceholderSelection =
    selection instanceof NodeSelection &&
    selection.node.type.name === 'mediaPlaceholder'
  const isEmbedOrVideoSelection =
    selection instanceof NodeSelection && isMediaEmbedNode(selection.node)
  const isTableSelection =
    selection instanceof CellSelection &&
    (selection.isRowSelection() || selection.isColSelection())
  const isTableOfContentsSelection =
    selection instanceof NodeSelection &&
    selection.node.type.name === 'tableOfContents'
  const isTextSelection = selection instanceof TextSelection && !selection.empty
  const isLinkSelection =
    selection instanceof TextSelection && editor.isActive('link')

  const MenuContent = isTableSelection ? (
    <TableFormattingMenu editor={editor} selection={selection} />
  ) : isLinkSelection ? (
    <LinkFormattingMenu editor={editor} selection={selection} />
  ) : editor.isActive('button') ? (
    <ButtonFormattingMenu editor={editor} selection={selection} />
  ) : isEmbedOrVideoSelection ? (
    <EmbedVideoFormattingMenu editor={editor} selection={selection} />
  ) : isDrawingSelection ? (
    <DrawingFormattingMenu editor={editor} selection={selection} />
  ) : isImageSelection || isMediaPlaceholderSelection ? (
    <ImageFormattingMenu editor={editor} selection={selection} />
  ) : isContributorsSelection ? (
    <ContributorsFormattingMenu editor={editor} />
  ) : isTableOfContentsSelection ? (
    <CardTOCFormattingMenu editor={editor} selection={selection} />
  ) : isTextSelection ? (
    <TextFormattingMenu editor={editor} />
  ) : null

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        maxWidth: 'none',
        // @ts-ignore - tippy.js only wants a number here, but the string works correctly
        zIndex: 'var(--chakra-zIndices-popover)',
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: [],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                boundary: scrollingParentSelector
                  ? document.querySelector(scrollingParentSelector)
                  : undefined,
              },
            },
          ],
        },
      }}
    >
      {MenuContent && (
        <Box
          shadow="lg"
          bg="white"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.200"
          borderBottom="none"
          borderRadius="full"
          w="auto"
          minW="0px"
          position="relative"
          bottom={selection instanceof CellSelection ? '2em' : '0'}
          // Nubbin
          _after={{
            content: '""',
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            margin: '0 auto',
            width: 0,
            height: 0,
            borderTop: 'solid 5px #fff',
            borderLeft: 'solid 5px transparent',
            borderRight: 'solid 5px transparent',
          }}
          data-in-editor-focus
          data-testid="formatting-menu"
        >
          <HStack spacing={3} px={2}>
            {MenuContent}
          </HStack>
        </Box>
      )}
    </BubbleMenu>
  )
}
