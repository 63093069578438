import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'

import { BlockClass } from '../extensions/BlockClass'
import { CodeBlock } from '../extensions/code/CodeBlock'
import { EmojiNode, EmojiShortcuts } from '../extensions/Emoji'
import { FocusedNodes } from '../extensions/FocusedNodes'
import { SimpleImage } from '../extensions/media/Image/SimpleImage'
import { MediaUpload } from '../extensions/media/Upload'
import { CardMention, DocMention, UserMention } from '../extensions/Mention'
import { MentionSuggestionMenu } from '../extensions/MentionSuggestionMenu'
export const getCommentExtensions = () => [
  StarterKit.configure({
    horizontalRule: false,
    codeBlock: false,
  }),
  MentionSuggestionMenu,
  Link.configure({
    openOnClick: false,
  }),
  // Base extensions. These loosely mirror the order from EditorCore getBaseExtensions
  CodeBlock,
  SimpleImage,
  UserMention,
  DocMention,
  CardMention,
  EmojiNode,

  // These extensions don't alter the schema, but rather give additional functionality
  // within the context of the editor.
  BlockClass,
  EmojiShortcuts,
  MediaUpload,
  FocusedNodes,
]
