import { InputRule, InputRuleFinder, getMarksBetween } from '@tiptap/core'
import { MarkType, NodeType, Fragment, Node } from 'prosemirror-model'

import { generateFootnoteId, setFootnoteExpanded } from './FootnoteState'

/**
 * Based on https://github.com/ueberdosis/tiptap/blob/main/packages/core/src/inputRules/markInputRule.ts
 * Adapted to insert a node in addition to the mark
 */
export default function footnoteInputRule(config: {
  find: InputRuleFinder
  markType: MarkType
  nodeType: NodeType
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range, match }): void => {
      const { tr } = state
      const captureGroup = match[match.length - 1]
      const fullMatch = match[0]
      let markEnd = range.to

      if (captureGroup) {
        const startSpaces = fullMatch.search(/\S/)
        const textStart = range.from + fullMatch.indexOf(captureGroup)
        const textEnd = textStart + captureGroup.length

        const excludedMarks = getMarksBetween(range.from, range.to, state.doc)
          .filter((item) => {
            // @ts-ignore
            const excluded = item.mark.type.excluded as MarkType[]

            return excluded.find(
              (type) => type === config.markType && type !== item.mark.type
            )
          })
          .filter((item) => item.to > textStart)

        if (excludedMarks.length) {
          return
        }

        if (textEnd < range.to) {
          tr.delete(textEnd, range.to)
        }

        if (textStart > range.from) {
          tr.delete(range.from + startSpaces, textStart)
        }

        markEnd = range.from + startSpaces + captureGroup.length

        const noteId = generateFootnoteId()
        setFootnoteExpanded(noteId, true)
        const newNode = config.nodeType.createAndFill(
          { noteId },
          Fragment.empty
        ) as Node // Passing fragment.empty ensures this will be a Node and not undefined
        tr.insert(markEnd, newNode)
        tr.addMark(
          range.from + startSpaces,
          markEnd,
          config.markType.create({ noteId })
        )
        tr.removeStoredMark(config.markType)
      }
    },
  })
}
