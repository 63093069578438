import { Checkbox, Text } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'

import { useAppSelector } from 'modules/redux'
import { selectEditable } from 'modules/tiptap_editor/reducer'

import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable/components/AnnotatableNodeViewWrapper'

export const TodoView = (nodeViewProps: NodeViewProps) => {
  const { node, updateAttributes } = nodeViewProps
  const editable = useAppSelector(selectEditable)
  const { checked, fontSize } = node.attrs

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <Text
        as="li"
        color={checked ? 'gray.400' : undefined}
        textDecoration={checked ? 'line-through' : undefined}
        transition="color 250ms ease-out"
        position="relative"
        className="todo"
        data-font-size={fontSize}
      >
        <Checkbox
          contentEditable="false"
          suppressContentEditableWarning={true}
          isDisabled={!editable}
          isChecked={checked}
          onChange={(event) =>
            updateAttributes({ checked: event.target.checked })
          }
          position="absolute"
          borderRadius="full"
        />
        <NodeViewContent as="span" className="todo-content" />
      </Text>
    </AnnotatableNodeViewWrapper>
  )
}
