import { CheckIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME, GammaTooltip } from '@gamma-app/ui'
import { Editor, NodeViewProps } from '@tiptap/core'
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useFeatureFlag } from 'modules/featureFlags'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { Theme } from 'modules/theming'
import { CARD_WIDTHS } from 'modules/tiptap_editor/extensions/Card/constants'

import { useForwardUndo } from '../../hooks'
import { selectBackground, selectTheme } from '../../reducer'
import {
  BackgroundOptions,
  BackgroundType,
  getBackgroundProps,
  getDocOrThemeBackground,
} from '../../styles/backgroundStyles'
import {
  ContainerEffect,
  ContainerOptions,
  ContainerWidth,
  getContainerOptions,
  getContainerStyles,
} from '../../styles/containerStyles'
import { BackgroundPanel } from '../panels/BackgroundPanel'
import { useDrawerSize } from './hooks'

type CardStyleDrawerProps = {
  editor: Editor
  isOpen: boolean
  isNested: boolean
  setIsStyleDrawerOpen: (isOpen: boolean) => void
  cardContainer: ContainerOptions
  cardBackground: BackgroundOptions
  updateAttributes: NodeViewProps['updateAttributes']
  tabIndex: number
  setTabIndex: (tabIndex: number) => void
}

export const CardStyleDrawer = memo(function CardStyleDrawer({
  editor,
  isOpen,
  isNested,
  setIsStyleDrawerOpen,
  cardContainer,
  cardBackground,
  updateAttributes,
  tabIndex,
  setTabIndex,
}: CardStyleDrawerProps) {
  const docBackground = useSelector(selectBackground)
  const theme = useSelector(selectTheme)
  const docOrThemeBackground = getDocOrThemeBackground(theme, docBackground)
  const analytics = useAnalytics()
  const updateAttributesAndTrack: CardStyleDrawerProps['updateAttributes'] =
    useCallback(
      (attrs) => {
        if (
          attrs.background?.type &&
          attrs.background.type !== BackgroundType.NONE &&
          attrs.background.inside === undefined
        ) {
          attrs.background.inside = isNested
          attrs.container = {
            ...attrs.container,
            effect: attrs.background.inside
              ? ContainerEffect.FADED
              : ContainerEffect.FROSTED,
          }
        }
        updateAttributes(attrs)
        analytics?.track(SegmentEvents.CARD_STYLES_UPDATED, attrs)
      },
      [updateAttributes, analytics, isNested]
    )
  const onClose = useCallback(
    () => setIsStyleDrawerOpen(false),
    [setIsStyleDrawerOpen]
  )
  const forwardUndo = useForwardUndo(editor)

  // Card settings override the doc defaults
  const computedContainer = getContainerOptions(theme, cardContainer)

  const handleReset = useCallback(() => {
    updateAttributes({
      container: {},
      background: { type: BackgroundType.NONE },
    })
    analytics?.track(SegmentEvents.CARD_STYLES_RESET)
  }, [updateAttributes, analytics])

  const drawerSize = useDrawerSize()

  return (
    <Drawer
      trapFocus={false}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={drawerSize}
    >
      <DrawerOverlay background="none" />
      <DrawerContent
        onKeyDown={forwardUndo}
        data-in-editor-focus
        data-testid="card-style-drawer"
      >
        <DrawerCloseButton />
        <DrawerHeader>Style</DrawerHeader>
        <DrawerBody overflowX="hidden">
          <Tabs
            variant="soft-rounded"
            size="sm"
            isFitted
            isLazy
            index={tabIndex}
            onChange={setTabIndex}
          >
            <TabList>
              <Tab>
                <Box mr={2}>
                  <FontAwesomeIcon icon={regular('fill-drip')} />
                </Box>
                Background
              </Tab>
              <Tab>
                <Box mr={2}>
                  <FontAwesomeIcon icon={regular('border-style')} />
                </Box>
                Advanced
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0} pb={1} mt={4}>
                <BackgroundPanel
                  editor={editor}
                  updateAttributes={updateAttributesAndTrack}
                  background={cardBackground}
                  defaultMessage={
                    <Alert>
                      <AlertIcon />
                      The {DOC_DISPLAY_NAME}'s background will show up behind
                      this card. You can change the {DOC_DISPLAY_NAME}'s
                      background using the theme button in the toolbar, or add a
                      background specific to this card.
                    </Alert>
                  }
                  isDark={computedContainer.isDark}
                />
              </TabPanel>
              <TabPanel p={0} pb={1} mt={4}>
                <ContainerStylePanel
                  container={computedContainer}
                  updateAttributes={updateAttributesAndTrack}
                  background={cardBackground}
                  docBackground={docOrThemeBackground}
                  theme={theme}
                  isNested={isNested}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>

        <DrawerFooter>
          <ButtonGroup w="100%">
            <GammaTooltip
              placement="top"
              label="Reset this card's style to the theme default"
            >
              <Button
                variant="ghost"
                colorScheme="gray"
                onClick={handleReset}
                data-testid="reset-card-style"
              >
                Reset
              </Button>
            </GammaTooltip>
            <Button flex="1" variant="solid" onClick={onClose}>
              Done
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
})

const COLOR_OPTIONS = [
  { isDark: false, label: 'Light', key: 'color-light' },
  { isDark: true, label: 'Dark', key: 'color-dark' },
]

const BACKGROUND_SIZE_OPTIONS = [
  { inside: false, label: 'Outside', key: 'size-outside' },
  { inside: true, label: 'Inside', key: 'size-inside' },
]

const EFFECT_OPTIONS = [
  {
    effect: ContainerEffect.SOLID,
    label: 'Solid',
    disableInside: true,
    key: 'effect-solid',
  },
  {
    effect: ContainerEffect.FROSTED,
    label: 'Frosted',
    disableInside: true,
    key: 'effect-frosted',
  },
  {
    effect: ContainerEffect.FADED,
    label: 'Faded',
    key: 'effect-faded',
  },
  { effect: ContainerEffect.CLEAR, label: 'Clear', key: 'effect-clear' },
]

const WIDTH_OPTIONS = [
  { width: 'full', label: 'Full', key: 'full' },
  { width: 'lg', label: 'Wide', key: 'lg', disabled: true },
  { width: 'md', label: 'Default', key: 'md' },
  { width: 'sm', label: 'Narrow', key: 'sm', disabled: true },
]

const checkEffectDisabledInside = (effect) =>
  EFFECT_OPTIONS.find((e) => e.effect === effect)?.disableInside

type ContainerStylePanelProps = {
  container: ContainerOptions
  background: BackgroundOptions
  docBackground: BackgroundOptions
  updateAttributes: NodeViewProps['updateAttributes']
  theme: Theme
  isNested?: boolean
  enableBackgroundSizes?: boolean
  disableEffects?: ContainerEffect[]
}

export const ContainerStylePanel = ({
  container,
  background,
  docBackground,
  updateAttributes,
  theme,
  isNested = false,
  enableBackgroundSizes = true,
  disableEffects = [],
}: ContainerStylePanelProps) => {
  const canChooseBackgroundSize =
    background.type !== BackgroundType.NONE &&
    (!isNested || (isNested && !background.inside)) &&
    enableBackgroundSizes
  const canChooseContainerColor = true // Enabling for everyone for now
  const canChooseContainerWidth = useFeatureFlag('cardWidthOptions')

  return (
    <VStack spacing={4} w="100%" align="stretch">
      {canChooseContainerWidth && (
        <>
          <Heading size="md" mb={2}>
            Width
          </Heading>
          <SimpleGrid columns={2} spacing={2}>
            {WIDTH_OPTIONS.map(({ width, label, key, disabled }) => {
              if (disabled && key !== container.width) return
              const newContainer = {
                ...container,
                width: width as ContainerWidth,
              }
              return (
                <ContainerStylePreview
                  container={newContainer}
                  onClick={() =>
                    updateAttributes({
                      container: newContainer,
                    })
                  }
                  key={key}
                  data-testid={`container-style-preview-${key}`}
                  label={label}
                  isChecked={(container.width || 'md') === width}
                  cardBackground={background}
                  docBackground={docBackground}
                  theme={theme}
                />
              )
            })}
          </SimpleGrid>
        </>
      )}
      {canChooseBackgroundSize && (
        <>
          <Heading size="md" mb={2}>
            Background Size
          </Heading>
          <SimpleGrid columns={2} spacing={2}>
            {BACKGROUND_SIZE_OPTIONS.map(({ inside, label, key }) => {
              const newBackground = { ...background, inside }
              const newContainer =
                inside && checkEffectDisabledInside(container.effect)
                  ? {
                      ...container,
                      effect: ContainerEffect.FADED,
                    }
                  : container

              return (
                <ContainerStylePreview
                  container={newContainer}
                  onClick={() => {
                    updateAttributes({
                      background: newBackground,
                      container: newContainer,
                    })
                  }}
                  key={key}
                  data-testid={`container-style-preview-${key}`}
                  label={label}
                  isChecked={background.inside === inside}
                  cardBackground={newBackground}
                  docBackground={docBackground}
                  theme={theme}
                />
              )
            })}
          </SimpleGrid>
        </>
      )}
      <Heading size="md" mb={2}>
        Transparency
      </Heading>
      <SimpleGrid columns={2} spacing={2}>
        {EFFECT_OPTIONS.map(({ effect, label, disableInside, key }) => {
          const newContainer = { ...container, effect }
          if (disableInside && background.inside) return null
          if (disableEffects.includes(effect)) return null
          return (
            <ContainerStylePreview
              container={newContainer}
              onClick={() =>
                updateAttributes({
                  container: newContainer,
                })
              }
              key={key}
              data-testid={`container-style-preview-${key}`}
              label={label}
              isChecked={container.effect === effect}
              cardBackground={background}
              docBackground={docBackground}
              theme={theme}
            />
          )
        })}
      </SimpleGrid>
      {/* Card-level color switcher is deprecated. We'll only show it if you had previously set it to something other than the theme default, otherwise you won't even see the choice to change it. */}
      {canChooseContainerColor ? (
        <>
          <Heading size="md" mb={2}>
            Color
          </Heading>
          <SimpleGrid columns={2} spacing={2}>
            {COLOR_OPTIONS.map(({ isDark, label, key }) => {
              const newContainer = { ...container, isDark }
              return (
                <ContainerStylePreview
                  container={newContainer}
                  onClick={() =>
                    updateAttributes({
                      container: newContainer,
                    })
                  }
                  key={key}
                  data-testid={`container-style-preview-${key}`}
                  label={label}
                  isChecked={container.isDark === isDark}
                  cardBackground={background}
                  docBackground={docBackground}
                  theme={theme}
                />
              )
            })}
          </SimpleGrid>
        </>
      ) : null}
    </VStack>
  )
}

type ContainerStylePreviewProps = {
  container: ContainerOptions
  label: string
  onClick: () => void
  isChecked: boolean
  cardBackground: BackgroundOptions
  docBackground: BackgroundOptions
  theme: Theme
} & ButtonProps

const ContainerStylePreview = ({
  container,
  label,
  onClick,
  isChecked,
  cardBackground,
  docBackground,
  theme,
  ...buttonProps
}: ContainerStylePreviewProps) => {
  const width = CARD_WIDTHS[container.width || 'md']
  const isFullWidth = container.width === 'full'
  const canChooseContainerWidth = useFeatureFlag('cardWidthOptions')

  return (
    <Button
      borderRadius="md"
      display="block"
      height="auto"
      variant="ghost"
      color="black"
      onClick={onClick}
      p={1}
      {...buttonProps}
    >
      <Flex
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="md"
        {...getBackgroundProps(docBackground, container.isDark)}
        backgroundAttachment="unset"
        _focus={{
          boxShadow: 'outline',
        }}
        p={isFullWidth ? 0 : 2}
        overflow="hidden"
        position="relative"
        direction="column"
        align="center"
        justify="center"
      >
        <Box
          {...getBackgroundProps(cardBackground, container.isDark)}
          backgroundAttachment="unset"
          position="absolute"
          borderRadius="md"
          inset={cardBackground.inside ? 4 : 0}
          zIndex={0}
          transitionProperty="inset"
          transitionDuration="normal"
        ></Box>
        <Box
          px={3}
          py={isFullWidth ? 6 : 4}
          mx={isFullWidth ? -1 : 0}
          transitionProperty="background, color, opacity, backdrop-filter"
          transitionDuration="normal"
          borderRadius={isFullWidth ? 'none' : 'md'}
          border="1px solid transparent" // To prevent jittering
          sx={getContainerStyles(container, theme)}
          color={container.isDark ? 'white' : 'gray.900'}
          position="relative"
          zIndex={1}
          w={
            canChooseContainerWidth
              ? `${Math.min(width / CARD_WIDTHS['lg'], 1) * 100}%`
              : '100%'
          }
        >
          <ContentSkeleton />
        </Box>
      </Flex>
      <Flex align="center" mt={1}>
        {isChecked && <CheckIcon w={3} mr={1} />}
        <Text fontSize="sm" fontWeight="medium">
          {label}
        </Text>
      </Flex>
    </Button>
  )
}

const ContentSkeleton = () => {
  const baseElementColor = 'currentColor'
  const baseElementBorderRadius = 'sm'
  const baseElementLgHeight = '6px'
  const baseElementSmHeight = '3px'
  const baseCardStackHeight = '28px'
  return (
    <Stack spacing={1} h={baseCardStackHeight}>
      <Box
        h={baseElementLgHeight}
        w="50%"
        bg={baseElementColor}
        borderRadius={baseElementBorderRadius}
      ></Box>

      <Box
        h={baseElementSmHeight}
        w="100%"
        bg={baseElementColor}
        borderRadius={baseElementBorderRadius}
      ></Box>
      <Box
        h={baseElementSmHeight}
        w="100%"
        bg={baseElementColor}
        borderRadius={baseElementBorderRadius}
      ></Box>
      <Box
        h={baseElementSmHeight}
        w="30%"
        bg={baseElementColor}
        borderRadius={baseElementBorderRadius}
      ></Box>
    </Stack>
  )
}
