export const SUPPORTED_GOOGLE_FONTS = [
  // Top Google Fonts
  'Anton',
  'Arimo',
  'Barlow',
  'Bebas Neue',
  'Bitter',
  'Cabin',
  'Cairo',
  'Crimson Text',
  'DM Sans',
  'Dosis',
  'Exo',
  'Fira Sans',
  'Heebo',
  'IBM Plex Sans',
  'Inconsolata',
  'Inter',
  'Josefin Sans',
  'Kanit',
  'Karla',
  'Lato',
  'Libre Baskerville',
  'Libre Franklin',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Mukta',
  'Mulish',
  'Nanum Gothic',
  'Noto Sans',
  'Noto Serif',
  'Nunito',
  'Nunito Sans',
  'Open Sans',
  'Oswald',
  'Oxygen',
  'PT Sans',
  'PT Sans Narrow',
  'PT Serif',
  'Playfair Display',
  'Poppins',
  'Quicksand',
  'Raleway',
  'Roboto',
  'Roboto Condensed',
  'Roboto Mono',
  'Roboto Slab',
  'Rubik',
  'Source Sans Pro',
  'Source Serif Pro',
  'Titillium Web',
  'Varela Round',
  'Work Sans',
  // Google Fonts used by existing themes
  'Cambo',
  'Overpass',
  'Space Mono',
  'Ovo',
  'Outfit',
  'Readex Pro',
  'Fraunces',
]

export const SUPPORTED_SYSTEM_FONTS = [
  'Arial',
  'Courier New',
  'Garamond',
  'Georgia',
  'Verdana',
  'Helvetica',
  'Tahoma',
  'Trebuchet MS',
  'Times New Roman',
]

export const SUPPORTED_FONTS = SUPPORTED_GOOGLE_FONTS.concat(
  SUPPORTED_SYSTEM_FONTS
).sort()

// TBD if we'll allow these and how we'll load them
// export const SUPPORTED_TYPEKIT_FONTS = [
//   'adonis-web',
//   'Eudoxus',
//   'p22-mackinac-pro',
//   'Halcom
// ]
