import { Mark, MarkType, Schema } from 'prosemirror-model'
import { EditorState } from 'prosemirror-state'

/*
 * Forked from:
 * https://github.com/ueberdosis/tiptap/blob/ab4a0e2507b4b92c46d293a0bb06bb00a04af6e0/packages/core/src/helpers/getMarkType.ts
 */
function getMarkType(nameOrType: string | MarkType, schema: Schema): MarkType {
  if (typeof nameOrType === 'string') {
    if (!schema.marks[nameOrType]) {
      throw Error(
        `There is no mark type named '${nameOrType}'. Maybe you forgot to add the extension?`
      )
    }

    return schema.marks[nameOrType]
  }

  return nameOrType
}

/*
 * Modified from:
 * https://github.com/ueberdosis/tiptap/blob/ab4a0e2507b4b92c46d293a0bb06bb00a04af6e0/packages/core/src/helpers/getMarkAttributes.ts
 * Will return all matched Mark Attributes as an array of attributes instead of the first one
 */
export function getAllMarkAttributes(
  state: EditorState,
  typeOrName: string | MarkType
): Record<string, any>[] {
  const type = getMarkType(typeOrName, state.schema)
  const { from, to, empty } = state.selection
  const marks: Mark[] = []

  if (empty) {
    if (state.storedMarks) {
      marks.push(...state.storedMarks)
    }

    marks.push(...state.selection.$head.marks())
  } else {
    state.doc.nodesBetween(from, to, (node) => {
      marks.push(...node.marks)
    })
  }
  // OUR CUSTOM LOGIC FROM HERE
  return marks
    .filter((mark) => mark.type.name === type.name)
    .map((filtered) => {
      return {
        ...filtered.attrs,
      }
    })
}

export const hasOneUniqueAttribute = <T>(
  attributes: Record<string, T>[],
  key: string
): T | false => {
  const uniqs = new Set<T>()
  for (const attr of attributes) {
    uniqs.add(attr[key])
    if (uniqs.size > 1) {
      return false
    }
  }
  return [...uniqs][0]
}

export const hasOneUniqueAttributeForMark = <T>(
  state: EditorState,
  mark: string | MarkType,
  attr: string
) => hasOneUniqueAttribute<T>(getAllMarkAttributes(state, mark), attr)
