import { useEffect } from 'react'

import { useUserContext } from '../user'
import { setHoneycombBaseData } from './honeycombApi'
import { useHoneycombSampleRate } from './hooks'
import { BaseUserData } from './types'

const ANONYMOUS_USER_KEY = 'anonymous_user'
const NO_ORG_ID = 'no_org_id'

export const HoneycombUserWrapper = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  useHoneycombSampleRate()

  const { user, anonymousUser, currentWorkspace, isUserLoading } =
    useUserContext()

  useEffect(() => {
    if (isUserLoading || !process.browser) return

    let honeycombUser: BaseUserData
    if (user) {
      const { id, email } = user
      const isGammaUser = email ? email.endsWith('@gamma.app') : false
      const userOrgId = currentWorkspace?.id || NO_ORG_ID
      honeycombUser = {
        userId: id,
        isGammaUser,
        userOrgId,
        anonymousUser: false,
        gammaUserFirstName: isGammaUser ? user.firstName : '',
      }
    } else {
      honeycombUser = {
        userId: ANONYMOUS_USER_KEY,
        isGammaUser: false,
        userOrgId: NO_ORG_ID,
        anonymousUser: true,
        gammaUserFirstName: '',
      }
    }

    setHoneycombBaseData(honeycombUser)
  }, [isUserLoading, user, anonymousUser, currentWorkspace])

  return <>{children}</>
}
