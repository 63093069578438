import { Flex } from '@chakra-ui/react'
import { callAllHandlers } from '@chakra-ui/utils'
import { Global } from '@emotion/react'
import {
  ColorStyle,
  DashStyle,
  ShapeStyles,
  Tldraw,
  TldrawApp,
  TldrawProps,
} from '@gamma-app/tldraw'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useUserContext } from 'modules/user'

import { EditToolbar } from './components/EditToolbar'
import { InsertToolbar } from './components/InsertToolbar'
import { ZoomToolbar } from './components/ZoomToolbar'
import { TldrawContext } from './hooks'
import { useAssetUpload } from './hooks/useAssetUpload'
import { getShapeStyles, getTextStyles } from './styles'

type TldrawWrapperProps = TldrawProps & {
  font: string
  isDark?: boolean
}

const defaultStyle: Partial<ShapeStyles> = {
  dash: DashStyle.Solid,
  isFilled: true,
  color: ColorStyle.White,
}

export const TldrawWrapper = ({
  isDark,
  font,
  ...props
}: TldrawWrapperProps) => {
  const { currentWorkspace } = useUserContext()
  const [instance, setInstance] = useState<TldrawApp>()
  const [mounted, setMounted] = useState(false)

  const handleMount = useCallback((app: TldrawApp) => {
    setInstance(app)
    app.style(defaultStyle, [])
    setMounted(true)
  }, [])

  const { onAssetCreate, onAssetDelete } = useAssetUpload(currentWorkspace?.id)

  useEffect(() => {
    if (!instance || isDark === undefined) return
    instance.setSetting('isDarkMode', isDark)
  }, [instance, isDark])

  const shapeStyles = useMemo(() => getShapeStyles(isDark), [isDark])
  const textStyles = useMemo(() => getTextStyles(font), [font])

  return (
    <Flex
      sx={{
        '.tl-canvas': {
          'div, text, textarea': {
            ...textStyles,
          },
          ...shapeStyles,
          'g[cursor="grab"][aria-label="link handle"]': {
            display: 'none',
          },
        },
      }}
      onDrop={() => {
        // console.log('[TldrawWrapper] DROP EVENT', e)
      }}
      onPaste={() => {
        // console.log('[TldrawWrapper] PASTE EVENT', e)
      }}
      position="relative"
      h="100%"
      w="100%"
      className="highlight-mask"
    >
      <Global
        styles={{
          '#__textLabelMeasure, #__textMeasure': {
            ...textStyles,
          },
        }}
      />
      <TldrawContext.Provider value={instance || ({} as TldrawApp)}>
        <Tldraw
          onAssetCreate={onAssetCreate}
          onAssetDelete={onAssetDelete}
          {...props}
          onMount={callAllHandlers(props.onMount, handleMount)}
        />
        {mounted && !props.readOnly && (
          <>
            <EditToolbar />
            <InsertToolbar />
          </>
        )}
        {mounted && (
          <>
            <ZoomToolbar />
          </>
        )}
      </TldrawContext.Provider>
    </Flex>
  )
}
