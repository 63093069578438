/**
 * Resets any routing-related session storage values
 */
import { useEffect, useRef } from 'react'

import { useSessionStorage } from 'utils/hooks/useSessionStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

export const useInitRoutingSessionStorage = () => {
  const hasRun = useRef<boolean>(false)
  const [_, setLastHomeUrl] = useSessionStorage<string | null>(
    USER_SETTINGS_CONSTANTS.lastHomeUrl,
    '/'
  )
  useEffect(() => {
    if (hasRun.current || typeof window === 'undefined') return

    // Reset session storage
    setLastHomeUrl('/')
    hasRun.current = true
  }, [setLastHomeUrl])
}
