import { Extension, JSONContent } from '@tiptap/core'
import { DOMSerializer, Fragment, Schema, Slice } from 'prosemirror-model'
import { EditorState, Plugin, Transaction } from 'prosemirror-state'

import { createSelectionNearLastTo } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

import { defaultHandlePaste } from './defaultHandlePaste'
import { transformGoogleDocDOM } from './googleDocs'
import { handleLinkPaste } from './handleLinkPaste'
import { handleMarkdownPaste } from './handleMarkdownPaste'
import { flattenListTree, wrapListItems } from './list'
import { splitCards } from './splitCards'
import { isElementEmpty, querySelectorArray } from './utils'

// Extension for copying and pasting between Gamma and other apps like Google Docs
// Examples: https://codesandbox.io/s/bullet-examples-re319z?file=/index.html
export const Clipboard = Extension.create({
  name: 'clipboard',

  addProseMirrorPlugins() {
    const editor = this.editor
    const schema = editor.state.schema

    let dragging: { slice: Slice; move: boolean } | null = null
    return [
      new Plugin({
        /**
         * NOTE(jordan) This is the only reasonable way I found to fix the selection after a cut
         * since the cut event handler happens before PM's cut.  We have to look through all transactions
         * and use the `uiEvent` == "cut" to determine if a cut happened and to fix the selection in
         * an appended transaction
         */
        appendTransaction(
          transactions: Transaction[],
          _oldState: EditorState,
          newState: EditorState
        ) {
          const cutTransaction = transactions.find(
            (t) => t.getMeta('uiEvent') === 'cut'
          )
          if (!cutTransaction) {
            return null
          }

          const sel = createSelectionNearLastTo(cutTransaction)
          return sel ? newState.tr.setSelection(sel) : null
        },
        props: {
          handleDOMEvents: {
            drop(view) {
              // capture view.dragging since `transformPasted` gets called in `editHandlers.drop` after
              // `view.dragging` is nulled out
              dragging = view.dragging
              requestAnimationFrame(() => {
                dragging = null
              })
              return
            },
          },
          // @ts-ignore - the Typescript wants a full DOMSerializer, but per the docs we
          // only need serializeFragment: https://prosemirror.net/docs/ref/#view.EditorProps.clipboardSerializer
          clipboardSerializer: {
            serializeFragment: (fragment) =>
              serializeFragment(fragment, this.editor.schema),
          },
          // Takes incoming HTML from other tools (eg Google Docs) and parses it
          transformPastedHTML,
          // Takes the slice ProseMirror parses from the HTML and transforms it further
          // This runs after transformPastedHTML
          transformPasted: (slice): Slice => {
            const hasSlice = !!dragging?.slice
            const result =
              // hasSlice is true when the same editor programmatically sets the drag data
              hasSlice ? slice : transformOutsidePastedContent(slice, schema)

            return result
          },
          // Parse plain text
          handlePaste: (view, event, slice) => {
            return (
              handleLinkPaste(editor, event, slice) ||
              handleMarkdownPaste(view, event) ||
              defaultHandlePaste(view, event, slice)
            )
          },
        },
      }),
    ]
  },
})

export const serializeFragment = (fragment: Fragment, schema: Schema) => {
  // Use the built-in serializer, then we'll modify its output
  const serializer = DOMSerializer.fromSchema(schema)
  // ProseMirror will return a DocumentFragment when the third `target` prop is not sent
  const doc = serializer.serializeFragment(fragment) as DocumentFragment
  return transformCopiedDocFragment(doc)
}

export const transformCopiedDocFragment = (
  doc: DocumentFragment
): DocumentFragment => {
  // Convert our flat list structure into a semantic tree
  querySelectorArray(doc, 'li').forEach((elt) => {
    wrapListItems(elt)
  })
  return doc
}

export const transformOutsidePastedContent = (
  slice: Slice,
  schema: Schema
): Slice => {
  let content = slice.toJSON()?.content
  if (
    !content ||
    content.length <= 1 ||
    // This is an internal paste (Gamma -> Gamma), so don't do anything
    content[0].type === 'document' ||
    content[0].type === 'card' ||
    // If all nodes are inline (e.g a few spans of text), we don't want to try splitting cards
    // and our fixTopLevelInlineNodes would definitely break.
    content.every((node) => schema.nodes[node.type].isInline)
  ) {
    return slice
  }

  try {
    content = fixTopLevelInlineNodes(content, schema)
    content = fixFootnoteLineBreaks(content)
    content = splitCards(content)
    return Slice.fromJSON(schema, { ...slice, content })
  } catch (err) {
    console.error('Error transforming slice', err)
    return slice
  }
}

export const transformPastedHTML = (html: string): string => {
  try {
    // Parse the HTML
    const dom = new DOMParser().parseFromString(html, 'text/html')

    querySelectorArray(dom, 'li', true).forEach(flattenListTree)
    querySelectorArray(dom, 'img').forEach(pullImageOutOfTextBlock)
    transformGoogleDocDOM(dom)
    // This should run last, since other steps might create empty paragraphs
    querySelectorArray(dom, 'p').forEach(removeEmptyParagraphs)

    return dom.body.innerHTML
  } catch (err) {
    console.error('Error transforming pasted HTML', err)
    return html // Return the original, untransformed
  }
}

// Tools like Google Docs put images inside of paragraphs, which confuses ProseMirror's parser
// since our schema has them as block-level. This pulls them out into their own blocks.
const pullImageOutOfTextBlock = (img: Element) => {
  const textBlock = img.closest('p, h1, h2, h3, h4, h5, h6, ul, ol, li')
  if (!textBlock) return
  textBlock.after(img)
}

const removeEmptyParagraphs = (elt: HTMLElement) => {
  if (isElementEmpty(elt)) {
    elt.remove()
  }
}

// Tools like Google Docs have block elements like paragraphs inside footnotes,
// which confuses ProseMirror's HTML parser: putting a paragraph inside a paragraph
// will break the first paragraph. This cleans up those broken paragraphs by
// detecting cases where a footnote is the first node of a new block and merging
// it back into the previous block.
const fixFootnoteLineBreaks = (content: JSONContent[]): JSONContent[] => {
  const newContent: (JSONContent | null)[] = [...content]
  // Loop through content
  content.forEach((node, i) => {
    if (i < 1) return
    const prev = content[i - 1]
    if (prev.type !== node.type || !prev.content) return
    const firstChild = node.content?.[0]
    if (!firstChild || firstChild.type !== 'footnote') return
    // Merge with previous
    prev.content = prev.content.concat(node.content!)
    newContent[i] = null
  })
  return newContent.filter((node) => node !== null) as JSONContent[]
}

// Sometimes when we parse from Google Docs, we find stray spans or other inline
// elements that aren't in their parent block. This can happen when a footnote or
// comment breaks a block. This catches the stragglers and merges them back into
// their original block, or throws them out if no parent block can be found.
const fixTopLevelInlineNodes = (
  content: JSONContent[],
  schema: Schema
): JSONContent[] => {
  const blocks: JSONContent[] = []
  content.forEach((node) => {
    if (!node.type) return
    const isBlock = schema.nodes[node.type]?.isBlock
    if (isBlock) {
      // Add blocks to the list
      blocks.push(node)
    } else {
      // If we find inline elements, add them into the previous block
      const lastBlock = blocks[blocks.length - 1]
      // If there is no previous block, just throw out these nodes
      if (!lastBlock || !lastBlock.content) return
      lastBlock.content.push(node)
    }
  })
  return blocks
}

export const isPastedProsemirrorHtml = (html: string): boolean => {
  const dom = new DOMParser().parseFromString(html, 'text/html')
  return !!(dom && dom.querySelector('[data-pm-slice]'))
}
