export const DefaultCellContent = [
  {
    type: 'heading',
    attrs: {
      level: 2,
    },
    content: [
      {
        type: 'text',
        text: `Thing`,
      },
    ],
  },
  {
    type: 'paragraph',
    content: [
      {
        type: 'text',
        text: `Description`,
      },
    ],
  },
]

export const EmptyCellContent = [
  {
    type: 'heading',
    attrs: {
      level: 2,
    },
  },
]
