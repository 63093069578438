import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import { ImageSearch } from '../components/ImageSearch'
import {
  MediaProviderType,
  MediaProviderPanelProps,
} from '../types/MediaProvider'
import GoogleImageIcon from './icons/google-image.svg'

const GoogleImagePanel = ({
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps) => {
  return (
    <>
      <ImageSearch
        updateAttributes={updateAttributes}
        currentImageUrl={currentAttributes.src}
        defaultQuery={currentAttributes.query || ''}
        provider="google"
      />
    </>
  )
}

export const GoogleImageProvider: MediaProviderType = {
  key: 'image.google',
  Panel: GoogleImagePanel,
  label: 'Google images',
  icon: brands('google'),
  image: GoogleImageIcon,
  keywords: ['image', 'photo'],
}
