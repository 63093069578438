import TiptapHighlight from '@tiptap/extension-highlight'
import { mergeAttributes } from '@tiptap/react'

import { ExtensionPriorityMap } from '../constants'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    setHighlightVariant: {
      setHighlightVariant: (attributes?: { variant: string }) => ReturnType
    }
  }
}

export const Highlight = TiptapHighlight.extend({
  name: 'highlight',
  priority: ExtensionPriorityMap.Highlight,
  excludes: 'textColor highlight',
  addAttributes() {
    return {
      variant: {
        default: 'yellow',
      },
    }
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setHighlightVariant:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes)
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'mark[class=highlight]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'mark',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'highlight',
      }),
    ]
  },
}).configure({ multicolor: true })
