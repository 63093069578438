import {
  Box,
  CloseButton,
  RenderProps,
  Text,
  useToast,
  ToastId,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useRef } from 'react'

import { PANEL_MIN_WIDTH } from 'modules/panels/constants'
import { useUserContext } from 'modules/user'

import { AccessLinkSignUpProps } from './AccessLinkSignUp'

import {
  checkAccessLinkForLoggedOutUser,
  DocWithIdAndAccessLinks,
  AccessLinkSignUp,
} from '.'

type ToastSignUpProps = {
  onClose: RenderProps['onClose']
} & Pick<AccessLinkSignUpProps, 'docId' | 'accessLink' | 'refetchFn'>

const ToastSignUp = ({ onClose, ...rest }: ToastSignUpProps) => {
  return (
    <Box
      bg="white"
      border="0.5px solid var(--chakra-colors-gray-100)"
      borderRadius="md"
      shadow="md"
      maxWidth={`calc(${PANEL_MIN_WIDTH}px - 1rem)`}
      display="column"
      p={4}
      position="relative"
    >
      <CloseButton
        position="absolute"
        size="sm"
        onClick={onClose}
        right={1}
        top={1}
      />
      <VStack align="stretch" mt={2}>
        <Text fontWeight="bold">You're not signed in.</Text>
        <AccessLinkSignUp {...rest} textIsBold={false} spacing={4} />
      </VStack>
    </Box>
  )
}

export const useToastSignUp = (
  doc: DocWithIdAndAccessLinks | undefined,
  enabled: boolean
) => {
  const { isUserLoading, user, refetch } = useUserContext()
  const accessLink = checkAccessLinkForLoggedOutUser(doc, user)
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()
  const docId = doc?.id

  useEffect(() => {
    const showToastSignUp =
      enabled && !user && !isUserLoading && docId && accessLink
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
    if (!showToastSignUp) return

    toastIdRef.current = toast({
      position: 'bottom-left',
      render: ({ onClose }) =>
        ToastSignUp({ refetchFn: refetch, docId, accessLink, onClose }),
      isClosable: true,
      duration: null,
    })
  }, [refetch, user, isUserLoading, accessLink, toast, docId, enabled])
}
