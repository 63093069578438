// @ts-ignore
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Extension } from '@tiptap/core'

import {
  createDraftCommentsPlugin,
  DraftCommentsPluginKey,
} from './DraftCommentsPlugin'
import {
  CreateDraftCommentAction,
  DraftComment,
  RemoveDraftCommentAction,
} from './types'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    draftComments: {
      removeDraftComments?: (
        targetIds: DraftComment | DraftComment[]
      ) => ReturnType
      createDraftComment?: (comment: DraftComment) => ReturnType
    }
  }
}

export const DraftCommentsExtension = Extension.create({
  name: 'draftComments',

  addCommands() {
    return {
      removeDraftComments:
        (comments: DraftComment[] | DraftComment) =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            const payload: RemoveDraftCommentAction = {
              type: 'removeDraftComment',
              comments: Array.isArray(comments) ? comments : [comments],
            }
            tr.setMeta(DraftCommentsPluginKey, payload)
            dispatch(tr)
          }

          return true
        },
      createDraftComment:
        (comment: DraftComment) =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            const payload: CreateDraftCommentAction = {
              type: 'createDraftComment',
              comment,
            }
            tr.setMeta(DraftCommentsPluginKey, payload)
            dispatch(tr)
          }

          return true
        },
    }
  },

  addProseMirrorPlugins() {
    return [createDraftCommentsPlugin()]
  },
})
