// Based on https://github.com/ueberdosis/tiptap/tree/main/demos/src/Experiments/GlobalDragHandle

import { Editor, findParentNodeClosestToPos } from '@tiptap/core'
import {
  NodeSelection,
  Plugin,
  PluginKey,
  TextSelection,
} from 'prosemirror-state'
import { dropPoint } from 'prosemirror-transform'
import { EditorView } from 'prosemirror-view'

import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'
import { findSelectionInsideNode } from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'
import { isChrome } from 'utils/deviceDetection'
import { elementReady } from 'utils/dom'

import {
  DragAnnotationData,
  DropAnnotationEvent,
} from '../Annotatable/AnnotationExtension/types'
import { computeDragAnnotationData } from '../Annotatable/utils'
import { isCardCollapsed } from '../Card/CardCollapse'
import {
  blockAtCoords,
  CARD_EDGE_HOVER_THRESHOLD,
  Coords,
  fixYSyncSelection,
  getHandleOffset,
  HANDLE_WIDTH,
  RenderedNode,
} from './utils'

// These values should match the styles in globals.scss

export const HANDLE_HOVERING_ATTR = 'data-drag-handle-hovering'

class GlobalDragHandleState {
  constructor(public dragging: DragAnnotationData | null = null) {}
}

const GlobalDragHandlePluginKey = new PluginKey<GlobalDragHandleState>(
  'globalDragHandle'
)

type GlobalDragHandlePluginOpts = {
  editor: Editor
  scrollerSelector: string
  storage: any
}
export const GlobalDragHandlePlugin = (opts: GlobalDragHandlePluginOpts) => {
  let dragHandleEl: HTMLElement | undefined
  let editorCoreRootEl: HTMLElement | null | undefined
  let draggingNode: RenderedNode | undefined
  let coords: Coords | undefined
  const scrollingParent = document.querySelector(opts.scrollerSelector)
  const editor = opts.editor

  // Events we attach to specific elements
  const onScroll = () => {
    hideDragHandle()
  }

  const onClick = () => {
    if (!draggingNode || !draggingNode.el) return
    if (editor.view.dragging) return
    const { pos, node } = draggingNode

    if (node.type.name === 'table') {
      editor
        .chain()
        .focus(undefined, {
          scrollIntoView: false,
        })
        .selectTable(pos + 2)
        .run()
    } else if (node.isTextblock) {
      editor
        .chain()
        .focus(undefined, {
          scrollIntoView: false,
        })
        .setTextSelection({ from: pos, to: pos + node.nodeSize })
        .run()
    } else {
      editor
        .chain()
        .focus(undefined, {
          scrollIntoView: false,
        })
        .command(({ tr, state }) => {
          // TipTap setNodeSelection incorrectly determines the min
          // pos allowable as 3, which doesnt work for the first card (pos=2)
          // See https://github.com/ueberdosis/tiptap/blob/43611ea2e70d3dc66ff907ba7ca377bf74814543/packages/core/src/commands/setNodeSelection.ts#L19-L20
          const $pos = state.doc.resolve(pos)
          const selection = new NodeSelection($pos)
          tr.setSelection(selection)
          return true
        })
        .run()
    }
  }

  const onDragStart = (event: DragEvent) => {
    const { view, state } = editor
    if (!event.dataTransfer) {
      return
    }

    // We should have already set a draggingNode when we rendered the handle
    if (!draggingNode || !draggingNode.el) return
    const { el, pos } = draggingNode
    const referenceData = el.dataset.contentReference
    let xOffset = 0
    let yOffset = 0
    if (isChrome && referenceData) {
      // Chrome drag previews must use a manually calculated offset
      // to display correctly for cropped images
      // The data attribute contains the offset values:
      //   data-content-reference="referenceXOffset,referenceYOffset"
      const [x, y] = referenceData.split(',').map(parseFloat)
      if (x && y) {
        xOffset = x
        yOffset = y
      }
    }
    // Create the preview
    event.dataTransfer.clearData()
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/html', el.innerHTML)
    event.dataTransfer.setData('text/plain', el.textContent || '')
    event.dataTransfer.setDragImage(el, xOffset, yOffset)

    // Create a NodeSelection around it so we drag the entire block, not just the text inside
    // ProseMirror will erase this selection on drop
    const selection = NodeSelection.create(state.doc, pos)
    const slice = selection.content()
    view.dispatch(view.state.tr.setSelection(selection))

    view.dragging = { slice, move: true }

    const dragData = computeDragAnnotationData({
      pos,
      editor,
      from: selection.from,
      to: selection.to,
    })
    if (dragData) {
      // @ts-ignore
      view.dragging.annotations = dragData
    }
  }

  const onDragEnd = () => {
    // Placeholder for future use
  }

  const hideDragHandle = () => {
    if (!dragHandleEl) return
    dragHandleEl.style.display = 'none'
  }

  const showDragHandle = () => {
    if (!dragHandleEl) return
    dragHandleEl.style.display = 'block'
  }

  const moveDragHandle = (view: EditorView) => {
    document
      .querySelectorAll(`[${HANDLE_HOVERING_ATTR}]`)
      .forEach((elt) => elt.removeAttribute(HANDLE_HOVERING_ATTR))

    if (
      !editor.isEditable ||
      !coords ||
      !dragHandleEl ||
      !opts.storage.enabled
    ) {
      hideDragHandle()
      return
    }

    draggingNode = blockAtCoords(coords, view)
    // If we don't find anything, or we never instantiated, don't show a drag handle
    if (!draggingNode || !draggingNode.el) {
      hideDragHandle()
      return
    }

    const { el, node } = draggingNode
    const handleOffset = getHandleOffset(node, el)
    // Add a data- attribute to the element being hovered
    // Important: this requires an ignoreMutation on the layout element
    // or else it will trigger an infinite loop!
    const parentLayout = el.closest('.block-gridLayout, .block-gallery')
    if (parentLayout) {
      parentLayout.setAttribute(HANDLE_HOVERING_ATTR, 'true')
    }

    // check if drag handle is in table
    const $pos = editor.state.doc.resolve(draggingNode.pos)
    const isolatingParent = findParentNodeClosestToPos(
      $pos,
      (n) => !!n.type.spec.isolating
    )
    const isolatingParentNode = isolatingParent?.node.type.name || ''

    try {
      let { top, left } = el.getBoundingClientRect()
      const isExpandedCard = isCardNode(node) && !isCardCollapsed(node)
      if (isExpandedCard) {
        // Top level cards should show a drag handle in their upper left corner
        // but only when the mouse is near that point (not somewhere in the body)
        const cardBody = el.querySelector('[data-card-body]')
        if (!cardBody) {
          hideDragHandle()
          return
        }
        const { top: cardTop, left: cardLeft } =
          cardBody?.getBoundingClientRect()
        const distanceFromTop = Math.abs(coords.top - cardTop)
        if (Math.abs(distanceFromTop) > CARD_EDGE_HOVER_THRESHOLD) {
          // Were hovered over somewhere in the card (not on a block),
          // but its space that isnt near the top.
          hideDragHandle()
          return
        }

        top = cardTop + handleOffset.top
        left = cardLeft + handleOffset.left
      } else {
        top += window.pageYOffset + handleOffset.top
        left += window.pageXOffset + handleOffset.left
      }

      dragHandleEl.setAttribute('data-isolating-parent', isolatingParentNode)
      if (
        isolatingParentNode === 'tableCell' ||
        isolatingParentNode === 'gridCell'
      ) {
        // get rid of the margin left and shift over
        left += 12
      }

      dragHandleEl.style.left = `${-HANDLE_WIDTH + left}px`
      dragHandleEl.style.top = `${top}px`
      showDragHandle()
    } catch (error) {
      console.warn('[GlobalDragHandle] Error showing drag handle', error)
      hideDragHandle()
    }
  }

  return new Plugin({
    key: GlobalDragHandlePluginKey,
    state: {
      init() {
        return new GlobalDragHandleState()
      },

      apply(_transaction, pluginState) {
        return pluginState
      },
    },
    // Init code that runs at editor start
    view() {
      let onMouseMove
      elementReady('#editor-core-root')
        .then((el) => {
          editorCoreRootEl = el
          dragHandleEl = document.createElement('div')
          editorCoreRootEl?.appendChild(dragHandleEl)
          dragHandleEl.draggable = true
          dragHandleEl.classList.add('global-drag-handle')

          // Add listeners from above
          dragHandleEl.addEventListener('dragstart', onDragStart)
          dragHandleEl.addEventListener('dragend', onDragEnd)
          dragHandleEl.addEventListener('click', onClick)
          scrollingParent?.addEventListener('scroll', onScroll)
          onMouseMove = (event: MouseEvent) => {
            // Identify the element you're hovering
            coords = {
              left: event.clientX,
              top: event.clientY,
            }
            moveDragHandle(editor.view)
          }

          document.addEventListener('mousemove', onMouseMove)
        })
        .catch(() => {
          console.error(
            '[GlobalDragHandlePlugin.view] Could not find editor-core-root element. This is highly unexpected.'
          )
        })
      return {
        // Adjust the drag handle whenever the doc state changes (eg someone else edits, you open a card, etc.)
        update(view) {
          moveDragHandle(view)
        },

        // Clean up when plugin unregisters
        destroy() {
          editorCoreRootEl =
            document?.querySelector<HTMLElement>('#editor-core-root')
          if (onMouseMove && dragHandleEl) {
            scrollingParent?.removeEventListener('scroll', onScroll)
            editorCoreRootEl?.removeChild(dragHandleEl)
            dragHandleEl.removeEventListener('dragstart', onDragStart)
            dragHandleEl.removeEventListener('dragend', onDragEnd)
            dragHandleEl.removeEventListener('click', onClick)
            dragHandleEl = undefined
            document.removeEventListener('mousemove', onMouseMove)
          }
        },
      }
    },
    props: {
      handleDOMEvents: {
        drop(view) {
          // Store the annotation drag data here temporarily in the plugin state
          // this because the native prosemirror drop handler clears out the dragging data
          // before it calls pluginHandlers for dropHandler
          const annotationData = (view.dragging as any)
            ?.annotations as DragAnnotationData | null
          const pluginState = GlobalDragHandlePluginKey.getState(view.state)
          if (!pluginState) {
            return false
          }
          pluginState.dragging = annotationData
          return
        },
      },
      handleDrop(view, e, slice, move) {
        const pluginState = GlobalDragHandlePluginKey.getState(view.state)
        const dragAnnotationData = pluginState?.dragging
        if (pluginState) {
          // on drop always get rid of the drag data
          pluginState.dragging = null
        }
        //
        // START FORK OF prosemirror-view editorHandlers.drop
        //

        const eventPos = view.posAtCoords({
          left: e.clientX,
          top: e.clientY,
        })

        /**
         * We always want to return true from this plugin, since it's the lowest priority
         * `handleDrop`.  This plugin forks Prosemirrors `editHandlers.drop` logic, thus
         * we never want to invoke the default behavior
         */
        if (!eventPos) {
          return true
        }
        const $mouse = view.state.doc.resolve(eventPos.pos)
        if (!$mouse) {
          return true
        }
        if (!slice) {
          return true
        }
        // we added this
        fixYSyncSelection(editor, slice)

        e.preventDefault()
        let insertPos = slice
          ? dropPoint(view.state.doc, $mouse.pos, slice)
          : $mouse.pos
        if (insertPos == null) insertPos = $mouse.pos

        const tr = view.state.tr
        if (move) tr.deleteSelection()

        const pos = tr.mapping.map(insertPos)
        const isNode =
          slice.openStart == 0 &&
          slice.openEnd == 0 &&
          slice.content.childCount == 1
        const beforeInsert = tr.doc
        if (isNode) {
          tr.replaceRangeWith(pos, pos, slice.content!.firstChild!)
        } else {
          tr.replaceRange(pos, pos, slice)
        }
        if (tr.doc.eq(beforeInsert)) {
          return true
        }

        const $pos = tr.doc.resolve(pos)

        // prosemirror assumes that nodes are either node selectable or text selectable
        // we have a type of node (Card) which is a block node that is not selectable
        if (
          isNode &&
          // node is selectable (NOTE: card nodes are not selectable)
          $pos.nodeAfter &&
          // inserting the node didn't change the markup from the slice
          // so nothing was transformed or wrap
          $pos.nodeAfter.sameMarkup(slice.content!.firstChild!)
        ) {
          const sel = findSelectionInsideNode($pos)
          if (sel) {
            tr.setSelection(sel)
          }
        } else {
          let end = tr.mapping.map(insertPos)
          tr.mapping.maps[tr.mapping.maps.length - 1].forEach(
            (_from, _to, _newFrom, newTo) => (end = newTo)
          )
          const sel = TextSelection.between($pos, tr.doc.resolve(end))
          tr.setSelection(sel)
        }

        view.focus()

        if (dragAnnotationData) {
          tr.setMeta('annotationEvent', <DropAnnotationEvent>{
            type: 'drop',
            dragging: dragAnnotationData,
            droppedBlockPos: pos,
          })
        }
        view.dispatch(tr.setMeta('uiEvent', 'drop'))

        // GlobalDragHandle replaces prosemirror's editHandlers.drop
        // always return true to not allow prosemirror from duplicating
        // this behavior
        return true
      },
    },
  })
}
