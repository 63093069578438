import tinycolor from 'tinycolor2'

import {
  BackgroundType,
  DEFAULT_THEME_BACKGROUND,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import {
  getContainerBackgroundColor,
  getContainerOptions,
} from 'modules/tiptap_editor/styles/containerStyles'
import { colorWithLightness, lightenColor } from 'utils/color'

import {
  DARK_TEXT_LIGHTNESS,
  DEFAULT_ACCENT_COLOR,
  DEFAULT_BODY_COLOR,
  DEFAULT_BODY_COLOR_DARK,
  DEFAULT_FONTS,
  DEFAULT_HEADING_COLOR,
  DEFAULT_HEADING_COLOR_DARK,
  LIGHT_TEXT_LIGHTNESS,
} from './constants'
import { Theme } from './types'

export const getThemeBackgroundOrDefault = (theme: Theme) =>
  theme.config?.background &&
  theme.config.background.type !== BackgroundType.NONE
    ? theme.config.background
    : DEFAULT_THEME_BACKGROUND

export const isColorReadable = (
  textColor: string,
  backgroundColor: string,
  options: tinycolor.WCAG2Options = {
    size: 'large',
    level: 'AA',
  }
) => {
  return tinycolor.isReadable(textColor, backgroundColor, options)
}

export const getThemeCSSVars = (theme: Theme, isDarkOverride?: boolean) => {
  const themeContainer = getContainerOptions(theme)
  const isDark = isDarkOverride ?? themeContainer.isDark
  const { bodyFont, headingFont } = theme
  const accentColor = getAccentColor(theme)
  const {
    bodyColor = isDark ? DEFAULT_BODY_COLOR_DARK : DEFAULT_BODY_COLOR,
    headingColor = isDark ? DEFAULT_HEADING_COLOR_DARK : DEFAULT_HEADING_COLOR,
    fontSize = DEFAULT_FONTS.fontSize,
  } = theme.config

  const cardContainerColor = getContainerBackgroundColor(theme, undefined, {
    ...themeContainer,
    isDark,
  })

  const linkColor = isColorReadable(accentColor, cardContainerColor)
    ? accentColor
    : colorWithLightness(
        accentColor,
        isDark ? LIGHT_TEXT_LIGHTNESS : DARK_TEXT_LIGHTNESS
      )

  return {
    '--body-font': bodyFont || DEFAULT_FONTS.bodyFont,
    '--heading-font': headingFont || DEFAULT_FONTS.headingFont,
    '--accent-color': accentColor,
    '--font-size': `${fontSize}em`,
    '--accent-color-50': colorWithLightness(accentColor, 0.95),
    '--accent-color-100': colorWithLightness(accentColor, 0.9),
    '--accent-color-500': colorWithLightness(accentColor, 0.5),
    // Lighten or darken the colors if they aren't readable
    '--body-color': isColorReadable(bodyColor, cardContainerColor)
      ? bodyColor
      : colorWithLightness(
          bodyColor,
          isDark ? LIGHT_TEXT_LIGHTNESS : DARK_TEXT_LIGHTNESS
        ),
    '--heading-color': isColorReadable(headingColor, cardContainerColor)
      ? headingColor
      : colorWithLightness(
          headingColor,
          isDark ? LIGHT_TEXT_LIGHTNESS : DARK_TEXT_LIGHTNESS
        ),
    '--link-color': linkColor,
    '--link-color-hover': lightenColor(linkColor, -10),
    '--card-container-color': cardContainerColor,
  }
}

export const isThemeDark = (theme: Theme) =>
  theme.config.container?.isDark || false

export const getAccentColor = (theme: Theme) =>
  theme.accentColor ?? DEFAULT_ACCENT_COLOR
