import { User } from '@gammatech/schema'

import { BackgroundOptions } from 'modules/tiptap_editor/styles/backgroundStyles'
import { ContainerOptions } from 'modules/tiptap_editor/styles/containerStyles'

import { ColorAttrs } from '../tiptap_editor/components/panels/ColorPanel'

export type Theme = {
  id: string
  workspaceId?: string
  name: string
  archived: boolean
  createdBy?: Partial<Pick<User, 'id' | 'displayName' | 'email'>>
  createdTime?: string
  updatedTime?: string
  priority: number
  // Styles
  headingFont?: string
  bodyFont?: string
  accentColor?: string
  logoUrl?: string
  // Advanced
  config: {
    background?: BackgroundOptions
    container?: ContainerOptions
    containerStyles?: Record<string, any>
    contentStyles?: Record<string, any>
    fontSize?: number
    headingColor?: string
    bodyColor?: string
    colors?: ColorAttrs[]
  }
  docCount?: number
}

export const emptyTheme: Theme = {
  id: 'new',
  name: '',
  archived: false,
  priority: 0,
  config: {},
}
