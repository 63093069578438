import React, { useCallback } from 'react'

import { preventDefaultToAllowDrop } from '../handlers'

export const DRAGOVER_CLASS = 'is-dragging-over'

// CSS has no selector for when you're dragging over something,
// so we need to use JS. This adds a class when you drag over
// and clears it when you leave or drop.
//
// Important caveats:
// 1. When using with ProseMirror NodeViews, make sure you ignore the mutations
//    of adding this class to avoid an infinite loop.
// 2. These events don't handle nested elements well, so you'll want to apply
//    pointer-events: none to the dragover element.
// Based on https://htmldom.dev/highlight-an-element-when-dragging-a-file-over-it/
export const useDroppable = (
  ref: React.RefObject<HTMLElement>,
  allowDrop?: (ev: React.DragEvent) => boolean
) => {
  const handleDragOver = useCallback(
    (ev: React.DragEvent) => {
      if (allowDrop && !allowDrop(ev)) return
      ref.current?.classList.add(DRAGOVER_CLASS)
    },
    [ref]
  )
  const handleDragLeave = useCallback(
    () => ref.current?.classList.remove(DRAGOVER_CLASS),
    [ref]
  )

  return {
    onDragOver: preventDefaultToAllowDrop,
    onDragEnter: handleDragOver,
    onDragLeave: handleDragLeave,
    onDrop: handleDragLeave,
  }
}
