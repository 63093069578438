import { AspectRatio, Box, Flex } from '@chakra-ui/react'
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import tinycolor from 'tinycolor2'

import { Vibe } from './types'
import { updatePointers, XYPositionToColor } from './utils'

type PointerProps = {
  isPrimary?: boolean
  noTransition?: boolean
}

export const Pointer = forwardRef<HTMLDivElement, PointerProps>(
  function Pointer({ isPrimary = false, noTransition = false }, ref) {
    return (
      <Box
        ref={ref}
        borderRadius="full"
        boxSize={isPrimary ? 7 : 5}
        pos="absolute"
        zIndex={isPrimary ? 2 : 1}
        shadow="base"
        border="2px solid white"
        pointerEvents="none"
        transitionProperty={!noTransition ? 'transform' : 'none'}
        transitionDuration="normal"
      />
    )
  }
)

type ColorWheelProps = {
  vibe: Vibe
  color: tinycolor.Instance
  updateColor: (color: tinycolor.Instance) => void
  colors: tinycolor.Instance[]
}

export const ColorWheel = ({ updateColor, colors }: ColorWheelProps) => {
  // Store pointerRefs for each of the pointers, plus the wheels as a whole
  const wheelRef = useRef<HTMLDivElement>(null)
  const pointerARef = useRef<HTMLDivElement>(null)
  const pointerBRef = useRef<HTMLDivElement>(null)
  const pointerCRef = useRef<HTMLDivElement>(null)
  const pointerDRef = useRef<HTMLDivElement>(null)
  const pointerERef = useRef<HTMLDivElement>(null)
  const pointerRefs = useMemo(
    () => [pointerARef, pointerBRef, pointerCRef, pointerDRef, pointerERef],
    []
  )

  useEffect(() => {
    const pointerEls = pointerRefs.map((ref) => ref.current)
    updatePointers(colors, pointerEls, wheelRef.current)
  }, [colors, pointerRefs])

  // We don't use a real drag because you can click anywhere on the wheel, and
  // we don't want a preview getting moved around. To simulate the effect we want,
  // we detect a mousedown on the wheel and then track movements until the mouse
  // comes up.
  const handleDrag = useCallback(
    (e: React.MouseEvent | MouseEvent) => {
      if (!wheelRef.current) return
      const bounds = wheelRef.current.getBoundingClientRect()
      if (!e.clientX && !e.clientY) return // For some reason, there's a stray event at the end where these are both 0
      updateColor(
        XYPositionToColor(
          wheelRef,
          e.clientX - bounds.left,
          e.clientY - bounds.top
        )
      )
    },
    [updateColor]
  )
  const handleDragEnd = useCallback(() => {
    document.removeEventListener('mousemove', handleDrag)
  }, [handleDrag])

  const handleDragStart = useCallback(
    (e: React.MouseEvent) => {
      document.addEventListener('mousemove', handleDrag)
      document.addEventListener('mouseup', handleDragEnd, { once: true })
      handleDrag(e)
    },
    [handleDrag, handleDragEnd]
  )

  return (
    <Box onMouseDown={handleDragStart} p={4} m={-4} position="relative">
      <Pointer ref={pointerARef} noTransition isPrimary />
      <Pointer ref={pointerBRef} />
      <Pointer ref={pointerCRef} />
      <Pointer ref={pointerDRef} />
      <Pointer ref={pointerERef} />

      <AspectRatio ratio={1 / 1}>
        <Flex
          ref={wheelRef}
          position="relative"
          borderRadius="full"
          shadow="lg"
        >
          <Flex
            pos="absolute"
            borderRadius="full"
            bg="radial-gradient(circle closest-side, rgb(255, 255, 255), transparent)"
            inset={0}
            zIndex={1}
          />
          <Flex
            pos="absolute"
            inset={0}
            bg="conic-gradient(red, yellow, lime, aqua, blue, magenta, red)"
            transform="rotateZ(270deg)"
            borderRadius="full"
          />
        </Flex>
      </AspectRatio>
    </Box>
  )
}

export const ColorWheelMemo = memo(ColorWheel)
