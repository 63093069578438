import { VStack } from '@chakra-ui/react'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import { VideoEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { EmbedTip } from '../components/EmbedTip'
import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import Icon from './icons/spotify.svg'

const Panel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<VideoEmbedAttrs>) => {
  const url = getMediaSourceUrl(currentAttributes)
  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url || ''}
        updateAttributes={updateAttributes}
        placeholder="https://open.spotify.com/..."
      />
      <EmbedTip
        guideLink="https://artists.spotify.com/en/help/article/embedded-players-follow-buttons"
        providerName="Spotify"
        extraMessage="You can also paste a URL straight to a podcast episode."
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const SpotifyProvider: MediaProviderType = {
  label: 'Spotify',
  key: 'video.spotify',
  keywords: ['spotify', 'music', 'podcast', 'playlist', 'song', 'album'],
  description: 'Play a podcast, song, or playlist direct from Spotify',
  icon: brands('spotify'),
  image: Icon,
  urlRegex: /^https?:\/\/open.spotify.com/,
  Panel,
}

/* Example: 
https://open.spotify.com/show/0gWT8X6lgGuJkpcx0XJ3yr
*/
