import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

import { ExtensionPriorityMap } from '../../constants'
import { CardIdsPluginState } from './CardIdsPluginState'

export const CardIdsPluginKey = new PluginKey<CardIdsPluginState>('cardIds')

export const CardIdsExtension = Extension.create({
  name: 'cardIds',
  priority: ExtensionPriorityMap.CardIds,

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: CardIdsPluginKey,

        state: {
          init() {
            return new CardIdsPluginState()
          },

          apply(transaction, pluginState, oldEditorState, newEditorState) {
            return pluginState.apply(transaction, newEditorState)
          },
        },

        // Use appendTransaction because thats what the UniqueAttribute
        // extension does. This ensures our duplicate cardId check
        // happens after UniqueAttribute has its chance to run.
        appendTransaction(transactions, oldState, newState) {
          const state = CardIdsPluginKey.getState(newState)
          if (!state?.value?.cardIds) return null

          /**
           * Duplicate cardId check
           * Ensure that there aren't duplicate cardIds in the list.
           */
          const validCardIds = state.value.cardIds.filter(Boolean) // null/undefined values are OK, they'll be autogenerated
          const nonUniqueCardIds = validCardIds.filter((val, idx) =>
            validCardIds.includes(val, idx + 1)
          )
          if (nonUniqueCardIds.length) {
            // TODO - Investigate "repairing" cards with duplicate IDs as there are still
            //        some edge cases where you can get into this state (notably via y-sync remote changes)
            // https://linear.app/gamma-app/issue/G-1431/investigate-repairing-docs-when-duplicate-card-ids-are-detected
            console.error(
              '[generateCardIdMap] Duplicate cardIds detected. This is unexpected and will cause spotlight issues. nonUniqueCardIds:',
              nonUniqueCardIds
            )
          }

          return null
        },
      }),
    ]
  },
})
