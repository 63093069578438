import {
  callOrReturn,
  Extension,
  findChildrenInRange,
  getExtensionField,
} from '@tiptap/core'
import { Node } from 'prosemirror-model'

import { getFontSizeOption } from './utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      /**
       * Set the text size (sm, md, lg)
       */
      setFontSize: (size: FontSize) => ReturnType
    }
  }
}

// Note: to enable font sizing on a node, it needs a `fontSize` attrs and
// should have `allowFontSizes` in the node definition. It should be set to
// "body", "heading", or a combination like "body heading"

export type FontSize = string | null

export const FontSize = Extension.create({
  name: 'fontSize',
  addCommands() {
    return {
      setFontSize:
        (size) =>
        ({ tr, dispatch, state }) => {
          if (!dispatch) return true

          tr.selection.ranges.forEach((range) => {
            const from = range.$from.pos
            const to = range.$to.pos

            const { heading, title } = getFontSizeOption(size)
            const { nodes } = state.schema

            // Find all the nodes in the selection and update their attrs and type
            state.doc.nodesBetween(from, to, (node, pos) => {
              if (!node.isTextblock) return

              // Changing things to a heading or title size
              if (
                (heading || title) &&
                !allowedFontSizes(node).includes('heading')
              ) {
                tr.setNodeMarkup(pos, heading ? nodes.heading : nodes.title, {
                  ...node.attrs,
                  level: heading || title,
                })
                return
              }

              // Changing to a body size, when the node supports fontSize type
              if (node.type.spec.attrs?.fontSize) {
                // If this node already supports fontSize, just change it
                tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  fontSize: size,
                })
              } else if (['heading', 'title'].includes(node.type.name)) {
                // If it's a heading, switch to paragraph
                tr.setNodeMarkup(pos, nodes.paragraph, {
                  ...node.attrs,
                  fontSize: size,
                })
              }
              // Otherwise, leave it alone
            })
          })

          return true
        },
    }
  },

  extendNodeSchema(extension) {
    return {
      allowFontSizes:
        callOrReturn(
          getExtensionField(extension, 'allowFontSizes', extension)
        ) ?? '',
    }
  },
})

// Within a selection, find which text size(s) are present
export const getSelectedFontSizes = (editor) => {
  const { state } = editor
  const { from, to } = state.selection
  const nodes = findChildrenInRange(
    state.doc,
    { from, to },
    (n) => n.isTextblock
  )

  const sizes = nodes
    .reverse()
    .map(({ node }) => node.attrs.fontSize)
    .filter((val) => val !== undefined)

  return [...new Set(sizes)]
}

export const allowedFontSizes = (node: Node): string[] => {
  return node.type.spec.allowFontSizes?.split(' ') ?? []
}
