import { Decoration } from 'prosemirror-view'

export const useAnnotatableActivator = ({
  decorations,
}: {
  decorations: Decoration[]
}) => {
  const foundActivator = decorations.find(
    (d) => !!d.spec.isAnnotatableActivator
  )

  return !!foundActivator
}
