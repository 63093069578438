import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import { FeatureFlags } from 'modules/featureFlags'
import { CommandInfo, COMMANDS_MAP } from 'modules/tiptap_editor/commands'
import { CalloutBoxCommands } from 'modules/tiptap_editor/extensions/CalloutBox/options'
import { MediaCommands } from 'modules/tiptap_editor/extensions/media/MediaCommands'

import { DrawingCommands } from '../../../extensions/Drawing/templates'

export type InsertableCategory = Record<
  InsertableCategoryNameEnum,
  InsertableCategoryInfo
>
export type InsertableItemGroup = {
  subcategory: SubcategoryEnum
  items: Array<CommandInfo>
}
export type InsertableCategoryInfo = {
  name: string
  icon: IconProp
  itemGroups: Array<InsertableItemGroup>
  featureFlag?: keyof FeatureFlags
}

export enum InsertableCategoryNameEnum {
  Images = 'images',
  Apps = 'apps',
  Videos = 'videos',
  Layouts = 'layouts',
  Drawings = 'drawings',
  Text = 'text',
  Callouts = 'callouts',
}

export enum SubcategoryEnum {
  Uncategorized = 'Other',
  Table = 'Table',
  Grid = 'Grid',
  Lists = 'Lists',
  Headings = 'Headings',
  Templates = 'Templates',
  New = 'New',
  Callouts = 'Callout boxes',
}

export const INSERTABLE_CATEGORIES_MAP: InsertableCategory = {
  [InsertableCategoryNameEnum.Images]: {
    name: 'Add images',
    icon: duotone('image'),
    itemGroups: [
      {
        subcategory: SubcategoryEnum.Uncategorized,
        items: [
          ...MediaCommands.filter((command) => command.nodeName === 'image'),
          COMMANDS_MAP.gallery,
        ],
      },
    ],
  },
  [InsertableCategoryNameEnum.Apps]: {
    name: 'Embed apps & webpages',
    icon: duotone('browser'),

    itemGroups: [
      {
        subcategory: SubcategoryEnum.Uncategorized,
        items: [
          ...MediaCommands.filter((command) => command.nodeName === 'embed'),
        ],
      },
    ],
  },
  [InsertableCategoryNameEnum.Videos]: {
    name: 'Embed videos',
    icon: duotone('film'),
    itemGroups: [
      {
        subcategory: SubcategoryEnum.Uncategorized,
        items: [
          ...MediaCommands.filter((command) => command.nodeName === 'video'),
        ],
      },
    ],
  },
  [InsertableCategoryNameEnum.Layouts]: {
    name: 'Layout options',
    icon: duotone('table-layout'),
    itemGroups: [
      {
        subcategory: SubcategoryEnum.Grid,
        items: [
          COMMANDS_MAP.columns2,
          COMMANDS_MAP.columns3,
          COMMANDS_MAP.columns4,
          COMMANDS_MAP.gallery,
        ],
      },
      {
        subcategory: SubcategoryEnum.Table,
        items: [COMMANDS_MAP.table2, COMMANDS_MAP.table3, COMMANDS_MAP.table4],
      },
    ],
  },
  [InsertableCategoryNameEnum.Drawings]: {
    name: 'Drawings and diagrams',
    icon: duotone('shapes'),
    featureFlag: 'drawingBlock',
    itemGroups: [
      {
        subcategory: SubcategoryEnum.Templates,
        items: DrawingCommands,
      },
    ],
  },
  [InsertableCategoryNameEnum.Text]: {
    name: 'Text formatting',
    icon: duotone('font-case'),
    itemGroups: [
      {
        subcategory: SubcategoryEnum.Headings,
        items: [
          COMMANDS_MAP.title,
          COMMANDS_MAP.h1,
          COMMANDS_MAP.h2,
          COMMANDS_MAP.h3,
        ],
      },
      {
        subcategory: SubcategoryEnum.Lists,
        items: [
          COMMANDS_MAP.bulletedList,
          COMMANDS_MAP.numberedList,
          COMMANDS_MAP.todoList,
        ],
      },
      {
        subcategory: SubcategoryEnum.Uncategorized,
        items: [
          COMMANDS_MAP.codeBlock,
          COMMANDS_MAP.mathBlock,
          COMMANDS_MAP.footnote,
          COMMANDS_MAP.expandableToggle,
          COMMANDS_MAP.contributors,
          COMMANDS_MAP.tableOfContents,
          COMMANDS_MAP.normalText,
        ],
      },
    ],
  },
  [InsertableCategoryNameEnum.Callouts]: {
    name: 'Callout blocks',
    icon: duotone('comment-alt-exclamation'),
    featureFlag: 'calloutBox',
    itemGroups: [
      {
        subcategory: SubcategoryEnum.Callouts,
        items: CalloutBoxCommands,
      },
      {
        subcategory: SubcategoryEnum.Uncategorized,
        items: [COMMANDS_MAP.buttonGroup, COMMANDS_MAP.blockquote],
      },
    ],
  },
}

// Used to determine the order in which the categories appear in the widget
const SORTED_CATEGORIES: Array<Array<InsertableCategoryNameEnum>> = [
  [
    InsertableCategoryNameEnum.Text,
    InsertableCategoryNameEnum.Callouts,
    InsertableCategoryNameEnum.Layouts,
  ],
  [
    InsertableCategoryNameEnum.Images,
    InsertableCategoryNameEnum.Drawings,
    InsertableCategoryNameEnum.Videos,
    InsertableCategoryNameEnum.Apps,
  ],
]

export const INSERTABLE_CATEGORIES_LIST = SORTED_CATEGORIES.map((outer) =>
  outer.map((key) => INSERTABLE_CATEGORIES_MAP[key])
)
