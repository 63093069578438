import { TldrawApp } from '@gamma-app/tldraw'

const STRIP_CLASSES = ['tl-centered-g']

export const generateSvg = async (app: TldrawApp) => {
  const svg = await app.getSvg(
    app.shapes.map((shape) => shape.id),
    {
      padding: 1,
    }
  )
  if (!svg) return

  // Remove classes that are targeted by Tldraw editor's global CSS
  // todo: change tldraw to stop using global CSS
  STRIP_CLASSES.forEach((className) => {
    svg.querySelector(`.${className}`)?.classList.remove(className)
  })
  // Remove IDs, because when Tldraw tries to regenerate SVGs,
  // the outputted SVG will have the same IDs as the input SVG
  // and it will confuse the generation code here:
  // https://github.com/tldraw/tldraw/blob/70288776993f4c9041c0904ca92b93fd3aae6592/packages/tldraw/src/state/shapes/TDShapeUtil.tsx#L181
  svg
    .querySelectorAll(':not(mask)[id]')
    .forEach((elm) => elm.removeAttribute('id'))

  return svg
}

// export const uploadSvg = async (svgEl: SVGElement, orgId: string) => {
// const [file, tempUrl] = svgToFileAndTempUrl(svgEl.outerHTML)
//   const result = await uploadImage(file, orgId)
//   console.log('svg uploaded', result.src, result)
//   return result
// }
// }
