import { Flex, Spinner } from '@chakra-ui/react'
import { TldrawApp } from '@gamma-app/tldraw'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/core'
import { useCallback, useEffect, useState } from 'react'

import { TldrawWrapper } from 'modules/tldraw'
import { useDebounced } from 'utils/hooks'

import { generateSvg } from './svg'
import { initializeApp, setContent } from './utils'

import { DrawingAttrs } from '.'

type DrawingEditorProps = {
  isOpen: boolean
  isEditable: boolean
  font: string
  isDark: boolean
  onClose: () => void
  isDrawingEditorActive: boolean
  setDrawingEditorActive: (isDrawingEditorActive: boolean) => void
} & NodeViewProps

export const DrawingEditor = ({
  isOpen,
  isEditable,
  onClose,
  font,
  isDark,
  isDrawingEditorActive,
  setDrawingEditorActive,
  ...nodeViewProps
}: DrawingEditorProps) => {
  const { node, updateAttributes } = nodeViewProps
  const { page, assets } = node.attrs as DrawingAttrs

  const [appInstance, setAppInstance] = useState<TldrawApp>()

  // Handle document changing externally, or app mounting
  useEffect(() => {
    setContent(appInstance, page, assets)
  }, [page, assets, appInstance, updateAttributes])

  // Handle mount
  const handleMount = useCallback((app: TldrawApp) => {
    setAppInstance(app)
    // @ts-ignore - just for debugging
    window.tldrawEditor = app
    initializeApp(app, true)
  }, [])

  // Generate SVG
  const saveSVG = useCallback(async () => {
    if (!appInstance) return
    const svgEl = await generateSvg(appInstance)
    if (!svgEl) return
    updateAttributes({
      svg: svgEl.outerHTML,
    })
    const tldrawJson = JSON.stringify({
      page: appInstance.document.pages.page,
      assets: appInstance.document.assets,
    })
    console.debug('[DrawingEditor] Generated SVG', {
      svgSize: svgEl.outerHTML.length,
      jsonSize: tldrawJson.length,
      svgEl,
    })
  }, [appInstance, updateAttributes])

  // Persist edits
  const saveContent = useCallback(() => {
    // For some reason, using the app provided in the props can sometimes lead to a blank overwrite
    // but using our app instance always works
    if (!appInstance) return
    updateAttributes({
      page: appInstance.document.pages.page,
      assets: appInstance.document.assets,
    })
  }, [updateAttributes, appInstance])
  const saveContentDebounced = useDebounced(saveContent, 500)

  const handleClose = useCallback(() => {
    if (isEditable) {
      saveSVG()
    }
    setDrawingEditorActive(false)
    onClose()
  }, [onClose, saveSVG, setDrawingEditorActive, isEditable])

  return (
    <>
      <Drawer
        placement="bottom"
        onClose={handleClose}
        isOpen={isOpen}
        trapFocus={true}
        isFullHeight={true}
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent
          borderTopRadius="xl"
          h="calc(var(--100vh) - 24px)"
          // Tldraw gets confused by initializing during an animation,
          // so this waits until it's done to initialize.
          onAnimationComplete={(variant) => {
            if (variant == 'enter') {
              setDrawingEditorActive(true)
            }
          }}
          transition={{
            enter: { duration: 0.4 },
          }}
        >
          <DrawerBody
            p={0}
            h="100%"
            overflow="hidden"
            data-testid="drawing-editor-body"
          >
            {isDrawingEditorActive ? (
              <TldrawWrapper
                readOnly={!isEditable}
                showPages={false}
                onMount={handleMount}
                onChangePage={isEditable ? saveContentDebounced : undefined}
                autofocus={false}
                isDark={isDark}
                font={font}
              />
            ) : (
              <Flex align="center" justify="center" w="100%" h="100%">
                <Spinner size="xl" />
              </Flex>
            )}
          </DrawerBody>
          <DrawerCloseButton
            zIndex={2}
            backgroundColor="white"
            data-testid="drawing-editor-close"
          />
        </DrawerContent>
      </Drawer>
    </>
  )
}
