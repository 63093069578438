import { Editor, findParentNodeClosestToPos } from '@tiptap/core'
import { Node as ProsemirrorNode, Schema, Slice } from 'prosemirror-model'
import { Selection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { getStore } from 'modules/redux'
import { getScrollManager } from 'modules/scroll'
import { SET_SELECTION_ORIGIN_META_KEY } from 'modules/tiptap_editor/commands/keys'
import { selectMode } from 'modules/tiptap_editor/reducer'
import {
  findParentNodeClosestToPosWithDepth,
  findParentNodes,
} from 'modules/tiptap_editor/utils'
import { calculateScroll } from 'utils/dom'

import { EditorModeEnum } from '../../types'
import { isCardCollapsed, setCardCollapsed } from './CardCollapse'
import {
  CARD_BODY_CLASS,
  CARD_DEPTH,
  CARD_WRAPPER_CLASS,
  EXPAND_CARD_TRANSITION_TIME,
} from './constants'
import { isCardNode } from './utils'

export const findCollapsedCardNodeClosestToPos = (
  editor: Editor,
  pos: number,
  isCollapsed?: boolean
) => {
  const checkNode = (node: ProsemirrorNode) => {
    const isCard = isCardNode(node)
    const collapseFilter =
      typeof isCollapsed === 'boolean'
        ? isCardCollapsed(node) === isCollapsed
        : true
    return isCard && collapseFilter
  }

  const nodeAtPos = editor.state.doc.nodeAt(pos)
  const $pos = editor.state.doc.resolve(pos)
  return nodeAtPos && checkNode(nodeAtPos)
    ? { node: nodeAtPos, pos, start: $pos.start, depth: $pos.depth }
    : findParentNodeClosestToPos(editor.state.doc.resolve(pos), checkNode)
}

/**
 * Find the top level card that the provided pos exists inside
 */
export const findTopCardNodeParent = (editor: Editor, pos: number) => {
  const predicate = (n: ProsemirrorNode, d: number) =>
    isCardNode(n) && d === CARD_DEPTH
  const $pos = editor.state.doc.resolve(pos)
  const nodeAtPos = editor.state.doc.nodeAt(pos)

  if (nodeAtPos && predicate(nodeAtPos, $pos.depth + 1)) {
    return { node: nodeAtPos, pos }
  }

  return findParentNodeClosestToPosWithDepth($pos, predicate)
}

export const openParentCards = ({
  pos,
  editor,
}: {
  pos: number
  editor: Editor
}) => {
  const parentCards = findParentNodes(editor.state.doc.resolve(pos), isCardNode)
  const cardIds = parentCards.map((card) => card.node.attrs.id)
  setCardCollapsed(cardIds, false)
}

/**
 * If the card is nested, opens the card (and its parents) before scrolling
 * and setting the editor focus to the card.
 */
export const scrollToCard = async ({
  cardId,
  pos,
  editor,
  cardEl,
  isNested = false,
  origin = 'editor',
}: {
  cardId: string
  pos: number
  editor: Editor
  cardEl?: HTMLElement | null
  isNested?: boolean
  origin?: 'editor' | 'toc'
}) => {
  if (!cardEl) {
    console.error('[scrollToCard] no cardEl specified')
    return
  }
  setCardCollapsed(cardId, false)
  openParentCards({ pos, editor })

  // We must scroll before we set the selection. Set selection has the side effect of focusing an element,
  // which itself has a side effect of causing the browser to "jump" the element into the viewport.
  await getScrollManager('editor').scrollElementIntoView({
    element: cardEl,
    delay: isNested ? EXPAND_CARD_TRANSITION_TIME : 0,
  })

  editor.commands.command(({ tr }) => {
    // Set the selection just inside the card
    tr.setSelection(Selection.near(editor.state.doc.resolve(pos)))
      // Identify this command's origin
      .setMeta(SET_SELECTION_ORIGIN_META_KEY, origin)
    return true
  })
}

export const goToCard = ({
  cardId,
  editor,
}: {
  cardId: string | null
  editor: Editor
}): void => {
  if (!cardId) return
  const store = getStore()
  const mode = selectMode(store.getState())

  if (mode === EditorModeEnum.SLIDE_VIEW) {
    editor.commands.spotlightCardById(cardId)
    return
  }

  const cardEl = document.querySelector<HTMLDivElement>(
    `[data-card-id="${cardId}"]`
  )
  if (!cardEl) return
  const pos = editor.view.posAtDOM(cardEl, 0)
  const $pos = editor.state.doc.resolve(pos)
  const isNested = $pos.depth > CARD_DEPTH // -1 because $pos is just before/outside the card
  scrollToCard({ cardId, pos, editor, cardEl, isNested })
}

// Checks if a drag event is over the gap between two cards, and returns
// the correct insert position if so
export const checkBetweenCardsDropTarget = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice
): { pos: number } | null => {
  // Look for drags outside the card body
  const dropElt = event.target as HTMLElement
  if (
    dropElt.closest(`.${CARD_BODY_CLASS}`) &&
    !dropElt.closest('[data-outside-card-body]')
  ) {
    return null
  }

  if (slice && !canCardContainSlice(slice, view.state.schema)) {
    return null
  }
  const parentCardElt = dropElt.closest(`.${CARD_WRAPPER_CLASS}`)
  // If there is no parent card, then set drop position to be at the end of the doc
  if (!parentCardElt) {
    // view.state.doc.content.size - 1 the end pos of the last card
    // this makes the drop cursor show in the position of what would be the next card after the last
    return { pos: view.state.doc.content.size - 1 }
  }
  const cardBody = parentCardElt.querySelector(`.${CARD_BODY_CLASS}`)
  const cardBodyRect = cardBody?.getBoundingClientRect()
  if (!cardBodyRect) return null

  // Find the insert pos
  const { doc } = view.state
  // find the pos and node of the parent Card traversing up from the posAtDOM from the parentCardElt
  let parentCard: {
    pos: number
    start: number
    depth: number
    node: ProsemirrorNode
  }
  try {
    const parentCardPos = view.posAtDOM(parentCardElt, 0)
    if (parentCardPos === -1) {
      return null
    }
    const parentCards = findParentNodes(doc.resolve(parentCardPos), isCardNode)
    // probably shouldn't happen
    if (parentCards.length === 0) {
      return null
    }
    parentCard = parentCards[parentCards.length - 1]
  } catch (err) {
    console.error(
      '(caught) [checkBetweenCardsDropTarget] error finding parent node:',
      err
    )
    return null
  }

  const { node: cardNode, pos: cardPos } = parentCard
  if (!cardPos || !cardNode) return null

  // find the scrollTop position
  const [scrollTop] = calculateScroll(cardBody)
  // since cardBodyRect is affected by scrolling (and can be negative), normalize cardTop and cardBottom
  const cardTop = scrollTop + cardBodyRect.top
  const cardBottom = scrollTop + cardBodyRect.bottom
  const dropY = scrollTop + event.clientY
  // cardTop is distance measured from scrolling container to cardTop
  if (dropY < cardTop) {
    return { pos: cardPos }
  } else if (dropY > cardBottom) {
    return { pos: cardPos + cardNode.nodeSize }
  }
  return null
}

const canCardContainSlice = (slice: Slice, schema: Schema) =>
  schema.nodes.card.validContent(slice.content)
