import { prosemirrorJSONToYDoc } from '@gamma-app/y-prosemirror'
import { getSchema, JSONContent } from '@tiptap/core'
import { fromUint8Array } from 'js-base64'
import {
  DOMParser as ProseMirrorDOMParser,
  Schema,
  Slice,
} from 'prosemirror-model'
import { encodeStateAsUpdate } from 'yjs'

import { getBaseExtensions } from '../EditorCore'
import {
  transformOutsidePastedContent,
  transformPastedHTML,
} from '../extensions/Clipboard'

const deleteCardId = (node: JSONContent) => {
  if (node.type === 'card' && node.attrs) {
    delete node.attrs.id
  }
}

/**
 * Prune the card IDs from the provided JSON content
 */
export const pruneCardIds = (root: JSONContent) => {
  const remapContent = (node: JSONContent) =>
    node.content?.map((child: JSONContent) => {
      if (!child.content) return child
      const newChild = { ...child, attrs: { ...child.attrs } }
      deleteCardId(newChild)
      newChild.content = remapContent(newChild)
      return newChild
    })
  deleteCardId(root)
  return Object.assign({}, root, { content: remapContent(root) })
}

/**
 * Takes a JSON object, converts it to a prosemirror YDoc using
 * the EditorCore's schema, and returns a base64 encoded
 * version of it that we can use as a snapshot.
 */
export const jsonToYDocSnapshot = (content: JSONContent): string => {
  // Convert the JSON to a YDoc that adhere's to our schema.
  // IMPORTANT: The 'default' YDoc field here is specified by the Collaboration Extension:
  // https://github.com/ueberdosis/tiptap/blob/122f0821aaa020777e3ed9731af1aa9a75d524ed/packages/extension-collaboration/src/collaboration.ts#L46
  // The y-prosemirror default value is 'prosemirror'
  const baseExtensions = getBaseExtensions()
  const schema = getSchema(baseExtensions)
  const ydoc = prosemirrorJSONToYDoc(schema, content, 'default')
  const documentState = encodeStateAsUpdate(ydoc) // is a Uint8Array
  // Transform Uint8Array to a Base64-String
  return fromUint8Array(documentState)
}

export const htmlToYDocSnapshot = (html: string): string => {
  const baseExtensions = getBaseExtensions()
  const schema = getSchema(baseExtensions)
  const transformedHtml = transformPastedHTML(html)
  const slice = htmlToSlice(transformedHtml, schema)

  const splitContent =
    transformOutsidePastedContent(slice, schema).toJSON()?.content || []

  let doc: JSONContent
  if (splitContent.every((node) => node.type === 'card')) {
    doc = {
      type: 'document',
      content: splitContent,
    }
  } else {
    // This is a basic way of checking that the input will match our schema
    // ProseMirror does something much more complex, but that requires an
    // editor instance. We can explore that as a followup. See
    // https://linear.app/gamma-app/issue/G-2261/import-fails-when-there-are-inline-elements-at-the-top-level
    const content =
      splitContent.filter((node) => schema.nodes[node.type].isBlock) || []
    doc = {
      type: 'card',
      content,
    }
  }

  console.debug('Parsing imported doc', {
    doc,
    slice,
    splitContent,
    transformedHtml,
    html,
  })

  return jsonToYDocSnapshot(doc)
}

export const htmlToSlice = (html: string, schema: Schema): Slice => {
  const dom = new DOMParser().parseFromString(html, 'text/html')
  const slice = ProseMirrorDOMParser.fromSchema(schema).parseSlice(dom, {
    preserveWhitespace: false,
  })
  return slice
}
