import { Box, Button } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/react'
import { round } from 'lodash'
import React, { memo, useCallback, useEffect } from 'react'

import { NodeViewContent } from 'modules/tiptap_editor/react'
import { findSelectionInsideNode } from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'
import { isMobileDevice } from 'utils/deviceDetection'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { AnnotatableNodeViewWrapper } from '../../Annotatable'
import { TableMap } from '../prosemirror-table'
import { canAddCol, createColumnWidths } from '../prosemirror-table/columnUtils'

const MAX_MOBILE_TABLE_WIDTH = '200vw'
type ColGroupProps = {
  cols: number
  colWidths: string[]
  isFocused: boolean
}
const ColGroup: React.FC<ColGroupProps> = ({ colWidths, isFocused }) => {
  return (
    <colgroup>
      {isFocused && <col style={{ width: '0%' }}></col>}
      {colWidths.map((width, ind) => (
        <col
          key={ind}
          className="col-width-control"
          style={{ width: `${width}%` }}
        />
      ))}
    </colgroup>
  )
}

ColGroup.displayName = 'ColGroup'

export const TableView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, getPos, decorations, updateAttributes } = nodeViewProps
  // the table name is hard coded in to avoid a circular dependency
  const { colMinPercent, newColSize } = editor.schema.nodes['table'].spec
  const canAddColEnd = canAddCol(
    node.attrs.colWidths,
    newColSize,
    colMinPercent
  )
  const tableMap = TableMap.get(node)
  const isFocused = decorations.some(
    (decoration) => decoration.spec.isFocusedInside
  )
  const { colWidths } = node.attrs

  useEffect(() => {
    if (tableMap.width !== colWidths.length) {
      // has to be in a set timeout to prevent an infinite dispatch loop
      // with updateAttributes
      setTimeout(() => {
        updateAttributes({
          colWidths: createColumnWidths(tableMap.width),
        })
      }, 0)
    }
  }, [tableMap.width, colWidths, updateAttributes])

  const getColumnPosition = useCallback(
    (colNumber: number) => {
      // tableMap positions are relative to the inside of the table
      // https://github.com/ProseMirror/prosemirror-tables#class-tablemap
      // getPos() give us the spot right before table, +1 goes in
      const tableStart = getPos() + 1
      return tableStart + tableMap.positionAt(0, colNumber, node)
    },
    [getPos, node, tableMap]
  )

  const addColumnAfter = useCallback(
    (colNumber: number) => {
      const newColPos = getColumnPosition(colNumber + 1) + 1 // + 1 to put focus inside the <td>

      editor
        .chain()
        .addColumnAfter(colNumber) // Add after it
        .command(({ tr }) => {
          const sel = findSelectionInsideNode(tr.doc.resolve(newColPos))
          if (sel) {
            tr.setSelection(sel)
          }
          return true
        })
        .run()
    },
    [editor, getColumnPosition]
  )

  const addColumnEnd = useCallback(() => {
    const lastCol = tableMap.width - 1
    addColumnAfter(lastCol)
  }, [addColumnAfter, tableMap.width])

  const addRowEnd = () => {
    const tableStart = getPos() + 1
    const lastRow = tableMap.height - 1
    const lastCol = tableMap.width - 1

    const lastCellPos = tableStart + tableMap.positionAt(lastRow, lastCol, node)

    editor
      .chain()
      .focus(lastCellPos) // Select the last row
      .addRowAfter() // Add after it
      .run()

    requestAnimationFrame(() =>
      editor.chain().focus(lastCellPos).goToNextCell().run()
    )
  }

  // TODO work with
  const width = round(
    node.attrs.colWidths.reduce((a, b) => a + b, 0),
    20
  )

  return (
    <AnnotatableNodeViewWrapper as="div" {...nodeViewProps} {...node.attrs}>
      <Box
        w="fit-content"
        overflowX={isMobileDevice ? 'auto' : 'visible'}
        position="relative"
        className="table-wrapper"
        width="100%"
      >
        <table
          style={{
            width: isMobileDevice ? 'max-content' : `${width}%`,
            minWidth: isMobileDevice ? '100%' : undefined,
            // Set max width of tables, so you don't end up scrolling
            // all the way into oblivion
            maxWidth: isMobileDevice ? MAX_MOBILE_TABLE_WIDTH : undefined,
            tableLayout: isMobileDevice ? 'auto' : 'fixed',
            position: 'relative',
          }}
        >
          <ColGroup
            cols={tableMap.width}
            colWidths={colWidths}
            isFocused={isFocused}
          />
          <thead>
            {isFocused && (
              <ColumnControls
                editor={editor}
                numColumns={tableMap.width}
                getColumnPosition={getColumnPosition}
              />
            )}
          </thead>
          <NodeViewContent
            as="tbody"
            className="table-content"
          ></NodeViewContent>
        </table>
        {/* Floating controls */}
        {isFocused && (
          <Box contentEditable="false" suppressContentEditableWarning>
            <GammaTooltip placement="right" label="Add column">
              <Button
                position="absolute"
                left="100%"
                top="0"
                height="100%"
                colorScheme="gray"
                color="black"
                onClick={addColumnEnd}
                onMouseDown={preventDefaultToAvoidBlur}
                minWidth="0"
                px={1}
                borderLeftRadius="none"
                disabled={!canAddColEnd}
                zIndex={1}
                data-testid="add-col-end-button"
              >
                <FontAwesomeIcon icon={regular('plus')} />
              </Button>
            </GammaTooltip>
            <GammaTooltip placement="bottom" label="Add row">
              <Button
                position="absolute"
                left="0"
                top="100%"
                width="100%"
                colorScheme="gray"
                color="black"
                onClick={addRowEnd}
                onMouseDown={preventDefaultToAvoidBlur}
                minHeight="0"
                height="auto"
                py={1}
                borderTopRadius="none"
                zIndex={1}
                data-testid="add-row-end-button"
              >
                <FontAwesomeIcon icon={regular('plus')} />
              </Button>
            </GammaTooltip>
          </Box>
        )}
      </Box>
    </AnnotatableNodeViewWrapper>
  )
}

const ColumnControls = ({ editor, numColumns, getColumnPosition }) => {
  const selectColumn = useCallback(
    (colNumber: number) => {
      const colPos = getColumnPosition(colNumber)
      editor.chain().focus().selectColumn(colPos).run()
    },
    [editor, getColumnPosition]
  )

  const selectTable = () => {
    const firstColPos = getColumnPosition(0)
    editor.chain().focus().selectTable(firstColPos).run()
  }

  const cols = Array.from(Array(numColumns).keys())

  return (
    <tr contentEditable="false" suppressContentEditableWarning>
      <th style={{ padding: 0 }}>
        <GammaTooltip placement="top" label="Select table">
          <Button
            position="absolute"
            left="calc(2px + var(--chakra-space-6) * -1)"
            top={-6}
            height={5}
            width={5}
            minWidth={0}
            padding={0}
            borderRadius="full"
            colorScheme="gray"
            onClick={selectTable}
            onMouseDown={preventDefaultToAvoidBlur}
            data-testid="select-table-button"
          />
        </GammaTooltip>
      </th>
      {cols.map((colNumber) => (
        <SelectColumnHeader
          key={colNumber}
          colNumber={colNumber}
          selectColumn={selectColumn}
        />
      ))}
    </tr>
  )
}

const SelectColumnHeader = memo(
  ({
    colNumber,
    selectColumn,
  }: {
    colNumber: number
    selectColumn: (colNumber: number) => void
  }) => {
    const colLetter = 'ABCDEFGHJIKLMNOPQRSTUVWXYZ'[colNumber]
    return (
      <th
        key={colNumber}
        style={{ position: 'relative', padding: 0, border: 0 }}
        className="table-col-control"
      >
        <GammaTooltip placement="top" label="Select column">
          <Button
            position="absolute"
            width="calc(100% - 1px)"
            left={0}
            height={6}
            top="calc(var(--chakra-space-6) * -1)"
            padding={0}
            colorScheme="gray"
            color="black"
            mx="1px"
            borderBottomRadius="none"
            onClick={() => selectColumn(colNumber)}
            onMouseDown={preventDefaultToAvoidBlur}
            data-testid={`select-col-button`}
            data-table-col={colLetter}
          >
            {colLetter}
          </Button>
        </GammaTooltip>
      </th>
    )
  }
)

SelectColumnHeader.displayName = 'SelectColumnHeader'
