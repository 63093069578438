import { Extension } from '@tiptap/core'
import { Node, Schema } from 'prosemirror-model'
import { Plugin, PluginKey, EditorState, Transaction } from 'prosemirror-state'

export type MigrateFunction = (props: {
  node: Node
  pos: number
  tr: Transaction
  schema: Schema
  parent: Node | null
}) => boolean

import { migrateNativeEmojis } from './Emoji/index'

const MIGRATIONS = [migrateNativeEmojis]

export const MigratePluginKey = new PluginKey('migrate')

/**
 * This plugin just runs doc.descendants on each migration in
 * the list above. The intent is to register all the things
 * that we want to transform about the editor state.
 *
 * In the future this should probably be restructured and provide
 * the option for each migration to only run on initial load.
 */
const migratePlugin = new Plugin({
  key: MigratePluginKey,

  state: {
    init: () => {
      return {
        hasRun: false,
      }
    },
    apply(transaction, pluginState) {
      const data = transaction.getMeta(MigratePluginKey)
      if (data) {
        return data
      }
      return pluginState
    },
  },

  appendTransaction: (transactions, _oldState, newState: EditorState) => {
    const state = MigratePluginKey.getState(newState)
    if (state.hasRun && !transactions.some((tr) => tr.docChanged)) return null

    const { doc, tr, schema } = newState

    let migrationResults: boolean[] = []
    doc.descendants((node, pos, parent) => {
      migrationResults = MIGRATIONS.map((migrate) =>
        migrate({ node, pos, tr, schema, parent })
      )
    })

    if (!state.hasRun && migrationResults.every(Boolean)) {
      tr.setMeta(MigratePluginKey, { hasRun: true })
      return tr
    }

    if (tr.docChanged) {
      return tr
    }

    return null
  },
})

export const Migrate = Extension.create({
  name: 'migrate',
  addProseMirrorPlugins() {
    return [migratePlugin]
  },
})
