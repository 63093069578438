import {
  Button,
  Link,
  StackProps,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'

import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from 'gamma_components/constants'
import { AccessLink, Doc } from 'modules/api'
import { LoginScenarios, openLoginWindow } from 'modules/auth'
import { ErrorCode, ERROR_MAP } from 'modules/errors'
import { UserRefetchFn, useUserContext } from 'modules/user'

import { clearShareToken } from './useAccessLinkCollaborator'

export type AccessLinkSignUpProps = {
  accessLink: AccessLink
  docId: Doc['id']
  textIsBold?: boolean
  refetchFn?: UserRefetchFn
  showDivider?: boolean
} & StackProps

export const AccessLinkSignUp = ({
  accessLink,
  docId,
  refetchFn,
  textIsBold = true,
  ...rest
}: AccessLinkSignUpProps) => {
  const toast = useToast()
  const { refetch } = useUserContext()
  // User Context and therefore `refetch` is not available within the Chakra's toast manager
  // so when this component is used in a toast, a `refetchFn` function should be passed in
  const userRefetch = refetchFn || refetch

  const handleError = (errorCode: ErrorCode) => {
    const { title, description } = ERROR_MAP[errorCode] || {
      title: 'Unexpected Error',
      description: errorCode,
    }
    if (!toast.isActive(errorCode)) {
      toast({
        title,
        description,
        position: 'top',
        id: errorCode,
        status: 'error',
        isClosable: true,
        duration: null,
      })
    }
  }
  const actions = {
    manage: `comment on and edit this ${DOC_DISPLAY_NAME}`,
    edit: `comment on and edit this ${DOC_DISPLAY_NAME}`,
    comment: `comment on this ${DOC_DISPLAY_NAME}`,
    view: `access this ${DOC_DISPLAY_NAME} later`,
  }
  const action =
    accessLink.permission && actions[accessLink.permission]
      ? actions[accessLink.permission]
      : actions['view']

  const handleLoginComplete = async () => {
    if (userRefetch) {
      await userRefetch()
      clearShareToken()
    }
    if (!toast.isActive('login-complete')) {
      toast({
        title: "You're logged in.",
        description: `You can now ${action}.`,
        position: 'top',
        id: 'login-complete',
        status: 'success',
        isClosable: true,
        duration: 5000,
      })
    }
  }
  return (
    <VStack align="left" {...rest}>
      <Text fontWeight={textIsBold ? 'bold' : undefined} fontSize="sm">
        To {action}, sign up with your Google account.
      </Text>

      <Button
        variant="solid"
        leftIcon={<FontAwesomeIcon icon={brands('google')} />}
        size="md"
        onClick={() => {
          openLoginWindow({
            type: LoginScenarios.DOC_ACCESS_LINK,
            params: {
              docId,
              shareToken: accessLink.token,
            },
            errorCallback: handleError,
            doneCallback: handleLoginComplete,
          })
        }}
      >
        Sign up with Google
      </Button>
      <Text fontSize="xs" color="gray.500">
        By clicking this link, you agree to Gamma's{' '}
        <Link href={TERMS_OF_SERVICE_URL} isExternal fontWeight="bold">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href={PRIVACY_POLICY_URL} isExternal fontWeight="bold">
          Privacy Policy
        </Link>
        .
      </Text>
    </VStack>
  )
}
