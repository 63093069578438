import { VStack } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { EmbedTip } from '../components/EmbedTip'
import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import Icon from './icons/miro.svg'

const MiroPanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const url = getMediaSourceUrl(currentAttributes)
  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder="https://www.miro.com/..."
      />
      <EmbedTip
        guideLink="https://help.miro.com/hc/en-us/articles/360016335640-How-to-embed-editable-boards-into-websites"
        providerName="Miro"
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const MiroProvider: MediaProviderType = {
  label: 'Miro board',
  key: 'embed.miro',
  icon: duotone('note'),
  description: 'Embed boards and edit them live for interactive workshops',
  image: Icon,
  urlRegex: /^https?:\/\/miro.com/,
  Panel: MiroPanel,
  canEmbedPrivate: true,
  iframeOptions: {
    allowPopups: true, // Required for their sign-in system
  },
  keywords: ['whiteboard', 'workshop', 'brainstorm'],
  rewriteEmbedUrl: (url) => {
    // Don't rewrite if it's already an embed URL
    if (url.includes('/embed/') || url.includes('/live-embed/')) return url
    return url.replace('/board/', '/embed/')
  },
}
