import { marked } from 'marked'
import { EditorView } from 'prosemirror-view'

import { htmlToSlice } from 'modules/tiptap_editor/utils/transform'

import { textLooksLikeLink } from './handleLinkPaste'

import { transformOutsidePastedContent, transformPastedHTML } from '.'

export const handleMarkdownPaste = (
  view: EditorView,
  event: ClipboardEvent
) => {
  // Based on https://github.com/outline/rich-markdown-editor/blob/be1b9426a292fd41beb4db8d70d6695a9416dbea/src/plugins/PasteHandler.ts#L36
  if (!view.editable || !event.clipboardData) {
    return false
  }
  const text = event.clipboardData.getData('text/plain')
  // Don't run if there's no text, or on text that's just a link
  if (!text || textLooksLikeLink(text)) {
    return false
  }
  // If the HTML on the clipboard is from Prosemirror then the best
  // compatibility is to just use the HTML parser, regardless of
  // whether it "looks" like Markdown
  const html = event.clipboardData.getData('text/html')
  if (html?.includes('data-pm-slice')) {
    return false
  }

  const { state } = view

  // If we're pasting into a codeblock, just paste the text as is without parsing
  if (state.selection.$from.parent.type.spec.code) {
    const tr = state.tr.insertText(text)
    view.dispatch(tr)
    return true
  }

  // Because VSCode is an especially popular editor that places metadata
  // on the clipboard, we can parse it to find out what kind of content
  // was pasted.
  const vscode = event.clipboardData.getData('vscode-editor-data')
  const vscodeMeta = vscode ? JSON.parse(vscode) : undefined
  const pasteCodeLanguage = vscodeMeta?.mode

  const preferMarkdown =
    !html || pasteCodeLanguage === 'markdown' || looksLikeMarkdown(text)
  if (!preferMarkdown) return false

  try {
    const parsedHtml = marked.parse(text)
    const transformedHtml = transformPastedHTML(parsedHtml)
    const slice = htmlToSlice(transformedHtml, state.schema)
    const transformedSlice = transformOutsidePastedContent(slice, state.schema)
    if (!transformedSlice) return false

    const transaction = state.tr.replaceSelection(transformedSlice)
    view.dispatch(transaction)
    event.preventDefault()
    return true
  } catch (err) {
    console.warn('(caught) [Clipboard] handleMarkdownPaste error', err)
    return false
  }
}

// https://github.com/outline/rich-markdown-editor/blob/bf9aabcc14bcd049fbeccc05a4f8195a3e7243b4/src/lib/isMarkdown.ts#L1
const looksLikeMarkdown = (text: string): boolean => {
  // code-ish
  const fences = text.match(/^```/gm)
  if (fences && fences.length > 1) return true

  // link-ish
  if (text.match(/\[[^]+\]\(https?:\/\/\S+\)/gm)) return true
  if (text.match(/\[[^]+\]\(\/\S+\)/gm)) return true

  // heading-ish
  if (text.match(/^#{1,6}\s+\S+/gm)) return true

  // list-ish
  const listItems = text.match(/^\s*[\d-*].?\s\S+/gm)
  if (listItems && listItems.length > 1) return true

  return false
}
