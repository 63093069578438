import { useEffect, useState } from 'react'
/**
 * Simple hook to ensure SSR and CSR is the same
 * See https://nextjs.org/docs/messages/react-hydration-error
 */
export const useSSRMounted = () => {
  const [ready, setReady] = useState(false)
  useEffect(() => setReady(true), [])

  return ready
}
