import { Spinner, Text } from '@chakra-ui/react'
import React, { Suspense } from 'react'

type EmojiIconProps = {
  emojiId: string
  fontSize?: string
}

export const EmojiIcon = ({ emojiId, fontSize = '1em' }: EmojiIconProps) => {
  return (
    <Suspense fallback={<Spinner size="sm" />}>
      <Text
        as="span"
        sx={{
          '.emoji-mart-emoji': {
            fontSize: '1em', // Get the font size from the surrounding Text
            span: {
              cursor: 'inherit',
              fontSize: '1em !important',
            },
            ':hover::before': {
              backgroundColor: 'transparent',
            },
          },
        }}
        fontSize={fontSize}
      >
        {/** Emoji.size is expected to be a number. We override it above as 1em */}
        <em-emoji id={emojiId} native={true} size="0" />
      </Text>
    </Suspense>
  )
}
