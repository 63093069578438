import { yCursorPlugin } from '@gamma-app/y-prosemirror'
import { Extension } from '@tiptap/core'
import merge from 'lodash/merge'
import tinycolor from 'tinycolor2'

import { Collaborator } from '../../reducer'

/**
 * This is a fork of the Tiptap CollaborationCursor extension
 * https://github.com/ueberdosis/tiptap/tree/main/packages/extension-collaboration-cursor
 *
 * Fork reasons:
 *   - Add typesafety to the commands.user call
 *   - Do a deep merge of attributes in the commands.user call
 */

type CollaborationCursorStorage = {
  users: { clientId: number; [key: string]: any }[]
}

export interface CollaborationCursorOptions {
  provider: any
  user: Record<string, any>
  render(user: Record<string, any>): HTMLElement
  /**
   * @deprecated The "onUpdate" option is deprecated. Please use `editor.storage.collaborationCursor.users` instead. Read more: https://tiptap.dev/api/extensions/collaboration-cursor
   */
  onUpdate: (users: { clientId: number; [key: string]: any }[]) => null
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    collaborationCursor: {
      /**
       * Update details of the current user
       *
       */
      user: (attributes: Partial<Collaborator>) => ReturnType
    }
  }
}

const defaultOnUpdate = () => null

// Use similar logic from the TipTap extension to grab the awareness user data:
// https://github.com/ueberdosis/tiptap/blob/e28b770f60bc8a180e54e5bd1422aea4b58ab324/packages/extension-collaboration-cursor/src/collaboration-cursor.ts#L22-L29
export const awarenessStatesToArray = (
  states: Map<number, Record<string, any>>
) => {
  return Array.from(states.entries()).map(([clientId, aw]) => {
    return {
      clientId,
      ...aw.user,
    }
  })
}

export const CollaborationCursor = Extension.create<
  CollaborationCursorOptions,
  CollaborationCursorStorage
>({
  name: 'collaborationCursor',

  addOptions() {
    return {
      provider: null,
      user: {
        name: null,
        color: null,
      },
      render: (user) => {
        const cursor = document.createElement('span')

        // Return an empty span if the user isn't ready yet
        if (!user.isReady) return cursor

        cursor.classList.add('collaboration-cursor__caret')
        cursor.setAttribute('style', `border-color: ${user.color}`)

        const label = document.createElement('div')
        label.classList.add('collaboration-cursor__label')
        label.setAttribute(
          'style',
          `background-color: ${user.color}; color: ${
            tinycolor(user.color).isDark() ? 'white' : 'black'
          }`
        )
        const firstName = user.name.split(' ')[0]
        label.insertBefore(document.createTextNode(firstName), null)
        cursor.insertBefore(label, null)

        return cursor
      },
      onUpdate: defaultOnUpdate,
    }
  },

  addStorage() {
    return {
      users: [],
    }
  },

  addCommands() {
    return {
      /**
       * Update some or all of the current users attributes
       * using a deep merge.
       * Note that there isnt a way to delete any attributes
       * due to the deep merge, but thats probably ok.
       */
      user: (attributes: Partial<Collaborator>) => () => {
        this.options.user = merge({}, this.options.user, attributes)
        this.options.provider.awareness.setLocalStateField(
          'user',
          this.options.user
        )
        return true
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      yCursorPlugin(
        (() => {
          this.options.provider.awareness.setLocalStateField(
            'user',
            this.options.user
          )

          this.storage.users = awarenessStatesToArray(
            this.options.provider.awareness.states
          )

          this.options.provider.awareness.on('update', () => {
            this.storage.users = awarenessStatesToArray(
              this.options.provider.awareness.states
            )
          })

          return this.options.provider.awareness
        })(),
        // @ts-ignore
        {
          cursorBuilder: this.options.render,
        }
      ),
    ]
  },
})
