import { Editor } from '@tiptap/core'
import { useEffect } from 'react'

import { selectIsPanelComponentOpen } from 'modules/panels/reducer'
import { useAppStore } from 'modules/redux'
import {
  selectCardIdMap,
  selectDoc,
  selectMode,
  selectNumConcurrentCollaborators,
} from 'modules/tiptap_editor/reducer'
import { useEditorContext } from 'sections/docs/context/EditorContext'
import { TableOfContentsPanel } from 'sections/docs/editor/components/TableOfContents/TableOfContentsPanel'

import { BaseDocData } from './types'

let baseDocData: Partial<BaseDocData> = {}
let currentEditor: Editor | null = null

export const useBaseHoneycombDocData = () => {
  const { editor } = useEditorContext()
  useEffect(() => {
    currentEditor = editor || null

    return () => {
      currentEditor = null
    }
  }, [editor])

  const reduxStore = useAppStore()
  useEffect(() => {
    const compute = () => {
      const state = reduxStore.getState()
      const cardIdMap = selectCardIdMap(state)
      const doc = selectDoc(state)

      baseDocData.docNumConcurrentCollaborators =
        selectNumConcurrentCollaborators(state)
      baseDocData.editorMode = selectMode(state)
      baseDocData.tocOpen =
        selectIsPanelComponentOpen(TableOfContentsPanel)(state)
      baseDocData.docTitle = doc?.title || ''
      baseDocData.docId = doc?.id || ''
      baseDocData.docNumComments = doc?.comments?.length ?? -1
      if (cardIdMap) {
        const { nested, top } = Object.values(cardIdMap.parents).reduce<{
          top: number
          nested: number
        }>(
          (carry, parentCards) => {
            if (parentCards.length > 0) {
              carry.nested++
            } else {
              carry.top++
            }
            return carry
          },
          {
            top: 0,
            nested: 0,
          }
        )
        baseDocData.docNumAllCards = nested + top
        baseDocData.docNumTopLevelCards = top
        baseDocData.docNumNestedCards = nested
      }
    }

    compute()
    return reduxStore.subscribe(compute)
  }, [reduxStore])

  useEffect(() => {
    return () => {
      baseDocData = {}
    }
  }, [])
}

export const getBaseDocData = (): BaseDocData =>
  ({
    ...baseDocData,
    docSize: currentEditor?.state.doc.content.size || -1,
  } as BaseDocData)
