import {
  callOrReturn,
  ExtendedRegExpMatchArray,
  InputRule,
  InputRuleFinder,
} from '@tiptap/core'
import { NodeType } from 'prosemirror-model'

import { findSelectionNearOrGapCursor } from './selection/findSelectionNearOrGapCursor'

// Tiptap has a wrappingInputRule, and a textBlockTypeInputRule,
// but sometimes we need both at the same time: change the type
// of the inner node, then wrap it in the outer node.
export function wrappingTransformInputRule(config: {
  find: InputRuleFinder
  innerType: NodeType
  outerType: NodeType
  getAttributes?:
    | Record<string, any>
    | ((match: ExtendedRegExpMatchArray) => Record<string, any>)
    | false
    | null
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range, match }): void => {
      const $from = state.doc.resolve(range.from)

      // If the outer type doesn't work at this spot, bail
      if (
        !$from
          .node(-1) // The parent of the parent, e.g. see if a card can replace its child paragraph with a toggle
          .canReplaceWith(
            $from.index(-1),
            $from.indexAfter(-1),
            config.outerType
          )
      ) {
        return
      }

      const attributes =
        callOrReturn(config.getAttributes, undefined, match) || {}

      // Remove the initial input, like "- " for a bullet
      const tr = state.tr.delete(range.from, range.to)
      const $start = tr.doc.resolve(range.from)
      const block = $start.parent
      const newBlock = config.outerType.createAndFill(attributes, [
        config.innerType.create(null, block.content),
      ])
      tr.replaceWith($start.before(), $start.after(), newBlock)
      const newSelection = findSelectionNearOrGapCursor(
        tr.doc.resolve(range.from)
      )
      if (!newSelection) return
      tr.setSelection(newSelection)
    },
  })
}
