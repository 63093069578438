import { JSONContent } from '@tiptap/core'

import { User } from 'modules/api'
import { TitleLevel } from 'modules/tiptap_editor/extensions/Title'

import { DEFAULT_DOC_BACKGROUND } from '../styles/backgroundStyles'
import { DEFAULT_THEME } from '../styles/themes'

export const emptyDoc = (_user?: User): JSONContent => ({
  type: 'doc',
  content: [
    {
      type: 'document',
      attrs: {
        theme: DEFAULT_THEME,
        background: DEFAULT_DOC_BACKGROUND,
      },
      content: [
        {
          type: 'card',
          content: [
            {
              type: 'title',
              attrs: { level: TitleLevel.DocTitle },
            },
            {
              type: 'contributors',
            },
          ],
        },
        {
          type: 'card',
          content: [
            {
              type: 'heading',
            },
          ],
        },
      ],
    },
  ],
})
