import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { random } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import tinycolor from 'tinycolor2'

import { useThrottled } from 'utils/hooks'

import { ColorWheelMemo } from './ColorWheel'
import { VibeGradient, VibeKey } from './types'
import { generateColorsAndGradient } from './utils'
import { VibeOptions } from './vibes'

type GradientPickerProps = {
  value: Partial<VibeGradient>
  updateValue: (val: Partial<VibeGradient> | undefined) => void
  isDark?: boolean
}

const DEFAULT_GRADIENT: VibeGradient = {
  vibe: 'mono',
  primaryColor: '#75d8ff',
  css: {},
}

export const GradientPicker = ({
  value,
  updateValue,
  isDark,
}: GradientPickerProps) => {
  const { vibe, primaryColor } = { ...DEFAULT_GRADIENT, ...value }
  const [color, setColor] = useState<tinycolor.Instance>(
    tinycolor(primaryColor)
  )
  const { colors, gradient: css } = useMemo(
    () => generateColorsAndGradient(vibe, color),
    [vibe, color]
  )

  const updateGradient = useCallback(
    (newColor: tinycolor.Instance) => {
      const { gradient } = generateColorsAndGradient(vibe, newColor)
      updateValue({
        vibe: vibe,
        primaryColor: newColor.toHexString(),
        css: gradient,
      })
    },
    [vibe, updateValue]
  )

  const updateGradientThrottled = useThrottled(updateGradient, 100)
  const updateColor = useCallback(
    (newColor: tinycolor.Instance) => {
      setColor(newColor)
      updateGradientThrottled(newColor)
    },
    [updateGradientThrottled]
  )

  return (
    <HStack spacing={4} alignItems="stretch" wrap="wrap">
      <Box flex="1" minW="120px">
        <ColorWheelMemo
          vibe={VibeOptions[vibe]}
          color={color}
          updateColor={updateColor}
          colors={colors}
        />
      </Box>
      <Stack flex="2" spacing={4} minW="180px">
        {/* Preview */}
        <Flex
          backgroundColor={isDark ? 'black' : 'white'}
          {...css}
          opacity={1}
          flex="1"
          borderRadius="md"
          shadow="md"
          minH="80px"
          mt={2}
        ></Flex>

        {/* Controls */}
        <HStack>
          <Menu>
            <MenuButton
              w="100%"
              as={Button}
              size="sm"
              variant="plain"
              textAlign="left"
              rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
            >
              {VibeOptions[vibe].name}
            </MenuButton>
            <MenuList minWidth="240px">
              <MenuOptionGroup
                onChange={(v: VibeKey) => {
                  const { gradient } = generateColorsAndGradient(v, color)
                  updateValue({
                    vibe: v,
                    primaryColor: color.toHexString(),
                    css: gradient,
                  })
                }}
                value={vibe}
                title="Vibe"
                type="radio"
              >
                {Object.entries(VibeOptions).map(([key, { name }]) => (
                  <MenuItemOption value={key} key={key}>
                    {name}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          <ButtonGroup size="sm" variant="plain">
            <GammaTooltip placement="top" label="Surprise me">
              <IconButton
                icon={<FontAwesomeIcon icon={regular('dice')} />}
                aria-label="Surprise me"
                onClick={() => {
                  updateColor(
                    tinycolor.fromRatio({
                      h: random(0, 360),
                      s: 1,
                      l: random(0.5, 0.8),
                    })
                  )
                }}
              />
            </GammaTooltip>
          </ButtonGroup>
        </HStack>
      </Stack>
    </HStack>
  )
}
