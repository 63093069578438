import { Button, Badge, HStack, ButtonProps } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { memo } from 'react'

type GenericBlockCommentButtonTypes = {
  isMobile: boolean
  icon?: IconProp
  badgeCount?: number
  tooltip?: string
} & ButtonProps

export const GenericBlockCommentButton: React.FC<GenericBlockCommentButtonTypes> =
  memo(({ isMobile, icon, badgeCount, tooltip, children, ...buttonProps }) => {
    if (isMobile) {
      delete buttonProps['_hover']
    }
    const inner = (
      <HStack className="block-comment-button-wrapper">
        <Button
          variant="custom"
          borderRadius="full"
          size="sm"
          bgGradient="none"
          color="yellow.600"
          boxSize={isMobile ? 10 : 8}
          border={isMobile ? '' : '1px solid white'}
          transitionProperty="common"
          transitionDuration="normal"
          _hover={
            isMobile
              ? {}
              : {
                  bgGradient: 'linear(to-b, yellow.300, yellow.400)',
                  color: 'yellow.700',
                }
          }
          {...buttonProps}
        >
          {icon && (
            <FontAwesomeIcon size={isMobile ? 'lg' : 'sm'} icon={icon} />
          )}

          {children && children}

          {badgeCount !== undefined && (
            <Badge
              colorScheme="yellow"
              borderRadius="full"
              pos="absolute"
              bottom={-1}
              right={-1}
              shadow="base"
              data-testid="badge-count"
            >
              {badgeCount}
            </Badge>
          )}
        </Button>
      </HStack>
    )

    return tooltip ? (
      <GammaTooltip placement="left" label={tooltip}>
        {inner}
      </GammaTooltip>
    ) : (
      inner
    )
  })

GenericBlockCommentButton.displayName = 'GenericBlockCommentButton'
