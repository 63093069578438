import {
  useState,
  useEffect,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react'

import { useUserContext } from 'modules/user'
import { useEffectWhen } from 'utils/hooks'

import { AnalyticsWrapper, analytics } from './AnalyticsWrapper'

export const SegmentAnalyticsContext = createContext<
  AnalyticsWrapper | undefined
>(undefined)

type SegmentIdentifyTraits = {
  is_gamma_employee: boolean
  user_org_id: string | undefined
  anonymous_user: boolean
  gamma_employee_first_name: string | undefined
  gamma_user_id: string | null
  anonymous_user_id: string | null
}

export const SegmentContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [hasDoneInitialIdentify, setHasDoneInitialIdentify] = useState(false)
  const [analyticsSdk, setAnalyticsSdk] = useState<
    AnalyticsWrapper | undefined
  >(undefined)
  const { user, anonymousUser, isUserLoading, currentWorkspace } =
    useUserContext()

  useEffect(() => {
    if (hasDoneInitialIdentify || isUserLoading) {
      return
    }

    analytics.loadSdkPromise.then((sdk) => {
      if (!sdk) {
        return
      }
      const userId = user?.id
      let traits: SegmentIdentifyTraits
      if (user) {
        const { email } = user
        const isGammaUser = email ? email.endsWith('@gamma.app') : false
        traits = {
          is_gamma_employee: isGammaUser,
          user_org_id: currentWorkspace?.id,
          anonymous_user: false,
          anonymous_user_id: anonymousUser.id,
          gamma_user_id: user.id,
          gamma_employee_first_name: isGammaUser ? user.firstName : undefined,
        }
      } else {
        traits = {
          is_gamma_employee: false,
          user_org_id: 'no_org_id',
          anonymous_user: true,
          anonymous_user_id: anonymousUser.id,
          gamma_user_id: null,
          gamma_employee_first_name: undefined,
        }
      }
      // initial identify call
      analytics.setAnonymousId(anonymousUser.id)
      analytics.identify(userId, traits)

      setAnalyticsSdk(analytics)
      setHasDoneInitialIdentify(true)
    })
  }, [
    anonymousUser,
    hasDoneInitialIdentify,
    isUserLoading,
    user,
    currentWorkspace,
  ])

  return (
    <SegmentAnalyticsContext.Provider value={analyticsSdk}>
      {children}
    </SegmentAnalyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  return useContext(SegmentAnalyticsContext)
}

/**
 * Helper function around useEffect and useAnalytics
 *
 * Will only run the specified fn when the deps change, useful for tracking
 * something like an ID changing
 */
export const useAnalyticsEffect = (
  fn: (analytics: AnalyticsWrapper) => void,
  deps: any[] = []
) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const analytics = useAnalytics()
  useEffectWhen(
    () => {
      if (!analytics) {
        return
      }

      fn(analytics)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analytics, fn, ...deps],
    [analytics, ...deps]
  )
}
