import { Extension } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'
import { TouchEvent } from 'react'

import { isCardNode } from '../../Card/utils'
import { createMobileAnnotationPlugin } from './MobileAnnotationPlugin'
import {
  MobileAnnotationPluginKey,
  SetMobileAnnotationBlockCommentEvent,
} from './MobileAnnotationState'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mobileAnnotations: {
      setMobileAddBlockComment: (ev: MouseEvent | null) => ReturnType
      resetMobileAddBlockComment: () => ReturnType
    }
  }
}

type FoundAnnotatableBlock = {
  node: Node
  pos: number
  end: number
}
export const findAnnotatableBlock = (
  pos: number,
  view: EditorView
): FoundAnnotatableBlock | null => {
  let found: FoundAnnotatableBlock | null = null
  view.state.doc.descendants((node: Node, p: number, parent: Node) => {
    const parentIsCard = isCardNode(parent)

    if (!parentIsCard && parent.type.spec.group === 'block') {
      // Prevent recursing down into blocks that aren't annotatable for performance,
      // but don't do this for the top-level document nodes
      return false
    }

    if (node.type.name === 'card') {
      // Cards are not annotatable nodes
      return
    }

    if (parentIsCard && pos >= p && pos < p + node.nodeSize) {
      found = {
        node,
        pos: p,
        end: p + node.nodeSize,
      }
    }
    return
  })

  return found
}

export const MobileAnnotationExtension = Extension.create({
  name: 'mobileAnnotation',

  addCommands() {
    return {
      setMobileAddBlockComment:
        (ev: MouseEvent | null) =>
        ({ dispatch, tr, view }) => {
          if (!dispatch) {
            return false
          }
          if (ev === null) {
            tr.setMeta(MobileAnnotationPluginKey, <
              SetMobileAnnotationBlockCommentEvent
            >{
              setPos: null,
            })
            dispatch(tr)
            return true
          }
          const { clientX, clientY } = ev
          const pos = view.posAtCoords({
            left: clientX,
            top: clientY,
          })
          // could not find an element here, no-op
          if (pos === null) {
            return true
          }

          const found = findAnnotatableBlock(pos.inside, view)
          if (!found) {
            return false
          }
          // compute the offset x, y (x, y distance from the container element)
          const coords = view.coordsAtPos(found.pos)
          const offsetX = clientX - coords.left
          const offsetY = clientY - coords.top
          tr.setMeta(MobileAnnotationPluginKey, <
            SetMobileAnnotationBlockCommentEvent
          >{
            setPos: {
              pos: found.pos,
              end: found.end,
              offsetX,
              offsetY,
            },
          })
          dispatch(tr)
          return true
        },
    }
  },

  addProseMirrorPlugins() {
    return [createMobileAnnotationPlugin(this.editor)]
  },
})
