import {
  absolutePositionToRelativePosition,
  relativePositionToAbsolutePosition,
  ySyncPluginKey,
} from '@gamma-app/y-prosemirror'
import { EditorState, Transaction, PluginKey } from 'prosemirror-state'
import * as Y from 'yjs'

export const LayoutResizingPluginKey = new PluginKey<LayoutResizingState>(
  'layoutResizing'
)

export type DraggingState = {
  startX: number
  startWidth: number
  colWidths: number[]
  colIndex: number
  tableWidth: number
}
export type LayoutResizingSetHandleEvent = {
  setHandle: number | null
}
export type LayoutResizingSetDraggingEvent = {
  setDragging: DraggingState | null
}
export type LayoutResizingResetEvent = {
  reset: true
}

type LayoutResizingEvent =
  | LayoutResizingSetDraggingEvent
  | LayoutResizingSetHandleEvent
  | LayoutResizingResetEvent

export class LayoutResizingState {
  constructor(
    public activeHandle: Y.RelativePosition | null = null,
    public dragging: DraggingState | null = null
  ) {}

  getActiveHandleAbs(state: EditorState): number | null {
    const ystate = ySyncPluginKey.getState(state)
    if (!ystate) {
      return null
    }
    const { doc, type, binding } = ystate
    if (!ystate.binding) {
      return null
    }
    if (this.activeHandle === null) {
      return null
    }
    return relativePositionToAbsolutePosition(
      doc,
      type,
      this.activeHandle,
      binding.mapping
    )
  }

  reset(): this {
    this.activeHandle = null
    this.dragging = null
    return this
  }

  setHandle(state: EditorState, event: LayoutResizingSetHandleEvent): this {
    if (event.setHandle === null) {
      this.activeHandle = null
      return this
    }
    const ystate = ySyncPluginKey.getState(state)
    if (!ystate) {
      return this
    }
    const { type, binding } = ystate
    this.activeHandle = absolutePositionToRelativePosition(
      event.setHandle,
      type,
      binding.mapping
    )
    return this
  }

  setDragging(event: LayoutResizingSetDraggingEvent): this {
    this.dragging = event.setDragging
    return this
  }

  apply(tr: Transaction, state: EditorState): this {
    const action = tr.getMeta(
      LayoutResizingPluginKey
    ) as LayoutResizingEvent | null

    if (action && 'setHandle' in action) {
      return this.setHandle(state, action)
    }
    if (action && 'setDragging' in action) {
      return this.setDragging(action)
    }
    if (action && 'reset' in action) {
      return this.reset()
    }

    // for other changes we count on Y.RelativePosition to hold it's position
    // correctly
    return this
  }
}
