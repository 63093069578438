import { ButtonGroup, Center, Divider, HStack, Image } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor, findParentNode } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { NodeSelection, Selection } from 'prosemirror-state'
import { useCallback } from 'react'

import { Layout } from 'modules/tiptap_editor/extensions/Layout'

import { ToolbarButton } from '../ToolbarButton'
import Icon3Col from './icons/layout-3-cols.svg'
import Icon3070 from './icons/layout-30-70.svg'
import Icon4Col from './icons/layout-4-cols.svg'
import Icon5050 from './icons/layout-50-50.svg'
import Icon7030 from './icons/layout-70-30.svg'

type LayoutFormattingMenuProps = {
  editor: Editor
}

type ColPreset = {
  name: string
  image: any
  value: number[]
  testId: string
}

const presets: ColPreset[] = [
  {
    name: '50:50',
    testId: 'layout-preset-5050',
    image: Icon5050,
    value: [50, 50],
  },
  {
    name: '70:30',
    testId: 'layout-preset-7030',
    image: Icon7030,
    value: [70, 30],
  },
  {
    name: '30:70',
    testId: 'layout-preset-3070',
    image: Icon3070,
    value: [30, 70],
  },
  {
    name: '1:1:1',
    testId: 'layout-preset-3col',
    image: Icon3Col,
    value: [33.33, 33.33, 33.34],
  },
  {
    name: '1:1:1:1',
    testId: 'layout-preset-4col',
    image: Icon4Col,
    value: [25, 25, 25, 25],
  },
]

const LayoutPresetButton = ({
  name,
  image,
  onClick,
  isActive,
  testId,
}: {
  name: string
  image: any
  isActive: boolean
  onClick: () => void
  testId: string
}) => {
  return (
    <ToolbarButton
      key={name}
      // label={name}
      onClick={onClick}
      isActive={isActive}
      testId={testId}
      p={0}
      mx="2px"
    >
      <Image display="inline" h="2rem" objectFit="cover" src={image.src} />
    </ToolbarButton>
  )
}

const findParentLayout = (
  selection: Selection
): { node: Node | null; pos: number | null } => {
  if (
    selection instanceof NodeSelection &&
    selection.node.type.name === Layout.name
  ) {
    return {
      node: selection.node,
      pos: selection.from,
    }
  }
  const found = findParentNode((a) => a.type.name === Layout.name)(selection)
  if (found) {
    return {
      node: found.node,
      pos: found.pos,
    }
  }
  return {
    node: null,
    pos: null,
  }
}

export const LayoutFormattingMenu = ({ editor }: LayoutFormattingMenuProps) => {
  const { selection } = editor.state
  const { node: layout, pos: layoutPos } = findParentLayout(selection)

  const checkActive = (value: number[]) => {
    if (!layout) {
      return false
    }

    return JSON.stringify(value) === JSON.stringify(layout.attrs.colWidths)
  }

  const deleteLayout = useCallback(() => {
    if (!layoutPos) {
      return
    }
    editor
      .chain()
      .selectNodeAtPos(layoutPos)
      .deleteSelectionAndSelectNear()
      .run()
  }, [editor, layoutPos])

  return (
    <>
      <ButtonGroup spacing={1} size="sm" alignItems="center">
        <HStack spacing={0}>
          {presets.map(({ testId, name, image, value }) => {
            return (
              <LayoutPresetButton
                key={name}
                name={name}
                image={image}
                isActive={checkActive(value)}
                testId={testId}
                onClick={() => {
                  if (!layoutPos) {
                    return
                  }
                  editor.commands.useLayoutPreset(layoutPos, value)
                }}
              />
            )
          })}
        </HStack>
        <Center h="20px">
          <Divider borderColor="gray.300" orientation="vertical" />
        </Center>
        <ToolbarButton
          label="Delete layout"
          icon={regular('trash')}
          onClick={deleteLayout}
          testId="delete-layout-button"
          color="red"
        />
      </ButtonGroup>
    </>
  )
}
