import { memoize } from 'lodash'
import throttle from 'lodash/debounce'
import { RefObject, useEffect, useMemo, useState } from 'react'

import { EventEmitter } from 'utils/EventEmitter'

// The extra time we'll wait beyond the period for an event to come in.
const TIMEOUT_BUFFER = 100

class ContainerResizingEmitter extends EventEmitter<{
  resize: Event
}> {
  emitResize: (e: MouseEvent) => void

  constructor() {
    super()

    this.emitResize = (e: MouseEvent) => {
      this.emit('resize', e)
    }

    document.addEventListener('containerResize', this.emitResize)
  }

  cleanup() {
    document.removeEventListener('containerResize', this.emitResize)
  }
}

export const getEmitter = memoize(() => new ContainerResizingEmitter())

export const useContainerResizing = (
  ref: RefObject<HTMLElement | null>,
  period = 250
) => {
  const [isResizing, setResizing] = useState(false)

  const emitter = useMemo(getEmitter, [])

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const handleResize = throttle(
      (e: Event) => {
        const { target } = e
        if (
          !target ||
          !ref.current ||
          !isParentNode(ref.current, target as HTMLElement)
        ) {
          return
        }

        clearTimeout(timeout)
        setResizing(true)

        // Wait for a little longer than the period to ensure the
        // throttled events have stopped coming in before we set
        // the resizing state back to false.
        timeout = setTimeout(() => {
          setResizing(false)
        }, period + TIMEOUT_BUFFER)
      },
      period,
      { leading: true, trailing: false, maxWait: period }
    )

    return emitter.on('resize', handleResize)
  }, [emitter, period, ref])

  return isResizing
}
export const dispatchContainerResizeEvent = (el: HTMLElement) => {
  el.dispatchEvent(
    new Event('containerResize', {
      bubbles: true,
    })
  )
}

const isParentNode = (refEl: HTMLElement, target: HTMLElement): boolean => {
  let node: HTMLElement | null = refEl
  do {
    if (node !== target) {
      continue
    }

    return true
  } while ((node = node.parentElement))
  return false
}
