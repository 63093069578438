import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

export type IFrameOptions = {
  allowPopups?: boolean
  preventScroll?: boolean
  noSandbox?: boolean // PDF viewer breaks when using the sandbox attr https://bugs.chromium.org/p/chromium/issues/detail?id=413851
}

const defaultIframeOptions = {
  allowPopups: false,
  noSandbox: false,
  preventScroll: undefined,
}

type BrowserProps = FlexProps & {
  initialUrl: string
  frameRef?: React.RefObject<HTMLIFrameElement>
  iframeOptions?: IFrameOptions
}

export const Browser = ({
  initialUrl,
  frameRef,
  iframeOptions,
  backgroundColor = 'white',
  ...rest
}: BrowserProps) => {
  const { allowPopups, preventScroll, noSandbox } =
    iframeOptions || defaultIframeOptions

  const sandboxOptions = noSandbox
    ? undefined
    : `allow-forms allow-scripts allow-same-origin allow-modals allow-pointer-lock ${
        allowPopups ? 'allow-popups' : ''
      }`
  return (
    <Flex
      w="100%"
      h="100%"
      direction="column"
      background={backgroundColor}
      {...rest}
    >
      <iframe
        ref={frameRef}
        src={initialUrl}
        frameBorder="0"
        width="100%"
        height="100%"
        allow="encrypted-media; camera; microphone"
        allowFullScreen={true}
        title="Embedded app"
        sandbox={sandboxOptions}
        scrolling={preventScroll ? 'no' : undefined}
        style={{ flex: 1 }}
      ></iframe>
    </Flex>
  )
}
