import {
  generateHTML,
  generateText,
  JSONContent,
  Node,
  Mark,
  NodeViewProps,
} from '@tiptap/core'

import { getBaseExtensions } from 'modules/tiptap_editor/EditorCore'

// This is a list of nodes whose targetHTML we want to save when saving comments
const ALLOWABLE_NODES_FOR_TARGET_HTML = new Set([
  'bullet',
  'numbered',
  'todo',
  'title',
  'heading',
  'paragraph',
  'image',
  'blockquote',
  'embed',
  'video',
])

export const generateCommentJson = (text: []): JSONContent => {
  return {
    type: 'doc',
    content: [
      {
        type: 'blockquote',
        content: text.map((i) => {
          const paragraphContent: JSONContent[] = [
            {
              type: 'text',
              text: `${i ? i : ' '}`,
            },
          ]
          return {
            type: 'paragraph',
            content: paragraphContent,
          }
        }),
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: ' ',
          },
        ],
      },
    ],
  }
}

export const generateHtmlFromNode = (
  node: NodeViewProps['node'],
  excludeNodeTypes?: string[]
) => {
  if (ALLOWABLE_NODES_FOR_TARGET_HTML.has(node.type.name)) {
    const cardNode = {
      type: 'card',
      content: [node.toJSON()],
    }

    const extensions = getBaseExtensions().map((extension) =>
      excludeNodeTypes?.includes(extension.name)
        ? extension instanceof Mark
          ? extension.extend({ renderHTML: () => ['span', 0] })
          : extension.extend({
              renderHTML: () => '',
            })
        : extension
    )

    // In order to render the full HTML representation of a node (for example, a
    // paragraph node as a `<p>nut</p>`), we need to wrap the node whose HTML we want
    // inside an empty card node. Hat tip to the tests here:
    // https://github.com/ProseMirror/prosemirror-model/blob/23221300428f1b485cb17d86bb1bd72570096647/test/test-dom.js#L35
    return generateHTML(cardNode || {}, extensions)
  }
  return ''
}

export const generateTextFromNode = (
  node: NodeViewProps['node'],
  excludeNodeTypes?: string[]
) => {
  if (ALLOWABLE_NODES_FOR_TARGET_HTML.has(node.type.name)) {
    const cardNode = {
      type: 'card',
      content: [node.toJSON()],
    }

    const extensions = getBaseExtensions().map((extension) =>
      excludeNodeTypes?.includes(extension.name)
        ? extension instanceof Mark
          ? extension.extend({ renderText: () => '' })
          : extension.extend({
              renderText: () => '',
            })
        : extension
    )

    // Guess we'll make this a cardNode to keep it consistent with the generated HTML too.
    return generateText(cardNode || {}, extensions)
  }
  return ''
}
