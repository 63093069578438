export enum EditorModeEnum {
  DOC_VIEW = 'DOC_VIEW',
  SLIDE_VIEW = 'SLIDE_VIEW',
}

export type CardId = string
export type RecursiveMap = { [cardId: CardId]: RecursiveMap | {} }

// See cardIdMap.ts for how this is used.
export type CardIdMap = {
  tree: RecursiveMap
  parents: { [cardId: CardId]: CardId[] }
}
export type CardIds = CardId[]
