import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { EmptyCellContent } from '../constants'
import { NumberedOption } from '../options/NumberedOption'
import { TwoSidedOption } from '../options/TwoSidedOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

export const TimelineWrapper: SmartLayoutWrapperComponent = ({
  children,
  options,
}) => {
  const roomForTwoSided = useBreakpointValue({ base: false, xl: true }) || false
  const twoSided = options.twoSided && roomForTwoSided
  return (
    <Box
      borderLeft="2px solid var(--accent-color)"
      position="relative"
      left={twoSided ? '50%' : '0%'}
      w={twoSided ? '50%' : '100%'}
      transitionProperty="width, left"
      transitionDuration="normal"
    >
      {children}
    </Box>
  )
}

export const TimelineCell: SmartLayoutCellComponent = ({
  children,
  index,
  layoutOptions,
}) => {
  const num = index + 1
  const roomForTwoSided = useBreakpointValue({ base: false, xl: true }) || false
  const twoSided = layoutOptions.twoSided && roomForTwoSided
  const isEven = twoSided && num % 2 == 0

  return (
    <Box
      position="relative"
      pl={isEven ? 0 : '2em'}
      pr={isEven ? '2em' : 0}
      right={isEven ? '100%' : undefined}
      transformOrigin={isEven ? 'right center' : 'left center'}
    >
      <Flex
        fontWeight="bold"
        borderRadius="full"
        w="1.5em"
        h="1.5em"
        color="white"
        align="center"
        justify="center"
        backgroundColor="var(--accent-color)"
        position="absolute"
        left={isEven ? undefined : '-0.75em'}
        right={isEven ? '-0.75em' : undefined}
        top="0.35em"
        pointerEvents="none" // Prevents clicking the numbers from doing a weird select
      >
        {layoutOptions.numbered ? num : ''}
      </Flex>
      <Box position="relative" textAlign={isEven ? 'right' : 'left'}>
        {children}
      </Box>
    </Box>
  )
}

export const Timeline: SmartLayoutVariant = {
  key: 'timeline',
  name: 'Timeline',
  icon: regular('timeline'),
  options: [TwoSidedOption, NumberedOption],
  Wrapper: TimelineWrapper,
  Cell: TimelineCell,
  defaultContent: EmptyCellContent,
}
