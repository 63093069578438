import {
  CommandProps,
  Extension,
  findParentNode,
  InputRule,
} from '@tiptap/core'
import { cloneDeep } from 'lodash'
import { wrapIn } from 'prosemirror-commands'
import { GapCursor } from 'prosemirror-gapcursor'
import { NodeSelection } from 'prosemirror-state'

import { UniqueAttributePluginKey } from 'modules/tiptap_editor/plugins'
import { getInsertedNodePos } from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'
import { findSelectionNearOrGapCursor } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

import { EMPTY_NODES } from '../../commands/emptyNodes'
import { isCardNode } from '../../utils/nodeHelpers'
import { pruneCardIds } from '../../utils/transform'
import {
  AnnotationEvent,
  MergeCardsAnnotationEvent,
  MoveAnnotationEvent,
  SplitCardAnnotationEvent,
  UnnestCardAnnotationEvent,
} from '../Annotatable/AnnotationExtension/types'
import { computeInsertNestedCardMoves } from '../Annotatable/utils'
import { FindParentNodeResult } from '../Layout'
import { CARD_DEPTH, CARD_NODE_NAME } from './constants'

import { Card, isCardEmpty } from './index'

type RearrangeArgs = {
  from: number
  to: number
  position: 'above' | 'below' | 'inside'
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    cardCommands: {
      splitCardAtSelection: () => ReturnType
      insertNestedCard: () => ReturnType
      insertCardAfter: () => ReturnType
      convertToNestedCard: () => ReturnType
      unnestCard: (pos: number) => ReturnType
      mergeCardsAtPos: (pos: number) => ReturnType
      mergeCardsOnDelete: (forward: boolean) => ReturnType
      deleteCardIfEmpty: (forward: boolean) => ReturnType
      rearrangeCards: (opts: RearrangeArgs) => ReturnType
      duplicateCard: (pos: number) => ReturnType
      deleteCard: (pos: number) => ReturnType
    }
  }
}

// including `—-` for tiptap's typography emdash extension which replaces -- with —
const SPLIT_REGEX = /^(\*\*\*|---|___|—-)/

export const CardCommands = Extension.create({
  name: 'cardCommands',

  addCommands() {
    return {
      splitCardAtSelection: () => (props) => {
        splitCardAtSelection(props)
        return true
      },
      insertNestedCard:
        () =>
        ({ state, chain, dispatch, editor }) => {
          if (!dispatch) return true
          const { from, $from } = state.selection
          chain()
            .insertContentAndSelect(EMPTY_NODES.insertCardInside)
            .command(({ tr }) => {
              const toMove = computeInsertNestedCardMoves({
                editor,
                // we want the parent node, not the text selection which is at `from`
                pos: from - $from.parentOffset - 1,
                tr,
              })
              if (toMove.length) {
                // queue up move instructions until the
                requestAnimationFrame(() => {
                  editor.commands.moveAnnotations?.(toMove)
                })
              }
              return true
            })
            .run()
          return true
        },
      convertToNestedCard:
        () =>
        ({ state, dispatch, commands }) => {
          if (!dispatch) return true
          const result = wrapIn(state.schema.nodes[Card.name])(state, dispatch)
          // this works because state.tr and commands tr are the same instance
          commands.selectInsertedNode()
          return result
        },
      unnestCard:
        (pos) =>
        ({ state, dispatch, chain }) => {
          const card = state.doc.nodeAt(pos)
          if (!card || !isCardNode(card)) return false
          if (!dispatch) return true

          chain()
            .insertContentAt(
              { from: pos, to: pos + card.nodeSize },
              card.toJSON().content
            )
            .selectInsertedNode()
            .command(({ tr }) => {
              tr.setMeta('annotationEvent', <UnnestCardAnnotationEvent>{
                type: 'unnest-card',
                cardPos: pos,
              })
              return true
            })
            .run()
          return true
        },
      mergeCardsAtPos:
        (joinPos) =>
        ({ tr }) => {
          const event: MergeCardsAnnotationEvent = {
            type: 'merge-cards',
            joinPos,
          }

          // this works because tr and commands tr are the same instance
          tr.join(joinPos).setMeta('annotationEvent', event)
          const sel = findSelectionNearOrGapCursor(tr.doc.resolve(joinPos - 1))
          if (sel) {
            tr.setSelection(sel)
          }

          return true
        },
      mergeCardsOnDelete:
        (forward) =>
        ({ tr, state, dispatch }) => {
          if (!dispatch) return true
          const { selection } = state
          const parentCard = findParentNode(isCardNode)(selection)
          if (!parentCard || !selection.empty) return false

          const isGapCursor = selection instanceof GapCursor
          // +2 = inside the card > inside the text block
          // Could also be +1 if we have a GapCursor
          const isAtEdge = forward
            ? selection.from >= parentCard.pos + parentCard.node.nodeSize - 2
            : selection.from <= parentCard.pos + 2

          if (!isAtEdge || selection.from <= CARD_DEPTH) return false
          try {
            const joinPos = forward
              ? parentCard.pos + parentCard.node.nodeSize
              : parentCard.pos
            const event: MergeCardsAnnotationEvent = {
              type: 'merge-cards',
              joinPos,
            }
            tr.join(joinPos).setMeta('annotationEvent', event)
            // the replace always happens at { from: joinPos - 1, to: joinPos + 1 }
            // if we're joining forward and not gapcursor, then we are at the end of
            // text selection, keep the cursor here (so subtract another - 1)
            const newCursorPos = joinPos - 1 - (forward && !isGapCursor ? 1 : 0)
            const sel = findSelectionNearOrGapCursor(
              tr.doc.resolve(newCursorPos)
            )
            if (sel) {
              tr.setSelection(sel)
            }
          } catch (err) {
            // We should never crash the app if join failed, so just log the error and move on
            console.debug('[CardCommands] mergeCardsOnDelete failed', err)
            return false
          }
          return true
        },
      insertCardAfter:
        () =>
        ({ state, chain, dispatch }) => {
          if (!dispatch) return true
          const { selection } = state

          const parentCard = findParentNode(isCardNode)(selection)
          if (!parentCard) return true

          const { pos, node } = parentCard
          const end = pos + node.nodeSize
          const newCard = EMPTY_NODES.insertCardInside
          chain()
            .insertContentAt(end, newCard)
            .selectInsertedNode()
            .scrollIntoView()
            .run()
          return true
        },
      deleteCardIfEmpty:
        (forward) =>
        ({ tr, dispatch, state }) => {
          if (!dispatch) return true
          let didDelete = false

          const { from, to } = state.selection
          state.doc.nodesBetween(from, to, (node, pos) => {
            // Find cards in/around the selection
            if (
              isCardNode(node) &&
              isCardEmpty(node) &&
              // Don't delete the first card, nowhere to backspace to
              pos > CARD_DEPTH
            ) {
              const isNestedCard = tr.doc.resolve(pos)!.depth > 1

              tr.deleteRange(pos, pos + node.nodeSize)

              /**
               * if it's a nested card we put cursor at pos and let the bias (forward -> 1, backward -> -1) determine the cursor
               * if it's not a nested card we need to subtract 1 to put at end of prev card or add 1 to put at beginning of new card
               */
              const newSelPos = pos + (isNestedCard ? 0 : forward ? 1 : -1)
              const sel = findSelectionNearOrGapCursor(
                tr.doc.resolve(newSelPos),
                forward ? 1 : -1
              )
              if (sel) {
                tr.setSelection(sel)
              }

              didDelete = true
            }
          })

          return didDelete
        },
      deleteCard:
        (pos: number) =>
        ({ state, dispatch, tr }) => {
          if (!dispatch) return true

          const $pos = state.doc.resolve(pos)
          const isNestedCard = $pos.depth > 1
          const node = $pos.nodeAfter

          if (!node || !isCardNode(node)) {
            console.error(
              '[CardExtension.deleteCard] Cannot delete card. Node unknown'
            )
            return false
          }

          tr.delete(pos, pos + node.nodeSize)
          const sel = findSelectionNearOrGapCursor(
            tr.doc.resolve(pos - (isNestedCard ? 0 : 1)),
            -1
          )
          if (sel) {
            tr.setSelection(sel)
          }

          return true
        },

      duplicateCard:
        (pos: number) =>
        ({ state, chain }) => {
          const node = state.doc.nodeAt(pos)
          if (!node || !isCardNode(node)) {
            console.error(
              '[CardExtension.duplicateCard] Cannot duplicate card. Node unknown'
            )
            return false
          }
          const end = pos + node.nodeSize

          // The UniqueId extension SHOULD (does) handle removing cardIds for us,
          // but it appears sometimes there is a race condition which
          // causes our error checker in generateCardIdMap to fire before
          // the new card id is generated.
          // Delete them here with our prune utility avoid that error.

          // Note that Node.toJSON does NOT create a copy and thus is not safe to mutate,
          // so we make sure to cloneDeep it here
          // See https://github.com/ProseMirror/prosemirror-model/blob/95298fb02744e1a8f41eae50f8a6afde583a8817/src/node.js#L339-L350
          const newNode = pruneCardIds(cloneDeep(node.toJSON()))

          chain()
            .insertContentAt(end, newNode)
            .selectInsertedNode()
            .command(({ tr }) => {
              tr.setMeta(UniqueAttributePluginKey, true)
              return true
            })
            .run()

          return true
        },

      rearrangeCards:
        ({ from, to, position }: RearrangeArgs) =>
        ({ view, state, tr }) => {
          const $to = state.doc.resolve(to)
          const $from = state.doc.resolve(from)
          const fromEndPos = $from.pos + $from.nodeAfter!.nodeSize
          const sel = new NodeSelection($from)
          const slice = sel.content()

          // figure out insertPos
          let insertPosRaw: number

          if (position === 'below') {
            insertPosRaw = $to.pos + $to.nodeAfter!.nodeSize
          } else if (position === 'above') {
            insertPosRaw = $to.pos
          } else {
            insertPosRaw = $to.pos + $to.nodeAfter!.nodeSize - 1
          }
          // inserting content at the same position
          // tell tiptap not to dispatch the transaction
          if (insertPosRaw === $from.pos) {
            tr.setMeta('preventDispatch', true)
            return true
          }

          tr.delete(sel.from, sel.to)
          const insertPos = tr.mapping.map(insertPosRaw)
          tr.replaceRangeWith(insertPos, insertPos, slice.content.firstChild!)

          const $pos = tr.doc.resolve(insertPos + 1)

          tr.setSelection(new NodeSelection($pos))
          const event: MoveAnnotationEvent = {
            type: 'move',
            insertPos,
            insertPosRaw,
            pos: $from.pos,
            end: fromEndPos,
          }
          tr.setMeta('annotationEvent', event)
          view.focus()

          return true
        },
    }
  },

  addInputRules() {
    return [
      new InputRule({
        find: SPLIT_REGEX,
        handler: ({ state, range }) => {
          const { tr } = state
          splitCardAtSelection({
            tr: tr.deleteRange(range.from, range.to),
            dispatch: true,
          })
        },
      }),
    ]
  },
})

type SplitCardProps = {
  tr: CommandProps['tr']
  dispatch: CommandProps['dispatch'] | boolean
}

/**
 * Card splitting can happen in two ways currently:
 *  1. Regex InputRule -> /^(\*\*\*|---|___|—-)/.  NOTE: the regex must happen at beginning of line
 *  2. Slash command (/split).  This can happen, beginning, middle or end of a line
 *
 *
 * Example using `/split`
 *
 * [  beforeLen  ]      [afterLen]
 * This is before /split and after
 *                     ^
 *                     enter pressed and split happens
 *
 * The result should be
 * <Card>
 *   <p>this is before</p>
 * </Card>
 * <Card>
 *   <p>| and after</p>
 *      ^
 *      cursor
 * </Card>
 *
 * Example using `/split` or `---` on an empty line
 *
 * RESULT:
 * <Card>
 *   <p></p>
 * </Card>
 * <Card>
 *   <p>|</p>
 *      ^
 *      cursor
 *   <p>next content</p>
 * </Card>
 *
 * Because of how `tr.split()` works, there may be extraneous blocks of empty text in the previous and/or new cards
 * This function handles cleaning that up.
 *
 * TO FIX THIS:
 *  if [beforeLen] is 0
 *   -> delete the empty block at the previous card
 *
 *  if [afterLen] is 0
 *   -> delete the empty block at the start of the next card
 *
 */
const splitCardAtSelection = ({ tr, dispatch }: SplitCardProps): void => {
  if (!dispatch) return
  const { selection } = tr
  const { $from } = selection
  const parentCard = findParentNode(isCardNode)(
    selection
  ) as FindParentNodeResult
  if (!parentCard) {
    console.error("Couldn't find parent card while splitting", selection)
    return
  }

  const beforeLen = selection.$from.parentOffset
  const parentPos = selection.from - beforeLen - 1
  const afterLen =
    selection.$from.node().content.size - selection.$from.parentOffset

  tr.setMeta(UniqueAttributePluginKey, true) // Force UniqueAttribute extension to assign new ID to card node
  tr.setMeta('annotationEvent', <SplitCardAnnotationEvent>{
    type: 'split-card',
    // use before() here because we have a text selection, and we want the position of the node
    splitPos: $from.before(),
  })

  const { node, depth } = parentCard
  const { type, attrs } = node
  // https://prosemirror.net/docs/ref/#transform.Transform.split
  tr.split($from.pos, $from.depth - depth + 1, [
    {
      type,
      attrs,
    },
  ])

  let insertCardPos = getInsertedNodePos(tr, CARD_NODE_NAME)!.pos
  const newCardChildCount = tr.doc.nodeAt(insertCardPos)!.childCount
  if (beforeLen === 0) {
    tr.delete(parentPos, parentPos + tr.doc.nodeAt(parentPos)!.nodeSize)
    // if we deleted something we need to map over ONLY the most recent delete
    // to find the right cardInsertPos
    insertCardPos = tr.steps[tr.steps.length - 1].getMap().map(insertCardPos)
  }

  if (afterLen === 0 && newCardChildCount > 1) {
    const newPos = insertCardPos + 1
    tr.delete(newPos, newPos + tr.doc.nodeAt(newPos)!.nodeSize)
  }

  const newSel = findSelectionNearOrGapCursor(tr.doc.resolve(insertCardPos + 1))
  if (newSel) {
    tr.setSelection(newSel).scrollIntoView()
  }

  return
}
