import {
  Badge,
  Box,
  Button,
  ButtonProps,
  HStack,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, Suspense } from 'react'

import { User } from 'modules/api'
import { generateNamePhrase } from 'utils/displayNames'

import { BlockReaction } from '../../types'
import { getBlockReactionUsers } from '../utils'
import { EmojiCluster } from './EmojiCluster'

const MotionBox = motion(Box)

type ReactionButtonProps = {
  user?: User
  isMobile: boolean
  canReact: boolean
  reaction: BlockReaction
  emoji: string
  badgeCount: number
  emojiCluster?: string[]
} & ButtonProps

export const ReactionButton: React.FC<ReactionButtonProps> = memo(
  ({
    emoji,
    emojiCluster,
    isMobile,
    user,
    canReact,
    reaction,
    badgeCount,
    onClick,
    ...buttonProps
  }) => {
    const users = getBlockReactionUsers(reaction)
    const userHasReacted = !!users.find((u) => u.id === user?.id)
    const styles: Partial<ButtonProps> = userHasReacted
      ? {
          bg: isMobile ? 'gray.100' : 'gray.100',
          borderColor: isMobile ? 'blackAlpha.200' : 'gray.200',
        }
      : {}
    const usersLabel = generateNamePhrase({
      users,
      selfUserId: user?.id || '',
    })
    const tooltip = (
      <Box alignContent="center">
        {usersLabel}{' '}
        <Text as="span" color="gray.300">
          reacted with :{emoji}:
        </Text>
        {canReact && (
          <Text display="block" color={'gray.500'}>
            {userHasReacted ? 'Click to remove' : 'Click to react'}
          </Text>
        )}
      </Box>
    )

    const inner = (
      <HStack className="block-comment-button-wrapper">
        <Button
          variant="ghost"
          borderRadius="full"
          size="sm"
          boxSize={isMobile ? 10 : 8}
          transitionProperty="common"
          transitionDuration="normal"
          {...styles}
          {...buttonProps}
          onClick={(e) => {
            if (!canReact) {
              return
            }
            if (onClick) {
              onClick(e)
            }
          }}
          pos="relative"
        >
          <Suspense fallback={<Spinner size="sm" />}>
            <Text
              pos="absolute"
              inset={0}
              as="span"
              sx={{
                '.emoji-mart-emoji': {
                  fontSize: '1em', // Get the font size from the surrounding Text
                  span: {
                    cursor: 'inherit',
                    fontSize: '1em !important',
                  },
                  ':hover::before': isMobile
                    ? {}
                    : {
                        backgroundColor: 'transparent',
                      },
                },
              }}
              fontSize={isMobile ? '1.5em' : '1.25em'}
            >
              {/** Emoji.size is expected to be a number. We override it above as 1em */}
              {!emojiCluster && (
                <MotionBox
                  initial={{
                    x: '6px',
                    y: '5px',
                  }}
                  fontSize="20px"
                  position="absolute"
                  zIndex={1}
                >
                  <em-emoji size={0} native={true} id={emoji} />
                </MotionBox>
              )}
              <AnimatePresence>
                <EmojiCluster emojis={emojiCluster} />
              </AnimatePresence>
            </Text>
          </Suspense>

          {badgeCount !== undefined && (
            <Badge
              colorScheme="yellow"
              borderRadius="full"
              pos="absolute"
              bottom={-1}
              right={-1}
              shadow="base"
              zIndex={1}
            >
              {badgeCount}
            </Badge>
          )}
        </Button>
      </HStack>
    )
    return tooltip ? (
      <GammaTooltip placement="left" label={tooltip}>
        {inner}
      </GammaTooltip>
    ) : (
      inner
    )
  }
)

ReactionButton.displayName = 'ReactionButton'
