import { Box, Link, Text } from '@chakra-ui/react'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import { ImageSearch } from '../components/ImageSearch'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import UnsplashIcon from './icons/unsplash.svg'
const UNSPLASH_RANDOM_QUERIES = [
  'wallpaper',
  'textures',
  'architecture',
  'background',
  'nature',
  'gradient',
  'experimental',
  'animals',
  'california',
  'flowers',
  'relax',
  'sky',
  'water',
  'pattern',
  'abstract',
]
const UnsplashPanel = ({
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps) => {
  return (
    <>
      <ImageSearch
        updateAttributes={updateAttributes}
        currentImageUrl={currentAttributes.src}
        defaultQuery={currentAttributes.query}
        randomQueries={UNSPLASH_RANDOM_QUERIES}
        provider="unsplash"
      />
      <Box width="100%" textAlign="center" padding="10px" paddingTop="30px">
        <Text fontSize="sm" color="gray.500">
          Photos from{' '}
          <Link
            href="https://unsplash.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            Unsplash
          </Link>
        </Text>
      </Box>
    </>
  )
}

export const UnsplashProvider: MediaProviderType = {
  key: 'image.unsplash',
  Panel: UnsplashPanel,
  label: 'Unsplash images',
  description: 'Search free high-resolution photos and backgrounds',
  icon: brands('unsplash'),
  image: UnsplashIcon,
  keywords: ['stock', 'photo', 'photography', 'image'],
}
