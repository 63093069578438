import { Editor, findChildren, findChildrenInRange } from '@tiptap/core'
import { range as _range } from 'lodash'
import { GapCursor } from 'prosemirror-gapcursor'
import { Fragment, Node, ResolvedPos } from 'prosemirror-model'
import { findWrapping } from 'prosemirror-transform'
import { EditorView } from 'prosemirror-view'

import { getScrollManager } from 'modules/scroll'
import { CardId } from 'modules/tiptap_editor/types'
import {
  getFirstParentWithHeight,
  __DEBUGGING_addDebuggingOutline,
} from 'utils/dom'
import { startsWithHttp } from 'utils/link'
import { CARD_HASH_PREFIX } from 'utils/url'

type PosAtCoords = ReturnType<EditorView['posAtCoords']>

export const isNodeEmpty = (node: Node) => {
  const hasNoChildren =
    node.type.isBlock && node.childCount === 0 && !node.isAtom
  const isEmptyTextBlock = node.isTextblock && node.content.size === 0
  const isEmpty = hasNoChildren || isEmptyTextBlock
  return isEmpty
}

export const isTreeEmpty = (node) => {
  return (
    isNodeEmpty(node) || (node.childCount == 1 && isTreeEmpty(node.child(0)))
  )
}

// Uses an undocumented method in DOM observer to fix cases where selections are jumping around when they shouldn't be. The try/catch protects us if this method is ever removed or changed.
export const suppressSelectionUpdates = (editor) => {
  try {
    // EditorView type definition doesn't include the dom observer:
    // https://github.com/ProseMirror/prosemirror-view/blob/8fc84abf6126dc9125993227414f10710f7999c2/src/input.js#L35
    // @ts-ignore
    editor.view.domObserver.suppressSelectionUpdates()
  } catch (e) {
    console.error(
      `editor.view.domObserver.suppressSelectionUpdates failed: ${e}`
    )
  }
}

export const getDomNodeFromPos = (editor: Editor, pos: number) => {
  if (editor.isDestroyed) return
  const nodeAt = editor.view.state.doc.nodeAt(pos)
  const nodeDom = editor.view.nodeDOM(pos)
  const domAtPos = editor.view.domAtPos(pos)

  if (!nodeDom && !domAtPos) {
    return
  }

  // If we found a card, prefer its nodeDom
  const cardNode = nodeAt?.type.name === 'card' && nodeDom
  const preferredNode =
    // NodeViews of size 1, like image, dont play well with domAtPos
    // while ones with children dont play well with nodeDOM 🤷
    nodeAt && nodeAt.nodeSize > 1 && domAtPos ? domAtPos.node : nodeDom

  const nodeToUse = cardNode || preferredNode || domAtPos?.node || nodeDom

  // I think this covers text nodes, which dont support getBoundingClientRect
  const finalNode =
    nodeToUse instanceof Element ? nodeToUse : nodeToUse.parentElement
  return finalNode as HTMLElement
}

export const getDomNodeFromPosAtCoords = (
  editor: Editor,
  posAtCoords: PosAtCoords
): {
  node: HTMLElement
  pos: number // The pos that was used to find the node (.pos or .inside)
} | null => {
  if (!posAtCoords) return null

  // Prefer nodeDom via inside, but fallback to domAtPos via pos
  // See https://discuss.prosemirror.net/t/domatpos-for-atom-block-nodes/3800/2
  const domInside = editor.view.nodeDOM(posAtCoords.inside) as
    | HTMLElement
    | undefined
  if (domInside) {
    return { node: domInside, pos: posAtCoords.inside }
  }

  const domPos = editor.view.domAtPos(posAtCoords.pos).node as
    | HTMLElement
    | undefined
  if (domPos) {
    return { node: domPos, pos: posAtCoords.pos }
  }

  return null
}

/**
 * Get the domNode from nodeAt, ensuring that it's not a TEXT_NODE
 */
export const getNodeDOMNonText = (editor: Editor, pos: number) => {
  const domNode = editor.view.nodeDOM(pos) as HTMLElement | undefined
  const nonTextNode =
    domNode?.nodeType === window.Node.TEXT_NODE
      ? domNode.parentElement
      : domNode

  return nonTextNode || undefined
}

/**
 * Attempt to figure out the prosemirror node and pos that is
 * at the top center of the page (with optional topOffset) by
 * starting in the middle and moving to the left if nothing
 * is found.
 */
export const getTopCenterIshNode = (
  editor: Editor,
  parentSelector: string,
  topOffset = 0,
  leftOffset = 0
): {
  node?: HTMLElement | undefined
  pos?: PosAtCoords
  posForNode?: number
} => {
  const rootEl = document.querySelector(parentSelector)
  if (!rootEl) return {}
  const rootRect = rootEl.getBoundingClientRect()
  let posToUse: PosAtCoords | undefined = undefined
  let domNodeToUse: HTMLElement | undefined = undefined
  let posForNode: number | undefined = undefined

  for (const divisor of [2, 2.5, 3, 3.5, 4, 5, 6, 7, 8]) {
    const left = leftOffset + (rootRect.width - leftOffset) / divisor
    const top = rootRect.top + topOffset
    posToUse = editor.view.posAtCoords({ left, top })
    if (!posToUse) continue

    const domNodeFromPos = getDomNodeFromPosAtCoords(editor, posToUse)
    domNodeToUse = domNodeFromPos?.node
    posForNode = domNodeFromPos?.pos
    if (domNodeToUse) {
      break
    }
  }
  return {
    node: domNodeToUse,
    pos: posToUse,
    posForNode,
  }
}

/**
 * Get the pos and scroll percent of the top center node
 */
export const getTopCenterPosPct = (
  editor: Editor,
  topOffset = 0,
  leftOffset = 0
) => {
  const scrollManager = getScrollManager('editor')
  let resultPos: number | undefined = undefined
  let resultDom: HTMLElement | undefined = undefined

  const CHUNK_SIZE = 10 // How many vertical pixels to move up each time we try getTopCenterIshNode
  for (
    let offset = topOffset;
    offset > topOffset / 2;
    offset = offset - CHUNK_SIZE
  ) {
    const nextResult = getTopCenterIshNode(
      editor,
      scrollManager.scrollSelector,
      offset,
      leftOffset
    )
    if (!nextResult.posForNode) continue

    if (!resultPos || nextResult.posForNode > resultPos) {
      resultPos = nextResult.posForNode
      resultDom = nextResult.node
    }
  }

  if (!resultPos) {
    console.warn('[getTopCenterPosPct] No result for getTopCenterIshNode')
    return
  }

  const domNodeToUse = getFirstParentWithHeight(resultDom)
  if (!domNodeToUse || !resultPos) return

  __DEBUGGING_addDebuggingOutline({
    element: domNodeToUse,
    color: '#32ff61',
    requiredCookie: 'spotlightScrollDebug=true',
  })

  const domNodeToUseRect = domNodeToUse.getBoundingClientRect()
  const pct = parseFloat(
    ((topOffset - domNodeToUseRect.y) / domNodeToUseRect.height).toFixed(2)
  )
  console.debug(
    '[getTopCenterPosPct].',
    JSON.stringify({ pct, pos: resultPos }),
    resultDom
  )
  return { pos: resultPos, pct }
}

// Modeled off of https://github.com/atlassian/prosemirror-utils/blob/1b97ff08f1bbaea781f205744588a3dfd228b0d1/src/selection.js#L21
export const findParentNodes = (
  $pos: ResolvedPos,
  predicate: (node: Node, parent: Node) => boolean
) => {
  const matches = [] as {
    pos: number
    start: number
    depth: number
    node: Node
  }[]
  for (let i = $pos.depth; i > 0; i--) {
    const thisNode = $pos.node(i)
    const pos = $pos.posAtIndex(0, i - 1)
    const parentNode = $pos.doc.resolve(pos).parent
    if (predicate(thisNode, parentNode)) {
      matches.push({
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node: thisNode,
      })
    }
  }
  return matches
}

export const findParentNodeClosestToPosWithDepth = (
  $pos: ResolvedPos,
  predicate: (node: Node, depth: number) => boolean
) => {
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i)
    if (predicate(node, i)) {
      return {
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node,
      }
    }
  }
  return
}

// Handles stringifying/parsing complex attributes
export const configureJSONAttribute = (attrName: string) => {
  return {
    parseHTML: (el) => {
      const value = el.getAttribute(`data-${attrName}`)
      if (!value) return
      return JSON.parse(value)
    },
    renderHTML: (attrs) => {
      const attr = attrs[attrName]
      if (!attr) return {}
      return {
        [`data-${attrName}`]: JSON.stringify(attr),
      }
    },
  }
}

export const getCardIdFromHash = (url: string): string | null => {
  if (!url.length || !startsWithHttp(url)) return null
  try {
    const hash = new URL(url).hash
    const cardId = hash?.split(CARD_HASH_PREFIX)?.[1] || null
    return cardId
  } catch {
    return null
  }
}

export const doesMemoContainGivenCardFromUrl = (
  url: string,
  cardIds: CardId[]
) => {
  const cardId = getCardIdFromHash(url)
  return cardId ? cardIds.includes(cardId) : false
}

// Based on https://github.com/ProseMirror/prosemirror-model/blob/95298fb02744e1a8f41eae50f8a6afde583a8817/src/fragment.js#L46
// Only includes nodes that match `nodePredicate`, skipping over any that don't, including their children
export const textBetweenFiltered = (
  root: Node,
  from: number,
  to: number,
  nodePredicate: (node: Node) => boolean,
  blockSeparator: string,
  leafText?: string
) => {
  let text = '',
    separated = true
  root.nodesBetween(
    from,
    to,
    (node, pos): false | void => {
      if (!nodePredicate(node)) return false // Prevents children from being iterated over
      if (node.isText && node.text) {
        text += node.text.slice(Math.max(from, pos) - pos, to - pos)
        separated = !blockSeparator
      } else if (node.isLeaf && leafText) {
        text += leafText
        separated = !blockSeparator
      } else if (!separated && node.isBlock) {
        text += blockSeparator
        separated = true
      }
    },
    0
  )
  return text
}

export const editorHasFocus = (editor: Editor) => {
  return (
    editor.view.hasFocus() ||
    Boolean(document.activeElement?.closest('[data-in-editor-focus]')) // Add this attr to drawers/popovers that are still considered part of the editor
  )
}

export const selectOnClick = (
  editor: Editor,
  event: MouseEvent | React.MouseEvent
) => {
  // If you click top/bottom of the card, bring the cursor there
  // ProseMirror handles this automatically, except when there's a
  // non-text block at the end (eg an image or collapsed card)
  const coords = {
    left: event.clientX,
    top: event.clientY,
  }
  const coordPos = editor.view.posAtCoords(coords)
  if (!coordPos) return false
  try {
    const $coordPos = editor.state.doc.resolve(coordPos.pos)
    // @ts-ignore
    if (!GapCursor.valid($coordPos)) return false
    const gapCursor = new GapCursor($coordPos)
    editor.view.dispatch(editor.state.tr.setSelection(gapCursor))
    event.stopPropagation()
  } catch (err) {
    console.error('[selectOnClick] Error creating GapCursor', err)
  }
  return true
}

// Checks whether a node can be inserted at a given point
// Used in the slash menu and insert widget
export const canInsertNodeAtSelection = (editor: Editor, nodeName: string) => {
  const { selection, schema } = editor.state
  const { $from } = selection
  const range = $from.blockRange(selection.$to)
  const nodeType = schema.nodes[nodeName]
  if (!range) return false

  const canInsertAtCursor = $from.parent.canReplaceWith(
    $from.index(),
    $from.index(),
    nodeType
  )
  const canInsertAfterBlock = range.parent.canReplaceWith(
    range.endIndex,
    range.endIndex,
    nodeType
  )

  return canInsertAfterBlock || canInsertAtCursor
}

// Checks whether a node can be replaced with a given type
// Used in the formatting menu
export const canChangeSelectedNodeType = (editor: Editor, nodeName: string) => {
  const { selection, schema } = editor.state
  const { $from } = selection
  const range = $from.blockRange(selection.$to)
  const nodeType = schema.nodes[nodeName]
  if (!range) return false

  const canReplaceWithBlock = range.parent.canReplaceWith(
    range.startIndex,
    range.endIndex,
    nodeType
  )

  return canReplaceWithBlock
}

// Checks whether the selected range can be wrapped in the given node
// Used in the formatting menu
export const canWrapSelection = (editor: Editor, nodeName: string) => {
  const { selection, schema } = editor.state
  const { $from } = selection
  const range = $from.blockRange(selection.$to)
  const nodeType = schema.nodes[nodeName]
  if (!range) return false

  return !!findWrapping(range, nodeType)
}

export const selectionAllowsMark = (editor: Editor, markName: string) => {
  const { selection, schema, doc } = editor.state
  const { from, to } = selection
  const nodes = findChildrenInRange(doc, { from, to }, (n) =>
    n.type.allowsMarkType(schema.marks[markName])
  )
  return nodes.length > 0
}

export const selectionAllowsAttr = (editor: Editor, attrName: string) => {
  const { selection, doc } = editor.state
  const { from, to } = selection
  const nodes = findChildrenInRange(
    doc,
    { from, to },
    (n) => !!n.type.spec.attrs?.[attrName]
  )
  return nodes.length > 0
}

export const fragmentToArray = (fragment: Fragment): Node[] => {
  return _range(fragment.childCount).map((i) => fragment.child(i))
}

export const rectAtPos = (
  pos: number,
  view: EditorView
): DOMRect | undefined => {
  const dom = view.nodeDOM(pos) as HTMLElement | null
  return dom?.getBoundingClientRect()
}

export const hasImageNodeWithNoSrc = (node) => {
  if (!node) return false
  return (
    findChildren(node, (n) => n.type.name === 'image' && !n.attrs.src).length >
    0
  )
}
