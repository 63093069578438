import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { attrsOrDecorationsChanged } from '../updateFns'
import { CardTableOfContentsView } from './CardTableOfContentsView'

export const CardTableOfContents = Node.create({
  name: 'tableOfContents',
  group: 'block',
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      nested: {
        default: false,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=toc]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'toc' })]
  },

  addNodeView() {
    return ReactNodeViewRenderer(CardTableOfContentsView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
