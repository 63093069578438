import { Node } from 'prosemirror-model'

export enum ListVariant {
  Todo = 'todo',
  Bullet = 'bullet',
  Numbered = 'numbered',
}

export const isListNode = (node: Node) =>
  ['todo', 'bullet', 'numbered'].includes(node.type.name)
