import { ySyncPluginKey } from '@gamma-app/y-prosemirror'
import { nanoid } from 'nanoid'
import { PluginKey } from 'prosemirror-state'

import { UniqueAttribute } from '../../plugins'

const pluginKey = new PluginKey('UniqueMediaId')

export const generateMediaId = () => nanoid(5)

/**
 * Ensure each media node instance has a unique id attribute
 */
export const UniqueMediaId = UniqueAttribute.extend({
  name: 'UniqueMediaId',
}).configure({
  attributeName: 'id',
  types: ['image', 'embed', 'video'],
  initialValue: generateMediaId,
  filterTransaction: (transaction) => {
    const ySyncMeta = transaction.getMeta(ySyncPluginKey)
    // Ensure that the transaction didnt originate from
    // another user (the name is confusing, but see the code here)
    // https://github.com/yjs/y-prosemirror/blob/a2135a683cb8bdb170c4fb909c8f72a88447951c/src/plugins/sync-plugin.js#L356
    return ySyncMeta?.isChangeOrigin !== true
  },
  pluginKey,
})
