import { isEqual } from 'lodash'
import { Node as ProsemirrorNode } from 'prosemirror-model'
import { EditorState, Transaction } from 'prosemirror-state'
import { Dispatch } from 'redux'

import { setDocAttrs } from 'modules/tiptap_editor/reducer'

import { DocumentAttributes } from './attributes'

export class DocumentAttrsPluginState {
  public hasChanges: boolean = false

  public value: DocumentAttributes | null = null

  public compute(doc: ProsemirrorNode): this {
    const document = doc.firstChild
    if (!document) {
      // this shouldn't happen
      return this
    }
    const nextValue = document.attrs as DocumentAttributes

    if (!isEqual(nextValue, this.value)) {
      this.hasChanges = true
    }

    // dont hold on to old ref
    this.value = nextValue
    return this
  }

  public apply(tr: Transaction, _state: EditorState): this {
    if (!tr.docChanged) {
      return this
    }

    this.compute(tr.doc)

    return this
  }

  public processChanges(dispatch: Dispatch): void {
    if (this.hasChanges && this.value) {
      dispatch(setDocAttrs({ docAttrs: this.value }))
    }

    this.hasChanges = false
  }
}
