import { Editor } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'

import { isMobileDevice } from 'utils/deviceDetection'

import { CARD_WRAPPER_CLASS } from '../../Card'
import { findAnnotatableBlock } from '../MobileAnnotationExtension/MobileAnnotationExtension'
import {
  AnnotatableActivatorPluginKey,
  AnnotatableActivatorState,
  SetAnnotatableActivatorPosEvent,
} from './AnnotatableActivatorState'

export function createAnnotatableActivatorPlugin(editor: Editor) {
  const plugin = new Plugin({
    key: AnnotatableActivatorPluginKey,
    state: {
      init() {
        return new AnnotatableActivatorState()
      },
      apply(tr, pluginState, _oldEditorState, newEditorState) {
        return pluginState.apply(tr, newEditorState)
      },
    },

    props: {
      handleDOMEvents: {
        mousemove(view: EditorView, event: MouseEvent) {
          if (isMobileDevice) {
            return
          }

          if (
            !(event.target as HTMLElement).closest(`.${CARD_WRAPPER_CLASS}`)
          ) {
            return
          }
          const { clientX, clientY } = event
          const pos1 = view.posAtCoords({
            left: clientX,
            top: clientY,
          })
          const pos2 = view.posAtCoords({
            left: clientX - 100,
            top: clientY,
          })

          let setPos: SetAnnotatableActivatorPosEvent['setPos'] = null

          if (pos1) {
            const found1 = findAnnotatableBlock(pos1.inside, view)
            if (found1) {
              setPos = found1
            }
          }

          if (!setPos && pos2) {
            const found2 = findAnnotatableBlock(pos2.inside, view)
            if (found2) {
              setPos = found2
            }
          }

          const pluginState = AnnotatableActivatorPluginKey.getState(view.state)
          if (setPos) {
            const existing = pluginState?.getAbsPos(view.state)
            if (
              existing &&
              existing.pos === setPos.pos &&
              existing.end === setPos.end
            ) {
              return
            }

            const tr = view.state.tr
            tr.setMeta(AnnotatableActivatorPluginKey, <
              SetAnnotatableActivatorPosEvent
            >{
              setPos,
            })
            view.dispatch(tr)
          }

          if (setPos === null && pluginState?.pos !== null) {
            // reset if hovering over something that isn't a thing
            const tr = view.state.tr
            tr.setMeta(AnnotatableActivatorPluginKey, <
              SetAnnotatableActivatorPosEvent
            >{
              setPos: null,
            })
            view.dispatch(tr)
          }
        },
      },
      decorations(state) {
        const pluginState = AnnotatableActivatorPluginKey.getState(state)

        const found = pluginState!.getAbsPos(state)

        if (found === null) {
          return DecorationSet.empty
        }

        const deco = Decoration.node(
          found.pos,
          found.end,
          {}, // classes
          {
            isAnnotatableActivator: true,
          }
        )

        try {
          return DecorationSet.create(state.doc, [deco])
        } catch (e) {
          return DecorationSet.empty
        }
      },
    },
  })
  return plugin
}
