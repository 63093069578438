import { gammaTheme } from '@gamma-app/ui'
import { Extension, ExtensionConfig } from '@tiptap/core'
import { ReactRenderer } from '@tiptap/react'
import clamp from 'lodash/clamp'
import { PluginKey } from 'prosemirror-state'
import React, {
  ForwardedRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import tippy, { Instance as TippyInstance } from 'tippy.js'

import {
  Suggestion,
  SuggestionKeyDownProps,
  SuggestionOptions,
  SuggestionProps,
} from './suggestion'

/**
 * A simple factory method to create a Tiptap suggestion Extension
 * instance based on the provided options.
 * The primary use case for the wrapper here is to handle the
 * lifecycle of the tippy instance used for the suggestion.
 */
interface GenerateSuggestionExtension extends ExtensionConfig {
  char: string
  pluginKey: PluginKey
  MenuComponent: React.ComponentType<PropsWithChildren<SuggestionProps>>
  allowSpaces?: boolean
  command?: SuggestionOptions['command']
}

type UseSuggestionKeyboardHandlerArgs = {
  ref: ForwardedRef<any>
  selectItem: (index: number) => void
  options: any[]
}
export const useSuggestionKeyboardHandler = ({
  ref,
  selectItem,
  options,
}: UseSuggestionKeyboardHandlerArgs) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const selectedItemEl = useRef<HTMLButtonElement | null>(null)

  // Keep a reference to the items we need inside handleKeyDown
  // so that we dont have to unbind/rebind when they change
  const keyboardHandlerRef = useRef({
    selectedIndex,
    options,
    selectItem,
  })
  keyboardHandlerRef.current = {
    selectedIndex,
    options,
    selectItem,
  }

  useImperativeHandle(ref, () => {
    // Keep a reference to the items we need inside handleKeyDown
    // so that we dont have to unbind/rebind when they change
    const handleKeydown = (event: KeyboardEvent) => {
      const { key } = event

      const handlerRef = keyboardHandlerRef.current

      if (key === 'ArrowUp') {
        event.preventDefault()
        if (handlerRef.selectedIndex <= 0) {
          setSelectedIndex(options.length - 1)
        } else {
          setSelectedIndex(
            clamp(
              handlerRef.selectedIndex - 1,
              0,
              handlerRef.options.length - 1
            )
          )
        }
      } else if (key === 'ArrowDown') {
        event.preventDefault()
        if (handlerRef.selectedIndex >= handlerRef.options.length - 1) {
          setSelectedIndex(0)
        } else {
          setSelectedIndex(
            clamp(
              handlerRef.selectedIndex + 1,
              0,
              handlerRef.options.length - 1
            )
          )
        }
      } else if (key === 'Enter' || key === 'Tab') {
        event.preventDefault()
        if (handlerRef.options.length > 0) {
          handlerRef.selectItem(selectedIndex)
        }
      }
      event.stopPropagation()
      event.preventDefault()
      return true
    }

    return {
      onKeyDown: ({ event }) => {
        if (['ArrowUp', 'ArrowDown', 'Enter', 'Tab'].includes(event.key)) {
          return handleKeydown(event)
        }

        return false
      },
    }
  })

  useEffect(() => {
    if (selectedItemEl.current) {
      selectedItemEl?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    }
  }, [selectedIndex])

  return { selectedIndex, selectedItemEl }
}

export const createSuggestionExtension = ({
  char,
  MenuComponent,
  pluginKey,
  command,
  allowSpaces = false,
  ...base
}: GenerateSuggestionExtension) =>
  Extension.create({
    ...base,

    addOptions() {
      return {
        HTMLAttributes: {},
        suggestion: {
          char,
          pluginKey,
          allowSpaces,
          startOfLine: false,
          command,
          render: () => {
            let reactRenderer: ReactRenderer | null = null
            let popup: TippyInstance | null = null

            return {
              onStart: (props: SuggestionProps) => {
                if (popup && reactRenderer) {
                  reactRenderer.updateProps(props)
                  popup.setProps({
                    getReferenceClientRect: props.clientRect,
                  })
                  popup.show()
                  return
                }
                // @ts-ignore
                reactRenderer = new ReactRenderer(MenuComponent, {
                  props,
                  editor: props.editor,
                })

                const el = props.editor.view.dom.parentElement
                popup = tippy(el!, {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  placement: 'bottom-start',
                  zIndex: gammaTheme.zIndices.dropdown,
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'preventOverflow',
                        options: {
                          padding: { bottom: 8 },
                        },
                      },
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['auto-start'],
                        },
                      },
                    ],
                  },
                })
              },
              onUpdate(props: SuggestionProps) {
                reactRenderer?.updateProps(props)
                popup?.setProps({
                  getReferenceClientRect: props.clientRect,
                })
              },
              onKeyDown(props: SuggestionKeyDownProps) {
                if (props.event.key === 'Escape') {
                  popup?.destroy()
                  reactRenderer?.destroy()

                  popup = null
                  reactRenderer = null

                  return true
                }
                // @ts-ignore
                return reactRenderer?.ref?.onKeyDown(props)
              },
              onExit(props: SuggestionProps) {
                const next = pluginKey.getState(props.editor.view.state)
                if (next.active !== true) {
                  popup?.destroy()
                  reactRenderer?.destroy()

                  popup = null
                  reactRenderer = null
                }
              },
            }
          },
        },
      }
    },
    addProseMirrorPlugins() {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ]
    },
  })
