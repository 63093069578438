import { Extension } from '@tiptap/core'

import 'katex/dist/katex.min.css'
import { inputRules } from 'prosemirror-inputrules'
import { Node } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'

import { featureFlags } from 'modules/featureFlags'

import { MathDisplay } from './MathDisplay'
import { MathInline } from './MathInline'
import {
  makeBlockMathInputRule,
  makeInlineMathInputRule,
  mathPlugin,
  mathSerializer,
} from './prosemirror-math/'

const INLINE_MATH_REGEX = /\$([^ $]+)\$[ ]$/ // $ ... $, no whitespace, followed by a space
const BLOCK_MATH_REGEX = /^\${2}\s+$/ // $$ followed by a space
const BLOCK_MATH_REGEX_WRAPPING = /^\${2}(.+)\${2}$/ // $$ ... $$

export const Math = Extension.create({
  name: 'math',
  addExtensions() {
    return [MathInline, MathDisplay]
  },

  // https://github.com/benrbray/prosemirror-math#plugins
  addProseMirrorPlugins() {
    const mathEnabled = featureFlags.get('math')
    const mathInputPlugins = mathEnabled
      ? [
          new Plugin({
            props: {
              clipboardTextSerializer: (slice) => {
                return mathSerializer.serializeSlice(slice)
              },
            },
          }),
          inputRules({
            rules: [
              makeBlockMathInputRule(
                BLOCK_MATH_REGEX,
                this.editor.schema.nodes.math_display
              ),
              makeBlockMathInputRule(
                BLOCK_MATH_REGEX_WRAPPING,
                this.editor.schema.nodes.math_display
              ),
              makeInlineMathInputRule(
                INLINE_MATH_REGEX,
                this.editor.schema.nodes.math_inline
              ),
            ],
          }),
        ]
      : []
    return [
      mathPlugin({
        undo: () => this.editor.commands.undo(),
        redo: () => this.editor.commands.redo(),
      }),
    ].concat(mathInputPlugins)
  },
})

export const isMathNode = (n: Node) =>
  n.type.name === 'math_display' || n.type.name === 'math_inline'
