/**
 * These are the lone straggler functions that we still use from prosemirror-utils
 *
 * Most have been replaced by fucntions in titpap/core, suhc as findChildren and findParent
 */
import { findParentNode, findChildren } from '@tiptap/core'
import { MarkType, NodeType, Node as ProseMirrorNode } from 'prosemirror-model'
import { Selection, Transaction } from 'prosemirror-state'

export const findChildrenByMark = (
  node: ProseMirrorNode,
  markType: MarkType
) => {
  return findChildren(node, (child) => !!markType.isInSet(child.marks))
}

/**
 * This function is different than `editor.commands.setTextSelection` because it uses
 * `Selection.findFrom`
 *
 * From Selection.findFrom source code comment:
 *  Find a valid cursor or leaf node selection starting at the given
 *  position and searching back if `dir` is negative, and forward if
 *  positive. When `textOnly` is true, only consider cursor
 *  selections. Will return null when no valid selection position is
 *  found.
 */
export const setTextSelection =
  (position: number, dir: number = 1) =>
  (tr: Transaction) => {
    const nextSelection = Selection.findFrom(
      tr.doc.resolve(position),
      dir,
      true
    )
    if (nextSelection) {
      return tr.setSelection(nextSelection)
    }
    return tr
  }

export const findParentNodeOfType =
  (nodeType: NodeType | NodeType[]) => (selection: Selection) => {
    return findParentNode((node) => equalNodeType(nodeType, node))(selection)
  }

const equalNodeType = (
  nodeType: NodeType | NodeType[],
  node: ProseMirrorNode
) => {
  return (
    (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) ||
    node.type === nodeType
  )
}
