import { Flex, FlexProps, IconButton, Text, Wrap } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import React, { MouseEvent } from 'react'

import { Reaction, TargetType } from 'modules/api'
import { useUserContext } from 'modules/user'
import { generateNamePhrase } from 'utils/displayNames'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { useReactionUpdate } from '../hooks'
import { AddReactionButton } from './AddReactionButton'
import { ReactionButton } from './ReactionButton'

export const TOOLTIP_PLACEMENT = 'bottom'

type ReactionsProps = {
  size: 'xs' | 'sm' | 'md'
  colorScheme?: 'gray'
  placement?: 'right' | 'left'
  usePortal?: boolean
  reactions: any
  docId: string
  cardId?: string
  commentId?: string
  parentCommentId?: string
  targetType: TargetType
  readOnly: boolean
  innerPaddingY?: number
} & FlexProps

export const Reactions = ({
  size,
  colorScheme = undefined,
  placement = 'right',
  usePortal,
  reactions,
  docId,
  cardId,
  commentId,
  parentCommentId,
  targetType,
  readOnly,
  innerPaddingY,
  ...flexProps
}: ReactionsProps) => {
  const { addReactionFn, removeReactionFn } = useReactionUpdate({
    docId,
    commentId,
    parentCommentId,
    cardId,
    targetType,
  })
  const { user: selfUser } = useUserContext()
  const reactionsMap =
    reactions?.reduce((acc, r) => {
      if (r.count) {
        acc[r.emoji] = r
      }
      return acc
    }, {}) || {}

  const showLikeButton =
    !readOnly && targetType === TargetType.Card && !reactionsMap.heart
  const hasReactions = Object.keys(reactionsMap).length > 0
  const handleReactionClick = (emoji: string, event?: MouseEvent) => {
    // Validate that we have the correct data for the given targetType;
    // e.g. if targetType is comment, we must have a commentId; if targetType is card, we must have a cardId.
    // Otherwise, bail
    if (
      !(
        (targetType === TargetType.Comment && commentId) ||
        (targetType === TargetType.Card && cardId)
      )
    ) {
      throw new Error(
        `[Reactions.handleReactionClick] Invalid targetType. Args: targetType: ${targetType}, commentId: ${commentId}, cardId: ${cardId}`
      )
    }
    // Go through the Reactions data and see if the user has already reacted. If so,
    // we need the reactionId so we can remove it.
    const matchingReactionId = reactionsMap[emoji]?.users.find(
      (u) => u.id === selfUser?.id
    )?.id
    if (matchingReactionId) {
      // We found a match, so this is a remove operation
      const count = reactionsMap?.[emoji]?.count - 1
      const users = reactionsMap?.[emoji]?.users
        ? reactionsMap[emoji].users.filter((u) => u.id !== selfUser?.id)
        : []
      removeReactionFn(emoji, count, users)
    } else {
      const count = reactionsMap?.[emoji]?.count
        ? reactionsMap[emoji].count + 1
        : 1
      const users = reactionsMap?.[emoji]?.users
        ? reactionsMap[emoji].users.concat(selfUser)
        : [selfUser]
      addReactionFn(emoji, count, users)
    }
    // We need to stop the event from propagating, otherwise the selectCardEdges code runs and people's
    // cursors jump down
    // https://github.com/gamma-app/gamma/blob/856d7041bde10e989baf44ee2c3cd2db6095218e/packages/client/src/modules/tiptap_editor/extensions/Card/Card.tsx#L151
    event?.stopPropagation()
  }

  return (
    <Flex
      direction={'row'}
      cursor="default"
      contentEditable={false}
      onMouseDown={preventDefaultToAvoidBlur}
      mb={innerPaddingY ? `-${innerPaddingY / 1.5}rem` : undefined}
      {...flexProps}
    >
      {showLikeButton && (
        <GammaTooltip label="Like" placement={TOOLTIP_PLACEMENT}>
          <IconButton
            colorScheme={colorScheme}
            float="inline-start"
            aria-label=""
            variant="ghost"
            onClick={(e) => {
              handleReactionClick('heart', e)
            }}
            size={size}
            isRound={true}
            mr={2}
            icon={<FontAwesomeIcon icon={regular('heart')} />}
          />
        </GammaTooltip>
      )}
      {!readOnly && (
        <AddReactionButton
          size={size}
          colorScheme={colorScheme}
          handleReactionClick={handleReactionClick}
          usePortal={usePortal}
          placement={placement}
        />
      )}
      {hasReactions && (
        <Wrap spacing={size === 'sm' ? 2 : 1} mr={2} className="reactions-row">
          {hasReactions &&
            Object.entries(reactionsMap).map(
              ([emoji, reaction]: [string, Reaction]) => {
                const users = reaction?.users
                const hasSelfReacted = Boolean(
                  users?.find((u) => u?.id === selfUser?.id)
                )
                const usersLabel = generateNamePhrase({
                  users,
                  selfUserId: selfUser?.id as string,
                })
                return (
                  <ReactionButton
                    colorScheme={colorScheme}
                    key={emoji}
                    size={size}
                    emoji={emoji}
                    readOnly={readOnly}
                    label={
                      <>
                        {usersLabel}{' '}
                        <Text as="span" color="gray.300">
                          reacted with :{emoji}:
                        </Text>
                      </>
                    }
                    count={users?.length}
                    hasSelfReacted={hasSelfReacted}
                    handleReactionClick={handleReactionClick}
                  />
                )
              }
            )}
        </Wrap>
      )}
    </Flex>
  )
}
