import { Box, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { getAccentColor } from 'modules/theming'

import { generateGradientSpectrum } from '../colors'
import { EmptyCellContent } from '../constants'
import { NumberedOption } from '../options/NumberedOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

export const BulletsWrapper: SmartLayoutWrapperComponent = ({ children }) => {
  return (
    <Flex gap="1em" direction="row" wrap="wrap">
      {children}
    </Flex>
  )
}

export const BulletsCell: SmartLayoutCellComponent = ({
  children,
  layoutOptions,
  index,
  theme,
  numCells,
}) => {
  const { numbered } = layoutOptions
  const num = index + 1
  const accentColor = getAccentColor(theme)
  const gradient = generateGradientSpectrum(accentColor, 0.5, 0.7, numCells)[
    index
  ]
  return (
    <Flex direction="row" align="flex-start" gap="1em" flex="1 1 0" minW="14em">
      <Flex
        backgroundImage={gradient}
        border="2px solid white"
        fontWeight="bold"
        borderRadius="full"
        w="1.5em"
        h="1.5em"
        color="white"
        shadow="md"
        align="center"
        justify="center"
        mt="2em"
      >
        {numbered ? num : ''}
      </Flex>
      <Box flex="1">{children}</Box>
    </Flex>
  )
}

export const Bullets: SmartLayoutVariant = {
  key: 'bullets',
  name: 'Bullets',
  icon: regular('square-list'),
  options: [NumberedOption],
  Wrapper: BulletsWrapper,
  Cell: BulletsCell,
  defaultContent: EmptyCellContent,
}
