import {
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor } from '@tiptap/core'

import { useAllFeatureFlags, useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'
import {
  BodySizeOptions,
  HeadingSizeOptions,
  TitleSizeOptions,
} from 'modules/tiptap_editor/extensions/Font/constants'
import {
  allowedFontSizes,
  FontSize,
  getSelectedFontSizes,
} from 'modules/tiptap_editor/extensions/Font/FontSize'
import { getFontSizeOption } from 'modules/tiptap_editor/extensions/Font/utils'
import { TextColorMenu } from 'modules/tiptap_editor/extensions/TextColor/TextColorMenu'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { canChangeSelectedNodeType } from 'modules/tiptap_editor/utils'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { ToolbarButton } from '../ToolbarButton'
import {
  checkCommandDisabled,
  FilterKeyForTextFormattingCommands,
  TEXT_FORMATTING_COMMANDS,
} from './TextFormattingCommands'

type TextFormattingMenuProps = {
  editor: Editor
  filterBy?: FilterKeyForTextFormattingCommands
}

export const TextFormattingMenu = ({
  editor,
  filterBy,
}: TextFormattingMenuProps) => {
  const allFlags = useAllFeatureFlags()

  return (
    <>
      <ButtonGroup
        isAttached
        key="text"
        onMouseDown={preventDefaultToAvoidBlur}
      >
        <TextDropdown editor={editor}></TextDropdown>
      </ButtonGroup>
      {TEXT_FORMATTING_COMMANDS.map((group, idx) => (
        <ButtonGroup
          spacing={0}
          size="sm"
          key={idx}
          onMouseDown={preventDefaultToAvoidBlur}
          _empty={{
            display: 'none',
          }}
        >
          {group
            .filter((n) => {
              if (filterBy) return n[filterBy]
              return n
            })
            .map((command) => {
              if (checkCommandDisabled(editor, command)) return
              const {
                key,
                name,
                shortcut,
                icon,
                mark,
                node,
                checkActive,
                apply,
                featureFlag,
              } = command
              if (featureFlag && !allFlags[featureFlag]) return
              const action = apply
                ? apply
                : mark
                ? (editorInstance) =>
                    editorInstance.chain().toggleMark(mark).focus().run()
                : () => {}
              const isActive = checkActive
                ? checkActive(editor)
                : mark
                ? editor.isActive(mark)
                : node
                ? editor.isActive(node)
                : undefined
              if (key === 'textColor') {
                return (
                  <TextColorMenu
                    key={key}
                    shortcut={shortcut}
                    icon={icon}
                    editor={editor}
                  />
                )
              } else {
                return (
                  <ToolbarButton
                    key={key}
                    label={name}
                    shortcut={shortcut}
                    isActive={isActive}
                    onClick={() => action(editor)}
                    icon={icon}
                    testId={key}
                  />
                )
              }
            })}
        </ButtonGroup>
      ))}
    </>
  )
}

type TextDropdownProps = {
  editor: Editor
}

const TextDropdown = ({ editor }: TextDropdownProps) => {
  const setFontSize = (value: FontSize) => {
    editor.chain().focus().setFontSize(value).run()
  }
  const theme = useAppSelector(selectTheme)

  const canChangeFontSize = useFeatureFlag('fontSize')

  const { $from } = editor.state.selection
  const allowHeading =
    canChangeSelectedNodeType(editor, 'heading') ||
    allowedFontSizes($from.parent).includes('heading')
  const allowTitle =
    canChangeSelectedNodeType(editor, 'title') ||
    allowedFontSizes($from.parent).includes('title')
  const allowBody =
    canChangeFontSize &&
    (canChangeSelectedNodeType(editor, 'paragraph') ||
      allowedFontSizes($from.parent).includes('body'))

  if (!allowBody && !allowHeading && !allowTitle) return null

  const currentSizes = getSelectedFontSizes(editor)
  const currentSize = editor.isActive('title')
    ? 'title'
    : editor.isActive('heading')
    ? `h${editor.getAttributes('heading').level}`
    : currentSizes.length === 1
    ? currentSizes[0] || 'default'
    : 'default'
  const currentOption = getFontSizeOption(currentSize)

  return (
    <Menu closeOnSelect={true} isLazy>
      <MenuButton
        size="sm"
        borderRadius="full"
        as={Button}
        rightIcon={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            transform="shrink-6"
          />
        }
        variant="toolbar"
        pl={2}
      >
        {currentOption.label}
      </MenuButton>
      <MenuList maxH="min(25em, 45vh)" overflowY="auto">
        {allowBody && (
          <MenuOptionGroup type="radio" value={currentSize} title="Body">
            {BodySizeOptions.map(([key, { label, size, flagged }]) => {
              if (flagged && !canChangeFontSize && key !== currentSize) return
              return (
                <MenuItemOption
                  value={key}
                  key={key}
                  onClick={() => setFontSize(key)}
                >
                  <Text fontSize={`${size}em`} fontFamily={theme.bodyFont}>
                    {label}
                  </Text>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        )}
        {allowHeading && (
          <MenuOptionGroup type="radio" value={currentSize} title="Heading">
            {HeadingSizeOptions.map(([key, { label, size, flagged }]) => {
              if (flagged && !canChangeFontSize && key !== currentSize) return
              return (
                <MenuItemOption
                  value={key}
                  key={key}
                  onClick={() => setFontSize(key)}
                >
                  <Text
                    fontWeight="bold"
                    fontSize={`${size}em`}
                    fontFamily={theme.headingFont}
                  >
                    {label}
                  </Text>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        )}
        {allowTitle && (
          <MenuOptionGroup type="radio" value={currentSize} title="Title">
            {TitleSizeOptions.map(([key, { label, size, flagged }]) => {
              if (flagged && !canChangeFontSize && key !== currentSize) return
              return (
                <MenuItemOption
                  value={key}
                  key={key}
                  onClick={() => setFontSize(key)}
                >
                  <Text
                    fontWeight="bold"
                    fontSize={`${size}em`}
                    fontFamily={theme.headingFont}
                  >
                    {label}
                  </Text>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  )
}
