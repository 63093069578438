import { device, os } from 'utils/deviceDetection'

type SegmentContext = {
  device?: {
    manufacturer?: string
    model?: string
    type?: string
  }
  os?: {
    name?: string
    version?: string
  }
  timezone: string
}
// https://segment.com/docs/connections/spec/common/#context
// Segment automatically collects the following for us:
// - campaign
// - library
// - ip --> IP Address isn’t collected by Segment’s libraries, but is instead
//          filled in by Segment’s servers when it receives a message for client
//          side events only.
// - locale
// - page
// - userAgent
// See https://segment.com/docs/connections/spec/common/#context-fields-automatically-collected
export const getSegmentContext = (): SegmentContext => {
  const _device = _getDeviceInfo()
  const _os = _getOsInfo()
  const obj: SegmentContext = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
  if (_device) {
    obj.device = _device
  }
  if (_os) {
    obj.os = _os
  }
  return obj
}

const _getDeviceInfo = () => {
  if (!device.model && !device.type && !device.vendor) {
    return undefined
  }
  return {
    model: device.model,
    type: device.type,
    manufacturer: device.vendor,
  }
}

const _getOsInfo = () => {
  if (!os.name && !os.version) {
    return undefined
  }
  return {
    name: os.name,
    version: os.version,
  }
}
