import { ButtonGroup, Kbd, HStack, Text } from '@chakra-ui/react'
import { useGammaTooltipHider } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import React, { useEffect } from 'react'

import { NodeInsertMethods } from 'modules/segment'

import { CommandInfo, trackItemInserted } from '../../../commands'
import { COMMANDS_LIST } from '../../../commands/commandsList'
import { FloatingMenu } from '../../../extensions/FloatingMenu/FloatingMenu'
import { ToolbarButton } from '../ToolbarButton'

type SlashMenuPromptProps = {
  editor: Editor
}

const PROMPT_SHORTCUTS = [
  'image.custom',
  'table2',
  'columns',
  'insertCardInside',
  'video.custom',
  'embed.custom',
  'diagram',
]

const promptCommands = PROMPT_SHORTCUTS.map((key) =>
  COMMANDS_LIST.find((cmd) => cmd.key === key)
).filter(Boolean) as CommandInfo[]

// The placeholder that appears on a new line prompting you to add blocks
export const SlashMenuPrompt = ({ editor }: SlashMenuPromptProps) => {
  const { GammaTooltipHiderContext, hideTooltips } = useGammaTooltipHider()
  return (
    <FloatingMenu
      editor={editor}
      hideTooltips={hideTooltips}
      tippyOptions={{
        offset: [0, 0],
        // zindex doesn't need to be super high here since it's going over empty block content,
        // but it needs to be high enough to go over nested cards
        zIndex: 1,
      }}
    >
      <GammaTooltipHiderContext>
        <HStack spacing={4} color="gray.400" data-print-hidden>
          <Text whiteSpace="nowrap" mr={6}>
            Type <Kbd>/</Kbd> for blocks
          </Text>
          <ButtonGroup
            isAttached
            variant="ghost"
            size="xs"
            spacing={1}
            pointerEvents="auto"
          >
            {promptCommands.map((command) => {
              const { name, shortcut, icon, execute } = command
              return (
                <ToolbarButton
                  key={name}
                  label={name}
                  shortcut={shortcut}
                  onClick={() => {
                    trackItemInserted(command, NodeInsertMethods.BLOCK_PROMPT)
                    execute(editor)
                  }}
                  icon={icon}
                />
              )
            })}
          </ButtonGroup>
        </HStack>
      </GammaTooltipHiderContext>
    </FloatingMenu>
  )
}
