import {
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/core'
import { useCallback, useEffect } from 'react'

import { LayoutFormattingMenu } from 'modules/tiptap_editor/components/menus/FormattingMenus/LayoutFormattingMenu'
import { isMobileDevice } from 'utils/deviceDetection'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { useAppSelector } from '../../../redux'
import { NodeViewContent } from '../../react'
import { selectContentEditable } from '../../reducer'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { isSelectingNodeOrInside } from '../FocusedNodes'
import { createColumnWidths } from '../tables/prosemirror-table/columnUtils'
import { MAX_COLUMNS } from './constants'
import { getLayoutChildren } from './utils'

const useFixLayoutColumns = ({
  getPos,
  updateAttributes,
  editor,
  node,
}: NodeViewProps) => {
  const $layout = editor.state.doc.resolve(getPos())

  const children = getLayoutChildren($layout)
  useEffect(() => {
    if (children.length !== node.attrs.colWidths.length) {
      // has to be in a set timeout to prevent an infinite dispatch loop
      // with updateAttributes
      setTimeout(() => {
        updateAttributes({
          colWidths: createColumnWidths(children.length),
        })
      }, 0)
    }
  }, [node, children, updateAttributes])
}

export const LayoutView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, getPos, decorations } = nodeViewProps
  const numCells = node.content.childCount
  const isEditable = useAppSelector(selectContentEditable)
  const showMenu = isSelectingNodeOrInside(decorations)

  useFixLayoutColumns(nodeViewProps)
  // Wrap on small screen sizes
  const isEven = numCells % 2 == 0
  const maxColumns =
    useBreakpointValue({
      base: 1,
      sm: isEven ? 2 : 1,
      md: isEven ? 2 : 3,
      lg: 4,
    }) || 4
  const columns = Math.min(maxColumns, numCells)

  const addColumn = useCallback(() => {
    editor.commands.addLayoutCell(getPos())
  }, [editor, getPos])

  const canAddColumn = numCells < MAX_COLUMNS && isEditable
  const templateColumns = isMobileDevice
    ? '100%'
    : (node.attrs.colWidths || []).map((w) => `${w}%`).join(' ')

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <SimpleGrid
        className="grid-col-controls"
        columns={columns}
        gap="var(--grid-border)"
        position="relative"
        gridTemplateColumns={templateColumns}
      >
        <NodeViewContent
          className="layout-content"
          data-editable={isEditable}
        ></NodeViewContent>
        <Flex
          position="absolute"
          top="0"
          h="100%"
          align="center"
          w={6}
          right={-3}
          className="add-column"
          opacity="0"
          transitionProperty="opacity"
          transitionDuration="normal"
        >
          {canAddColumn && (
            <GammaTooltip label="Add column" placement="top">
              <IconButton
                icon={<FontAwesomeIcon icon={regular('plus')} />}
                aria-label="Add column"
                position="absolute"
                size="xs"
                variant="plain"
                isRound
                onClick={addColumn}
              />
            </GammaTooltip>
          )}
        </Flex>
      </SimpleGrid>
      {showMenu && (
        <Flex
          w="100%"
          align="center"
          direction="column"
          color="black"
          contentEditable={false}
        >
          <HStack
            bg="white"
            shadow="lg"
            borderRadius="full"
            border="1px solid"
            borderColor="gray.200"
            position="absolute"
            zIndex="docked" // Go over neighboring nodes and card + button
            bottom="100%"
            mb={4} // Clear the inner formatting menu
            py={1}
            px={2}
            contentEditable={false}
            userSelect="none"
            onMouseDown={preventDefaultToAvoidBlur}
          >
            <LayoutFormattingMenu editor={editor} />
          </HStack>
        </Flex>
      )}
    </AnnotatableNodeViewWrapper>
  )
}
