import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

type CalloutBoxDecorationSpec = {
  isCalloutBoxDecoration: true
  firstChildType: string
}

type CalloutBoxDecoration = Decoration & { spec: CalloutBoxDecorationSpec }

export const CalloutBoxPlugin = () =>
  new Plugin({
    props: {
      decorations(state) {
        const decos: Decoration[] = []
        state.doc.descendants((node, pos) => {
          if (node.type.name !== 'calloutBox' || !node.firstChild) {
            return
          }
          const firstChildType =
            node.firstChild.type.name === 'heading'
              ? `h${node.firstChild.attrs.level}`
              : node.firstChild.type.name

          const spec: CalloutBoxDecorationSpec = {
            isCalloutBoxDecoration: true,
            firstChildType,
          }
          decos.push(Decoration.node(pos, pos + node.nodeSize, {}, spec))
        })
        if (decos.length == 0) {
          return
        }
        return DecorationSet.create(state.doc, decos)
      },
    },
  })

export const findCalloutBoxDeco = (
  decos: Decoration[]
): CalloutBoxDecorationSpec =>
  decos.find((d): d is CalloutBoxDecoration => d.spec.isCalloutBoxDecoration)
    ?.spec || {
    isCalloutBoxDecoration: true,
    firstChildType: 'paragraph',
  }
