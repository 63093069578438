import { ySyncPluginKey } from '@gamma-app/y-prosemirror'
import { Node } from 'prosemirror-model'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'
import { DecorationSet, Decoration } from 'prosemirror-view'

import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'

import { isNodeContentSpotlightable } from './Spotlight'

// This key lets us read the plugin's state from the editor instance
// The PluginKey's getState method picks the plugin's config out of
// the overall editor state
export const SpotlightPluginKey = new PluginKey<SpotlightPluginState>(
  'spotlight'
)

export interface SpotlightMeta {
  pos: number | null
  cardId: string | null
}

export interface SpotlightPluginState {
  pos: number | null
  cardId: string | null
  decorations: DecorationSet
}

const createDecorations = (
  state: EditorState,
  spotlight: SpotlightMeta,
  isSpotlightable: (node: Node, cardId?: string | null) => boolean
) => {
  if (spotlight.pos === null) {
    return DecorationSet.create(state.doc, [])
  }

  const decorations: Decoration[] = []

  const spotlightNode = state.doc.nodeAt(spotlight.pos)

  // Card-by-card navigation
  if (
    spotlightNode &&
    isCardNode(spotlightNode) &&
    !isSpotlightable(spotlightNode, spotlight.cardId) // Prevents collapsed cards from getting the presenting-card tag
  ) {
    decorations.push(
      Decoration.node(spotlight.pos, spotlight.pos + spotlightNode.nodeSize, {
        class: 'presenting-card',
      })
    )

    // Spotlighting a node within a card
  } else if (
    spotlightNode &&
    isSpotlightable(spotlightNode, spotlight.cardId)
  ) {
    decorations.push(
      Decoration.node(spotlight.pos, spotlight.pos + spotlightNode.nodeSize, {
        class: 'spotlight-block',
      })
    )
  }

  state.doc.descendants((node, pos) => {
    if (isSpotlightable(node, spotlight.cardId)) {
      decorations.push(
        Decoration.node(pos, pos + node.nodeSize, {
          class: 'spotlightable',
        })
      )
    }
    // Stop descending if this node's content isn't spotlightable (eg columns)
    // Make an exception for cards, since we start at the top level of the doc
    return isNodeContentSpotlightable(node) || isCardNode(node)
  })

  return DecorationSet.create(state.doc, decorations)
}

export const SpotlightPlugin = (
  isSpotlightable: (node: Node, cardId?: string | null) => boolean
) =>
  new Plugin<SpotlightPluginState>({
    key: SpotlightPluginKey,

    state: {
      // Store the current spotlight position in this plugin's state
      init: (_, state) => {
        const initialSpotlight = {
          pos: null,
          cardId: '',
        }
        return {
          ...initialSpotlight,
          decorations: createDecorations(
            state,
            initialSpotlight,
            isSpotlightable
          ),
        }
      },

      apply(tr, prevSpotlight, oldState, newState): SpotlightPluginState {
        const ySyncPluginState = ySyncPluginKey.getState(newState)
        const newSpotlight = tr.getMeta(SpotlightPluginKey)
        const isExternalChange =
          ySyncPluginState && ySyncPluginState.isChangeOrigin

        // We need to re-create the decorations if:
        // there is a new spotlight provided in the transaction
        // OR
        // the doc was changed by someone else (isExternalChange)
        if (newSpotlight || isExternalChange) {
          const spotlightToUse = newSpotlight || prevSpotlight
          return {
            ...spotlightToUse,
            decorations: createDecorations(
              newState,
              spotlightToUse,
              isSpotlightable
            ),
          }
        }

        // Otherwise, remap the existing decorations.
        // Inspired by y-prosemirror cursor-plugin logic
        // See https://github.com/yjs/y-prosemirror/blob/1c393fb3254cc1ed4933e8326b57c1316793122a/src/plugins/cursor-plugin.js#L87
        const decorations = prevSpotlight.decorations.map(tr.mapping, tr.doc)
        return {
          ...prevSpotlight,
          decorations,
        }
      },
    },
    props: {
      // Add a class to the editor DOM node when spotlight is active to dim the rest
      attributes: (state) => {
        const spotlight = SpotlightPluginKey.getState(state)

        if (!spotlight || spotlight.pos === null) return { class: '' }

        const spotlightNode = state.doc.nodeAt(spotlight.pos)
        if (spotlightNode && isSpotlightable(spotlightNode, spotlight.cardId)) {
          return {
            class: 'spotlight-active',
          }
        }
        return { class: '' }
      },

      // Add a class to the spotlighted node
      decorations: (state) => {
        const pluginState = SpotlightPluginKey.getState(state)
        return pluginState ? pluginState.decorations : null
      },
    },
  })
