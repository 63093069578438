import { debounce } from 'lodash'
import { Plugin } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { AnnotationItem } from './AnnotationItem'
import { AnnotationPluginKey } from './AnnotationPluginKey'
import { AnnotationState } from './AnnotationState'
import { AnnotationPluginParams } from './types'

export const createAnnotationPlugin = (options: AnnotationPluginParams) => {
  // pass `view` as a referenceable object so that the current `annotationState.map`
  // matches the `view.state` so that the relative positions line up
  const debouncedPersistRestoreMap = debounce((view: EditorView) => {
    const annotationState = AnnotationPluginKey.getState(
      view.state
    ) as AnnotationState
    annotationState.persistRestoreMap(view.state)
  }, 500)
  return new Plugin({
    key: AnnotationPluginKey,

    state: {
      init() {
        return new AnnotationState(options)
      },

      apply(transaction, pluginState, oldEditorState, newEditorState) {
        return pluginState.apply(transaction, newEditorState)
      },
    },
    view() {
      return {
        update(view) {
          debouncedPersistRestoreMap(view)
        },
      }
    },

    props: {
      /**
       * Intercept transformPasted where we have the pre transformed Slice
       * from `clipboardData.getData('text/html').  This should have the docId
       * serialized in the paste buffer from prosemirror.  Compare that docId
       * to figure out if content was copied from this document or another sourcedocId
       *
       * If copied from another document dont try to paste annotation positions
       * store in annotationState.cutData
       */
      transformPasted(slice) {
        const annotationState = this.getState(
          options.editor.state
        ) as AnnotationState

        const pastedDocId = slice.content.firstChild?.attrs.docId

        if (!pastedDocId || pastedDocId !== options.editor.gammaDocId) {
          annotationState.clearCutData()
        }

        return slice
      },
      handleDOMEvents: {
        copy(view): boolean {
          ;(this.getState(view.state) as AnnotationState).clearCutData()

          return false
        },
      },
      decorations(state) {
        const { decorations, annotations } = this.getState(
          state
        ) as AnnotationState

        if (options.onUpdate) {
          options.onUpdate(
            decorations.find().map((d) => new AnnotationItem(d)),
            annotations
          )
        }

        return decorations
      },
    },
  })
}
