import { Heading, VStack } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useCallback, useRef } from 'react'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import {
  getTempPDFAttrs,
  handlePDFUploadSuccess,
} from 'modules/tiptap_editor/extensions/media/Upload'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { useAppSelector } from '../../redux'
import { selectDocOrgId } from '../../tiptap_editor/reducer'
import { OnUploadStartParams, UploadBox } from '../components/UploadBox'
import { URLFetcher } from '../components/URLFetcher'
import { ImageUploadResult } from '../types/ImageUpload'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import PDFIcon from './icons/PDF_icon.svg'

const PDFPanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const orgId = useAppSelector(selectDocOrgId)
  const url = getMediaSourceUrl(currentAttributes)
  const uploadingTempUrl = useRef<string | null>(null)

  const onUploadStart = useCallback(
    ({ tempUrl, file }: OnUploadStartParams) => {
      const attrs = getTempPDFAttrs(file)
      uploadingTempUrl.current = tempUrl
      updateAttributes({
        ...attrs,
        url: tempUrl,
      })
    },
    [updateAttributes]
  )

  const onUploadSuccess = useCallback(
    (result: ImageUploadResult, prevUrl: string) => {
      if (!editor) return
      const tempUrl = uploadingTempUrl.current
      const existingUrl = tempUrl || prevUrl
      handlePDFUploadSuccess(editor, existingUrl, result)
      uploadingTempUrl.current = null
    },
    [editor]
  )

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder="Enter a URL here"
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
      <Heading size="md">Upload</Heading>
      {orgId && (
        <UploadBox
          onUploadSuccess={onUploadSuccess}
          onUploadStart={onUploadStart}
          orgId={orgId}
          uploadType="doc"
          showTip={false}
          afterLinkDropHereText="a .pdf, .docx, or .pptx file"
          editType="node"
        />
      )}
    </VStack>
  )
}

export const PDFProvider: MediaProviderType = {
  label: 'PDF upload',
  key: 'embed.pdf',
  description: 'Attach PDFs, Word documents, and PowerPoint presentations',
  icon: duotone('file-pdf'),
  Panel: PDFPanel,
  // Match any URL that ends with .pdf
  urlRegex: /\.pdf$/,
  image: PDFIcon,
  featureFlag: 'uploadPDF',
  iframeOptions: {
    noSandbox: true,
  },
  downloadable: true,
}
