import { mergeAttributes } from '@tiptap/core'
import TiptapParagraph from '@tiptap/extension-paragraph'
import { NodeViewProps } from '@tiptap/react'

import { NodeViewContent, ReactNodeViewRenderer } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { attrsOrDecorationsChanged } from '../updateFns'

export const ParagraphView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps
  const { horizontalAlign, fontSize } = node.attrs
  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <NodeViewContent
        as="p"
        data-font-size={fontSize}
        style={{ textAlign: horizontalAlign }}
      ></NodeViewContent>
    </AnnotatableNodeViewWrapper>
  )
}

export const Paragraph = TiptapParagraph.extend({
  name: 'paragraph',
  selectable: false,

  addNodeView() {
    return ReactNodeViewRenderer(ParagraphView, {
      update: attrsOrDecorationsChanged,
    })
  },

  allowFontSizes: 'body',
  addAttributes() {
    return {
      fontSize: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'p',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(HTMLAttributes, { class: 'paragraph' }), 0]
  },
})
