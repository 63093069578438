import { BoxProps } from '@chakra-ui/layout'
import { CSSObject } from '@emotion/react'
import { gammaTheme } from '@gamma-app/ui'
import { merge } from 'lodash'

import { isThemeDark, Theme } from 'modules/theming'
import { colorWithOpacity, lightenColor } from 'utils/color'

export type ContainerWidth = 'sm' | 'md' | 'lg' | 'full'

export type ContainerOptions = {
  isDark: boolean
  effect: ContainerEffect
  css?: BoxProps // Theme overrides of built-in container effects
  width?: ContainerWidth
}

export enum ContainerEffect {
  SOLID = 'solid',
  FROSTED = 'frosted',
  FADED = 'faded',
  CLEAR = 'clear',
  COLLAPSED = 'collapsed',
}

export const DEFAULT_CONTAINER = {
  isDark: false,
  effect: ContainerEffect.FROSTED,
}

const DEFAULT_SHADOW = 'var(--chakra-shadows-md)'
export const DEFAULT_LIGHT_CONTAINER_BACKGROUND = 'white'
export const DEFAULT_DARK_CONTAINER_BACKGROUND = 'rgba(12, 12, 12)'

export const getContainerStyles = (
  container: ContainerOptions,
  theme: Theme
): CSSObject => {
  if (!container) return {}

  const containerStyleOverrides = getContainerStyleOverrides(theme, container)
  const containerBackgroundColor = getContainerBackgroundColor(
    theme,
    containerStyleOverrides,
    container
  )
  const { isDark } = getContainerOptions(theme, container)

  switch (container.effect) {
    case ContainerEffect.COLLAPSED:
      return {
        border: '1px solid',
        borderColor: colorWithOpacity(gammaTheme.colors.gray[400], 0.25),
        ...containerStyleOverrides,
        // Always have a border on these, even if theme CSS would disable it when expanded
        borderWidth: '1px',
        boxShadow: 'var(--chakra-shadows-base)',
        backgroundColor: colorWithOpacity(
          isDark
            ? lightenColor(containerBackgroundColor, 30)
            : containerBackgroundColor,
          0.6
        ),
        '@media print': {
          boxShadow: 'none',
        },
      }
    case ContainerEffect.SOLID:
      return {
        boxShadow: DEFAULT_SHADOW,
        border: '1px solid',
        borderColor: container.isDark ? 'gray.700' : 'gray.200',
        ...containerStyleOverrides,
        backgroundColor: containerBackgroundColor,
        '@media print': {
          boxShadow: 'none',
        },
      }
    case ContainerEffect.FADED:
      return {
        boxShadow: DEFAULT_SHADOW,
        border: '2px solid',
        borderColor: container.isDark ? 'whiteAlpha.300' : 'whiteAlpha.500',
        ...containerStyleOverrides,
        backgroundColor: colorWithOpacity(containerBackgroundColor, 0.75),
        '@media print': {
          boxShadow: 'none',
        },
      }
    case ContainerEffect.FROSTED:
      return {
        backdropFilter: `blur(20px) saturate(170%)`,
        boxShadow: DEFAULT_SHADOW,
        border: '2px solid',
        borderColor: container.isDark ? 'whiteAlpha.300' : 'whiteAlpha.500',
        ...containerStyleOverrides,
        backgroundColor: colorWithOpacity(containerBackgroundColor, 0.75),
        '@media print': {
          boxShadow: 'none',
          backgroundColor: colorWithOpacity(containerBackgroundColor, 0.95),
        },
      }
    case ContainerEffect.CLEAR:
      return {
        boxShadow: undefined,
        borderRadius: containerStyleOverrides.borderRadius,
      }
  }

  return {}
}

// Reads the default container options from the theme,
// and considers any overrides from the card-level settings
// (e.g. dark/light, frosted/faded)
export const getContainerOptions = (
  theme: Theme,
  containerOptions?: Partial<ContainerOptions>
): ContainerOptions => {
  return merge({}, DEFAULT_CONTAINER, theme.config.container, containerOptions)
}

export const getContainerStyleOverrides = (
  theme: Theme,
  containerOptions?: ContainerOptions
) => {
  const { config } = theme
  const container = getContainerOptions(theme, containerOptions)
  const containerStyles = config.containerStyles || {}
  if (container.isDark !== isThemeDark(theme)) {
    // Fall back to the default background if the card overrides the theme's light/dark setting
    return {
      ...containerStyles,
      backgroundColor: undefined,
    }
  }
  return containerStyles
}

export const getContainerBackgroundColor = (
  theme: Theme,
  containerStyleOverrides?: BoxProps,
  containerOptions?: ContainerOptions
) => {
  const container = getContainerOptions(theme, containerOptions)
  const styleOverrides =
    containerStyleOverrides ??
    getContainerStyleOverrides(theme, containerOptions)

  return typeof styleOverrides?.backgroundColor == 'string'
    ? styleOverrides.backgroundColor
    : container.isDark
    ? DEFAULT_DARK_CONTAINER_BACKGROUND
    : DEFAULT_LIGHT_CONTAINER_BACKGROUND
}
