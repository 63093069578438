import { gammaTheme } from '@gamma-app/ui'

export const DEFAULT_ACCENT_COLOR = gammaTheme.colors.trueblue[500] as string
export const DEFAULT_HEADING_COLOR = '#000000' // black
export const DEFAULT_HEADING_COLOR_DARK = '#FFFFFF' // white
export const DEFAULT_BODY_COLOR = '#272525' // dark gray
export const DEFAULT_BODY_COLOR_DARK = gammaTheme.colors.gray[200] as string

export const DEFAULT_FONTS = {
  bodyFont: 'Inter',
  headingFont: 'Inter',
  fontSize: 1,
}

export const DARK_TEXT_LIGHTNESS = 0.4
export const LIGHT_TEXT_LIGHTNESS = 0.8
