import { SmartLayoutAttrs } from './types'
import { getSmartLayoutVariant } from './variants'

export const getSmartLayoutOptions = (attrs: SmartLayoutAttrs) => {
  const variant = getSmartLayoutVariant(attrs.variantKey)
  const options: Record<string, any> = {}
  variant.options.forEach(({ key, defaultValue }) => {
    options[key] = attrs.options[key] ?? defaultValue
  })
  return options
}
