// Adapted from https://github.com/ueberdosis/tiptap/tree/main/packages/extension-floating-menu/src

import { Editor } from '@tiptap/core'
import React, { PropsWithChildren, useEffect, useRef } from 'react'

import { usePanelLifecycle } from 'modules/panels/PanelLifecycle'

import {
  FloatingMenuPluginKey,
  FloatingMenuPluginState,
} from './floating-menu-plugin'

export type FloatingMenuProps = Omit<
  FloatingMenuPluginState,
  'element' | 'panelLifecycle'
> & {
  editor: Editor
  className?: string
  hideTooltips: () => void
}

export const FloatingMenu: React.FC<PropsWithChildren<FloatingMenuProps>> = (
  props
) => {
  const element = useRef<HTMLDivElement>(null)
  const panelLifecycle = usePanelLifecycle()

  const { editor, hideTooltips, tippyOptions } = props
  useEffect(() => {
    if (!editor) return

    editor.commands.command(({ tr }) => {
      tr.setMeta(FloatingMenuPluginKey, {
        element: element.current as HTMLElement,
        tippyOptions,
        panelLifecycle,
        hideTooltips,
      })
      return true
    })
  }, [editor, tippyOptions, panelLifecycle, hideTooltips])

  return (
    <div
      ref={element}
      className={props.className}
      style={{ visibility: 'hidden' }}
    >
      {props.children}
    </div>
  )
}
