import { Divider } from '@chakra-ui/react'

import {
  AccessLinkSignUp,
  AccessLinkSignUpProps,
} from 'modules/auth/accesslink_signup'

export const CommentSignUp = ({
  accessLink,
  docId,
  className,
  showDivider = true,
}: AccessLinkSignUpProps) => {
  return (
    <>
      <AccessLinkSignUp
        accessLink={accessLink}
        docId={docId}
        p={6}
        spacing={4}
        className={className}
      />
      {showDivider && <Divider />}
    </>
  )
}
