import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { CommandInfo } from 'modules/tiptap_editor/commands'

import { CalloutBoxOption, CalloutBoxVariant } from './types'
export const CalloutBoxOptions: Record<CalloutBoxVariant, CalloutBoxOption> = {
  note: {
    name: 'Note',
    description: 'Draw attention using your theme accent color',
    icon: regular('sticky-note'),
    colorScheme: 'accent',
  },
  info: {
    name: 'Info',
    description: 'Highlight information in a blue box',
    icon: regular('info-circle'),
    colorScheme: 'blue',
  },
  warning: {
    name: 'Warning',
    icon: regular('triangle-exclamation'),
    description: 'Call out risks in a yellow box',
    colorScheme: 'yellow',
  },
  caution: {
    name: 'Caution',
    description: 'Emphasize problems in a red box',
    icon: regular('xmark-circle'),
    colorScheme: 'red',
  },
  success: {
    name: 'Success',
    description: 'Add positive news in a green box',
    icon: regular('check-circle'),
    colorScheme: 'green',
  },
}

export const getCalloutBoxOption = (
  option: CalloutBoxVariant
): CalloutBoxOption => {
  return CalloutBoxOptions[option] || CalloutBoxOptions.note
}

export const CalloutBoxCommands = Object.entries(CalloutBoxOptions).map(
  ([variant, { name, icon }]) => {
    const command: CommandInfo = {
      key: `calloutBox-${variant}`,
      name: `${name} box`,
      nodeName: 'calloutBox',
      icon,
      shortcut: `/${variant}`,
      featureFlag: 'calloutBox',
      keywords: ['callout', 'box'],
      execute: (editor) =>
        editor.chain().focus().wrapIn('calloutBox', { variant }).run(),
    }
    return command
  }
)

export const CalloutBoxEmptyNodes = Object.fromEntries(
  Object.keys(CalloutBoxOptions).map((variant) => [
    `calloutBox-${variant}`,
    {
      type: 'calloutBox',
      attrs: {
        variant,
      },
      content: [
        {
          type: 'paragraph',
        },
      ],
    },
  ])
)
