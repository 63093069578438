import { Avatar, Flex, Menu, MenuButton } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import React from 'react'

import { useSSRMounted } from 'utils/hooks'

import { FeatureFlagPanel } from './FeatureFlagPanel'

export const FeatureFlagDevtoolBall: React.FC = () => {
  if (!useSSRMounted()) return null // SSR Hydration

  return (
    <Flex zIndex="overlay" position="fixed" bottom="16px" left="16px">
      <Menu placement="top">
        <GammaTooltip label={'Feature Flags'}>
          <MenuButton
            tabIndex={0}
            _focus={{
              shadow: 'outline',
            }}
            as={Avatar}
            width="40px"
            height="40px"
            src="https://res.cloudinary.com/bizzaboprod/image/upload/q_auto:best,c_crop,g_custom/v1560896151/yecilv8jlrtehq3zmea2.png"
            shadow="base"
            outline={'none'}
            alignItems="center"
            flexDirection="column"
            cursor="pointer"
            transition="box-shadow 150ms ease-out"
            opacity={0}
            _hover={{
              shadow: '0 0 0 3px #CBD5E0',
              opacity: 1,
            }}
          />
        </GammaTooltip>
        <FeatureFlagPanel />
      </Menu>
    </Flex>
  )
}
