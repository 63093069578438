import type { TldrawApp } from '@gamma-app/tldraw'
import { NodeViewProps } from '@tiptap/react'
import { nanoid } from 'nanoid'

import { getStore } from 'modules/redux'
import { shapeUtils, getCommonBounds, zoomPageToFit } from 'modules/tldraw'

import { setExpandedDrawingId } from '../../reducer'

import { DrawingAttrs } from '.'

// This would ideally be 0, but the bounds calc isn't quite right when you rotate
const ZOOM_PADDING_VIEWER = 10
const ZOOM_PADDING_EDITOR = 128

export const setContent = (
  app: TldrawApp | undefined,
  page: DrawingAttrs['page'],
  assets: DrawingAttrs['assets']
) => {
  if (!app || !page) return
  app.replacePageContent(page.shapes, page.bindings, assets)
}

export const recalculateViewerBounds = (
  app: TldrawApp,
  updateAttributes: NodeViewProps['updateAttributes'],
  resetBoundsCache?: boolean
) => {
  const shapes = Object.values(app.shapes)
  if (resetBoundsCache) {
    // This is necessary when changing theme because the shape data
    // is unchanged but the fonts inside have actually changed.
    shapes.forEach((shape) => {
      shapeUtils[shape.type].boundsCache.delete(shape)
    })
  }
  const bounds = getCommonBounds(shapes)
  if (bounds && shapes.length > 0) {
    updateAttributes({
      meta: {
        width: bounds.width,
        height: bounds.height,
      },
    })
  } else {
    updateAttributes({
      meta: {
        width: null,
        height: null,
      },
    })
  }
  console.warn('[DrawingView] Recalculating bounds', bounds, shapes.length)
  if (shapes.length == 0) return
  setTimeout(() => {
    zoomPageToFit(app, ZOOM_PADDING_VIEWER)
  })
}

export const initializeApp = (app: TldrawApp, editing: boolean) => {
  // app.setSetting('showGrid', editing)
  app.setSetting('isSnapping', editing)
  setTimeout(() => {
    zoomPageToFit(app, editing ? ZOOM_PADDING_EDITOR : ZOOM_PADDING_VIEWER)
  })
}

export const setDrawingExpanded = (
  drawingId: string | null,
  enabled: boolean
) => {
  const store = getStore()
  const payload = enabled ? { drawingId } : { drawingId: null }
  store.dispatch(setExpandedDrawingId(payload))
}

export const generateDrawingId = () => nanoid(5)
