import {
  absolutePositionToRelativePosition,
  relativePositionToAbsolutePosition,
  ySyncPluginKey,
} from '@gamma-app/y-prosemirror'
import { EditorState, Transaction, PluginKey } from 'prosemirror-state'
import * as Y from 'yjs'

export const AnnotatableActivatorPluginKey =
  new PluginKey<AnnotatableActivatorState>('annotatableActivatorKey')

type ActivatorPos = {
  pos: number
  end: number
}
export type SetAnnotatableActivatorPosEvent = {
  setPos: ActivatorPos | null
}

type AnnotatableActivatorEvent = SetAnnotatableActivatorPosEvent

export class AnnotatableActivatorState {
  public pos: Y.RelativePosition | null = null
  public end: Y.RelativePosition | null = null

  constructor() {}

  getAbsPos(state: EditorState): { pos: number; end: number } | null {
    const ystate = ySyncPluginKey.getState(state)
    if (!ystate) {
      return null
    }
    const { doc, type, binding } = ystate
    if (!ystate.binding) {
      return null
    }
    if (this.pos === null || this.end === null) {
      return null
    }
    const pos = relativePositionToAbsolutePosition(
      doc,
      type,
      this.pos,
      binding.mapping
    )
    const end = relativePositionToAbsolutePosition(
      doc,
      type,
      this.end,
      binding.mapping
    )
    if (pos === null || end === null) {
      return null
    }

    return {
      pos,
      end,
    }
  }

  handleSetPos(
    state: EditorState,
    event: SetAnnotatableActivatorPosEvent
  ): this {
    if (event.setPos === null) {
      this.pos = null
      this.end = null
      return this
    }
    const ystate = ySyncPluginKey.getState(state)
    if (!ystate) {
      return this
    }
    const { type, binding } = ystate
    this.pos = absolutePositionToRelativePosition(
      event.setPos.pos,
      type,
      binding.mapping
    )
    this.end = absolutePositionToRelativePosition(
      event.setPos.end,
      type,
      binding.mapping
    )
    return this
  }

  apply(tr: Transaction, state: EditorState): this {
    const action = tr.getMeta(
      AnnotatableActivatorPluginKey
    ) as AnnotatableActivatorEvent | null

    if (action && 'setPos' in action) {
      this.handleSetPos(state, action)
    }

    return this
  }
}
