import getConfig from 'next/config'

const IS_CLIENT_SIDE = typeof window !== 'undefined'

type Config = {
  AMAZON_ACCESS_KEY_ID: string
  AMAZON_REGION: string
  AMAZON_SECRET_ACCESS_KEY: string
  API_HOST: string
  APPLICATION_ENVIRONMENT: 'dev' | 'staging' | 'production'
  ASSETS_CDN_HOST: string
  CONTENTFUL_API_TOKEN: string
  CONTENTFUL_GRAPHQL_ENDPOINT: string
  DEBUG_ENABLED: boolean
  EDITOR_ANIMATION_DEBUG_FACTOR?: number
  EMBED_PROXY_URL: string
  EVENT_TRACKING_ENDPOINT: string
  FRONTEND_URL: string
  GAMMA_E2E_USER: boolean
  GIT_SHA: string
  GOOGLE_DRIVE_IMPORT_CLIENT_ID: string
  GOOGLE_PROJECT_ID: string
  GOOGLE_API_KEY: string
  HIGHLIGHT_PROJECT_ID: string
  HONEYCOMB_API_KEY: string
  HONEYCOMB_DATASET: string
  INTERCOM_APP_ID: string
  IS_CLIENT_SIDE: boolean
  KNOCK_FEED_ID: string
  KNOCK_PUBLIC_KEY: string
  LAUNCH_DARKLY_CLIENT_SIDE_ID: string
  MULTIPLAYER_WS_URL: string
  OG_IMAGE_URL: string
  SLACK_CLIENT_ID: string
  SHARE_TOKEN: string
  TRANSLOADIT_AUTH_KEY: string
  TRANSLOADIT_TEMPLATE_ID: string
  VISITOR_ID_COOKIE_DOMAIN: string
  GAMMA_PUPPETEER_SERVICE: boolean
}

const EDITOR_ANIMATION_DEBUG_FACTOR_QUERY = new URLSearchParams(
  IS_CLIENT_SIDE ? window.location.search : ''
).get('EDITOR_ANIMATION_DEBUG_FACTOR')

const EDITOR_ANIMATION_DEBUG_FACTOR =
  IS_CLIENT_SIDE && window['Cypress']
    ? 0 // Disable animations in Cypress tests
    : EDITOR_ANIMATION_DEBUG_FACTOR_QUERY !== null
    ? Number(EDITOR_ANIMATION_DEBUG_FACTOR_QUERY)
    : null

const DEBUG_ENABLED =
  IS_CLIENT_SIDE && document.cookie.includes('gammadebug=true')

const GAMMA_E2E_USER =
  IS_CLIENT_SIDE && document.cookie.includes('gamma_e2e_user=true')

const SHARE_TOKEN =
  (IS_CLIENT_SIDE &&
    new URLSearchParams(window.location.search).get('token')) ||
  ''

const GAMMA_PUPPETEER_SERVICE = IS_CLIENT_SIDE && window.gammaPuppeteerService

export const config: Config = {
  ...getConfig()?.publicRuntimeConfig,
  DEBUG_ENABLED,
  EDITOR_ANIMATION_DEBUG_FACTOR,
  GAMMA_E2E_USER,
  IS_CLIENT_SIDE,
  OG_IMAGE_URL:
    'https://cdn.gamma.app/_app_static/images/og_image_8_10_22_85e43e81bac01f83.png',
  SHARE_TOKEN,
  GAMMA_PUPPETEER_SERVICE,
}

export function setConfigValue<T extends keyof Config>(
  key: T,
  value: Config[T]
) {
  config[key] = value
}

if (IS_CLIENT_SIDE) {
  window['gammaDebug'] = (val: boolean = true) => {
    // delete all other cookies
    document.cookie = `gammadebug=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
    document.cookie = `gammadebug=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/docs`
    const days = 90
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    document.cookie = `gammadebug=${
      val ? 'true' : 'false'
    }; expires=${date.toUTCString()}; path=/`

    if (val !== DEBUG_ENABLED) {
      window.location.reload()
    }
  }
}
