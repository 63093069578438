import { Flex, IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { NodeViewContent } from 'modules/tiptap_editor/react'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { isFocusedAndEditable } from '../FocusedNodes'
import { findSmartLayoutCellDecoration } from './decoration'
import { SmartLayoutCellAttrs } from './types'
import { getSmartLayoutOptions } from './utils'
import { getSmartLayoutVariant } from './variants'

export const SmartLayoutCellView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations, editor, getPos } = nodeViewProps
  const { options } = node.attrs as SmartLayoutCellAttrs
  // Decoration has layout-level info like attrs and # of cells
  const deco = findSmartLayoutCellDecoration(decorations)
  const variant = getSmartLayoutVariant(deco.smartLayoutAttrs.variantKey)
  const { Cell } = variant
  const isEditableAndFocused = isFocusedAndEditable(decorations)
  const addCell = useCallback(() => {
    editor.commands.insertSmartLayoutCell(getPos())
  }, [editor, getPos])
  const theme = useAppSelector(selectTheme)
  const smartLayoutOptions = getSmartLayoutOptions(deco.smartLayoutAttrs)

  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      style={{ position: 'relative' }}
    >
      <Cell
        {...deco}
        cellOptions={options}
        layoutOptions={smartLayoutOptions}
        theme={theme}
      >
        <NodeViewContent />
        {isEditableAndFocused && (
          <Flex
            position="absolute"
            bottom="0"
            h={`100%`}
            align="center"
            w={6}
            right={-3}
            // opacity="0"
            transitionProperty="opacity"
            transitionDuration="normal"
          >
            <GammaTooltip label="Add element" placement="top">
              <IconButton
                icon={<FontAwesomeIcon icon={regular('plus')} />}
                aria-label="Add element"
                position="absolute"
                size="xs"
                variant="plain"
                isRound
                onClick={addCell}
                onMouseDown={preventDefaultToAvoidBlur}
              />
            </GammaTooltip>
          </Flex>
        )}
      </Cell>
    </AnnotatableNodeViewWrapper>
  )
}
