import { VStack } from '@chakra-ui/react'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { EmbedTip } from '../components/EmbedTip'
import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import GoogleDriveIcon from './icons/google-drive.svg'

const GoogleDrivePanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const url = getMediaSourceUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder="https://docs.google.com/..."
      />
      <EmbedTip
        guideLink="https://support.google.com/docs/answer/183965?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Cedit-embedded-spreadsheets%2Cembed-a-document-spreadsheet-or-presentation"
        extraMessage={`You don't need to publish to embed, but if you don't, anyone you share your ${DOC_DISPLAY_NAME} with will need to be shared on the file to see it.`}
        providerName="Google Sheets, Docs, or Slides"
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const GoogleDriveProvider: MediaProviderType = {
  label: 'Google Drive',
  key: 'embed.gdrive',
  description: `Embed interactive Sheets and Docs and full Slides presentations directly in a ${DOC_DISPLAY_NAME}`,
  icon: brands('google-drive'),
  image: GoogleDriveIcon,
  urlRegex: /^https?:\/\/docs.google.com\//,
  Panel: GoogleDrivePanel,
  canEmbedPrivate: true,
  rewriteEmbedUrl: (embedUrl) => {
    // https://stackoverflow.com/questions/23446449/google-sheet-embed-url-documentation
    if (embedUrl.includes('/presentation/')) {
      // Always load the play version for Slides
      const previewUrl = new URL(embedUrl.replace('/edit', '/preview'))
      previewUrl.searchParams.set('rm', 'minimal') // Hides the nav that causes weird jumping behavior
      return previewUrl.toString()
    } else {
      // Always load the editable version for Docs and Sheets
      const editableUrl = new URL(embedUrl.replace('/preview', '/edit'))
      // editableUrl.searchParams.set('rm', 'embedded') // This hides the title, but not sure we want that by default
      return editableUrl.toString()
    }
  },
  keywords: ['docs', 'sheet', 'slide', 'gdrive', 'document'],
}
