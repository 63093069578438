import { Editor, JSONContent } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import {
  ContainerEffect,
  ContainerWidth,
} from 'modules/tiptap_editor/styles/containerStyles'

import { findParentNodes } from '../../utils'
import { DragAnnotationData } from '../Annotatable/AnnotationExtension/types'
import { computeInsertCardMoveInstructions } from '../Annotatable/utils'
import { checkBetweenCardsDropTarget } from './cardNavigationUtils'
import { CARD_WIDTHS } from './constants'
import { getClosestParentContainerOption, isCardNode } from './utils'

class CardPluginState {
  constructor(public dragging: DragAnnotationData | null = null) {}
}
const CardPluginKey = new PluginKey<CardPluginState>('cardPlugin')

type CardDecorationSpec = {
  isCardDecoration: true
  nestedDepth: number
  isNested: boolean
  isFirstCard: boolean
  inheritContainerEffect?: ContainerEffect
  inheritContainerIsDark?: boolean
  inheritContainerWidth?: ContainerWidth
}

type CardDecoration = Decoration & {
  spec: CardDecorationSpec
}

// Which node types need to be aware of the card depth and style
const CARD_DECORATION_NODES = ['card', 'calloutBox', 'image', 'drawing']

export const CardPlugin = (editor: Editor) =>
  new Plugin({
    key: CardPluginKey,

    state: {
      init() {
        return new CardPluginState()
      },

      apply(_transaction, pluginState) {
        return pluginState
      },
    },

    props: {
      decorations: ({ doc }) => {
        const decorations: Decoration[] = []
        const decorate = (node: Node, pos: number) => {
          if (CARD_DECORATION_NODES.includes(node.type.name)) {
            const $pos = doc.resolve(pos)
            const parentCards = findParentNodes($pos, isCardNode).map(
              (c) => c.node
            )
            const inheritContainerEffect = getClosestParentContainerOption(
              parentCards,
              'effect'
            )
            const inheritContainerIsDark = getClosestParentContainerOption(
              parentCards,
              'isDark'
            )
            const inheritContainerWidth = getClosestParentContainerOption(
              parentCards,
              'width'
            )
            const nestedDepth = parentCards.length
            const isNested = nestedDepth > 0
            const isFirstCard = !isNested && $pos.index() === 0
            const spec: CardDecorationSpec = {
              isCardDecoration: true,
              nestedDepth,
              isNested,
              isFirstCard,
              // This needs to be passed as individual props because passing an object or array causes a ProseMirror bug where the nodeviews get recreated
              inheritContainerEffect,
              inheritContainerIsDark,
              inheritContainerWidth,
            }

            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {}, spec)
            )
          }
        }
        doc.descendants(decorate)
        return decorations.length > 0
          ? DecorationSet.create(doc, decorations)
          : DecorationSet.empty
      },

      handleDOMEvents: {
        drop(view) {
          // Store the annotation drag data here temporarily in the plugin state
          // this because the native prosemirror drop handler clears out the dragging data
          // before it calls pluginHandlers for dropHandler
          const annotationData = (view.dragging as any)
            ?.annotations as DragAnnotationData | null
          const pluginState = CardPluginKey.getState(view.state)
          if (!pluginState) {
            return false
          }
          pluginState.dragging = annotationData
          return
        },
      },
      handleDrop: (view, event, slice) => {
        const pluginState = CardPluginKey.getState(view.state)
        const dragAnnotationData = pluginState?.dragging
        if (pluginState) {
          // on drop always get rid of the drag data
          pluginState.dragging = null
        }
        const betweenCardsDropTarget = checkBetweenCardsDropTarget(
          view,
          event as DragEvent,
          slice
        )
        if (!betweenCardsDropTarget) {
          return false
        }
        // at this point we're dropping in between two cards

        const sliceContent = slice.content.toJSON() as JSONContent[]
        if (!sliceContent) {
          return false
        }
        const isSliceCard = sliceContent[0]?.type === 'card'
        // If we're dragging a card between other cards then we are rearranging cards
        // the rearrangeCards command handles this well and deals with annotations
        // NOTE: there is a possibility that the original node pos isn't right here
        // because of other editing happening, we may want to encode it as a Y.RelativePosition
        if (isSliceCard && dragAnnotationData) {
          return editor.commands.rearrangeCards({
            from: dragAnnotationData.origNodePos,
            to: betweenCardsDropTarget.pos,
            position: 'above',
          })
        }

        const dropContent = isSliceCard
          ? sliceContent
          : { type: 'card', content: sliceContent }
        const { selection } = view.state
        const shouldDeleteOriginal = !selection.empty // Insert widget will set this empty

        try {
          return editor
            .chain()
            .insertContentAt(betweenCardsDropTarget.pos, dropContent, {
              updateSelection: false,
            })
            .command(({ tr }) => {
              if (shouldDeleteOriginal) {
                tr.deleteSelection()
              }

              if (dragAnnotationData) {
                const moveInstructions = computeInsertCardMoveInstructions({
                  view,
                  tr,
                  cardWrapOffset: isSliceCard ? 0 : 1,
                  dragging: dragAnnotationData!,
                  dropPos: betweenCardsDropTarget.pos,
                })
                requestAnimationFrame(() => {
                  editor.commands.moveAnnotations?.(moveInstructions)
                })
              }
              return true
            })
            .focusMapped(betweenCardsDropTarget.pos, 1) // Focus into the new card
            .run()
        } catch (err) {
          console.error('(caught) [Cardplugin] handleDrop error:', err)
          return true
        }
      },
    },
  })

export const findCardPluginDecoration = (
  decorations: Decoration[]
): CardDecorationSpec => {
  const cardPluginDeco = decorations.find(
    (d): d is CardDecoration => d.spec.isCardDecoration
  )
  if (!cardPluginDeco) {
    return {
      isNested: false,
      nestedDepth: 0,
      isFirstCard: false,
      isCardDecoration: true,
    }
  }
  return cardPluginDeco.spec
}

// Only works for node types listed in CARD_DECORATION_NODES in CardPlugin.ts
export const getCardWidth = (decorations: Decoration[]) => {
  const { inheritContainerWidth } = findCardPluginDecoration(decorations)
  return {
    width: inheritContainerWidth,
    widthPx: CARD_WIDTHS[inheritContainerWidth || 'md'] * 16,
    isFullWidth: inheritContainerWidth === 'full',
  }
}
