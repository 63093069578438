import { Box, Divider, Flex, IconButton, Text } from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import React from 'react'

import { useAllFeatureFlags } from 'modules/featureFlags'
import { useAnalytics, SegmentEvents, NodeInsertMethods } from 'modules/segment'
import { useHover } from 'utils/hooks'

import { COMMANDS_MAP } from '../../../commands/commandsList'
import { useDragAndMouseDownMonitor } from './hooks'
import {
  InsertWidgetButtons,
  onItemDragEnd,
  onItemDragStart,
  onItemClick,
} from './InsertWidgetButtons'
import { INSERTABLE_CATEGORIES_LIST } from './items'
import { VaultButton } from './vault'

const CardButton = ({ editor }: { editor: Editor }) => {
  const item = COMMANDS_MAP['insertCardInside']
  const analytics = useAnalytics()
  const [ref, isHovered] = useHover<HTMLButtonElement>()
  const {
    isDragging,
    isMouseDown,
    handleClick,
    handleDragStart,
    handleDragEnd,
    onMouseDown,
    onMouseUp,
  } = useDragAndMouseDownMonitor(
    (ev) => onItemDragStart(item, editor, ev),
    () => {
      analytics?.track(SegmentEvents.CARD_CREATED, {
        method: NodeInsertMethods.INSERT_WIDGET,
      })
      onItemDragEnd(item, editor)
    },
    (ev) => onItemClick(item, editor, ev)
  )

  return (
    <Box
      mb={2}
      bg="#F9FAFBDD"
      borderWidth="1px"
      borderColor="whiteAlpha.600"
      borderRadius="xl"
    >
      <GammaTooltip
        // Since clicking does nothing, leave the tooltip open on mousedown/click
        closeOnMouseDown={false}
        closeOnClick={false}
        // Once dragging starts, override tooltip to be closed
        isOpen={isDragging ? false : undefined}
        label={
          <Box as="span" display="inline">
            <FontAwesomeIcon icon={regular('hand')} />{' '}
            <Text display="inline">Drag to insert card</Text>
          </Box>
        }
      >
        <IconButton
          ref={ref}
          shadow={isHovered ? 'lg' : ''}
          draggable={true}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseUp}
          onClick={handleClick}
          aria-label={item.name}
          data-testid={`${item.name
            .toLowerCase()
            .split(' ')
            .join('-')}-widget-inner-button`}
          size="sm"
          borderRadius="lg"
          variant="solid"
          icon={<FontAwesomeIcon icon={solid('rectangle')} />}
          color={'white'}
          textShadow="-1px 0px 2px rgba(0,0,0,.5)"
          borderColor={'transparent'}
          transform={`rotate(${isMouseDown && !isDragging ? -5 : 0}deg) scale(${
            isHovered || isMouseDown || isDragging ? 1.1 : 1
          })`}
        />
      </GammaTooltip>
    </Box>
  )
}
export const InsertWidgetInner = ({ editor }: { editor: Editor }) => {
  const allFlags = useAllFeatureFlags()
  return (
    <Flex
      direction="column"
      bg="#F9FAFBDD"
      p={1}
      borderWidth="1px"
      borderColor="whiteAlpha.600"
      borderRadius="xl"
      shadow="xl"
    >
      {/* Special snowflake: card */}
      <CardButton editor={editor} />
      <Divider mb={2} borderColor="blackAlpha.200" />
      <VaultButton editor={editor} />
      {INSERTABLE_CATEGORIES_LIST.map((outer, index) => {
        return (
          <React.Fragment key={index}>
            <Divider mb={2} borderColor="blackAlpha.200" />
            {outer.map(({ name, itemGroups, icon, featureFlag }) => {
              if (featureFlag && !allFlags[featureFlag]) return null
              return (
                <InsertWidgetButtons
                  key={name}
                  itemGroups={itemGroups}
                  icon={icon}
                  name={name}
                  editor={editor}
                />
              )
            })}
          </React.Fragment>
        )
      })}
    </Flex>
  )
}
