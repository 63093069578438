// Fork from tiptap/packages/core/src/helpers/getSchemaTypeNameByName.ts
// since it's not exported
import { Schema } from 'prosemirror-model'

export default function getSchemaTypeNameByName(
  name: string,
  schema: Schema
): 'node' | 'mark' | null {
  if (schema.nodes[name]) {
    return 'node'
  }

  if (schema.marks[name]) {
    return 'mark'
  }

  return null
}
