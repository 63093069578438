import { pushState, replaceState, GammaHistoryState } from 'modules/history'
import { CARD_HASH_PREFIX } from 'utils/url'

// Adapted from https://stackoverflow.com/a/43467144
export const isValidHttpUrl = (input: string) => {
  let url: URL

  try {
    url = new URL(input)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const updateCardHash = ({
  cardId,
  method = 'replace',
  data = {},
  emitChange = true,
}: {
  cardId: string | null
  method?: 'replace' | 'push'
  data?: Omit<GammaHistoryState, keyof NextHistoryState>
  emitChange?: boolean
}) => {
  const hash = `#${CARD_HASH_PREFIX}${cardId}`
  if (!cardId) return

  if (method === 'push') {
    pushState({ hash, data, emitChange })
  } else {
    replaceState({ hash, data, emitChange })
  }
}
