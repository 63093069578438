import {
  ySyncPluginKey,
  relativePositionToAbsolutePosition,
  absolutePositionToRelativePosition,
} from '@gamma-app/y-prosemirror'
import { findChildren } from '@tiptap/core'
import { EditorState, Transaction } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import * as Y from 'yjs'

import { AnnotationPluginKey } from '../AnnotationExtension/AnnotationPluginKey'
import { AnnotationActions } from '../AnnotationExtension/types'
import { DraftCommentsPluginKey } from './DraftCommentsPlugin'
import {
  CreateDraftCommentAction,
  DraftComment,
  DraftCommentActions,
  RemoveDraftCommentAction,
} from './types'

export class DraftCommentsState {
  public draftComments: {
    [targetId: string]: DraftComment
  } = {}

  public decorations: DecorationSet = DecorationSet.empty

  constructor() {}

  apply(transaction: Transaction, state: EditorState) {
    // Add/Remove annotations
    const action = transaction.getMeta(
      DraftCommentsPluginKey
    ) as DraftCommentActions

    if (action && action.type) {
      if (action.type === 'createDraftComment') {
        this.createDraftComment(action, state)
      }
      if (action.type === 'removeDraftComment') {
        this.removeDraftComment(action, state)
      }

      return this
    }

    //  hook into annotation plugin to refresh decoration
    const annotationAction = transaction.getMeta(
      AnnotationPluginKey
    ) as AnnotationActions

    if (annotationAction && annotationAction.type) {
      if (annotationAction.type === 'refreshAnnotationDecorations') {
        this.createDecorations(state)
      }
      return this
    }

    const ystate = ySyncPluginKey.getState(state)

    if (ystate.isChangeOrigin) {
      try {
        this.createDecorations(state)
      } catch (e) {
        console.log(`could not create decorations: ${e.message}`, e)
        // swallow
      }
      return this
    }

    // LOCAL CHANGE
    this.decorations = this.decorations.map(
      transaction.mapping,
      transaction.doc
    )
    return this
  }

  removeDraftComment(action: RemoveDraftCommentAction, state: EditorState) {
    // const relativePos = this.absToRel(state, pos)
    action.comments.forEach(({ targetId }) => {
      delete this.draftComments[targetId]
    })
    this.createDecorations(state)
    return this
  }

  createDraftComment(action: CreateDraftCommentAction, state: EditorState) {
    // const relativePos = this.absToRel(state, pos)

    this.draftComments[action.comment.targetId] = action.comment
    this.createDecorations(state)
  }

  createDecorations(state: EditorState) {
    const ystate = ySyncPluginKey.getState(state)
    if (!ystate.binding) {
      return this
    }
    const { doc, type, binding } = ystate
    const decorations: Decoration[] = []

    for (const comment of Object.values(this.draftComments)) {
      try {
        const pos = relativePositionToAbsolutePosition(
          doc,
          type,
          comment.relativePos,
          binding.mapping
        )

        if (pos == null) {
          continue
        }

        const node = state.doc.resolve(pos)
        const getPos = (curr) => {
          const results = findChildren(state.doc, (n) => n === curr)
          if (!results || results.length === 0) {
            throw new Error()
          }
          return results[0].pos
        }

        const start = node.nodeAfter?.isBlock ? pos : getPos(node.parent)
        const end =
          start +
          (node.nodeAfter?.isBlock
            ? node.nodeAfter.nodeSize
            : node.parent.nodeSize)

        decorations.push(
          Decoration.node(
            start,
            end,
            // attrs
            {},
            {
              isDraftComment: true,
              comment,
            }
          )
        )
      } catch (e) {
        console.log(`[DraftCommentState] error: $${e.message}`)
      }
    }

    this.decorations = DecorationSet.create(state.doc, decorations)
    return this
  }

  absToRel(state: EditorState, abs: number): Y.RelativePosition {
    const ystate = ySyncPluginKey.getState(state)
    const { type, binding } = ystate
    if (!ystate.binding) {
      throw new Error('Y.State non initialized')
    }
    return absolutePositionToRelativePosition(abs, type, binding.mapping)
  }
}
