import './patchEditorView'
// This is the public interface for consumers of this module
export * from './CollaborativeEditor'
export * from './defaultDocs'
export * from './EditorCore'
export * as CardConstants from './extensions/Card/constants'
export * from './types'
export * from './utils'
export * from './EditorCore/static'
export { SCHEMA_VERSION, getSerializableSchema } from './schema'
