import { Flex, Button } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react'
import React, { memo } from 'react'

import { NodeViewContent } from 'modules/tiptap_editor/react'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

export const TableRowView = ({
  node,
  editor,
  getPos,
  decorations,
}: NodeViewProps) => {
  const tableFocused = decorations.find(
    (decoration) => decoration.spec.tableFocused
  )

  return (
    <NodeViewWrapper as="tr" className="table-row-wrapper" {...node.attrs}>
      {tableFocused && (
        <th style={{ position: 'relative', padding: 0 }}>
          <RowControl
            editor={editor}
            getPos={getPos}
            rowNumber={tableFocused.spec.rowNumber}
          ></RowControl>
        </th>
      )}
      <NodeViewContent as="div" className="table-row-content"></NodeViewContent>
    </NodeViewWrapper>
  )
}

const RowControl = memo(
  ({
    editor,
    getPos,
    rowNumber,
  }: {
    editor: Editor
    getPos: () => number
    rowNumber: number
  }) => {
    const selectRow = () => {
      const rowPos = getPos() + 1
      editor.chain().focus().selectRow(rowPos).run()
    }

    return (
      <Flex
        height="100%"
        align="center"
        contentEditable="false"
        suppressContentEditableWarning
      >
        <GammaTooltip placement="top" label="Select row">
          <Button
            position="absolute"
            left="calc(var(--chakra-space-6) * -1)"
            top={0}
            height="calc(100% - 1px)"
            width={6}
            minWidth={0}
            padding={0}
            borderRightRadius="none"
            colorScheme="gray"
            color="black"
            onClick={selectRow}
            onMouseDown={preventDefaultToAvoidBlur}
            variant="plain"
            zIndex="1"
            data-testid={`select-row-button`}
            data-table-row={rowNumber + 1}
          >
            {rowNumber + 1}
          </Button>
        </GammaTooltip>
      </Flex>
    )
  }
)

RowControl.displayName = 'RowControl'
