import { TDShape, TLDR, TldrawApp } from '@gamma-app/tldraw'
import { Utils, TLBounds } from '@tldraw/core'
import { Vec } from '@tldraw/vec'

/**
 * Get the bounds of a a set of shapes
 * @param shapes The shapes to bound
 */
export const getCommonBounds = (shapes: TDShape[]): TLBounds | undefined => {
  return Utils.getCommonBounds(shapes.map(TLDR.getRotatedBounds))
}

/**
 * Zoom to fit the page's shapes.
 */
export const zoomPageToFit = (app: TldrawApp, padding: number = 128): void => {
  const {
    shapes,
    pageState: { camera },
    rendererBounds,
  } = app
  if (shapes.length === 0) return
  const commonBounds = getCommonBounds(shapes)
  if (!commonBounds) return
  const zoom = TLDR.getCameraZoom(
    Math.min(
      (rendererBounds.width - padding) / commonBounds.width,
      (rendererBounds.height - padding) / commonBounds.height
    )
  )
  // This line causes zoom to jump back and forth and doesn't seem to be needed
  // zoom = camera.zoom === zoom || camera.zoom < 1 ? Math.min(1, zoom) : zoom
  const mx = (rendererBounds.width - commonBounds.width * zoom) / 2 / zoom
  const my = (rendererBounds.height - commonBounds.height * zoom) / 2 / zoom
  app.setCamera(
    Vec.toFixed(Vec.sub([mx, my], [commonBounds.minX, commonBounds.minY])),
    zoom,
    `zoomed_to_fit`
  )
}
