import { groupBy, uniqBy } from 'lodash'

import { Comment, Reaction, User } from 'modules/api'

import { BlockReaction } from '../types'

export const getNewComment = (
  oldComments: Comment[],
  newComments: Comment[]
): Comment | null => {
  const oldById = groupBy(oldComments, 'id')

  const found: Comment[] = []
  newComments.forEach((comment) => {
    if (!oldById[comment.id]) {
      found.push(comment)
    }
  })

  return found[0] || null
}

/**
 *
 */
export const getParentCommentId = (comments: Comment[], id: string) => {
  for (const comment of comments) {
    if (comment.id === id) {
      return id
    }

    const replies = comment.replies || []
    for (const c2 of replies) {
      if (c2.id === id) {
        return comment.id
      }
    }
  }
  return null
}

const getUserReaction = ({
  reactions,
  emoji,
  user,
}: {
  reactions: BlockReaction[]
  emoji: string
  user?: User
}): Reaction | null => {
  const blockReaction = reactions.find((b) => b.emoji === emoji)
  if (!blockReaction) {
    return null
  }

  return (
    blockReaction.reactions.find(
      (r) => r.emoji === emoji && r.users?.find((u) => u!.id === user?.id)
    ) || null
  )
}

export const hasUserReacted = ({
  reactions,
  emoji,
  user,
}: {
  reactions: BlockReaction[]
  emoji: string
  user?: User
}): boolean => {
  return !!getUserReaction({ reactions, emoji, user })
}

/**
 * gets existing reaction from array, regardless of if the user has reacted to it
 */
export const getExistingReaction = ({
  reactions,
  emoji,
}: {
  reactions: BlockReaction[]
  emoji: string
}): Reaction | null => {
  const blockReaction = reactions.find((b) => b.emoji === emoji)
  if (!blockReaction) {
    return null
  }

  return blockReaction.reactions.find((r) => r.emoji === emoji) || null
}

export const getBlockReactionUsers = (br: BlockReaction): User[] => {
  return uniqBy(
    br.reactions.flatMap((r) => (r.users! as User[])!),
    'id'
  )
}
