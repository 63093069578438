import { Box, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { getAccentColor, isThemeDark } from 'modules/theming'
import { BLOCK_MARGIN } from 'modules/tiptap_editor/styles/constants'

import { generateGradientSpectrum } from '../colors'
import { EmptyCellContent } from '../constants'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

export const TextGridWrapper: SmartLayoutWrapperComponent = ({ children }) => {
  return (
    <Flex gap="1em" wrap="wrap">
      {children}
    </Flex>
  )
}

export const TextGridCell: SmartLayoutCellComponent = ({
  children,
  theme,
  numCells,
  index,
}) => {
  const accentColor = getAccentColor(theme)
  const isDark = isThemeDark(theme)
  const gradient = generateGradientSpectrum(
    accentColor,
    isDark ? 0.25 : 0.8,
    isDark ? 0.35 : 0.9,
    numCells
  )[index]

  return (
    <Box
      flex="1 1 auto"
      w="14em"
      backgroundImage={gradient}
      borderRadius="var(--block-border-radius)"
      shadow="base"
      p={BLOCK_MARGIN}
    >
      {children}
    </Box>
  )
}

export const TextGrid: SmartLayoutVariant = {
  key: 'textGrid',
  name: 'Text grid',
  icon: regular('grid-2-plus'),
  options: [],
  Wrapper: TextGridWrapper,
  Cell: TextGridCell,
  defaultContent: EmptyCellContent,
}
