import { Flex } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'

import { EmojiObject, EmojiPicker } from 'modules/emoji'

import { useDomNodeOffsetFromTop } from '../BlockCommentsButtons/hooks'
import { BaseCommentPopup } from './BaseCommentPopup'

type AddReactionPopupProps = {
  isMobile: boolean
  editor: NodeViewProps['editor']
  getPos: NodeViewProps['getPos']
  onReactionClick: (emojiId: EmojiObject) => void
}
export const AddReactionPopup: React.FC<AddReactionPopupProps> = ({
  isMobile,
  editor,
  onReactionClick,
  getPos,
}) => {
  const offsetFromTop = useDomNodeOffsetFromTop({
    editor,
    getPos,
  })

  // we dont know the position, so wait until useLayoutEffect before rendering
  if (offsetFromTop === null) {
    return null
  }

  const inner = (
    <Flex
      bg="white"
      p="0"
      zIndex="tooltip"
      data-testid="add-reaction-popup"
      data-target-name="reaction-emoji-picker"
      boxShadow="none"
      sx={{
        '.emoji-mart': {
          border: 'none',
        },
      }}
      overflow="hidden"
      justify="center"
    >
      <EmojiPicker
        perLine={9}
        handlePick={onReactionClick}
        shouldFocusSearch={!isMobile}
      />
    </Flex>
  )
  if (isMobile) {
    return inner
  }
  return (
    <BaseCommentPopup offsetFromTop={offsetFromTop}>{inner}</BaseCommentPopup>
  )
}
