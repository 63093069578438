import debounce from 'lodash/debounce'
import { nanoid } from 'nanoid'
import { NodeSelection, Selection } from 'prosemirror-state'

import { getStore } from 'modules/redux'

import {
  selectExpandedMediaId,
  selectExpandedNoteId,
  selectIsEditingMedia,
  setExpandedNoteId,
  setIsEditingMedia,
  setMediaNodeExpanded,
} from '../../reducer'

export const setFootnoteExpanded = (
  noteId: string | null,
  enabled: boolean
) => {
  const store = getStore()
  const payload = enabled ? { noteId } : { noteId: null }
  store.dispatch(setExpandedNoteId(payload))

  const state = store.getState()

  // When closing a footnote, also close the media drawer if it had been open before
  // otherwise you could get in a state where it opens and closes in an infinite loop
  if (!enabled && selectIsEditingMedia(state)) {
    store.dispatch(setIsEditingMedia({ isEditingMedia: false }))
  }
  if (!enabled && selectExpandedMediaId(state)) {
    store.dispatch(setMediaNodeExpanded({ nodeId: null }))
  }
}

export const setFootnoteExpandedDebounced = debounce(setFootnoteExpanded, 30)

// Returns null if no footnote expanded, or the string ID of the expanded one
export const getExpandedFootnoteId = (): string | null => {
  const state = getStore().getState()
  return selectExpandedNoteId(state)
}

export const generateFootnoteId = () => nanoid(5)

export const isFootnoteSelected = (selection: Selection) =>
  selection instanceof NodeSelection && selection.node.type.name === 'footnote'
