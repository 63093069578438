import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor } from '@tiptap/core'
import { NodeSelection } from 'prosemirror-state'
import { useCallback } from 'react'

import { getParentCardWidthPxFromSelection } from 'modules/tiptap_editor/extensions/Card/utils'
import { DrawingAttrs } from 'modules/tiptap_editor/extensions/Drawing'

import { AlignmentCommands } from '../../../extensions/HorizontalAlign'
import { ToolbarButton } from '../ToolbarButton'

type DrawingFormattingMenuProps = {
  editor: Editor
  selection: NodeSelection
}

export const DrawingFormattingMenu = ({
  editor,
  selection,
}: DrawingFormattingMenuProps) => {
  const nodeName = selection.node.type.name
  const { isFullWidth, width } = editor.getAttributes(nodeName) as DrawingAttrs

  const editNode = useCallback(
    (ev) => {
      ev.stopPropagation()
      editor.commands.editSelectedDrawingBlock?.()
    },
    [editor]
  )

  const isFullWidthOr100 =
    isFullWidth || width == getParentCardWidthPxFromSelection(selection)

  return (
    <ButtonGroup spacing={0} size="sm">
      <ToolbarButton
        label={'Full Width'}
        icon={regular('arrows-h')}
        isActive={isFullWidthOr100}
        onClick={() =>
          editor.commands.updateAttributes(nodeName, {
            isFullWidth: !isFullWidth,
            width: null,
          })
        }
      />
      {AlignmentCommands.map(({ name, icon, checkActive, apply }) => {
        if (!checkActive || !apply) return
        return (
          <ToolbarButton
            key={name}
            label={name}
            icon={icon}
            onClick={() => {
              apply(editor)
              if (isFullWidthOr100) {
                editor.commands.updateAttributes(nodeName, {
                  isFullWidth: false,
                })
              }
            }}
            isActive={!isFullWidthOr100 && checkActive(editor)}
          />
        )
      })}
      <ToolbarButton
        label={'Edit'}
        icon={regular('pencil')}
        onClick={editNode}
      />
    </ButtonGroup>
  )
}
