import { Component, ReactNode } from 'react'

import { config } from 'config'
import {
  ApolloClientPromiseRejectionEvent,
  ReactQueryPromiseRejectionEvent,
} from 'modules/api'

import { ErrorComponent } from './ErrorComponent'

type ErrorBoundaryState = {
  hasError: boolean
  errMessage: string
  shouldMountChildren: boolean
}

type ErrorBoundaryProps = {
  unhandledRejectionFilter: (
    promiseRejectionEvent: PromiseRejectionEvent
  ) => boolean
  children: ReactNode
}

// helper to identify malformed URIErrors in different browsers
export const hasMalformedURIError = (message: string) => {
  const URI_MALFORMED_PREFIX = 'URIError'
  const uriErrorMessageWords = ['malformed', 'encoded', 'illegal']
  /**
   * Different browser URI messages (sometimes starts with Uncaught):
   * URIError: The URI to be encoded contains invalid character (Edge)
   * URIError: malformed URI sequence (Firefox)
   * URIError: URI malformed (Chrome)
   * URIError: The URI to be encoded contains invalid character (Safari)
   */
  return (
    message.includes(URI_MALFORMED_PREFIX) &&
    message.split(' ').some((item) => uriErrorMessageWords.includes(item))
  )
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, errMessage: '', shouldMountChildren: true }
    this.handleError = this.handleError.bind(this)
    this.handleUnhandledRejection = this.handleUnhandledRejection.bind(this)
  }

  static defaultProps = {
    unhandledRejectionFilter: (e: PromiseRejectionEvent) => {
      // Always handle URI Malformed unhandled promise errors
      if (hasMalformedURIError(e.reason?.message || '')) return true

      // Also handle REST and GraphQL API specific ErrorEvents
      if (
        e.reason instanceof ApolloClientPromiseRejectionEvent &&
        // Only handle these when the document is in the foreground
        // until we can figure out how to properly configure the API refetcher
        document.hidden === false
      )
        return true
      if (e.reason instanceof ReactQueryPromiseRejectionEvent) return true

      return false
    },
  }

  componentDidMount() {
    window.addEventListener('error', this.handleError)
    window.addEventListener('unhandledrejection', this.handleUnhandledRejection)
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.handleError)
    window.removeEventListener(
      'unhandledrejection',
      this.handleUnhandledRejection
    )
  }

  componentDidCatch(
    error: Error,
    // Loosely typed because it depends on the React version and was
    // accidentally excluded in some versions.
    errorInfo?: { componentStack?: string | null }
  ) {
    if (config.APPLICATION_ENVIRONMENT === 'dev') {
      // In development, let the built in NextJS error boundary show useful error details
      // See https://github.com/vercel/next.js/blob/0af3b526408bae26d6b3f8cab75c4229998bf7cb/packages/react-dev-overlay/src/internal/ErrorBoundary.tsx
      throw error
    }
    // This error state will be captured below via getDerivedStateFromError
  }

  // This method will fire if the react tree has an uncaught exception.
  // In this case, we must unmount the children to avoid a possible infinite loop of errors.
  // See https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      errMessage: error.message,
      shouldMountChildren: false,
    }
  }

  /**
   * Handles global runtime errors that bubble up to the top
   * See https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
   */
  handleError(event: ErrorEvent) {
    if (config.APPLICATION_ENVIRONMENT === 'dev') {
      // show built in NextJS error boundary
      throw new Error(`[ErrorBoundary]: ${event.message}`)
    }

    // TODO: Figure out which global runtime errors we should handle here
    // https://linear.app/gamma-app/issue/G-2601/determine-which-additional-errors-the-errorboundary-should-handle
    // const message = event.message || ''
    // this.setState({ hasError: true, errMessage: message })
  }

  /**
   * Handles errors that are uncaught in promise rejections (async)
   * See https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
   */
  handleUnhandledRejection(promiseRejectionEvent: PromiseRejectionEvent) {
    const shouldHandle = this.props.unhandledRejectionFilter(
      promiseRejectionEvent
    )
    if (shouldHandle) {
      const message = promiseRejectionEvent.reason?.message || ''
      this.setState({ hasError: true, errMessage: message })
    }
  }

  render() {
    const malFormedURIErrorStyles =
      hasMalformedURIError(this.state.errMessage) === true
        ? {
            opacity: '0.65',
            starOverlay: false,
          }
        : {}

    return (
      <>
        {this.state.hasError && (
          <ErrorComponent
            pos="absolute"
            top={0}
            {...malFormedURIErrorStyles}
            errMessage={
              this.state.errMessage === '' ? undefined : this.state.errMessage
            }
            showDisclosure={true}
          />
        )}
        {this.state.shouldMountChildren && this.props.children}
      </>
    )
  }
}
