import { ClippableStyles } from './Image/Clippable'
import { ResizeableStyles } from './Resizeable'

export * from './MediaSources'
export * from './MediaCommands'
export * from './Embed'
export * from './Video'
export * from './Image'
export * from './Placeholder'
export * from './Gallery'
export * from './MediaExtension'
export * from './utils'

export const MOVEABLE_WRAPPER_CLASSNAME = 'gamma-moveable-wrapper'
export const MediaMoveableStyles = {
  ...ClippableStyles,
  ...ResizeableStyles,
}
