import { Editor } from '@tiptap/core'
import { NodeSelection } from 'prosemirror-state'

import { isMathNode } from '../extensions/Math'

export const blurEditorAndChildren = (editor: Editor) => {
  // If there's a math editor open, close it
  if (
    editor.state.selection instanceof NodeSelection &&
    isMathNode(editor.state.selection.node)
  ) {
    editor.chain().focus(editor.state.selection.from).blur().run()
    return
  }
  return editor.commands.blur()
}
