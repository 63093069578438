import { HStack, IconButton, Image, Text, VStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Mark, Node } from 'prosemirror-model'

import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { getLinkDomain } from 'utils/link'

import { MediaEmbedAttrs } from '../types'
import { getMediaSourceUrl } from '../utils'
import { getEmbedProvider } from './utils'

type EmbedDescriptionProps = {
  node: Node | Mark
  disableOpenButton?: boolean
}

export const EmbedDescription = ({
  node,
  disableOpenButton = false,
}: EmbedDescriptionProps) => {
  const { meta, source } = node.attrs as MediaEmbedAttrs
  const sourceUrl = getMediaSourceUrl(node.attrs)
  const provider = getEmbedProvider(source)
  const isGeneric = source === 'embed.custom' || source === 'video.custom'
  const icon = meta?.icon || provider.image?.src
  const site =
    meta?.site || (isGeneric ? getLinkDomain(sourceUrl) : provider.label)

  return (
    <VStack spacing={2} align="flex-start" color="black" w="100%">
      <HStack
        fontSize="sm"
        align="center"
        spacing={2}
        w="100%"
        color="gray.600"
      >
        <EmbedIcon icon={icon} />
        <Text noOfLines={1} flex={1} data-testid="site-name">
          {site}
        </Text>
        {provider.downloadable && sourceUrl && (
          <GammaTooltip label="Download" placement="top">
            <IconButton
              isRound
              aria-label="Download"
              icon={<FontAwesomeIcon icon={regular('download')} />}
              onClick={() => {
                // In Chrome this still just opens in new tab, but in other browsers it will actually download
                const link = document.createElement('a')
                link.href = sourceUrl
                link.target = '_blank'
                link.download = meta?.title || 'file.pdf'
                link.dispatchEvent(new MouseEvent('click'))
              }}
              size="xs"
              variant="ghost"
              colorScheme="blackAlpha"
              zIndex={2}
              onMouseDown={preventDefaultToAvoidBlur}
            />
          </GammaTooltip>
        )}
        {!disableOpenButton && sourceUrl && (
          <GammaTooltip label="Open in new tab" placement="top">
            <IconButton
              isRound
              aria-label="Open in new tab"
              icon={<FontAwesomeIcon icon={regular('external-link')} />}
              onClick={() => {
                window.open(sourceUrl), '_blank'
              }}
              size="xs"
              variant="ghost"
              colorScheme="blackAlpha"
              zIndex={2}
              onMouseDown={preventDefaultToAvoidBlur}
            />
          </GammaTooltip>
        )}
      </HStack>
      <Text
        fontSize="md"
        fontWeight="bold"
        noOfLines={1}
        maxW="90%"
        data-testid="title"
      >
        {meta?.title}
      </Text>
      {meta?.description && (
        <Text noOfLines={[2, 3]} whiteSpace="normal" fontSize="md" maxW="100%">
          {meta.description}
        </Text>
      )}
    </VStack>
  )
}

export const EmbedIcon = ({ icon, ...props }) => {
  return icon ? (
    <Image src={icon} h="1.5em" borderRadius="sm" {...props} />
  ) : (
    <Text as="span" {...props}>
      <FontAwesomeIcon icon={regular('browser')} />
    </Text>
  )
}
