import { CheckIcon } from '@chakra-ui/icons'
import { Button, ButtonGroup, HStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
} from 'modules/tiptap_editor/components/menus/ToolbarDropdown'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { SmartLayoutAttrs } from './types'
import { getSmartLayoutVariant, SmartLayoutVariants } from './variants'

export const SmartLayoutFormattingMenu = (nodeViewProps: NodeViewProps) => {
  const { editor, node, updateAttributes } = nodeViewProps
  const { options, variantKey } = node.attrs as SmartLayoutAttrs
  const variant = getSmartLayoutVariant(variantKey)
  const updateVariant = useCallback(
    (key: string) => {
      updateAttributes({ variantKey: key })
    },
    [updateAttributes]
  )
  return (
    <HStack
      bg="white"
      shadow="lg"
      borderRadius="full"
      border="1px solid"
      borderColor="gray.200"
      position="absolute"
      zIndex="docked" // Go over neighboring nodes and card + button
      bottom="100%"
      mb={4} // Clear the inner formatting menu
      py={1}
      px={2}
      contentEditable={false}
      userSelect="none"
      onMouseDown={preventDefaultToAvoidBlur}
    >
      <Dropdown isLazy>
        <DropdownButton
          as={Button}
          variant="toolbar"
          rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
          leftIcon={<FontAwesomeIcon icon={variant.icon} fixedWidth />}
          mr={2}
          size="sm"
        >
          {variant.name}
        </DropdownButton>
        <DropdownList
          maxH="min(25em, 45vh)"
          overflowY="auto"
          data-in-editor-focus
          fontSize="md"
        >
          {SmartLayoutVariants.map(({ key, name, icon }) => {
            const isChecked = variantKey === key
            return (
              <DropdownItem
                key={key}
                icon={
                  isChecked ? (
                    <CheckIcon />
                  ) : (
                    <FontAwesomeIcon icon={icon} fixedWidth />
                  )
                }
                onClick={() => updateVariant(key)}
              >
                {name}
              </DropdownItem>
            )
          })}
        </DropdownList>
      </Dropdown>
      <ButtonGroup size="sm">
        {variant.options.map(({ key, Control, defaultValue }) => {
          const value = options[key] ?? defaultValue
          const updateValue = (newValue: any) => {
            updateAttributes({
              options: { ...options, [key]: newValue },
            })
          }
          return (
            <Control
              key={key}
              value={value}
              updateValue={updateValue}
              {...nodeViewProps}
            />
          )
        })}
      </ButtonGroup>
    </HStack>
  )
}
