import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccessLink = {
  __typename?: 'AccessLink';
  id: Scalars['ID'];
  token: Scalars['String'];
  docId: Scalars['ID'];
  permission?: Maybe<Permission>;
  url: Scalars['String'];
};

export type AccessLinkInput = {
  permission?: Maybe<Permission>;
};

export type AddReactionInput = {
  docId: Scalars['String'];
  cardId?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
  targetType: TargetType;
  emoji: Scalars['String'];
};

export type BaseSnapshotInput = {
  ydoc: Scalars['String'];
  schemaVersion: Scalars['Int'];
  editors: Array<Scalars['ID']>;
};

export type Card = {
  __typename?: 'Card';
  id: Scalars['ID'];
  docId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
  text?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ID']>;
  previewUrl?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Array<Comment>>;
  reactions?: Maybe<Array<Reaction>>;
  createdTime: Scalars['DateTime'];
  updatedTime: Scalars['DateTime'];
};

export type CardStats = {
  __typename?: 'CardStats';
  cardId: Scalars['ID'];
  viewTimePercent?: Maybe<Scalars['Float']>;
  viewersPercent?: Maybe<Scalars['Float']>;
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  members?: Maybe<Array<ChannelMember>>;
  memberCount?: Maybe<Scalars['Int']>;
  isMember?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  workspaceId: Scalars['String'];
  activity?: Maybe<ChannelActivity>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type ChannelActivity = {
  __typename?: 'ChannelActivity';
  activeUsers?: Maybe<Array<ChannelMember>>;
};

export type ChannelConnection = {
  __typename?: 'ChannelConnection';
  edges: Array<ChannelEdge>;
  nodes: Array<Channel>;
  pageInfo: PageInfo;
};

export type ChannelEdge = {
  __typename?: 'ChannelEdge';
  node: Channel;
  cursor?: Maybe<Scalars['String']>;
};

export type ChannelMember = {
  __typename?: 'ChannelMember';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum ChannelSortField {
  MemberCount = 'memberCount'
}

export type ChannelSortOrder = {
  field?: Maybe<ChannelSortField>;
  direction?: Maybe<SortDirection>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  content?: Maybe<Scalars['JSON']>;
  cardId?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetText?: Maybe<Scalars['String']>;
  targetHtml?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  mentionedUsers?: Maybe<Array<Scalars['String']>>;
  replies?: Maybe<Array<Comment>>;
  reactions?: Maybe<Array<Reaction>>;
  archived?: Maybe<Scalars['Boolean']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CommentStatus>;
};

export enum CommentStatus {
  Open = 'open',
  Closed = 'closed'
}

export type CreateChannelInput = {
  name: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type CreateCommentInput = {
  docId?: Maybe<Scalars['String']>;
  cardId?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetText?: Maybe<Scalars['String']>;
  targetHtml?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
};

export type CreateDocInput = {
  orgId: Scalars['ID'];
  channels?: Maybe<Array<DocChannelInput>>;
  title?: Maybe<Scalars['String']>;
  initialContent?: Maybe<BaseSnapshotInput>;
  themeId?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSON']>;
};

export type CreateSnapshotInput = {
  docId: Scalars['ID'];
  ydoc: Scalars['String'];
  schemaVersion: Scalars['Int'];
  editors: Array<Scalars['ID']>;
};

export type CreateThemeInput = {
  workspaceId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  headingFont?: Maybe<Scalars['String']>;
  bodyFont?: Maybe<Scalars['String']>;
  accentColor?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  priority?: Maybe<Scalars['Int']>;
};



export type Doc = {
  __typename?: 'Doc';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleEdited?: Maybe<Scalars['Boolean']>;
  currentSnapshotId?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  organization?: Maybe<Organization>;
  currentSnapshot?: Maybe<Snapshot>;
  collaborators?: Maybe<Array<DocCollaborator>>;
  invitations?: Maybe<Array<DocInvitation>>;
  accessLinks?: Maybe<Array<AccessLink>>;
  cards?: Maybe<Array<Card>>;
  titleCard?: Maybe<Card>;
  theme?: Maybe<Theme>;
  attributes?: Maybe<Scalars['JSON']>;
  channels?: Maybe<Array<DocChannel>>;
  comments?: Maybe<Array<Comment>>;
  reactions?: Maybe<Array<Reaction>>;
  editors?: Maybe<Array<DocEditor>>;
  docUser?: Maybe<DocUser>;
  orgAccess?: Maybe<Permission>;
  publicAccess?: Maybe<Permission>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  editedTime?: Maybe<Scalars['DateTime']>;
  savedTime?: Maybe<Scalars['DateTime']>;
};

export type DocActivity = {
  __typename?: 'DocActivity';
  editors?: Maybe<Array<User>>;
  presenters?: Maybe<Array<User>>;
  doc: Doc;
};

export type DocActivityConnection = {
  __typename?: 'DocActivityConnection';
  edges: Array<DocActivityEdge>;
  nodes: Array<DocActivity>;
  pageInfo: PageInfo;
};

export type DocActivityEdge = {
  __typename?: 'DocActivityEdge';
  node: DocActivity;
  cursor?: Maybe<Scalars['String']>;
};

export type DocCardsStats = {
  __typename?: 'DocCardsStats';
  docId?: Maybe<Scalars['String']>;
  uniqueVisitors?: Maybe<Scalars['Int']>;
  uniqueEditors?: Maybe<Scalars['Int']>;
  cardCount?: Maybe<Scalars['Int']>;
  everyone?: Maybe<Array<CardStats>>;
  viewers?: Maybe<Array<CardStats>>;
};

export type DocChannel = {
  __typename?: 'DocChannel';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  permission?: Maybe<Permission>;
};

export type DocChannelInput = {
  channelId: Scalars['ID'];
  permission?: Maybe<Permission>;
};

export type DocCollaborator = {
  __typename?: 'DocCollaborator';
  user: User;
  docId: Scalars['ID'];
  permission: Permission;
  guest?: Maybe<Scalars['Boolean']>;
  accessLinkId?: Maybe<Scalars['ID']>;
};

export type DocCollaboratorInput = {
  userId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['ID']>;
  permission: Permission;
};

export type DocCollaboratorRemovalInput = {
  userId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['ID']>;
};

export type DocCommentsSubscription = {
  __typename?: 'DocCommentsSubscription';
  id: Scalars['ID'];
  comments?: Maybe<Array<Comment>>;
};

export type DocConnection = {
  __typename?: 'DocConnection';
  edges: Array<DocEdge>;
  nodes: Array<Doc>;
  pageInfo: PageInfo;
};

export type DocDailyViews = {
  __typename?: 'DocDailyViews';
  dayCount?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  everyone?: Maybe<Array<DocDayStats>>;
  viewers?: Maybe<Array<DocDayStats>>;
};

export type DocDayStats = {
  __typename?: 'DocDayStats';
  date?: Maybe<Scalars['Date']>;
  uniqueVisitors?: Maybe<Scalars['Int']>;
};

export type DocEdge = {
  __typename?: 'DocEdge';
  node: Doc;
  cursor?: Maybe<Scalars['String']>;
};

export type DocEditor = {
  __typename?: 'DocEditor';
  user: User;
  docId: Scalars['ID'];
  lastViewed?: Maybe<Scalars['DateTime']>;
  lastEdited?: Maybe<Scalars['DateTime']>;
  editCount?: Maybe<Scalars['Int']>;
};

export type DocInvitation = {
  __typename?: 'DocInvitation';
  id: Scalars['ID'];
  email: Scalars['String'];
  docId: Scalars['ID'];
  docTitle: Scalars['String'];
  permission: Permission;
  invitedBy: User;
};

export type DocJsonPatch = {
  op?: Maybe<PatchOperation>;
  path?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type DocReactionsSubscription = {
  __typename?: 'DocReactionsSubscription';
  id: Scalars['ID'];
  reactions?: Maybe<Array<Reaction>>;
};

export type DocResult = {
  __typename?: 'DocResult';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  previewUrl?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  organization?: Maybe<Organization>;
  editors?: Maybe<Array<DocEditor>>;
  docUser?: Maybe<DocUser>;
  orgAccess?: Maybe<Permission>;
  publicAccess?: Maybe<Permission>;
  createdTime?: Maybe<Scalars['DateTime']>;
  editedTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
};

export type DocSharingInput = {
  docId: Scalars['ID'];
  sharing?: Maybe<Array<DocJsonPatch>>;
};

export enum DocSortField {
  Title = 'title',
  LastViewed = 'lastViewed',
  LastEdited = 'lastEdited',
  Favorited = 'favorited',
  CreatedTime = 'createdTime',
  EditedTime = 'editedTime',
  UpdatedTime = 'updatedTime'
}

export type DocSortOrder = {
  field?: Maybe<DocSortField>;
  direction?: Maybe<SortDirection>;
};

export type DocSubscription = {
  __typename?: 'DocSubscription';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleEdited?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  previewUrl?: Maybe<Scalars['String']>;
  titleCard?: Maybe<Card>;
  theme?: Maybe<Theme>;
  channels?: Maybe<Array<DocChannel>>;
  collaborators?: Maybe<Array<DocCollaborator>>;
  invitations?: Maybe<Array<DocInvitation>>;
  orgAccess?: Maybe<Permission>;
  publicAccess?: Maybe<Permission>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  editedTime?: Maybe<Scalars['DateTime']>;
  savedTime?: Maybe<Scalars['DateTime']>;
  accessLinks?: Maybe<Array<AccessLink>>;
  reactions?: Maybe<Array<Reaction>>;
  editors?: Maybe<Array<DocEditor>>;
};

export type DocSubscriptionResult = DocSubscription | DocCommentsSubscription | DocReactionsSubscription;

export type DocUser = {
  __typename?: 'DocUser';
  id: Scalars['ID'];
  user?: Maybe<User>;
  docId: Scalars['ID'];
  lastViewed?: Maybe<Scalars['DateTime']>;
  lastEdited?: Maybe<Scalars['DateTime']>;
  favorited?: Maybe<Scalars['DateTime']>;
  editCount?: Maybe<Scalars['Int']>;
};

export type DocUserInput = {
  docId: Scalars['ID'];
  userId: Scalars['ID'];
  lastViewed?: Maybe<Scalars['DateTime']>;
  lastEdited?: Maybe<Scalars['DateTime']>;
  favorited?: Maybe<Scalars['DateTime']>;
};

export type DocUserPermissions = {
  __typename?: 'DocUserPermissions';
  user?: Maybe<User>;
  docId: Scalars['ID'];
  workspaceId: Scalars['ID'];
  permission?: Maybe<Permission>;
};

export type DuplicateDocInput = {
  orgId: Scalars['ID'];
  sourceDocId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  initialContent?: Maybe<BaseSnapshotInput>;
  channels?: Maybe<Array<DocChannelInput>>;
};

export type GammaInvitation = {
  __typename?: 'GammaInvitation';
  email: Scalars['String'];
};

export type InvitationInput = {
  userId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};


export type MemoStats = {
  __typename?: 'MemoStats';
  memoId: Scalars['ID'];
  uniqueViewers?: Maybe<Scalars['Int']>;
  uniqueEditors?: Maybe<Scalars['Int']>;
  cardCount?: Maybe<Scalars['Int']>;
  lastOpened?: Maybe<Scalars['DateTime']>;
  viewers?: Maybe<Array<MemoViewerStats>>;
  dailyViews?: Maybe<DocDailyViews>;
};

export type MemoViewerStats = {
  __typename?: 'MemoViewerStats';
  user?: Maybe<User>;
  visitorId?: Maybe<Scalars['ID']>;
  lastOpened?: Maybe<Scalars['DateTime']>;
  cardsViewed?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCard: Card;
  updateCard: Card;
  updateCardPreviewUrl: Card;
  archiveChannel: Channel;
  createChannel: Channel;
  updateChannel: Channel;
  joinChannel: Channel;
  leaveChannel: Channel;
  createComment: Comment;
  updateComment: Comment;
  updateCommentStatus: Comment;
  archiveComment: Comment;
  unarchiveComment: Comment;
  createDoc: Doc;
  updateDoc: Doc;
  addCollaborators: Doc;
  removeCollaborators: Doc;
  addCollaboratorByAccessLink?: Maybe<Doc>;
  acceptDocInvitation?: Maybe<Doc>;
  duplicateDoc: Doc;
  archiveDoc: Doc;
  unarchiveDoc: Doc;
  updateDocUser?: Maybe<DocUser>;
  updatePublicChannels: Doc;
  inviteMembers: Organization;
  inviteUsersToGamma: GammaInvitation;
  updateWorkspace: Organization;
  addReaction: Reaction;
  removeReaction: Reaction;
  createSnapshot: Snapshot;
  createTheme: Theme;
  updateTheme: Theme;
  duplicateTheme: Theme;
  archiveTheme: Theme;
  unarchiveTheme: Theme;
  transformText: TextTransformation;
};


export type MutationCreateCardArgs = {
  id: Scalars['ID'];
  docId: Scalars['ID'];
};


export type MutationUpdateCardArgs = {
  input: UpdateCardInput;
};


export type MutationUpdateCardPreviewUrlArgs = {
  id: Scalars['ID'];
  docId: Scalars['ID'];
  previewUrl?: Maybe<Scalars['String']>;
};


export type MutationArchiveChannelArgs = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type MutationCreateChannelArgs = {
  input?: Maybe<CreateChannelInput>;
};


export type MutationUpdateChannelArgs = {
  input?: Maybe<UpdateChannelInput>;
};


export type MutationJoinChannelArgs = {
  channelId: Scalars['ID'];
};


export type MutationLeaveChannelArgs = {
  channelId: Scalars['ID'];
};


export type MutationCreateCommentArgs = {
  input?: Maybe<CreateCommentInput>;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateCommentStatusArgs = {
  input: UpdateCommentStatusInput;
};


export type MutationArchiveCommentArgs = {
  id: Scalars['ID'];
};


export type MutationUnarchiveCommentArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDocArgs = {
  input?: Maybe<CreateDocInput>;
};


export type MutationUpdateDocArgs = {
  input: UpdateDocInput;
};


export type MutationAddCollaboratorsArgs = {
  docId: Scalars['ID'];
  collaborators: Array<DocCollaboratorInput>;
};


export type MutationRemoveCollaboratorsArgs = {
  docId: Scalars['ID'];
  collaborators: Array<DocCollaboratorRemovalInput>;
};


export type MutationAddCollaboratorByAccessLinkArgs = {
  docId: Scalars['ID'];
};


export type MutationAcceptDocInvitationArgs = {
  docId: Scalars['ID'];
  email: Scalars['String'];
  inviteCode: Scalars['String'];
};


export type MutationDuplicateDocArgs = {
  input: DuplicateDocInput;
};


export type MutationArchiveDocArgs = {
  id: Scalars['ID'];
};


export type MutationUnarchiveDocArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateDocUserArgs = {
  input?: Maybe<DocUserInput>;
};


export type MutationUpdatePublicChannelsArgs = {
  input?: Maybe<DocSharingInput>;
};


export type MutationInviteMembersArgs = {
  orgId: Scalars['ID'];
  invitees: Array<InvitationInput>;
};


export type MutationInviteUsersToGammaArgs = {
  emails: Array<Scalars['String']>;
};


export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};


export type MutationAddReactionArgs = {
  input?: Maybe<AddReactionInput>;
};


export type MutationRemoveReactionArgs = {
  input?: Maybe<RemoveReactionInput>;
};


export type MutationCreateSnapshotArgs = {
  input: CreateSnapshotInput;
};


export type MutationCreateThemeArgs = {
  input: CreateThemeInput;
};


export type MutationUpdateThemeArgs = {
  input: UpdateThemeInput;
};


export type MutationDuplicateThemeArgs = {
  id: Scalars['ID'];
  workspaceId?: Maybe<Scalars['ID']>;
};


export type MutationArchiveThemeArgs = {
  id: Scalars['ID'];
};


export type MutationUnarchiveThemeArgs = {
  id: Scalars['ID'];
};


export type MutationTransformTextArgs = {
  text: Scalars['String'];
  operation: TransformationOperation;
  params?: Maybe<Scalars['JSON']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  inviteCode?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  invitations?: Maybe<Array<WorkspaceInvitation>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export enum PatchOperation {
  Add = 'add',
  Remove = 'remove',
  Replace = 'replace'
}

export enum Permission {
  Manage = 'manage',
  Edit = 'edit',
  Comment = 'comment',
  View = 'view'
}

export type Query = {
  __typename?: 'Query';
  memoStats: MemoStats;
  docCardsStats: DocCardsStats;
  card?: Maybe<Card>;
  docCards: Array<Card>;
  channel?: Maybe<Channel>;
  channels?: Maybe<Array<Channel>>;
  workspaceChannels: ChannelConnection;
  docs: DocConnection;
  channelDocActivity: DocActivityConnection;
  previewChannelDocs: DocConnection;
  doc?: Maybe<Doc>;
  docPermission?: Maybe<DocUserPermissions>;
  docInvitation?: Maybe<DocInvitation>;
  workspace?: Maybe<Organization>;
  workspaceInvitation?: Maybe<WorkspaceInvitation>;
  workspaceMembers: WorkspaceMembersConnection;
  search: Array<SearchResult>;
  snapshots: SnapshotConnection;
  snapshot?: Maybe<Snapshot>;
  themes: Array<Theme>;
  theme?: Maybe<Theme>;
};


export type QueryMemoStatsArgs = {
  id: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  viewerStatsSortBy?: Maybe<ViewerStatsSortOrder>;
};


export type QueryDocCardsStatsArgs = {
  docId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
};


export type QueryCardArgs = {
  id: Scalars['ID'];
  docId: Scalars['ID'];
};


export type QueryDocCardsArgs = {
  docId: Scalars['ID'];
  maxDepth?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
};


export type QueryChannelArgs = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type QueryChannelsArgs = {
  workspaceId: Scalars['ID'];
};


export type QueryWorkspaceChannelsArgs = {
  workspaceId: Scalars['ID'];
  sortBy?: Maybe<ChannelSortOrder>;
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryDocsArgs = {
  workspaceId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['ID']>;
  channelId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<DocSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


export type QueryChannelDocActivityArgs = {
  channelId: Scalars['ID'];
  sortBy?: Maybe<DocSortOrder>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryPreviewChannelDocsArgs = {
  channelId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryDocArgs = {
  id: Scalars['ID'];
};


export type QueryDocPermissionArgs = {
  id: Scalars['ID'];
};


export type QueryDocInvitationArgs = {
  docId: Scalars['ID'];
  email: Scalars['String'];
  inviteCode: Scalars['String'];
};


export type QueryWorkspaceArgs = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryWorkspaceInvitationArgs = {
  email: Scalars['String'];
  inviteCode: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};


export type QueryWorkspaceMembersArgs = {
  id: Scalars['ID'];
  sortBy?: Maybe<WorkspaceMembersSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QuerySearchArgs = {
  query: Scalars['String'];
  types: Array<SearchType>;
  orgId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
};


export type QuerySnapshotsArgs = {
  docId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QuerySnapshotArgs = {
  docId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryThemesArgs = {
  workspaceId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
};


export type QueryThemeArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type Reaction = {
  __typename?: 'Reaction';
  docId: Scalars['String'];
  cardId?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
  targetType: TargetType;
  emoji: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<User>>;
};

export type RemoveReactionInput = {
  docId: Scalars['String'];
  cardId?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
  emoji: Scalars['String'];
};

export type SearchResult = Channel | DocResult | User;

export enum SearchType {
  User = 'user',
  Doc = 'doc',
  Channel = 'channel'
}

export type Snapshot = {
  __typename?: 'Snapshot';
  id: Scalars['ID'];
  docId: Scalars['ID'];
  ydoc: Scalars['String'];
  content: Scalars['JSON'];
  text: Scalars['String'];
  schemaVersion: Scalars['Int'];
  sha1: Scalars['String'];
  editors: Array<User>;
  createdTime: Scalars['DateTime'];
  updatedTime: Scalars['DateTime'];
};

export type SnapshotConnection = {
  __typename?: 'SnapshotConnection';
  edges: Array<SnapshotEdge>;
  nodes: Array<Snapshot>;
  pageInfo: PageInfo;
};

export type SnapshotEdge = {
  __typename?: 'SnapshotEdge';
  node: Snapshot;
  cursor?: Maybe<Scalars['String']>;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Subscription = {
  __typename?: 'Subscription';
  docCards: Array<Card>;
  channels?: Maybe<Array<Channel>>;
  doc?: Maybe<DocSubscriptionResult>;
  channelDocActivity?: Maybe<Array<DocActivity>>;
};


export type SubscriptionDocCardsArgs = {
  docId: Scalars['ID'];
};


export type SubscriptionChannelsArgs = {
  workspaceId: Scalars['ID'];
};


export type SubscriptionDocArgs = {
  id: Scalars['ID'];
};


export type SubscriptionChannelDocActivityArgs = {
  channelId: Scalars['ID'];
};

export enum TargetType {
  Card = 'card',
  Comment = 'comment',
  Decoration = 'decoration'
}

export type TextTransformation = {
  __typename?: 'TextTransformation';
  text?: Maybe<Scalars['String']>;
};

export type Theme = {
  __typename?: 'Theme';
  id: Scalars['ID'];
  workspaceId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  headingFont?: Maybe<Scalars['String']>;
  bodyFont?: Maybe<Scalars['String']>;
  accentColor?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  config: Scalars['JSON'];
  priority: Scalars['Int'];
  docCount?: Maybe<Scalars['Int']>;
  archived: Scalars['Boolean'];
  createdBy?: Maybe<User>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export enum TransformationOperation {
  Summarize = 'summarize',
  Shorten = 'shorten',
  Expand = 'expand',
  Bullets = 'bullets',
  Confident = 'confident',
  Oneline = 'oneline',
  FinetunedSummary = 'finetuned_summary',
  Raw = 'raw'
}

export type UpdateCardInput = {
  id: Scalars['ID'];
  docId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
  text?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type UpdateChannelInput = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateCommentInput = {
  id: Scalars['ID'];
  cardId?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
  targetText?: Maybe<Scalars['String']>;
  targetHtml?: Maybe<Scalars['String']>;
};

export type UpdateCommentStatusInput = {
  id: Scalars['ID'];
  status: CommentStatus;
  docId: Scalars['String'];
};

export type UpdateDocInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleCardId?: Maybe<Scalars['ID']>;
  titleEdited?: Maybe<Scalars['Boolean']>;
  themeId?: Maybe<Scalars['String']>;
  orgAccess?: Maybe<Permission>;
  publicAccess?: Maybe<Permission>;
  accessLinks?: Maybe<Array<AccessLinkInput>>;
  sharing?: Maybe<Array<DocJsonPatch>>;
  attributes?: Maybe<Scalars['JSON']>;
};

export type UpdateThemeInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  headingFont?: Maybe<Scalars['String']>;
  bodyFont?: Maybe<Scalars['String']>;
  accentColor?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  priority?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['ID']>;
};

export type UpdateWorkspaceInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  channelIds?: Maybe<Array<Scalars['ID']>>;
  organizations?: Maybe<Array<Organization>>;
};

export type ViewerCount = {
  __typename?: 'ViewerCount';
  everyone?: Maybe<Scalars['Int']>;
  viewersOnly?: Maybe<Scalars['Int']>;
};

export enum ViewerStatsSortField {
  LastOpened = 'lastOpened'
}

export type ViewerStatsSortOrder = {
  field?: Maybe<ViewerStatsSortField>;
  direction?: Maybe<SortDirection>;
};

export type WorkspaceInvitation = {
  __typename?: 'WorkspaceInvitation';
  id: Scalars['ID'];
  email: Scalars['String'];
  workspaceId?: Maybe<Scalars['ID']>;
  workspaceName?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<User>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type WorkspaceMemberEdge = {
  __typename?: 'WorkspaceMemberEdge';
  node: User;
  cursor?: Maybe<Scalars['String']>;
};

export type WorkspaceMembersConnection = {
  __typename?: 'WorkspaceMembersConnection';
  edges: Array<WorkspaceMemberEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export enum WorkspaceMembersSortField {
  CreatedTime = 'createdTime',
  DisplayName = 'displayName'
}

export type WorkspaceMembersSortOrder = {
  field?: Maybe<WorkspaceMembersSortField>;
  direction?: Maybe<SortDirection>;
};

export type TransformTextMutationVariables = Exact<{
  text: Scalars['String'];
  operation: TransformationOperation;
  params?: Maybe<Scalars['JSON']>;
}>;


export type TransformTextMutation = (
  { __typename?: 'Mutation' }
  & { transformText: (
    { __typename?: 'TextTransformation' }
    & Pick<TextTransformation, 'text'>
  ) }
);

export type GetDocWithSnapshotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocWithSnapshotQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { theme?: Maybe<(
      { __typename?: 'Theme' }
      & DefaultThemeFieldsFragment
    )>, currentSnapshot?: Maybe<(
      { __typename?: 'Snapshot' }
      & Pick<Snapshot, 'id' | 'docId' | 'content'>
    )>, titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl'>
    )>, cards?: Maybe<Array<(
      { __typename?: 'Card' }
      & DefaultCardFieldsFragment
    )>> }
  )> }
);

export type GetDocPreviewUrlQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocPreviewUrlQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl'>
    )> }
  )> }
);

export type DocFieldsFragmentFragment = (
  { __typename?: 'Doc' }
  & Pick<Doc, 'id' | 'title' | 'archived' | 'createdTime' | 'updatedTime' | 'editedTime' | 'currentSnapshotId' | 'orgAccess' | 'publicAccess'>
  & { titleCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'previewUrl' | 'createdTime' | 'updatedTime'>
  )>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'profileImageUrl' | 'displayName' | 'id'>
  )>, collaborators?: Maybe<Array<(
    { __typename?: 'DocCollaborator' }
    & Pick<DocCollaborator, 'docId' | 'permission'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )>>, docUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
  )>, channels?: Maybe<Array<(
    { __typename?: 'DocChannel' }
    & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
  )>>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, editors?: Maybe<Array<(
    { __typename?: 'DocEditor' }
    & Pick<DocEditor, 'docId' | 'lastEdited'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    ) }
  )>> }
);

export type SearchChannelResultFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'id' | 'name' | 'slug' | 'memberCount' | 'isMember' | 'workspaceId' | 'createdTime' | 'updatedTime'>
  & { members?: Maybe<Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'profileImageUrl' | 'displayName' | 'id'>
  )>> }
);

export type SearchDocResultFragment = (
  { __typename?: 'DocResult' }
  & Pick<DocResult, 'id' | 'title' | 'text' | 'archived' | 'previewUrl' | 'orgAccess' | 'publicAccess' | 'updatedTime' | 'createdTime' | 'editedTime'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
  )>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
  )>, editors?: Maybe<Array<(
    { __typename?: 'DocEditor' }
    & Pick<DocEditor, 'lastViewed' | 'lastEdited'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
    ) }
  )>>, docUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'lastViewed' | 'lastEdited' | 'favorited'>
  )> }
);

export type SearchDocsAndChannelsQueryVariables = Exact<{
  workspaceId?: Maybe<Scalars['ID']>;
  query: Scalars['String'];
}>;


export type SearchDocsAndChannelsQuery = (
  { __typename?: 'Query' }
  & { search: Array<(
    { __typename: 'Channel' }
    & SearchChannelResultFragment
  ) | (
    { __typename: 'DocResult' }
    & SearchDocResultFragment
  ) | { __typename: 'User' }> }
);

export type GetDocsForGlobalSearchQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<DocSortOrder>;
}>;


export type GetDocsForGlobalSearchQuery = (
  { __typename?: 'Query' }
  & { docs: (
    { __typename?: 'DocConnection' }
    & { edges: Array<(
      { __typename?: 'DocEdge' }
      & { node: (
        { __typename?: 'Doc' }
        & DocFieldsFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type DocCollaboratorsUpdateFragment = (
  { __typename?: 'Doc' }
  & { collaborators?: Maybe<Array<(
    { __typename?: 'DocCollaborator' }
    & Pick<DocCollaborator, 'docId' | 'permission' | 'guest'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    ) }
  )>> }
);

export type ShareSearchUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
);

export type SharingSearchUserQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type SharingSearchUserQuery = (
  { __typename?: 'Query' }
  & { search: Array<{ __typename: 'Channel' } | { __typename: 'DocResult' } | (
    { __typename: 'User' }
    & ShareSearchUserFragment
  )> }
);

export type DocChannelsUpdateFragment = (
  { __typename?: 'Doc' }
  & { channels?: Maybe<Array<(
    { __typename?: 'DocChannel' }
    & Pick<DocChannel, 'id' | 'name' | 'slug'>
  )>> }
);

export type ShareSearchChannelFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'id' | 'name' | 'slug' | 'isMember' | 'memberCount'>
  & { members?: Maybe<Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  )>> }
);

export type SharingSearchChannelQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type SharingSearchChannelQuery = (
  { __typename?: 'Query' }
  & { search: Array<(
    { __typename: 'Channel' }
    & ShareSearchChannelFragment
  ) | { __typename: 'DocResult' } | { __typename: 'User' }> }
);

export type UpdatePublicChannelsMutationVariables = Exact<{
  input: DocSharingInput;
}>;


export type UpdatePublicChannelsMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicChannels: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { channels?: Maybe<Array<(
      { __typename?: 'DocChannel' }
      & Pick<DocChannel, 'id' | 'name' | 'permission'>
    )>> }
  ) }
);

export type UpdateDocSharingMutationVariables = Exact<{
  id: Scalars['ID'];
  sharing?: Maybe<Array<DocJsonPatch> | DocJsonPatch>;
}>;


export type UpdateDocSharingMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { channels?: Maybe<Array<(
      { __typename?: 'DocChannel' }
      & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
    )>> }
  ) }
);

export type DefaultThemeFieldsFragment = (
  { __typename?: 'Theme' }
  & Pick<Theme, 'id' | 'workspaceId' | 'name' | 'headingFont' | 'bodyFont' | 'accentColor' | 'logoUrl' | 'config' | 'priority' | 'archived' | 'createdTime' | 'updatedTime'>
);

export type CreateThemeMutationVariables = Exact<{
  input: CreateThemeInput;
}>;


export type CreateThemeMutation = (
  { __typename?: 'Mutation' }
  & { createTheme: (
    { __typename?: 'Theme' }
    & Pick<Theme, 'docCount'>
    & DefaultThemeFieldsFragment
  ) }
);

export type UpdateThemeMutationVariables = Exact<{
  input: UpdateThemeInput;
}>;


export type UpdateThemeMutation = (
  { __typename?: 'Mutation' }
  & { updateTheme: (
    { __typename?: 'Theme' }
    & Pick<Theme, 'docCount'>
    & DefaultThemeFieldsFragment
  ) }
);

export type DuplicateThemeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicateThemeMutation = (
  { __typename?: 'Mutation' }
  & { duplicateTheme: (
    { __typename?: 'Theme' }
    & DefaultThemeFieldsFragment
  ) }
);

export type GetThemeQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type GetThemeQuery = (
  { __typename?: 'Query' }
  & { theme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'docCount'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email'>
    )> }
    & DefaultThemeFieldsFragment
  )> }
);

export type GetThemesQueryVariables = Exact<{
  workspaceId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
}>;


export type GetThemesQuery = (
  { __typename?: 'Query' }
  & { themes: Array<(
    { __typename?: 'Theme' }
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email'>
    )> }
    & DefaultThemeFieldsFragment
  )> }
);

export type ArchiveThemeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveThemeMutation = (
  { __typename?: 'Mutation' }
  & { archiveTheme: (
    { __typename?: 'Theme' }
    & DefaultThemeFieldsFragment
  ) }
);

export type UnarchiveThemeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnarchiveThemeMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveTheme: (
    { __typename?: 'Theme' }
    & DefaultThemeFieldsFragment
  ) }
);

export type DefaultCardFieldsFragment = (
  { __typename?: 'Card' }
  & Pick<Card, 'id' | 'archived' | 'docId' | 'title' | 'text' | 'previewUrl' | 'createdTime' | 'updatedTime'>
);

export type CreateCardMutationVariables = Exact<{
  id: Scalars['ID'];
  docId: Scalars['ID'];
}>;


export type CreateCardMutation = (
  { __typename?: 'Mutation' }
  & { createCard: (
    { __typename?: 'Card' }
    & Pick<Card, 'id'>
  ) }
);

export type GetCardQueryVariables = Exact<{
  id: Scalars['ID'];
  docId: Scalars['ID'];
}>;


export type GetCardQuery = (
  { __typename?: 'Query' }
  & { card?: Maybe<(
    { __typename?: 'Card' }
    & DefaultCardFieldsFragment
  )> }
);

export type GetCardsQueryVariables = Exact<{
  docId: Scalars['ID'];
}>;


export type GetCardsQuery = (
  { __typename?: 'Query' }
  & { docCards: Array<(
    { __typename?: 'Card' }
    & DefaultCardFieldsFragment
  )> }
);

export type SubscribeToCardsSubscriptionVariables = Exact<{
  docId: Scalars['ID'];
}>;


export type SubscribeToCardsSubscription = (
  { __typename?: 'Subscription' }
  & { docCards: Array<(
    { __typename?: 'Card' }
    & DefaultCardFieldsFragment
  )> }
);

export type GetDocMentionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocMentionQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title' | 'updatedTime' | 'editedTime' | 'createdTime'>
    & { titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl'>
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    )>, docUser?: Maybe<(
      { __typename?: 'DocUser' }
      & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
    )>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & Pick<DocEditor, 'lastViewed' | 'lastEdited'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
      ) }
    )>> }
  )> }
);

export type SubscribeToDocMentionSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SubscribeToDocMentionSubscription = (
  { __typename?: 'Subscription' }
  & { doc?: Maybe<(
    { __typename?: 'DocSubscription' }
    & Pick<DocSubscription, 'id' | 'title' | 'updatedTime' | 'editedTime'>
    & { titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl'>
    )>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & Pick<DocEditor, 'lastViewed' | 'lastEdited'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
      ) }
    )>> }
  ) | { __typename?: 'DocCommentsSubscription' } | { __typename?: 'DocReactionsSubscription' }> }
);

export type UpdateWorkspaceMutationVariables = Exact<{
  input: UpdateWorkspaceInput;
}>;


export type UpdateWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkspace: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  ) }
);

export type GetWorkspaceMembersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  sortBy?: Maybe<WorkspaceMembersSortOrder>;
  id: Scalars['ID'];
}>;


export type GetWorkspaceMembersQuery = (
  { __typename?: 'Query' }
  & { workspaceMembers: (
    { __typename?: 'WorkspaceMembersConnection' }
    & { edges: Array<(
      { __typename?: 'WorkspaceMemberEdge' }
      & { node: (
        { __typename?: 'User' }
        & Pick<User, 'displayName' | 'id' | 'createdTime' | 'profileImageUrl' | 'email'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetWorkspaceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetWorkspaceQuery = (
  { __typename?: 'Query' }
  & { workspace?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { invitations?: Maybe<Array<(
      { __typename?: 'WorkspaceInvitation' }
      & Pick<WorkspaceInvitation, 'id' | 'email' | 'createdTime'>
    )>> }
  )> }
);

export type SearchMentionQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type SearchMentionQuery = (
  { __typename?: 'Query' }
  & { search: Array<{ __typename: 'Channel' } | (
    { __typename: 'DocResult' }
    & Pick<DocResult, 'id' | 'title' | 'text' | 'archived' | 'previewUrl' | 'orgAccess' | 'publicAccess' | 'updatedTime' | 'createdTime' | 'editedTime'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & Pick<DocEditor, 'lastViewed' | 'lastEdited'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
      ) }
    )>>, docUser?: Maybe<(
      { __typename?: 'DocUser' }
      & Pick<DocUser, 'lastViewed' | 'lastEdited' | 'favorited'>
    )> }
  ) | (
    { __typename: 'User' }
    & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  )> }
);

export type ReactionFieldsFragment = (
  { __typename?: 'Reaction' }
  & Pick<Reaction, 'emoji' | 'count' | 'targetId' | 'targetType' | 'docId'>
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
  )>> }
);

export type CommentAuthorFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'profileImageUrl' | 'email'>
);

export type ReplyFieldsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'commentId' | 'content' | 'createdTime' | 'updatedTime' | 'archived'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & CommentAuthorFieldsFragment
  )>, reactions?: Maybe<Array<(
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  )>> }
);

export type CommentFieldsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'docId' | 'cardId' | 'content' | 'targetText' | 'targetHtml' | 'createdTime' | 'updatedTime' | 'archived' | 'targetId' | 'status'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & CommentAuthorFieldsFragment
  )>, replies?: Maybe<Array<(
    { __typename?: 'Comment' }
    & ReplyFieldsFragment
  )>>, reactions?: Maybe<Array<(
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  )>> }
);

export type DocCollaboratorFieldsFragment = (
  { __typename?: 'DocCollaborator' }
  & Pick<DocCollaborator, 'docId' | 'permission' | 'guest' | 'accessLinkId'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  ) }
);

export type DocInvitationsFieldsFragment = (
  { __typename?: 'DocInvitation' }
  & Pick<DocInvitation, 'id' | 'docId' | 'email' | 'permission'>
);

export type DocChannelFieldsFragment = (
  { __typename?: 'DocChannel' }
  & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
);

export type DocEditorsFieldsFragment = (
  { __typename?: 'DocEditor' }
  & Pick<DocEditor, 'lastEdited'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  ) }
);

export type AddCollaboratorsMutationVariables = Exact<{
  docId: Scalars['ID'];
  collaborators: Array<DocCollaboratorInput> | DocCollaboratorInput;
}>;


export type AddCollaboratorsMutation = (
  { __typename?: 'Mutation' }
  & { addCollaborators: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>>, invitations?: Maybe<Array<(
      { __typename?: 'DocInvitation' }
      & DocInvitationsFieldsFragment
    )>> }
  ) }
);

export type RemoveCollaboratorsMutationVariables = Exact<{
  docId: Scalars['ID'];
  collaborators: Array<DocCollaboratorRemovalInput> | DocCollaboratorRemovalInput;
}>;


export type RemoveCollaboratorsMutation = (
  { __typename?: 'Mutation' }
  & { removeCollaborators: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>>, invitations?: Maybe<Array<(
      { __typename?: 'DocInvitation' }
      & DocInvitationsFieldsFragment
    )>> }
  ) }
);

export type AddCollaboratorByAccessLinkMutationVariables = Exact<{
  docId: Scalars['ID'];
}>;


export type AddCollaboratorByAccessLinkMutation = (
  { __typename?: 'Mutation' }
  & { addCollaboratorByAccessLink?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>> }
  )> }
);

export type GetDocQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title' | 'titleEdited' | 'archived' | 'createdTime' | 'updatedTime' | 'editedTime' | 'savedTime' | 'currentSnapshotId' | 'publicAccess' | 'orgAccess'>
    & { comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & CommentFieldsFragment
    )>>, reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>>, accessLinks?: Maybe<Array<(
      { __typename?: 'AccessLink' }
      & Pick<AccessLink, 'id' | 'docId' | 'permission' | 'token' | 'url'>
    )>>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & DefaultThemeFieldsFragment
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>>, invitations?: Maybe<Array<(
      { __typename?: 'DocInvitation' }
      & DocInvitationsFieldsFragment
    )>>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & DocEditorsFieldsFragment
    )>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'displayName' | 'id' | 'email'>
    )>, channels?: Maybe<Array<(
      { __typename?: 'DocChannel' }
      & DocChannelFieldsFragment
    )>> }
  )> }
);

export type GetDocRobotsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocRobotsQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title' | 'createdTime' | 'updatedTime'>
    & { currentSnapshot?: Maybe<(
      { __typename?: 'Snapshot' }
      & Pick<Snapshot, 'id' | 'docId' | 'content'>
    )>, titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl'>
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'displayName' | 'id' | 'email'>
    )> }
  )> }
);

export type SubscribeToDocSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SubscribeToDocSubscription = (
  { __typename?: 'Subscription' }
  & { doc?: Maybe<(
    { __typename?: 'DocSubscription' }
    & Pick<DocSubscription, 'id' | 'title' | 'titleEdited' | 'archived' | 'updatedTime' | 'editedTime' | 'savedTime' | 'orgAccess'>
    & { accessLinks?: Maybe<Array<(
      { __typename?: 'AccessLink' }
      & Pick<AccessLink, 'id' | 'docId' | 'permission' | 'token' | 'url'>
    )>>, channels?: Maybe<Array<(
      { __typename?: 'DocChannel' }
      & DocChannelFieldsFragment
    )>>, collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & DocEditorsFieldsFragment
    )>>, invitations?: Maybe<Array<(
      { __typename?: 'DocInvitation' }
      & DocInvitationsFieldsFragment
    )>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & DefaultThemeFieldsFragment
    )>, reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>> }
  ) | (
    { __typename?: 'DocCommentsSubscription' }
    & Pick<DocCommentsSubscription, 'id'>
    & { comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & CommentFieldsFragment
    )>> }
  ) | (
    { __typename?: 'DocReactionsSubscription' }
    & Pick<DocReactionsSubscription, 'id'>
    & { reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>> }
  )> }
);

export type UpdateDocTitleMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleEdited?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateDocTitleMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title' | 'titleEdited'>
  ) }
);

export type UpdateDocOrgAccessMutationVariables = Exact<{
  id: Scalars['ID'];
  orgAccess?: Maybe<Permission>;
}>;


export type UpdateDocOrgAccessMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'orgAccess'>
  ) }
);

export type UpdateDocPublicAccessMutationVariables = Exact<{
  id: Scalars['ID'];
  publicAccess?: Maybe<Permission>;
}>;


export type UpdateDocPublicAccessMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'publicAccess'>
  ) }
);

export type UpdateDocAccessLinksMutationVariables = Exact<{
  id: Scalars['ID'];
  accessLinks?: Maybe<Array<AccessLinkInput> | AccessLinkInput>;
}>;


export type UpdateDocAccessLinksMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { accessLinks?: Maybe<Array<(
      { __typename?: 'AccessLink' }
      & Pick<AccessLink, 'permission' | 'id'>
    )>> }
  ) }
);

export type UpdateDocThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  themeId?: Maybe<Scalars['String']>;
}>;


export type UpdateDocThemeMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { theme?: Maybe<(
      { __typename?: 'Theme' }
      & DefaultThemeFieldsFragment
    )> }
  ) }
);

export type InviteMembersMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  invitees: Array<InvitationInput> | InvitationInput;
}>;


export type InviteMembersMutation = (
  { __typename?: 'Mutation' }
  & { inviteMembers: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { invitations?: Maybe<Array<(
      { __typename?: 'WorkspaceInvitation' }
      & Pick<WorkspaceInvitation, 'email'>
    )>> }
  ) }
);

export type CreateCommentMutationVariables = Exact<{
  input?: Maybe<CreateCommentInput>;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'docId' | 'cardId' | 'content' | 'targetText' | 'targetHtml' | 'createdTime' | 'updatedTime' | 'commentId' | 'archived' | 'targetId' | 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & CommentAuthorFieldsFragment
    )>, replies?: Maybe<Array<(
      { __typename?: 'Comment' }
      & ReplyFieldsFragment
    )>>, reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>> }
  ) }
);

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;


export type UpdateCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'docId' | 'content' | 'updatedTime'>
  ) }
);

export type UpdateCommentStatusMutationVariables = Exact<{
  input: UpdateCommentStatusInput;
}>;


export type UpdateCommentStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateCommentStatus: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'status' | 'updatedTime'>
  ) }
);

export type RemoveCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCommentMutation = (
  { __typename?: 'Mutation' }
  & { archiveComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'archived'>
  ) }
);

export type DocReactionsCreateFragment = (
  { __typename?: 'Doc' }
  & { reactions?: Maybe<Array<(
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  )>> }
);

export type DocCommentsCreateFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'docId' | 'cardId' | 'content' | 'targetText' | 'targetHtml' | 'createdTime' | 'updatedTime' | 'archived' | 'targetId' | 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & CommentAuthorFieldsFragment
    )> }
  )>> }
);

export type DocRepliesCreateFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
    & { replies?: Maybe<Array<(
      { __typename?: 'Comment' }
      & ReplyFieldsFragment
    )>> }
  )>> }
);

export type DocCommentsUpdateFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'docId' | 'content' | 'updatedTime'>
    & { reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>> }
  )>> }
);

export type DocRepliesUpdateFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
    & { replies?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'docId' | 'content' | 'updatedTime'>
      & { reactions?: Maybe<Array<(
        { __typename?: 'Reaction' }
        & ReactionFieldsFragment
      )>> }
    )>> }
  )>> }
);

export type DocCommentsRemoveFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'archived'>
  )>> }
);

export type DocRepliesRemoveFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
    & { replies?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'archived'>
    )>> }
  )>> }
);

export type AddReactionMutationVariables = Exact<{
  reactionInput: AddReactionInput;
}>;


export type AddReactionMutation = (
  { __typename?: 'Mutation' }
  & { addReaction: (
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  ) }
);

export type RemoveReactionMutationVariables = Exact<{
  reactionInput: RemoveReactionInput;
}>;


export type RemoveReactionMutation = (
  { __typename?: 'Mutation' }
  & { removeReaction: (
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  ) }
);

export type GetMemoAnalyticsQueryVariables = Exact<{
  memoId: Scalars['ID'];
}>;


export type GetMemoAnalyticsQuery = (
  { __typename?: 'Query' }
  & { memoStats: (
    { __typename?: 'MemoStats' }
    & Pick<MemoStats, 'memoId' | 'uniqueViewers' | 'uniqueEditors' | 'cardCount' | 'lastOpened'>
    & { viewers?: Maybe<Array<(
      { __typename?: 'MemoViewerStats' }
      & Pick<MemoViewerStats, 'visitorId' | 'lastOpened' | 'cardsViewed'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
      )> }
    )>>, dailyViews?: Maybe<(
      { __typename?: 'DocDailyViews' }
      & Pick<DocDailyViews, 'dayCount' | 'timezone'>
      & { everyone?: Maybe<Array<(
        { __typename?: 'DocDayStats' }
        & Pick<DocDayStats, 'date' | 'uniqueVisitors'>
      )>>, viewers?: Maybe<Array<(
        { __typename?: 'DocDayStats' }
        & Pick<DocDayStats, 'date' | 'uniqueVisitors'>
      )>> }
    )> }
  ) }
);

export type GetDocCardsAnalyticsQueryVariables = Exact<{
  docId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
}>;


export type GetDocCardsAnalyticsQuery = (
  { __typename?: 'Query' }
  & { docCardsStats: (
    { __typename?: 'DocCardsStats' }
    & Pick<DocCardsStats, 'docId' | 'uniqueVisitors' | 'uniqueEditors' | 'cardCount'>
    & { everyone?: Maybe<Array<(
      { __typename?: 'CardStats' }
      & Pick<CardStats, 'cardId' | 'viewTimePercent' | 'viewersPercent'>
    )>>, viewers?: Maybe<Array<(
      { __typename?: 'CardStats' }
      & Pick<CardStats, 'cardId' | 'viewTimePercent' | 'viewersPercent'>
    )>> }
  ) }
);

export type CreateDocMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  initialContent?: Maybe<BaseSnapshotInput>;
  channels?: Maybe<Array<DocChannelInput> | DocChannelInput>;
}>;


export type CreateDocMutation = (
  { __typename?: 'Mutation' }
  & { createDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
  ) }
);

export type ArchiveDocMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveDocMutation = (
  { __typename?: 'Mutation' }
  & { archiveDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'archived'>
  ) }
);

export type UnArchiveDocMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnArchiveDocMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'archived'>
  ) }
);

export type FavoriteDocMutationVariables = Exact<{
  input: DocUserInput;
}>;


export type FavoriteDocMutation = (
  { __typename?: 'Mutation' }
  & { updateDocUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
  )> }
);

export type DocUserUpdateFragment = (
  { __typename?: 'Doc' }
  & { docUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
  )> }
);

export type HomeDocFieldsFragmentFragment = (
  { __typename?: 'Doc' }
  & Pick<Doc, 'id' | 'title' | 'archived' | 'createdTime' | 'updatedTime' | 'editedTime' | 'currentSnapshotId' | 'orgAccess' | 'publicAccess'>
  & { titleCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'previewUrl' | 'createdTime' | 'updatedTime'>
  )>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'profileImageUrl' | 'displayName' | 'id'>
  )>, collaborators?: Maybe<Array<(
    { __typename?: 'DocCollaborator' }
    & Pick<DocCollaborator, 'docId' | 'permission'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )>>, docUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
  )>, channels?: Maybe<Array<(
    { __typename?: 'DocChannel' }
    & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
  )>>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, editors?: Maybe<Array<(
    { __typename?: 'DocEditor' }
    & Pick<DocEditor, 'docId' | 'lastEdited'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    ) }
  )>> }
);

export type GetPreviewChannelDocsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  channelId: Scalars['ID'];
}>;


export type GetPreviewChannelDocsQuery = (
  { __typename?: 'Query' }
  & { previewChannelDocs: (
    { __typename?: 'DocConnection' }
    & { edges: Array<(
      { __typename?: 'DocEdge' }
      & { node: (
        { __typename?: 'Doc' }
        & HomeDocFieldsFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetChannelDocActivityQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  channelId: Scalars['ID'];
}>;


export type GetChannelDocActivityQuery = (
  { __typename?: 'Query' }
  & { channelDocActivity: (
    { __typename?: 'DocActivityConnection' }
    & { edges: Array<(
      { __typename?: 'DocActivityEdge' }
      & { node: (
        { __typename?: 'DocActivity' }
        & { editors?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
        )>>, presenters?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
        )>>, doc: (
          { __typename?: 'Doc' }
          & HomeDocFieldsFragmentFragment
        ) }
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetDocsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<DocSortOrder>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['ID']>;
}>;


export type GetDocsQuery = (
  { __typename?: 'Query' }
  & { docs: (
    { __typename?: 'DocConnection' }
    & { edges: Array<(
      { __typename?: 'DocEdge' }
      & { node: (
        { __typename?: 'Doc' }
        & HomeDocFieldsFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetSnapshotsQueryVariables = Exact<{
  docId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetSnapshotsQuery = (
  { __typename?: 'Query' }
  & { snapshots: (
    { __typename?: 'SnapshotConnection' }
    & { edges: Array<(
      { __typename?: 'SnapshotEdge' }
      & { node: (
        { __typename?: 'Snapshot' }
        & Pick<Snapshot, 'id' | 'docId' | 'createdTime' | 'schemaVersion'>
        & { editors: Array<(
          { __typename?: 'User' }
          & Pick<User, 'displayName' | 'id'>
        )> }
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type DuplicateDocMutationVariables = Exact<{
  duplicateDocInput: DuplicateDocInput;
}>;


export type DuplicateDocMutation = (
  { __typename?: 'Mutation' }
  & { duplicateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title'>
  ) }
);

export type GetSnapshotQueryVariables = Exact<{
  snapshotId: Scalars['ID'];
  docId: Scalars['ID'];
}>;


export type GetSnapshotQuery = (
  { __typename?: 'Query' }
  & { snapshot?: Maybe<(
    { __typename?: 'Snapshot' }
    & Pick<Snapshot, 'id' | 'content' | 'schemaVersion'>
  )> }
);

export type GetDocForSnapshotViewerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocForSnapshotViewerQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'currentSnapshotId' | 'createdTime' | 'title'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'profileImageUrl' | 'displayName' | 'id'>
    )> }
  )> }
);

export type ChannelActivitySubscriptionVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type ChannelActivitySubscription = (
  { __typename?: 'Subscription' }
  & { channelDocActivity?: Maybe<Array<(
    { __typename?: 'DocActivity' }
    & { editors?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
    )>>, presenters?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
    )>>, doc: (
      { __typename?: 'Doc' }
      & HomeDocFieldsFragmentFragment
    ) }
  )>> }
);

export type ChannelFieldsFragmentFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'id' | 'name' | 'slug' | 'memberCount' | 'isMember' | 'workspaceId' | 'createdTime' | 'updatedTime' | 'archived'>
  & { members?: Maybe<Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'profileImageUrl' | 'displayName' | 'id'>
  )>>, activity?: Maybe<(
    { __typename?: 'ChannelActivity' }
    & { activeUsers?: Maybe<Array<(
      { __typename?: 'ChannelMember' }
      & Pick<ChannelMember, 'id' | 'displayName' | 'profileImageUrl' | 'email'>
    )>> }
  )> }
);

export type DocChannelUpdateFragment = (
  { __typename?: 'Doc' }
  & Pick<Doc, 'id'>
  & { channels?: Maybe<Array<(
    { __typename?: 'DocChannel' }
    & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
  )>> }
);

export type GetChannelsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type GetChannelsQuery = (
  { __typename?: 'Query' }
  & { channels?: Maybe<Array<(
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  )>> }
);

export type GetChannelQueryVariables = Exact<{
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
}>;


export type GetChannelQuery = (
  { __typename?: 'Query' }
  & { channel?: Maybe<(
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  )> }
);

export type GetWorkspaceChannelsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<ChannelSortOrder>;
}>;


export type GetWorkspaceChannelsQuery = (
  { __typename?: 'Query' }
  & { workspaceChannels: (
    { __typename?: 'ChannelConnection' }
    & { edges: Array<(
      { __typename?: 'ChannelEdge' }
      & { node: (
        { __typename?: 'Channel' }
        & ChannelFieldsFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type CreateChannelMutationVariables = Exact<{
  input: CreateChannelInput;
}>;


export type CreateChannelMutation = (
  { __typename?: 'Mutation' }
  & { createChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type JoinChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type JoinChannelMutation = (
  { __typename?: 'Mutation' }
  & { joinChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type LeaveChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type LeaveChannelMutation = (
  { __typename?: 'Mutation' }
  & { leaveChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type ArchiveChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
  workspaceId: Scalars['ID'];
}>;


export type ArchiveChannelMutation = (
  { __typename?: 'Mutation' }
  & { archiveChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type UpdateChannelMutationVariables = Exact<{
  input?: Maybe<UpdateChannelInput>;
}>;


export type UpdateChannelMutation = (
  { __typename?: 'Mutation' }
  & { updateChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type SearchChannelsQueryVariables = Exact<{
  workspaceId?: Maybe<Scalars['ID']>;
  query: Scalars['String'];
}>;


export type SearchChannelsQuery = (
  { __typename?: 'Query' }
  & { search: Array<(
    { __typename: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) | { __typename: 'DocResult' } | { __typename: 'User' }> }
);

export type ChannelsSubscriptionVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type ChannelsSubscription = (
  { __typename?: 'Subscription' }
  & { channels?: Maybe<Array<(
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  )>> }
);

export type GetCardPreviewUrlsForDocQueryVariables = Exact<{
  docId: Scalars['ID'];
  maxDepth?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
}>;


export type GetCardPreviewUrlsForDocQuery = (
  { __typename?: 'Query' }
  & { docCards: Array<(
    { __typename?: 'Card' }
    & Pick<Card, 'docId' | 'previewUrl' | 'position' | 'depth' | 'id' | 'archived'>
  )> }
);

export type GetDocInvitationQueryVariables = Exact<{
  docId: Scalars['ID'];
  email: Scalars['String'];
  inviteCode: Scalars['String'];
}>;


export type GetDocInvitationQuery = (
  { __typename?: 'Query' }
  & { docInvitation?: Maybe<(
    { __typename?: 'DocInvitation' }
    & Pick<DocInvitation, 'docId' | 'docTitle' | 'email' | 'permission'>
    & { invitedBy: (
      { __typename?: 'User' }
      & Pick<User, 'displayName'>
    ) }
  )> }
);

export type AcceptDocInvitationMutationVariables = Exact<{
  docId: Scalars['ID'];
  email: Scalars['String'];
  inviteCode: Scalars['String'];
}>;


export type AcceptDocInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptDocInvitation?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
  )> }
);

export type GetWorkspaceInvitationQueryVariables = Exact<{
  email: Scalars['String'];
  inviteCode: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
}>;


export type GetWorkspaceInvitationQuery = (
  { __typename?: 'Query' }
  & { workspaceInvitation?: Maybe<(
    { __typename?: 'WorkspaceInvitation' }
    & Pick<WorkspaceInvitation, 'email' | 'workspaceId' | 'workspaceName'>
    & { invitedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'displayName'>
    )> }
  )> }
);

export const DocFieldsFragmentFragmentDoc = gql`
    fragment DocFieldsFragment on Doc {
  id
  title
  archived
  createdTime
  updatedTime
  editedTime
  currentSnapshotId
  titleCard {
    id
    previewUrl
    createdTime
    updatedTime
  }
  createdBy {
    profileImageUrl
    displayName
    id
  }
  collaborators {
    docId
    user {
      id
    }
    permission
  }
  docUser {
    id
    docId
    lastViewed
    lastEdited
    favorited
  }
  channels {
    id
    name
    slug
    permission
  }
  organization {
    id
    name
  }
  orgAccess
  publicAccess
  editors {
    docId
    user {
      id
      displayName
      email
      profileImageUrl
    }
    lastEdited
  }
}
    `;
export const SearchChannelResultFragmentDoc = gql`
    fragment SearchChannelResult on Channel {
  id
  name
  slug
  members {
    profileImageUrl
    displayName
    id
  }
  memberCount
  isMember
  workspaceId
  createdTime
  updatedTime
}
    `;
export const SearchDocResultFragmentDoc = gql`
    fragment SearchDocResult on DocResult {
  id
  title
  text
  archived
  previewUrl
  createdBy {
    id
    displayName
    profileImageUrl
  }
  organization {
    id
  }
  editors {
    user {
      id
      displayName
      profileImageUrl
    }
    lastViewed
    lastEdited
  }
  docUser {
    lastViewed
    lastEdited
    favorited
  }
  orgAccess
  publicAccess
  updatedTime
  createdTime
  editedTime
}
    `;
export const DocCollaboratorsUpdateFragmentDoc = gql`
    fragment DocCollaboratorsUpdate on Doc {
  collaborators {
    docId
    user {
      id
      displayName
      email
      profileImageUrl
    }
    permission
    guest
  }
}
    `;
export const ShareSearchUserFragmentDoc = gql`
    fragment ShareSearchUser on User {
  id
  displayName
  email
  profileImageUrl
}
    `;
export const DocChannelsUpdateFragmentDoc = gql`
    fragment DocChannelsUpdate on Doc {
  channels {
    id
    name
    slug
  }
}
    `;
export const ShareSearchChannelFragmentDoc = gql`
    fragment ShareSearchChannel on Channel {
  id
  name
  slug
  isMember
  memberCount
  members {
    id
    displayName
    email
    profileImageUrl
  }
}
    `;
export const DefaultThemeFieldsFragmentDoc = gql`
    fragment DefaultThemeFields on Theme {
  id
  workspaceId
  name
  headingFont
  bodyFont
  accentColor
  logoUrl
  config
  priority
  archived
  createdTime
  updatedTime
}
    `;
export const DefaultCardFieldsFragmentDoc = gql`
    fragment DefaultCardFields on Card {
  id
  archived
  docId
  title
  text
  previewUrl
  createdTime
  updatedTime
}
    `;
export const CommentAuthorFieldsFragmentDoc = gql`
    fragment CommentAuthorFields on User {
  id
  displayName
  profileImageUrl
  email
}
    `;
export const ReactionFieldsFragmentDoc = gql`
    fragment ReactionFields on Reaction {
  emoji
  count
  targetId
  targetType
  docId
  users {
    id
    displayName
    profileImageUrl
  }
}
    `;
export const ReplyFieldsFragmentDoc = gql`
    fragment ReplyFields on Comment {
  id
  commentId
  content
  createdTime
  updatedTime
  archived
  user {
    ...CommentAuthorFields
  }
  reactions {
    ...ReactionFields
  }
}
    ${CommentAuthorFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}`;
export const CommentFieldsFragmentDoc = gql`
    fragment CommentFields on Comment {
  id
  docId
  cardId
  content
  targetText
  targetHtml
  createdTime
  updatedTime
  archived
  targetId
  status
  user {
    ...CommentAuthorFields
  }
  replies {
    ...ReplyFields
  }
  reactions {
    ...ReactionFields
  }
}
    ${CommentAuthorFieldsFragmentDoc}
${ReplyFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}`;
export const DocCollaboratorFieldsFragmentDoc = gql`
    fragment DocCollaboratorFields on DocCollaborator {
  docId
  user {
    id
    displayName
    email
    profileImageUrl
  }
  permission
  guest
  accessLinkId
}
    `;
export const DocInvitationsFieldsFragmentDoc = gql`
    fragment DocInvitationsFields on DocInvitation {
  id
  docId
  email
  permission
}
    `;
export const DocChannelFieldsFragmentDoc = gql`
    fragment DocChannelFields on DocChannel {
  id
  name
  slug
  permission
}
    `;
export const DocEditorsFieldsFragmentDoc = gql`
    fragment DocEditorsFields on DocEditor {
  user {
    id
    displayName
    email
    profileImageUrl
  }
  lastEdited
}
    `;
export const DocReactionsCreateFragmentDoc = gql`
    fragment DocReactionsCreate on Doc {
  reactions {
    ...ReactionFields
  }
}
    ${ReactionFieldsFragmentDoc}`;
export const DocCommentsCreateFragmentDoc = gql`
    fragment DocCommentsCreate on Doc {
  comments {
    id
    docId
    cardId
    content
    targetText
    targetHtml
    createdTime
    updatedTime
    archived
    targetId
    status
    user {
      ...CommentAuthorFields
    }
  }
}
    ${CommentAuthorFieldsFragmentDoc}`;
export const DocRepliesCreateFragmentDoc = gql`
    fragment DocRepliesCreate on Doc {
  comments {
    id
    replies {
      ...ReplyFields
    }
  }
}
    ${ReplyFieldsFragmentDoc}`;
export const DocCommentsUpdateFragmentDoc = gql`
    fragment DocCommentsUpdate on Doc {
  comments {
    id
    docId
    content
    updatedTime
    reactions {
      ...ReactionFields
    }
  }
}
    ${ReactionFieldsFragmentDoc}`;
export const DocRepliesUpdateFragmentDoc = gql`
    fragment DocRepliesUpdate on Doc {
  comments {
    id
    replies {
      id
      docId
      content
      updatedTime
      reactions {
        ...ReactionFields
      }
    }
  }
}
    ${ReactionFieldsFragmentDoc}`;
export const DocCommentsRemoveFragmentDoc = gql`
    fragment DocCommentsRemove on Doc {
  comments {
    id
    archived
  }
}
    `;
export const DocRepliesRemoveFragmentDoc = gql`
    fragment DocRepliesRemove on Doc {
  comments {
    id
    replies {
      id
      archived
    }
  }
}
    `;
export const DocUserUpdateFragmentDoc = gql`
    fragment DocUserUpdate on Doc {
  docUser {
    id
    docId
    lastViewed
    lastEdited
    favorited
  }
}
    `;
export const HomeDocFieldsFragmentFragmentDoc = gql`
    fragment HomeDocFieldsFragment on Doc {
  id
  title
  archived
  createdTime
  updatedTime
  editedTime
  currentSnapshotId
  titleCard {
    id
    previewUrl
    createdTime
    updatedTime
  }
  createdBy {
    profileImageUrl
    displayName
    id
  }
  collaborators {
    docId
    user {
      id
    }
    permission
  }
  docUser {
    id
    docId
    lastViewed
    lastEdited
    favorited
  }
  channels {
    id
    name
    slug
    permission
  }
  organization {
    id
    name
  }
  orgAccess
  publicAccess
  editors {
    docId
    user {
      id
      displayName
      email
      profileImageUrl
    }
    lastEdited
  }
}
    `;
export const ChannelFieldsFragmentFragmentDoc = gql`
    fragment ChannelFieldsFragment on Channel {
  id
  name
  slug
  members {
    profileImageUrl
    displayName
    id
  }
  memberCount
  isMember
  workspaceId
  createdTime
  updatedTime
  archived
  activity {
    activeUsers {
      id
      displayName
      profileImageUrl
      email
    }
  }
}
    `;
export const DocChannelUpdateFragmentDoc = gql`
    fragment DocChannelUpdate on Doc {
  id
  channels {
    id
    name
    slug
    permission
  }
}
    `;
export const TransformTextDocument = gql`
    mutation TransformText($text: String!, $operation: TransformationOperation!, $params: JSON) {
  transformText(text: $text, operation: $operation, params: $params) {
    text
  }
}
    `;
export type TransformTextMutationFn = Apollo.MutationFunction<TransformTextMutation, TransformTextMutationVariables>;

/**
 * __useTransformTextMutation__
 *
 * To run a mutation, you first call `useTransformTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransformTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transformTextMutation, { data, loading, error }] = useTransformTextMutation({
 *   variables: {
 *      text: // value for 'text'
 *      operation: // value for 'operation'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useTransformTextMutation(baseOptions?: Apollo.MutationHookOptions<TransformTextMutation, TransformTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransformTextMutation, TransformTextMutationVariables>(TransformTextDocument, options);
      }
export type TransformTextMutationHookResult = ReturnType<typeof useTransformTextMutation>;
export type TransformTextMutationResult = Apollo.MutationResult<TransformTextMutation>;
export type TransformTextMutationOptions = Apollo.BaseMutationOptions<TransformTextMutation, TransformTextMutationVariables>;
export const GetDocWithSnapshotDocument = gql`
    query GetDocWithSnapshot($id: ID!) {
  doc(id: $id) {
    id
    theme {
      ...DefaultThemeFields
    }
    currentSnapshot {
      id
      docId
      content
    }
    titleCard {
      previewUrl
    }
    cards {
      ...DefaultCardFields
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}
${DefaultCardFieldsFragmentDoc}`;

/**
 * __useGetDocWithSnapshotQuery__
 *
 * To run a query within a React component, call `useGetDocWithSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocWithSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocWithSnapshotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocWithSnapshotQuery(baseOptions: Apollo.QueryHookOptions<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>(GetDocWithSnapshotDocument, options);
      }
export function useGetDocWithSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>(GetDocWithSnapshotDocument, options);
        }
export type GetDocWithSnapshotQueryHookResult = ReturnType<typeof useGetDocWithSnapshotQuery>;
export type GetDocWithSnapshotLazyQueryHookResult = ReturnType<typeof useGetDocWithSnapshotLazyQuery>;
export type GetDocWithSnapshotQueryResult = Apollo.QueryResult<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>;
export const GetDocPreviewUrlDocument = gql`
    query GetDocPreviewUrl($id: ID!) {
  doc(id: $id) {
    id
    titleCard {
      previewUrl
    }
  }
}
    `;

/**
 * __useGetDocPreviewUrlQuery__
 *
 * To run a query within a React component, call `useGetDocPreviewUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocPreviewUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocPreviewUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocPreviewUrlQuery(baseOptions: Apollo.QueryHookOptions<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>(GetDocPreviewUrlDocument, options);
      }
export function useGetDocPreviewUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>(GetDocPreviewUrlDocument, options);
        }
export type GetDocPreviewUrlQueryHookResult = ReturnType<typeof useGetDocPreviewUrlQuery>;
export type GetDocPreviewUrlLazyQueryHookResult = ReturnType<typeof useGetDocPreviewUrlLazyQuery>;
export type GetDocPreviewUrlQueryResult = Apollo.QueryResult<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>;
export const SearchDocsAndChannelsDocument = gql`
    query SearchDocsAndChannels($workspaceId: ID, $query: String!) {
  search(orgId: $workspaceId, query: $query, types: [doc, channel]) {
    __typename
    ... on DocResult {
      ...SearchDocResult
    }
    ... on Channel {
      ...SearchChannelResult
    }
  }
}
    ${SearchDocResultFragmentDoc}
${SearchChannelResultFragmentDoc}`;

/**
 * __useSearchDocsAndChannelsQuery__
 *
 * To run a query within a React component, call `useSearchDocsAndChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDocsAndChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDocsAndChannelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchDocsAndChannelsQuery(baseOptions: Apollo.QueryHookOptions<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>(SearchDocsAndChannelsDocument, options);
      }
export function useSearchDocsAndChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>(SearchDocsAndChannelsDocument, options);
        }
export type SearchDocsAndChannelsQueryHookResult = ReturnType<typeof useSearchDocsAndChannelsQuery>;
export type SearchDocsAndChannelsLazyQueryHookResult = ReturnType<typeof useSearchDocsAndChannelsLazyQuery>;
export type SearchDocsAndChannelsQueryResult = Apollo.QueryResult<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>;
export const GetDocsForGlobalSearchDocument = gql`
    query GetDocsForGlobalSearch($first: Int, $after: String, $workspaceId: ID, $createdBy: ID, $archived: Boolean, $sortBy: DocSortOrder) {
  docs(
    first: $first
    after: $after
    workspaceId: $workspaceId
    createdBy: $createdBy
    archived: $archived
    sortBy: $sortBy
  ) {
    edges {
      node {
        ...DocFieldsFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${DocFieldsFragmentFragmentDoc}`;

/**
 * __useGetDocsForGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGetDocsForGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocsForGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocsForGlobalSearchQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      workspaceId: // value for 'workspaceId'
 *      createdBy: // value for 'createdBy'
 *      archived: // value for 'archived'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetDocsForGlobalSearchQuery(baseOptions?: Apollo.QueryHookOptions<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>(GetDocsForGlobalSearchDocument, options);
      }
export function useGetDocsForGlobalSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>(GetDocsForGlobalSearchDocument, options);
        }
export type GetDocsForGlobalSearchQueryHookResult = ReturnType<typeof useGetDocsForGlobalSearchQuery>;
export type GetDocsForGlobalSearchLazyQueryHookResult = ReturnType<typeof useGetDocsForGlobalSearchLazyQuery>;
export type GetDocsForGlobalSearchQueryResult = Apollo.QueryResult<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>;
export const SharingSearchUserDocument = gql`
    query SharingSearchUser($workspaceId: ID!, $query: String!) {
  search(orgId: $workspaceId, query: $query, types: [user]) {
    __typename
    ... on User {
      ...ShareSearchUser
    }
  }
}
    ${ShareSearchUserFragmentDoc}`;

/**
 * __useSharingSearchUserQuery__
 *
 * To run a query within a React component, call `useSharingSearchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharingSearchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharingSearchUserQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSharingSearchUserQuery(baseOptions: Apollo.QueryHookOptions<SharingSearchUserQuery, SharingSearchUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharingSearchUserQuery, SharingSearchUserQueryVariables>(SharingSearchUserDocument, options);
      }
export function useSharingSearchUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharingSearchUserQuery, SharingSearchUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharingSearchUserQuery, SharingSearchUserQueryVariables>(SharingSearchUserDocument, options);
        }
export type SharingSearchUserQueryHookResult = ReturnType<typeof useSharingSearchUserQuery>;
export type SharingSearchUserLazyQueryHookResult = ReturnType<typeof useSharingSearchUserLazyQuery>;
export type SharingSearchUserQueryResult = Apollo.QueryResult<SharingSearchUserQuery, SharingSearchUserQueryVariables>;
export const SharingSearchChannelDocument = gql`
    query SharingSearchChannel($workspaceId: ID!, $query: String!) {
  search(orgId: $workspaceId, query: $query, types: [channel]) {
    __typename
    ... on Channel {
      ...ShareSearchChannel
    }
  }
}
    ${ShareSearchChannelFragmentDoc}`;

/**
 * __useSharingSearchChannelQuery__
 *
 * To run a query within a React component, call `useSharingSearchChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharingSearchChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharingSearchChannelQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSharingSearchChannelQuery(baseOptions: Apollo.QueryHookOptions<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>(SharingSearchChannelDocument, options);
      }
export function useSharingSearchChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>(SharingSearchChannelDocument, options);
        }
export type SharingSearchChannelQueryHookResult = ReturnType<typeof useSharingSearchChannelQuery>;
export type SharingSearchChannelLazyQueryHookResult = ReturnType<typeof useSharingSearchChannelLazyQuery>;
export type SharingSearchChannelQueryResult = Apollo.QueryResult<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>;
export const UpdatePublicChannelsDocument = gql`
    mutation UpdatePublicChannels($input: DocSharingInput!) {
  updatePublicChannels(input: $input) {
    id
    channels {
      id
      name
      permission
    }
  }
}
    `;
export type UpdatePublicChannelsMutationFn = Apollo.MutationFunction<UpdatePublicChannelsMutation, UpdatePublicChannelsMutationVariables>;

/**
 * __useUpdatePublicChannelsMutation__
 *
 * To run a mutation, you first call `useUpdatePublicChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicChannelsMutation, { data, loading, error }] = useUpdatePublicChannelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublicChannelsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicChannelsMutation, UpdatePublicChannelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublicChannelsMutation, UpdatePublicChannelsMutationVariables>(UpdatePublicChannelsDocument, options);
      }
export type UpdatePublicChannelsMutationHookResult = ReturnType<typeof useUpdatePublicChannelsMutation>;
export type UpdatePublicChannelsMutationResult = Apollo.MutationResult<UpdatePublicChannelsMutation>;
export type UpdatePublicChannelsMutationOptions = Apollo.BaseMutationOptions<UpdatePublicChannelsMutation, UpdatePublicChannelsMutationVariables>;
export const UpdateDocSharingDocument = gql`
    mutation UpdateDocSharing($id: ID!, $sharing: [DocJSONPatch!]) {
  updateDoc(input: {id: $id, sharing: $sharing}) {
    id
    channels {
      id
      name
      slug
      permission
    }
  }
}
    `;
export type UpdateDocSharingMutationFn = Apollo.MutationFunction<UpdateDocSharingMutation, UpdateDocSharingMutationVariables>;

/**
 * __useUpdateDocSharingMutation__
 *
 * To run a mutation, you first call `useUpdateDocSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocSharingMutation, { data, loading, error }] = useUpdateDocSharingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sharing: // value for 'sharing'
 *   },
 * });
 */
export function useUpdateDocSharingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocSharingMutation, UpdateDocSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocSharingMutation, UpdateDocSharingMutationVariables>(UpdateDocSharingDocument, options);
      }
export type UpdateDocSharingMutationHookResult = ReturnType<typeof useUpdateDocSharingMutation>;
export type UpdateDocSharingMutationResult = Apollo.MutationResult<UpdateDocSharingMutation>;
export type UpdateDocSharingMutationOptions = Apollo.BaseMutationOptions<UpdateDocSharingMutation, UpdateDocSharingMutationVariables>;
export const CreateThemeDocument = gql`
    mutation CreateTheme($input: CreateThemeInput!) {
  createTheme(input: $input) {
    ...DefaultThemeFields
    docCount
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type CreateThemeMutationFn = Apollo.MutationFunction<CreateThemeMutation, CreateThemeMutationVariables>;

/**
 * __useCreateThemeMutation__
 *
 * To run a mutation, you first call `useCreateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThemeMutation, { data, loading, error }] = useCreateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateThemeMutation(baseOptions?: Apollo.MutationHookOptions<CreateThemeMutation, CreateThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateThemeMutation, CreateThemeMutationVariables>(CreateThemeDocument, options);
      }
export type CreateThemeMutationHookResult = ReturnType<typeof useCreateThemeMutation>;
export type CreateThemeMutationResult = Apollo.MutationResult<CreateThemeMutation>;
export type CreateThemeMutationOptions = Apollo.BaseMutationOptions<CreateThemeMutation, CreateThemeMutationVariables>;
export const UpdateThemeDocument = gql`
    mutation UpdateTheme($input: UpdateThemeInput!) {
  updateTheme(input: $input) {
    ...DefaultThemeFields
    docCount
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type UpdateThemeMutationFn = Apollo.MutationFunction<UpdateThemeMutation, UpdateThemeMutationVariables>;

/**
 * __useUpdateThemeMutation__
 *
 * To run a mutation, you first call `useUpdateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThemeMutation, { data, loading, error }] = useUpdateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateThemeMutation, UpdateThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateThemeMutation, UpdateThemeMutationVariables>(UpdateThemeDocument, options);
      }
export type UpdateThemeMutationHookResult = ReturnType<typeof useUpdateThemeMutation>;
export type UpdateThemeMutationResult = Apollo.MutationResult<UpdateThemeMutation>;
export type UpdateThemeMutationOptions = Apollo.BaseMutationOptions<UpdateThemeMutation, UpdateThemeMutationVariables>;
export const DuplicateThemeDocument = gql`
    mutation DuplicateTheme($id: ID!) {
  duplicateTheme(id: $id) {
    ...DefaultThemeFields
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type DuplicateThemeMutationFn = Apollo.MutationFunction<DuplicateThemeMutation, DuplicateThemeMutationVariables>;

/**
 * __useDuplicateThemeMutation__
 *
 * To run a mutation, you first call `useDuplicateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateThemeMutation, { data, loading, error }] = useDuplicateThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateThemeMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateThemeMutation, DuplicateThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateThemeMutation, DuplicateThemeMutationVariables>(DuplicateThemeDocument, options);
      }
export type DuplicateThemeMutationHookResult = ReturnType<typeof useDuplicateThemeMutation>;
export type DuplicateThemeMutationResult = Apollo.MutationResult<DuplicateThemeMutation>;
export type DuplicateThemeMutationOptions = Apollo.BaseMutationOptions<DuplicateThemeMutation, DuplicateThemeMutationVariables>;
export const GetThemeDocument = gql`
    query GetTheme($id: ID) {
  theme(id: $id) {
    ...DefaultThemeFields
    docCount
    createdBy {
      id
      displayName
      email
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;

/**
 * __useGetThemeQuery__
 *
 * To run a query within a React component, call `useGetThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThemeQuery(baseOptions?: Apollo.QueryHookOptions<GetThemeQuery, GetThemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThemeQuery, GetThemeQueryVariables>(GetThemeDocument, options);
      }
export function useGetThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThemeQuery, GetThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThemeQuery, GetThemeQueryVariables>(GetThemeDocument, options);
        }
export type GetThemeQueryHookResult = ReturnType<typeof useGetThemeQuery>;
export type GetThemeLazyQueryHookResult = ReturnType<typeof useGetThemeLazyQuery>;
export type GetThemeQueryResult = Apollo.QueryResult<GetThemeQuery, GetThemeQueryVariables>;
export const GetThemesDocument = gql`
    query GetThemes($workspaceId: ID, $archived: Boolean) {
  themes(workspaceId: $workspaceId, archived: $archived) {
    ...DefaultThemeFields
    createdBy {
      id
      displayName
      email
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;

/**
 * __useGetThemesQuery__
 *
 * To run a query within a React component, call `useGetThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useGetThemesQuery(baseOptions?: Apollo.QueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options);
      }
export function useGetThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options);
        }
export type GetThemesQueryHookResult = ReturnType<typeof useGetThemesQuery>;
export type GetThemesLazyQueryHookResult = ReturnType<typeof useGetThemesLazyQuery>;
export type GetThemesQueryResult = Apollo.QueryResult<GetThemesQuery, GetThemesQueryVariables>;
export const ArchiveThemeDocument = gql`
    mutation ArchiveTheme($id: ID!) {
  archiveTheme(id: $id) {
    ...DefaultThemeFields
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type ArchiveThemeMutationFn = Apollo.MutationFunction<ArchiveThemeMutation, ArchiveThemeMutationVariables>;

/**
 * __useArchiveThemeMutation__
 *
 * To run a mutation, you first call `useArchiveThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveThemeMutation, { data, loading, error }] = useArchiveThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveThemeMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveThemeMutation, ArchiveThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveThemeMutation, ArchiveThemeMutationVariables>(ArchiveThemeDocument, options);
      }
export type ArchiveThemeMutationHookResult = ReturnType<typeof useArchiveThemeMutation>;
export type ArchiveThemeMutationResult = Apollo.MutationResult<ArchiveThemeMutation>;
export type ArchiveThemeMutationOptions = Apollo.BaseMutationOptions<ArchiveThemeMutation, ArchiveThemeMutationVariables>;
export const UnarchiveThemeDocument = gql`
    mutation UnarchiveTheme($id: ID!) {
  unarchiveTheme(id: $id) {
    ...DefaultThemeFields
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type UnarchiveThemeMutationFn = Apollo.MutationFunction<UnarchiveThemeMutation, UnarchiveThemeMutationVariables>;

/**
 * __useUnarchiveThemeMutation__
 *
 * To run a mutation, you first call `useUnarchiveThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveThemeMutation, { data, loading, error }] = useUnarchiveThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveThemeMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveThemeMutation, UnarchiveThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnarchiveThemeMutation, UnarchiveThemeMutationVariables>(UnarchiveThemeDocument, options);
      }
export type UnarchiveThemeMutationHookResult = ReturnType<typeof useUnarchiveThemeMutation>;
export type UnarchiveThemeMutationResult = Apollo.MutationResult<UnarchiveThemeMutation>;
export type UnarchiveThemeMutationOptions = Apollo.BaseMutationOptions<UnarchiveThemeMutation, UnarchiveThemeMutationVariables>;
export const CreateCardDocument = gql`
    mutation CreateCard($id: ID!, $docId: ID!) {
  createCard(id: $id, docId: $docId) {
    id
  }
}
    `;
export type CreateCardMutationFn = Apollo.MutationFunction<CreateCardMutation, CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, options);
      }
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>;
export const GetCardDocument = gql`
    query GetCard($id: ID!, $docId: ID!) {
  card(id: $id, docId: $docId) {
    ...DefaultCardFields
  }
}
    ${DefaultCardFieldsFragmentDoc}`;

/**
 * __useGetCardQuery__
 *
 * To run a query within a React component, call `useGetCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useGetCardQuery(baseOptions: Apollo.QueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
      }
export function useGetCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
        }
export type GetCardQueryHookResult = ReturnType<typeof useGetCardQuery>;
export type GetCardLazyQueryHookResult = ReturnType<typeof useGetCardLazyQuery>;
export type GetCardQueryResult = Apollo.QueryResult<GetCardQuery, GetCardQueryVariables>;
export const GetCardsDocument = gql`
    query GetCards($docId: ID!) {
  docCards(docId: $docId) {
    ...DefaultCardFields
  }
}
    ${DefaultCardFieldsFragmentDoc}`;

/**
 * __useGetCardsQuery__
 *
 * To run a query within a React component, call `useGetCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useGetCardsQuery(baseOptions: Apollo.QueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
      }
export function useGetCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
        }
export type GetCardsQueryHookResult = ReturnType<typeof useGetCardsQuery>;
export type GetCardsLazyQueryHookResult = ReturnType<typeof useGetCardsLazyQuery>;
export type GetCardsQueryResult = Apollo.QueryResult<GetCardsQuery, GetCardsQueryVariables>;
export const SubscribeToCardsDocument = gql`
    subscription SubscribeToCards($docId: ID!) {
  docCards(docId: $docId) {
    ...DefaultCardFields
  }
}
    ${DefaultCardFieldsFragmentDoc}`;

/**
 * __useSubscribeToCardsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToCardsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToCardsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToCardsSubscription({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useSubscribeToCardsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToCardsSubscription, SubscribeToCardsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToCardsSubscription, SubscribeToCardsSubscriptionVariables>(SubscribeToCardsDocument, options);
      }
export type SubscribeToCardsSubscriptionHookResult = ReturnType<typeof useSubscribeToCardsSubscription>;
export type SubscribeToCardsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToCardsSubscription>;
export const GetDocMentionDocument = gql`
    query GetDocMention($id: ID!) {
  doc(id: $id) {
    id
    title
    updatedTime
    editedTime
    titleCard {
      previewUrl
    }
    createdTime
    createdBy {
      id
      displayName
      email
      profileImageUrl
    }
    docUser {
      id
      docId
      lastViewed
      lastEdited
      favorited
    }
    editors {
      user {
        id
        displayName
        profileImageUrl
      }
      lastViewed
      lastEdited
    }
  }
}
    `;

/**
 * __useGetDocMentionQuery__
 *
 * To run a query within a React component, call `useGetDocMentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocMentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocMentionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocMentionQuery(baseOptions: Apollo.QueryHookOptions<GetDocMentionQuery, GetDocMentionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocMentionQuery, GetDocMentionQueryVariables>(GetDocMentionDocument, options);
      }
export function useGetDocMentionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocMentionQuery, GetDocMentionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocMentionQuery, GetDocMentionQueryVariables>(GetDocMentionDocument, options);
        }
export type GetDocMentionQueryHookResult = ReturnType<typeof useGetDocMentionQuery>;
export type GetDocMentionLazyQueryHookResult = ReturnType<typeof useGetDocMentionLazyQuery>;
export type GetDocMentionQueryResult = Apollo.QueryResult<GetDocMentionQuery, GetDocMentionQueryVariables>;
export const SubscribeToDocMentionDocument = gql`
    subscription SubscribeToDocMention($id: ID!) {
  doc(id: $id) {
    ... on DocSubscription {
      id
      title
      updatedTime
      editedTime
      titleCard {
        previewUrl
      }
      editors {
        user {
          id
          displayName
          profileImageUrl
        }
        lastViewed
        lastEdited
      }
    }
  }
}
    `;

/**
 * __useSubscribeToDocMentionSubscription__
 *
 * To run a query within a React component, call `useSubscribeToDocMentionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToDocMentionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToDocMentionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeToDocMentionSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToDocMentionSubscription, SubscribeToDocMentionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToDocMentionSubscription, SubscribeToDocMentionSubscriptionVariables>(SubscribeToDocMentionDocument, options);
      }
export type SubscribeToDocMentionSubscriptionHookResult = ReturnType<typeof useSubscribeToDocMentionSubscription>;
export type SubscribeToDocMentionSubscriptionResult = Apollo.SubscriptionResult<SubscribeToDocMentionSubscription>;
export const UpdateWorkspaceDocument = gql`
    mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
  updateWorkspace(input: $input) {
    id
    name
  }
}
    `;
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>(UpdateWorkspaceDocument, options);
      }
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;
export const GetWorkspaceMembersDocument = gql`
    query GetWorkspaceMembers($first: Int, $after: String, $sortBy: WorkspaceMembersSortOrder, $id: ID!) {
  workspaceMembers(id: $id, first: $first, after: $after, sortBy: $sortBy) {
    edges {
      node {
        displayName
        id
        createdTime
        profileImageUrl
        email
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetWorkspaceMembersQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceMembersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceMembersQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>(GetWorkspaceMembersDocument, options);
      }
export function useGetWorkspaceMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>(GetWorkspaceMembersDocument, options);
        }
export type GetWorkspaceMembersQueryHookResult = ReturnType<typeof useGetWorkspaceMembersQuery>;
export type GetWorkspaceMembersLazyQueryHookResult = ReturnType<typeof useGetWorkspaceMembersLazyQuery>;
export type GetWorkspaceMembersQueryResult = Apollo.QueryResult<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>;
export const GetWorkspaceDocument = gql`
    query GetWorkspace($id: String!) {
  workspace(id: $id) {
    id
    name
    invitations {
      id
      email
      createdTime
    }
  }
}
    `;

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
      }
export function useGetWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
        }
export type GetWorkspaceQueryHookResult = ReturnType<typeof useGetWorkspaceQuery>;
export type GetWorkspaceLazyQueryHookResult = ReturnType<typeof useGetWorkspaceLazyQuery>;
export type GetWorkspaceQueryResult = Apollo.QueryResult<GetWorkspaceQuery, GetWorkspaceQueryVariables>;
export const SearchMentionDocument = gql`
    query SearchMention($workspaceId: ID!, $query: String!) {
  search(orgId: $workspaceId, query: $query, types: [user, doc]) {
    __typename
    ... on DocResult {
      id
      title
      text
      archived
      previewUrl
      createdBy {
        id
        displayName
        profileImageUrl
      }
      organization {
        id
      }
      editors {
        user {
          id
          displayName
          profileImageUrl
        }
        lastViewed
        lastEdited
      }
      docUser {
        lastViewed
        lastEdited
        favorited
      }
      orgAccess
      publicAccess
      updatedTime
      createdTime
      editedTime
    }
    ... on User {
      id
      displayName
      email
      profileImageUrl
    }
  }
}
    `;

/**
 * __useSearchMentionQuery__
 *
 * To run a query within a React component, call `useSearchMentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMentionQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchMentionQuery(baseOptions: Apollo.QueryHookOptions<SearchMentionQuery, SearchMentionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchMentionQuery, SearchMentionQueryVariables>(SearchMentionDocument, options);
      }
export function useSearchMentionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchMentionQuery, SearchMentionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchMentionQuery, SearchMentionQueryVariables>(SearchMentionDocument, options);
        }
export type SearchMentionQueryHookResult = ReturnType<typeof useSearchMentionQuery>;
export type SearchMentionLazyQueryHookResult = ReturnType<typeof useSearchMentionLazyQuery>;
export type SearchMentionQueryResult = Apollo.QueryResult<SearchMentionQuery, SearchMentionQueryVariables>;
export const AddCollaboratorsDocument = gql`
    mutation AddCollaborators($docId: ID!, $collaborators: [DocCollaboratorInput!]!) {
  addCollaborators(docId: $docId, collaborators: $collaborators) {
    id
    collaborators {
      ...DocCollaboratorFields
    }
    invitations {
      ...DocInvitationsFields
    }
  }
}
    ${DocCollaboratorFieldsFragmentDoc}
${DocInvitationsFieldsFragmentDoc}`;
export type AddCollaboratorsMutationFn = Apollo.MutationFunction<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>;

/**
 * __useAddCollaboratorsMutation__
 *
 * To run a mutation, you first call `useAddCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaboratorsMutation, { data, loading, error }] = useAddCollaboratorsMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *      collaborators: // value for 'collaborators'
 *   },
 * });
 */
export function useAddCollaboratorsMutation(baseOptions?: Apollo.MutationHookOptions<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>(AddCollaboratorsDocument, options);
      }
export type AddCollaboratorsMutationHookResult = ReturnType<typeof useAddCollaboratorsMutation>;
export type AddCollaboratorsMutationResult = Apollo.MutationResult<AddCollaboratorsMutation>;
export type AddCollaboratorsMutationOptions = Apollo.BaseMutationOptions<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>;
export const RemoveCollaboratorsDocument = gql`
    mutation RemoveCollaborators($docId: ID!, $collaborators: [DocCollaboratorRemovalInput!]!) {
  removeCollaborators(docId: $docId, collaborators: $collaborators) {
    id
    collaborators {
      ...DocCollaboratorFields
    }
    invitations {
      ...DocInvitationsFields
    }
  }
}
    ${DocCollaboratorFieldsFragmentDoc}
${DocInvitationsFieldsFragmentDoc}`;
export type RemoveCollaboratorsMutationFn = Apollo.MutationFunction<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>;

/**
 * __useRemoveCollaboratorsMutation__
 *
 * To run a mutation, you first call `useRemoveCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollaboratorsMutation, { data, loading, error }] = useRemoveCollaboratorsMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *      collaborators: // value for 'collaborators'
 *   },
 * });
 */
export function useRemoveCollaboratorsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>(RemoveCollaboratorsDocument, options);
      }
export type RemoveCollaboratorsMutationHookResult = ReturnType<typeof useRemoveCollaboratorsMutation>;
export type RemoveCollaboratorsMutationResult = Apollo.MutationResult<RemoveCollaboratorsMutation>;
export type RemoveCollaboratorsMutationOptions = Apollo.BaseMutationOptions<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>;
export const AddCollaboratorByAccessLinkDocument = gql`
    mutation AddCollaboratorByAccessLink($docId: ID!) {
  addCollaboratorByAccessLink(docId: $docId) {
    id
    collaborators {
      ...DocCollaboratorFields
    }
  }
}
    ${DocCollaboratorFieldsFragmentDoc}`;
export type AddCollaboratorByAccessLinkMutationFn = Apollo.MutationFunction<AddCollaboratorByAccessLinkMutation, AddCollaboratorByAccessLinkMutationVariables>;

/**
 * __useAddCollaboratorByAccessLinkMutation__
 *
 * To run a mutation, you first call `useAddCollaboratorByAccessLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaboratorByAccessLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaboratorByAccessLinkMutation, { data, loading, error }] = useAddCollaboratorByAccessLinkMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useAddCollaboratorByAccessLinkMutation(baseOptions?: Apollo.MutationHookOptions<AddCollaboratorByAccessLinkMutation, AddCollaboratorByAccessLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCollaboratorByAccessLinkMutation, AddCollaboratorByAccessLinkMutationVariables>(AddCollaboratorByAccessLinkDocument, options);
      }
export type AddCollaboratorByAccessLinkMutationHookResult = ReturnType<typeof useAddCollaboratorByAccessLinkMutation>;
export type AddCollaboratorByAccessLinkMutationResult = Apollo.MutationResult<AddCollaboratorByAccessLinkMutation>;
export type AddCollaboratorByAccessLinkMutationOptions = Apollo.BaseMutationOptions<AddCollaboratorByAccessLinkMutation, AddCollaboratorByAccessLinkMutationVariables>;
export const GetDocDocument = gql`
    query GetDoc($id: ID!) {
  doc(id: $id) {
    id
    title
    titleEdited
    archived
    createdTime
    updatedTime
    editedTime
    savedTime
    currentSnapshotId
    publicAccess
    comments {
      ...CommentFields
    }
    reactions {
      ...ReactionFields
    }
    accessLinks {
      id
      docId
      permission
      token
      url
    }
    orgAccess
    organization {
      id
      name
    }
    theme {
      ...DefaultThemeFields
    }
    collaborators {
      ...DocCollaboratorFields
    }
    invitations {
      ...DocInvitationsFields
    }
    editors {
      ...DocEditorsFields
    }
    createdBy {
      displayName
      id
      email
    }
    channels {
      ...DocChannelFields
    }
  }
}
    ${CommentFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}
${DefaultThemeFieldsFragmentDoc}
${DocCollaboratorFieldsFragmentDoc}
${DocInvitationsFieldsFragmentDoc}
${DocEditorsFieldsFragmentDoc}
${DocChannelFieldsFragmentDoc}`;

/**
 * __useGetDocQuery__
 *
 * To run a query within a React component, call `useGetDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocQuery(baseOptions: Apollo.QueryHookOptions<GetDocQuery, GetDocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocQuery, GetDocQueryVariables>(GetDocDocument, options);
      }
export function useGetDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocQuery, GetDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocQuery, GetDocQueryVariables>(GetDocDocument, options);
        }
export type GetDocQueryHookResult = ReturnType<typeof useGetDocQuery>;
export type GetDocLazyQueryHookResult = ReturnType<typeof useGetDocLazyQuery>;
export type GetDocQueryResult = Apollo.QueryResult<GetDocQuery, GetDocQueryVariables>;
export const GetDocRobotsDocument = gql`
    query GetDocRobots($id: ID!) {
  doc(id: $id) {
    id
    title
    createdTime
    updatedTime
    currentSnapshot {
      id
      docId
      content
    }
    titleCard {
      previewUrl
    }
    organization {
      id
      name
    }
    createdBy {
      displayName
      id
      email
    }
  }
}
    `;

/**
 * __useGetDocRobotsQuery__
 *
 * To run a query within a React component, call `useGetDocRobotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocRobotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocRobotsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocRobotsQuery(baseOptions: Apollo.QueryHookOptions<GetDocRobotsQuery, GetDocRobotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocRobotsQuery, GetDocRobotsQueryVariables>(GetDocRobotsDocument, options);
      }
export function useGetDocRobotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocRobotsQuery, GetDocRobotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocRobotsQuery, GetDocRobotsQueryVariables>(GetDocRobotsDocument, options);
        }
export type GetDocRobotsQueryHookResult = ReturnType<typeof useGetDocRobotsQuery>;
export type GetDocRobotsLazyQueryHookResult = ReturnType<typeof useGetDocRobotsLazyQuery>;
export type GetDocRobotsQueryResult = Apollo.QueryResult<GetDocRobotsQuery, GetDocRobotsQueryVariables>;
export const SubscribeToDocDocument = gql`
    subscription SubscribeToDoc($id: ID!) {
  doc(id: $id) {
    ... on DocSubscription {
      id
      title
      titleEdited
      archived
      updatedTime
      editedTime
      savedTime
      accessLinks {
        id
        docId
        permission
        token
        url
      }
      channels {
        ...DocChannelFields
      }
      collaborators {
        ...DocCollaboratorFields
      }
      editors {
        ...DocEditorsFields
      }
      invitations {
        ...DocInvitationsFields
      }
      theme {
        ...DefaultThemeFields
      }
      reactions {
        ...ReactionFields
      }
      orgAccess
    }
    ... on DocCommentsSubscription {
      id
      comments {
        ...CommentFields
      }
    }
    ... on DocReactionsSubscription {
      id
      reactions {
        ...ReactionFields
      }
    }
  }
}
    ${DocChannelFieldsFragmentDoc}
${DocCollaboratorFieldsFragmentDoc}
${DocEditorsFieldsFragmentDoc}
${DocInvitationsFieldsFragmentDoc}
${DefaultThemeFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}
${CommentFieldsFragmentDoc}`;

/**
 * __useSubscribeToDocSubscription__
 *
 * To run a query within a React component, call `useSubscribeToDocSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToDocSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToDocSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeToDocSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToDocSubscription, SubscribeToDocSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToDocSubscription, SubscribeToDocSubscriptionVariables>(SubscribeToDocDocument, options);
      }
export type SubscribeToDocSubscriptionHookResult = ReturnType<typeof useSubscribeToDocSubscription>;
export type SubscribeToDocSubscriptionResult = Apollo.SubscriptionResult<SubscribeToDocSubscription>;
export const UpdateDocTitleDocument = gql`
    mutation UpdateDocTitle($id: ID!, $title: String, $titleEdited: Boolean) {
  updateDoc(input: {id: $id, title: $title, titleEdited: $titleEdited}) {
    id
    title
    titleEdited
  }
}
    `;
export type UpdateDocTitleMutationFn = Apollo.MutationFunction<UpdateDocTitleMutation, UpdateDocTitleMutationVariables>;

/**
 * __useUpdateDocTitleMutation__
 *
 * To run a mutation, you first call `useUpdateDocTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocTitleMutation, { data, loading, error }] = useUpdateDocTitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      titleEdited: // value for 'titleEdited'
 *   },
 * });
 */
export function useUpdateDocTitleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocTitleMutation, UpdateDocTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocTitleMutation, UpdateDocTitleMutationVariables>(UpdateDocTitleDocument, options);
      }
export type UpdateDocTitleMutationHookResult = ReturnType<typeof useUpdateDocTitleMutation>;
export type UpdateDocTitleMutationResult = Apollo.MutationResult<UpdateDocTitleMutation>;
export type UpdateDocTitleMutationOptions = Apollo.BaseMutationOptions<UpdateDocTitleMutation, UpdateDocTitleMutationVariables>;
export const UpdateDocOrgAccessDocument = gql`
    mutation UpdateDocOrgAccess($id: ID!, $orgAccess: Permission) {
  updateDoc(input: {id: $id, orgAccess: $orgAccess}) {
    id
    orgAccess
  }
}
    `;
export type UpdateDocOrgAccessMutationFn = Apollo.MutationFunction<UpdateDocOrgAccessMutation, UpdateDocOrgAccessMutationVariables>;

/**
 * __useUpdateDocOrgAccessMutation__
 *
 * To run a mutation, you first call `useUpdateDocOrgAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocOrgAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocOrgAccessMutation, { data, loading, error }] = useUpdateDocOrgAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orgAccess: // value for 'orgAccess'
 *   },
 * });
 */
export function useUpdateDocOrgAccessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocOrgAccessMutation, UpdateDocOrgAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocOrgAccessMutation, UpdateDocOrgAccessMutationVariables>(UpdateDocOrgAccessDocument, options);
      }
export type UpdateDocOrgAccessMutationHookResult = ReturnType<typeof useUpdateDocOrgAccessMutation>;
export type UpdateDocOrgAccessMutationResult = Apollo.MutationResult<UpdateDocOrgAccessMutation>;
export type UpdateDocOrgAccessMutationOptions = Apollo.BaseMutationOptions<UpdateDocOrgAccessMutation, UpdateDocOrgAccessMutationVariables>;
export const UpdateDocPublicAccessDocument = gql`
    mutation UpdateDocPublicAccess($id: ID!, $publicAccess: Permission) {
  updateDoc(input: {id: $id, publicAccess: $publicAccess}) {
    id
    publicAccess
  }
}
    `;
export type UpdateDocPublicAccessMutationFn = Apollo.MutationFunction<UpdateDocPublicAccessMutation, UpdateDocPublicAccessMutationVariables>;

/**
 * __useUpdateDocPublicAccessMutation__
 *
 * To run a mutation, you first call `useUpdateDocPublicAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocPublicAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocPublicAccessMutation, { data, loading, error }] = useUpdateDocPublicAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      publicAccess: // value for 'publicAccess'
 *   },
 * });
 */
export function useUpdateDocPublicAccessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocPublicAccessMutation, UpdateDocPublicAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocPublicAccessMutation, UpdateDocPublicAccessMutationVariables>(UpdateDocPublicAccessDocument, options);
      }
export type UpdateDocPublicAccessMutationHookResult = ReturnType<typeof useUpdateDocPublicAccessMutation>;
export type UpdateDocPublicAccessMutationResult = Apollo.MutationResult<UpdateDocPublicAccessMutation>;
export type UpdateDocPublicAccessMutationOptions = Apollo.BaseMutationOptions<UpdateDocPublicAccessMutation, UpdateDocPublicAccessMutationVariables>;
export const UpdateDocAccessLinksDocument = gql`
    mutation UpdateDocAccessLinks($id: ID!, $accessLinks: [AccessLinkInput!]) {
  updateDoc(input: {id: $id, accessLinks: $accessLinks}) {
    id
    accessLinks {
      permission
      id
    }
  }
}
    `;
export type UpdateDocAccessLinksMutationFn = Apollo.MutationFunction<UpdateDocAccessLinksMutation, UpdateDocAccessLinksMutationVariables>;

/**
 * __useUpdateDocAccessLinksMutation__
 *
 * To run a mutation, you first call `useUpdateDocAccessLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocAccessLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocAccessLinksMutation, { data, loading, error }] = useUpdateDocAccessLinksMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accessLinks: // value for 'accessLinks'
 *   },
 * });
 */
export function useUpdateDocAccessLinksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocAccessLinksMutation, UpdateDocAccessLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocAccessLinksMutation, UpdateDocAccessLinksMutationVariables>(UpdateDocAccessLinksDocument, options);
      }
export type UpdateDocAccessLinksMutationHookResult = ReturnType<typeof useUpdateDocAccessLinksMutation>;
export type UpdateDocAccessLinksMutationResult = Apollo.MutationResult<UpdateDocAccessLinksMutation>;
export type UpdateDocAccessLinksMutationOptions = Apollo.BaseMutationOptions<UpdateDocAccessLinksMutation, UpdateDocAccessLinksMutationVariables>;
export const UpdateDocThemeDocument = gql`
    mutation UpdateDocTheme($id: ID!, $themeId: String) {
  updateDoc(input: {id: $id, themeId: $themeId}) {
    id
    theme {
      ...DefaultThemeFields
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type UpdateDocThemeMutationFn = Apollo.MutationFunction<UpdateDocThemeMutation, UpdateDocThemeMutationVariables>;

/**
 * __useUpdateDocThemeMutation__
 *
 * To run a mutation, you first call `useUpdateDocThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocThemeMutation, { data, loading, error }] = useUpdateDocThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useUpdateDocThemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocThemeMutation, UpdateDocThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocThemeMutation, UpdateDocThemeMutationVariables>(UpdateDocThemeDocument, options);
      }
export type UpdateDocThemeMutationHookResult = ReturnType<typeof useUpdateDocThemeMutation>;
export type UpdateDocThemeMutationResult = Apollo.MutationResult<UpdateDocThemeMutation>;
export type UpdateDocThemeMutationOptions = Apollo.BaseMutationOptions<UpdateDocThemeMutation, UpdateDocThemeMutationVariables>;
export const InviteMembersDocument = gql`
    mutation InviteMembers($workspaceId: ID!, $invitees: [InvitationInput!]!) {
  inviteMembers(orgId: $workspaceId, invitees: $invitees) {
    id
    name
    invitations {
      email
    }
  }
}
    `;
export type InviteMembersMutationFn = Apollo.MutationFunction<InviteMembersMutation, InviteMembersMutationVariables>;

/**
 * __useInviteMembersMutation__
 *
 * To run a mutation, you first call `useInviteMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMembersMutation, { data, loading, error }] = useInviteMembersMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      invitees: // value for 'invitees'
 *   },
 * });
 */
export function useInviteMembersMutation(baseOptions?: Apollo.MutationHookOptions<InviteMembersMutation, InviteMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteMembersMutation, InviteMembersMutationVariables>(InviteMembersDocument, options);
      }
export type InviteMembersMutationHookResult = ReturnType<typeof useInviteMembersMutation>;
export type InviteMembersMutationResult = Apollo.MutationResult<InviteMembersMutation>;
export type InviteMembersMutationOptions = Apollo.BaseMutationOptions<InviteMembersMutation, InviteMembersMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($input: CreateCommentInput) {
  createComment(input: $input) {
    id
    docId
    cardId
    content
    targetText
    targetHtml
    createdTime
    updatedTime
    commentId
    archived
    targetId
    status
    user {
      ...CommentAuthorFields
    }
    replies {
      ...ReplyFields
    }
    reactions {
      ...ReactionFields
    }
  }
}
    ${CommentAuthorFieldsFragmentDoc}
${ReplyFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    id
    docId
    content
    updatedTime
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const UpdateCommentStatusDocument = gql`
    mutation UpdateCommentStatus($input: UpdateCommentStatusInput!) {
  updateCommentStatus(input: $input) {
    id
    status
    updatedTime
  }
}
    `;
export type UpdateCommentStatusMutationFn = Apollo.MutationFunction<UpdateCommentStatusMutation, UpdateCommentStatusMutationVariables>;

/**
 * __useUpdateCommentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCommentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentStatusMutation, { data, loading, error }] = useUpdateCommentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentStatusMutation, UpdateCommentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentStatusMutation, UpdateCommentStatusMutationVariables>(UpdateCommentStatusDocument, options);
      }
export type UpdateCommentStatusMutationHookResult = ReturnType<typeof useUpdateCommentStatusMutation>;
export type UpdateCommentStatusMutationResult = Apollo.MutationResult<UpdateCommentStatusMutation>;
export type UpdateCommentStatusMutationOptions = Apollo.BaseMutationOptions<UpdateCommentStatusMutation, UpdateCommentStatusMutationVariables>;
export const RemoveCommentDocument = gql`
    mutation RemoveComment($id: ID!) {
  archiveComment(id: $id) {
    id
    archived
  }
}
    `;
export type RemoveCommentMutationFn = Apollo.MutationFunction<RemoveCommentMutation, RemoveCommentMutationVariables>;

/**
 * __useRemoveCommentMutation__
 *
 * To run a mutation, you first call `useRemoveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentMutation, { data, loading, error }] = useRemoveCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCommentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCommentMutation, RemoveCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCommentMutation, RemoveCommentMutationVariables>(RemoveCommentDocument, options);
      }
export type RemoveCommentMutationHookResult = ReturnType<typeof useRemoveCommentMutation>;
export type RemoveCommentMutationResult = Apollo.MutationResult<RemoveCommentMutation>;
export type RemoveCommentMutationOptions = Apollo.BaseMutationOptions<RemoveCommentMutation, RemoveCommentMutationVariables>;
export const AddReactionDocument = gql`
    mutation AddReaction($reactionInput: AddReactionInput!) {
  addReaction(input: $reactionInput) {
    ...ReactionFields
  }
}
    ${ReactionFieldsFragmentDoc}`;
export type AddReactionMutationFn = Apollo.MutationFunction<AddReactionMutation, AddReactionMutationVariables>;

/**
 * __useAddReactionMutation__
 *
 * To run a mutation, you first call `useAddReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReactionMutation, { data, loading, error }] = useAddReactionMutation({
 *   variables: {
 *      reactionInput: // value for 'reactionInput'
 *   },
 * });
 */
export function useAddReactionMutation(baseOptions?: Apollo.MutationHookOptions<AddReactionMutation, AddReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReactionMutation, AddReactionMutationVariables>(AddReactionDocument, options);
      }
export type AddReactionMutationHookResult = ReturnType<typeof useAddReactionMutation>;
export type AddReactionMutationResult = Apollo.MutationResult<AddReactionMutation>;
export type AddReactionMutationOptions = Apollo.BaseMutationOptions<AddReactionMutation, AddReactionMutationVariables>;
export const RemoveReactionDocument = gql`
    mutation RemoveReaction($reactionInput: RemoveReactionInput!) {
  removeReaction(input: $reactionInput) {
    ...ReactionFields
  }
}
    ${ReactionFieldsFragmentDoc}`;
export type RemoveReactionMutationFn = Apollo.MutationFunction<RemoveReactionMutation, RemoveReactionMutationVariables>;

/**
 * __useRemoveReactionMutation__
 *
 * To run a mutation, you first call `useRemoveReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReactionMutation, { data, loading, error }] = useRemoveReactionMutation({
 *   variables: {
 *      reactionInput: // value for 'reactionInput'
 *   },
 * });
 */
export function useRemoveReactionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReactionMutation, RemoveReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveReactionMutation, RemoveReactionMutationVariables>(RemoveReactionDocument, options);
      }
export type RemoveReactionMutationHookResult = ReturnType<typeof useRemoveReactionMutation>;
export type RemoveReactionMutationResult = Apollo.MutationResult<RemoveReactionMutation>;
export type RemoveReactionMutationOptions = Apollo.BaseMutationOptions<RemoveReactionMutation, RemoveReactionMutationVariables>;
export const GetMemoAnalyticsDocument = gql`
    query GetMemoAnalytics($memoId: ID!) {
  memoStats(id: $memoId) {
    memoId
    uniqueViewers
    uniqueEditors
    cardCount
    lastOpened
    viewers {
      user {
        id
        displayName
        email
        profileImageUrl
      }
      visitorId
      lastOpened
      cardsViewed
    }
    dailyViews {
      dayCount
      timezone
      everyone {
        date
        uniqueVisitors
      }
      viewers {
        date
        uniqueVisitors
      }
    }
  }
}
    `;

/**
 * __useGetMemoAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetMemoAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemoAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemoAnalyticsQuery({
 *   variables: {
 *      memoId: // value for 'memoId'
 *   },
 * });
 */
export function useGetMemoAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>(GetMemoAnalyticsDocument, options);
      }
export function useGetMemoAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>(GetMemoAnalyticsDocument, options);
        }
export type GetMemoAnalyticsQueryHookResult = ReturnType<typeof useGetMemoAnalyticsQuery>;
export type GetMemoAnalyticsLazyQueryHookResult = ReturnType<typeof useGetMemoAnalyticsLazyQuery>;
export type GetMemoAnalyticsQueryResult = Apollo.QueryResult<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>;
export const GetDocCardsAnalyticsDocument = gql`
    query GetDocCardsAnalytics($docId: ID!, $userId: ID) {
  docCardsStats(docId: $docId, userId: $userId) {
    docId
    uniqueVisitors
    uniqueEditors
    cardCount
    everyone {
      cardId
      viewTimePercent
      viewersPercent
    }
    viewers {
      cardId
      viewTimePercent
      viewersPercent
    }
  }
}
    `;

/**
 * __useGetDocCardsAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetDocCardsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocCardsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocCardsAnalyticsQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDocCardsAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>(GetDocCardsAnalyticsDocument, options);
      }
export function useGetDocCardsAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>(GetDocCardsAnalyticsDocument, options);
        }
export type GetDocCardsAnalyticsQueryHookResult = ReturnType<typeof useGetDocCardsAnalyticsQuery>;
export type GetDocCardsAnalyticsLazyQueryHookResult = ReturnType<typeof useGetDocCardsAnalyticsLazyQuery>;
export type GetDocCardsAnalyticsQueryResult = Apollo.QueryResult<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>;
export const CreateDocDocument = gql`
    mutation CreateDoc($workspaceId: ID!, $title: String, $initialContent: BaseSnapshotInput, $channels: [DocChannelInput!]) {
  createDoc(
    input: {orgId: $workspaceId, title: $title, initialContent: $initialContent, channels: $channels}
  ) {
    id
  }
}
    `;
export type CreateDocMutationFn = Apollo.MutationFunction<CreateDocMutation, CreateDocMutationVariables>;

/**
 * __useCreateDocMutation__
 *
 * To run a mutation, you first call `useCreateDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocMutation, { data, loading, error }] = useCreateDocMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      title: // value for 'title'
 *      initialContent: // value for 'initialContent'
 *      channels: // value for 'channels'
 *   },
 * });
 */
export function useCreateDocMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocMutation, CreateDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocMutation, CreateDocMutationVariables>(CreateDocDocument, options);
      }
export type CreateDocMutationHookResult = ReturnType<typeof useCreateDocMutation>;
export type CreateDocMutationResult = Apollo.MutationResult<CreateDocMutation>;
export type CreateDocMutationOptions = Apollo.BaseMutationOptions<CreateDocMutation, CreateDocMutationVariables>;
export const ArchiveDocDocument = gql`
    mutation ArchiveDoc($id: ID!) {
  archiveDoc(id: $id) {
    id
    archived
  }
}
    `;
export type ArchiveDocMutationFn = Apollo.MutationFunction<ArchiveDocMutation, ArchiveDocMutationVariables>;

/**
 * __useArchiveDocMutation__
 *
 * To run a mutation, you first call `useArchiveDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveDocMutation, { data, loading, error }] = useArchiveDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveDocMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveDocMutation, ArchiveDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveDocMutation, ArchiveDocMutationVariables>(ArchiveDocDocument, options);
      }
export type ArchiveDocMutationHookResult = ReturnType<typeof useArchiveDocMutation>;
export type ArchiveDocMutationResult = Apollo.MutationResult<ArchiveDocMutation>;
export type ArchiveDocMutationOptions = Apollo.BaseMutationOptions<ArchiveDocMutation, ArchiveDocMutationVariables>;
export const UnArchiveDocDocument = gql`
    mutation UnArchiveDoc($id: ID!) {
  unarchiveDoc(id: $id) {
    id
    archived
  }
}
    `;
export type UnArchiveDocMutationFn = Apollo.MutationFunction<UnArchiveDocMutation, UnArchiveDocMutationVariables>;

/**
 * __useUnArchiveDocMutation__
 *
 * To run a mutation, you first call `useUnArchiveDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnArchiveDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unArchiveDocMutation, { data, loading, error }] = useUnArchiveDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnArchiveDocMutation(baseOptions?: Apollo.MutationHookOptions<UnArchiveDocMutation, UnArchiveDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnArchiveDocMutation, UnArchiveDocMutationVariables>(UnArchiveDocDocument, options);
      }
export type UnArchiveDocMutationHookResult = ReturnType<typeof useUnArchiveDocMutation>;
export type UnArchiveDocMutationResult = Apollo.MutationResult<UnArchiveDocMutation>;
export type UnArchiveDocMutationOptions = Apollo.BaseMutationOptions<UnArchiveDocMutation, UnArchiveDocMutationVariables>;
export const FavoriteDocDocument = gql`
    mutation FavoriteDoc($input: DocUserInput!) {
  updateDocUser(input: $input) {
    id
    docId
    lastViewed
    lastEdited
    favorited
  }
}
    `;
export type FavoriteDocMutationFn = Apollo.MutationFunction<FavoriteDocMutation, FavoriteDocMutationVariables>;

/**
 * __useFavoriteDocMutation__
 *
 * To run a mutation, you first call `useFavoriteDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteDocMutation, { data, loading, error }] = useFavoriteDocMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFavoriteDocMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteDocMutation, FavoriteDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FavoriteDocMutation, FavoriteDocMutationVariables>(FavoriteDocDocument, options);
      }
export type FavoriteDocMutationHookResult = ReturnType<typeof useFavoriteDocMutation>;
export type FavoriteDocMutationResult = Apollo.MutationResult<FavoriteDocMutation>;
export type FavoriteDocMutationOptions = Apollo.BaseMutationOptions<FavoriteDocMutation, FavoriteDocMutationVariables>;
export const GetPreviewChannelDocsDocument = gql`
    query GetPreviewChannelDocs($first: Int, $after: String, $channelId: ID!) {
  previewChannelDocs(first: $first, after: $after, channelId: $channelId) {
    edges {
      node {
        ...HomeDocFieldsFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${HomeDocFieldsFragmentFragmentDoc}`;

/**
 * __useGetPreviewChannelDocsQuery__
 *
 * To run a query within a React component, call `useGetPreviewChannelDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewChannelDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewChannelDocsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetPreviewChannelDocsQuery(baseOptions: Apollo.QueryHookOptions<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>(GetPreviewChannelDocsDocument, options);
      }
export function useGetPreviewChannelDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>(GetPreviewChannelDocsDocument, options);
        }
export type GetPreviewChannelDocsQueryHookResult = ReturnType<typeof useGetPreviewChannelDocsQuery>;
export type GetPreviewChannelDocsLazyQueryHookResult = ReturnType<typeof useGetPreviewChannelDocsLazyQuery>;
export type GetPreviewChannelDocsQueryResult = Apollo.QueryResult<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>;
export const GetChannelDocActivityDocument = gql`
    query GetChannelDocActivity($first: Int, $channelId: ID!) {
  channelDocActivity(first: $first, channelId: $channelId) {
    edges {
      node {
        editors {
          id
          displayName
          profileImageUrl
        }
        presenters {
          id
          displayName
          profileImageUrl
        }
        doc {
          ...HomeDocFieldsFragment
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${HomeDocFieldsFragmentFragmentDoc}`;

/**
 * __useGetChannelDocActivityQuery__
 *
 * To run a query within a React component, call `useGetChannelDocActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelDocActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelDocActivityQuery({
 *   variables: {
 *      first: // value for 'first'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChannelDocActivityQuery(baseOptions: Apollo.QueryHookOptions<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>(GetChannelDocActivityDocument, options);
      }
export function useGetChannelDocActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>(GetChannelDocActivityDocument, options);
        }
export type GetChannelDocActivityQueryHookResult = ReturnType<typeof useGetChannelDocActivityQuery>;
export type GetChannelDocActivityLazyQueryHookResult = ReturnType<typeof useGetChannelDocActivityLazyQuery>;
export type GetChannelDocActivityQueryResult = Apollo.QueryResult<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>;
export const GetDocsDocument = gql`
    query GetDocs($first: Int, $after: String, $workspaceId: ID, $createdBy: ID, $archived: Boolean, $sortBy: DocSortOrder, $isPrivate: Boolean, $channelId: ID) {
  docs(
    first: $first
    after: $after
    workspaceId: $workspaceId
    createdBy: $createdBy
    archived: $archived
    sortBy: $sortBy
    isPrivate: $isPrivate
    channelId: $channelId
  ) {
    edges {
      node {
        ...HomeDocFieldsFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${HomeDocFieldsFragmentFragmentDoc}`;

/**
 * __useGetDocsQuery__
 *
 * To run a query within a React component, call `useGetDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      workspaceId: // value for 'workspaceId'
 *      createdBy: // value for 'createdBy'
 *      archived: // value for 'archived'
 *      sortBy: // value for 'sortBy'
 *      isPrivate: // value for 'isPrivate'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetDocsQuery(baseOptions?: Apollo.QueryHookOptions<GetDocsQuery, GetDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocsQuery, GetDocsQueryVariables>(GetDocsDocument, options);
      }
export function useGetDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocsQuery, GetDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocsQuery, GetDocsQueryVariables>(GetDocsDocument, options);
        }
export type GetDocsQueryHookResult = ReturnType<typeof useGetDocsQuery>;
export type GetDocsLazyQueryHookResult = ReturnType<typeof useGetDocsLazyQuery>;
export type GetDocsQueryResult = Apollo.QueryResult<GetDocsQuery, GetDocsQueryVariables>;
export const GetSnapshotsDocument = gql`
    query GetSnapshots($docId: ID!, $first: Int, $after: String) {
  snapshots(docId: $docId, first: $first, after: $after) {
    edges {
      node {
        id
        docId
        createdTime
        editors {
          displayName
          id
        }
        schemaVersion
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetSnapshotsQuery__
 *
 * To run a query within a React component, call `useGetSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSnapshotsQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetSnapshotsQuery(baseOptions: Apollo.QueryHookOptions<GetSnapshotsQuery, GetSnapshotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSnapshotsQuery, GetSnapshotsQueryVariables>(GetSnapshotsDocument, options);
      }
export function useGetSnapshotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSnapshotsQuery, GetSnapshotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSnapshotsQuery, GetSnapshotsQueryVariables>(GetSnapshotsDocument, options);
        }
export type GetSnapshotsQueryHookResult = ReturnType<typeof useGetSnapshotsQuery>;
export type GetSnapshotsLazyQueryHookResult = ReturnType<typeof useGetSnapshotsLazyQuery>;
export type GetSnapshotsQueryResult = Apollo.QueryResult<GetSnapshotsQuery, GetSnapshotsQueryVariables>;
export const DuplicateDocDocument = gql`
    mutation DuplicateDoc($duplicateDocInput: DuplicateDocInput!) {
  duplicateDoc(input: $duplicateDocInput) {
    id
    title
  }
}
    `;
export type DuplicateDocMutationFn = Apollo.MutationFunction<DuplicateDocMutation, DuplicateDocMutationVariables>;

/**
 * __useDuplicateDocMutation__
 *
 * To run a mutation, you first call `useDuplicateDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateDocMutation, { data, loading, error }] = useDuplicateDocMutation({
 *   variables: {
 *      duplicateDocInput: // value for 'duplicateDocInput'
 *   },
 * });
 */
export function useDuplicateDocMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateDocMutation, DuplicateDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateDocMutation, DuplicateDocMutationVariables>(DuplicateDocDocument, options);
      }
export type DuplicateDocMutationHookResult = ReturnType<typeof useDuplicateDocMutation>;
export type DuplicateDocMutationResult = Apollo.MutationResult<DuplicateDocMutation>;
export type DuplicateDocMutationOptions = Apollo.BaseMutationOptions<DuplicateDocMutation, DuplicateDocMutationVariables>;
export const GetSnapshotDocument = gql`
    query GetSnapshot($snapshotId: ID!, $docId: ID!) {
  snapshot(id: $snapshotId, docId: $docId) {
    id
    content
    schemaVersion
  }
}
    `;

/**
 * __useGetSnapshotQuery__
 *
 * To run a query within a React component, call `useGetSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSnapshotQuery({
 *   variables: {
 *      snapshotId: // value for 'snapshotId'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useGetSnapshotQuery(baseOptions: Apollo.QueryHookOptions<GetSnapshotQuery, GetSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSnapshotQuery, GetSnapshotQueryVariables>(GetSnapshotDocument, options);
      }
export function useGetSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSnapshotQuery, GetSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSnapshotQuery, GetSnapshotQueryVariables>(GetSnapshotDocument, options);
        }
export type GetSnapshotQueryHookResult = ReturnType<typeof useGetSnapshotQuery>;
export type GetSnapshotLazyQueryHookResult = ReturnType<typeof useGetSnapshotLazyQuery>;
export type GetSnapshotQueryResult = Apollo.QueryResult<GetSnapshotQuery, GetSnapshotQueryVariables>;
export const GetDocForSnapshotViewerDocument = gql`
    query GetDocForSnapshotViewer($id: ID!) {
  doc(id: $id) {
    id
    currentSnapshotId
    createdTime
    title
    createdBy {
      profileImageUrl
      displayName
      id
    }
  }
}
    `;

/**
 * __useGetDocForSnapshotViewerQuery__
 *
 * To run a query within a React component, call `useGetDocForSnapshotViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocForSnapshotViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocForSnapshotViewerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocForSnapshotViewerQuery(baseOptions: Apollo.QueryHookOptions<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>(GetDocForSnapshotViewerDocument, options);
      }
export function useGetDocForSnapshotViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>(GetDocForSnapshotViewerDocument, options);
        }
export type GetDocForSnapshotViewerQueryHookResult = ReturnType<typeof useGetDocForSnapshotViewerQuery>;
export type GetDocForSnapshotViewerLazyQueryHookResult = ReturnType<typeof useGetDocForSnapshotViewerLazyQuery>;
export type GetDocForSnapshotViewerQueryResult = Apollo.QueryResult<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>;
export const ChannelActivityDocument = gql`
    subscription ChannelActivity($channelId: ID!) {
  channelDocActivity(channelId: $channelId) {
    editors {
      id
      displayName
      profileImageUrl
    }
    presenters {
      id
      displayName
      profileImageUrl
    }
    doc {
      ...HomeDocFieldsFragment
    }
  }
}
    ${HomeDocFieldsFragmentFragmentDoc}`;

/**
 * __useChannelActivitySubscription__
 *
 * To run a query within a React component, call `useChannelActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useChannelActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelActivitySubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useChannelActivitySubscription(baseOptions: Apollo.SubscriptionHookOptions<ChannelActivitySubscription, ChannelActivitySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChannelActivitySubscription, ChannelActivitySubscriptionVariables>(ChannelActivityDocument, options);
      }
export type ChannelActivitySubscriptionHookResult = ReturnType<typeof useChannelActivitySubscription>;
export type ChannelActivitySubscriptionResult = Apollo.SubscriptionResult<ChannelActivitySubscription>;
export const GetChannelsDocument = gql`
    query GetChannels($workspaceId: ID!) {
  channels(workspaceId: $workspaceId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useGetChannelsQuery__
 *
 * To run a query within a React component, call `useGetChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetChannelsQuery(baseOptions: Apollo.QueryHookOptions<GetChannelsQuery, GetChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelsQuery, GetChannelsQueryVariables>(GetChannelsDocument, options);
      }
export function useGetChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelsQuery, GetChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelsQuery, GetChannelsQueryVariables>(GetChannelsDocument, options);
        }
export type GetChannelsQueryHookResult = ReturnType<typeof useGetChannelsQuery>;
export type GetChannelsLazyQueryHookResult = ReturnType<typeof useGetChannelsLazyQuery>;
export type GetChannelsQueryResult = Apollo.QueryResult<GetChannelsQuery, GetChannelsQueryVariables>;
export const GetChannelDocument = gql`
    query GetChannel($id: ID!, $workspaceId: ID!) {
  channel(id: $id, workspaceId: $workspaceId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useGetChannelQuery__
 *
 * To run a query within a React component, call `useGetChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelQuery({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetChannelQuery(baseOptions: Apollo.QueryHookOptions<GetChannelQuery, GetChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelQuery, GetChannelQueryVariables>(GetChannelDocument, options);
      }
export function useGetChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelQuery, GetChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelQuery, GetChannelQueryVariables>(GetChannelDocument, options);
        }
export type GetChannelQueryHookResult = ReturnType<typeof useGetChannelQuery>;
export type GetChannelLazyQueryHookResult = ReturnType<typeof useGetChannelLazyQuery>;
export type GetChannelQueryResult = Apollo.QueryResult<GetChannelQuery, GetChannelQueryVariables>;
export const GetWorkspaceChannelsDocument = gql`
    query GetWorkspaceChannels($workspaceId: ID!, $first: Int, $after: String, $query: String, $sortBy: ChannelSortOrder) {
  workspaceChannels(
    workspaceId: $workspaceId
    first: $first
    after: $after
    query: $query
    sortBy: $sortBy
  ) {
    edges {
      node {
        ...ChannelFieldsFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useGetWorkspaceChannelsQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceChannelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetWorkspaceChannelsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>(GetWorkspaceChannelsDocument, options);
      }
export function useGetWorkspaceChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>(GetWorkspaceChannelsDocument, options);
        }
export type GetWorkspaceChannelsQueryHookResult = ReturnType<typeof useGetWorkspaceChannelsQuery>;
export type GetWorkspaceChannelsLazyQueryHookResult = ReturnType<typeof useGetWorkspaceChannelsLazyQuery>;
export type GetWorkspaceChannelsQueryResult = Apollo.QueryResult<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>;
export const CreateChannelDocument = gql`
    mutation CreateChannel($input: CreateChannelInput!) {
  createChannel(input: $input) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type CreateChannelMutationFn = Apollo.MutationFunction<CreateChannelMutation, CreateChannelMutationVariables>;

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMutation, CreateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, options);
      }
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>;
export type CreateChannelMutationResult = Apollo.MutationResult<CreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<CreateChannelMutation, CreateChannelMutationVariables>;
export const JoinChannelDocument = gql`
    mutation JoinChannel($channelId: ID!) {
  joinChannel(channelId: $channelId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type JoinChannelMutationFn = Apollo.MutationFunction<JoinChannelMutation, JoinChannelMutationVariables>;

/**
 * __useJoinChannelMutation__
 *
 * To run a mutation, you first call `useJoinChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinChannelMutation, { data, loading, error }] = useJoinChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useJoinChannelMutation(baseOptions?: Apollo.MutationHookOptions<JoinChannelMutation, JoinChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinChannelMutation, JoinChannelMutationVariables>(JoinChannelDocument, options);
      }
export type JoinChannelMutationHookResult = ReturnType<typeof useJoinChannelMutation>;
export type JoinChannelMutationResult = Apollo.MutationResult<JoinChannelMutation>;
export type JoinChannelMutationOptions = Apollo.BaseMutationOptions<JoinChannelMutation, JoinChannelMutationVariables>;
export const LeaveChannelDocument = gql`
    mutation LeaveChannel($channelId: ID!) {
  leaveChannel(channelId: $channelId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type LeaveChannelMutationFn = Apollo.MutationFunction<LeaveChannelMutation, LeaveChannelMutationVariables>;

/**
 * __useLeaveChannelMutation__
 *
 * To run a mutation, you first call `useLeaveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveChannelMutation, { data, loading, error }] = useLeaveChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useLeaveChannelMutation(baseOptions?: Apollo.MutationHookOptions<LeaveChannelMutation, LeaveChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveChannelMutation, LeaveChannelMutationVariables>(LeaveChannelDocument, options);
      }
export type LeaveChannelMutationHookResult = ReturnType<typeof useLeaveChannelMutation>;
export type LeaveChannelMutationResult = Apollo.MutationResult<LeaveChannelMutation>;
export type LeaveChannelMutationOptions = Apollo.BaseMutationOptions<LeaveChannelMutation, LeaveChannelMutationVariables>;
export const ArchiveChannelDocument = gql`
    mutation ArchiveChannel($channelId: ID!, $workspaceId: ID!) {
  archiveChannel(id: $channelId, workspaceId: $workspaceId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type ArchiveChannelMutationFn = Apollo.MutationFunction<ArchiveChannelMutation, ArchiveChannelMutationVariables>;

/**
 * __useArchiveChannelMutation__
 *
 * To run a mutation, you first call `useArchiveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveChannelMutation, { data, loading, error }] = useArchiveChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useArchiveChannelMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveChannelMutation, ArchiveChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveChannelMutation, ArchiveChannelMutationVariables>(ArchiveChannelDocument, options);
      }
export type ArchiveChannelMutationHookResult = ReturnType<typeof useArchiveChannelMutation>;
export type ArchiveChannelMutationResult = Apollo.MutationResult<ArchiveChannelMutation>;
export type ArchiveChannelMutationOptions = Apollo.BaseMutationOptions<ArchiveChannelMutation, ArchiveChannelMutationVariables>;
export const UpdateChannelDocument = gql`
    mutation UpdateChannel($input: UpdateChannelInput) {
  updateChannel(input: $input) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type UpdateChannelMutationFn = Apollo.MutationFunction<UpdateChannelMutation, UpdateChannelMutationVariables>;

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMutation, UpdateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(UpdateChannelDocument, options);
      }
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>;
export type UpdateChannelMutationResult = Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMutation, UpdateChannelMutationVariables>;
export const SearchChannelsDocument = gql`
    query SearchChannels($workspaceId: ID, $query: String!) {
  search(orgId: $workspaceId, query: $query, types: [doc, channel]) {
    __typename
    ... on Channel {
      ...ChannelFieldsFragment
    }
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useSearchChannelsQuery__
 *
 * To run a query within a React component, call `useSearchChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChannelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchChannelsQuery(baseOptions: Apollo.QueryHookOptions<SearchChannelsQuery, SearchChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchChannelsQuery, SearchChannelsQueryVariables>(SearchChannelsDocument, options);
      }
export function useSearchChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchChannelsQuery, SearchChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchChannelsQuery, SearchChannelsQueryVariables>(SearchChannelsDocument, options);
        }
export type SearchChannelsQueryHookResult = ReturnType<typeof useSearchChannelsQuery>;
export type SearchChannelsLazyQueryHookResult = ReturnType<typeof useSearchChannelsLazyQuery>;
export type SearchChannelsQueryResult = Apollo.QueryResult<SearchChannelsQuery, SearchChannelsQueryVariables>;
export const ChannelsDocument = gql`
    subscription Channels($workspaceId: ID!) {
  channels(workspaceId: $workspaceId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useChannelsSubscription__
 *
 * To run a query within a React component, call `useChannelsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChannelsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useChannelsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChannelsSubscription, ChannelsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChannelsSubscription, ChannelsSubscriptionVariables>(ChannelsDocument, options);
      }
export type ChannelsSubscriptionHookResult = ReturnType<typeof useChannelsSubscription>;
export type ChannelsSubscriptionResult = Apollo.SubscriptionResult<ChannelsSubscription>;
export const GetCardPreviewUrlsForDocDocument = gql`
    query GetCardPreviewUrlsForDoc($docId: ID!, $maxDepth: Int, $archived: Boolean) {
  docCards(docId: $docId, maxDepth: $maxDepth, archived: $archived) {
    docId
    previewUrl
    position
    depth
    id
    archived
  }
}
    `;

/**
 * __useGetCardPreviewUrlsForDocQuery__
 *
 * To run a query within a React component, call `useGetCardPreviewUrlsForDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardPreviewUrlsForDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardPreviewUrlsForDocQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      maxDepth: // value for 'maxDepth'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useGetCardPreviewUrlsForDocQuery(baseOptions: Apollo.QueryHookOptions<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>(GetCardPreviewUrlsForDocDocument, options);
      }
export function useGetCardPreviewUrlsForDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>(GetCardPreviewUrlsForDocDocument, options);
        }
export type GetCardPreviewUrlsForDocQueryHookResult = ReturnType<typeof useGetCardPreviewUrlsForDocQuery>;
export type GetCardPreviewUrlsForDocLazyQueryHookResult = ReturnType<typeof useGetCardPreviewUrlsForDocLazyQuery>;
export type GetCardPreviewUrlsForDocQueryResult = Apollo.QueryResult<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>;
export const GetDocInvitationDocument = gql`
    query GetDocInvitation($docId: ID!, $email: String!, $inviteCode: String!) {
  docInvitation(docId: $docId, email: $email, inviteCode: $inviteCode) {
    docId
    docTitle
    email
    invitedBy {
      displayName
    }
    permission
  }
}
    `;

/**
 * __useGetDocInvitationQuery__
 *
 * To run a query within a React component, call `useGetDocInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocInvitationQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      email: // value for 'email'
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useGetDocInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetDocInvitationQuery, GetDocInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocInvitationQuery, GetDocInvitationQueryVariables>(GetDocInvitationDocument, options);
      }
export function useGetDocInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocInvitationQuery, GetDocInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocInvitationQuery, GetDocInvitationQueryVariables>(GetDocInvitationDocument, options);
        }
export type GetDocInvitationQueryHookResult = ReturnType<typeof useGetDocInvitationQuery>;
export type GetDocInvitationLazyQueryHookResult = ReturnType<typeof useGetDocInvitationLazyQuery>;
export type GetDocInvitationQueryResult = Apollo.QueryResult<GetDocInvitationQuery, GetDocInvitationQueryVariables>;
export const AcceptDocInvitationDocument = gql`
    mutation AcceptDocInvitation($docId: ID!, $email: String!, $inviteCode: String!) {
  acceptDocInvitation(docId: $docId, email: $email, inviteCode: $inviteCode) {
    id
  }
}
    `;
export type AcceptDocInvitationMutationFn = Apollo.MutationFunction<AcceptDocInvitationMutation, AcceptDocInvitationMutationVariables>;

/**
 * __useAcceptDocInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptDocInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptDocInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptDocInvitationMutation, { data, loading, error }] = useAcceptDocInvitationMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *      email: // value for 'email'
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useAcceptDocInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptDocInvitationMutation, AcceptDocInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptDocInvitationMutation, AcceptDocInvitationMutationVariables>(AcceptDocInvitationDocument, options);
      }
export type AcceptDocInvitationMutationHookResult = ReturnType<typeof useAcceptDocInvitationMutation>;
export type AcceptDocInvitationMutationResult = Apollo.MutationResult<AcceptDocInvitationMutation>;
export type AcceptDocInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptDocInvitationMutation, AcceptDocInvitationMutationVariables>;
export const GetWorkspaceInvitationDocument = gql`
    query GetWorkspaceInvitation($email: String!, $inviteCode: String!, $workspaceId: String) {
  workspaceInvitation(
    email: $email
    inviteCode: $inviteCode
    workspaceId: $workspaceId
  ) {
    email
    workspaceId
    workspaceName
    invitedBy {
      displayName
    }
  }
}
    `;

/**
 * __useGetWorkspaceInvitationQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceInvitationQuery({
 *   variables: {
 *      email: // value for 'email'
 *      inviteCode: // value for 'inviteCode'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetWorkspaceInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>(GetWorkspaceInvitationDocument, options);
      }
export function useGetWorkspaceInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>(GetWorkspaceInvitationDocument, options);
        }
export type GetWorkspaceInvitationQueryHookResult = ReturnType<typeof useGetWorkspaceInvitationQuery>;
export type GetWorkspaceInvitationLazyQueryHookResult = ReturnType<typeof useGetWorkspaceInvitationLazyQuery>;
export type GetWorkspaceInvitationQueryResult = Apollo.QueryResult<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>;