import {
  ySyncPluginKey,
  absolutePositionToRelativePosition,
  relativePositionToAbsolutePosition,
} from '@gamma-app/y-prosemirror'
import { Editor } from '@tiptap/core'
import { RelativePosition } from 'yjs'

import { commentNanoid } from 'utils/comments'

import { DraftCommentsPluginKey } from '../DraftCommentsExtension/DraftCommentsPlugin'
import { DraftCommentsState } from '../DraftCommentsExtension/DraftCommentsState'
import { DraftComment } from '../DraftCommentsExtension/types'
import { DraftCommentReplyMap } from './types'

const getRelativePos = (pos: number, editor: Editor): RelativePosition => {
  const { type, binding } = ySyncPluginKey.getState(editor.state)
  return absolutePositionToRelativePosition(pos, type, binding.mapping)
}

export const createEmptyDraftComment = (
  getPos: () => number,
  editor: Editor,
  targetHtml?: string
): DraftComment => {
  return {
    targetId: commentNanoid.generate(),
    targetHtml,
    json: null,
    text: '',
    relativePos: getRelativePos(getPos(), editor),
  }
}

export const updateDraftComment = (
  draft: DraftComment,
  getPos: () => number,
  targetHtml: string | undefined,
  editor: Editor
): DraftComment => {
  return {
    ...draft,
    targetHtml,
    relativePos: getRelativePos(getPos(), editor),
  }
}

/**
 * Return any draft comments for the given absolute position.
 * This can be used when outside the context of a NodeView,
 * such as the comments panel for memo level comments.
 */
export const findDraftCommentByAbsPos = (
  editor: Editor,
  absPos: number
): DraftComment[] => {
  const pluginState = DraftCommentsPluginKey.getState(
    editor.state
  ) as DraftCommentsState
  const ystate = ySyncPluginKey.getState(editor.state)
  const { doc, type, binding } = ystate

  return Object.values(pluginState.draftComments).filter(
    (draft: DraftComment) => {
      return (
        relativePositionToAbsolutePosition(
          doc,
          type,
          draft.relativePos,
          binding.mapping
        ) === absPos
      )
    }
  )
}

export const omitEmptyDraftReplies = (
  replies: DraftCommentReplyMap
): DraftCommentReplyMap => {
  return Object.entries(replies).reduce<DraftCommentReplyMap>(
    (carry, [key, val]) => {
      if (val.text.trim() === '') {
        return carry
      }
      carry[key] = val
      return carry
    },
    {}
  )
}
