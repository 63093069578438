import { EditorView } from 'prosemirror-view'

import {
  LayoutResizingPluginKey,
  LayoutResizingResetEvent,
  LayoutResizingSetDraggingEvent,
  LayoutResizingSetHandleEvent,
} from './LayoutResizingState'

export const getLayoutResizingState = (view: EditorView) => {
  return LayoutResizingPluginKey.getState(view.state)!
}

export const updateHandle = (view: EditorView, value: number | null) => {
  const payload = <LayoutResizingSetHandleEvent>{
    setHandle: value,
  }
  view.dispatch(view.state.tr.setMeta(LayoutResizingPluginKey, payload))
}

export const setDragging = (
  view: EditorView,
  args: LayoutResizingSetDraggingEvent['setDragging']
) => {
  const payload = <LayoutResizingSetDraggingEvent>{
    setDragging: args,
  }
  view.dispatch(view.state.tr.setMeta(LayoutResizingPluginKey, payload))
}

export const resetResizingState = (view: EditorView) => {
  const payload = <LayoutResizingResetEvent>{
    reset: true,
  }
  view.dispatch(view.state.tr.setMeta(LayoutResizingPluginKey, payload))
}
