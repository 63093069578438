import { HardBreak as TiptapHardBreak } from '@tiptap/extension-hard-break'

// Extend to explicitly use our own shortcuts
export const HardBreak = TiptapHardBreak.extend({
  addKeyboardShortcuts() {
    return {
      'Shift-Enter': () => this.editor.commands.setHardBreak(),
    }
  },
})
