import { Node, NodeType } from 'prosemirror-model'

import { MediaEmbedAttrs } from './types'

const MEDIA_NODES = ['image', 'video', 'embed', 'mediaPlaceholder']

export const isMediaNode = (node: Node) => {
  return isMediaNodeType(node.type)
}

export const isMediaNodeType = (type: NodeType) => {
  return MEDIA_NODES.includes(type.name)
}

export const isMediaEmbedNode = (
  node: Node
): node is Node & { attrs: MediaEmbedAttrs } => {
  return node.type.name === 'video' || node.type.name === 'embed'
}

export const getMediaSourceUrl = (attrs: Node['attrs']): string | undefined => {
  return (
    attrs.href || attrs.sourceUrl || attrs.url || attrs.embedUrl || undefined
  )
}

export const getMediaTitle = (node: Node) => {
  const attrs = node.attrs as MediaEmbedAttrs
  return [attrs.meta?.title, attrs.meta?.site].filter(Boolean).join(' - ')
}
