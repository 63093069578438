import { Box, Divider, Flex, Text, useToast } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HocuspocusProvider } from '@hocuspocus/provider'
import { Editor } from '@tiptap/core'
import { formatDistanceToNow } from 'date-fns'
import { useEffect, useRef, useState } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'
import { useAnalytics, AppMonitoringEvents } from 'modules/segment'
import { useCanWithSelectDoc } from 'modules/tiptap_editor/hooks'
import { selectDocSavedTime } from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'

import { useDataSyncMonitor } from './useDataSyncMonitor'
import { useMonitorKeyboard } from './useKeyboardMonitor'

const POLLING_INTERVAL_STANDARD = 1000 * 10
const POLLING_INTERVAL_WARNING = 1000 * 2

const TOAST_ID = 'data-sync-error-toast'

const WarningIcon = (
  <FontAwesomeIcon icon={duotone('circle-exclamation')} size="2x" />
)
const ErrorIcon = (
  <FontAwesomeIcon icon={duotone('triangle-exclamation')} size="2x" />
)

const SyncErrorToastComponent = ({
  type,
  yDocLastSynced,
}: {
  type: 'warn' | 'error'
  yDocLastSynced?: number
}) => {
  const ErrorToast = () => {
    return (
      <Flex
        direction="column"
        p={4}
        color="white"
        bg={type === 'warn' ? 'yellow.500' : 'red.500'}
        borderRadius="md"
      >
        <Flex alignItems="center">
          {type === 'warn' ? WarningIcon : ErrorIcon}
          <Box ml={3}>
            <Text lineHeight={6} fontSize="sm" fontWeight="bold">
              {type === 'warn'
                ? 'Gamma is having trouble syncing your changes right now.'
                : 'Gamma cannot sync your changes right now.'}
            </Text>
            {yDocLastSynced && (
              <Text lineHeight={6} fontSize="sm">
                Your work was last synced{' '}
                {formatDistanceToNow(new Date(yDocLastSynced))} ago.
              </Text>
            )}
            {type === 'error' && (
              <>
                <Divider pt={2} />
                <Text lineHeight={6} fontSize="sm" pt={2}>
                  Editing will be disabled until we can reconnect.
                </Text>
              </>
            )}
          </Box>
        </Flex>
      </Flex>
    )
  }
  return ErrorToast
}

/**
 * This hook monitors for different phases of data saving and shows a toast if
 * any issues are detected, warning first, then erroring and disabling editing
 *
 * The phases we came up with are:
 *   ContentEditable <0️⃣> PM State <1️⃣> Local YDoc <2️⃣> Remote YDoc <3️⃣> Snapshot API
 */

export const useDataPersistenceSync = ({
  editor,
  yProvider,
  enabled,
}: {
  editor: Editor | undefined
  yProvider: HocuspocusProvider | undefined
  enabled: boolean
}) => {
  // Only perform this check for users who have a writeable connection to Hocuspocus
  const canCommentDoc = useCanWithSelectDoc('comment')
  const docSavedTime = useAppSelector(selectDocSavedTime)
  const [pollingInterval, setPollingInterval] = useState(
    POLLING_INTERVAL_STANDARD
  )
  const analytics = useAnalytics()
  const toast = useToast()
  const { user } = useUserContext()
  // How many errors we can observe before showing the toast
  const errorCountThreshold = useFeatureFlag('dataSyncErrorThreshold')

  // Phase 0️⃣ - errorCountKeyboard
  const errorCountKeyboard = useMonitorKeyboard({ editor, pollingInterval })

  // Phase 1️⃣ - errorCountPMState
  // Phase 2️⃣ - errorCountClock
  const { errorCountPMState, errorCountClock, yDocLastSynced } =
    useDataSyncMonitor({
      editor,
      yProvider,
      enabled: enabled && canCommentDoc,
      pollingInterval,
    })

  const docEventData = {
    docSavedTime,
    yDocLastSynced,
    errorCountKeyboard,
    errorCountPMState,
    errorCountClock,
    clientID: yProvider?.document.clientID,
    userId: user?.id,
  }
  const docEventDataRef = useRef(docEventData)
  docEventDataRef.current = docEventData

  // Dont use the typing error count yet
  const errorCount = errorCountClock + errorCountPMState

  useEffect(() => {
    // Poll more frequently once we detect errors.
    setPollingInterval(
      errorCount === 0 ? POLLING_INTERVAL_STANDARD : POLLING_INTERVAL_WARNING
    )
  }, [errorCount])

  // The warning state is when the count is above the error threshold as
  // long as the threshold is not -1 (which means the flag is off)
  const warningThresholdExceeded =
    enabled && errorCountThreshold !== -1 && errorCount > errorCountThreshold

  // Escalate to an error after 2x the warningThresholdExceeded
  const errorThresholdExceeded =
    enabled &&
    errorCountThreshold !== -1 &&
    errorCount / errorCountThreshold > 2

  useEffect(() => {
    if (!warningThresholdExceeded) {
      toast.close(TOAST_ID)
      return
    }

    const render = SyncErrorToastComponent({
      type: errorThresholdExceeded ? 'error' : 'warn',
      yDocLastSynced,
    })

    if (!toast.isActive(TOAST_ID)) {
      analytics?.trackDocEvent(
        AppMonitoringEvents.DATA_SYNC_ERROR,
        docEventDataRef.current
      )
      toast({
        render,
        duration: null,
        id: TOAST_ID,
      })
    } else {
      toast.update(TOAST_ID, { render })
    }

    if (errorThresholdExceeded) {
      // Send to Sentry. In the future send to Event Tracking DB instead
      console.error(
        '[useDataPersistenceSync] ERROR_THRESHOLD_EXCEEDED. docId:',
        editor?.gammaDocId
      )
    }
  }, [
    editor,
    toast,
    analytics,
    errorThresholdExceeded,
    warningThresholdExceeded,
    docSavedTime,
    yDocLastSynced,
  ])

  // Only return true for error, not warn
  return errorThresholdExceeded
}
