import { Node as ProseMirrorNode } from 'prosemirror-model'
import { Decoration } from 'prosemirror-view'

import { isCardCollapsed } from './Card/CardCollapse'

export type UpdateFn = (updateProps: {
  newNode: ProseMirrorNode
  newDecorations: Decoration[]
  oldNode: ProseMirrorNode
  oldDecorations: Decoration[]
  updateProps: () => any
}) => boolean

export const isFocusedCard = (
  decorations: Decoration[],
  node: ProseMirrorNode
) =>
  isCardCollapsed(node)
    ? decorations.some((decoration) => decoration.spec.focusedNode)
    : decorations.some((decoration) => decoration.spec.focusedCard)

export const didDecorationsSpecChange = (a: Decoration[], b: Decoration[]) => {
  // Only consider decorations with spec defined
  const aFiltered = a.filter((d) => Object.keys(d.spec).length > 0)
  const bFiltered = b.filter((d) => Object.keys(d.spec).length > 0)

  const result =
    // check length first as a cheaper check
    aFiltered.length !== bFiltered.length ||
    JSON.stringify(aFiltered.map((d) => d.spec)) !==
      JSON.stringify(bFiltered.map((d) => d.spec))

  return result
}

export const attrsOrDecorationsChanged: UpdateFn = ({
  newNode,
  oldNode,
  updateProps,
  oldDecorations,
  newDecorations,
}) => {
  const didAttrsChange =
    JSON.stringify(newNode.attrs) !== JSON.stringify(oldNode.attrs)

  if (didAttrsChange) {
    updateProps()
    return true
  }

  const didDecosChange = didDecorationsSpecChange(
    oldDecorations,
    newDecorations
  )

  if (didDecosChange) {
    updateProps()
    return true
  }

  return true
}

export const numChildrenOrAttrsOrDecorationsChanged: UpdateFn = (props) => {
  const { newNode, oldNode, updateProps } = props

  const didNumChildrenChange =
    newNode.content.childCount !== oldNode.content.childCount

  if (didNumChildrenChange) {
    updateProps()
    return true
  }

  return attrsOrDecorationsChanged(props)
}
