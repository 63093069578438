import {
  ButtonProps,
  Button,
  IconButton,
  IconButtonProps,
  useBreakpointValue,
} from '@chakra-ui/react'
import { forwardRef } from '@chakra-ui/system'
import React from 'react'

interface ToolbarButtonProps extends ButtonProps {
  children: React.ReactNode
  icon: JSX.Element
  onClick?: (e: any) => void
  rightIcon?: JSX.Element
}

export const ToolbarButton = forwardRef(
  (
    { children, icon, onClick, rightIcon, ...rest }: ToolbarButtonProps,
    ref: React.MutableRefObject<HTMLButtonElement>
  ) => {
    const size = useBreakpointValue(['xs', 'sm'])
    return (
      <Button
        ref={ref}
        fontWeight="normal"
        size={size}
        leftIcon={icon}
        onClick={onClick}
        rightIcon={rightIcon}
        {...rest}
      >
        {children}
      </Button>
    )
  }
)

export const ToolbarIconButton = forwardRef(
  (
    { icon, ...rest }: IconButtonProps,
    ref: React.MutableRefObject<HTMLButtonElement>
  ) => {
    return (
      <IconButton
        ref={ref}
        fontWeight="normal"
        size="sm"
        icon={icon}
        {...rest}
      />
    )
  }
)
