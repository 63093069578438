import { Box } from '@chakra-ui/react'

import { config } from 'config'
import { useAppSelector } from 'modules/redux'

import { selectEditable } from '../../../reducer'

type ZoomClickCaptureProps = {
  enterZoom: () => void
} & React.ComponentProps<typeof Box>

export const ZoomClickCapture = ({
  enterZoom,
  ...boxProps
}: ZoomClickCaptureProps) => {
  const isEditable = useAppSelector(selectEditable)
  const clickShouldZoom = !isEditable
  const doubleClickShouldZoom = isEditable
  if (config.GAMMA_PUPPETEER_SERVICE) {
    return null
  }
  return (
    <Box
      // Fill the parent and eat clicks
      inset={0}
      w="100%"
      h="100%"
      position="absolute"
      zIndex={1}
      cursor={clickShouldZoom ? 'zoom-in' : 'default'}
      onDoubleClick={doubleClickShouldZoom ? enterZoom : undefined}
      onClick={clickShouldZoom ? enterZoom : undefined}
      data-drag-handle
      data-testid="zoom-click-capture"
      // Should be invisible, but still get click events
      opacity={0}
      {...boxProps}
    />
  )
}
