import { Editor } from '@tiptap/core'
import isEqual from 'lodash/isEqual'
import { useEffect, useMemo } from 'react'

import { useAppSelector } from 'modules/redux'

import {
  selectFollowingAttached,
  selectCollaboratorBeingFollowedSpotlightAndScroll,
  selectLocalCollaboratorSpotlight,
} from '../../reducer'
import { spotlightingBlock } from './Spotlight'

const TOP_OFFSET = 100

/**
 * Hook to determine if we are currently spotlighting by block.
 * Only recomputes when spotlight changes.
 */
export const useSpotlightingByBlock = (editor?: Editor) => {
  const spotlight = useAppSelector(
    selectLocalCollaboratorSpotlight,
    (...args) => isEqual(...args)
  )
  return useMemo(() => {
    return {
      enabled: Boolean(
        editor && spotlight && spotlightingBlock(editor, spotlight)
      ),
      pos: spotlight?.pos,
    }
  }, [editor, spotlight])
}

/**
 * Responsible for syncing the local spotlight with who were following
 */
export const useSpotlightAndScrollSync = (editor?: Editor) => {
  const isFollowingSomeoneAndAttached = useAppSelector(selectFollowingAttached)
  const collaboratorBeingFollowedSpotlightAndScroll = useAppSelector(
    selectCollaboratorBeingFollowedSpotlightAndScroll,
    (...args) => isEqual(...args)
  )

  useEffect(() => {
    if (
      !editor ||
      !isFollowingSomeoneAndAttached ||
      !collaboratorBeingFollowedSpotlightAndScroll
    )
      return
    // YOU ("localCollaborator") ARE FOLLOWING SOMEONE ELSE
    // Update our spotlight and scroll pos in accordance with who were following
    // This is applicable when in both DOC_VIEW and SLIDE_VIEW
    console.debug(
      '[useScrollAndSpotlightSync] collaboratorBeingFollowed spotlight or scroll changed',
      JSON.stringify(collaboratorBeingFollowedSpotlightAndScroll, null, 2)
    )

    const { spotlight, scroll } = collaboratorBeingFollowedSpotlightAndScroll

    editor.commands.syncSpotlightAndScroll({
      spotlight,
      scroll,
      scrollOffset: TOP_OFFSET,
      isFollowing: true,
    })
  }, [
    editor,
    isFollowingSomeoneAndAttached,
    collaboratorBeingFollowedSpotlightAndScroll,
  ])
}
