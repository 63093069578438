import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  IconButton,
  Button,
  Box,
  SimpleGrid,
  FormLabel,
  VStack,
} from '@chakra-ui/react'
import {
  TDSnapshot,
  ColorStyle,
  TldrawApp,
  ShapeStyles,
  shapesShared,
} from '@gamma-app/tldraw'
import * as React from 'react'

import TransparentBg from 'publicImages/transparent-background.png'

import { useTldrawApp } from '../hooks'

const colors = {
  [ColorStyle.White]: '#f0f1f3',
  // [ColorStyle.LightGray]: '#c6cbd1',
  [ColorStyle.Gray]: '#788492',
  // [ColorStyle.Black]: '#1d1d1d',
  [ColorStyle.Green]: '#36b24d',
  // [ColorStyle.Cyan]: '#0e98ad',
  [ColorStyle.Blue]: '#1c7ed6',
  // [ColorStyle.Indigo]: '#4263eb',
  [ColorStyle.Violet]: '#7746f1',
  [ColorStyle.Red]: '#ff2133',
  [ColorStyle.Orange]: '#ff9433',
  [ColorStyle.Yellow]: '#ffc936',
}

export const currentStyleSelector = (s: TDSnapshot) => s.appState.currentStyle
export const selectedIdsSelector = (s: TDSnapshot) =>
  s.document.pageStates[s.appState.currentPageId].selectedIds
const colorModeSelector = (s: TDSnapshot) =>
  s.settings.isDarkMode ? 'dark' : 'light'

type ColorIconProps = {
  style: ShapeStyles
  colorMode: 'dark' | 'light'
}

const ColorIcon = ({ style, colorMode }: ColorIconProps) => {
  return (
    <Box
      backgroundColor={
        style.isFilled ? shapesShared.fills[colorMode][style.color] : undefined
      }
      backgroundImage={style.isFilled ? undefined : TransparentBg.src}
      backgroundSize="4em"
      borderColor={shapesShared.strokes[colorMode][style.color]}
      borderWidth="2px"
      h="1em"
      w="1em"
      borderRadius="md"
    ></Box>
  )
}

// Based on https://github.com/tldraw/tldraw/blob/7555c22632510a91fd9123ed54685dcf3f615ebc/packages/tldraw/src/components/TopPanel/StyleMenu/StyleMenu.tsx#L127
const STYLE_KEYS = Object.keys(
  shapesShared.defaultTextStyle
) as (keyof ShapeStyles)[]
export const getCommonStyle = (
  app: TldrawApp,
  shapeIds: string[]
): Partial<ShapeStyles> => {
  const { page } = app
  const commonStyle = {}
  if (shapeIds.length <= 0) {
    return {}
  } else {
    const mismatches = new Set<string>([])
    shapeIds
      .map((id) => page.shapes[id])
      .forEach((shape) => {
        STYLE_KEYS.forEach((key) => {
          if (mismatches.has(key)) return
          if (commonStyle[key] === undefined) {
            commonStyle[key] = shape.style[key]
          } else {
            if (commonStyle[key] === shape.style[key]) return
            mismatches.add(key)
          }
        })
      })
    mismatches.forEach((key) => {
      delete commonStyle[key]
    })
  }
  return commonStyle
}

export const StylePopover = () => {
  const app = useTldrawApp()
  const colorMode = app.useStore(colorModeSelector)

  // The style used for new shapes going forward
  const currentStyle = app.useStore(currentStyleSelector)
  // Common style attributes in the selection
  const selectedIds = app.useStore(selectedIdsSelector)
  const commonStyle = getCommonStyle(app, selectedIds)
  // Display the current style with common styles overridden
  const displayStyle = { ...currentStyle, ...commonStyle }

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <IconButton
          icon={<ColorIcon style={displayStyle} colorMode={colorMode} />}
          variant="ghost"
          size="sm"
          aria-label=""
        />
      </PopoverTrigger>
      <PopoverContent w="fit-content" bg="#F9FAFBFA" p={4}>
        <VStack spacing={2} align="flex-start">
          <FormLabel>Fill</FormLabel>
          <SimpleGrid columns={2} spacing={2}>
            {[true, false].map((isFilled) => (
              <Button
                leftIcon={
                  <ColorIcon
                    style={{ ...displayStyle, isFilled }}
                    colorMode={colorMode}
                  />
                }
                variant="ghost"
                size="sm"
                key={isFilled ? 'fill' : 'outline'}
                isActive={displayStyle.isFilled === isFilled}
                onClick={() => app.style({ isFilled })}
              >
                {isFilled ? 'Solid' : 'Outline'}
              </Button>
            ))}
          </SimpleGrid>
          <FormLabel>Colors</FormLabel>
          <SimpleGrid columns={4} spacing={2} w="100%">
            {Object.entries(ColorStyle).map(([name, key]) => {
              if (!colors[key]) return null
              return (
                <IconButton
                  icon={
                    <ColorIcon
                      style={{ ...displayStyle, color: key }}
                      colorMode={colorMode}
                    />
                  }
                  variant="ghost"
                  size="md"
                  key={key}
                  aria-label={name}
                  isActive={displayStyle.color === key}
                  onClick={() => app.style({ color: key })}
                />
              )
            })}
          </SimpleGrid>
        </VStack>
      </PopoverContent>
    </Popover>
  )
}
