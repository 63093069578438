import { sortBy } from 'lodash'
type FontSizeOption = {
  label: string
  size: number
  flagged?: boolean
  // If it's a heading or title, the level
  heading?: number
  title?: number
}

export const FontSizeOptions: Record<string, FontSizeOption> = {
  sm: {
    label: 'Small',
    size: 0.8,
    flagged: true,
  },
  default: {
    label: 'Normal',
    size: 1,
  },
  lg: {
    label: 'Large',
    size: 1.25,
    flagged: true,
  },
  xl: {
    label: 'Extra Large',
    size: 1.5,
    flagged: true,
  },
  h3: {
    label: 'Heading 3',
    size: 1.5,
    heading: 3,
  },
  h2: {
    label: 'Heading 2',
    size: 2,
    heading: 2,
  },
  h1: {
    label: 'Heading 1',
    size: 2.5,
    heading: 1,
  },
  title: {
    label: 'Title',
    size: 3,
    title: 1,
  },
}

export const HeadingSizeOptions = sortBy(
  Object.entries(FontSizeOptions).filter(([_, { heading }]) => heading),
  ([_, { heading }]) => heading
)
export const TitleSizeOptions = sortBy(
  Object.entries(FontSizeOptions).filter(([_, { title }]) => title),
  ([_, { title }]) => title
)
export const BodySizeOptions = sortBy(
  Object.entries(FontSizeOptions).filter(
    ([_, { heading, title }]) => !heading && !title
  ),
  ([_, { size }]) => size
)
