import { Plugin } from 'prosemirror-state'

import { decorateLayouts } from './decoration'

export const SmartLayoutPlugin = () =>
  new Plugin({
    props: {
      decorations(state) {
        return decorateLayouts(state)
      },
    },
  })
