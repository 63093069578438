import { FontPreloadLinkSet } from './FontPreloadLinkSet'
const CSSString = `@font-face {
  font-family: 'Eudoxus Sans';
  src: url('/fonts/EudoxusSans-Bold.woff2') format('woff2'),
    url('/fonts/EudoxusSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('/fonts/EudoxusSans-Regular.woff2') format('woff2'),
    url('/fonts/EudoxusSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('/fonts/EudoxusSans-Light.woff2') format('woff2'),
    url('/fonts/EudoxusSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('/fonts/EudoxusSans-ExtraBold.woff2') format('woff2'),
    url('/fonts/EudoxusSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('/fonts/EudoxusSans-ExtraLight.woff2') format('woff2'),
    url('/fonts/EudoxusSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('/fonts/EudoxusSans-Medium.woff2') format('woff2'),
    url('/fonts/EudoxusSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
`
const files = [
  '/fonts/EudoxusSans-Bold.woff',
  '/fonts/EudoxusSans-Bold.woff2',
  '/fonts/EudoxusSans-Regular.woff',
  '/fonts/EudoxusSans-Regular.woff2',
  '/fonts/EudoxusSans-Light.woff',
  '/fonts/EudoxusSans-Light.woff2',
  '/fonts/EudoxusSans-ExtraBold.woff',
  '/fonts/EudoxusSans-ExtraBold.woff2',
  '/fonts/EudoxusSans-ExtraLight.woff',
  '/fonts/EudoxusSans-ExtraLight.woff2',
  '/fonts/EudoxusSans-Medium.woff',
  '/fonts/EudoxusSans-Medium.woff2',
]

const Component = () => (
  <>
    <style>{CSSString}</style>
    <FontPreloadLinkSet files={files} />
  </>
)

export default Component
