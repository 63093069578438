import { NodeViewProps, Editor } from '@tiptap/core'
import { useCallback } from 'react'

import {
  DocReactionsCreateFragmentDoc,
  Reaction,
  TargetType,
  useAddReactionMutation,
  User,
  useRemoveReactionMutation,
} from 'modules/api'
import { useUserContext } from 'modules/user'
import { commentNanoid } from 'utils/comments'

import { BlockReaction } from '../types'
import { getExistingReaction, hasUserReacted } from './utils'

/**
 * Helper hook to provide apollo mutation functions with update functions and
 * optimistic cache updating for reactions to comments.
 */
export const useBlockReactionUpdate = ({
  getPos,
  editor,
  reactions,
}: {
  getPos: NodeViewProps['getPos']
  editor: Editor
  reactions: BlockReaction[]
}) => {
  const { user } = useUserContext()
  const [addReaction] = useAddReactionMutation()
  const [removeReaction] = useRemoveReactionMutation()

  const removeReactionFn = useCallback(
    ({ reaction }: { reaction: Reaction }) => {
      if (!user) {
        // there is no signed in user
        return
      }
      const docId = reaction.docId!
      if (reaction.count === 1) {
        editor.commands.deleteAnnotation(reaction.targetId!)
      }
      const newCount = reaction.count! - 1
      const newUsers = reaction.users!.filter((u) => u?.id !== user?.id)

      removeReaction({
        variables: {
          reactionInput: {
            docId,
            targetId: reaction.targetId!,
            emoji: reaction.emoji!,
          },
        },
        update: (cache, { data }) => {
          cache.writeFragment({
            id: `Doc:${docId}`,
            fragment: DocReactionsCreateFragmentDoc,
            fragmentName: 'DocReactionsCreate',
            data: {
              reactions: [data?.removeReaction],
            },
          })
        },
        optimisticResponse: {
          removeReaction: {
            docId,
            targetId: reaction.targetId!,
            targetType: TargetType.Decoration,
            count: newCount,
            emoji: reaction.emoji!,
            users: newUsers,
            __typename: 'Reaction',
          },
        },
      })

      // no need to check inside here, we assume the check happened outside
    },
    [editor.commands, removeReaction, user]
  )

  const addReactionFn = useCallback(
    ({ emoji }: { emoji: string }) => {
      // check if user already reacted
      if (
        hasUserReacted({
          emoji,
          user,
          reactions,
        })
      ) {
        // user has already reacted, no-op
        return
      }
      if (!user) {
        // there is no signed in user
        return
      }

      const docId = editor.gammaDocId as string
      const existingReaction = getExistingReaction({ reactions, emoji })
      let targetId = commentNanoid.generate()
      // new count and users for optimistic updating
      const newCount = (existingReaction?.count! || 0) + 1
      let newUsers: User[] = []
      if (existingReaction) {
        targetId = existingReaction.targetId!
        // update for optimistic response
        // this doesnt work when existing reaction is a block
        newUsers = [...(existingReaction.users! as User[])]
      } else {
        // if there is no existing reaction add an annotation
        editor.commands.addAnnotation({
          id: targetId,
          pos: getPos(),
        })
      }
      newUsers.unshift(user!)
      // sort users according to BE rules for optimistic updating
      newUsers.sort((a, b) => (a.id > b.id ? 1 : -1))

      addReaction({
        variables: {
          reactionInput: {
            docId,
            targetId,
            targetType: TargetType.Decoration,
            emoji,
          },
        },
        update: (cache, { data }) => {
          cache.writeFragment({
            id: `Doc:${docId}`,
            fragment: DocReactionsCreateFragmentDoc,
            fragmentName: 'DocReactionsCreate',
            data: {
              reactions: [data?.addReaction],
            },
          })
        },
        optimisticResponse: {
          addReaction: {
            docId,
            targetId,
            targetType: TargetType.Decoration,
            count: newCount,
            emoji,
            users: newUsers,
            __typename: 'Reaction',
          },
        },
      })
    },
    [addReaction, editor.commands, editor.gammaDocId, getPos, reactions, user]
  )

  return {
    addReactionFn,
    removeReactionFn,
  }
}
