import { useUpdateEffect } from '@chakra-ui/hooks'
import { Editor } from '@tiptap/core'
import throttle from 'lodash/throttle'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  InitialMoveable,
  makeMoveable,
  Resizable,
  ResizableProps,
} from 'react-moveable'

import { useWindowResizing } from 'utils/hooks'
import { useContainerResizing } from 'utils/hooks/useContainerResizing'

import { CARD_CONTENT_CLASS } from '../Card/constants'
import { MIN_WIDTH_OR_HEIGHT_PIXELS } from './constants'

const wrapperClassName = 'resizeable-control-wrapper'

const Moveable = makeMoveable<ResizableProps>([Resizable])

// Enforce both a min width and min height (based on the width)
const getMinWidth = (width: number, target: HTMLElement | SVGElement) => {
  const minWidthComputed =
    MIN_WIDTH_OR_HEIGHT_PIXELS *
    Math.max(target.clientWidth / target.clientHeight, 1)

  return Math.max(width, minWidthComputed)
}

export function useResizeable<T extends HTMLElement | null>(editor: Editor) {
  const [isAnimating, setAnimating] = useState(false)
  const isWindowResizing = useWindowResizing()
  const ref = useRef<T>(null)
  const isContainerResizing = useContainerResizing(ref)
  const [isResizing, setIsResizing] = useState(false) // If we are currently resizing

  const sx = {
    '.moveable-control': {
      // Force hide all controls while the window or animations are happening
      display:
        isWindowResizing || isContainerResizing || isAnimating ? 'none' : '',
    },
  }

  const onLayoutAnimationStart = useCallback(() => {
    editor.commands.forceHideBubbleMenu?.(true)
    setAnimating(true)
  }, [editor])

  const onLayoutAnimationComplete = useCallback(() => {
    editor.commands.forceHideBubbleMenu?.(false)
    setAnimating(false)
  }, [editor])

  useUpdateEffect(() => {
    // Hide the bubble menu while resizing
    editor.commands.forceHideBubbleMenu?.(isResizing || isContainerResizing)
  }, [editor, isResizing, isContainerResizing])

  return {
    ref,
    isResizing,
    setIsResizing,
    isAnimating,
    resizeableSx: sx,
    onLayoutAnimationStart,
    onLayoutAnimationComplete,
  }
}

/**
 * Styles for the controls added by the moveable library.
 * See https://github.com/daybrush/moveable/blob/master/handbook/handbook.md#toc-custom-css
 *
 * Some of these are just overrides for the default values the library uses, like z-index
 * See those defaults here: https://github.com/daybrush/moveable/blob/6a6bc858afc7edc90212fba8b46b7bdf1c572afd/packages/react-moveable/src/react-moveable/consts.ts#L35-L152
 */
export const ResizeableStyles = {
  [`.${wrapperClassName}`]: {
    zIndex: 2,
    '.moveable-control': {
      bg: 'trueblue.500',
      borderRadius: 'full',
      w: 1,
      h: 10,
      mt: -5,
      opacity: 0.5,
      zIndex: 2,
      border: 'initial',
      _hover: { opacity: 1 },
      transitionProperty: 'opacity',
      transitionDuration: 'normal',
      '&.moveable-e': {
        cursor: 'col-resize',
        ml: 2,
      },
      '&.moveable-w': {
        cursor: 'col-resize',
        ml: -3,
      },
    },
    '.moveable-line': {
      display: 'none',
    },
  },
}

type ResizableControlsProps = {
  updateResizeAttrs: (
    resizeAttrs: { width: number },
    setFullWidth?: boolean
  ) => void
  imageWrapperRef: MutableRefObject<HTMLImageElement | HTMLDivElement | null>
  setIsResizing: (isResizing: boolean) => void
  refreshDeps: any[]
  maxWidth: number
}

export const ResizableControls = ({
  imageWrapperRef,
  setIsResizing,
  updateResizeAttrs,
  refreshDeps,
  maxWidth,
}: ResizableControlsProps) => {
  const moveableInstance = useRef<InitialMoveable | null>(null)

  useEffect(() => {
    requestAnimationFrame(() => {
      moveableInstance.current?.updateRect()
      moveableInstance.current?.updateTarget()
    })
  }, [refreshDeps])

  // Refresh the resizable target on resize and drop
  useEffect(() => {
    const refresh = () => {
      moveableInstance.current?.updateTarget()
    }
    const refreshThrottled = throttle(refresh, 250)

    document.addEventListener('drop', refresh)
    window.addEventListener('resize', refreshThrottled)
    return () => {
      document.removeEventListener('drop', refresh)
      window.removeEventListener('resize', refreshThrottled)
    }
  }, [])

  return (
    <Moveable
      ref={(instance) => {
        moveableInstance.current = instance
      }}
      className={wrapperClassName}
      target={imageWrapperRef.current}
      renderDirections={['e', 'w']}
      keepRatio={true}
      draggable={false}
      resizable={true}
      origin={false}
      onResizeStart={() => {
        setIsResizing(true)
      }}
      onResize={({ target, width }) => {
        target.style.width = `${getMinWidth(width, target)}px`
      }}
      onResizeEnd={({ target }) => {
        const newWidth = target.clientWidth
        const zoomFactor =
          parseFloat(
            getComputedStyle(target).getPropertyValue('--zoom-factor')
          ) || 1
        const normalizedWidth = newWidth / zoomFactor

        // Handle the edge case where you're on a small screen - set full width if you get to the edge
        const cardWidth = target.closest(`.${CARD_CONTENT_CLASS}`)?.clientWidth
        const isFullWidth = cardWidth ? newWidth / cardWidth == 1 : undefined

        updateResizeAttrs(
          {
            width: isFullWidth ? maxWidth : normalizedWidth,
          },
          isFullWidth
        )
        setIsResizing(false)
        target!.style.width = ''
      }}
    />
  )
}
