import { Stack } from '@chakra-ui/react'
import { MutableRefObject } from 'react'

import { SearchResult } from '../../types'
import { ChannelSearchResultItem } from './ChannelSearchResultItem'
import { DocSearchResultItem } from './DocSearchResultItem'

type SearchResultsWrapperProps = {
  results: SearchResult[]
  focusedIndex: number
  focusedItemEl: MutableRefObject<HTMLDivElement | null>
  onMouseEnter: (index: number) => void
}
export const SearchResultsWrapper = ({
  results,
  focusedIndex,
  focusedItemEl,
  onMouseEnter,
}: SearchResultsWrapperProps) => {
  return (
    <Stack
      spacing={1}
      px={4}
      h={['lg', 'xl']}
      maxHeight={['lg', 'xl']}
      pb={4}
      overflow="auto"
      data-testid="search-results-wrapper"
    >
      {results.map((result, index) => {
        if (
          result.__typename === 'DocResult' ||
          result.__typename === 'PseudoDocResult'
        ) {
          return (
            <DocSearchResultItem
              result={result}
              key={result.id}
              isActive={focusedIndex === index}
              focusedItemEl={focusedItemEl}
              onMouseEnter={() => {
                onMouseEnter(index)
              }}
            />
          )
        } else if (result.__typename === 'Channel') {
          return (
            <ChannelSearchResultItem
              result={result}
              key={result.id}
              isActive={focusedIndex === index}
              focusedItemEl={focusedItemEl}
              onMouseEnter={() => {
                onMouseEnter(index)
              }}
            />
          )
        } else {
          return null
        }
      })}
    </Stack>
  )
}
