/**
 * Styles that are applied to the doc editor root
 */
import { SystemStyleObject } from '@chakra-ui/system'

import { cursorStyles } from './cursorStyles'
import { spotlightStyles } from './spotlightStyles'

export const editorStyles: SystemStyleObject = {
  '.ProseMirror': {
    '&.spotlight-active': spotlightStyles,

    ...cursorStyles,

    // Card hover
    '.document-content.is-doc-mode': {
      '.card-wrapper': {
        '&:hover': {
          '.card-divider': {
            opacity: 1,
          },
          '.manage-card-controls': {
            opacity: 1,
          },
        },
      },
    },
    '.document-content': {
      '.card-wrapper': {
        '&:hover': {
          '.card-footer-reactions': {
            opacity: 1,
          },
          '.card-collapsed': {
            '.card-footer-reactions': {
              display: 'none',
            },
          },
        },
      },
    },
    counterReset: 'footnote',
  },

  '.ProseMirror-dropcursor': {
    transitionProperty: 'inset',
    transitionDuration: 'fast',
    borderRadius: 'md',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    outline: '1px solid',
    outlineColor: 'whiteAlpha.800',
    pointerEvents: 'none',
    backdropFilter: 'blur(4px)',

    // Modeled after tooltips
    '&::after': {
      backgroundColor: 'gray.700',
      color: 'whiteAlpha.900',
      px: '8px',
      py: '2px',
      borderRadius: 'sm',
      fontWeight: 'medium',
      shadow: 'md',
      fontSize: 'sm',
      w: 'max-content',
      pointerEvents: 'none',
      display: 'none',
    },

    '&[data-type="column"]::after': {
      content: '"Drop to add column"',
      position: 'absolute',
      top: '-2em',
      display: 'block',
    },
  },
}
