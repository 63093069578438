import { Editor, JSONContent } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

import {
  DragAnnotationData,
  DropAnnotationEvent,
} from '../Annotatable/AnnotationExtension/types'
import { computeLayoutCreateMoveInstructions } from '../Annotatable/utils'
import { checkColumnDropTarget, ColumnDropTarget } from './utils'
class LayoutPluginState {
  constructor(public dragging: DragAnnotationData | null = null) {}
}
const LayoutPluginKey = new PluginKey<LayoutPluginState>('layoutPlugin')

export const LayoutPlugin = (editor: Editor) =>
  new Plugin({
    key: LayoutPluginKey,

    state: {
      init() {
        return new LayoutPluginState()
      },

      apply(_transaction, pluginState) {
        return pluginState
      },
    },

    appendTransaction: (transactions, _oldState, newState) => {
      if (!transactions.some((tr) => tr.docChanged)) return null
      const { tr } = newState
      newState.doc.descendants((node, pos) => {
        if (node.type.name === 'gridLayout' && node.childCount == 1) {
          tr.replaceWith(
            tr.mapping.map(pos),
            tr.mapping.map(pos + node.nodeSize),
            node.child(0).content
          )
        }
      })
      if (tr.docChanged) {
        return tr
      } else {
        return null
      }
    },

    props: {
      handleDOMEvents: {
        drop(view) {
          // Store the annotation drag data here temporarily in the plugin state
          // this because the native prosemirror drop handler clears out the dragging data
          // before it calls pluginHandlers for dropHandler
          const annotationData = (view.dragging as any)
            ?.annotations as DragAnnotationData | null
          const pluginState = LayoutPluginKey.getState(view.state)
          if (!pluginState) {
            return false
          }
          pluginState.dragging = annotationData
          return
        },
      },
      handleDrop: (view, event, slice) => {
        const pluginState = LayoutPluginKey.getState(view.state)
        const dragAnnotationData = pluginState?.dragging
        if (pluginState) {
          // on drop always get rid of the drag data
          pluginState.dragging = null
        }

        let columnDropTarget: ColumnDropTarget | null = null

        try {
          columnDropTarget = checkColumnDropTarget(
            view,
            event as DragEvent,
            slice,
            false // We know upload isn't needed because image upload already would have handled drop
          )
          if (!columnDropTarget) {
            return false
          }
        } catch (err) {
          return false
        }
        try {
          const { selection } = view.state
          const shouldDeleteOriginal = !selection.empty // Insert widget will set this empty
          const { node, pos, side } = columnDropTarget

          // Don't allow dropping inside yourself
          if (pos > selection.from && pos < selection.to) return true

          const pasteContent = slice.content.toJSON() as JSONContent[]

          if (node.type.name === 'gridCell') {
            // If it's a layout cell, add another here
            const insertPos = side === 'left' ? pos : pos + node.nodeSize
            editor
              .chain()
              .insertContentAt(
                { from: insertPos, to: insertPos },
                {
                  type: 'gridCell',
                  content: pasteContent,
                },
                { updateSelection: false }
              )
              .command(({ tr }) => {
                if (shouldDeleteOriginal) tr.deleteSelection()

                if (dragAnnotationData) {
                  tr.setMeta('annotationEvent', <DropAnnotationEvent>{
                    type: 'drop',
                    dragging: dragAnnotationData,
                    // add 1 to account for the shift by wrapping content in gridCell
                    droppedBlockPos: insertPos + 1,
                  })
                }

                return true
              })
              .focusMapped(insertPos, 1) // Focus into the new cell
              .run()
          } else {
            // If it's a block node, wrap in a layout
            const leftContentSize = side === 'left' ? slice.size : node.nodeSize
            editor
              .chain()
              .insertContentAt(
                { from: pos, to: pos + node.nodeSize },
                {
                  type: 'gridLayout',
                  content: [
                    {
                      type: 'gridCell',
                      content: side === 'left' ? pasteContent : [node.toJSON()],
                    },
                    {
                      type: 'gridCell',
                      content: side === 'left' ? [node.toJSON()] : pasteContent,
                    },
                  ],
                },
                { updateSelection: false }
              )
              .command(({ tr }) => {
                if (shouldDeleteOriginal) {
                  tr.deleteSelection()
                }
                if (dragAnnotationData) {
                  const moveInstructions = computeLayoutCreateMoveInstructions({
                    side,
                    view,
                    tr,
                    dragging: dragAnnotationData!,
                    dropPos: pos,
                    dropNode: node,
                    leftContentSize,
                  })
                  requestAnimationFrame(() => {
                    editor.commands.moveAnnotations?.(moveInstructions)
                  })
                }

                return true
              })
              // Focus into the first cell, or past the first cell and its content into the second cell
              // +1 into the layout, +1 into the cell
              .focusMapped(pos, side === 'left' ? 2 : node.nodeSize + 4)
              .run()
          }
        } catch (err) {
          console.error('(caught) [LayoutPlugin] handleDrop error:', err)
        }
        // if we've determined that checkColumnDropTarget is not null
        // we always want to return true to prevent the default drop handler in view.js from running
        return true
      },
    },
  })
