import { Box } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'

import { config } from 'config'
import { useAppSelector } from 'modules/redux'
import { NodeViewContent } from 'modules/tiptap_editor/react'
import {
  selectIsStatic,
  selectToggleExpanded,
} from 'modules/tiptap_editor/reducer'
import { FONT_SIZES } from 'modules/tiptap_editor/styles/constants'

import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { findCalloutBoxDeco } from '../CalloutBox/CalloutBoxPlugin'
import { LEFT_PADDING } from './constants'

export const ExpandableToggleView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations } = nodeViewProps
  const { firstChildType } = findCalloutBoxDeco(decorations)
  const iconFontSize = FONT_SIZES[firstChildType]

  const isStatic = useAppSelector(selectIsStatic)
  const isOpen =
    useAppSelector(selectToggleExpanded(node.attrs.id)) ||
    isStatic ||
    config.GAMMA_PUPPETEER_SERVICE

  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      hideComments={isOpen} // Prevent double comments showing up on the summary and the expandableToggle
      readOnly={!isOpen} // Todo: allow commenting when the expandableToggle is closed, but put it on the summary
    >
      <Box
        pl={LEFT_PADDING}
        position="relative"
        data-open={!!isOpen}
        className="expandableToggle"
        borderRadius="var(--block-border-radius)"
      >
        <NodeViewContent />
        {isOpen && (
          <Box
            position="absolute"
            left="0.25em"
            bottom="0"
            fontSize={iconFontSize}
            top="2.5em"
            borderLeft="1px solid"
            borderColor="var(--block-border-color)"
          />
        )}
      </Box>
    </AnnotatableNodeViewWrapper>
  )
}
