import { H, HighlightSegmentMiddleware } from 'highlight.run'
import { useEffect } from 'react'

import { config } from 'config'
import { useFeatureFlag } from 'modules/featureFlags'
import { analytics } from 'modules/segment'

import { useUserContext } from '../user'

export const initializeHighlight = (
  enableStrictPrivacy: boolean,
  isGammaOrgUser: boolean
): void => {
  // if (!config.HIGHLIGHT_PROJECT_ID) return
  H.init(config.HIGHLIGHT_PROJECT_ID, {
    environment: config.APPLICATION_ENVIRONMENT,
    sessionShortcut: isGammaOrgUser ? 'shift+command+0' : false,
    enableStrictPrivacy,
    enableSegmentIntegration: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
    },
  })
  analytics.loadSdkPromise.then((sdk) => {
    if (sdk) {
      sdk.addSourceMiddleware(HighlightSegmentMiddleware)
    }
  })
}

export const HighlightProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { user, anonymousUser, isGammaOrgUser, isUserLoading } =
    useUserContext()
  const { enabled, enableStrictPrivacy } = useFeatureFlag('highlight')

  useEffect(() => {
    if (enabled && !isUserLoading) {
      initializeHighlight(enableStrictPrivacy, isGammaOrgUser)
    }
  }, [enableStrictPrivacy, enabled, isGammaOrgUser, isUserLoading])

  useEffect(() => {
    if (isUserLoading) return

    if (user) {
      const { id, displayName, email, profileImageUrl } = user
      // https://docs.highlight.run/identifying-users
      H.identify(id, {
        highlightDisplayName: displayName,
        email,
        avatar: profileImageUrl,
      })
    } else if (anonymousUser?.id) {
      const { id, displayName } = anonymousUser
      // Empty string is treated as a blank and ignored (their types don't allow null)
      H.identify('', { anonDisplayName: displayName || '', anonId: id })
    }
  }, [user, anonymousUser, isUserLoading])

  return <>{children}</>
}
