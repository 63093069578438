import tinycolor from 'tinycolor2'

export const colorWithLightness = (
  color: tinycolor.ColorInput,
  lightness: number
) => {
  const hsl = tinycolor(color).toHsl()
  hsl.l = lightness
  return tinycolor(hsl).toHex8String()
}

export const lightenColor = (
  color: tinycolor.ColorInput,
  lightness: number
) => {
  return tinycolor(color).lighten(lightness).toHex8String()
}

export const colorWithOpacity = (
  color: tinycolor.ColorInput,
  opacity: number
) => {
  return tinycolor(color).setAlpha(opacity).toHex8String()
}
