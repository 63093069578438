import { VStack } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { EmbedTip } from '../components/EmbedTip'
import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import Icon from './icons/amplitude.svg'

const Panel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<WebEmbedAttrs>) => {
  const url = getMediaSourceUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder="<iframe..."
      />
      <EmbedTip
        guideLink="https://help.amplitude.com/hc/en-us/articles/235717108-Share-charts-dashboards-and-notebooks-with-stakeholders-outside-your-company#generate-embed-code-for-your-content"
        extraMessage="Only Amplitude's paid plans allow embedding."
        providerName="Amplitude"
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const AmplitudeProvider: MediaProviderType = {
  label: 'Amplitude',
  key: 'embed.amplitude',
  keywords: ['amplitude', 'analytics', 'chart', 'data'],
  description: 'Live charts, notebooks, dashboards, and cohorts',
  icon: duotone('chart-column'),
  image: Icon,
  urlRegex: /^https?:\/\/analytics.amplitude.com/,
  Panel,
}

/* Example: 
<iframe class="frame"
  src="https://analytics.amplitude.com/share/embed/adef9fc0-72e5-44ac-b424-561cfe229aba"
  frameBorder="0" width="100%" height="500">
</iframe>
Generated from https://analytics.amplitude.com/demo/chart/q4dlifb
*/
