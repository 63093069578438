import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'

import { featureFlags } from 'modules/featureFlags'

import { handleMouseDown, handleMouseLeave, handleMouseMove } from './input'
import {
  LayoutResizingPluginKey,
  LayoutResizingState,
} from './LayoutResizingState'

export function createLayoutResizingPlugin() {
  const plugin = new Plugin({
    key: LayoutResizingPluginKey,
    state: {
      init() {
        return new LayoutResizingState()
      },
      apply(tr, resizeState, _oldEditorState, newEditorState) {
        return resizeState.apply(tr, newEditorState)
      },
    },
    props: {
      attributes(state) {
        const pluginState = LayoutResizingPluginKey.getState(state)
        const activeHandle = pluginState!.getActiveHandleAbs(state)
        return activeHandle !== null
          ? { class: 'resize-cursor' }
          : { class: '' }
      },

      handleDOMEvents: {
        mousemove(view: EditorView, event: MouseEvent) {
          if (!featureFlags.get('layoutResizing')) {
            return false
          }

          handleMouseMove(view, event)
          return false
        },
        mouseleave(view: EditorView) {
          if (!featureFlags.get('layoutResizing')) {
            return false
          }

          handleMouseLeave(view)
          return false
        },
        mousedown(view: EditorView, event: MouseEvent) {
          if (!featureFlags.get('layoutResizing')) {
            return false
          }

          handleMouseDown(view, event)
          return false
        },
      },

      decorations(state) {
        const pluginState = LayoutResizingPluginKey.getState(state)
        const activeHandle = pluginState!.getActiveHandleAbs(state)
        if (activeHandle !== null) {
          const decorations: Decoration[] = []
          try {
            const dom = document.createElement('div')
            dom.className = 'column-resize-handle'
            decorations.push(Decoration.widget(activeHandle + 1, dom))

            return DecorationSet.create(state.doc, decorations)
          } catch (e) {
            console.error(`(caught) layoutResizing error: ${e.message}`)
            return DecorationSet.empty
          }
        }
        return
      },

      nodeViews: {},
    },
  })
  return plugin
}
