import { markInputRule } from '@tiptap/core'
import TiptapBold from '@tiptap/extension-bold'

// Override default regex such that `*bold*` and `**bold**` both produce bold text
// https://github.com/ueberdosis/tiptap/blob/941ce64b2851e0c6fd95649b84816789fe146e07/packages/extension-bold/src/bold.ts#L31-L32
export const starInputRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))$/
export const doubleStarInputRegex = /(?:^|\s)((?:\*\*)((?:[^*]+))(?:\*\*))$/

export const Bold = TiptapBold.extend({
  addInputRules() {
    return [
      markInputRule({
        find: starInputRegex,
        type: this.type,
      }),
      markInputRule({
        find: doubleStarInputRegex,
        type: this.type,
      }),
    ]
  },
})
