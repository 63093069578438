import { VStack } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import { VideoEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'

const CustomVideoPanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<VideoEmbedAttrs>) => {
  const url = getMediaSourceUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder="Enter a URL here"
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const CustomVideoProvider: MediaProviderType = {
  label: 'Video or audio',
  description: 'Include videos, podcasts, and other playable content',
  key: 'video.custom',
  icon: duotone('circle-play'),
  Panel: CustomVideoPanel,
  keywords: ['video', 'audio', 'podcast', 'embed'],
  iframeOptions: {
    allowPopups: true,
  },
}
