import { mergeAttributes, Node } from '@tiptap/core'

import { insertMathCmd } from './prosemirror-math/'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mathInline: {
      insertMathInline: () => ReturnType
      convertToMathInline: () => ReturnType
    }
  }
}

export const MathInline = Node.create({
  name: 'math_inline',
  group: 'inline math',
  content: 'text*',
  inline: true,
  atom: true,
  renderHTML({ HTMLAttributes }) {
    return [
      'math-inline',
      mergeAttributes(HTMLAttributes, { class: 'math-node' }),
      0,
    ]
  },
  parseHTML() {
    return [{ tag: 'math-inline' }]
  },
  addCommands() {
    return {
      insertMathInline:
        () =>
        ({ state, dispatch, view }) =>
          insertMathCmd(this.type, ' ')(state, dispatch, view),
      convertToMathInline:
        () =>
        ({ state, chain }) => {
          const text = state.doc.textBetween(
            state.selection.from,
            state.selection.to
          )
          chain()
            .insertContent({
              type: 'math_inline',
              content: [
                {
                  type: 'text',
                  text,
                },
              ],
            })
            .run()
          return true
        },
    }
  },
})
