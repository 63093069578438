export const BLOCK_MARGIN = ['0.75em', '1em'] // Vertical padding between all blocks

// The first item in this array is for the smallest viewport width.
export const FONT_SIZES = {
  title: ['2.25em', '3em'],
  h1: ['2em', '2.5em'],
  h2: ['1.5em', '2em'],
  h3: ['1.25em', '1.5em'],
  paragraph: '1em',
  blockquote: '1.15em',
  codeBlock: ['0.85em', '1em'],
}
