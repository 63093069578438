import { IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'

import { CommentStatus } from 'modules/api'

export const COMMENT_STATUS_CONFIG = {
  [CommentStatus.Open]: {
    label: 'Mark as closed',
    icon: regular('check-circle'),
    successMessage: 'Comment marked as closed',
  },
  [CommentStatus.Closed]: {
    label: 'Mark as open',
    icon: regular('dot-circle'),
    successMessage: 'Comment reopened',
  },
}

export const CommentStatusButton = ({ status, handleCommentStatusUpdate }) => {
  return (
    <GammaTooltip
      label={COMMENT_STATUS_CONFIG[status].label}
      aria-label={COMMENT_STATUS_CONFIG[status].label}
    >
      <IconButton
        variant="ghost"
        size="sm"
        borderRadius="full"
        disabled={false}
        aria-label={COMMENT_STATUS_CONFIG[status].label}
        icon={<FontAwesomeIcon icon={COMMENT_STATUS_CONFIG[status].icon} />}
        onClick={() => handleCommentStatusUpdate(status)}
      />
    </GammaTooltip>
  )
}
