import { findParentNode, Node } from '@tiptap/core'
import { Fragment } from 'prosemirror-model'

import { featureFlags } from 'modules/featureFlags'
import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import { fragmentToArray } from 'modules/tiptap_editor/utils'
import { findSelectionNearOrGapCursor } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'
import { wrappingTransformInputRule } from 'modules/tiptap_editor/utils/wrappingTransformInputRule'

import { ExtensionPriorityMap } from '../constants'
import { getFontSizeOption } from '../Font/utils'
import { attrsOrDecorationsChanged } from '../updateFns'
import { ExpandablePlugin } from './ExpandablePlugin'
import { ExpandableSummary } from './ExpandableSummary'
import { ExpandableToggleView } from './ExpandableToggleView'
import { generateExpandableId, UniqueExpandableId } from './UniqueExpandableId'
import {
  isExpandableOpen,
  isExpandableToggleNode,
  toggleExpandableOpen,
} from './utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    expandableToggle: {
      enterInExpandable: () => ReturnType
    }
  }
}

const toggleRegex = /^\s*([+])\s$/

export const ExpandableToggle = Node.create({
  name: 'expandableToggle',
  group: 'layoutBlock calloutBlock fullWidthBlock',
  content: 'expandableSummary (block | layoutBlock)+',
  selectable: false,

  isolating: false,
  priority: ExtensionPriorityMap.ExpandableToggle,

  addNodeView() {
    return ReactNodeViewRenderer(ExpandableToggleView, {
      update: attrsOrDecorationsChanged,
    })
  },

  addProseMirrorPlugins() {
    return [ExpandablePlugin()]
  },

  addInputRules() {
    if (!featureFlags.get('expandableToggle')) {
      return []
    }
    return [
      wrappingTransformInputRule({
        find: toggleRegex,
        innerType: this.editor.schema.nodes.expandableSummary,
        outerType: this.type,
        getAttributes: () => {
          const id = generateExpandableId()
          toggleExpandableOpen(id)
          return { id }
        },
      }),
    ]
  },

  addCommands() {
    return {
      // Don't split summary blocks on enter if they're collapsed
      // because the content confusingly disappears
      enterInExpandable:
        () =>
        ({ editor, tr }) => {
          if (!editor.isActive('expandableSummary')) {
            return false
          }

          const id = editor.getAttributes('expandableToggle').id
          if (!id || isExpandableOpen(id)) return false

          const { selection, doc } = editor.state
          const { $from } = selection
          const isEndOfLine = $from.parentOffset === $from.parent.nodeSize - 2

          if (isEndOfLine) {
            // Jump after
            const afterParentPos = $from.after($from.depth - 1)
            const afterSelection = findSelectionNearOrGapCursor(
              doc.resolve(afterParentPos),
              1
            )
            if (!afterSelection) {
              return false
            }
            tr.setSelection(afterSelection)
            return true
          }

          toggleExpandableOpen(id)
          return true
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => editor.commands.enterInExpandable(),
      Backspace: ({ editor }) => {
        // Remove expandableToggle if you backspace at the start
        if (!editor.isActive('expandableSummary')) {
          return false
        }

        // If the expandableToggle is collapsed, don't backspace it
        const id = editor.getAttributes('expandableToggle').id
        if (!id || !isExpandableOpen(id)) return false

        const { selection } = editor.state
        const expandableToggle = findParentNode(isExpandableToggleNode)(
          selection
        )
        if (
          !expandableToggle ||
          expandableToggle.start + 1 !== selection.from || // Only run if your selection is at the start of the expandableToggle summary (+1 from the expandableToggle itself)
          !selection.empty
        ) {
          return false
        }

        // Convert the summary to regular text
        const content = fragmentToArray(expandableToggle.node.content)
        const summary = content[0]
        const currentSize = getFontSizeOption(summary.attrs.fontSize)
        const newNode = currentSize.heading
          ? editor.schema.nodes.heading.create(
              { level: currentSize.heading },
              summary.content
            )
          : editor.schema.nodes.paragraph.create(
              { fontSize: summary.attrs.fontSize },
              summary.content
            )
        const newContent = [newNode].concat(content.slice(1))
        editor
          .chain()
          .command(({ tr }) => {
            tr.replaceWith(
              expandableToggle.pos,
              expandableToggle.pos + expandableToggle.node.nodeSize,
              Fragment.from(newContent)
            )
            return true
          })
          .focus(expandableToggle.pos)
          .run()

        // Todo: this will break comments

        return true
      },
    }
  },

  addExtensions() {
    return [UniqueExpandableId, ExpandableSummary]
  },

  renderHTML({ HTMLAttributes }) {
    return ['details', HTMLAttributes, 0]
  },

  parseHTML() {
    return [
      {
        tag: 'details',
      },
    ]
  },
})
