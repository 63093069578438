import {
  Node,
  mergeAttributes,
  JSONContent,
  findChildren,
  getMarkRange,
} from '@tiptap/core'
import { Fragment } from 'prosemirror-model'
import { NodeSelection } from 'prosemirror-state'

import { findSelectionInsideNode } from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'

import { setCardCollapsed } from '../Card/CardCollapse'
import { cardNanoid } from '../Card/uniqueId'
import { ExtensionPriorityMap } from '../constants'
import footnoteInputRule from './footnoteInputRule'
export { FootnoteLabel } from './FootnoteLabel'
import { FootnotePlugin } from './FootnotePlugin'
import {
  setFootnoteExpanded,
  getExpandedFootnoteId,
  generateFootnoteId,
  isFootnoteSelected,
} from './FootnoteState'
import { FootnoteView } from './FootnoteView'
import { InnerEditorNodeViewRenderer } from './InnerEditorNodeView'

const noteInputRegex = /(?:^|\s)((?:\^)((?:[^^]+))(?:\^))$/
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    footnote: {
      toggleFootnote: () => ReturnType
      convertNoteToCard: (noteId: string) => ReturnType
    }
  }
}

export const Footnote = Node.create({
  name: 'footnote',
  content: '(block | footnoteBlock)+',
  group: 'inline',
  atom: true,
  inline: true,
  priority: ExtensionPriorityMap.Footnote,

  parseHTML() {
    return [
      // Store the footnote content as an attribute so that when we copy/paste,
      // we don't run into issues with nested paragraphs (can't have <p> inside <p>)
      // or have the footnote content interrupt the parent content.
      {
        tag: 'span[class=footnote]',
        getContent: (node: Element, schema) =>
          Fragment.fromJSON(
            schema,
            JSON.parse(node.getAttribute('data-content') || '{}')
          ),
      },
      // For imported footnotes, we use a block level element and put the content inside,
      // then clean up the parsing after the fact in Clipboard extensions' transformPasted.
      {
        tag: 'div[class=imported-footnote]',
      },
    ]
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        class: 'footnote',
        'data-content': JSON.stringify(node.content.toJSON()),
      }),
    ]
  },

  addAttributes() {
    return {
      // Used to keep a footnote mark and node in sync. Only needs to be unique within the memo.
      noteId: {
        // Generate a new ID when you paste a footnote instead of keeping the existing one
        parseHTML: () => generateFootnoteId(),
        // Mark this attr as required for FixRequiredAttrs extension
        default: undefined,
      },
    }
  },

  addNodeView() {
    return InnerEditorNodeViewRenderer(FootnoteView, {
      nodeName: 'footnote',
      preventNodeTypes: ['doc', 'document', 'card'],
    })
  },

  addInputRules() {
    return [
      footnoteInputRule({
        find: noteInputRegex,
        nodeType: this.type,
        markType: this.editor.schema.marks.footnoteLabel,
      }),
    ]
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        // Check if a footnote is selected
        if (!isFootnoteSelected(editor.state.selection)) return false

        // If so, open it on enter
        const noteId = (editor.state.selection as NodeSelection).node.attrs
          .noteId
        setFootnoteExpanded(noteId, true)
        return true
      },
      Escape: () => {
        // Check if a footnote is selected
        const expandedNoteId = getExpandedFootnoteId()
        if (!expandedNoteId) return false

        // If so, close it
        setFootnoteExpanded(expandedNoteId, false)
        return true
      },
      'Mod-Alt-f': ({ editor }) => editor.commands.toggleFootnote(),
    }
  },
  addProseMirrorPlugins() {
    return [FootnotePlugin]
  },

  addCommands() {
    return {
      convertNoteToCard:
        (noteId) =>
        ({ chain, state }) => {
          const { doc } = state

          // Get the note position and content
          const noteNodes = findChildren(
            doc,
            (node) =>
              node.type.name === 'footnote' && node.attrs.noteId === noteId
          )
          if (noteNodes.length !== 1) {
            throw new Error(
              `Found the wrong number of footnotes nodes: ${noteNodes}`
            )
          }
          const { node, pos } = noteNodes[0]

          // Get the mark range and content
          const markRange = getMarkRange(
            doc.resolve(pos - 1),
            state.schema.marks.footnoteLabel,
            { noteId: node.attrs.noteId }
          )
          if (!markRange) {
            console.error('Couldnt find the corresponding footnote mark', {
              node,
              pos,
            })
            return false
          }

          // Construct a new card
          const title = doc.textBetween(markRange.from, markRange.to)
          const newCard = {
            type: 'card',
            attrs: {
              id: cardNanoid.generate(),
            },
            content: [
              {
                type: 'heading',
                attrs: {
                  level: 1,
                },
                content: [
                  {
                    type: 'text',
                    text: title,
                  },
                ],
              },
              ...(node.content.toJSON() as JSONContent[]),
            ],
          }
          const url = new URL(window.location.href)
          url.hash = `card-${newCard.attrs.id}`

          setCardCollapsed(newCard.attrs.id, false)
          const insertCardPos = doc.content.size - 1

          // Replace the mark and footnote node with a link to the card
          return (
            chain()
              .setTextSelection(markRange)
              .unsetMark('footnoteLabel')
              .setLink({ href: url.toString() })
              .insertContentAt(insertCardPos, newCard)
              .command(({ tr }) => {
                const sel = findSelectionInsideNode(
                  tr.doc.resolve(insertCardPos)
                )
                if (sel) {
                  tr.setSelection(sel)
                }
                return true
              })
              .deleteRange({ from: pos, to: pos + node.nodeSize })
              // use focus delayed here for it's view.focus() + scrollIntoView behavior in requestAnimationFrame
              .focusDelayed()
              .run()
          )
        },
      toggleFootnote:
        () =>
        ({ chain, state, editor }) => {
          // If there's already a footnote on this range, remove it
          if (editor.isActive('footnoteLabel')) {
            return chain().toggleMark('footnoteLabel').focus().run()
          }

          // Create a new footnote
          const noteId = generateFootnoteId()

          // Expand newly created footnotes
          setFootnoteExpanded(noteId, true)
          setTimeout(() => focusInsideFootnote(noteId), 50)

          return chain()
            .setMark('footnoteLabel', {
              noteId,
            })
            .insertContentAt(state.selection.to, {
              type: 'footnote',
              attrs: {
                noteId,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            })
            .run()
        },
    }
  },
})

const focusInsideFootnote = (noteId: string) => {
  const innerEditorElt = document.querySelector(
    `[data-footnote-popover-id="${noteId}"] .ProseMirror`
  ) as HTMLElement | null
  if (!innerEditorElt) return
  innerEditorElt.focus()
  // Put the selection at the start https://stackoverflow.com/a/16863913
  const sel = window.getSelection()
  if (!sel) return
  const range = document.createRange()
  range.setStart(innerEditorElt, 0)
  range.setEnd(innerEditorElt, 0)
  sel.removeAllRanges()
  sel.addRange(range)
}
