import { mergeAttributes, Node } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mathBlock: {
      insertMathDisplay: () => ReturnType
    }
  }
}

export const MathDisplay = Node.create({
  name: 'math_display',
  group: 'block math',
  content: 'text*',
  atom: true,
  code: true,
  renderHTML({ HTMLAttributes }) {
    return [
      'math-display',
      mergeAttributes(HTMLAttributes, { class: 'math-node' }),
      0,
    ]
  },
  parseHTML() {
    return [{ tag: 'math-display' }]
  },
})
