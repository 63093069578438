/**
 * NB: This is a pared-down version of @tiptap/extension-mention.
 * Original source here: https://github.com/ueberdosis/tiptap/blob/ab4a0e2507b4b92c46d293a0bb06bb00a04af6e0/packages/extension-mention/src/mention.ts
 *
 * The only difference is that this version is totally decoupled from @tiptap/extension-suggestion.
 */
import { mergeAttributes, Node, Range } from '@tiptap/core'
import { Node as ProseMirrorNode } from 'prosemirror-model'

import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from 'modules/tiptap_editor/react'

import { ExtensionPriorityMap } from '../constants'
import { MENTION_SUGGESTION_CHARACTER } from '../MentionSuggestionMenu'
import { attrsOrDecorationsChanged } from '../updateFns'
import { MentionTag } from './MentionTag'

const UserMentionNodeView = ({ node }) => {
  const { label } = node.attrs
  return (
    <NodeViewWrapper as="span">
      <MentionTag mentionLabel={`@${label}`} />
    </NodeViewWrapper>
  )
}

export type UserMentionOptions = {
  HTMLAttributes: Record<string, any>
  renderLabel: (props: {
    options: UserMentionOptions
    node: ProseMirrorNode
  }) => string
  suggestion: { char: string }
}
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mention: {
      addUserMention: (
        { id, label, profileImageUrl, mentionedById }: UserMentionArgs,
        range: Range
      ) => ReturnType
    }
  }
}

type UserMentionArgs = {
  id: string
  label?: string
  profileImageUrl?: string
  mentionedById?: string
}

export const UserMention = Node.create<UserMentionOptions>({
  name: 'mention',

  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
      },
      suggestion: {
        // We are storing this in an option because it's better than hardcoding the character
        // into the Backspace command (see below)
        char: MENTION_SUGGESTION_CHARACTER,
      },
    }
  },
  priority: ExtensionPriorityMap.UserMention,
  group: 'inline',
  inline: true,
  selectable: true,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {}
          }

          return {
            'data-id': attributes.id,
          }
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {}
          }

          return {
            'data-label': attributes.label,
          }
        },
      },

      profileImageUrl: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-profile-image-url'),
        renderHTML: (attributes) => {
          if (!attributes.profileImageUrl) {
            return {}
          }

          return {
            'data-profile-image-url': attributes.profileImageUrl,
          }
        },
      },

      mentionedById: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-mentioned-by-id'),
        renderHTML: (attributes) => {
          if (!attributes.mentionedById) {
            return {}
          }

          return {
            'data-mentioned-by-id': attributes.mentionedById,
          }
        },
      },
    }
  },

  addCommands() {
    return {
      addUserMention:
        (
          {
            id,
            label,
            profileImageUrl,
            mentionedById,
          }: UserMentionArgs /*todo */,
          range
        ) =>
        ({ state, chain }) => {
          const nodeAfter = state.selection.$to.nodeAfter
          const overrideSpace = nodeAfter?.text?.startsWith(' ')

          if (overrideSpace) {
            range.to += 1
          }
          chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: { id, label, profileImageUrl, mentionedById },
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run()

          return true
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-type': this.name },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ]
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },

  addNodeView() {
    return ReactNodeViewRenderer(UserMentionNodeView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
