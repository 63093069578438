import { Extension } from '@tiptap/core'

import { createAnnotatableActivatorPlugin } from './AnnotatableActivatorPlugin'

export const AnnotatableActivatorExtension = Extension.create({
  name: 'annotatableActivator',

  addProseMirrorPlugins() {
    return [createAnnotatableActivatorPlugin(this.editor)]
  },
})
