import {
  Extension,
  JSONContent,
  NodeViewProps,
  NodeViewRenderer,
  NodeViewRendererProps,
} from '@tiptap/core'
import { Editor } from '@tiptap/react'
import cloneDeep from 'lodash/cloneDeep'
import { Plugin } from 'prosemirror-state'
import { Mappable, ReplaceAroundStep, StepMap } from 'prosemirror-transform'
import { NodeView as ProseMirrorNodeView } from 'prosemirror-view'

import { getBaseExtensions } from '../../EditorCore'
import { ReactNodeView, ReactNodeViewRendererOptions } from '../../react'
import { BubbleMenu } from '../BubbleMenu/bubble-menu-plugin'
import { EmojiShortcuts } from '../Emoji'
import { FocusedNodes } from '../FocusedNodes'
import { MediaUpload } from '../media/Upload'
import { MentionSuggestionMenu } from '../MentionSuggestionMenu'
import { SlashMenu } from '../SlashMenu'

type InnerEditorNodeViewOptions = {
  nodeName: string
  preventNodeTypes?: string[]
} & Partial<ReactNodeViewRendererOptions>

export const INNER_EDITOR_META_KEY = 'fromInnerEditor'
export const OUTER_EDITOR_META_KEY = 'fromOuterEditor'

// Based on https://prosemirror.net/examples/footnote/ but adapted to work with Tiptap
class InnerEditorNodeView extends ReactNodeView {
  innerEditor?: Editor
  editorOptions: InnerEditorNodeViewOptions
  isDestroyed: boolean = false

  updateProps(props: Partial<InnerEditorNodeViewProps>) {
    if (this.isDestroyed) return // updateProps can cause a destroyed node to re-create itself, creating a ghost duplicate
    // @ts-ignore - we're not running TypeScript in ReactNodeViewRenderer.tsx so this isn't exposed
    this.renderer.updateProps(props)
  }

  constructor(
    component: React.FunctionComponent,
    props: NodeViewRendererProps,
    options: InnerEditorNodeViewOptions
  ) {
    super(component, props, options)
    this.editorOptions = options
    this.updateProps({
      mountEditor: () => this.mountEditor(),
      destroyEditor: () => {
        this.innerEditor?.destroy()
        this.updateProps({
          innerEditor: undefined,
        })
      },
    })
  }

  mountEditor() {
    const outerEditor = this.editor
    const { nodeName, preventNodeTypes } = this.editorOptions
    const innerExtensions = [
      ...getBaseExtensions()
        .filter(
          (ex) => !preventNodeTypes || !preventNodeTypes.includes(ex.name)
        )
        .map((ex) =>
          // The node we're building this view for will be the topNode of the inner editor
          // .extend returns a new copy of the extension instead of mutating the original
          // https://github.com/ueberdosis/tiptap/blob/ab4a0e2507b4b92c46d293a0bb06bb00a04af6e0/packages/core/src/Extension.ts#L340-L342
          ex.name === nodeName ? ex.extend({ topNode: true }) : ex
        ),
      SlashMenu,
      EmojiShortcuts,
      FocusedNodes,
      MediaUpload,
      InnerEditorExtension.configure({ outerEditor, getPos: this.getPos }),
      MentionSuggestionMenu,
      BubbleMenu,
    ]
    this.innerEditor = new Editor({
      extensions: innerExtensions,
      content: {
        // In the inner editor, the root node should correspond to the outer node type
        type: nodeName,
        content: this.node.content.toJSON() as JSONContent[],
        attrs: cloneDeep(this.node.attrs),
      },
      onBeforeCreate({ editor }) {
        // This lets us enforce that the inner editor uses the exact same node types as the outer one (referential equality)
        // Shallow clone the outer schema - we want the same node and mark types, but with problematic ones taken out
        editor.schema.nodes = { ...outerEditor.schema.nodes }
        // These nodes also need to be removed from `innerExtensions` above or else we'll get an error
        // @ts-ignore - schema is meant to be readonly so you don't break the doc while it's in use,
        // but mutating its nodes is the only way to keep the referential equality
        preventNodeTypes?.forEach((name) => delete editor.schema.nodes[name])
        editor.schema.marks = { ...outerEditor.schema.marks }
        editor.schema.topNodeType = outerEditor.schema.nodes[nodeName]
        editor.extensionManager.schema = editor.schema // Required to make input rules work
      },
    })
    this.innerEditor.gammaOrgId = outerEditor.gammaOrgId
    this.innerEditor.gammaDocId = outerEditor.gammaDocId

    this.updateProps({
      innerEditor: this.innerEditor,
    })
  }

  destroy() {
    this.isDestroyed = true
    this.innerEditor?.destroy()
    super.destroy()
  }

  // When the outer editor gets an update, send the transform steps to the inner editor
  // Note: this mapping requires a matching node/mark types between both editors
  update(node, decorations) {
    if (!node.sameMarkup(this.node)) return false
    this.node = node
    const innerView = this.innerEditor?.view
    if (!innerView) return true
    const state = innerView.state

    const start = node.content.findDiffStart(state.doc.content)
    if (start != null) {
      let { a: endA, b: endB } = node.content.findDiffEnd(state.doc.content)
      const overlap = start - Math.min(endA, endB)
      if (overlap > 0) {
        endA += overlap
        endB += overlap
      }

      innerView.dispatch(
        state.tr
          .replace(start, endB, node.slice(start, endA))
          .setMeta(OUTER_EDITOR_META_KEY, true)
          .setMeta('preventAutolink', true)
      )
    }
    return super.update(node, decorations)
  }

  stopEvent(event) {
    return (
      !!this.innerEditor && this.innerEditor.view.dom.contains(event.target)
    )
  }

  ignoreMutation() {
    return true
  }

  // Unlike a typical NodeView, we're not using <NodeViewContent>. Overriding this getter fixes
  // a bug where editing text around the note would cause the note to be deleted. This happened
  // because ProseMirror's parser returns an empty fragment here when a node has ContentDOM
  // (I don't understand why tho) https://github.com/ProseMirror/prosemirror-view/blob/089aa9bd8f261d8181782bc5f8c46c5dc5e11a5f/src/viewdesc.js#L676
  get contentDOM() {
    return null
  }
}

// Copied from ReactNodeViewRenderer.tsx, just using InnerEditorNodeView instead of ReactNodeView
export function InnerEditorNodeViewRenderer(
  component: any,
  options: InnerEditorNodeViewOptions
): NodeViewRenderer {
  return (props: NodeViewRendererProps) => {
    return new InnerEditorNodeView(
      component,
      props,
      options
    ) as ProseMirrorNodeView
  }
}

export type InnerEditorNodeViewProps = NodeViewProps & {
  innerEditor?: Editor
  mountEditor?: () => void
  destroyEditor?: () => void
}

type InnerEditorExtensionOptions = {
  outerEditor: Editor
  getPos: NodeViewProps['getPos']
}

// Any new plugins or commands we want for the inner editor only can be put in here
const InnerEditorExtension = Extension.create<InnerEditorExtensionOptions>({
  name: 'innerEditorExtension',

  // @ts-ignore - we don't actually want null values here, you should have to configure this extension to use it
  addOptions() {
    return {
      outerEditor: null,
      getPos: null,
    }
  },

  addProseMirrorPlugins() {
    const { outerEditor, getPos } = this.options
    return [
      new Plugin({
        appendTransaction: (transactions) => {
          // When the inner editor gets an update, send the transform steps to the outer editor
          // Note: this mapping requires matching node/mark types between both editors
          // The ProseMirror example uses dispatchTransaction for this, but that overrides Tiptap's dispatchTransaction
          // which powers their event emitters like onSelectionUpdate. So we're using appendTransaction instead.
          const tr = transactions[0]
          if (!tr) return null

          // Ignore changes from the outer editor that we ourselves just sent from here
          if (!tr.getMeta(OUTER_EDITOR_META_KEY)) {
            const outerTr = outerEditor.state.tr
            const offsetMap = StepMap.offset(getPos() + 1)
            for (let i = 0; i < transactions.length; i++) {
              const steps = transactions[i].steps
              for (let j = 0; j < steps.length; j++) {
                const step = steps[j]
                let newStep = step.map(offsetMap)
                if (
                  !newStep &&
                  step instanceof ReplaceAroundStep &&
                  step.from == 0
                ) {
                  // ReplaceAroundStep.map doesn't work with offset maps when the step
                  // touches position 0. So as a backup, we map it with a slight fork of the logic.
                  // https://linear.app/gamma-app/issue/G-3148/cant-paste-into-a-blockquote-inside-a-footnote
                  newStep = mapReplaceAroundStepAtStart(step, offsetMap)
                }
                if (!newStep) {
                  continue
                }
                outerTr.step(newStep)
              }
            }
            outerTr
              .setMeta(INNER_EDITOR_META_KEY, true)
              .setMeta('preventAutolink', true)
            if (outerTr.docChanged) outerEditor.view.dispatch(outerTr)
          }

          return null
        },
      }),
    ]
  },

  // Forward undo and redo to the outer editor, which controls the state
  addKeyboardShortcuts() {
    const { outerEditor } = this.options
    return {
      'Mod-z': () => outerEditor.commands.undo(),
      'Mod-y': () => outerEditor.commands.redo(),
    }
  },
})

// Fork of https://github.com/ProseMirror/prosemirror-transform/blob/d322f415ee89e8a9cd58bbd24ed69e33587a8f2b/src/replace_step.ts#L137
// that just changes one line, marked below. This fixes a case where we run a ReplaceAroundStep (eg insert a blockquote) on the very
// first character of a footnote
const mapReplaceAroundStepAtStart = (
  step: ReplaceAroundStep,
  mapping: Mappable
) => {
  const from = mapping.mapResult(step.from, 1),
    to = mapping.mapResult(step.to, -1)
  const gapFrom = mapping.map(step.gapFrom, 1), // This was changed from -1 to 1
    gapTo = mapping.map(step.gapTo, 1)
  if (
    (from.deletedAcross && to.deletedAcross) ||
    gapFrom < from.pos ||
    gapTo > to.pos
  )
    return null
  return new ReplaceAroundStep(
    from.pos,
    to.pos,
    gapFrom,
    gapTo,
    step.slice,
    step.insert,
    // @ts-ignore - this is a private property
    step.structure
  )
}
