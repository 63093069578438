import { Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { attrsOrDecorationsChanged } from '../updateFns'
import { ExpandableSummaryView } from './ExpandableSummaryView'

export const ExpandableSummary = Node.create({
  name: 'expandableSummary',
  content: 'inline*',

  marks: 'bold italic underline code footnoteLabel strike textColor highlight',

  allowFontSizes: 'heading body',
  addAttributes() {
    return {
      fontSize: {
        default: null,
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(ExpandableSummaryView, {
      update: attrsOrDecorationsChanged,
    })
  },

  renderHTML({ HTMLAttributes }) {
    return ['summary', HTMLAttributes, 0]
  },

  parseHTML() {
    return [
      {
        tag: 'summary',
      },
    ]
  },
})
