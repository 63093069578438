import { Mark } from '@tiptap/core'
import { mergeAttributes } from '@tiptap/react'

import { ExtensionPriorityMap } from '../constants'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textColor: {
      setTextColorVariant: (attributes?: { variant: string }) => ReturnType
    }
  }
}

export const TextColor = Mark.create({
  name: 'textColor',
  excludes: 'highlight textColor',
  priority: ExtensionPriorityMap.TextColor,
  addAttributes() {
    return {
      variant: {
        default: 'blue',
      },
    }
  },
  addCommands() {
    return {
      setTextColorVariant:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes)
        },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'span[class=textColor]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        class: 'textColor',
      }),
    ]
  },
})
