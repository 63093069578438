import ImageNode from '@tiptap/extension-image'
import { NodeSelection } from 'prosemirror-state'

import { ImageUploadResult, UploadStatus } from 'modules/media'
import { getStore } from 'modules/redux'
import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { configureJSONAttribute } from '../../../utils'
import { ExtensionPriorityMap } from '../../constants'
import { HorizontalAlignment } from '../../HorizontalAlign'
import { attrsOrDecorationsChanged } from '../../updateFns'
import { eventEmitter } from './eventEmitter'
import { ImageView } from './ImageView'
import { selectIsCropping } from './reducer'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageCommands: {
      /**
       * Sets the image clipping back to default
       */
      resetImageClip: () => ReturnType
      /**
       * Sets the image scale back to default
       */
      resetImageScale: () => ReturnType
    }
  }
}

export const DEFAULT_RESIZE_STATE = {
  clipType: null,
  clipPath: null,
  clipAspectRatio: null,
  width: null,
} as const

export type ResizeAttrs = {
  width: number | null
  clipType: 'inset' | 'circle' | null
  clipPath: string[] | null
  clipAspectRatio: number | null
}

export type ImageAttrs = {
  id: string
  src?: string | null
  tempUrl?: string | null
  uploadStatus?: UploadStatus | null
  meta?: ImageUploadResult['meta'] | null
  name?: string | null
  query?: string // The search query that led to this image, if applicable
  source?: string
  showPlaceholder?: boolean
  horizontalAlign: HorizontalAlignment
  isFullWidth: boolean
  resize?: ResizeAttrs
}

export const Image = ImageNode.extend({
  selectable: true,
  draggable: true,
  group: 'block media',
  priority: ExtensionPriorityMap.Image,

  addOptions() {
    return {
      ...this.parent?.(), // Extend parent options
      placeholders: {},
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageView, {
      update: attrsOrDecorationsChanged,
    })
  },

  parseHTML() {
    return [
      {
        tag: 'img[src], img[tempUrl]',
      },
    ]
  },

  addAttributes() {
    return {
      src: {},
      tempUrl: {},
      uploadStatus: {},
      meta: {
        ...configureJSONAttribute('meta'),
      },
      name: {},
      query: {},
      source: {},
      showPlaceholder: {},
      isFullWidth: { default: false },
      resize: {
        ...configureJSONAttribute('resize'),
        default: DEFAULT_RESIZE_STATE,
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const selection = editor.state.selection
        if (
          selection instanceof NodeSelection &&
          selection.node.type.name === ImageNode.name
        ) {
          const isCropping = selectIsCropping(getStore().getState())
          if (isCropping) {
            eventEmitter.emit('endClip', { confirm: true })
            return true
          }
        }
        return false
      },
    }
  },

  addCommands() {
    const nodeName = this.name
    const getSelectedImage = (state) => {
      const { selection } = state
      return selection instanceof NodeSelection &&
        selection.node.type.name === nodeName
        ? selection.node
        : undefined
    }
    return {
      resetImageClip:
        () =>
        ({ chain, state }) => {
          const selectedNode = getSelectedImage(state)
          if (!selectedNode) {
            console.warn(
              '[resetImageClip] Selected node is not an image: ',
              state.selection
            )
            return true
          }

          const { clipPath, clipType, clipAspectRatio } = DEFAULT_RESIZE_STATE
          return chain()
            .updateAttributes(nodeName, {
              resize: {
                ...selectedNode.attrs.resize,
                clipPath,
                clipType,
                clipAspectRatio,
              },
            })
            .run()
        },
      resetImageScale:
        () =>
        ({ chain, state }) => {
          const selectedNode = getSelectedImage(state)
          if (!selectedNode) {
            console.warn(
              '[resetImageClip] Selected node is not an image: ',
              state.selection
            )
            return true
          }

          return chain()
            .updateAttributes(nodeName, {
              resize: {
                ...selectedNode.attrs.resize,
                width: null,
              },
            })
            .run()
        },
    }
  },
})
