import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Card } from 'modules/api'
import { RootState } from 'modules/redux'

export type CardState = {
  cards: Card[]
}

const initialState: CardState = {
  cards: [],
}

const CardsSlice = createSlice({
  name: 'Cards',
  initialState,
  reducers: {
    reset: () => initialState,
    setCards(
      state: CardState,
      action: PayloadAction<{
        cards: Card[]
      }>
    ) {
      const { cards } = action.payload
      state.cards = cards
    },
  },
})

export const { setCards, reset } = CardsSlice.actions

type SliceState = Pick<RootState, 'Cards'>

// Selectors
export const selectCards = (state: SliceState) => state.Cards.cards

export const selectCard =
  (id?: string) =>
  (state: SliceState): Card | undefined => {
    return state.Cards.cards.find((c) => c.id === id)
  }

// Reducer
export const CardsReducer = CardsSlice.reducer
