import { Node } from 'prosemirror-model'

import { getStore } from 'modules/redux'
import {
  selectToggleExpanded,
  setToggleExpanded,
} from 'modules/tiptap_editor/reducer'

export const isExpandableOpen = (id: string) => {
  const store = getStore()
  return selectToggleExpanded(id)(store.getState()) || false
}

export const toggleExpandableOpen = (id: string) => {
  const store = getStore()
  const isOpen = isExpandableOpen(id)
  store.dispatch(setToggleExpanded({ toggleId: id, isExpanded: !isOpen }))
}

export const isExpandableToggleNode = (node: Node) => {
  return node.type.name === 'expandableToggle'
}

export const isExpandableSummaryNode = (node: Node) => {
  return node.type.name === 'expandableSummary'
}
