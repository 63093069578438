import { range } from 'lodash'

import { CommandInfo } from 'modules/tiptap_editor/commands'

import { DefaultCellContent } from './constants'
import { SmartLayoutVariants } from './variants'

const NUM_CELLS = 3

export const SmartLayoutCommands: CommandInfo[] = SmartLayoutVariants.map(
  (variant) => {
    const { key, name, description, icon, defaultContent } = variant
    return {
      key: `smartLayout-${key}`,
      name: `${name} layout`,
      description,
      nodeName: 'smartLayout',
      icon,
      execute: (editor) => {
        editor
          .chain()
          .focus()
          .insertContentAndSelect({
            type: 'smartLayout',
            attrs: {
              variantKey: key,
            },
            content: range(NUM_CELLS).map(() => {
              return {
                type: 'smartLayoutCell',
                content: defaultContent || DefaultCellContent,
              }
            }),
          })
          .run()
      },
      featureFlag: 'smartLayout',
    }
  }
)
