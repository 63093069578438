import { Extension } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'

import { keyboardHandler } from 'modules/keyboard'

import { ExtensionPriorityMap } from '../constants'

// Global keyboard shortcut catchall for any keyboard
// events that bubble all the way through without being handled.
// This should have the lowest priority
export const KeyBoardCatchall = Extension.create({
  name: 'KeyBoardCatchall',
  priority: ExtensionPriorityMap.KeyBoardCatchall,
  addKeyboardShortcuts() {
    return {
      // Intercept Tab commands in the editor if nothing else handles them
      // to prevent focus from jumping to elements outside of the editor
      // Only do this if the editor is not focused (blurred) to allow
      // keyboard navigation out of the editor via Escape below
      Tab: ({ editor }) => editor.view.hasFocus(),
      'Shift-Tab': ({ editor }) => editor.view.hasFocus(),
    }
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleKeyDown: (view, event) => {
            // If we get here, no other extension handled the event
            // so our window-level keyboard handler should do it
            return keyboardHandler.handleKeydown(event)
          },
        },
      }),
    ]
  },
})
