import { featureFlags } from 'modules/featureFlags'
import { EventEmitter } from 'utils/EventEmitter'

type ViewedCards = { [cardId: string]: number }

export class CardViewedEmitter extends EventEmitter<{
  cardViewed: {
    cardId: string
    // interval is ms
    interval: number
  }
  flush: {
    viewed: ViewedCards
  }
}> {
  protected viewedCards: ViewedCards = {}
  private cardViewedThreshold = featureFlags.get('cardViewedThreshold')

  initialize() {
    this.on('cardViewed', ({ cardId, interval }) => {
      this.viewedCards[cardId] = this.viewedCards[cardId] || 0
      this.viewedCards[cardId] += interval
    })
    const setThresholdFromFlagCB = () => {
      this.cardViewedThreshold = featureFlags.get('cardViewedThreshold') ?? 5000
    }
    featureFlags.subscribe('cardViewedThreshold', setThresholdFromFlagCB)
    setThresholdFromFlagCB()
  }

  flush() {
    const viewed: ViewedCards = {}

    for (const cardId in this.viewedCards) {
      const duration = this.viewedCards[cardId]
      if (!duration || duration < this.cardViewedThreshold) {
        continue
      }

      // normalize to seconds
      viewed[cardId] = Math.floor(duration / 1000)
    }

    this.emit('flush', {
      viewed,
    })
    this.viewedCards = {}
  }

  destroy() {
    this.flush()
    this.off('cardViewed')
    this.off('flush')
  }
}

export const cardViewedEmitter = new CardViewedEmitter()
