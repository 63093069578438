// Based on
// https://github.com/outline/rich-markdown-editor/blob/main/src/commands/insertFiles.ts

import { Editor, JSONContent } from '@tiptap/core'
import { nanoid } from 'nanoid'

import {
  CustomImageProvider,
  ImageUploadResult,
  uploadFile,
  UploadStatus,
  UppyUploadHandlers,
} from 'modules/media'
import { findSelectionInsideNode } from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'
import { getFileExtension, isDocUpload } from 'utils/file'
import { isHEICFileType, isImageMimeType } from 'utils/image'

import { ImageAttrs } from '../Image'
import { WebEmbedAttrs } from '../types'
import {
  getTempPDFAttrs,
  handleImageUploadFailed,
  handleImageUploadSuccess,
  handlePDFUploadSuccess,
} from './utils'

type FileUpload = {
  name: string
  content: JSONContent
  upload: () => void
}

export const insertAndUploadFiles = function (
  editor: Editor,
  files: File[],
  insertPos: number
) {
  const uploads: FileUpload[] = files
    .map((file) => {
      if (isImageMimeType(file.type)) {
        return setupImageUpload(file, editor)
      } else if (isDocUpload(file.type, getFileExtension(file))) {
        return setupPDFUpload(file, editor)
      } else {
        return null
      }
    })
    .filter((upload): upload is FileUpload => upload !== null)

  if (uploads.length == 0) {
    return
  }
  const contents = uploads.map((a) => a.content)
  const to = insertPos
  const $insertPos = editor.state.doc.resolve(insertPos)

  // if the insert pos is at the beginning of text node, replace the start of the node
  // to prevent empty line before
  const from =
    $insertPos.parentOffset === 0 && $insertPos.parent.inlineContent
      ? insertPos - 1
      : insertPos

  editor
    .chain()
    .insertContentAt({ from, to }, contents)
    .command(({ tr }) => {
      // select the last inserted image
      const $to = tr.doc.resolve(tr.mapping.map(to))
      const sel = findSelectionInsideNode(
        tr.doc.resolve($to.pos - ($to.nodeBefore?.nodeSize || 1))
      )
      if (sel) {
        tr.setSelection(sel)
      }
      return true
    })
    .run()

  uploads.forEach((item) => {
    item.upload()
  })

  // put scrollIntoView in set timeout to allow browser to render images for correct
  // calculation of the scroll position
  setTimeout(() => {
    editor.commands.scrollIntoView()
  }, 200)
}

const setupImageUpload = (file: File, editor: Editor): FileUpload => {
  const attrs: Partial<ImageAttrs> = {
    uploadStatus: UploadStatus.Uploading,
    source: CustomImageProvider.key,
  }

  // if HEIC file type, can't display temporary url, instead show the image placeholder
  if (isHEICFileType(file.type)) {
    attrs.showPlaceholder = true
    attrs.tempUrl = `HEIC_tempId_${nanoid()}`
  } else {
    attrs.tempUrl = URL.createObjectURL(file)
  }

  const onUploadComplete = (result, previousUrl?) => {
    const tempUrl = attrs.tempUrl
    if (!tempUrl && !previousUrl) return
    const existingUrl = tempUrl || previousUrl
    handleImageUploadSuccess(editor, existingUrl, result)
  }

  const onUploadFailed = (error) => {
    if (!attrs.tempUrl) return
    handleImageUploadFailed(editor, attrs.tempUrl, error)
    URL.revokeObjectURL(attrs.tempUrl!)
  }

  const uppyUploadHandlers: UppyUploadHandlers = {
    onOriginalFileUpload: onUploadComplete,
    onUploadComplete,
    onUploadFailed,
  }

  const content: JSONContent = {
    type: 'image',
    attrs,
  }
  return {
    name: file.name,
    content,
    upload: () =>
      uploadFile(file, editor.storage.mediaUpload?.orgId, uppyUploadHandlers),
  }
}

const setupPDFUpload = (file: File, editor: Editor): FileUpload => {
  const tempUrl = URL.createObjectURL(file)

  const attrs: Partial<WebEmbedAttrs> = {
    ...getTempPDFAttrs(file),
    url: tempUrl,
  }

  const onUploadComplete = (result: ImageUploadResult, previousUrl: string) => {
    if (!tempUrl && !previousUrl) return
    const existingUrl = tempUrl || previousUrl
    handlePDFUploadSuccess(editor, existingUrl, result)
  }

  const uppyUploadHandlers: UppyUploadHandlers = {
    onOriginalFileUpload: onUploadComplete,
    onUploadComplete,
  }

  const content: JSONContent = {
    type: 'embed',
    attrs,
  }
  return {
    name: file.name,
    content,
    upload: () =>
      uploadFile(
        file,
        editor.storage.mediaUpload?.orgId,
        uppyUploadHandlers,
        'node',
        'doc'
      ),
  }
}
