import { IconButton, useColorMode } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/core'
import { memo } from 'react'

import { useManageCardMenu } from 'modules/tiptap_editor/extensions/Card/ManageCardMenu/hooks'
import { getCardUrl } from 'utils/url'
import { EditorModeEnum } from 'modules/tiptap_editor/types'

type CardMenuProps = {
  cardId: string
  docId?: string
  shouldShowButton: boolean
  getPos: () => number | null
  editorMode: EditorModeEnum
} & Pick<NodeViewProps, 'editor'>

export const CardMenu = memo(function CardMenuMemo({
  cardId,
  docId,
  getPos,
  shouldShowButton,
  editorMode,
}: CardMenuProps) {
  const cardUrl = getCardUrl({
    cardId,
    docId,
    isPresentMode: editorMode === EditorModeEnum.SLIDE_VIEW,
  })
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  const { openManageCardMenuClickHandler, isMenuOpen, activatorRef } =
    useManageCardMenu({
      cardUrl,
      getPos,
    })

  if (!shouldShowButton && !isMenuOpen) {
    return null
  }
  return (
    <GammaTooltip label={'Card options'} placement="top">
      <IconButton
        ref={activatorRef}
        aria-label="card options"
        onClick={(e) => {
          e.stopPropagation()
          openManageCardMenuClickHandler()
        }}
        isRound={true}
        icon={<FontAwesomeIcon icon={regular('ellipsis-h')} />}
        size="xs"
        variant="ghost"
        _hover={{ bg: isDark ? 'whiteAlpha.100' : 'blackAlpha.100' }}
      >
        Actions
      </IconButton>
    </GammaTooltip>
  )
})
