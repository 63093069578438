// This is a duplicate of useLocalStorage.
// They have the same API: https://developer.mozilla.org/en-US/docs/Web/API/Storage

import { useCallback, useState } from 'react'

const isWindowDefined = typeof window !== 'undefined'

export function useSessionStorage<R>(
  key: string,
  defaultValue: R
): [R, (val: R) => void] {
  const [state, setState] = useState(() => {
    try {
      const raw = isWindowDefined ? sessionStorage.getItem(key) : null
      if (raw === null) {
        return defaultValue
      }
      return JSON.parse(raw) as R
    } catch (e) {
      console.error(
        '[useSessionStorage.getItem] Error getting sessionStorage item',
        e
      )
      return defaultValue
    }
  })
  const set = useCallback(
    (val: R) => {
      try {
        sessionStorage.setItem(key, JSON.stringify(val))
        setState(val)
      } catch (e) {
        console.error(
          '[useSessionStorage.setItem] Error setting sessionStorage item',
          e
        )
      }
    },
    [key]
  )

  return [state, set]
}
