import { Box, Flex } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'
import { motion } from 'framer-motion'

import { config } from 'config'

import { AnnotatableNodeViewWrapper } from '../../Annotatable/components/AnnotatableNodeViewWrapper'
import { isNodeViewInGallery } from '../Gallery'
import { WebEmbedAttrs } from '../types'
import {
  getZoomLayoutId,
  useMediaZoom,
  ZoomableOverlay,
  ZoomClickCapture,
  ZoomTransition,
} from '../Zoomable'
import { EmbedPlayer } from './EmbedPlayer'
import { EmbedPreview, MiniEmbedPreview } from './EmbedPreview'
import { getEmbedProvider } from './utils'

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)

export const EmbedView = (nodeViewProps: NodeViewProps) => {
  const { node, editor } = nodeViewProps
  const { source, embed, displayStyle, id } = node.attrs as WebEmbedAttrs
  const provider = getEmbedProvider(source)
  const { isZoomed, enterZoom, exitZoom } = useMediaZoom(id)
  const useHtmlEmbed = provider.preferHtml && embed?.html
  const inGallery = isNodeViewInGallery(nodeViewProps)

  const showInline =
    // Never show inline iframe within the context of puppeteer, because there's
    // no guarantee what will load in the iframe!
    !config.GAMMA_PUPPETEER_SERVICE &&
    (useHtmlEmbed || displayStyle === 'inline')
  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      style={{ position: 'relative' }}
    >
      <MotionBox
        layoutDependency={isZoomed}
        layoutId={getZoomLayoutId(id)}
        transition={ZoomTransition}
        h="100%"
      >
        {inGallery ? (
          <MiniEmbedPreview {...nodeViewProps} />
        ) : showInline ? (
          <EmbedPlayer {...nodeViewProps} />
        ) : (
          <EmbedPreview {...nodeViewProps} />
        )}
      </MotionBox>
      <ZoomClickCapture enterZoom={enterZoom} />
      {!inGallery && (
        <ZoomableOverlay
          isZoomed={isZoomed}
          exitZoom={exitZoom}
          editor={editor}
        >
          <MotionFlex
            layoutDependency={isZoomed}
            layoutId={getZoomLayoutId(id)}
            transition={ZoomTransition}
            h="100%"
            w="100%"
            direction="column"
            justify="center"
            align="center"
          >
            <EmbedPlayer {...nodeViewProps} isZoomed />
          </MotionFlex>
        </ZoomableOverlay>
      )}
    </AnnotatableNodeViewWrapper>
  )
}
