import { TldrawApp, TDAssetType } from '@gamma-app/tldraw'
import { useCallback } from 'react'

import { uploadFile } from 'modules/media'

export const useAssetUpload = (orgId?: string) => {
  const onAssetCreate = useCallback(
    async (app: TldrawApp, file: File, id: string): Promise<string | false> => {
      if (!orgId) {
        console.warn('[useAssetUpload] OrgId is required to upload assets')
        return false
      }
      const tempUrl = URL.createObjectURL(file)

      console.debug('[useAssetUpload] Handling upload for file', {
        file,
        id,
        tempUrl,
      })

      const onUpload = (result) => {
        const asset = app.assets.find((a) => a.id === id)

        // When the upload is complete, replace the src attribute of the existing asset
        if (asset) {
          console.debug(
            '[useAssetUpload] Upload complete. Replacing temp asset',
            { id, src: result.src }
          )
          const patch = {
            document: {
              assets: {
                [id]: {
                  ...asset,
                  src: result.src,
                },
              },
            },
          }
          app.patchState(patch, 'upload')
          app.persist(patch, 'upload')
        }
      }

      // Start uploading the image to transloadit ASAP, but dont block on it
      uploadFile(file, orgId, {
        onOriginalFileUpload: onUpload,
        onUploadComplete: onUpload,
      })

      app.patchState({
        document: {
          assets: {
            [id]: {
              id,
              type: TDAssetType.Image,
              fileName: file.name,
              src: tempUrl,
            },
          },
        },
      })

      return tempUrl
    },
    [orgId]
  )

  const onAssetDelete = useCallback(
    async (app: TldrawApp, id: string): Promise<boolean> => {
      // Should we do anything here?
      return true
    },
    []
  )

  return { onAssetCreate, onAssetDelete }
}
