import { ResolvedPos } from 'prosemirror-model'

import { findSelectionNearOrGapCursor } from './findSelectionNearOrGapCursor'

export function findIsolatingSelectionPos(
  $pos: ResolvedPos,
  dir: number = 1
): number {
  const forward = dir > 0
  const { parent, doc } = $pos
  const start = $pos.start()
  const end = $pos.after()

  if (parent.type.spec.isolating) {
    const $newPos = doc.resolve(forward ? end - 1 : start)
    const found = findSelectionNearOrGapCursor($newPos, forward ? 1 : -1)!

    if (forward) {
      const $result = doc.resolve(found.to)
      const n = $result.node()
      if (n && n.isTextblock && n.content.size === 0) {
        // is a text block node we want to end selection at the node's end
        return found.to + 1
      }
    }

    return forward ? found.to : found.from
  }

  const parentPos = $pos.start($pos.depth - 1)

  return findIsolatingSelectionPos($pos.doc.resolve(parentPos), dir)
}
