import { findChildren } from '@tiptap/core'
import { ResolvedPos } from 'prosemirror-model'
import { NodeSelection, Selection, Transaction } from 'prosemirror-state'
import { ReplaceAroundStep, ReplaceStep } from 'prosemirror-transform'

/**
 * Try to traverse the selection to either the first or last selectable text
 * if no selectable text is available, then select the node
 */
export function findSelectionInsideNode(
  $node: ResolvedPos,
  bias: number = 1
): Selection | null {
  const { doc } = $node
  const last = bias < 0
  if (!$node.nodeAfter) {
    return null
  }
  if ($node.nodeAfter.inlineContent) {
    return Selection.near(
      doc.resolve($node.pos + (last ? $node.nodeAfter.content.size + 1 : 0)),
      bias
    )
  }
  const found = findChildren(
    $node.nodeAfter!,
    (n) => n.inlineContent || NodeSelection.isSelectable(n)
  )

  // no selectable children to be found, select the entire node
  if (!found || found.length === 0) {
    return NodeSelection.create(doc, $node.pos)
  }

  // $node here is parent .node() but nodeAfter is the one we used to find the pos offse
  const pos =
    $node.start($node.depth + 1) +
    (last ? found[found.length - 1].pos : found[0].pos)

  return findSelectionInsideNode(doc.resolve(pos), bias)
}

export const getInsertedNodePos = (
  tr: Transaction,
  typeName?: string
): ResolvedPos | null => {
  const doc = tr.doc
  const last = tr.steps.length - 1
  const step = tr.steps[last]
  if (
    !step ||
    !(step instanceof ReplaceStep || step instanceof ReplaceAroundStep)
  ) {
    return null
  }

  if (step.slice.content.size === 0) {
    return null
  }

  const typeNameToUse =
    typeName || step.slice.content.child(step.slice.openStart)?.type.name

  const map = tr.mapping.maps[last]
  let from: number | undefined
  map.forEach((_from, _to, newFrom) => {
    if (from == null) from = newFrom
  })
  if (from == null) {
    return null
  }

  const nodeAtFrom = doc.nodeAt(from)
  if (nodeAtFrom && nodeAtFrom.type.name === typeNameToUse) {
    return doc.resolve(from)!
  }

  if (!typeNameToUse) {
    console.warn(
      '[getInsertedNodePos] Error selecting node, type not found in slice.lastChild',
      from
    )
    return null
  }

  // we have a node type specified, iterate through the newly inserted range
  // and find the node
  for (let p = step.from; p < tr.mapping.map(step.to); p++) {
    const node = tr.doc.nodeAt(p)
    if (node && node.type.name === typeNameToUse) {
      return doc.resolve(p)
    }
  }
  return null
}
