import { useEffect } from 'react'

import { setConfigValue, config } from 'config'
import { Doc, User, useAddCollaboratorByAccessLinkMutation } from 'modules/api'
import { replaceState } from 'modules/history'
import { abilityFactory, useUserContext } from 'modules/user'
import { useHandleError } from 'utils/hooks'

export type DocWithIdAndAccessLinks = Pick<Doc, 'accessLinks' | 'id'>

export const clearShareToken = () => {
  // clear share token from config
  setConfigValue('SHARE_TOKEN', '')

  // strip `token` query from url
  const { searchParams } = new URL(window.location.href)
  searchParams.delete('token')
  const query = Object.fromEntries(searchParams.entries())
  replaceState({ query })
}

export const useAccessLinkCollaborator = (doc?: DocWithIdAndAccessLinks) => {
  const { user } = useUserContext()
  const [addCollaborator, { error: addError }] =
    useAddCollaboratorByAccessLinkMutation()
  useHandleError(
    '[useAccessLinkCollaborator]: Error updating collaborator.',
    addError
  )

  useEffect(() => {
    if (!user || !doc) return

    const accessLink = doc.accessLinks?.find(
      (l) => l.token === config.SHARE_TOKEN
    )
    if (!accessLink) return

    // if we have a accessLink, a doc and a user
    // get the user's ability without the share token
    // (what they'd see if they come back to the dashboard)
    const ability = abilityFactory.createForUser({
      ...user,
      __typename: 'User',
    } as User)

    // if the user doesn't have the permission of the access link
    // add them as a collaborator so they'll get that permission
    if (accessLink.permission && ability.cannot(accessLink.permission, doc)) {
      addCollaborator({
        variables: {
          docId: doc.id,
        },
      })
        .then(clearShareToken)
        .catch((err) =>
          console.error(
            '[useAccessLinkCollaborator]: Error adding collaborator.',
            err
          )
        )
    } else {
      clearShareToken()
    }
  }, [addCollaborator, doc, user])
}

export const checkAccessLinkForLoggedOutUser = (
  doc?: DocWithIdAndAccessLinks,
  user?: User
) => {
  if (!doc || user) return null

  const accessLink = doc?.accessLinks?.find(
    (l) => l.token === config.SHARE_TOKEN
  )
  if (!accessLink || !accessLink.permission) return null

  return accessLink
}
