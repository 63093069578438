import { useCallback, useEffect, useRef, useState } from 'react'

import { useAppDispatch, useAppStore } from 'modules/redux'

import { ManageCardMenuOptions } from './ManageCardMenu'
import { showManageCardMenu, updateManageCardMenuOptions } from './reducer'

export const useManageCardMenu = (options: ManageCardMenuOptions) => {
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const activatorRef = useRef<HTMLButtonElement | null>(null)
  const { getPos, cardUrl } = options

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const onClose = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  useEffect(() => {
    if (isMenuOpen) {
      dispatch(updateManageCardMenuOptions({ getPos, cardUrl }))
    }
  }, [getPos, dispatch, isMenuOpen, cardUrl])

  const openManageCardMenu = useCallback(() => {
    setIsMenuOpen(true)
    dispatch(
      showManageCardMenu({ ...options, onClose, ref: activatorRef.current! })
    )
  }, [dispatch, options, onClose])

  const openManageCardMenuClickHandler = useCallback(() => {
    const lastRef = store.getState().ManageCardMenu.lastRef
    // since the clickOutside handler will occur synchronously before
    // this click handler.  If the last activator ref was this button
    // don't open the menu, instead let it "toggle" by allowing the
    // clickOutside handler to close the menu
    if (lastRef && lastRef === activatorRef.current) {
      return
    }
    openManageCardMenu()
  }, [openManageCardMenu, store])

  return {
    isMenuOpen,
    openManageCardMenuClickHandler,
    activatorRef,
  }
}
