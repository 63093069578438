import { markInputRule } from '@tiptap/core'
import TiptapItalic from '@tiptap/extension-italic'

// Override default regex such that `_italic_` and `__italic__` both produce italic text
// https://github.com/ueberdosis/tiptap/blob/6c34dec33ac39c9f037a0a72e4525f3fc6d422bf/packages/extension-italic/src/italic.ts

export const underscoreInputRegex = /(?:^|\s)((?:_)((?:[^_]+))(?:_))$/
export const doubleUnderscoreInputRegex = /(?:^|\s)((?:__)((?:[^__]+))(?:__))$/

export const Italic = TiptapItalic.extend({
  addInputRules() {
    return [
      markInputRule({
        find: underscoreInputRegex,
        type: this.type,
      }),
      markInputRule({
        find: doubleUnderscoreInputRegex,
        type: this.type,
      }),
    ]
  },
})
