import {
  Box,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditorContent, findChildren } from '@tiptap/react'
import { useCallback, useEffect } from 'react'

import { useAppSelector } from 'modules/redux'
import { getThemeCSSVars } from 'modules/theming'
import { NodeViewWrapper } from 'modules/tiptap_editor/react'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { MediaDrawer } from '../../components/drawers/MediaDrawer/MediaDrawer'
import { FormattingMenu } from '../../components/menus/FormattingMenus'
import {
  selectContentEditable,
  selectExpandedNoteId,
  selectTheme,
} from '../../reducer'
import { cursorStyles } from '../../styles/cursorStyles'
import { editorHasFocus, selectOnClick } from '../../utils'
import { CARD_CONTENT_CLASS } from '../Card/constants'
import { setFootnoteExpanded } from './FootnoteState'
import { useToggleFootnoteOnHover } from './hooks'
import { InnerEditorNodeViewProps } from './InnerEditorNodeView'

const GUTTER_PIXELS = 8

export const FootnoteView = (nodeViewProps: InnerEditorNodeViewProps) => {
  const { node, editor, getPos, innerEditor, selected } = nodeViewProps
  const { noteId } = node.attrs

  // Check in redux to see if we're hovering, because the mark will dispatch here
  const expandedNoteId = useAppSelector(selectExpandedNoteId)
  const editable = useAppSelector(selectContentEditable)

  // But also stay open if you hover on the popover directly
  const { onTouchEnd, ...onHoverHandlers } = useToggleFootnoteOnHover(
    editor,
    noteId
  )

  // Prevent editing notes when outer editor isn't editable
  useEffect(() => {
    if (!innerEditor) return
    innerEditor.setEditable(editable)
  }, [editable, innerEditor])

  const isOpen = expandedNoteId ? expandedNoteId === noteId : false
  const onClose = useCallback(
    () => setFootnoteExpanded(noteId, false),
    [noteId]
  )
  const selectNode = useCallback(
    () => editor.commands.setNodeSelection(getPos()),
    [editor, getPos]
  )

  useEffect(() => {
    if (!innerEditor) return
    const onEditorBlur = () => {
      setTimeout(() => {
        if (!editorHasFocus(innerEditor)) {
          onClose()
        }
      }, 20)
    }
    innerEditor.on('blur', onEditorBlur)
    return () => {
      innerEditor.off('blur', onEditorBlur)
    }
  }, [innerEditor, onClose])

  return (
    <NodeViewWrapper as="span" data-testid="footnote-nodeview-wrapper">
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        placement="top"
        gutter={GUTTER_PIXELS}
        isLazy
        lazyBehavior="unmount"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        closeOnBlur={false} // Use our custom logic above instead of Chakra's
        closeOnEsc={false}
        returnFocusOnClose={false}
      >
        <PopoverTrigger>
          <Text
            as="span"
            {...onHoverHandlers}
            onTouchEnd={onTouchEnd}
            onClick={editable ? selectNode : undefined}
            _after={{
              // This counter is setup in contentStyles.ts, so that we number from 1 in each card
              content: 'counter(footnote)',
              counterIncrement: 'footnote',
              position: 'relative',
              bottom: '0.6em',
              fontSize: '0.8em',
            }}
            pl="1px"
            className="footnote"
            cursor="default"
            contentEditable={false}
            borderRadius="sm"
            shadow={selected ? 'outline' : undefined}
          ></Text>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            zIndex="popover"
            shadow="md"
            border="1px solid"
            borderColor="gray.200"
            w="500px"
            maxW="100vw"
            {...onHoverHandlers}
            data-footnote-popover-test-id={noteId}
            p={0}
          >
            <PopoverArrow />
            <PopoverBody
              position="relative"
              zIndex="1"
              p={0}
              className="footnote-popover"
              data-footnote-popover-id={noteId}
            >
              <NoteEditor {...nodeViewProps} editable={editable} />
            </PopoverBody>
            {/* Make the hover target bigger on the edges so you don't fall in the gap */}
            <Box
              position="absolute"
              top={`-${GUTTER_PIXELS}px`}
              bottom={`-${GUTTER_PIXELS}px`}
              left={0}
              right={0}
              zIndex="0"
            />
          </PopoverContent>
        </Portal>
      </Popover>
    </NodeViewWrapper>
  )
}

const NoteEditor = ({
  editor,
  innerEditor,
  mountEditor,
  destroyEditor,
  node,
  editable,
  getPos,
}: InnerEditorNodeViewProps & { editable: boolean }) => {
  const { noteId } = node.attrs
  const convertToCard = useCallback(
    () => editor.commands.convertNoteToCard(noteId),
    [editor, noteId]
  )

  const deleteFootnote = useCallback(() => {
    editor.chain().focus().setNodeSelection(getPos()).deleteSelection().run()
  }, [editor, getPos])

  const selectFootnoteEdges = useCallback(
    (ev) => {
      if (!innerEditor || ev?.target.closest('.ProseMirror > .block')) {
        return
      }
      selectOnClick(innerEditor, ev)
    },
    [innerEditor]
  )

  const onClose = useCallback(
    () => setFootnoteExpanded(noteId, false),
    [noteId]
  )

  // Mount the editor when we open the footnote, then destroy it when we close
  useEffect(() => {
    if (!mountEditor || !destroyEditor) return
    mountEditor()
    return () => {
      destroyEditor()
    }
  }, [mountEditor, destroyEditor])

  const theme = useAppSelector(selectTheme)
  const cssVars = getThemeCSSVars(theme, false)

  if (!innerEditor) return null

  const hasLinkedMark =
    findChildren(editor.state.doc, (childNode) =>
      childNode.marks.some(
        (mark) =>
          mark.type.name === 'footnoteLabel' && mark.attrs.noteId === noteId
      )
    ).length > 0

  return (
    <>
      <Box
        className={CARD_CONTENT_CLASS}
        sx={{
          '.ProseMirror': {
            px: 8,
            py: 4,
            // This scrolling needs to happen here, because bubble-menu-plugin
            // mounts to the direct parent of the editor and that needs to be
            // outside the overflow
            overflow: 'hidden auto',
            maxH: '300px',
            maxW: '100%',
            borderRadius: 'md',
            ...cursorStyles,
          },
        }}
        position="relative"
        _focusWithin={{ shadow: editable ? 'outline' : undefined }}
        borderRadius="md"
        onClick={selectFootnoteEdges}
        css={cssVars}
      >
        <EditorContent editor={innerEditor} className="highlight-mask" />
        <MediaDrawer editor={innerEditor} />
        <ButtonGroup
          size="sm"
          colorScheme="gray"
          variant="ghost"
          position="absolute"
          right={4}
          top={2}
          onMouseDown={preventDefaultToAvoidBlur}
          spacing={0}
        >
          {editable && (
            <Menu autoSelect={false} isLazy>
              <MenuButton
                as={IconButton}
                icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
                minW={6}
                h={6}
                isRound
                backgroundColor="white"
              ></MenuButton>
              <Portal>
                <MenuList data-in-editor-focus zIndex="dropdown">
                  {hasLinkedMark && (
                    <MenuItem
                      icon={
                        <span className="fa-layers fa-fw">
                          <FontAwesomeIcon
                            icon={solid('rectangle')}
                            fixedWidth
                          />
                          <FontAwesomeIcon
                            icon={solid('arrow-right')}
                            inverse
                            transform="shrink-8"
                          />
                        </span>
                      }
                      onClick={convertToCard}
                    >
                      Convert to card
                    </MenuItem>
                  )}
                  <MenuItem
                    icon={
                      <FontAwesomeIcon icon={regular('trash')} fixedWidth />
                    }
                    color="red.500"
                    onClick={deleteFootnote}
                  >
                    Delete footnote
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          )}
          <IconButton
            aria-label="Close footnote"
            icon={<FontAwesomeIcon icon={regular('times')} />}
            minW={6}
            h={6}
            isRound
            onClick={onClose}
            backgroundColor="white"
          />
        </ButtonGroup>
      </Box>
      <FormattingMenu editor={innerEditor} />
    </>
  )
}
