export enum AppMonitoringEvents {
  DATA_SYNC_ERROR = 'dataSyncError',
}

export enum SegmentEvents {
  CARD_VIEWED = 'card.view',
  CARD_CREATED = 'card.create',
  CARD_STYLES_UPDATED = 'card.styles.update',
  CARD_STYLES_RESET = 'card.styles.reset',
  DOC_ATTRIBUTES_UPDATED = 'doc.attrs.update',
  CARD_COLLAPSED = 'card.collapse',
  CARD_EXPANDED = 'card.expand',
  DOC_VIEWED = 'doc.view',
  DOC_CREATED = 'doc.create',
  DOC_PRINTED = 'doc.print',
  DOC_ZOOMED = 'doc.zoom',
  EXAMPLE_VIEWED = 'example.view',
  EXAMPLE_DUPLICATED = 'example.duplicate',
  EXAMPLES_SNIPPET_CLOSED = 'examplesSnippet.close',
  EXAMPLES_SNIPPET_EXPANDED = 'examplesSnippet.expand',
  HOME_TAB_VIEWED = 'homeTab.view',
  NODE_INSERTED = 'node.insert',
  PRESENT_MODE_NAVIGATED = 'present.navigate',
  PRESENT_MODE_ENTERED = 'present.enter',
  PRESENT_MODE_EXITED = 'present.exit',
  ONBOARDING_TOUR_SKIPPED = 'onboardingTour.skip',
  ONBOARDING_TOUR_STEP = 'onboardingTour.step',
  LOGIN = 'auth.login',
  SIGNUP = 'auth.signup',
}

export enum NodeInsertMethods {
  SLASH_MENU = 'slash_menu',
  BLOCK_PROMPT = 'block_prompt',
  INSERT_WIDGET = 'insert_widget',
  INPUT_RULE = 'input_rule',
  KEYBOARD_SHORTCUT = 'keyboard_shortcut',
  CARD_DIVIDER = 'card_divider',
  FORMATTING_MENU = 'formatting_menu',
}

export enum PresentNavigateMethods {
  KEYBOARD = 'keyboard',
  BUTTON_CLICK = 'button_click',
}

export enum PresentNavigateDirections {
  TELEPROMPTER_UP = 'teleprompter_up',
  TELEPROMPTER_DOWN = 'teleprompter_down',
  CARD_NEXT = 'card_next',
  CARD_PREV = 'card_prev',
}
