import { VStack } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import { VideoEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { EmbedTip } from '../components/EmbedTip'
import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import Icon from './icons/wistia.svg'

const Panel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<VideoEmbedAttrs>) => {
  const url = getMediaSourceUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder="<iframe..."
      />
      <EmbedTip
        guideLink="https://wistia.com/support/embed-and-share/media-on-your-website"
        providerName="Wistia"
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const WistiaProvider: MediaProviderType = {
  label: 'Wistia',
  key: 'video.wistia',
  icon: duotone('video'),
  image: Icon,
  urlRegex: /^https?:\/\/fast.wistia.net/,
  Panel,
}

/* Example: 
<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 59.1667%;"><iframe src="https://fast.wistia.net/embed/iframe/7350d06e13" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen scrolling="no" allow="encrypted-media;"></iframe></div>
*/
