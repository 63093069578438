import { VStack } from '@chakra-ui/react'

import { VideoEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import vimeoIcon from './icons/vimeo.svg'

const VimeoPanel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<VideoEmbedAttrs>) => {
  const url = getMediaSourceUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder="https://vimeo.com/..."
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

export const VimeoProvider: MediaProviderType = {
  key: 'video.vimeo',
  Panel: VimeoPanel,
  label: 'Vimeo videos',
  image: vimeoIcon,
  urlRegex: /^https?:\/\/(player\.)?vimeo\.com/,
}
