import { Plugin } from 'prosemirror-state'

// y-prosemirror's addToHistory assumes that the `addToHistory` meta will always be set'
// on the last transaction of a set. This plugin ensures that's the case by pushing a
// new addToHistory on the end.
export const addToHistoryPlugin = () =>
  new Plugin({
    appendTransaction(transactions, _oldState, newState) {
      if (transactions.some((tr) => tr.getMeta('addToHistory') == false)) {
        const newTr = newState.tr
        newTr.setMeta('addToHistory', false)
        // console.debug(
        //   '[addToHistoryPlugin] Appending addToHistory false',
        //   transactions
        // )
        return newTr
      }
      return null
    },
  })
