import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { fragmentToArray, isNodeEmpty } from 'modules/tiptap_editor/utils'

import { isExpandableSummaryNode } from './utils'

type ExpandableDecorationSpec = {
  isExpandableDecoration: true
  toggleId: string
  isEmpty: boolean
}

type ExpandableDecoration = Decoration & {
  spec: ExpandableDecorationSpec
}

export const ExpandablePlugin = () =>
  new Plugin({
    props: {
      decorations({ doc }) {
        const decorations: Decoration[] = []

        doc.descendants((node, pos, parent) => {
          if (!parent || !isExpandableSummaryNode(node)) {
            return
          }
          const peers = fragmentToArray(parent.content)
          const isEmpty = peers.slice(1).every((n) => isNodeEmpty(n))
          const decoration = Decoration.node(
            pos,
            pos + node.nodeSize,
            {},
            {
              isExpandableDecoration: true,
              toggleId: parent!.attrs.id,
              isEmpty,
            }
          )
          decorations.push(decoration)
        })
        return DecorationSet.create(doc, decorations)
      },
    },
  })

export const findExpandableDecoration = (
  decorations: Decoration[]
): Partial<ExpandableDecorationSpec> => {
  return (
    decorations.find(
      (d): d is ExpandableDecoration => d.spec.isExpandableDecoration
    )?.spec || {}
  )
}
