// Forked from https://github.com/ueberdosis/tiptap/blob/main/packages/extension-bubble-menu/src/bubble-menu-plugin.ts
// Version @tiptap/extension-bubble-menu@2.0.0-beta.23,  https://github.com/ueberdosis/tiptap/commit/f12b1273f24984806394e3deb431823a9d00ba79
// See isLink below for modification

import {
  Editor,
  Extension,
  findChildrenInRange,
  isNodeSelection,
  isTextSelection,
  posToDOMRect,
} from '@tiptap/core'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import tippy, { Instance, Props } from 'tippy.js'

import { PanelLifecycle } from 'modules/panels/PanelLifecycle'
import { isHTMLElement } from 'utils/dom'

import { editorHasFocus } from '../../utils'

export const BubbleMenuPluginKey = new PluginKey('menuBubble')

export interface BubbleMenuPluginState {
  element: HTMLElement | null
  tippyInstance: Instance | null
  tippyOptions?: Partial<Props>
  forceHide: boolean
  panelLifecycle?: PanelLifecycle
}

export type BubbleMenuViewProps = {
  view: EditorView
  editor: Editor
}

export class BubbleMenuView {
  public editor: Editor

  public element: HTMLElement

  public view: EditorView

  public preventHide = false

  public tippy!: Instance

  // Keep track of the last known editor.isEditable state
  private isEditable: boolean

  // Keep track of mousedown activity outside of this node
  private isMouseDownOutsideMenu: boolean

  private cleanupPanelLifecycle: () => void

  constructor({ editor, view }: BubbleMenuViewProps) {
    this.editor = editor
    this.isEditable = editor.isEditable
    this.view = view
  }

  initialize({ element, tippyOptions, panelLifecycle }: BubbleMenuPluginState) {
    if (!element) {
      console.warn('[BubbleMenuView] initialize. No element provided')
      return
    }
    this.element = element
    this.element.addEventListener('mousedown', this.mousedownHandler, {
      capture: true,
    })
    this.view.dom.addEventListener('dragstart', this.dragstartHandler)
    this.view.dom.addEventListener('dragend', this.dragendHandler)
    this.view.dom.addEventListener('drop', this.dragendHandler)
    this.editor.on('focus', this.focusHandler)
    this.editor.on('blur', this.blurHandler)
    this.createTooltip(tippyOptions)
    this.element.style.visibility = 'visible'

    document.addEventListener('mousedown', this.mousedownDocumentHandler, true)
    document.addEventListener('mouseup', this.mouseupDocumentHandler, true)

    // account for the times where panel lifecycle isn't provided via context
    // such as test environments.  This plugin doesnt NEED the PanelLifecycle to function
    // correctly, so making this optional is okay.
    if (panelLifecycle) {
      this.cleanupPanelLifecycle = panelLifecycle.on('resize', () => {
        this.tippy.popperInstance?.update()
      })
    }
  }

  // Keep track of the mousedown state when the event
  // happens outside this bubble menu
  mousedownDocumentHandler = (event) => {
    if (this.element.contains(event.target)) {
      this.isMouseDownOutsideMenu = false
    } else {
      this.isMouseDownOutsideMenu = true
    }
  }

  mouseupDocumentHandler = () => {
    this.isMouseDownOutsideMenu = false
    this.update(this.editor.view)
  }

  mousedownHandler = () => {
    this.preventHide = true
  }

  dragstartHandler = () => {
    this.hide()
  }

  dragendHandler = () => {
    // A drag end should set this to false because mouseup doesnt fire
    this.isMouseDownOutsideMenu = false
    setTimeout(() => this.update(this.editor.view))
  }

  focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
    setTimeout(() => this.update(this.editor.view))
  }

  blurHandler = ({ event }: { event: FocusEvent }) => {
    if (this.preventHide) {
      this.preventHide = false

      return
    }

    if (
      event?.relatedTarget &&
      this.element.parentNode?.contains(event.relatedTarget as Node)
    ) {
      return
    }

    this.hide()
  }

  createTooltip(options: Partial<Props> = {}) {
    const el = this.view.dom.parentElement
    this.tippy = tippy(el!, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: 'manual',
      placement: 'top',
      hideOnClick: 'toggle',
      ...options,
    })

    this.editor.commands.command(({ tr }) => {
      tr.setMeta(BubbleMenuPluginKey, {
        tippyInstance: this.tippy,
      })
      return true
    })
  }

  update(view: EditorView, oldState?: EditorState) {
    const previousPluginState =
      oldState &&
      (BubbleMenuPluginKey.getState(oldState) as
        | BubbleMenuPluginState
        | undefined)
    const pluginState = BubbleMenuPluginKey.getState(
      view.state
    ) as BubbleMenuPluginState

    const { state, composing } = view
    const { doc, selection } = state
    const isSame =
      oldState &&
      oldState.doc.eq(doc) &&
      oldState.selection.eq(selection) &&
      this.isEditable === this.editor.isEditable &&
      previousPluginState?.forceHide === pluginState.forceHide

    if (!this.element) {
      if (!pluginState.element) {
        return
      }
      this.initialize(pluginState)
    }

    this.isEditable = this.editor.isEditable

    // Always hide the menu if the instance is not editable
    if (!this.editor.isEditable || pluginState.forceHide === true) {
      this.hide()
      return
    }

    if (composing || isSame) {
      return
    }

    const { empty, ranges } = selection

    // support for CellSelections
    const from = Math.min(...ranges.map((range) => range.$from.pos))
    const to = Math.max(...ranges.map((range) => range.$to.pos))

    // Sometime check for `empty` is not enough.
    // Doubleclick an empty paragraph returns a node size of 2.
    // So we check also for an empty text size.
    const isEmptyTextBlock =
      !doc.textBetween(from, to).length && isTextSelection(view.state.selection)

    if (empty || isEmptyTextBlock) {
      // OUR CUSTOM LOGIC STARTS HERE
      const isLink =
        selection.$anchor.marks().some((mark) => mark.type.name === 'link') ||
        selection.$anchor.parent.type.name === 'button'
      // Emojis without any text won't get counted as text in textBetween
      const emojis = findChildrenInRange(doc, { from, to }, (node) => {
        return node.type.name === 'emoji'
      })
      if (!isLink && emojis?.length === 0) {
        this.hide()
        return
      }
      // END OUR CUSTOM LOGIC
    }

    this.tippy.setProps({
      getReferenceClientRect: () => {
        if (isNodeSelection(view.state.selection)) {
          const domNode = view.nodeDOM(from)

          const node = view.state.selection.node

          if (domNode && isHTMLElement(domNode)) {
            // Allow nodes to specify a sub-selector to use as the reference element
            const nodeToUse = node.type.spec.atom
              ? domNode.querySelector('[data-content-reference]') || domNode
              : domNode

            return nodeToUse.getBoundingClientRect()
          }
        }

        return posToDOMRect(view, from, to)
      },
    })

    if (this.isMouseDownOutsideMenu || !editorHasFocus(this.editor)) return
    this.show()
  }

  show() {
    this.tippy.show()
    document.body.classList.add('formatting-menu-open')
  }

  hide() {
    this.tippy.hide()
    document.body.classList.remove('formatting-menu-open')
  }

  destroy() {
    this.tippy?.destroy()
    this.element?.removeEventListener('mousedown', this.mousedownHandler)
    document.removeEventListener(
      'mousedown',
      this.mousedownDocumentHandler,
      true
    )
    document.removeEventListener('mouseup', this.mouseupDocumentHandler, true)
    this.view.dom.removeEventListener('dragstart', this.dragstartHandler)
    this.editor.off('focus', this.focusHandler)
    this.editor.off('blur', this.blurHandler)

    if (this.cleanupPanelLifecycle) {
      this.cleanupPanelLifecycle()
    }
  }
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    bubbleMenuCommands: {
      /**
       * Override the menu to be hidden
       */
      forceHideBubbleMenu?: (forceHide: boolean) => ReturnType

      /**
       * Force refreshes the position of the bubblemenu
       */
      refreshBubbleMenu?: () => ReturnType
    }
  }
}

export const BubbleMenu = Extension.create({
  name: 'bubbleMenu',

  addCommands() {
    return {
      forceHideBubbleMenu:
        (forceHide) =>
        ({ tr }) => {
          tr.setMeta(BubbleMenuPluginKey, {
            forceHide,
          })
          return true
        },

      refreshBubbleMenu:
        () =>
        ({ state }) => {
          const { tippyInstance } = BubbleMenuPluginKey.getState(
            state
          ) as BubbleMenuPluginState
          tippyInstance?.popperInstance?.forceUpdate()

          return true
        },
    }
  },

  addProseMirrorPlugins() {
    const { editor } = this

    return [
      new Plugin<BubbleMenuPluginState>({
        key: BubbleMenuPluginKey,
        state: {
          init: () => {
            return {
              element: null,
              forceHide: false,
              tippyOptions: {},
              tippyInstance: null,
            }
          },
          apply(transaction, pluginState) {
            const newPluginState = transaction.getMeta(BubbleMenuPluginKey)

            if (newPluginState) {
              return { ...pluginState, ...newPluginState }
            }
            return pluginState
          },
        },
        view: (view) => new BubbleMenuView({ view, editor }),
      }),
    ]
  },
})
