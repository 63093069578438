import { Editor } from '@tiptap/core'
import { Mark } from 'prosemirror-model'

import { fetchEmbedAttrsForUrl } from 'modules/media/components/URLFetcher'
import { findChildrenByMark } from 'modules/tiptap_editor/prosemirror-utils'
export const isValidAutoLink = (url: string) => {
  const isFuzzy = !url.includes('://')
  return !isFuzzy
}

export const fetchUrlThenUpdateMarks = (url: string, editor: Editor) => {
  fetchEmbedAttrsForUrl(url)
    .then((attrs) => {
      const { doc, schema, tr } = editor.state
      // Find marks
      findChildrenByMark(doc, editor.schema.marks.link).forEach(
        ({ node, pos }) => {
          const linkMark = node.marks.find(
            (mark) => mark.type.name === 'link' && mark.attrs.href == url
          )
          if (!linkMark) return
          tr.removeMark(pos, pos + node.nodeSize, linkMark).addMark(
            pos,
            pos + node.nodeSize,
            schema.marks.link.create({ ...linkMark.attrs, ...attrs })
          )
        }
      )
      editor.view.dispatch(tr)
    })
    .catch((error) => {
      console.error('[Link] fetchUrlThenUpdateMarks error:', error)
    })
}

export const linkHasMetadata = (mark: Mark) =>
  mark.attrs.source || mark.attrs.meta || mark.attrs.embed
