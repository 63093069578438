const CSS_RESET = {
  fontSize: 'var(--chakra-fontSizes-sm) !important',
  fontFamily: '"Inter", sans-serif !important',
  fontWeight: '500 !important',
  lineHeight: '1.5 !important',
  textTransform: 'none',
  letterSpacing: 'normal !important',
  textAlign: 'left !important',
  margin: '0 !important',
  color: 'var(--chakra-colors-gray-900) !important',
  background: 'none !important',
  blockquote: {
    borderLeftWidth: '0px !important',
    borderLeftStyle: 'solid !important',
    borderLeftColor: 'transparent !important',
    padding: '0 !important',
    color: 'var(--chakra-colors-gray-900) !important',
    fontStyle: 'normal !important',
  },
  border: '0 none !important',
  padding: '0 !important',
  content: '""',
}

const LINK_STYLES = {
  ...CSS_RESET,
  color: 'var(--chakra-colors-blue-500) !important',
  '&:hover': { textDecoration: 'underline' },
  cursor: 'pointer',
}

const COMMENT_INNER_TEXT_STYLES = {
  'p,h1,h2,h3,h4,h5,h6, .title, .section-title': {
    ...CSS_RESET,
    margin: 0,
  },
  a: LINK_STYLES,
  'ul, ol': {
    marginLeft: '1.2em',
  },
  blockquote: {
    borderLeft: '3px solid var(--chakra-colors-gray-200)',
    paddingLeft: '0.5em',
  },
  color: 'var(--chakra-colors-gray-900) !important',
  wordBreak: 'break-word',
  lineHeight: '1.5 !important',
  letterSpacing: 'normal !important',
  '.mention': {
    bgColor: 'trueblue.50',
    whiteSpace: 'break-spaces',
  },
}
// Necessary because Button does not yet support responsive sizes:
// https://github.com/chakra-ui/chakra-ui/discussions/2276

export const CommentStyles = {
  // Comment boxes - includes author name, date, target html/text, and actual comment content
  fontFamily: '"Inter", sans-serif !important',
  fontWeight: '500',
  '@media screen and (max-width: 30rem)': {
    '.controls-wrapper': {},
  },
  '@media screen and (min-width: 30rem)': {
    '.controls-wrapper': {
      paddingLeft: `var(--card-inner-padding-x)`,
    },
    '.controls-wrapper__gridLayout, .controls-wrapper__gallery': {
      paddingLeft: `calc(-1 * var(--grid-padding) + var(--card-inner-padding-x))`,
    },
  },
  '.comments-list': {
    '.comments-sign-up': {
      a: {
        ...CSS_RESET,
        ...LINK_STYLES,
        fontSize: 'xs',
        color: 'gray.500',
        fontWeight: 'bold',
        '&:hover': { color: 'gray.500', textDecoration: 'underline' },
      },
    },
    '.comment': {
      // Comment box - includes author name, date, target html/text, and actual comment content
      '.reactions-row ul': {
        marginLeft: 'auto',
      },
      '.comment-date': {
        fontSize: 'var(--chakra-fontSizes-xs) !important',
        color: 'var(--chakra-colors-gray-500) !important',
      },
      ...COMMENT_INNER_TEXT_STYLES,
    },
    // from contentStyles.ts
    '.block-image': {
      '&.is-focused': {
        'img, .image, .placeholder': {
          boxShadow: 'var(--chakra-shadows-outline)',
        },
      },
    },
    '.block-target-html': {
      'p,h1,h2,h3,h4,h5,h6,blockquote,ol,ul,li, .title, .section-title': {
        ...CSS_RESET,
        '::after': CSS_RESET,
      },
      a: { ...LINK_STYLES },
      li: {
        margin: '0 !important',
        padding: '0 !important',
        listStyle: 'none !important',
        p: {
          margin: 0,
        },
      },
      img: {
        maxWidth: '200px',
        maxHeight: '80px',
      },
    },
  },
  // Styles for the editor inside
  '.comment-editor .ProseMirror': {
    ...COMMENT_INNER_TEXT_STYLES,
    // Mobile styles
    [`
      @media only screen and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2),
      @media only screen and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3),
      @media only screen and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 4)
    `]: {
      // 16px is required to prevent auto-zoom when an input is focused (which somehow includes a Prosemirror editor)
      fontSize: '16px  !important',
    },
    padding: '12px',
    borderRadius: '5px',
    '&.focus-visible': {
      boxShadow: '0 0 0 2px rgb(49, 130, 206)',
    },
    'a.image-link': {
      pointerEvents: 'none',
    },
  },
}
