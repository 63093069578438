import { NodeViewProps } from '@tiptap/core'
import { Decoration } from 'prosemirror-view'
import { useSelector } from 'react-redux'

import { useAppSelector } from 'modules/redux'
import {
  selectCardCollapsed,
  selectEditable,
  selectIsStatic,
  selectTheme,
} from 'modules/tiptap_editor/reducer'

import { getContainerOptions } from '../../styles/containerStyles'
import { EditorModeEnum } from '../../types'
import { isFocusedCard } from '../updateFns'
import { findCardPluginDecoration } from './CardPlugin'
import { usePresentVariant } from './usePresentVariant'

import { CardAttributes } from './index'

export const useCardState = (nodeViewProps: NodeViewProps) => {
  const { node, decorations } = nodeViewProps
  const { id: cardId, previewContent } = node.attrs as CardAttributes

  // Presenting states
  const [presentVariant, prevPresentVariant] = usePresentVariant(cardId)
  // Derive the mode from the presentVariant so that we recompute things at the same time
  const mode =
    presentVariant === 'doc'
      ? EditorModeEnum.DOC_VIEW
      : EditorModeEnum.SLIDE_VIEW
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW
  const isPresenting =
    presentVariant !== 'doc' && presentVariant !== 'presentCollapsed'
  const isPresentingCurrent = presentVariant === 'presentCurrent'
  // Pass the mode derived from the presentVariant to observe editable changes
  const isEditable = useAppSelector((state) => selectEditable(state, mode))
  const isPresentingParent = presentVariant === 'presentParent'

  // Focus ring
  const isFocused = isFocusedCard(decorations, node) && isEditable

  // Card nesting
  const isCollapsed = useAppSelector(selectCardCollapsed(cardId))

  const {
    isNested,
    nestedDepth,
    isFirstCard,
    inheritContainerEffect,
    inheritContainerIsDark,
    inheritContainerWidth,
  } = findCardPluginDecoration(decorations)
  const inheritContainer = {
    effect: inheritContainerEffect,
    isDark: inheritContainerIsDark,
    width: inheritContainerWidth,
  }

  // Use Redux here
  const isStatic = useAppSelector(selectIsStatic)

  return {
    inheritContainer,
    isCollapsed,
    isEditable,
    isFirstCard,
    isFocused,
    isNested,
    isPresenting,
    isPresentingCurrent,
    isPresentingParent,
    isPresentMode,
    isStatic,
    mode,
    nestedDepth,
    presentVariant,
    prevPresentVariant,
    previewContent,
  }
}

// Hook for detecting whether the card you're inside of is light or dark
// Only works for node types listed in CARD_DECORATION_NODES in CardPlugin.ts
export const useCardColorMode = (decorations: Decoration[]) => {
  const theme = useSelector(selectTheme)
  const { inheritContainerIsDark } = findCardPluginDecoration(decorations)
  const { isDark } = getContainerOptions(theme, {
    isDark: inheritContainerIsDark,
  })
  return { theme, isDark }
}
