import {
  Box,
  Text,
  Flex,
  HStack,
  StackDivider,
  VStack,
  FormControl,
  Switch,
  Checkbox,
  MenuList,
  MenuGroup,
  Button,
  ButtonGroup,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import { isMobileOrTabletDevice } from 'utils/deviceDetection'

import { featureFlags } from '../FeatureFlagProvider'

const useFeatureFlagExplanation = () => {
  const [explain, setExplain] = useState(featureFlags.explain())

  useEffect(() => {
    if (!featureFlags.hasInitialized) {
      // Ensure to update upon initialization
      featureFlags.initializePromise.then(() => {
        setExplain(featureFlags.explain())
      })
    }
    return featureFlags.onChange(() => {
      setExplain(featureFlags.explain())
    })
  }, [])

  return explain
}

export const FeatureFlagPanel: React.FC = () => {
  const explain = useFeatureFlagExplanation()
  const allFlags = featureFlags.all()

  // Used to debug our error boundary: when true, throws an error in the render path
  const [testRenderError, setTestRenderError] = useState(false)
  if (testRenderError) {
    throw new Error('[FeatureFlagPanel]: Render Error')
  }

  return (
    <MenuList
      bg="white"
      p={3}
      width="max(30vw, 300px)"
      flexDirection="column"
      overflowY="auto"
      maxH={isMobileOrTabletDevice ? '60vh' : '80vh'}
    >
      <MenuGroup title="Feature Flags">
        <VStack
          px={4}
          py={2}
          align="stretch"
          divider={<StackDivider borderColor="gray.200" />}
        >
          {explain
            .filter((item) => item.type === 'boolean')
            .map((item) => {
              const currentValue = allFlags[item.key]
              return (
                <Flex key={item.key} justify="space-between" align="center">
                  <Box>
                    <Text>
                      <strong>{item.key}</strong>
                    </Text>
                    <Text fontSize="sm" color="gray.700">
                      <Checkbox
                        isChecked={item.overrideValue !== undefined}
                        onChange={(e) => {
                          const newValue = e.target.checked
                          if (newValue) {
                            featureFlags.overrideFlag(
                              item.key as any,
                              item.defaultValue as any
                            )
                          } else {
                            featureFlags.removeOverride(item.key as any)
                          }
                        }}
                      >
                        override
                      </Checkbox>
                    </Text>
                  </Box>

                  <Flex direction="column" align="stretch">
                    <FormControl
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Flex direction="column" alignItems="flex-end">
                        <Text fontSize={10} fontStyle="italic">
                          {item.source}
                        </Text>
                        <Switch
                          id={`${item.key}-switch`}
                          isDisabled={item.overrideValue === undefined}
                          isChecked={Boolean(currentValue)}
                          onChange={(e) => {
                            featureFlags.overrideFlag(
                              item.key as any,
                              e.target.checked
                            )
                          }}
                        />
                      </Flex>
                    </FormControl>
                  </Flex>
                </Flex>
              )
            })}
        </VStack>
      </MenuGroup>
      <MenuGroup title="Debugging">
        <HStack mb={3}>
          <ButtonGroup variant="plain" size="xs" flexWrap="wrap">
            <Button
              onClick={() => {
                throw new Error('[FeatureFlagPanel] SyncError')
              }}
            >
              Throw global err
            </Button>
            <Button
              onClick={() => {
                setTestRenderError(true)
              }}
            >
              Throw render err
            </Button>
            <Button
              onClick={() => {
                new Promise((r) => setTimeout(r, 100)).then(() => {
                  throw new Error(
                    '[FeatureFlagPanel] UnhandledPromiseException'
                  )
                })
              }}
            >
              Throw promise err
            </Button>
          </ButtonGroup>
        </HStack>
      </MenuGroup>
    </MenuList>
  )
}
