import { NodeViewProps } from '@tiptap/react'

import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'

export const BulletView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps
  const { fontSize } = node.attrs
  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <NodeViewContent
        as="li"
        data-testid="bullet-list-item"
        data-font-size={fontSize}
      ></NodeViewContent>
    </AnnotatableNodeViewWrapper>
  )
}
