import { Editor, EditorEvents } from '@tiptap/core'
import { useEffect, useRef, useState } from 'react'

const TYPING_CAPTURE_THRESHOLD = 500

const SINGLE_KEY_REGEX = /^[\w\d\t\n ,./<>?;:"'`!@#$%^&*()[\]{}_+=|\\-]$/u // Exactly 1 keyboard character

export const useMonitorKeyboard = ({
  editor,
  pollingInterval,
}: {
  editor: Editor | undefined
  pollingInterval: number
}) => {
  const lastKeypressRef = useRef(Date.now())
  const lastTransactionRef = useRef(Date.now())

  const [highLatencyCount, setCount] = useState(0)

  useEffect(() => {
    if (!editor) return

    const handleKeyDown = (e: KeyboardEvent) => {
      /**
       * The goal here is to detect keypresses that should result in the doc changing
       */
      if (e.metaKey || !e.key.match(SINGLE_KEY_REGEX)) {
        return
      }
      lastKeypressRef.current = Date.now()
    }

    const handleTransaction = ({ transaction }: EditorEvents['update']) => {
      if (!transaction.docChanged) return

      lastKeypressRef.current = Date.now() // Reset keypress once we capture a doc change
      lastTransactionRef.current = Date.now()
    }

    editor.on('update', handleTransaction)
    editor.view.dom.addEventListener('keydown', handleKeyDown, true)

    const interval = setInterval(() => {
      const lastKnownDelta =
        lastTransactionRef.current - lastKeypressRef.current
      if (lastKnownDelta > TYPING_CAPTURE_THRESHOLD) {
        console.error(
          '[useDataPersistenceSync_0] Delay to capture changes has exceeded threshold'
        )
        setCount((p) => p + 1)
      } else {
        setCount(0)
      }
    }, pollingInterval)

    return () => {
      clearInterval(interval)
      editor.off('update', handleTransaction)
      editor.view.dom.removeEventListener('keydown', handleKeyDown, true)
    }
  }, [editor, pollingInterval])

  return highLatencyCount
}
