import { Flex, Image } from '@chakra-ui/react'

import { useAppSelector } from 'modules/redux'
import { isMobileDevice } from 'utils/deviceDetection'

import { selectMode, selectTheme } from '../../reducer'
import { EditorModeEnum } from '../../types'
import { DOC_Z_INDEXES } from './zIndexes'

const LOGO_MARGIN = 'var(--chakra-space-10)'
const LOGO_MAX_HEIGHT = '3em'

export const CustomerLogo = () => {
  const theme = useAppSelector(selectTheme)
  const mode = useAppSelector(selectMode)
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW
  const { logoUrl } = theme

  if (!logoUrl) return null

  if (isMobileDevice) {
    return (
      <Image
        src={logoUrl}
        maxW="12em"
        zIndex={DOC_Z_INDEXES.customerLogo}
        maxH={LOGO_MAX_HEIGHT}
        transitionProperty="width, height"
        transitionDuration="normal"
        pointerEvents="none"
      />
    )
  }

  return (
    <Flex
      // Uses position: sticky to always stay on screen, without scrolling away
      // position: fixed doesn't work because this needs to stay in the same stacking context as Doc.tsx
      // to go above the background but behind the cards
      // and also because this needs to work inside modals/drawers like Snapshots and Theme Editor
      position="sticky"
      left={LOGO_MARGIN}
      top={`calc(100% - ${LOGO_MARGIN} - ${LOGO_MAX_HEIGHT})`} // Workaround to achieve bottom={LOGO_MARGIN} with position="sticky"
      height={LOGO_MAX_HEIGHT}
      className="logo"
      zIndex={isPresentMode ? DOC_Z_INDEXES.customerLogo : undefined}
      w={{
        base: '6em',
        lg: '8em',
        '3xl': '12em',
      }}
      h={{
        base: '1.5em',
        lg: '2.5em',
        '3xl': LOGO_MAX_HEIGHT,
      }}
      justify="flex-start"
    >
      <Image
        src={logoUrl}
        maxH="100%"
        maxW="100%"
        transitionProperty="width, height"
        transitionDuration="normal"
        position="absolute"
        bottom={0}
        pointerEvents="none"
        data-testid="customer-logo"
      />
    </Flex>
  )
}
