// Based on https://github.com/ueberdosis/tiptap/tree/main/demos/src/Experiments/GlobalDragHandle

import { Editor, Extension } from '@tiptap/core'

import { GlobalDragHandlePlugin } from './GlobalDragHandlePlugin'

export const GlobalDragHandle = Extension.create({
  name: 'globalDragHandle',

  addOptions() {
    return {
      scrollerSelector: 'body', // The parent scrolling container
    }
  },

  addStorage() {
    return {
      enabled: true,
    }
  },

  addProseMirrorPlugins() {
    return [
      GlobalDragHandlePlugin({
        editor: this.editor,
        storage: this.storage,
        scrollerSelector: this.options.scrollerSelector,
      }),
    ]
  },
})

export const setGlobalDragHandleEnabled = (
  editor: Editor,
  isEnabled: boolean
) => {
  // console.log('[GlobalDragHandle] setGlobalDragHandleEnabled', isEnabled)
  const storage = editor.storage[GlobalDragHandle.name]
  if (storage) {
    storage.enabled = isEnabled
  }
}
