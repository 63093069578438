import {
  Configuration,
  GetOrganizationRequest,
  Organization,
  OrganizationsApi,
  User,
  UsersApi,
} from '@gammatech/schema'
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useRouter } from 'next/dist/client/router'

import { config } from 'config'

export enum API_CALLS {
  GET_ORGANIZATION = 'getOrganization',
  GET_USER = 'getUser',
}

const RETRY_COUNT_LIMIT = 3

// Retry on 500+ errors only
function retry500Errors<T>(failureCount: number, error: T & Response) {
  return failureCount < RETRY_COUNT_LIMIT && error.status > 499
}

const UseQueryDefaultOptions = {
  // https://react-query.tanstack.com/guides/query-retries
  retry: retry500Errors,
  // https://react-query.tanstack.com/guides/window-focus-refetching
  refetchOnWindowFocus: false,
}

const ApiConfiguration = new Configuration({
  basePath: `${config.API_HOST}`,
  credentials: 'include',
})

/*
Instantiate APIs
 */
export const organizationsApi = new OrganizationsApi(ApiConfiguration)
export const usersApi = new UsersApi(ApiConfiguration)

type AllTypes = Organization | User
type AllQueries = (args: any) => Promise<AllTypes>

export const useAPI = <T extends AllTypes, E = Response>(
  key: `${API_CALLS}`[],
  method: AllQueries,
  params?: ObjectAllowUndefined<Record<string, StringQueryParam>>,
  options?: UseQueryOptions<T, E>
): UseQueryResult<T, E> => {
  // Unfortunately, on first page load, query is an empty object, so we need
  // to watch the isReady value to know when the query params are available.
  // https://github.com/vercel/next.js/issues/10521#issuecomment-760926715
  //
  // This is only necessary if the caller passed params
  const { isReady } = useRouter()
  return useQuery<T, E>(key, () => method(params) as Promise<T>, {
    enabled: isReady || !params,
    ...UseQueryDefaultOptions,
    ...options,
  })
}

export const useGetOrganization = (
  params: ObjectAllowUndefined<GetOrganizationRequest>,
  options?: UseQueryOptions<Organization, Response>
): UseQueryResult<Organization, Response> => {
  const result = useAPI<Organization>(
    [API_CALLS.GET_ORGANIZATION],
    organizationsApi.getOrganization.bind(organizationsApi),
    params,
    options
  )
  return result
}

type ExistingEntity<T> = T & { id: string }
export type ExistingWorkspace = ExistingEntity<Organization>
export type ExistingUser = ExistingEntity<User> & {
  organizations?: ExistingWorkspace[]
}

export const useGetUser = (
  options?: UseQueryOptions<ExistingUser, Response>
): UseQueryResult<ExistingUser, Response> => {
  const result = useAPI<ExistingUser>(
    [API_CALLS.GET_USER],
    usersApi.getUser.bind(usersApi),
    {},
    options
  )
  return result
}
