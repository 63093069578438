import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useAppSelector } from 'modules/redux'

import { PanelLifecycle } from './PanelLifecycle'
import {
  closePanel,
  initializePanel,
  openPanel,
  selectIsPanelComponentOpen,
  selectPanelWidth,
} from './reducer'
import { PanelComponent, PanelPosition } from './types'

export const useClosePanel = (position: PanelPosition) => {
  const dispatch = useDispatch()
  return () => {
    dispatch(closePanel({ position }))
  }
}

export const useLeftPanelWidth = () => {
  return useAppSelector(selectPanelWidth('left'))
}

export const useRightPanelWidth = () => {
  return useAppSelector(selectPanelWidth('right'))
}

export type TogglePanelConfig = {
  disableTransition: boolean
  override?: 'open' | 'closed'
}
export const useTogglePanel = (component: PanelComponent) => {
  const position = component.panelPosition
  const dispatch = useDispatch()
  const isPanelOpen = useAppSelector(selectIsPanelComponentOpen(component))
  const initPanel = useCallback(() => {
    dispatch(initializePanel({ component }))
  }, [component, dispatch])
  const togglePanel = useCallback(
    (config?: TogglePanelConfig) => {
      let shouldPanelOpen = !isPanelOpen
      if (config?.override === 'open') {
        shouldPanelOpen = true
      } else if (config?.override === 'closed') {
        shouldPanelOpen = false
      }
      if (shouldPanelOpen) {
        dispatch(
          openPanel({
            component,
            disableTransition: Boolean(config?.disableTransition),
          })
        )
      } else {
        dispatch(closePanel({ position }))
      }
    },
    [component, position, isPanelOpen, dispatch]
  )

  return { togglePanel, initPanel, isPanelOpen }
}

// Setup panel lifecycles and pipe left+right into single global panel lifecycle
export const useDocEditorPanelLifecycles = () => {
  const leftPanelLifecycle = useMemo(() => new PanelLifecycle(), [])
  const rightPanelLifecycle = useMemo(() => new PanelLifecycle(), [])
  const globalPanelLifecycle = useMemo(() => new PanelLifecycle(), [])

  useEffect(() => {
    if (!globalPanelLifecycle || !leftPanelLifecycle || !rightPanelLifecycle) {
      return
    }
    const unbindLeft = leftPanelLifecycle.pipe(globalPanelLifecycle)
    const unbindRight = rightPanelLifecycle.pipe(globalPanelLifecycle)

    return () => {
      unbindLeft()
      unbindRight()
    }
  }, [leftPanelLifecycle, rightPanelLifecycle, globalPanelLifecycle])

  return { leftPanelLifecycle, rightPanelLifecycle, globalPanelLifecycle }
}
