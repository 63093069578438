export enum ErrorCode {
  invalid_signup_code = 'invalid_signup_code',
  invalid_user = 'invalid_user',
  other_workspace_member = 'other_workspace_member',
}

export const ERROR_MAP = {
  [ErrorCode.invalid_signup_code]: {
    title: "Hmm... We couldn't sign you up for Gamma.",
    description:
      'The code that you provided is invalid. Please check and try signing up again.',
  },

  [ErrorCode.invalid_user]: {
    title: "Hmm... We couldn't sign you in.",
    description:
      'You do not have access to Gamma. Ask your workspace administrator to send you a join link',
  },

  [ErrorCode.other_workspace_member]: {
    title: "Hmm... We can't add you to this workspace.",
    description:
      'Gamma currently only supports membership in a single workspace. Let us know at support@gamma.app if you need access to more than one workspace',
  },
}
