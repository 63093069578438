import { createSlice } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

type ImageEditorSliceState = Pick<RootState, 'ImageEditor'>

export interface ImageEditorState {
  clipType: 'inset' | 'circle'
  isCropping: boolean
}

const initialState: ImageEditorState = {
  clipType: 'inset',
  isCropping: false,
}

const ImageEditorSlice = createSlice({
  name: 'ImageEditor',
  initialState,
  reducers: {
    reset: () => initialState,
    setClipType(
      state,
      action: { payload: { clipType: ImageEditorState['clipType'] } }
    ) {
      const { clipType } = action.payload
      state.clipType = clipType
    },
    setCropping(
      state,
      action: { payload: { isCropping: ImageEditorState['isCropping'] } }
    ) {
      const { isCropping } = action.payload
      state.isCropping = isCropping
    },
  },
})

export const { setClipType, setCropping } = ImageEditorSlice.actions

// Selectors
export const selectClipType = (state: ImageEditorSliceState) =>
  state.ImageEditor.clipType

export const selectIsCropping = (state: ImageEditorSliceState) =>
  state.ImageEditor.isCropping

// Reducer
export const reducer = ImageEditorSlice.reducer
