import { SmartLayoutVariant } from '../types'
import { Bullets } from './Bullets'
import { TextGrid } from './TextGrid'
import { Timeline } from './Timeline'

const DefaultVariant = Bullets
export const SmartLayoutVariants: SmartLayoutVariant[] = [
  Bullets,
  TextGrid,
  Timeline,
]

const SmartLayoutVariantsMap: Record<string, SmartLayoutVariant> =
  SmartLayoutVariants.reduce((acc, variant) => {
    acc[variant.key] = variant
    return acc
  }, {})

export const getSmartLayoutVariant = (key?: string): SmartLayoutVariant => {
  return (key && SmartLayoutVariantsMap[key]) || DefaultVariant
}
