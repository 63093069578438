import { Extension } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { isBlockNode } from '../utils/nodeHelpers'
import { isAnnotatableParent } from './Annotatable/utils'

export const BlockClass = Extension.create({
  name: 'blockClass',

  addOptions() {
    return {
      blockClass: 'block',
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: ({ doc }) => {
            const decorations: Decoration[] = []
            const decorate = (node: Node, pos: number, parent: Node) => {
              if (isAnnotatableParent(parent)) {
                decorations.push(
                  Decoration.node(
                    pos,
                    pos + node.nodeSize,
                    {}, // classes
                    {
                      isAnnotatable: true,
                    }
                  )
                )
              }

              if (isBlockNode(node)) {
                decorations.push(
                  Decoration.node(pos, pos + node.nodeSize, {
                    class: `${this.options.blockClass} block-${node.type.name}`,
                  })
                )
              } else {
                // prosemirror-view has a bug with decorations on inline nodes with content like footnotes
                // https://linear.app/gamma-app/issue/G-1689/memo-load-error-for-customer
                // https://github.com/ProseMirror/prosemirror/issues/1267
                if (!node.isLeaf && !node.isBlock) return
                // ReactRenderer also adds a similar class for react renderer nodeviews
                decorations.push(
                  Decoration.node(pos, pos + node.nodeSize, {
                    class: `node-${node.type.name}`,
                  })
                )
              }
            }
            doc.descendants(decorate)
            return DecorationSet.create(doc, decorations)
          },
        },
      }),
    ]
  },
})
