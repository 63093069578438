import {
  relativePositionToAbsolutePosition,
  ySyncPluginKey,
} from '@gamma-app/y-prosemirror'
import { Editor, findParentNodeClosestToPos } from '@tiptap/core'
import { Node as ProsemirrorNode, ResolvedPos } from 'prosemirror-model'
import { Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { isCardNode } from '../Card/utils'
import { isExpandableToggleNode } from '../Expandable/utils'
import { AnnotationPluginKey } from './AnnotationExtension/AnnotationPluginKey'
import {
  AnnotationData,
  DragAnnotationData,
  MoveInstruction,
} from './AnnotationExtension/types'

export const isAnnotatableParent = (parent: ProsemirrorNode) => {
  return isCardNode(parent) || isExpandableToggleNode(parent)
}

/**
 * Traverses up parent chain to return the annotatable node pos
 */
export const findNearestAnnotatableParent = ($pos: ResolvedPos) => {
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i)
    const parent = $pos.node(i - 1)
    if (isAnnotatableParent(parent)) {
      return {
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node,
      }
    }
  }
  return null
}

export const isInAnnotatableParent = ($pos: ResolvedPos) => {
  return !!findNearestAnnotatableParent($pos)
}

export const computeDragAnnotationData = ({
  pos,
  from,
  to,
  editor,
}: {
  pos: number
  from: number
  to: number
  editor: Editor
}): DragAnnotationData | null => {
  const { state } = editor
  const annotationState = AnnotationPluginKey.getState(state)
  // If we aren't using annotation plugin (e.g. in tests) we can bail here
  if (!annotationState) {
    return null
  }

  // parentCard is null when the block being dragged is a top level card
  const parentCard = findParentNodeClosestToPos(
    editor.state.doc.resolve(pos),
    isCardNode
  )

  const annotationsInBlock = annotationState.getAnnotationsBetween(
    state,
    from,
    to
  )
  const annotationsInCard = !parentCard
    ? []
    : annotationState
        .getAnnotationsBetween(
          state,
          parentCard.pos,
          parentCard.pos + parentCard.node.nodeSize
        )
        .filter((a) => {
          // exclude annotations in block being moved
          return !annotationsInBlock.find((b) => b.id === a.id)
        })

  return {
    inBlock: annotationsInBlock,
    inCard: annotationsInCard,
    origNodePos: pos,
  }
}

export const computeMediaOnMediaGalleryCreationMoves = ({
  dropPos,
  dropNode,
  dragging,
  side,
  tr,
  view,
}: {
  dropPos: number
  dropNode: ProsemirrorNode
  dragging: DragAnnotationData
  side: 'left' | 'right'
  tr: Transaction
  view: EditorView
}): MoveInstruction[] => {
  const annotationState = AnnotationPluginKey.getState(view.state)
  // If we aren't using annotation plugin (e.g. in tests) we can bail here
  if (!annotationState) {
    return []
  }

  const dropNodeAnnotations = annotationState.getAnnotationsBetween(
    view.state,
    dropPos,
    dropPos + dropNode.nodeSize
  )
  const draggingNodeAnnotations = dragging.inBlock
  const leftAnnotations =
    side === 'left' ? draggingNodeAnnotations : dropNodeAnnotations
  const leftPos = side === 'left' ? dragging.origNodePos : dropPos
  const rightPos = side === 'left' ? dropPos : dragging.origNodePos

  const rightAnnotations =
    side === 'left' ? dropNodeAnnotations : draggingNodeAnnotations

  const { doc, type, binding } = ySyncPluginKey.getState(view.state)
  // map over the tr here, because a node from above could have been dragged and deleted
  const insertPos = tr.mapping.map(dropPos)
  const createMapFn =
    (origPos: number, galleryOffset: number) =>
    ({ id, relativePos }: AnnotationData) => {
      const pos = relativePositionToAbsolutePosition(
        doc,
        type,
        relativePos,
        binding.mapping
      )
      if (pos == null) {
        return null
      }
      const offset = pos - origPos

      return {
        id,
        newPos: insertPos + offset + galleryOffset,
      }
    }
  const leftMoveInsturctions: MoveInstruction[] = leftAnnotations
    .map(createMapFn(leftPos, 1))
    .filter((a): a is MoveInstruction => !!a)

  const rightMoveInstructions: MoveInstruction[] = rightAnnotations
    .map(createMapFn(rightPos, 2))
    .filter((a): a is MoveInstruction => !!a)
  const instructions: MoveInstruction[] = [
    ...leftMoveInsturctions,
    ...rightMoveInstructions,
  ]

  // handle in card annotations
  const inCardMoveInstructions: MoveInstruction[] = dragging.inCard
    .filter(({ id }) => !instructions.find((a) => a.id === id))
    .map(({ id, pos }) => {
      // use pos (absolute position) here because we can't
      // trust RelativePositions once a drag and drop move
      // has occurred
      const mappedPos = tr.mapping.map(pos)
      if (pos === mappedPos) {
        return null
      }
      return {
        newPos: mappedPos,
        id,
      }
    })
    .filter((a): a is MoveInstruction => !!a)

  return [...instructions, ...inCardMoveInstructions]
}

export const computeLayoutCreateMoveInstructions = ({
  dropPos,
  dropNode,
  dragging,
  side,
  tr,
  view,
  leftContentSize,
}: {
  dropPos: number
  dropNode: ProsemirrorNode
  dragging: DragAnnotationData
  side: 'left' | 'right'
  tr: Transaction
  view: EditorView
  leftContentSize: number
}): MoveInstruction[] => {
  const annotationState = AnnotationPluginKey.getState(view.state)
  // If we aren't using annotation plugin (e.g. in tests) we can bail here
  if (!annotationState) {
    return []
  }

  const dropNodeAnnotations = annotationState.getAnnotationsBetween(
    view.state,
    dropPos,
    dropPos + dropNode.nodeSize
  )
  const draggingNodeAnnotations = dragging.inBlock
  const leftAnnotations =
    side === 'left' ? draggingNodeAnnotations : dropNodeAnnotations
  const rightAnnotations =
    side === 'left' ? dropNodeAnnotations : draggingNodeAnnotations
  const leftPos = side === 'left' ? dragging.origNodePos : dropPos
  const rightPos = side === 'left' ? dropPos : dragging.origNodePos

  const { doc, type, binding } = ySyncPluginKey.getState(view.state)
  // map over the tr here, because a node from above could have been dragged and deleted
  const insertPos = tr.mapping.map(dropPos)
  const createMapFn =
    (origPos: number, galleryOffset: number) =>
    ({ id, relativePos }: AnnotationData) => {
      const pos = relativePositionToAbsolutePosition(
        doc,
        type,
        relativePos,
        binding.mapping
      )
      if (pos == null) {
        return null
      }
      const offset = pos - origPos

      return {
        id,
        newPos: insertPos + offset + galleryOffset,
      }
    }
  const leftMoveInsturctions: MoveInstruction[] = leftAnnotations
    .map(createMapFn(leftPos, 2))
    .filter((a): a is MoveInstruction => !!a)

  const rightMoveInstructions: MoveInstruction[] = rightAnnotations
    .map(createMapFn(rightPos, 4 + leftContentSize))
    .filter((a): a is MoveInstruction => !!a)

  const instructions: MoveInstruction[] = [
    ...leftMoveInsturctions,
    ...rightMoveInstructions,
  ]

  // handle in card annotations
  const inCardMoveInstructions: MoveInstruction[] = dragging.inCard
    .filter(({ id }) => !instructions.find((a) => a.id === id))
    .map(({ id, pos }) => {
      // use pos (absolute position) here because we can't
      // trust RelativePositions once a drag and drop move
      // has occurred
      const mappedPos = tr.mapping.map(pos)
      if (pos === mappedPos) {
        return null
      }
      return {
        newPos: mappedPos,
        id,
      }
    })
    .filter((a): a is MoveInstruction => !!a)

  return [...instructions, ...inCardMoveInstructions]
}

export const computeDeleteLayoutAnnotationMoves = ({
  contentPos,
  contentEnd,
  insertPos,
  editor,
}: {
  insertPos: number
  contentPos: number
  contentEnd: number
  editor: Editor
}): MoveInstruction[] => {
  const annotationState = AnnotationPluginKey.getState(editor.state)
  // If we aren't using annotation plugin (e.g. in tests) we can bail here
  if (!annotationState) {
    return []
  }

  const { state } = editor

  const contentAnnotations = annotationState.getAnnotationsBetween(
    state,
    contentPos,
    contentEnd
  )
  const { doc, type, binding } = ySyncPluginKey.getState(editor.view.state)
  const instructions: MoveInstruction[] = contentAnnotations
    .map(({ id, relativePos }) => {
      const pos = relativePositionToAbsolutePosition(
        doc,
        type,
        relativePos,
        binding.mapping
      )
      if (pos == null) {
        return null
      }
      const offset = pos - contentPos

      return {
        id,
        newPos: insertPos + offset,
      }
    })
    .filter((a): a is MoveInstruction => !!a)

  return instructions
}

export const computeInsertCardMoveInstructions = ({
  dropPos,
  dragging,
  cardWrapOffset,
  tr,
  view,
}: {
  dropPos: number
  dragging: DragAnnotationData
  cardWrapOffset: number
  tr: Transaction
  view: EditorView
}): MoveInstruction[] => {
  const annotationState = AnnotationPluginKey.getState(view.state)
  // If we aren't using annotation plugin (e.g. in tests) we can bail here
  if (!annotationState) {
    return []
  }

  const { doc, type, binding } = ySyncPluginKey.getState(view.state)
  // map over the tr here, because a node from above could have been dragged and deleted
  // Use -1 here to get the position before the inserted content, not after
  const insertPos = tr.mapping.map(dropPos, -1)
  const createMapFn =
    (origPos: number, insertOffset: number) =>
    ({ id, relativePos }: AnnotationData) => {
      const pos = relativePositionToAbsolutePosition(
        doc,
        type,
        relativePos,
        binding.mapping
      )
      if (pos == null) {
        return null
      }
      const offset = pos - origPos

      return {
        id,
        newPos: insertPos + offset + insertOffset,
      }
    }

  const inBlockMoveInstructions: MoveInstruction[] = dragging.inBlock
    .map(createMapFn(dragging.origNodePos, cardWrapOffset))
    .filter((a): a is MoveInstruction => !!a)

  // handle in card annotations
  const inCardMoveInstructions: MoveInstruction[] = dragging.inCard
    .filter(({ id }) => !inBlockMoveInstructions.find((a) => a.id === id))
    .map(({ id, pos }) => {
      // use pos (absolute position) here because we can't
      // trust RelativePositions once a drag and drop move
      // has occurred
      const mappedPos = tr.mapping.map(pos)
      if (pos === mappedPos) {
        return null
      }
      return {
        newPos: mappedPos,
        id,
      }
    })
    .filter((a): a is MoveInstruction => !!a)

  return [...inBlockMoveInstructions, ...inCardMoveInstructions]
}

export const computeInsertNestedCardMoves = ({
  pos,
  tr,
  editor,
}: {
  pos: number
  tr: Transaction
  editor: Editor
}): MoveInstruction[] => {
  const annotationState = AnnotationPluginKey.getState(editor.view.state)
  // If we aren't using annotation plugin (e.g. in tests) we can bail here
  if (!annotationState) {
    return []
  }

  const $pos = editor.state.doc.resolve(pos)
  if (!$pos.nodeAfter) {
    return []
  }

  return annotationState
    .getAnnotationsBetween(editor.state, pos, pos + $pos.nodeAfter.nodeSize)
    .map<MoveInstruction>(({ id, pos: p }) => {
      return {
        id,
        newPos:
          p === pos
            ? // p === pos mapping doesnt change, but it actually implies that the annotation
              // was on the parent node, which does get moved down, so use +1 then -1 to fix this
              tr.mapping.map(p + 1) - 1
            : tr.mapping.map(p),
      }
    })
}
