import uniqBy from 'lodash/uniqBy'
import React from 'react'

const MAX_NAMES_TO_DISPLAY = 30

type Maybe<T> = T | undefined

export type BaseUser = { id: string; displayName?: string }

export type BaseReply = {
  id: string
  user?: BaseUser
}

type GenerateNamePhraseArgs = {
  users?: Maybe<BaseUser>[]
  selfUserId: string
}
export const joinUserNames = (
  userNames: string[],
  max = MAX_NAMES_TO_DISPLAY
) => {
  if (!userNames.length) {
    return null
  } else if (userNames.length === 1) {
    return userNames[0] as string
  } else if (userNames.length === 2) {
    return userNames.join(' and ')
  } else if (userNames.length <= max) {
    return `${[
      userNames.slice(0, userNames.length - 1).join(', '),
      userNames[userNames.length - 1],
    ].join(', and ')}`
  } else {
    return `${[
      userNames.slice(0, max).join(', '),
      `${userNames.length - max} ${
        userNames.length - max > 1 ? 'others' : 'other'
      }`,
    ].join(', and ')}`
  }
}
export const generateNamePhrase = ({
  users,
  selfUserId,
}: GenerateNamePhraseArgs): React.ReactNode => {
  const userNames = users
    ? [...users]
        // Put this user at the front of the list
        .sort((a) => (a?.id === selfUserId ? -1 : 1))
        // convert to list of name strings with 'You' as this user's name
        .map((u) => (u?.id === selfUserId ? 'You' : u?.displayName || ''))
    : []
  return joinUserNames(userNames)
}

type GenerateReplyPlaceholderArgs = {
  user?: BaseUser // The author of the top-level comment to which others are replying
  replies?: BaseReply[] // The replies to the top-level comment
  selfUser?: BaseUser // The current user
}
export const generateReplyPlaceholder = ({
  user,
  replies,
  selfUser,
}: GenerateReplyPlaceholderArgs) => {
  const replyUsers = replies ? replies.map((r) => r?.user).filter(Boolean) : []
  const allUsers = user ? ([user] as Maybe<BaseUser>[]).concat(replyUsers) : []
  const userNames = allUsers
    ? uniqBy(
        [...allUsers].filter((u) => u?.id !== selfUser?.id!),
        'id'
      ).map((u) => u?.displayName || '')
    : []
  const names = joinUserNames(userNames)
  return names?.length ? `Reply to ${names}` : `Reply`
}
