import {
  Box,
  ButtonGroup,
  Divider,
  IconButton,
  CloseButton,
  InputRightElement,
  Input,
  Flex,
  Grid,
  GridItem,
  Image,
  List,
  ListItem,
  ListIcon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  InputGroup,
  InputLeftElement,
  Link,
  Spacer,
  Textarea,
  Progress,
  PopoverTrigger,
  Popover,
  Portal,
  PopoverContent,
  Button,
  useOutsideClick,
  Wrap,
} from '@chakra-ui/react'
import {
  duotone,
  regular,
  solid,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  GammaTooltip,
  LinkTagGroup,
  LinkTag,
  useGammaTooltipHider,
} from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import React, {
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useRef,
} from 'react'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { fetchIframelyJSON } from 'modules/media/apis/iframely'
import { setDraggingContent } from 'modules/tiptap_editor/commands/utils'
import { useMediaZoom } from 'modules/tiptap_editor/extensions/media/Zoomable/hooks'
import { ZoomableOverlay } from 'modules/tiptap_editor/extensions/media/Zoomable/ZoomableOverlay'
import { useUserContext } from 'modules/user'
import { useDebounced } from 'utils/hooks'

import { updateAsset, updateNote, removeAsset, addAsset } from './api'
import { useVaultData, useVaultFileUpload } from './hooks'
import { NoteEditor } from './NoteEditor'

type VaultView = 'sm' | 'lg'

const EmptyState = () => {
  return (
    <Flex
      h="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        objectFit="contain"
        h="100%"
        w="175px"
        src="https://cdn.gamma.app/zc87vhr30n8uf3n/8179d34b1fcc4291b6c63584ed0ab35b/optimized/_2.svg"
      />
      <Text fontSize="sm" pt={2}>
        There's nothing here yet.
      </Text>
    </Flex>
  )
}

const InDocIcon = (
  <FontAwesomeIcon
    icon={solid('circle-check')}
    size="sm"
    color="var(--chakra-colors-green-500)"
  />
)

export const VaultPanel = ({
  editor,
  docId,
}: {
  editor: Editor
  docId?: string
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [view, setView] = useState<VaultView>('sm')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchFocused, setSearchFocused] = useState(false)
  const [linkInput, setLinkInput] = useState('')
  const { user } = useUserContext()
  const { currentWorkspace } = useUserContext()
  const workspaceId = currentWorkspace?.id
  const userId = user?.id
  const ref = useRef(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const searchWrapperRef = useRef<HTMLDivElement>(null)
  useOutsideClick({
    ref: searchWrapperRef,
    handler: () => {
      setSearchFocused(false)
    },
  })

  const { isDragHovered, uploadInProgress } = useVaultFileUpload({
    dropRef: ref,
    fileInputRef,
    workspaceId,
  })
  const { items, note } = useVaultData(editor, searchTerm)

  const handleSearchChange = useCallback((val: string) => {
    setSearchTerm(val.toLocaleLowerCase())
  }, [])

  const addLink = useCallback(() => {
    if (!docId || !userId) return
    fetchIframelyJSON(linkInput).then((data) => {
      const meta = JSON.parse(JSON.stringify(data))
      addAsset({
        docId,
        userId,
        name: '',
        type: '',
        url: linkInput,
        meta,
        vaultType: 'link',
      })
    })
    setLinkInput('')
  }, [docId, userId, linkInput])

  console.log('[VaultPanel]', { items })

  const tabPanelProps = {
    width: '100%',
    minH: '200px',
    display: 'flex',
    flexDirection: 'column',
  }

  const gridViewButtons = (
    <ButtonGroup>
      <IconButton
        onClick={() => {
          setView('sm')
        }}
        aria-label="vault-view-small"
        size="sm"
        bg={view === 'sm' ? 'gray.200' : ''}
        variant=""
        p={0}
        icon={<FontAwesomeIcon icon={regular('grid')} />}
      />
      <IconButton
        onClick={() => {
          setView('lg')
        }}
        aria-label="vault-view-large"
        size="sm"
        bg={view === 'lg' ? 'gray.200' : ''}
        variant=""
        p={0}
        icon={<FontAwesomeIcon icon={regular('grid-2')} />}
      />
    </ButtonGroup>
  )

  return (
    <Flex
      h="344px"
      minH="344px"
      direction="column"
      p={2}
      flex={1}
      ref={ref}
      borderRadius="xl"
      outline={
        isDragHovered ? 'dashed 2px var(--chakra-colors-trueblue-300)' : ''
      }
    >
      <Flex alignItems="center" pb={2}>
        <InputGroup
          mr={2}
          ref={searchWrapperRef}
          flexDirection="column"
          onFocus={() => {
            setSearchFocused(true)
          }}
        >
          <InputLeftElement pointerEvents="none">
            <FontAwesomeIcon icon={duotone('search')} size="sm" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search vault"
            value={searchTerm}
            onChange={(event) => {
              handleSearchChange(event.target.value)
            }}
          />
          {searchFocused && (
            <Wrap
              position="absolute"
              mt="40px"
              zIndex="2"
              minW="100%"
              bg="white"
              p={3}
              // borderBottomWidth="2px"
              borderBottomColor="gray.300"
              borderBottomRadius="5px"
            >
              {items.keywords.slice(0, 100).map(([k]) => {
                const activeStyles =
                  searchTerm && k.startsWith(searchTerm)
                    ? {
                        bg: 'trueblue.100 !important',
                        color: 'blackAlpha.900',
                      }
                    : {}
                return (
                  <LinkTag
                    key={k}
                    id={k}
                    cursor="pointer"
                    NextLink={TagWrapper}
                    label={k}
                    {...activeStyles}
                    onClick={() => {
                      setSearchTerm(k)
                    }}
                  />
                )
              })}
            </Wrap>
          )}
        </InputGroup>
      </Flex>
      <Tabs isFitted flex={1} mb={4} onChange={(index) => setTabIndex(index)}>
        <TabList>
          <Tab fontSize="sm">Images</Tab>
          <Tab fontSize="sm">Links</Tab>
          <Tab fontSize="sm">Notes</Tab>
          <Tab fontSize="sm">Cards</Tab>
        </TabList>

        <TabPanels>
          <TabPanel {...tabPanelProps}>
            <Flex pb={3}>{gridViewButtons}</Flex>
            <Grid
              templateColumns={
                view === 'lg' ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
              }
              gap={2}
            >
              {items.files.map(
                ({
                  name,
                  url,
                  type,
                  id,
                  inDoc,
                  meta,
                  keywords = [],
                  text = '',
                }) => {
                  return (
                    <GridItem
                      key={url}
                      cursor="pointer"
                      display="flex"
                      h={view === 'lg' ? '28' : '20'}
                      border="1px solid var(--chakra-colors-gray-200)"
                      borderRadius={4}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <VaultItem
                        editor={editor}
                        recordId={id}
                        docId={docId}
                        userId={userId}
                        name={name}
                        type={type}
                        meta={meta}
                        keywords={keywords}
                        text={text}
                        inDoc={inDoc}
                        url={url}
                      />
                    </GridItem>
                  )
                }
              )}
            </Grid>
            {items.files.length === 0 && <EmptyState />}
          </TabPanel>
          <TabPanel {...tabPanelProps}>
            <InputGroup size="sm" mb={2}>
              <Input
                pr="4rem"
                type="text"
                value={linkInput}
                placeholder="Enter url"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    addLink()
                  }
                }}
                onChange={(event) => {
                  setLinkInput(event.target.value)
                }}
              />
              <InputRightElement width="4rem">
                <Button h="1.75rem" size="sm" onClick={addLink}>
                  Add
                </Button>
              </InputRightElement>
            </InputGroup>
            <List spacing={1}>
              {items.links.map(({ meta, url, recordId, inDoc }) => (
                <LinkItem
                  key={recordId}
                  recordId={recordId}
                  meta={meta}
                  url={url}
                  inDoc={inDoc}
                />
              ))}
              {items.links.length === 0 && <EmptyState />}
            </List>
          </TabPanel>
          <TabPanel {...tabPanelProps}>
            {note && (
              <NoteEditor
                initialContent={note || ''}
                onUpdate={({ editor }) => {
                  if (!docId || !userId) return
                  updateNote({ docId, userId, data: editor.getJSON() })
                }}
              />
            )}
          </TabPanel>
          <TabPanel {...tabPanelProps}>
            <Flex pb={2}>{gridViewButtons}</Flex>
            <Grid
              templateColumns={
                view === 'lg' ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
              }
              gap={2}
            >
              {items.cards.map(({ cardPreviewUrl, cardData, id }) => {
                return (
                  <GridItem
                    key={cardPreviewUrl}
                    cursor="pointer"
                    display="flex"
                    h={view === 'lg' ? '28' : '20'}
                    border="1px solid var(--chakra-colors-gray-200)"
                    borderRadius={4}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <VaultCard
                      id={id}
                      previewUrl={cardPreviewUrl}
                      editor={editor}
                      cardData={cardData}
                    />
                  </GridItem>
                )
              })}
            </Grid>
            {items.cards.length === 0 && <EmptyState />}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <input hidden type="file" ref={fileInputRef} multiple={true} />
      {uploadInProgress ? (
        <Progress size="xs" isIndeterminate />
      ) : tabIndex === 0 ? (
        <Text fontStyle="italic" fontSize="xs" color="gray.400">
          <Link
            color="trueblue.600"
            onClick={() => {
              fileInputRef.current!.click()
            }}
          >
            Click to upload
          </Link>{' '}
          or drag an asset here to add it to your vault.
        </Text>
      ) : tabIndex === 1 ? (
        <Text fontStyle="italic" fontSize="xs" color="gray.400"></Text>
      ) : tabIndex === 2 ? (
        <Text fontStyle="italic" fontSize="xs" color="gray.400">
          These notes are privated to you. Nobody else can see them
        </Text>
      ) : tabIndex === 3 ? (
        <Text fontStyle="italic" fontSize="xs" color="gray.400">
          Move cards to the vault using the card ellipsis menu.
        </Text>
      ) : null}
    </Flex>
  )
}

const VaultCard = ({ previewUrl, editor, cardData, id }) => {
  const { isZoomed, enterZoom, exitZoom } = useMediaZoom(id)
  return (
    <Flex
      w="100%"
      h="100%"
      position="relative"
      onDragStart={() => {
        console.log('VAULTCARD DRAG START!', cardData)
        setDraggingContent(editor, cardData)
      }}
    >
      <Image
        src={previewUrl}
        fallbackSrc={placeholderBackground.src}
        objectFit="contain"
        w="100%"
        h="100%"
        onClick={() => {
          enterZoom()
        }}
      />
      <ZoomableOverlay isZoomed={isZoomed} exitZoom={exitZoom} editor={editor}>
        <Image
          src={previewUrl}
          fallbackSrc={placeholderBackground.src}
          objectFit="contain"
          w="100%"
          h="100%"
        />
      </ZoomableOverlay>
    </Flex>
  )
}

type LinkItemProps = {
  url: string
  type?: string
  inDoc: boolean
  recordId: string
  text?: string
  meta?: Record<string, any>
}

const LinkItem = (props: LinkItemProps) => {
  const { meta, url, inDoc } = props
  const metaData = meta?.meta || {}
  const imageUrl = metaData.icon
  const name = metaData.title || metaData.site
  return (
    <ListItem display="flex">
      <Image
        mr={2}
        src={imageUrl}
        fallbackSrc=""
        h="16px"
        w="16px"
        objectFit="contain"
      />
      <Text
        whiteSpace="nowrap"
        overflowX="hidden"
        fontSize="xs"
        fontWeight="bold"
        minWidth="100px"
        width="100px"
        textOverflow="ellipsis"
        px={1}
      >
        {name}
      </Text>
      <Link
        px={1}
        fontSize="xs"
        href={meta?.sourceUrl}
        target="_blank"
        whiteSpace="nowrap"
        overflowX="hidden"
        textOverflow="ellipsis"
      >
        {meta?.sourceUrl || url}
      </Link>
      {inDoc && InDocIcon}
    </ListItem>
  )
}

type VaultItemProps = {
  name: string
  url: string
  type: string
  inDoc: boolean
  recordId: string
  text: string
  meta: Record<string, any>
  keywords: string[]
  editor: Editor
  docId?: string
  userId?: string
}

const VaultItem = (props: VaultItemProps) => {
  const { url, inDoc, recordId, editor } = props
  const [menuOpen, setMenuOpen] = useState(false)
  const { GammaTooltipHiderContext, hideTooltips } = useGammaTooltipHider()
  const { isZoomed, enterZoom, exitZoom } = useMediaZoom(recordId)

  return (
    <Flex w="100%" h="100%" position="relative">
      {inDoc && (
        <Flex
          position="absolute"
          borderRadius="full"
          bg="gray.100"
          p="0px"
          bottom="-7px"
          left="-7px"
        >
          {InDocIcon}
        </Flex>
      )}
      <Popover
        placement="bottom-end"
        trigger="hover"
        isLazy={true}
        lazyBehavior="unmount"
        isOpen={menuOpen}
      >
        <PopoverTrigger>
          <IconButton
            aria-label="item-menu"
            icon={<FontAwesomeIcon icon={duotone('ellipsis')} />}
            variant="plain"
            top="0px"
            right="0px"
            borderRadius="full"
            position="absolute"
            size="xs"
            height="5"
            minWidth="5"
            onClick={() => {
              setMenuOpen((p) => !p)
            }}
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent bg="#F9FAFBFA" width="250px">
            <GammaTooltipHiderContext>
              <VaultItemMenu {...props} setMenuOpen={setMenuOpen} />
            </GammaTooltipHiderContext>
          </PopoverContent>
        </Portal>
      </Popover>
      <Image
        src={url}
        objectFit="contain"
        w="100%"
        h="100%"
        onClick={() => {
          enterZoom()
        }}
      />
      <ZoomableOverlay isZoomed={isZoomed} exitZoom={exitZoom} editor={editor}>
        <Image src={url} objectFit="contain" w="100%" h="100%" />
      </ZoomableOverlay>
    </Flex>
  )
}

type VaultItemMenuProps = VaultItemProps & {
  setMenuOpen: Dispatch<SetStateAction<boolean>>
}

const TagWrapper = ({ children }) => {
  return <Flex cursor="pointer">{children}</Flex>
}

const VaultItemMenu = (props: VaultItemMenuProps) => {
  const { name, meta, docId, userId, recordId, setMenuOpen, keywords, text } =
    props
  const ref = useRef(null)
  const [textValue, setTextValue] = useState(text)

  useOutsideClick({
    ref,
    handler: () => {
      setMenuOpen(false)
    },
  })
  const updateAssetDebounced = useDebounced(updateAsset, 250)

  return (
    <Flex direction="column" p={1} ref={ref}>
      <Text fontSize="xxs" fontWeight="600" pb={1}>
        {name}
      </Text>
      <Text fontSize="xxs" fontWeight="600">
        {meta.width}x{meta.height}px
      </Text>

      <Tabs isFitted flex={1} mb={4}>
        <TabList>
          <Tab fontSize="xs">Tags</Tab>
          <Tab fontSize="xs">Image Text</Tab>
        </TabList>

        <TabPanels>
          <TabPanel overflowY="auto">
            <LinkTagGroup
              max={20}
              NextLink={TagWrapper}
              tags={keywords.map((k) => {
                return {
                  id: k,
                  label: k,
                  href: '#',
                }
              })}
            />
          </TabPanel>
          <TabPanel>
            <Textarea
              value={textValue}
              onChange={(event) => {
                setTextValue(event.target.value)

                if (!docId || !userId || !recordId) return

                updateAssetDebounced({
                  docId,
                  userId,
                  recordId,
                  data: {
                    text: event.target.value,
                  },
                })
              }}
              fontSize="sm"
              color="gray.800"
              resize="none"
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Flex pt={2}>
        <Button
          size="xs"
          onClick={() => {
            if (!docId || !userId) return
            removeAsset({ docId, userId, recordId })
          }}
        >
          Delete from vault
        </Button>
        <Spacer />
        <Button
          size="xs"
          variant="solid"
          onClick={() => {
            setMenuOpen(false)
          }}
        >
          Done
        </Button>
      </Flex>
    </Flex>
  )
}
