import { Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { NodeViewProps } from '@tiptap/react'
import React from 'react'

import { UploadStatus } from 'modules/media'

import { AnnotatableNodeViewWrapper } from '../../Annotatable/components/AnnotatableNodeViewWrapper'
import { getFlexAlign } from '../../HorizontalAlign'
import { isNodeViewInGallery } from '../Gallery'
import { ImageAttrs } from '../Image'
import { MediaPlaceholderAttrs } from './MediaPlaceholder'
import { MediaPlaceholderImage } from './MediaPlaceholderImage'

export const MediaPlaceholderView = (nodeViewProps: NodeViewProps) => {
  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps} as="div">
      <MediaPlaceholderBlock {...nodeViewProps} />
    </AnnotatableNodeViewWrapper>
  )
}

export const MediaPlaceholderBlock = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps
  const { source, horizontalAlign, isFullWidth, uploadStatus } =
    node.attrs as MediaPlaceholderAttrs & ImageAttrs
  const icon = source?.includes('embed.')
    ? regular('browser')
    : regular('photo-video')
  const inGallery = isNodeViewInGallery(nodeViewProps)

  return (
    <Flex w="100%" h="100%" justify={getFlexAlign(horizontalAlign)}>
      <MediaPlaceholderImage
        hasUploadError={uploadStatus === UploadStatus.Error}
        isLoading={uploadStatus === UploadStatus.Uploading}
        data-content-reference
        icon={icon}
        width={isFullWidth || inGallery ? '100%' : '18em'}
        maxW="100%"
        h={inGallery ? '100%' : '10em'}
        maxH="100%"
        data-drag-handle // https://tiptap.dev/guide/node-views/react#dragging
        borderRadius="var(--block-border-radius)"
      />
    </Flex>
  )
}
