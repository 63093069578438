import { ButtonGroup, Center, Divider } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor } from '@tiptap/core'
import React from 'react'

import { AlignmentCommands } from '../../../extensions/HorizontalAlign'
import { ToolbarButton } from '../ToolbarButton'

type ContributorsFormattingMenuProps = {
  editor: Editor
}

export const ContributorsFormattingMenu = ({
  editor,
}: ContributorsFormattingMenuProps) => {
  return (
    <ButtonGroup spacing={0} size="sm">
      {AlignmentCommands.map(({ name, icon, checkActive, apply }) => {
        if (!checkActive || !apply) return
        return (
          <ToolbarButton
            key={name}
            label={name}
            icon={icon}
            onClick={() => {
              apply(editor)
            }}
            isActive={checkActive(editor)}
          />
        )
      })}
      <Center my={2} px={0.5}>
        <Divider borderColor="gray.300" orientation="vertical" />
      </Center>
      <ToolbarButton
        label="Delete contributors block"
        icon={regular('trash')}
        onClick={() => {
          editor.commands.deleteSelectionAndSelectNear(-1)
        }}
        color="red"
      />
    </ButtonGroup>
  )
}
