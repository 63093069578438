import { Flex } from '@chakra-ui/react'

export const ToolbarWrapper = ({ children, ...props }) => {
  return (
    <Flex
      bg="#F9FAFBDD"
      p={1}
      borderWidth="1px"
      borderColor="whiteAlpha.600"
      borderRadius="xl"
      shadow="xl"
      {...props}
    >
      {children}
    </Flex>
  )
}
