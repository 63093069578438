import {
  duotone,
  regular,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { sortBy } from 'lodash'
import keyBy from 'lodash/keyBy'

import { EMPTY_NODES } from 'modules/tiptap_editor/commands/emptyNodes'

import { CalloutBoxCommands } from '../extensions/CalloutBox/options'
import { DrawingCommands } from '../extensions/Drawing/templates'
import { generateExpandableId } from '../extensions/Expandable/UniqueExpandableId'
import { toggleExpandableOpen } from '../extensions/Expandable/utils'
import {
  generateFootnoteId,
  setFootnoteExpanded,
} from '../extensions/Footnote/FootnoteState'
import { isFootnoteEditor } from '../extensions/Footnote/utils'
import { ListVariant } from '../extensions/lists/ListTypes'
import { MediaCommands } from '../extensions/media/MediaCommands'
import { SmartLayoutCommands } from '../extensions/SmartLayout/commands'
import { Title, TitleLevel } from '../extensions/Title'
import { canInsertNodeAtSelection } from '../utils'
import { CommandInfo } from './types'
import { setDraggingContent } from './utils'

export const COMMANDS_LIST: CommandInfo[] = [
  // CARDS
  {
    key: 'insertCardAfter',
    name: 'New card below',
    nodeName: 'card',
    icon: duotone('diagram-cells'),
    execute: (editor) => editor.commands.insertCardAfter(),
    // Don't enable if cards aren't enabled here (e.g. inside a footnote)
    checkDisabled: (editor) => !editor.schema.nodes.card,
  },
  {
    key: 'insertCardInside',
    name: 'Nested card',
    nodeName: 'card',
    icon: duotone('diagram-subtask'),
    execute: (editor) => editor.commands.insertNestedCard(),
    // Don't enable if cards aren't enabled here (e.g. inside a footnote)
    checkDisabled: (editor) => !editor.schema.nodes.card,
  },
  {
    key: 'splitCard',
    name: 'Split card here',
    nodeName: 'card',
    icon: duotone('page-break'),
    shortcut: '***',
    execute: (editor) => editor.commands.splitCardAtSelection(),
    checkDisabled: (editor) => !editor.schema.nodes.card,
    dragStartFn: (editor) => {
      // This one is a special snowflake. First, we'll create an empty paragraph node
      setDraggingContent(editor, EMPTY_NODES.normalText)
    },
    dragEndFn: (editor) => {
      // Then, we'll run the split command once the drag interaction has completed
      editor.chain().splitCardAtSelection().focusDelayed().run()
    },
  },
  {
    key: 'expandableToggle',
    name: 'Expandable',
    keywords: ['toggle', 'expand', 'collapse', 'disclosure', 'details'],
    nodeName: 'expandableToggle',
    icon: duotone('chevron-down'),
    featureFlag: 'expandableToggle',
    execute: (editor) => {
      const id = generateExpandableId()
      toggleExpandableOpen(id)
      editor
        .chain()
        .insertContent({
          ...EMPTY_NODES.expandableToggle,
          attrs: {
            id,
          },
        })
        .selectInsertedNode()
        .run()
    },
    dragStartFn: (editor) => {
      const id = generateExpandableId()
      toggleExpandableOpen(id)
      setDraggingContent(editor, {
        ...EMPTY_NODES.expandableToggle,
        attrs: {
          id,
        },
      })
    },
  },
  {
    key: 'title',
    name: 'Title',
    nodeName: 'title',
    icon: regular('text'),
    shortcut: '/title',
    execute: (editor) => {
      editor
        .chain()
        .focus()
        .setNode(Title.name, { level: TitleLevel.DocTitle })
        .run()
    },
  },
  {
    key: 'h1',
    name: 'Heading 1',
    nodeName: 'heading',
    icon: duotone('h1'),
    shortcut: '# Heading 1',
    execute: (editor) => {
      editor
        .chain()
        .focus()
        .setHeading({
          level: 1,
        })
        .run()
    },
    keywords: ['heading', 'h1'],
  },
  {
    key: 'normalText',
    name: 'Normal text',
    nodeName: 'paragraph',
    icon: duotone('text'),
    execute: (editor) => {
      editor.chain().focus().setParagraph().run()
    },
    keywords: ['normal', 'paragraph', 'reset', 'clear'],
  },
  {
    key: 'h2',
    name: 'Heading 2',
    nodeName: 'heading',
    icon: duotone('h2'),
    shortcut: '## Heading 2',
    execute: (editor) => {
      editor
        .chain()
        .focus()
        .setHeading({
          level: 2,
        })
        .run()
    },
    keywords: ['heading', 'h2'],
  },
  {
    key: 'h3',
    name: 'Heading 3',
    nodeName: 'heading',
    icon: duotone('h3'),
    shortcut: '### Heading 3',
    execute: (editor) => {
      editor
        .chain()
        .focus()
        .setHeading({
          level: 3,
        })
        .run()
    },
    keywords: ['heading', 'h3'],
  },
  {
    key: 'bulletedList',
    name: 'Bulleted list',
    nodeName: ListVariant.Bullet,
    icon: duotone('list-ul'),
    shortcut: '- Item',
    execute: (editor) => {
      editor
        .chain()
        .setListItems({ variant: ListVariant.Bullet })
        .selectInsertedNode()
        .run()
    },
    keywords: ['bullets', 'unordered', 'ul', 'list'],
  },
  {
    key: 'numberedList',
    name: 'Numbered list',
    nodeName: ListVariant.Numbered,
    icon: duotone('list-ol'),
    shortcut: '1. Item',
    execute: (editor) => {
      editor
        .chain()
        .setListItems({ variant: ListVariant.Numbered })
        .selectInsertedNode()
        .run()
    },
    keywords: ['numbers', 'numbered', 'ordered', 'ol', 'list'],
  },
  {
    key: 'todoList',
    name: 'Todo list',
    nodeName: ListVariant.Todo,
    icon: duotone('tasks'),
    shortcut: '[] Item',
    execute: (editor) => {
      editor
        .chain()
        .setListItems({ variant: ListVariant.Todo })
        .selectInsertedNode()
        .run()
    },
    keywords: ['todo', 'task', 'checkbox', 'list', 'checklist'],
  },
  {
    key: 'blockquote',
    name: 'Blockquote',
    nodeName: 'blockquote',
    icon: duotone('block-quote'),
    shortcut: '> Quote',
    execute: (editor) => editor.chain().focus().toggleBlockquote().run(),
  },
  ...CalloutBoxCommands,
  {
    key: 'buttonGroup',
    name: 'Button',
    nodeName: 'buttonGroup',
    icon: duotone('hand-pointer'),
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.buttonGroup),
  },
  {
    key: 'codeBlock',
    name: 'Code block',
    nodeName: 'codeBlock',
    icon: duotone('code'),
    // shortcut: '```code```', // This doesn't work yet
    featureFlag: 'codeBlock',
    execute: (editor) => {
      editor.chain().focus().setCodeBlock().run()
    },
  },
  {
    key: 'mathBlock',
    name: 'Math block',
    nodeName: 'math_display',
    featureFlag: 'math',
    icon: duotone('function'),
    keywords: ['math', 'equation', 'latex', 'katex'],
    execute: (editor) =>
      editor.chain().focus().insertContent(EMPTY_NODES.mathBlock).run(),
    dragEndFn: () => {},
    description: 'Write equations using Katex syntax',
  },
  {
    key: 'mathInline',
    name: 'Inline math',
    featureFlag: 'math',
    nodeName: 'math_inline',
    icon: duotone('sigma'),
    keywords: ['math', 'equation', 'latex', 'katex'],
    execute: (editor) => editor.chain().focus().insertMathInline().run(),
    shortcut: '$x^2$ ',
  },
  {
    key: 'table2',
    name: '2x2 table',
    keywords: ['table', 'grid', 'data'],
    nodeName: 'table',
    icon: duotone('table'),
    execute: (editor) =>
      editor.commands.insertTable({ rows: 2, cols: 2, withHeaderRow: false }),
    // Passing a no-op prevents onFocusDragEnd getting called from within onItemDragEnd in InsertWidgetButtons
    // onFocusDragEnd can cause the selection to be incorrect for tables when dragging between cards
  },
  {
    key: 'table3',
    name: '3x3 table',
    keywords: ['table', 'grid', 'data'],
    nodeName: 'table',
    icon: duotone('table'),
    execute: (editor) =>
      editor.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: false }),
  },
  {
    key: 'table4',
    name: '4x4 table',
    keywords: ['table', 'grid', 'data'],
    nodeName: 'table',
    icon: duotone('table'),
    execute: (editor) =>
      editor.commands.insertTable({ rows: 4, cols: 4, withHeaderRow: false }),
  },
  {
    key: 'columns2',
    name: '2 columns',
    keywords: ['columns', 'layout', 'grid', 'two'],
    nodeName: 'gridLayout',
    icon: duotone('columns-3'),
    execute: (editor) => editor.commands.insertLayout(2),
  },
  {
    key: 'columns3',
    name: '3 columns',
    keywords: ['columns', 'layout', 'grid', 'three'],
    nodeName: 'gridLayout',
    icon: duotone('columns-3'),
    execute: (editor) => editor.commands.insertLayout(3),
  },
  {
    key: 'columns4',
    name: '4 columns',
    keywords: ['columns', 'layout', 'grid', 'four'],
    nodeName: 'gridLayout',
    icon: duotone('columns-3'),
    execute: (editor) => editor.commands.insertLayout(4),
  },
  {
    key: 'contributors',
    name: 'Contributors',
    nodeName: 'contributors',
    icon: duotone('user-astronaut'),
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.contributors),
  },
  {
    key: 'tableOfContents',
    name: 'Table of contents',
    featureFlag: 'tableOfContentsBlock',
    nodeName: 'tableOfContents',
    icon: duotone('bars-staggered'),
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.tableOfContents, -1),
    keywords: ['toc'],
  },
  {
    key: 'gallery',
    name: 'Gallery',
    description: 'Combine images, videos, and embeds in a zoomable carousel',
    nodeName: 'gallery',
    icon: duotone('grid-2-plus'),
    keywords: ['gallery', 'image', 'layout', 'filmstrip', 'carousel'],
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.gallery),
    featureFlag: 'imageGallery',
  },
  {
    key: 'footnote',
    name: 'Footnote',
    nodeName: 'footnote',
    shortcut: '^note^',
    icon: duotone('superscript'),
    execute: (editor) => {
      const noteId = generateFootnoteId()
      const newNode = { ...EMPTY_NODES.footnote, attrs: { noteId } }
      setFootnoteExpanded(noteId, true)
      editor.commands.insertContent(newNode)
    },
    dragStartFn: (editor) => {
      const noteId = generateFootnoteId()
      const newNode = { ...EMPTY_NODES.footnote, attrs: { noteId } }
      setFootnoteExpanded(noteId, true)
      setDraggingContent(editor, newNode)
    },
    checkDisabled: isFootnoteEditor, // Dont allow footnotes inside footnotes
  },
  ...SmartLayoutCommands,
  ...MediaCommands,
  ...DrawingCommands,
]

export const SORTED_COMMANDS = sortBy(
  COMMANDS_LIST,
  (command) => command.priority
)

export const COMMANDS_MAP = keyBy(COMMANDS_LIST, 'key')

export const checkCommandDisabled = (editor, command) => {
  if (command.checkDisabled?.(editor)) {
    return true
  }
  return !canInsertNodeAtSelection(editor, command.nodeName)
}
