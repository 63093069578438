/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { yUndoPluginKey } from '@gamma-app/y-prosemirror'
import { Extension, getMarkType, getNodeType } from '@tiptap/core'
import debounce from 'lodash/debounce'
import { MarkType, NodeType } from 'prosemirror-model'
import * as Y from 'yjs'

import { featureFlags } from 'modules/featureFlags'
import getSchemaTypeNameByName from 'utils/schema'

import { AnnotationDataSource } from './AnnotationDataSource'
import { createAnnotationPlugin } from './AnnotationPlugin'
import { AnnotationPluginKey } from './AnnotationPluginKey'
import { AnnotationState } from './AnnotationState'
import {
  AddAnnotationAction,
  AnnotationEvent,
  AnnotationOptions,
  AnnotationStateEntry,
  DeleteAnnotationAction,
  MoveInstruction,
  RestoreAnnotationEntry,
  RestoreAnnotationsAction,
  UpdateNodeAttrsAnnotationEvent,
} from './types'
import { reverseOps, UndoOperation } from './UndoableYMap'

type RestoreAnnotationsMap = {
  [key: string]: RestoreAnnotationEntry
}
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    annotation: {
      addAnnotation: (params: AddAnnotationParams) => ReturnType
      deleteAnnotation: (id: string) => ReturnType
      clearAnnotations: () => ReturnType
      refreshDecorations: () => ReturnType
      moveAnnotations: (toMove: MoveInstruction[]) => ReturnType
      restoreAnnotations: (toRestore: RestoreAnnotationsMap) => ReturnType
    }
  }
}

type AddAnnotationParams = {
  id: string
  pos: number
  data?: any
}

export const AnnotationExtension = Extension.create<AnnotationOptions>({
  name: 'annotation',

  onCreate() {
    const log = (...args: any[]) => {
      featureFlags.get('debugComments')
        ? console.log(...args)
        : console.debug(...args)
    }
    this.options.document.on('afterAllTransactions', (a) => {
      const annotationState = AnnotationPluginKey.getState(
        this.editor.view.state
      ) as AnnotationState
      const queuedMoves = annotationState.flushMoveInstructionQueue()
      if (queuedMoves.length > 0) {
        log(
          '%c[Annotations] afterAllTransactions handling queued moves',
          'color:pink',
          queuedMoves
        )
        this.editor.commands.moveAnnotations(queuedMoves)
      } else if (annotationState.refreshDecorations) {
        log(
          '%c[Annotations] afterAllTransactions refreshDecorations',
          'color:pink'
        )
        this.editor.commands.refreshDecorations()
      }
    })

    const annotationState = AnnotationPluginKey.getState(this.editor.state)!
    // @ts-ignore
    window.gammaAnnotations = annotationState
    const undoManager: Y.UndoManager = yUndoPluginKey.getState(
      this.editor.state
    ).undoManager

    // hack to enable this to get access to the Y.UndoManager on() method
    // it's most likely due to the undo manager not being initialized
    setTimeout(() => {
      undoManager.on('stack-item-popped', (event) => {
        const serialized = event.stackItem.meta.get('annotations') as
          | UndoOperation<AnnotationStateEntry>[]
          | undefined

        if (!serialized) {
          annotationState.persistRestoreMap(this.editor.state, true)
          return
        }

        const { undoStack, redoStack } = undoManager

        // an undo happened add info to redo stack
        if (event.type === 'undo' && redoStack.length > 0) {
          redoStack[redoStack.length - 1].meta.set(
            'annotations',
            reverseOps(serialized)
          )
        }
        // an redo happened, add info to undo stack
        else if (event.type === 'redo' && undoStack.length > 0) {
          undoStack[undoStack.length - 1].meta.set(
            'annotations',
            reverseOps(serialized)
          )
        }
        annotationState.restore(this.editor.state, serialized)
      })
    }, 0)

    annotationState.map.observe(
      debounce(() => {
        log('[Annotations] map observe -> resfreshDecorations')
        this.editor.commands.refreshDecorations()
      })
    )
    // Initialize the decorations for the first time
    this.editor.commands.refreshDecorations()
  },

  addCommands() {
    return {
      restoreAnnotations:
        (toRestore) =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            tr.setMeta(AnnotationPluginKey, <RestoreAnnotationsAction>{
              type: 'restoreAnnotations',
              toRestore: Object.values(toRestore),
            })
            dispatch(tr)
          }

          return true
        },
      // override the default `commands.updateAttributes` provided in tiptap/core.  This makes updating attributes
      // respect any annotatable attached to it
      updateAttributes:
        (typeOrName, attributes = {}) =>
        ({ tr, state, dispatch }) => {
          // taken from tiptap/packages/core/src/commands/updateAttributes.ts
          // we fork it to add support for moving annotation
          let nodeType: NodeType | null = null
          let markType: MarkType | null = null

          const schemaType = getSchemaTypeNameByName(
            typeof typeOrName === 'string' ? typeOrName : typeOrName.name,
            state.schema
          )

          if (!schemaType) {
            return false
          }

          if (schemaType === 'node') {
            nodeType = getNodeType(typeOrName as NodeType, state.schema)
          }

          if (schemaType === 'mark') {
            markType = getMarkType(typeOrName as MarkType, state.schema)
          }

          if (dispatch) {
            tr.selection.ranges.forEach((range) => {
              const from = range.$from.pos
              const to = range.$to.pos

              state.doc.nodesBetween(from, to, (node, pos) => {
                if (nodeType && nodeType === node.type) {
                  const annotationEvent: UpdateNodeAttrsAnnotationEvent = {
                    type: 'update-node-attrs',
                    pos: pos,
                  }
                  tr.setNodeMarkup(pos, undefined, {
                    ...node.attrs,
                    ...attributes,
                  }).setMeta('annotationEvent', annotationEvent)
                }

                if (markType && node.marks.length) {
                  node.marks.forEach((mark) => {
                    if (markType === mark.type) {
                      const trimmedFrom = Math.max(pos, from)
                      const trimmedTo = Math.min(pos + node.nodeSize, to)

                      tr.addMark(
                        trimmedFrom,
                        trimmedTo,
                        markType.create({
                          ...mark.attrs,
                          ...attributes,
                        })
                      )
                    }
                  })
                }
              })
            })
          }

          return true
        },

      moveAnnotations:
        (toMove: MoveInstruction[]) =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            tr.setMeta(AnnotationPluginKey, {
              type: 'moveAnnotations',
              toMove,
            })
            dispatch(tr)
          }

          return true
        },
      clearAnnotations:
        () =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            tr.setMeta(AnnotationPluginKey, {
              type: 'clearAnnotations',
            })
            dispatch(tr)
          }

          return true
        },
      refreshDecorations:
        () =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            tr.setMeta(AnnotationPluginKey, {
              type: 'refreshAnnotationDecorations',
            })
            dispatch(tr)
          }

          return true
        },
      addAnnotation:
        ({ pos, id, data }: AddAnnotationParams) =>
        ({ dispatch, state }) => {
          if (dispatch) {
            state.tr.setMeta(AnnotationPluginKey, <AddAnnotationAction>{
              type: 'addAnnotation',
              id,
              data,
              pos,
            })
          }

          return true
        },
      deleteAnnotation:
        (id) =>
        ({ dispatch, state }) => {
          if (dispatch) {
            state.tr.setMeta(AnnotationPluginKey, <DeleteAnnotationAction>{
              type: 'deleteAnnotation',
              id,
            })
          }
          return true
        },
    }
  },

  addProseMirrorPlugins() {
    const { document, onUpdate } = this.options
    const dataSource = new AnnotationDataSource(document)

    return [
      createAnnotationPlugin({
        document,
        editor: this.editor,
        map: dataSource.annotations,
        restoreMap: dataSource.annotationsAbsolute,
        onUpdate,
      }),
    ]
  },
})
