import { useState, useEffect } from 'react'

import { featureFlags } from '../FeatureFlagProvider'
import { FeatureFlags } from '../flags'

/**
 * React hook for subscribing to a feature flag
 *
 * @param {string} flag
 * @param {any} defaultValue
 */
export function useFeatureFlag<
  F extends keyof FeatureFlags = keyof FeatureFlags
>(flag: F): FeatureFlags[F] {
  const [value, setValue] = useState<FeatureFlags[F]>(
    // Not sure why this needs to be cast
    featureFlags.get(flag) as FeatureFlags[F]
  )

  useEffect(() => {
    if (!featureFlags.hasInitialized) {
      // Ensure to update upon initialization
      featureFlags.initializePromise.then(() => {
        setValue(featureFlags.get(flag) as FeatureFlags[F])
      })
    }
    return featureFlags.subscribe(flag, (v) => {
      setValue(v as FeatureFlags[F])
    })
  }, [flag])

  return value
}

export function useAllFeatureFlags(): FeatureFlags {
  const [flags, setFlags] = useState<FeatureFlags>(featureFlags.all())

  useEffect(() => {
    return featureFlags.onChange(() => {
      setFlags(featureFlags.all())
    })
  }, [])

  return flags
}
