import { isEqual } from 'lodash'
import { EditorState, Transaction } from 'prosemirror-state'

import { AppDispatch } from 'modules/redux'
import { CardIds, CardIdMap } from 'modules/tiptap_editor'
import { setCardIds } from 'modules/tiptap_editor/reducer'

import { generateCardIdMap } from './cardIdMap'

export class CardIdsPluginState {
  public hasChanges: boolean = false

  public value: { cardIds: CardIds; cardIdMap: CardIdMap } | null = null

  public compute(state: EditorState): this {
    const nextValue = generateCardIdMap(state)
    if (!isEqual(this.value, nextValue)) {
      this.hasChanges = true
    }

    // release old ref
    this.value = nextValue
    return this
  }

  public apply(tr: Transaction, state: EditorState): this {
    if (!tr.docChanged) {
      // early return to account for things like selection update
      return this
    }
    this.compute(state)

    return this
  }

  public processChanges(dispatch: AppDispatch): void {
    if (this.hasChanges && this.value) {
      dispatch(setCardIds(this.value))
    }

    this.hasChanges = false
  }
}
