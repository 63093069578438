import * as Y from 'yjs'

import { AnnotationsYKeyValueMigration } from './migrations/AnnotationsYKeyValueMigration'
import { Migration } from './migrations/migration'
import {
  AnnotationStateYMap,
  RestoreAnnotationEntry,
  RestoreAnnotationYKeyValue,
} from './types'
import { YKeyValue } from './y-keyvalue'

export class AnnotationDataSource {
  static ANNOTATION_MIGRATIONS_KEY = 'annotationMigrations'

  document: Y.Doc

  migrations: Migration[] = [new AnnotationsYKeyValueMigration()]

  annotations: AnnotationStateYMap

  annotationsAbsolute: RestoreAnnotationYKeyValue

  constructor(doc: Y.Doc) {
    this.document = doc
    this.document.transact(() => {
      this.runMigrations()
    })

    this.annotations = this.document.getMap('annotations')
    this.annotationsAbsolute = new YKeyValue<RestoreAnnotationEntry>(
      this.document.getArray('annotationsAbsoluteKV')
    )
  }

  private runMigrations() {
    const migrationsMap = this.getMigrationsMap()

    for (const migration of this.migrations) {
      if (this.hasRunMigration(migration.name)) {
        continue
      }

      try {
        migration.run(this.document)
        migrationsMap.set(migration.name, true)
      } catch (e) {
        // swallow
      }
    }
  }

  getMigrationsMap(): Y.Map<any> {
    return this.document.getMap(AnnotationDataSource.ANNOTATION_MIGRATIONS_KEY)
  }

  private hasRunMigration(key: string): boolean {
    return this.getMigrationsMap().get(key) || false
  }
}
