import { Editor } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'

import { isMobileDevice } from 'utils/deviceDetection'

import {
  MobileAnnotationPluginKey,
  MobileAnnotationState,
} from './MobileAnnotationState'

const DOUBLE_CLICK_THRESHOLD = 300

export function createMobileAnnotationPlugin(editor: Editor) {
  const plugin = new Plugin({
    key: MobileAnnotationPluginKey,
    state: {
      init() {
        return new MobileAnnotationState()
      },
      apply(tr, pluginState, _oldEditorState, newEditorState) {
        return pluginState.apply(tr, newEditorState)
      },
    },

    props: {
      handleDOMEvents: {
        click(view: EditorView, event: MouseEvent) {
          if (!isMobileDevice) {
            return false
          }

          // only allow long press on the content of a node, not other comments
          const target = event.target as HTMLElement
          if (target.closest('[data-comments-wrapper]')) {
            return
          }
          const state = MobileAnnotationPluginKey.getState(view.state)!
          // there is already a MobileAddCommentWidghet shown
          if (state.pos) {
            return
          }

          if (state.timeout) {
            // a click happened before
            clearTimeout(state.timeout)
            state.timeout = null
            editor.commands.setMobileAddBlockComment(event)
          } else {
            // first click
            state.timeout = setTimeout(() => {
              state.timeout = null
            }, DOUBLE_CLICK_THRESHOLD)
          }
          return
        },
      },
      decorations(state) {
        const pluginState = MobileAnnotationPluginKey.getState(state)

        const found = pluginState!.pos

        if (found === null) {
          return DecorationSet.empty
        }

        const deco = Decoration.node(
          found.pos,
          found.end,
          {}, // classes
          {
            isMobileAnnotation: true,
            type: 'show-buttons',
            offsetX: found.offsetX,
            offsetY: found.offsetY,
          }
        )

        return DecorationSet.create(state.doc, [deco])
      },
    },
  })
  return plugin
}
