/**
 * This menu powers the `@mention` suggestion menu search. See the React component
 * passed to the `MenuComponent` field for the search and list functionality.
 *
 * People can use this menu to add UserMentions (`mention` nodes) or DocMentions
 * (to be implemented).
 *
 * Each of the mention node types is responsible for registering
 * its own NodeView and its own Editor command.
 */

import { Editor, Range } from '@tiptap/core'
import { PluginKey } from 'prosemirror-state'

import { Card, DocResult, User } from 'modules/api'
import { MentionList } from 'modules/tiptap_editor/components/menus/MentionSuggestionMenu/MentionList'
import { GraphqlUser } from 'modules/user'

import { ExtensionPriorityMap } from '../constants'
import { createSuggestionExtension } from '../Suggestion'

const MentionKey = new PluginKey('MentionGamma')
export const MENTION_SUGGESTION_CHARACTER = '@'
type MentionSuggestionCommandArgs = {
  editor: Editor
  range: Range
  props: {
    item: DocResult | User | Card
    user: GraphqlUser
  }
}
export const MentionSuggestionMenu = createSuggestionExtension({
  name: 'GammaMention',
  char: MENTION_SUGGESTION_CHARACTER,
  pluginKey: MentionKey,
  allowSpaces: true,
  MenuComponent: MentionList,
  /*
   * Priority needs to be ahead of List.ts so you can hit Enter inside
   * the suggestion menu when in a list
   */
  priority: ExtensionPriorityMap.MentionSuggestionMenu,
  command: ({ editor, range, props }: MentionSuggestionCommandArgs) => {
    const { item, user } = props
    if (item.__typename === 'User') {
      const { id, displayName: label, profileImageUrl } = item
      const { id: mentionedById } = user

      editor.commands.addUserMention(
        { id, label, profileImageUrl, mentionedById },
        range
      )
    } else if (item.__typename === 'Card') {
      const { id, docId } = item
      const { id: mentionedById } = user
      editor.commands.addCardMention(
        { id, docId: docId!, mentionedById },
        range
      )
    } else if (item.__typename === 'DocResult') {
      const { id } = item
      const { id: mentionedById } = user
      editor.commands.addDocMention({ id, mentionedById }, range)
    }
  },
})
