import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

export type IntercomState = {
  isOpen: boolean
}

const initialState: IntercomState = {
  isOpen: false,
}

const IntercomSlice = createSlice({
  name: 'Intercom',
  initialState,
  reducers: {
    reset: () => initialState,
    setIsIntercomOpen(
      state: IntercomState,
      action: PayloadAction<{
        isOpen: boolean
        initialQuery?: string
      }>
    ) {
      const { isOpen } = action.payload
      state.isOpen = isOpen
    },
  },
})

export const { setIsIntercomOpen } = IntercomSlice.actions

type SliceState = Pick<RootState, 'Intercom'>

// Selectors
export const selectIsIntercomOpen = (state: SliceState): boolean => {
  return state.Intercom.isOpen
}

// Reducer
export const reducer = IntercomSlice.reducer
