import { mergeAttributes, Node } from '@tiptap/core'

export const LayoutCell = Node.create({
  name: 'gridCell',
  // In general, we only want standard blocks that can't nest inside layouts
  // e.g. no cards, no other layouts. We can make an exception for complex blocks
  // like blockquotes by putting them in layoutBlock
  content: '(block | layoutBlock)+',
  defining: true,
  isolating: true,
  selectable: false,

  parseHTML() {
    return [
      {
        tag: 'div[class=grid-cell]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'grid-cell' }), 0]
  },
})
