import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { ToolbarButton } from 'modules/tiptap_editor/components/menus/ToolbarButton'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const NumberedOptionControl = ({
  value,
  updateValue,
}: SmartLayoutOptionControlProps<boolean>) => {
  return (
    <ToolbarButton
      label="Numbered"
      onClick={() => updateValue(!value)}
      isActive={value}
      icon={regular('list-ol')}
    />
  )
}

export const NumberedOption: SmartLayoutOption<boolean> = {
  key: 'numbered',
  defaultValue: true,
  Control: NumberedOptionControl,
}
