import {
  PasteRule,
  PasteRuleFinder,
  ExtendedRegExpMatchArray,
  callOrReturn,
} from '@tiptap/core'
import { NodeType } from 'prosemirror-model'

/**
 * Build a paste rule that replaces text found with a node.
 */
export function nodePasteRule(config: {
  find: PasteRuleFinder
  type: NodeType
  getAttributes?:
    | Record<string, any>
    | ((match: ExtendedRegExpMatchArray) => Record<string, any>)
    | false
    | null
}) {
  const { type: nodeType } = config
  if (!nodeType.isInline) {
    // Only allow inline nodes to be used here.
    // See Discord chat about why this is tricky for other node types:
    // https://discord.com/channels/818568566479257641/818569721934774272/854103944669822996
    throw new Error(
      '[nodePasteRule] This paste rule was only designed to consider inline nodes.'
    )
  }

  return new PasteRule({
    find: config.find,
    handler: ({ state, range, match }) => {
      const { tr } = state
      const start = range.from
      const end = range.to
      const attributes =
        callOrReturn(config.getAttributes, undefined, match) || {}

      tr.replaceWith(start, end, config.type.create(attributes))
    },
  })
}
