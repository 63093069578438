import { Flex, Spinner } from '@chakra-ui/react'

export const FullPageSpinner = () => {
  return (
    <Flex
      className="full-page-spinner"
      flex="1"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner></Spinner>
    </Flex>
  )
}
