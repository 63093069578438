import {
  prosemirrorJSONToYDoc,
  yDocToProsemirrorJSON,
} from '@gamma-app/y-prosemirror'
import { HocuspocusProvider } from '@hocuspocus/provider'
import { Editor } from '@tiptap/core'
import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import { ID, Item, GC } from 'yjs'

import { config } from 'config'
import { useFeatureFlag } from 'modules/featureFlags'

type LastItemRemoteResponse = {
  lastKnownItem: {
    id: ID | undefined
    length: number | undefined
  }
  itemSummary: {
    clientCount: number | undefined
    totalCount: number | undefined
  }
}
const getLastItemRemote = async (
  docId: string,
  clientID: number,
  lastItemLocal?: Item | GC,
  itemSummaryLocal?: LastItemRemoteResponse['itemSummary']
) => {
  try {
    const { id, length } = lastItemLocal || {}
    const resp = await fetch(
      `${config.MULTIPLAYER_WS_URL.replace(
        'wss',
        'https'
      )}/${docId}/verify/${clientID}`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id,
          length,
          clientCount: itemSummaryLocal?.clientCount,
          totalCount: itemSummaryLocal?.totalCount,
        }),
      }
    )
    const data = await resp.json()
    return data as LastItemRemoteResponse
  } catch (err) {
    // Any issue fetching should return undefined, which will count as a sync error
  }
  return
}

const getLastItemLocal = (provider: HocuspocusProvider) => {
  const items = provider.document.store.clients.get(provider.document.clientID)

  if (!items || !items.length) return

  return items[items.length - 1]
}

const getItemSummaryLocal = (provider: HocuspocusProvider) => {
  const clientCount = provider.document.store.clients.size
  const totalCount = [...provider.document.store.clients.values()].reduce(
    (acc_c, items) => acc_c + items.reduce((acc_i, i) => acc_i + i.length, 0),
    0
  )

  return {
    clientCount,
    totalCount,
  }
}

/**
 * Compares this client's most recent edit locally with Hocuspocus
 * to see if they are in sync.
 */
const compareClocks = async (
  editor: Editor,
  provider: HocuspocusProvider,
  clockDriftTolerance: number
) => {
  const lastItemLocal = getLastItemLocal(provider)
  const itemSummaryLocal = getItemSummaryLocal(provider)

  const lastItemRemote = await getLastItemRemote(
    editor.gammaDocId!,
    provider.document.clientID,
    lastItemLocal,
    itemSummaryLocal
  )
  if (!lastItemRemote) {
    console.warn('[compareClocks] Failed to fetch remote clock')
    return false
  }
  try {
    const localTotal =
      (lastItemLocal?.id.clock || 0) + (lastItemLocal?.length || 0)
    const remoteTotal =
      (lastItemRemote?.lastKnownItem.id?.clock || 0) +
      (lastItemRemote?.lastKnownItem.length || 0)
    const diffLastItem = localTotal - remoteTotal
    if (diffLastItem > 0) {
      console.warn('[compareClocks] Clock drift detected', { diffLastItem })
    }

    const diffClientCount =
      itemSummaryLocal.clientCount -
      (lastItemRemote.itemSummary.clientCount || 0)
    const diffTotalCount =
      itemSummaryLocal.totalCount - (lastItemRemote.itemSummary.totalCount || 0)

    if (diffClientCount > 0) {
      console.warn('[compareClocks] clientCount drift detected', {
        diffClientCount,
      })
    }

    if (diffTotalCount > 0) {
      console.warn('[compareClocks] totalCount drift detected', {
        diffTotalCount,
      })
    }

    return (
      diffClientCount === 0 &&
      diffTotalCount <= clockDriftTolerance &&
      diffLastItem <= clockDriftTolerance
    )
  } catch (err) {
    console.warn('[compareClocks] Failed to fetch remote clock')
  }

  return false
}

/**
 * Compare the JSON in prosemirror state with the JSON in the local YDoc.
 */
const compareDocJSON = (editor: Editor, provider: HocuspocusProvider) => {
  const editorYDoc = prosemirrorJSONToYDoc(
    editor.schema,
    editor.getJSON(),
    'default'
  )
  const editorJSON = yDocToProsemirrorJSON(editorYDoc, 'default')

  // Convert the YDoc to JSON first so we can use the schema to
  // do the same comparison as we do above. Without this, there can be
  // minor mismatches with attrs/content being undefined vs empty.
  const yDocJSON = yDocToProsemirrorJSON(provider.document, 'default')
  const providerYDoc = prosemirrorJSONToYDoc(editor.schema, yDocJSON, 'default')
  const providerJSON = yDocToProsemirrorJSON(providerYDoc, 'default')

  // TODO - Ignore key: undefined for this comparison
  const result = isEqual(editorJSON, providerJSON)
  return result
}

/**
 * Hook to periodically check the "in sync" state of the tiptap editor
 * Checks this in 2 different ways:
 *   - Phase 1: If the prosemirror state has been synced to the local YDoc
 *   - Phase 2: If the local YDoc is in sync with the prosemirror YDoc
 *
 *     This is done by asking Hocuspocus for the last known change for
 *     a specific client ID and comparing that to the local YDoc
 */
export const useDataSyncMonitor = ({
  editor,
  yProvider,
  enabled,
  pollingInterval,
}: {
  editor: Editor | undefined
  yProvider: HocuspocusProvider | undefined
  enabled: boolean
  pollingInterval: number
}) => {
  const [yDocLastSynced, setLastSynced] = useState(Date.now())
  const [errorCountClock, setCountClock] = useState(0)
  const [errorCountPMState, setCountPMState] = useState(0)
  // How far off the clock can drift before considering it an error
  // In practice, this has been observed to be off by 2 when typing really fast
  const clockDriftTolerance = useFeatureFlag('dataSyncClockDriftTolerance')

  useEffect(() => {
    if (!editor || !yProvider || !enabled) return

    let shouldCheck = true
    let timeoutId: number | undefined

    const doCheck = async () => {
      if (!editor.gammaDocId) return

      const clocksInSync = await compareClocks(
        editor,
        yProvider,
        clockDriftTolerance
      )
      if (!clocksInSync) {
        console.warn(
          '[useDataPersistenceSync_2] Clocks out of sync for doc',
          editor.gammaDocId
        )
      } else {
        setLastSynced(Date.now())
      }

      requestIdleCallback(() => {
        // Disable until we can fix the theme edit issue with duplicated unedited decks
        // https://linear.app/gamma-app/issue/G-3138/data-sync-1-check-triggers-incorrectly-when-editing-a-custom-theme
        const docsAreEqual = true // compareDocJSON(editor, yProvider)

        if (!docsAreEqual) {
          console.warn(
            '[useDataPersistenceSync_1] Prosemirror & YJS out of sync',
            editor.gammaDocId
          )
        }

        setCountClock((p) => (clocksInSync ? 0 : p + 1))
        setCountPMState((p) => (docsAreEqual ? 0 : p + 1))

        if (shouldCheck) {
          timeoutId = window.setTimeout(doCheck, pollingInterval)
        }
      })
    }

    doCheck()

    return () => {
      clearTimeout(timeoutId)
      shouldCheck = false
    }
  }, [editor, yProvider, enabled, pollingInterval, clockDriftTolerance])

  return { errorCountClock, errorCountPMState, yDocLastSynced }
}
