import { Flex } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'
import { MutableRefObject } from 'react'
import { useSelector } from 'react-redux'

import { Comment } from 'modules/api'
import { checkAccessLinkForLoggedOutUser } from 'modules/auth/accesslink_signup/'
import { useAppSelector } from 'modules/redux'
import { selectMode, selectDoc } from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'

import { CommentBox } from '../../Comments/Comment'
import { CommentSignUp } from '../../Comments/CommentSignUp'
import { useDomNodeOffsetFromTop } from '../BlockCommentsButtons/hooks'
import {
  BaseCommentPopup,
  BLOCK_COMMENTS_CONTAINER_SELECTOR,
} from './BaseCommentPopup'

type ThreadViewPopupProps = {
  comment: Comment | null
  onCancel: () => void
  editor: NodeViewProps['editor']
  getPos: NodeViewProps['getPos']
  commentIdToHighlight: string | null
  userCanComment: boolean
  blockAllowsCommenting: boolean
  commentsPanelRef: MutableRefObject<any>
  isMobile: boolean
}
export const ThreadViewPopup: React.FC<ThreadViewPopupProps> = ({
  comment,
  editor,
  commentIdToHighlight,
  userCanComment,
  blockAllowsCommenting,
  getPos,
  isMobile,
}) => {
  const docId = editor.gammaDocId as string
  const { user } = useUserContext()
  const editorMode = useSelector(selectMode)
  const doc = useAppSelector(selectDoc)
  const accessLink = checkAccessLinkForLoggedOutUser(doc, user)

  const offsetFromTop = useDomNodeOffsetFromTop({
    editor,
    getPos,
  })
  // we dont know the position, so wait until useLayoutEffect before rendering
  if (offsetFromTop === null) {
    return null
  }
  if (!comment) {
    return null
  }

  const inner = (
    <Flex
      direction="column"
      className="comments-list"
      data-testid="thread-view-popup"
    >
      {accessLink && !user && blockAllowsCommenting && (
        <CommentSignUp
          accessLink={accessLink}
          docId={docId}
          className="comments-sign-up"
        />
      )}
      <CommentBox
        instanceType={isMobile ? 'drawer' : 'popup'}
        userCanComment={userCanComment}
        commentIdToHighlight={commentIdToHighlight}
        editor={editor}
        comment={comment}
        selfUser={user}
        key={comment.id}
        docId={docId}
        editorMode={editorMode}
        isRootComment={true}
        borderBottomWidth="none"
        p={isMobile ? 0 : 4}
        pb={4}
      />
    </Flex>
  )
  if (isMobile) {
    return inner
  }
  return (
    <BaseCommentPopup offsetFromTop={offsetFromTop}>{inner}</BaseCommentPopup>
  )
}
