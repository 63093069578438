import { Button, Link, Text } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'
import { useMemo } from 'react'

import { useAppSelector } from 'modules/redux'

import { NodeViewContent, NodeViewWrapper } from '../../react'
import { selectContentEditable } from '../../reducer'
import { ButtonAttrs } from './Button'

const getButtonStyleProps = (
  variant: ButtonAttrs['variant'],
  color: string
) => {
  if (variant == 'solid') {
    return {
      background: color,
      _hover: { textDecoration: 'none', shadow: 'none' },
    }
  } else if (variant == 'outline') {
    return {
      outline: '1px solid',
      outlineColor: color,
      outlineOffset: '-1px',
      color: color,
      _before: {},
      _hover: { textDecoration: 'none', shadow: 'none' },
    }
  }
  return {}
}

export const ButtonView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations } = nodeViewProps
  const { href, variant } = node.attrs
  const isEditable = useAppSelector(selectContentEditable)
  const isFocused = decorations.some(
    (decoration) => decoration.spec.focusedNode
  )
  const isEditing = isEditable && isFocused

  const styleProps = useMemo(() => {
    return {
      ...getButtonStyleProps(variant, 'var(--link-color)'),
      _hover: isEditable
        ? { textDecoration: 'none', shadow: 'none' }
        : getButtonStyleProps(variant, 'var(--link-color-hover)'),
      _active: isEditable
        ? { textDecoration: 'none', shadow: 'none' }
        : getButtonStyleProps(variant, 'var(--link-color-hover)'),
    }
  }, [variant, isEditable])

  return (
    <NodeViewWrapper>
      <Button
        as={Link}
        href={href}
        variant={variant}
        cursor={isEditable ? 'text' : 'pointer'}
        userSelect={isEditable ? 'auto' : 'none'}
        {...styleProps}
        isDisabled={!isEditable && !href}
        // Force the sizes to scale with present mode
        minW="min(100%, 12em)"
        maxW="100%"
        width={['100%', 'auto']}
        fontSize="1em"
        h="auto"
        py="0.5em"
        px="1em"
        className="button"
        // Prevents a double transition on font size that's too slow
        transitionProperty="none"
      >
        {isEditing ? (
          <NodeViewContent />
        ) : href && !isEditable ? (
          <Text noOfLines={1}>
            <NodeViewContent />
          </Text>
        ) : (
          <Text noOfLines={1} wordBreak="break-all">
            <NodeViewContent />
          </Text>
        )}
      </Button>
    </NodeViewWrapper>
  )
}
