// Returns an array of elements matching the selector

import { Editor } from '@tiptap/core'
import { Fragment } from 'prosemirror-model'

import { serializeFragment } from './index'

// https://stackoverflow.com/a/28211790
export const querySelectorArray = (
  dom: Document | DocumentFragment,
  selector: string,
  reverse: boolean = false
) => {
  const elts = [].slice.call(dom.querySelectorAll(selector), 0) as HTMLElement[]
  if (reverse) {
    elts.reverse()
  }
  return elts
}

const NonTextTags = ['img']
export const isElementEmpty = (node: Node) => {
  if (!node.hasChildNodes()) return true
  if (node.textContent?.trim() != '' || NonTextTags.includes(node.nodeName))
    return false
  return [...node.childNodes].every((child) => {
    return isElementEmpty(child)
  })
}

export const getSerializedNodeAtPos = (editor: Editor, pos: number) => {
  const node = editor.state.doc.nodeAt(pos)
  if (!node) return
  const fragment = Fragment.from(node)
  const serialized = serializeFragment(fragment, editor.schema)
  // Turn the fragment into HTML for clipboard
  const div = document.createElement('div')
  div.appendChild(serialized)
  const html = div.innerHTML
  return html
}
