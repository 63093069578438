import { Editor } from '@tiptap/core'
import { Decoration } from 'prosemirror-view'
import { useEffect, useState, useRef } from 'react'

export const useMobileAddCommentPos = ({
  editor,
  decorations,
}: {
  editor: Editor
  decorations: Decoration[]
}) => {
  const mobileAnnotations = decorations.filter(
    (d) => !!d.spec.isMobileAnnotation
  )
  const [addCommentPos, setAddCommentPos] = useState<{
    left: number
    top: number
  } | null>(null)
  const closeAddCommentCbRef = useRef<((ev: TouchEvent) => void) | null>(null)
  useEffect(() => {
    if (mobileAnnotations.length === 0) {
      if (closeAddCommentCbRef.current !== null) {
        document.removeEventListener('touchstart', closeAddCommentCbRef.current)
        closeAddCommentCbRef.current = null
        setAddCommentPos(null)
      }
      return
    }

    if (closeAddCommentCbRef.current) {
      // if there is already a touchstart handler to remove the add comment widget
      // then dont setup another one
      return
    }
    // set the position of the add comment widget
    setAddCommentPos({
      left: mobileAnnotations[0].spec.offsetX as number,
      top: mobileAnnotations[0].spec.offsetY as number,
    })

    // create the callback to hide the add comment widget
    // save as ref, because we dont want to re-run this use effect
    // and need to unbind later
    closeAddCommentCbRef.current = (ev: TouchEvent) => {
      const target = ev.target as HTMLElement
      const found = target.closest('.mobile-add-comment-widget')

      if (found) {
        // if clicked the add comment widget dont hide it
        return
      }
      // remove the addBlockComment, this will cause this useEffect
      // to rerun and clean up the touch handler and null out the position
      editor.commands.setMobileAddBlockComment(null)
    }
    document.addEventListener('touchstart', closeAddCommentCbRef.current)

    // NOTE(jordan) i dont believe there is a need to unbind the listener here
    // if the hook re-runs with no mobileAnnotations then it will unbind
    // this MAY cause some issues when hot reloading the component this hook is used in
    // but still TBD
  }, [editor.commands, mobileAnnotations])
  return addCommentPos
}
