import { ySyncPluginKey } from '@gamma-app/y-prosemirror'
import { findChildren } from '@tiptap/core'
import { customAlphabet } from 'nanoid'
import { Fragment, Node as ProseMirrorNode, Slice } from 'prosemirror-model'
import { PluginKey } from 'prosemirror-state'

import { UniqueAttribute } from '../../plugins'
import { UniqueAttributeOptions } from './../../plugins/uniqueAttribute/uniqueAttribute'
import { CARD_NODE_NAME } from './constants'

// Should mimic packages/server/src/common/utils.ts
export const cardNanoid = {
  generate: customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 15),
}

const pluginKey = new PluginKey('UniqueCardId')

type CardIdMap = { [oldId: string]: string }

export const UniqueCardId = UniqueAttribute.extend({
  name: 'UniqueCardId',
}).configure({
  attributeName: 'id',
  initialValue: () => cardNanoid.generate(),
  filterTransaction: (transaction) => {
    const ySyncMeta = transaction.getMeta(ySyncPluginKey)
    // Ensure that the transaction didnt originate from
    // another user (the name is confusing, but see the code here)
    // https://github.com/yjs/y-prosemirror/blob/a2135a683cb8bdb170c4fb909c8f72a88447951c/src/plugins/sync-plugin.js#L356
    return ySyncMeta?.isChangeOrigin !== true
  },
  pluginKey,
  transformPasted: (
    slice: Slice,
    options: UniqueAttributeOptions,
    docId: string
  ): Slice => {
    const { types, initialValue, attributeName } = options
    let cardIdMap: CardIdMap = {}

    const updateVal = (frag: Fragment): Fragment => {
      // This is based on the `removeVal` function defined here:
      // https://github.com/gamma-app/gamma/blob/fb8f481dcf91fc831c829157abb22525b5df5fc4/packages/client/src/modules/tiptap_editor/plugins/uniqueAttribute/uniqueAttribute.ts#L253-L284
      // which itself comes from @tiptap-pro/extension-unique-id
      // Notably, instead of removing the attribute, it replaces it.
      const list: ProseMirrorNode[] = []
      frag.forEach((node) => {
        // don’t touch text nodes
        if (node.isText) {
          list.push(node)
          return
        }
        // check for any other child nodes
        if (!types.includes(node.type.name)) {
          list.push(node.copy(updateVal(node.content)))
          return
        }
        // replaceId id
        const newVal = initialValue()
        const nodeWithoutVal = node.type.create(
          {
            ...node.attrs,
            [attributeName]: newVal,
          },
          updateVal(node.content),
          node.marks
        )
        if (node.type.name === CARD_NODE_NAME) {
          cardIdMap = {
            ...cardIdMap,
            [node.attrs.id]: newVal,
          }
        }
        list.push(nodeWithoutVal)
      })
      return Fragment.from(list)
    }

    const replaceCardMentions = (frag) => {
      const list: ProseMirrorNode[] = []
      frag.forEach((node) => {
        // don’t touch text nodes
        if (node.isText) {
          list.push(node)
          return
        }

        if (node.type.name === 'cardMention') {
          const newId = cardIdMap[node.attrs.id]
          if (newId) {
            const newNode = node.type.create({
              ...node.attrs,
              ...(newId ? { id: newId } : {}),
              docId,
            })
            list.push(newNode)
          } else {
            list.push(node)
          }
          return
        }
        list.push(node.copy(replaceCardMentions(node.content)))
        return
      })
      return Fragment.from(list)
    }
    const withNewIds = updateVal(slice.content)
    const withNewCardMentions = replaceCardMentions(withNewIds)

    return new Slice(withNewCardMentions, slice.openStart, slice.openEnd)
  },
})
