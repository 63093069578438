import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import { CalloutBoxOptions } from 'modules/tiptap_editor/extensions/CalloutBox/options'
import { CalloutBoxAttrs } from 'modules/tiptap_editor/extensions/CalloutBox/types'

import { ToolbarButton } from '../../components/menus/ToolbarButton'

export const CalloutBoxFormattingMenu = ({
  editor,
  node,
  getPos,
}: NodeViewProps) => {
  const attrs = node.attrs as CalloutBoxAttrs
  const deleteCallout = useCallback(() => {
    editor
      .chain()
      .selectNodeAtPos(getPos())
      .deleteSelectionAndSelectNear()
      .run()
  }, [editor, getPos])

  return (
    <ButtonGroup
      spacing={0}
      size="sm"
      bg="white"
      shadow="lg"
      borderRadius="full"
      border="1px solid"
      borderColor="gray.200"
      position="absolute"
      zIndex="docked" // Go over neighboring nodes and card + button
      bottom="0"
      mb={4} // Clear the inner formatting menu
      py={1}
      px={2}
      contentEditable={false}
      userSelect="none"
    >
      {Object.entries(CalloutBoxOptions).map(([variant, { name, icon }]) => {
        return (
          <ToolbarButton
            key={name}
            label={name}
            icon={icon}
            isActive={attrs.icon && attrs.variant === variant}
            onClick={() => {
              editor
                .chain()
                .focus()
                .updateAttributes('calloutBox', { variant, icon: true })
                .selectNodeAtPos(getPos())
                .run()
            }}
          />
        )
      })}
      <ToolbarButton
        key={'icon'}
        label={attrs.icon ? 'Hide icon' : 'Show icon'}
        icon={regular('ban')}
        isActive={!attrs.icon}
        onClick={() => {
          editor
            .chain()
            .focus()
            .updateAttributes('calloutBox', { icon: !attrs.icon })
            .selectNodeAtPos(getPos())
            .run()
        }}
      />
      <ToolbarButton
        label="Delete callout"
        icon={regular('trash')}
        onClick={deleteCallout}
        color="red"
      />
    </ButtonGroup>
  )
}
