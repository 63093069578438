import { useEffect } from 'react'

// Remember your scroll position and restore it any time an iframe jumps you to the top
// https://linear.app/gamma-app/issue/G-262/embedded-google-sheet-sometimes-scrolls-the-entire-doc-to-the-top

export const usePreventIframeScrollTop = (
  scrollingParentSelector: string = 'body'
) => {
  useEffect(() => {
    const scrollingParent = document.querySelector(scrollingParentSelector)
    if (!scrollingParent) return

    let lastScrollPos

    const handleScroll = (ev) => {
      if (
        ev.target.scrollTop === 0 && // Only catch scrolls that go exactly to position 0
        lastScrollPos > 50 && // That came from farther down the page
        document.activeElement?.tagName === 'IFRAME' // While an iframe was focused
      ) {
        // If we detect a sketchy scroll from the iframe, lock back to the previously saved pos
        console.debug(
          '[EditorCore] Prevented scroll event from iframe',
          ev,
          lastScrollPos,
          document.activeElement
        )
        scrollingParent.scrollTo(0, lastScrollPos)
      } else {
        // Whenever the user scrolls, capture the latest position
        lastScrollPos = ev.target.scrollTop
      }
    }

    scrollingParent.addEventListener('scroll', handleScroll, { passive: true })
    return () => scrollingParent.removeEventListener('scroll', handleScroll)
  }, [scrollingParentSelector])
}
