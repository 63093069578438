import * as Y from 'yjs'

import { RestoreAnnotationYMap, RestoreAnnotationEntry } from '../types'
import { YKeyValue } from '../y-keyvalue'
import { Migration } from './migration'

export class AnnotationsYKeyValueMigration extends Migration {
  name = 'AnnotationsYKeyValue'

  private createKeyValue<K>(doc: Y.Doc, name: string): YKeyValue<K> {
    const arr = doc.getArray(name) as Y.Array<K>
    const ykv = new YKeyValue<K>(arr)
    return ykv
  }

  private getRestoreMap(doc: Y.Doc) {
    return doc.getMap('annotationsAbsolute') as RestoreAnnotationYMap
  }

  run(doc: Y.Doc) {
    const absoluteKv = this.createKeyValue<RestoreAnnotationEntry>(
      doc,
      'annotationsAbsoluteKV'
    )
    const restoreMap = this.getRestoreMap(doc)
    for (const [key, value] of restoreMap.entries()) {
      absoluteKv.set(key, value)
    }
  }
}
