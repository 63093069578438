import { Flex, HStack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeViewProps } from '@tiptap/core'
import { MouseEvent, useCallback } from 'react'

import { FONT_SIZES } from 'modules/tiptap_editor/styles/constants'
import { selectOnClick } from 'modules/tiptap_editor/utils'
import { colorWithLightness } from 'utils/color'

import { DEFAULT_ACCENT_COLOR } from '../../../theming/constants'
import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { useCardColorMode } from '../Card/hooks'
import { isSelectingNodeOrInside } from '../FocusedNodes'
import { highlightColors } from '../TextColor/highlightStyles'
import { textColors } from '../TextColor/textColorStyles'
import { CalloutBoxFormattingMenu } from './CalloutBoxFormattingMenu'
import { findCalloutBoxDeco } from './CalloutBoxPlugin'
import { getCalloutBoxOption } from './options'
import { CalloutBoxAttrs } from './types'

export const CalloutBoxView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, getPos, decorations } = nodeViewProps
  const { variant, icon } = node.attrs as CalloutBoxAttrs
  const { firstChildType } = findCalloutBoxDeco(decorations)
  const isTextNode = firstChildType in FONT_SIZES
  const iconFontSize = isTextNode
    ? FONT_SIZES[firstChildType]
    : FONT_SIZES.paragraph

  const { theme, isDark } = useCardColorMode(decorations)
  const accentColor = theme.accentColor ?? DEFAULT_ACCENT_COLOR
  const { colorScheme, icon: defaultIcon } = getCalloutBoxOption(variant)
  const { backgroundColor, iconColor, bodyColor } = useCalloutBoxColors(
    isDark,
    colorScheme,
    accentColor
  )

  const showMenu = isSelectingNodeOrInside(decorations)

  const selectNode = useCallback(() => {
    if (!editor.isEditable) return
    editor.commands.selectNodeAtPos(getPos())
  }, [editor, getPos])
  const onClick = useCallback(
    (ev: MouseEvent) => {
      if (!isElementOutsideCalloutContent(ev.target as HTMLElement)) {
        return
      }
      // Get a gap cursor if you click on the edges
      return selectOnClick(editor, ev)
    },
    [editor]
  )

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      {showMenu && (
        <Flex
          w="100%"
          align="center"
          direction="column"
          color="black"
          contentEditable={false}
          position="relative"
        >
          <CalloutBoxFormattingMenu {...nodeViewProps} />
        </Flex>
      )}
      <HStack
        direction="row"
        backgroundColor={backgroundColor}
        align={isTextNode ? 'baseline' : 'flex-start'}
        py="0.25em"
        px="1em"
        className="calloutBox"
        spacing="1em"
        borderRadius="var(--block-border-radius)"
        css={{
          '--body-color': bodyColor,
          '--heading-color': bodyColor,
        }}
        onClick={onClick}
      >
        {icon && (
          <Text
            fontSize={iconFontSize}
            onClick={selectNode}
            color={iconColor}
            mt={!isTextNode ? '1em' : undefined}
            contentEditable={false}
          >
            <FontAwesomeIcon icon={defaultIcon} fixedWidth />
          </Text>
        )}
        <NodeViewContent
          style={{
            flex: '1 1 auto',
            minWidth: 0, // Prevents images from overflowing
          }}
        ></NodeViewContent>
      </HStack>
    </AnnotatableNodeViewWrapper>
  )
}

const useCalloutBoxColors = (
  isDark: boolean,
  colorScheme: string,
  accentColor: string
) => {
  const backgroundColor = isDark
    ? colorWithLightness(
        colorScheme === 'accent'
          ? accentColor
          : highlightColors.dark[colorScheme].hex,
        0.2
      )
    : colorWithLightness(
        colorScheme === 'accent'
          ? accentColor
          : highlightColors.light[colorScheme].hex,
        0.8
      )
  const bodyColor = isDark ? 'white' : 'black'
  const iconColor = isDark
    ? colorScheme === 'accent'
      ? colorWithLightness(accentColor, 0.7)
      : textColors.dark[colorScheme].hex
    : colorScheme === 'accent'
    ? colorWithLightness(accentColor, 0.3)
    : textColors.light[colorScheme].hex

  return { backgroundColor, bodyColor, iconColor }
}

export const isElementOutsideCalloutContent = (element) => {
  return !element.closest('[data-node-view-content-inner="calloutBox"]')
}
