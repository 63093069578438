import { config } from 'config'
import { MediaProvidersMap, WebpageProvider } from 'modules/media'

export const getEmbedProvider = (source?: string) =>
  source && MediaProvidersMap[source]
    ? MediaProvidersMap[source]
    : WebpageProvider // Legacy webpage embeds didn't have a source

export const getProxiedUrl = (url?: string) =>
  `${config.EMBED_PROXY_URL}?alt_url=${url}`
