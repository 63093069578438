import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Flex,
  FlexProps,
  HStack,
  Spacer,
  useColorMode,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Content, EditorOptions, findChildren } from '@tiptap/core'
import { EditorContent, useEditor } from '@tiptap/react'
import { isHotkey } from 'is-hotkey'
import React, { ReactElement, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getCommentExtensions } from 'modules/tiptap_editor/CommentEditor/extensions'
import { CommentStyles } from 'modules/tiptap_editor/styles/commentStyles'

const isSubmitCommentHotkey = isHotkey('mod+Enter')
const isSelectAllHotkey = isHotkey('mod+a')
type NoteEditorProps = {
  clearContentOnSave?: boolean
  editable?: boolean
  initialContent?: Content
  placeholder?: string
  saveButtonIcon?: ReactElement
  saveButtonText?: string
  onCancelEditingClick?: () => void
  onCommentSave?: (any) => void
  onCreate?: EditorOptions['onCreate']
  onUpdate?: EditorOptions['onUpdate']
  shouldFocus?: boolean
  showBorder?: boolean
  alwaysShowButtons?: boolean
} & FlexProps

export const NoteEditor = React.memo(
  ({
    initialContent = '',
    placeholder = 'Add notes',
    showBorder = true,
    onCreate = () => {},
    onUpdate = () => {},
    ...flexProps
  }: NoteEditorProps) => {
    const extensions = useMemo(() => getCommentExtensions(), [])

    const noteEditor = useEditor({
      extensions,
      content: initialContent,
      onCreate: ({ editor }) => {
        onCreate({ editor })
      },
      onUpdate: ({ editor, transaction }) => {
        onUpdate({ editor, transaction })
      },
    })

    const handleKeydown = (e) => {
      if (isSelectAllHotkey(e)) {
        // Stop it from selecting the entire doc
        e.preventDefault()
        noteEditor?.commands.selectAll()
      }
      if (isSubmitCommentHotkey(e)) {
        // Stop it from going into present mode
        e.preventDefault()
        e.stopPropagation()
        noteEditor?.commands.blur()
      }
    }

    const { colorMode } = useColorMode()
    const isLight = colorMode === 'light'

    const borderProps: Partial<BoxProps> = showBorder
      ? {
          border: '1px solid',
          borderColor: isLight ? 'gray.200' : 'gray.700',
          borderRadius: 'md',
        }
      : {}

    return (
      <Flex
        data-gamma-child-tiptap-editor="note-editor"
        direction="column"
        h="100%"
        w="100%"
        {...flexProps}
        sx={{
          [`.comment-editor`]: CommentStyles,
          '.ProseMirror': {
            overflow: 'hidden auto',
            minH: '200px',
            maxH: '200px',
            maxW: '100%',
          },
        }}
      >
        <Box
          tabIndex={0}
          w="100%"
          h="100%"
          onKeyDownCapture={handleKeydown}
          onKeyPressCapture={handleKeydown}
          className="comment-editor"
          color={isLight ? 'gray.800' : 'gray.200'}
          bg={isLight ? 'white' : 'gray.800'}
          {...borderProps}
          sx={{
            // Adapted from https://www.tiptap.dev/api/extensions/placeholder/#placeholder
            'p:first-of-type::before': noteEditor?.isEmpty
              ? {
                  content: `"${placeholder}"`,
                  float: 'left',
                  color: isLight ? 'gray.400' : 'gray.500',
                  pointerEvents: 'none',
                  height: '0',
                }
              : '',
          }}
          fontSize="sm"
        >
          <EditorContent
            editor={noteEditor}
            className="comment-editor"
            style={{
              wordBreak: 'break-word',
              width: '100%',
            }}
          />
        </Box>
      </Flex>
    )
  }
)
