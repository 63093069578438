import React, { useContext } from 'react'

import { ExistingWorkspace, ExistingUser, useGetUser } from 'modules/api'

// Coerce the rest API user into a GraphQL user
export interface GraphqlUser extends ExistingUser {
  __typename: 'User'
}

/**
 * Fallback information to use in the event that we
 * dont know who you are. This is sticky based on a
 * localstorage id set in gamma_user_id
 */
export type AnonymousUser = {
  id: string
  displayName?: string
}

export type UserRefetchFn = ReturnType<typeof useGetUser>['refetch']

export type UserContextType = {
  user?: GraphqlUser
  currentWorkspace?: ExistingWorkspace
  setCurrentWorkspaceId?: any
  anonymousUser: AnonymousUser
  isUserLoading: boolean
  isGammaOrgUser: boolean
  color: {
    value: string
    isDark: boolean
  }
  refetch?: UserRefetchFn
}

export const UserContext = React.createContext<UserContextType>({} as any)

export function useUserContext() {
  const ctx = useContext(UserContext)
  return ctx
}
