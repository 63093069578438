import { Flex } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

import { COMMENT_POPUP_WIDTH } from '../../../constants'

export const BLOCK_COMMENTS_CONTAINER_SELECTOR = 'block-comments-container'

type BaseCommentPopupProps = {
  offsetFromTop: number
}

// Todo: adjust this to consider whether TOC is open, which reduces the room for comments to open
export const OPEN_RIGHT_BREAKPOINT = '2xl'

export const BaseCommentPopup: React.FC<
  PropsWithChildren<BaseCommentPopupProps>
> = ({ offsetFromTop, children }) => {
  return (
    <Flex
      contentEditable={false}
      suppressContentEditableWarning={true}
      color="gray.900"
      className={BLOCK_COMMENTS_CONTAINER_SELECTOR}
      direction="column"
      bg="white"
      borderRadius="xl"
      shadow="xl"
      overflowY="auto"
      transitionProperty="common"
      transitionDuration="normal"
      zIndex={'sticky'}
      bottom={{
        top: '0px',
        [OPEN_RIGHT_BREAKPOINT]: 'auto',
      }}
      top={{
        top: '0px',
        [OPEN_RIGHT_BREAKPOINT]: 'auto',
      }}
      width={{
        base: COMMENT_POPUP_WIDTH,
        [OPEN_RIGHT_BREAKPOINT]: COMMENT_POPUP_WIDTH,
      }}
      maxW={{
        base: '90vw',
        md: '80vw',
        [OPEN_RIGHT_BREAKPOINT]: COMMENT_POPUP_WIDTH,
      }}
      right={{
        // width of the ButtonStack + 10px
        base: '44px',
        [OPEN_RIGHT_BREAKPOINT]: 0,
      }}
      transform={{
        // for everything below 2xl translate the content below the content
        // since it appears to the left of the BlockCommentButtons
        base: `translate(0, ${offsetFromTop}px)`,
        [OPEN_RIGHT_BREAKPOINT]: `translate(calc(100% + 10px), 0)`,
      }}
      wordBreak="break-word"
      height="fit-content"
      maxHeight="calc(var(--100vh) - 8em)"
      position="absolute"
    >
      {children}
    </Flex>
  )
}
