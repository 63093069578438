import { ResolvedPos } from 'prosemirror-model'
import { Transaction } from 'prosemirror-state'

import { findSelectionNearOrGapCursor } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

/**
 * Deletes a layout cell (gridLayout or smartLayout) and sets
 * selection correctly based on the direction (forward or backward)
 */
export const deleteLayoutCell = (
  tr: Transaction,
  $cell: ResolvedPos,
  forward = false
): boolean => {
  const parentCell = $cell.nodeAfter
  if (!parentCell) {
    return false
  }

  tr.deleteRange($cell.pos, $cell.pos + parentCell.nodeSize)
  // see what happens after the cell is deleted, is there a cell to the right
  // that replaced the deleted one?
  const nextCell = tr.doc.nodeAt($cell.pos)

  let focusCellPos: number = $cell.pos
  let bias = 1
  // if goign backwards and there is a node before, select the end of the prev node
  if (
    // if goign backwards and there is a node before, select the end of the prev node
    (!forward && $cell.nodeBefore) ||
    // if going forward but at last cell
    (forward && !nextCell && $cell.nodeBefore)
  ) {
    focusCellPos = $cell.pos - $cell.nodeBefore.nodeSize
    bias = -1
  }

  const $inside = tr.doc.resolve(focusCellPos + 1)
  const sel = findSelectionNearOrGapCursor(
    tr.doc.resolve(bias > 0 ? $inside.pos : $inside.end()),
    bias
  )

  if (sel) {
    tr.setSelection(sel)
  }

  return true
}
