import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { mergeAttributes, Node } from '@tiptap/core'
import { NodeViewProps } from '@tiptap/react'

import { AnnotatableNodeViewWrapper } from 'modules/tiptap_editor/extensions/Annotatable'
import {
  NodeViewContent,
  ReactNodeViewRenderer,
} from 'modules/tiptap_editor/react'

import { attrsOrDecorationsChanged } from '../updateFns'

export const TitleView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps
  const { horizontalAlign } = node.attrs

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <NodeViewContent
        className="title"
        style={{ textAlign: horizontalAlign }}
        placeholder={`Untitled ${DOC_DISPLAY_NAME}`}
      />
    </AnnotatableNodeViewWrapper>
  )
}

export enum TitleLevel {
  DocTitle = 1,
}

export const Title = Node.create({
  name: 'title',
  content: 'inline*',
  group: 'block',
  defining: true,
  selectable: false,

  addAttributes() {
    return {
      level: {
        default: TitleLevel.DocTitle,
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(TitleView, {
      update: attrsOrDecorationsChanged,
    })
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=title]',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        class: 'title',
      }),
      0,
    ]
  },
})
