import { Editor } from '@tiptap/core'
import { PluginKey, Transaction } from 'prosemirror-state'

/**
 * Helper to programmatically activate a suggestion instance
 */
export const activateSuggestion =
  (editor: Editor, tr: Transaction) => (pluginKey: PluginKey, char: string) => {
    const state = pluginKey.getState(editor.view.state)
    const updatedState = { ...state }
    updatedState.active = true
    updatedState.key = char
    updatedState.query = ''
    tr.setMeta(pluginKey, updatedState)
  }
