import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'
import { AnimatePresence, motion } from 'framer-motion'
import isHotkey from 'is-hotkey'
import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { keyboardHandler } from 'modules/keyboard'
import { useLeftPanelWidth, useRightPanelWidth } from 'modules/panels/hooks'
import { useAppSelector } from 'modules/redux'
import { isThemeDark } from 'modules/theming'
import { ThemeFontLoader } from 'modules/theming/components/FontLoader'
import { NodeViewContent, NodeViewWrapper } from 'modules/tiptap_editor/react'
import { blurEditorAndChildren } from 'modules/tiptap_editor/utils/focus'
import { isMobileDevice, isMobileOrTabletDevice } from 'utils/deviceDetection'
import { preventDefaultToAllowDrop } from 'utils/handlers'

import {
  selectIsAnyCommentOpen,
  selectMode,
  selectNumberOfCards,
  selectPresentingCardId,
  selectTheme,
} from '../../reducer'
import {
  BackgroundType,
  getBackgroundProps,
  getDocOrThemeBackground,
} from '../../styles/backgroundStyles'
import { EditorModeEnum } from '../../types'
import { OPEN_RIGHT_BREAKPOINT } from '../Annotatable/components/BlockCommentsStack/popups/BaseCommentPopup'
import { COMMENT_POPUP_WIDTH } from '../Annotatable/constants'
import {
  BETWEEN_CARDS_FRAMER_TRANSITION,
  CARD_BODY_CLASS,
  CARD_CONTENT_CLASS,
  CARD_PRESENTING_CLASS,
  CARD_WRAPPER_CLASS,
} from '../Card/constants'
import { CustomerLogo } from './CustomerLogo'
import { DocumentAttributes } from './DocumentAttrs/attributes'
import { usePresentingCardBackground } from './usePresentingCardBackground'
import { DOC_Z_INDEXES } from './zIndexes'

const MotionBox = motion(Box)

/**
 * This hook helps us know when we've fully entered or fully left present mode
 * by keeping track of the most recent value in a ref. This is necessary because
 * 2 separate variables change between DOC<>SLIDE mode (the mode and the presentingCardId)
 *
 * isPresentModeReady tells us immediately when we have present mode and a presentingCardId
 * isPresentModeLagging tells us the same thing but doesnt change until both values have flipped
 *
 * Note that the mode always changes first, which allows us to derive the direction were going.
 * See similar logic for each card where we determine the present variant in usePresentVariant.ts
 *
 * |------ isPresentMode ------|------ presentingCardId ------|--------- status ---------|
 * |-------------------------------------------------------------------------------------|
 * |------     true      ------|------      string      ------|--------- PRESENT --------|  <-- isPresentModeReady
 * |------     true      ------|------      empty       ------|-- SWITCHING TO PRESENT --|  <-- isSwitchingToPresentMode
 * |------     false     ------|------      string      ------|--- SWITCHING TO DOC  ----|  <-- isSwitchingToDocMode
 * |------     false     ------|------      empty       ------|---------   DOC   --------|  <-- isDocModeReady
 * |-------------------------------------------------------------------------------------|
 */
const useEffectivePresentMode = (
  isPresentMode: boolean,
  presentingCardId: string | undefined | null
) => {
  const isPresentModeReady = Boolean(isPresentMode && presentingCardId)
  const isSwitchingToPresentMode = Boolean(isPresentMode && !presentingCardId)
  const isSwitchingToDocMode = Boolean(!isPresentMode && presentingCardId)
  const isDocModeReady = Boolean(!isPresentMode && !presentingCardId)
  const isPresentModeReadyPrev = useRef(isPresentModeReady)

  useEffect(() => {
    isPresentModeReadyPrev.current = isPresentModeReady
  }, [isPresentModeReady, isDocModeReady])

  const isPresentModeLagging =
    // If we are switching states, return the last known one
    isSwitchingToPresentMode || isSwitchingToDocMode
      ? isPresentModeReadyPrev.current
      : isPresentModeReady

  return [isPresentModeLagging, isPresentModeReady]
}

const NUM_CARDS_COMMENT_ANIMATION_THRESHOLD = 50
export const Doc = ({ editor, node, updateAttributes }: NodeViewProps) => {
  const mode = useSelector(selectMode)
  const isAnyCommentOpen = useSelector(selectIsAnyCommentOpen)
  const presentingCardId = useSelector(selectPresentingCardId)
  const numCards = useAppSelector(selectNumberOfCards)
  const theme = useAppSelector(selectTheme)
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW
  const presentingCardBackground = usePresentingCardBackground(editor)
  const { background: docBackground } = node.attrs as DocumentAttributes
  const leftPanelWidth = useLeftPanelWidth()
  const rightPanelWidth = useRightPanelWidth()

  // Corresponds with positioning defined here: https://github.com/gamma-app/gamma/blob/0a7e3fcadfd3ffaef710e4f3b6601662158babe7/packages/client/src/modules/tiptap_editor/extensions/Annotatable/components/CommentsList.tsx#L79-L94
  const isCommentsInMargin = useBreakpointValue({ xl: true })
  const paddingRight =
    isCommentsInMargin && isAnyCommentOpen
      ? COMMENT_POPUP_WIDTH
      : `${rightPanelWidth}px`

  const [isPresentModeReadyLagging, isPresentModeReady] =
    useEffectivePresentMode(isPresentMode, presentingCardId)

  // Keep the gammaDocId and document's node.attrs.docId in sync
  // this is necessary for understanding cut / copy provenance and whether
  // something in the paste buffer came from this document.
  // The current serializeForClipboard takes the document attributes and serializes
  // them in the `text/html` clipboard data
  useEffect(() => {
    if (!node.attrs.docId && editor.gammaDocId) {
      updateAttributes({
        ...node.attrs,
        docId: editor.gammaDocId,
      })
    }
  }, [node.attrs, editor, updateAttributes])

  // Theming and background
  const themeStyles = {
    [`.${CARD_WRAPPER_CLASS} .${CARD_CONTENT_CLASS}`]:
      theme.config.contentStyles,
  }
  const baseStyles = {
    // adjust the offset of left and right panels in present mode
    // this is due to Card's going position fixed in present mode
    [`.${CARD_PRESENTING_CLASS}`]: {
      paddingLeft: `${leftPanelWidth}px`,
      paddingRight,
      transitionProperty: 'padding-left, padding-right',
      transitionDuration: 'normal',
    },
  }
  const isDark = isThemeDark(theme)
  const docOrThemeBackground = getDocOrThemeBackground(theme, docBackground)
  const docModeBackgroundProps = getBackgroundProps(
    docOrThemeBackground,
    isDark
  )

  // In present mode, prefer the card background, if present
  const presentModeBackgroundProps = getBackgroundProps(
    isPresentMode &&
      presentingCardBackground &&
      presentingCardBackground.type !== BackgroundType.NONE
      ? presentingCardBackground
      : docOrThemeBackground,
    isDark
  )

  console.debug(
    `%c[DocComponent] Doc is rerendering`,
    'background-color: chartreuse',
    {
      isPresentModeReady,
      isPresentModeReadyLagging,
      presentingCardId,
    }
  )

  const commentSlideMargin = isAnyCommentOpen
    ? { base: '0px', [OPEN_RIGHT_BREAKPOINT]: `-${COMMENT_POPUP_WIDTH}` }
    : '0px'

  const computedDocStyles = {
    width: '100%',
    marginLeft: !isPresentMode ? commentSlideMargin : undefined,
    '--comment-slide-margin': commentSlideMargin,

    transitionProperty:
      numCards > NUM_CARDS_COMMENT_ANIMATION_THRESHOLD ? 'none' : 'margin-left',
    transitionDuration: 'normal',
    '[data-animate-value="doc"]': {
      // Use lagging present mode to change display only once the state change has settled
      display: isPresentModeReadyLagging ? 'none' : undefined,
    },
  }

  // Blur the editor when clicking outside or hitting Esc
  const blurOnOutsideClick = useCallback(
    (ev: React.MouseEvent) => {
      const target = ev.target as HTMLElement
      if (
        target.closest(`.${CARD_BODY_CLASS}`) &&
        !target.getAttribute('data-outside-card-body')
      ) {
        return false
      }
      blurEditorAndChildren(editor)
      return true
    },
    [editor]
  )
  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (isHotkey('Esc')(e) && editor.isFocused) {
        editor.commands.blur()
        e.preventDefault()
        return true
      }
      return false
    }
    return keyboardHandler.on('keydown', 'DOC_BLUR', keydownListener)
  }, [editor])

  return (
    <NodeViewWrapper as="div">
      <ThemeFontLoader theme={theme} />
      <Box
        sx={baseStyles}
        // Blur the editor if you click outside a card
        onMouseDown={blurOnOutsideClick}
      >
        <Flex
          className="doc-content-wrapper"
          direction="column"
          align="center"
          pb={
            !isPresentMode && !isMobileDevice
              ? isAnyCommentOpen
                ? '80vh'
                : '30vh'
              : '0px'
          }
          minH="var(--100vh)"
          sx={themeStyles}
          onDragOver={preventDefaultToAllowDrop} // Allows drops on the edges of the doc - https://stackoverflow.com/a/21341021
        >
          {/* This stays visible even in present mode because it's underneath, and the present bg will fade in over it */}
          <DocBackground {...docModeBackgroundProps} />
          <AnimatePresence>
            <MotionBox
              key={'presenting-background-' + presentingCardId}
              className="motion-present-mode-bg"
              data-doc-background-element-present-mode
              position="fixed"
              zIndex={DOC_Z_INDEXES.presentBg} // Go over "doc" cards that aren't rendered in present mode
              left={0}
              right={0}
              h="100%"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 1,
              }}
              // Only show the background when were fully in present mode.
              // This means for doc mode OR while switching, it is hidden
              visibility={isPresentModeReady ? 'visible' : 'hidden'}
              transition={BETWEEN_CARDS_FRAMER_TRANSITION}
              {...presentModeBackgroundProps}
            />
          </AnimatePresence>
          <Flex
            sx={computedDocStyles}
            justify="center"
            className={`document-content ${
              isPresentMode ? 'is-present-mode' : 'is-doc-mode'
            }`}
          >
            <NodeViewContent />
          </Flex>
        </Flex>
      </Box>
      {isMobileDevice && (
        <Flex w="100%" justify="center" align="center" mt={8} mb={10}>
          <CustomerLogo />
        </Flex>
      )}
    </NodeViewWrapper>
  )
}

export const DocBackground = (props) => {
  const mobileProps = isMobileOrTabletDevice
    ? {
        style: {
          // Prevent swiping the background up/down
          touchAction: 'none',
        },
        /**
         * Use a fixed position background for mobile devices because they dont support background-attachment: fixed
         * See https://css-tricks.com/the-fixed-background-attachment-hack/
         * Note: For now, were only using this approach on mobile because it covers the scrollbar,
         * and breaks editors in modals/drawers (eg snapshot viewer).
         */
        position: 'fixed',
        top: 0,
        left: 0,
      }
    : {}

  return (
    <Flex
      data-doc-background-element
      data-testid="doc-background"
      position="absolute"
      w="var(--editor-width)"
      h="100%"
      minH="var(--100vh)"
      contentEditable={false}
      {...props}
      {...mobileProps}
    >
      {!isMobileDevice && <CustomerLogo />}
    </Flex>
  )
}
