export const USER_SETTINGS_CONSTANTS = {
  backgroundedSince: 'backgroundedSince',
  hasClosedExamplesOnRecentTab: 'hasClosedExamplesOnRecentTab',
  hasSeenCoachBar: 'hasSeenCoachBar_v3',
  hasDismissedSearchTip: 'hasDismissedSearchTip',
  sessionId: 'gammaSessionId',
  lastDismissedPreferredBrowserMessage: 'lastDismissedPreferredBrowserMessage',
  viewPreferences: 'viewPreferences',
  lastHomeUrl: 'lastHomeUrl',
  hasDismissedDocWorkspaceNotice: 'hasDismissedDocWorkspaceNotice',
  welcomeDocId: 'welcomeDocId',
} as const
