// Note: default priority is 100
export const ExtensionPriorityMap = {
  UniqueAttribute: 10000, // We’ll set a very high priority to make sure this runs first and is compatible with `appendTransaction` hooks of other extensions

  // Mention priority is higher than the Link extension so that mention links pasted from outside the doc are not automatically converted to links.
  CardMention: 1001,
  DocMention: 1001,
  UserMention: 1001,

  UndoInputRuleKeymap: 1001, // UndoInput must be higher than Slashmenu and Collaboration (which handles undo/redo via the y-prosemirror UndoManager)
  HorizontalAlign: 1001, // Backspace check should run before other commands. todo: move this to KeyMapOverride for clarity
  Collaboration: 1000,

  // Marks with backgrounds should go ahead of others so they don't get split up
  // https://discuss.prosemirror.net/t/marks-priority/4463/2
  Highlight: 150,
  TextColor: 150, // Should go ahead of links so link color overrides text color
  Code: 150,
  Link: 140, // I don't know why but Tiptap was originally setting this to 1000, so I'm keeping it above other marks

  // Menus all need to be ahead of List so you can hit Enter inside when in a list
  SlashMenu: 104,
  EmojiShortcuts: 104,
  MentionSuggestionMenu: 104,

  List: 103, // Priority needs to be ahead of table and layout so you can indent bullets inside
  Table: 102, // Tab should run ahead of layout so you can tab inside
  Image: 102, // Must go above media placeholder
  Gallery: 102, // Should go ahead of layout so its drop handler takes precedence
  MediaPlaceholder: 101, // Needs to go ahead of KeyMapOverride so we can catch Enter key
  Layout: 101,
  Footnote: 101, // Needs to go ahead of KeyMapOverride so we can catch Enter key
  Card: 101, // Ahead of global drag handle (default) so its handleDrop doesn't affect scrolling calculation of CardPlugin's handleDrop in checkBetweenCardsDropTarget
  ExpandableToggle: 101, // Go ahead of KeyMapOverride
  KeyMapOverride: 100, // Override should go before other key handlers

  // 100 is the default, so anything below this line is behind most extensions

  CardIds: 1, // Allow all other transactions to happen first
  KeyBoardCatchall: 1, // Catchall should go last, so make it's priority number lowest
}
