import { isEqual } from 'lodash'
import { useEffect } from 'react'

import { Card } from 'modules/api'
import { useAppDispatch, useAppSelector } from 'modules/redux'

import { reset, selectCard, setCards } from './reducer'

export const useGetCard = (cardId?: string) => {
  return useAppSelector(selectCard(cardId), (...args) => isEqual(...args))
}

export const useSyncCardsRedux = (cards?: Card[]) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!cards) return
    dispatch(setCards({ cards }))
  }, [dispatch, cards])

  // Cleanup when we unmount
  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [])
}
