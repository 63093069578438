import { EditorState, Transaction, PluginKey } from 'prosemirror-state'

export const MobileAnnotationPluginKey = new PluginKey<MobileAnnotationState>(
  'mobileAnnotationPluginKey'
)

type ActivatorPos = {
  pos: number
  end: number
  offsetX: number
  offsetY: number
}
export type SetMobileAnnotationBlockCommentEvent = {
  setPos: ActivatorPos | null
}

type MobileAnnotationEvent = SetMobileAnnotationBlockCommentEvent

export class MobileAnnotationState {
  public pos: ActivatorPos | null = null

  public timeout: ReturnType<typeof setTimeout> | null

  constructor() {}

  apply(tr: Transaction, _state: EditorState): this {
    const action = tr.getMeta(
      MobileAnnotationPluginKey
    ) as MobileAnnotationEvent | null

    if (action && 'setPos' in action) {
      this.pos = action.setPos
    }

    return this
  }
}
