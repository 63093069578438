/**
 * A genericized fork of the TipTap Pro extension UniqueId
 * It uses the same logic but supports any attribute and
 * enables a few more customizations
 * See @tiptap/extension-unique-id
 */
import { UniqueAttribute } from './uniqueAttribute'

export * from './uniqueAttribute'

export default UniqueAttribute
