import { Editor } from '@tiptap/core'
import DropTarget from '@uppy/drop-target'
import { DocumentData } from 'firebase/firestore'
import { useEffect, useMemo, useRef, useState } from 'react'

import { UppyUploader, uploadFile } from 'modules/media/apis/transloadit'
import { useAppSelector } from 'modules/redux'
import { selectDoc, selectCardIdMap } from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'

import { getAssets, getCards, getNote } from './api'

export const useVaultData = (editor: Editor, searchTerm?: string) => {
  const doc = useAppSelector(selectDoc)
  const { user } = useUserContext()
  const [items, setItems] = useState<DocumentData[]>([])
  const [cards, setCards] = useState<DocumentData[]>([])
  const [note, setNote] = useState<DocumentData | null>(null)
  const [currentDocIds, setCurrentDocIds] = useState<string[]>([])
  const allCardIds = useAppSelector((s) =>
    Object.keys(selectCardIdMap(s).parents)
  )

  const docId = doc?.id
  const userId = user?.id

  const filteredItems = useMemo(() => {
    return {
      keywords: Object.entries(
        items.reduce<Record<string, number>>((acc, item) => {
          if (item.keywords) {
            item.keywords.forEach((kw: string) => {
              acc[kw] = acc[kw] || 0
              acc[kw]++
            })
          }
          return acc
        }, {})
      )
        // .filter(([kw]) => {
        //   if (!searchTerm) return true
        //   return kw.startsWith(searchTerm)
        // })
        .sort((a, b) => {
          return b[1] - a[1]
        }),
      cards: cards
        .filter((c) => !allCardIds.includes(c.id))
        .filter((c) => {
          if (!searchTerm) return true
          return c.cardTitle ? c.cardTitle.includes(searchTerm) : true
        }),
      links: items
        .filter((i) => i.vaultType === 'link')
        .map((item) => {
          item.inDoc = currentDocIds.includes(item.id)
          return item
        }),
      files: items
        .filter((i) => i.vaultType !== 'link')
        .filter((i) => {
          if (!searchTerm) return true
          return (
            i.keywords.some((kw: string) => kw.includes(searchTerm)) ||
            i.text.includes(searchTerm)
          )
        })
        .map((item) => {
          item.inDoc = currentDocIds.includes(item.id)
          return item
        })
        .sort((a, b) => {
          if (a.inDoc && b.inDoc) return 0
          if (a.inDoc) return -1
          return 1
        }),
    }
  }, [searchTerm, items, cards, allCardIds, currentDocIds])

  useEffect(() => {
    const cb = ({ transaction }) => {
      if (!transaction.docChanged) return
      const ids: string[] = []
      const urls: string[] = []
      editor.state.doc.descendants((node) => {
        const markUrls = node.marks
          .filter((m) => m.type.name === 'link')
          .map((m) => m.attrs.href)

        urls.push(...markUrls)
        if (node.type.name === 'image') {
          urls.push(node.attrs.src)
        } else if (node.type.name === 'embed') {
          urls.push(node.attrs.url)
          urls.push(node.attrs.sourceUrl)
        }
      })
      items.forEach((item) => {
        if (urls.includes(item.url) || urls.includes(item.meta?.sourceUrl)) {
          ids.push(item.id)
        }
      })
      setCurrentDocIds(ids)
    }
    editor.on('update', cb)
    cb({ transaction: { docChanged: true } })

    return () => {
      editor.off('update', cb)
    }
  }, [editor, items])

  useEffect(() => {
    if (!docId || !userId) return

    getNote({ docId, userId }).then((_note) => {
      setNote(_note || {})
    })

    const unsubA = getAssets(
      docId,
      userId
    )((_items) => {
      console.log('[useVaultData] Items for user: ', _items)
      setItems(_items)
    })
    const unsubC = getCards(
      docId,
      userId
    )((_cards) => {
      console.log('[useVaultData] Cards for user: ', _cards)
      setCards(_cards)
    })

    return () => {
      unsubA()
      unsubC()
    }
  }, [docId, userId])

  return {
    loading: false,
    items: filteredItems,
    note,
  }
}

export const useVaultFileUpload = ({
  dropRef,
  fileInputRef,
  workspaceId,
}: {
  dropRef: React.RefObject<HTMLDivElement>
  fileInputRef: React.RefObject<HTMLInputElement>
  workspaceId?: string
}) => {
  const [isDragHovered, setDragHovered] = useState(false)
  const [uploadInProgress, setUploadInProgress] = useState(false)

  useEffect(() => {
    if (!workspaceId) return
    const uppyOptions = {
      onUploadStart: () => {
        setUploadInProgress(true)
      },
      onUploadFailed: () => {
        setUploadInProgress(false)
        fileInput.value = ''
      },
      onUploadComplete: () => {
        // Removes internal list of files, so you can reupload the same thing again
        instance.reset()
        setUploadInProgress(false)
        fileInput.value = ''
      },
    }
    const instance = UppyUploader.createUppyInstance(
      workspaceId,
      uppyOptions,
      'node',
      'all'
    )
    const options = {
      id: 'react:DropTarget',
      target: dropRef.current!,
      onDragOver: (event) => {
        setDragHovered(true)
      },
      onDrop: (event) => {
        setDragHovered(false)
      },
      onDragLeave: (event) => {
        setDragHovered(false)
      },
    }
    // dropRef.current?.addEventListener('drop', (event) => {
    //   console.log('ONDROP', event.target.files)
    // })
    instance.use(DropTarget, options)
    const fileInput = fileInputRef.current!
    const changeCb = (event) => {
      const target = event.target as HTMLInputElement
      if (!target?.files) return
      const files = Array.from(target.files)

      setUploadInProgress(true)
      Promise.all(
        files.map((file) => {
          return new Promise((r) => {
            uploadFile(file, workspaceId, {
              onUploadFailed: r,
              onUploadComplete: r,
            })
          })
        })
      ).finally(() => {
        setUploadInProgress(false)
      })
    }
    fileInput.addEventListener('change', changeCb)
    instance.on('file-removed', () => {
      fileInput.value = ''
    })

    instance.on('complete', () => {
      fileInput.value = ''
    })

    return () => {
      fileInput.removeEventListener('change', changeCb)
      instance.cancelAll()
      instance.close()
    }
  }, [workspaceId, dropRef, fileInputRef])

  return {
    isDragHovered,
    uploadInProgress,
  }
}
