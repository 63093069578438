import isNil from 'lodash/isNil'
import throttle from 'lodash/throttle'
import UAParserJS from 'ua-parser-js'

import { isUAWebview } from './isUAWebview'

declare global {
  interface Navigator {
    brave?: { isBrave: () => Promise<boolean> }
  }
}

export const UAParser = new UAParserJS()

export const device = UAParser.getDevice()

export const os = UAParser.getOS()

export const isChrome = UAParser.getBrowser().name?.startsWith('Chrome')

export const isHeadlessChrome =
  UAParser.getBrowser().name?.startsWith('Chrome Headless')

export const isSafari = UAParser.getEngine().name === 'WebKit'

// Opera mobile may not have a 'mobile' type
const isOperaMobile =
  UAParser.getBrowser().name === 'Opera Mobi' &&
  UAParser.getOS().name === 'Android'

export const isMobileDevice =
  UAParser.getDevice().type === 'mobile' || isOperaMobile

const isiPad = () => {
  if (typeof navigator === 'undefined') return false

  // iPad on iOS 13 detection
  return navigator.userAgent.includes('Mac') && 'ontouchend' in document
}

// iPad user agents don't display the device as "tablet" --
// when inspecting UA strings, they look identical to desktop devices.
// You need to check some properties on `navigator` to make sure it's really a tablet.
export const isMobileOrTabletDevice =
  ['mobile', 'tablet'].includes(UAParser.getDevice().type || '') ||
  isOperaMobile ||
  isiPad()

// forklifted from @tiptap/core since its not exported
// https://github.com/ueberdosis/tiptap/blob/main/packages/core/src/utilities/isiOS.ts
export const isiOS = (): boolean => {
  if (typeof navigator === 'undefined') return false

  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) || isiPad()
  )
}

export const isDesktopDevice = isNil(UAParser.getDevice().type) && !isiOS()

export const handleAppHeight = () => {
  // Handle 100vh issue on mobile devices:
  // https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
  const setAppHeight = throttle((val?: string) => {
    const doc = document.documentElement
    const height = val || `${window.innerHeight}px`
    doc.style.setProperty('--100vh', height)
  }, 500)

  if (isMobileOrTabletDevice) {
    setAppHeight()
    window.addEventListener('resize', () => setAppHeight())
  } else {
    // For non mobile, just use the standard 100vh
    setAppHeight('100vh')
  }
}

export const isWebview = () => {
  if (typeof navigator === 'undefined') return false

  return isUAWebview(navigator.userAgent)
}

export const isBrave = () => {
  if (typeof navigator === 'undefined') {
    return undefined
  }
  // https://github.com/brave/brave-browser/issues/10165#issuecomment-644949774
  return typeof navigator.brave?.isBrave === 'function'
}

export const isGammaUserAgent = () => {
  return navigator.userAgent.startsWith('gamma/')
}
