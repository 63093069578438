import {
  Editor,
  Extension,
  findChildren,
  findChildrenInRange,
} from '@tiptap/core'
import { Slice, Fragment, Node as ProseMirrorNode } from 'prosemirror-model'
import { Plugin, PluginKey, Transaction } from 'prosemirror-state'

import { ExtensionPriorityMap } from '../../extensions/constants'
import combineTransactionSteps from './helpers/combineTransactionSteps'
import findDuplicates from './helpers/findDuplicates'
import getChangedRanges from './helpers/getChangedRanges'

export const UniqueAttributePluginKey = new PluginKey('UniqueAttribute')

export interface UniqueAttributeResult {
  val: any
  node: ProseMirrorNode
  pos: number
}

export interface UniqueAttributeOptions {
  attributeName: string
  types: string[]
  initialValue: () => any
  pluginKey?: PluginKey
  callback?: (
    editor: Editor,
    results: UniqueAttributeResult[],
    doc: ProseMirrorNode
  ) => any
  renderHTML?:
    | ((attributes: Record<string, any>) => Record<string, any> | null)
    | null
  filterTransaction: ((transaction: Transaction) => boolean) | null
  /**
   * Can be used to override the default transformPasted behavior
   */
  transformPasted?: (
    slice: Slice,
    options: UniqueAttributeOptions,
    docId?: string
  ) => Slice
}

export const UniqueAttribute = Extension.create<UniqueAttributeOptions>({
  name: 'UniqueAttribute',
  priority: ExtensionPriorityMap.UniqueAttribute,

  addOptions() {
    return {
      attributeName: '',
      pluginKey: new PluginKey(this.name),
      types: [],
      initialValue: () => undefined,
      filterTransaction: null,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          [this.options.attributeName]: {
            default: null,
            parseHTML: (element) =>
              element.getAttribute(`data-${this.options.attributeName}`),
            renderHTML: (attributes) => {
              if (this.options.renderHTML) {
                return this.options.renderHTML(attributes)
              }

              if (!attributes[this.options.attributeName]) {
                return {}
              }

              return {
                [`data-${this.options.attributeName}`]:
                  attributes[this.options.attributeName],
              }
            },
          },
        },
      },
    ]
  },

  // check initial content for missing ids
  onCreate() {
    const { view, state } = this.editor
    const { tr, doc } = state
    const { types, attributeName, initialValue, callback } = this.options
    const nodesWithoutId = findChildren(doc, (node) => {
      return (
        types.includes(node.type.name) && node.attrs[attributeName] === null
      )
    })

    const results: UniqueAttributeResult[] = nodesWithoutId.map(
      ({ node, pos }) => {
        const val = initialValue()
        tr.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          [attributeName]: val,
        })
        return { node, val, pos }
      }
    )

    view.dispatch(tr)

    if (callback) {
      callback(this.editor, results, tr.doc)
    }
  },

  addProseMirrorPlugins() {
    let transformPasted = false

    return [
      new Plugin({
        key: this.options.pluginKey,

        appendTransaction: (transactions, oldState, newState) => {
          const docChanges =
            transactions.some((transaction) => transaction.docChanged) &&
            !oldState.doc.eq(newState.doc)
          const filterTransactions =
            this.options.filterTransaction &&
            transactions.some((tr) => !this.options.filterTransaction?.(tr))

          if (!docChanges || filterTransactions) {
            return
          }

          const { tr } = newState
          const { types, attributeName, initialValue, callback } = this.options
          const transform = combineTransactionSteps(oldState.doc, transactions)
          const { mapping } = transform

          // get changed ranges based on the old state
          const changes = getChangedRanges(transform)

          const results: UniqueAttributeResult[] = []

          // Allow transaction invokers to flag that this
          // extension should run for their new nodes
          // NOTE: This uses a shared singleton plugin key,
          // not this.options.pluginKey
          const trOverride = transactions.some(
            (t) => t.getMeta(UniqueAttributePluginKey) === true
          )

          changes.forEach((change) => {
            const newRange = {
              from: change.newStart,
              to: change.newEnd,
            }

            const newNodes = findChildrenInRange(
              newState.doc,
              newRange,
              (node) => {
                return types.includes(node.type.name)
              }
            )

            const newVals = newNodes
              .map(({ node }) => node.attrs[attributeName])
              .filter((val) => val !== null)

            const duplicatedNewVals = findDuplicates(newVals)

            newNodes.forEach(({ node, pos }) => {
              // instead of checking `node.attrs[attributeName]` directly
              // we look at the current state of the node within `tr.doc`.
              // this helps to prevent adding new ids to the same node
              // if the node changed multiple times within one transaction
              const existingVal = tr.doc.nodeAt(pos)?.attrs[attributeName]

              if (!existingVal) {
                const val = initialValue()
                tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  [attributeName]: val,
                })

                results.push({ node, val, pos })

                return
              }

              // check if the node doesn’t exist in the old state
              const { deleted } = mapping.invert().mapResult(pos)
              const newNode = deleted && duplicatedNewVals.includes(existingVal)
              const override = deleted && trOverride

              if (newNode || override) {
                const val = initialValue()
                tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  [attributeName]: val,
                })
                results.push({ node, val, pos })
                console.debug(
                  `%c [UniqueAttribute][${this.options.types}] Replaced ${node.attrs[attributeName]} with new val: ${val}`,
                  'background-color: deeppink',
                  { override, newNode }
                )
              }
            })
          })

          if (callback) {
            callback(this.editor, results, tr.doc)
          }

          if (!tr.steps.length) {
            return
          }

          return tr
        },

        props: {
          // `handleDOMEvents` is called before `transformPasted`
          // so we can do some checks before
          handleDOMEvents: {
            // only create new ids for dropped content while holding `alt`
            // or content is dragged from another editor
            drop: (view, event) => {
              if (
                // The GlobalDragHandle sets view.dragging.move to true, and ids
                // dont need to be stripped when a node is merely moved (not copied)
                view.dragging?.move !== true ||
                event.dataTransfer?.effectAllowed === 'copy'
              ) {
                transformPasted = true
              }

              return false
            },
            // always create new ids on pasted content
            paste: () => {
              transformPasted = true

              return false
            },
          },

          // we’ll remove ids for every pasted node
          // so we can create a new one within `appendTransaction`
          transformPasted: (slice) => {
            if (!transformPasted) {
              return slice
            }

            const { types, attributeName } = this.options
            const removeVal = (fragment: Fragment): Fragment => {
              const list: ProseMirrorNode[] = []

              fragment.forEach((node) => {
                // don’t touch text nodes
                if (node.isText) {
                  list.push(node)

                  return
                }

                // check for any other child nodes
                if (!types.includes(node.type.name)) {
                  list.push(node.copy(removeVal(node.content)))

                  return
                }

                // remove id
                const nodeWithoutVal = node.type.create(
                  {
                    ...node.attrs,
                    [attributeName]: null,
                  },
                  removeVal(node.content),
                  node.marks
                )
                list.push(nodeWithoutVal)
              })

              return Fragment.from(list)
            }

            const transformedSlice: Slice = this.options.transformPasted
              ? // If transformPasted is passed by the plugin, use that to transform the slice
                this.options.transformPasted(
                  slice,
                  this.options,
                  this?.editor?.gammaDocId
                )
              : // Otherwise, fallback to using the default removeVal function:
                new Slice(
                  removeVal(slice.content),
                  slice.openStart,
                  slice.openEnd
                )

            // reset check
            transformPasted = false

            return transformedSlice
          },
        },
      }),
    ]
  },
})
