import { Extension } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'

import { TOOLBAR_HEIGHT } from './Card'

// Configures the prosemirror settings for scrolling elements into
// view below our toolbar and above our card footer
// scrollThreshold: https://prosemirror.net/docs/ref/#view.EditorProps.scrollThreshold
// scrollMargin: https://prosemirror.net/docs/ref/#view.EditorProps.scrollMargin
export const ScrollMargin = Extension.create({
  name: 'scrollMargin',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          scrollMargin: TOOLBAR_HEIGHT,
          scrollThreshold: TOOLBAR_HEIGHT,
        },
      }),
    ]
  },
})

export default ScrollMargin
