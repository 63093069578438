import {
  Button,
  Heading,
  HStack,
  Image,
  Input,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react'

import {
  ImageUploadResult,
  MediaEmbedProps,
  OnUploadStartParams,
  UploadBox,
  UploadStatus,
} from 'modules/media'
import { MediaPlaceholderImage } from 'modules/tiptap_editor/extensions/media/Placeholder/MediaPlaceholderImage'
import { MediaEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { isHEICFileType } from 'utils/image'

import { useAppSelector } from '../../redux'
import { selectDocOrgId } from '../../tiptap_editor/reducer'
import { fetchEmbedAttrsForUrl } from '../components/URLFetcher'

export const EmbedMetadata = ({
  updateAttributes,
  currentAttributes,
}: MediaEmbedProps<MediaEmbedAttrs>) => {
  const { sourceUrl, meta, thumbnail } = currentAttributes
  const orgId = useAppSelector(selectDocOrgId)
  const toast = useToast()

  const handleRestoreDefaultsClick = async () => {
    if (sourceUrl) {
      await fetchEmbedAttrsForUrl(sourceUrl)
        .then(updateAttributes)
        .catch((err) => {
          console.error(
            '[EMBED METADATA] Error restoring preview defaults',
            err
          )
          toast({
            title: `Error restoring preview defaults: ${err.error}`,
            status: 'error',
            isClosable: true,
            position: 'top',
          })
        })
    }
  }

  const setUploadStart = ({ file, tempUrl }: OnUploadStartParams) => {
    if (file.type && isHEICFileType(file.type)) {
      updateAttributes({
        thumbnail: {
          uploadStatus: UploadStatus.Uploading,
          showPlaceholder: true,
          tempUrl: null,
        },
      })
    } else {
      updateAttributes({
        thumbnail: {
          tempUrl,
          uploadStatus: UploadStatus.Uploading,
          showPlaceholder: false,
        },
      })
    }
  }

  const setUploadedThumbnail = ({ src }: ImageUploadResult) => {
    updateAttributes({
      thumbnail: {
        src,
      },
    })
  }

  const setUploadFailed = (errMessage: string) => {
    console.error(
      '[EMBED METADATA] Error uploading thumbnail image',
      errMessage
    )
    updateAttributes({
      thumbnail: {
        uploadStatus: UploadStatus.Error,
        showPlaceholder: true,
        tempUrl: null,
      },
    })
  }

  return (
    <VStack pt={4} align="stretch" spacing={4}>
      <VStack align="stretch">
        <Heading size="md">Edit preview</Heading>
        <Text fontSize="sm" color="gray.600">
          This information will show when the display is set to Preview, or on
          hover when it's set to Link.
        </Text>
      </VStack>
      <VStack
        align="stretch"
        p={4}
        borderRadius="lg"
        border="1px solid var(--chakra-colors-blue-500)"
        bg="gray.100"
        spacing={4}
      >
        {thumbnail?.showPlaceholder ? (
          <MediaPlaceholderImage
            hasUploadError={thumbnail?.uploadStatus === UploadStatus.Error}
            isLoading={thumbnail?.uploadStatus === UploadStatus.Uploading}
          />
        ) : thumbnail ? (
          <>
            <Image
              src={thumbnail.src || thumbnail.tempUrl || ''}
              alt={meta?.title || ''}
              ignoreFallback
              borderRadius="md"
            />
            <Button
              disabled={thumbnail.uploadStatus === UploadStatus.Uploading}
              onClick={() => {
                updateAttributes({
                  thumbnail: undefined,
                })
              }}
            >
              Replace thumbnail
            </Button>
          </>
        ) : (
          orgId && (
            <UploadBox
              onUploadStart={setUploadStart}
              onUploadSuccess={setUploadedThumbnail}
              onUploadFailed={setUploadFailed}
              orgId={orgId || ''}
              uploadType="image"
              editType={'icon'}
              showTip={false}
              afterLinkDropHereText="a thumbnail for this embed"
              customHeight="200px"
              customFontSize="sm"
            />
          )
        )}

        <VStack mt={2} align="stretch">
          <Text>Title</Text>
          <Input
            bg="white"
            value={meta?.title || ''}
            placeholder="Title not provided"
            onChange={(e) => {
              updateAttributes({
                meta: {
                  ...meta,
                  title: e.target.value,
                },
              })
            }}
            data-testid="title-input"
          />
        </VStack>
        <VStack align="stretch">
          <Text>Description</Text>
          <Textarea
            bg="white"
            rows={4}
            value={meta?.description || ''}
            placeholder="Description not provided"
            onChange={(e) => {
              updateAttributes({
                meta: {
                  ...meta,
                  description: e.target.value,
                },
              })
            }}
          />
        </VStack>
        <VStack align="stretch">
          <Text>Website</Text>
          <HStack>
            {meta?.icon && (
              <Image src={meta.icon} h="1.5em" borderRadius="sm" />
            )}
            <Input
              bg="white"
              value={meta?.site || ''}
              placeholder="Website name not provided"
              onChange={(e) => {
                updateAttributes({
                  meta: {
                    ...currentAttributes.meta,
                    site: e.target.value,
                  },
                })
              }}
            />
          </HStack>
        </VStack>
      </VStack>
      <Button onClick={handleRestoreDefaultsClick}>
        Restore preview defaults
      </Button>
    </VStack>
  )
}
