import { ExtendedRegExpMatchArray } from '@tiptap/core'
import { NodeType } from 'prosemirror-model'

import { nodePasteRule } from 'modules/tiptap_editor/utils/nodePasteRule'
import { DOC_NANOID_REGEX, getPathnameParts, parseUrlHash } from 'utils/url'

const getDocIdFromUrl = (url: string) => {
  const [_prefix, lastPart] = getPathnameParts(url)
  const urlNanoid = lastPart.match(DOC_NANOID_REGEX)
  return urlNanoid ? urlNanoid[0] : null
}

// Check this regex here: https://regex101.com/r/rA1Vqh/2
export const gammaDocRegex =
  /https:\/\/((staging\.)?gamma\.app|gamma-[^-]+-gamma(-app)?\.vercel\.app|localhost:3000|local\.gamma\.app:3000)\/docs\/[-\w\d]{15,}\/?(?:\?[^#\r\n\s]*)?(?:#[-\w\d]*)?/gm

type MatchResult = {
  index: number
  text: string
  match: RegExpMatchArray
}

type GetAttributesArgs = {
  match: ExtendedRegExpMatchArray
  docId?: string | null
  cardId?: string | null
}

type MentionPasteRuleArgs = {
  filterFn: (
    docId: string | null,
    cardId: string | null,
    r: MatchResult
  ) => boolean
  getAttributesFn: (args: GetAttributesArgs) => Record<string, any>
}

// Paste rule for capturing links to Gamma docs (see regex above)
export const getMentionPasteRule =
  ({ filterFn, getAttributesFn }: MentionPasteRuleArgs) =>
  (type: NodeType) =>
    nodePasteRule({
      find: (text: string) => {
        const matches = [...text.matchAll(gammaDocRegex)]

        if (!matches) {
          return []
        }

        const result = matches
          .map((regexpMatch) => {
            return {
              index: regexpMatch.index ?? -1,
              text: regexpMatch[0],
              match: regexpMatch,
            }
          })
          .filter((r) => {
            const resultDocId = getDocIdFromUrl(r.text)
            const { cardId } = parseUrlHash(r.text)
            return filterFn(resultDocId, cardId, r)
          })
        return result
      },
      type,
      getAttributes: (match) => {
        const id = getDocIdFromUrl(match[0])
        const { cardId } = parseUrlHash(match[0])
        return getAttributesFn({ match, docId: id, cardId })
      },
    })
