import { mergeAttributes, Node, NodeViewProps } from '@tiptap/core'

import { ReactNodeViewRenderer } from '../../react'
import { attrsOrDecorationsChanged } from '../updateFns'
import { listFilteredInputRule } from './List'
import { ListVariant } from './ListTypes'
import { NumberedView } from './NumberedView'

const numberRegex = /^(\d+)\.\s$/

export const Numbered = Node.create({
  name: ListVariant.Numbered,
  content: 'inline*',
  defining: true,
  group: 'block',
  selectable: false,
  allowFontSizes: 'body',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(NumberedView, {
      as: 'ol',
      update: attrsOrDecorationsChanged,
      updateWrapperEl: (el: HTMLElement, props: NodeViewProps) => {
        el.setAttribute('indent', props.node.attrs.indent)
      },
    })
  },

  addAttributes() {
    return {
      indent: {
        default: 0,
      },
      fontSize: {
        default: null,
      },
    }
  },

  addInputRules() {
    return [
      listFilteredInputRule(
        { find: numberRegex, type: this.type },
        this.editor.state.schema
      ),
    ]
  },

  addKeyboardShortcuts() {
    return {
      'Mod-/': ({ editor }) =>
        editor.commands.setListItems({ variant: ListVariant.Numbered }),
      'Mod-Shift-7': ({ editor }) =>
        editor.commands.setListItems({ variant: ListVariant.Numbered }),
    }
  },

  parseHTML() {
    return [{ tag: `li[variant="${this.name}"]` }]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'li',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        variant: this.name,
      }),
      0,
    ]
  },
})
