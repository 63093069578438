import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link, Alert, AlertDescription, AlertIcon } from '@chakra-ui/react'

type EmbedTipProps = {
  guideLink: string
  extraMessage?: string
  providerName: string
}

export const EmbedTip = ({
  guideLink,
  extraMessage,
  providerName,
}: EmbedTipProps) => {
  return (
    <Alert status="info">
      <AlertIcon />
      <AlertDescription>
        Follow{' '}
        <Link href={guideLink} textDecoration="underline" isExternal>
          this guide
          <ExternalLinkIcon mx="2px" />
        </Link>{' '}
        to find the URL or embed code for {providerName}. {extraMessage}
      </AlertDescription>
    </Alert>
  )
}
