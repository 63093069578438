import { Text } from '@chakra-ui/react'
import { mergeAttributes, Mark } from '@tiptap/core'

import { NodeViewWrapper, NodeViewContent } from 'modules/tiptap_editor/react'

import { addMarkViewPlugin, MarkViewProps } from '../../react/addMarkViewPlugin'
import { useToggleFootnoteOnHover } from './hooks'

export const FootnoteLabel = Mark.create({
  name: 'footnoteLabel',
  inclusive: false,
  excludes: 'link underline',

  parseHTML() {
    return [
      {
        tag: 'span[class=footnote-label]',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        class: 'footnote-label',
      }),
      0,
    ]
  },

  addAttributes() {
    return {
      noteId: {
        // Prevents noteId from coming with copy/paste operations. We'll generate a new one on paste using `fixOrphanedMarks`
        rendered: false,
      },
    }
  },

  addProseMirrorPlugins() {
    return [addMarkViewPlugin(this, FootnoteLabelView)]
  },
})

const FootnoteLabelView = ({ node, editor }: MarkViewProps) => {
  const { noteId } = node.attrs

  // On hover, mark the note as expanded
  const toggleHandlers = useToggleFootnoteOnHover(editor, noteId)

  return (
    <NodeViewWrapper as="span" className="footnote-label-view">
      <Text as="span" className="footnote-label" {...toggleHandlers}>
        <NodeViewContent as="span" />
      </Text>
    </NodeViewWrapper>
  )
}
