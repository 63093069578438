import { Theme } from 'modules/theming'

import { BackgroundType } from '../backgroundStyles'
import { ContainerEffect } from '../containerStyles'

export const DefaultLightTheme: Theme = {
  id: 'default-light',
  archived: false,
  createdTime: '2022-01-01T23:49:27.389Z',
  updatedTime: '2022-06-09T10:38:15.943Z',
  name: 'Default (light)',
  priority: 1000,
  config: {
    container: {
      isDark: false,
      effect: ContainerEffect.SOLID,
    },
    background: {
      type: BackgroundType.COLOR,
      color: { hex: '#f6f4f4' },
    },
    contentStyles: {
      letterSpacing: '-.02em',
      '.block-heading [level], .block-title .title': {
        letterSpacing: '-.03em',
      },
    },
    colors: [
      {
        name: 'Cool Gray',
        hex: '#EDF2F7',
        isDark: false,
      },
      {
        name: 'Neutral Gray',
        hex: '#F2F2F2',
        isDark: false,
      },
      {
        name: 'Warm Gray',
        hex: '#F7F3F0',
        isDark: false,
      },
      {
        name: 'Beige',
        hex: '#F7F1EB',
        isDark: false,
      },
      {
        name: 'Powder Blue',
        hex: '#bdebe8',
        isDark: false,
      },
      {
        name: 'Light Cyan',
        hex: '#d5eff6',
        isDark: false,
      },
      {
        name: 'Papaya Whip',
        hex: '#f8ecd3',
        isDark: false,
      },
      {
        name: 'Pale Pink',
        hex: '#f8d3d7',
        isDark: false,
      },
      {
        name: 'Lavender',
        hex: '#e2def2',
        isDark: false,
      },
      {
        name: 'Periwinkle',
        hex: '#cbdbf1',
        isDark: false,
      },
      {
        name: 'Linen',
        hex: '#eee2dd',
        isDark: false,
      },
    ],
  },
}
