import { Stack, AvatarGroup, Avatar, Box } from '@chakra-ui/react'
import { memo } from 'react'

import { Comment } from 'modules/api'

import {
  REACTION_COUNT_TO_SHOW,
  useAvatarGroupData,
} from './BlockCommentsButtons/hooks'

type AvatarGroupPopupProps = {
  comments: Comment[]
  reactions: any[]
  isExpanded: boolean
  avatarMax?: number
}
export const AvatarGroupPopup: React.FC<AvatarGroupPopupProps> = memo(
  ({ comments, reactions, isExpanded, avatarMax = 4 }) => {
    const { allAvatars, reactionAvatars, commentAvatars } = useAvatarGroupData({
      comments,
      reactions,
    })

    return (
      <Stack
        className="avatar-group-popup"
        spacing={1}
        transitionProperty="common"
        transitionDuration="fast"
        onClick={(ev) => {
          ev.stopPropagation() // Prevents button click from selecting after a nested card
        }}
        p="1px"
        pl={3}
        // dont allow text selection of the avatar initials
        userSelect="none"
      >
        {!isExpanded && (
          <AvatarGroup
            max={avatarMax}
            style={{ fontSize: 'var(--chakra-fontSizes-sm)' }} // hack to overwrite font size from card font size
            size="sm"
            justifyContent="start"
            opacity={[0, 0, 0, 1]}
            transitionProperty="common"
            transitionDuration="normal"
            position="relative"
          >
            {allAvatars.map((author, ind) => {
              return (
                <Avatar
                  zIndex={allAvatars.length - ind}
                  mr={-3}
                  key={`unexpanded-${author?.id}`}
                  name={author?.displayName}
                  src={author?.profileImageUrl}
                  shadow="base"
                  ignoreFallback={true}
                />
              )
            })}
          </AvatarGroup>
        )}
        {isExpanded && (
          <>
            {commentAvatars.map((avatars, index) => (
              <AvatarGroup
                key={`comment-${index}`}
                max={avatarMax}
                style={{ fontSize: 'var(--chakra-fontSizes-sm)' }} // hack to overwrite font size from card font size
                size="sm"
                justifyContent="start"
                opacity={[0, 0, 0, 1]}
                transitionProperty="common"
                transitionDuration="normal"
                position="relative"
              >
                {avatars.map((author, ind) => {
                  return (
                    <Avatar
                      zIndex={allAvatars.length - ind}
                      mr={-3}
                      key={`comment-${author?.id}`}
                      name={author?.displayName}
                      src={author?.profileImageUrl}
                      shadow="base"
                      ignoreFallback={true}
                    />
                  )
                })}
              </AvatarGroup>
            ))}

            {reactions
              .slice(0, REACTION_COUNT_TO_SHOW)
              .map((blockReaction, index) => (
                <AvatarGroup
                  key={`reaction-${index}`}
                  max={avatarMax}
                  style={{ fontSize: 'var(--chakra-fontSizes-sm)' }} // hack to overwrite font size from card font size
                  size="sm"
                  justifyContent="start"
                  opacity={[0, 0, 0, 1]}
                  transitionProperty="common"
                  transitionDuration="normal"
                  position="relative"
                >
                  {reactionAvatars[blockReaction.emoji].map((author, ind) => {
                    return (
                      <Avatar
                        zIndex={allAvatars.length - ind}
                        mr={-3}
                        key={`reaction-${author?.id}`}
                        name={author?.displayName}
                        src={author?.profileImageUrl}
                        shadow="base"
                        ignoreFallback={true}
                      />
                    )
                  })}
                </AvatarGroup>
              ))}
          </>
        )}
      </Stack>
    )
  }
)
AvatarGroupPopup.displayName = 'AvatarGroupPopup'
