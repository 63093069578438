import { extendEmojiMartNativeDict } from './data'
import { emojiMartPromise } from './emojiMartPromise'
import {
  EmojiData,
  EmojiMartData,
  EmojiMartInitFn,
  EmojiMartSearchIndex,
  EmojiNativeToEmojiDataDict,
  EmojiObject,
  NativeEmojiToIdDict,
  SkinTone,
} from './types'

type EmojisDict = {
  [id: string | number]: EmojiObject
}

let _nativeToEmojiDataDict: EmojiNativeToEmojiDataDict | undefined
let _emojis: EmojisDict
let init: EmojiMartInitFn
let SearchIndex: EmojiMartSearchIndex

emojiMartPromise.then((module) => {
  init = module.init
  SearchIndex = module.SearchIndex
})

export const initEmojiMartDataLazy = async () => {
  const data: EmojiMartData = (await import('@emoji-mart/data')).default

  // Wait til we've loaded the emoji mart module
  await emojiMartPromise

  // Wait til we've run initialize. It's needed for two reasons:
  // 1. Picker component and headless search rely on it, and
  // 2. It augments the data with things like shortcodes
  await init({ data })

  // Once we've run init, we can build out our map!
  _nativeToEmojiDataDict = buildNativeToEmojiDataDict(data.emojis)
  _emojis = data.emojis
}

const _getEmojiData = () => {
  if (!_nativeToEmojiDataDict) {
    console.error("[EmojiModule]: Native map wasn't loaded")
  }
  return {
    nativeToEmojiDataMap: _nativeToEmojiDataDict,
    emojis: _emojis,
  }
}

export const searchEmoji = async (
  query: string
): Promise<Array<EmojiObject>> => {
  const emojis: EmojiObject[] = await SearchIndex.search(query)
  if (!emojis) {
    return []
  }
  const results = emojis.map((emoji) => {
    return emoji
  })
  return results
}

export const getNativeFromEmojiObject = (emoji: EmojiObject): string => {
  return emoji?.skins?.[0].native
}

export const getIdFromNative = (nativeEmoji: string): string | undefined => {
  const { nativeToEmojiDataMap } = _getEmojiData()
  const id = nativeToEmojiDataMap?.[nativeEmoji]?.id
  if (!id) {
    // We are sending these to sentry just so we can keep track of emoji that aren't findable.
    // The only bad thing that happens if we can't find the ID is that the resulting EmojiNode
    // ends up missing its `id` attribute. It will still have the `native` attribute and the correct
    // emoji content, though.
    console.error(
      '[emoji.getIdFromNative] Cannot find ID from native:',
      nativeEmoji
    )
  }
  return id
}

export const getEmojiObjectFromId = (id: string): EmojiObject => {
  const { emojis } = _getEmojiData()
  return emojis?.[id]
}

export const isEmojiDataLoaded = (): boolean => {
  const { emojis, nativeToEmojiDataMap } = _getEmojiData()
  return !!nativeToEmojiDataMap && !!emojis
}

const buildEmojiDataForOverrides = (
  nativeEmojiToIdDict: NativeEmojiToIdDict,
  emojis: EmojisDict
): EmojiNativeToEmojiDataDict => {
  return Object.entries(nativeEmojiToIdDict).reduce((acc, [native, id]) => {
    const emojiData = emojis[id]
    acc[native] = getEmojiData(emojiData, { skinIndex: 0 })
    return acc
  }, {})
}
const buildNativeToEmojiDataDict = (emojis: EmojisDict) => {
  const extended = buildEmojiDataForOverrides(extendEmojiMartNativeDict, emojis)
  return {
    ...Object.entries(emojis).reduce((acc, [id, emojiData]) => {
      if (!id || !emojiData) {
        return acc
      }
      emojiData.skins.forEach((skin, skinIndex) => {
        acc[skin.native] = getEmojiData(emojiData, { skinIndex })
      })
      return acc
    }, {}),
    // Extend with emoji chars that don't exist in the emoji-mart dataset
    ...extended,
  }
}
// Cloned from
// https://github.com/missive/emoji-mart/blob/052badf03695a16390188efcc4b115ccb3346b13/packages/emoji-mart/src/utils.js#L18-L52
const getEmojiData = (
  emoji: EmojiObject,
  { skinIndex }: { skinIndex: number }
): EmojiData => {
  const skin =
    emoji.skins[skinIndex] ||
    (() => {
      skinIndex = 0
      return emoji.skins[skinIndex]
    })()

  const emojiData: EmojiData = {
    id: emoji.id,
    name: emoji.name,
    native: skin.native,
    unified: skin.unified,
    keywords: emoji.keywords,
    shortcodes: skin.shortcodes || emoji.shortcodes,
  }

  if (emoji.skins.length > 1) {
    emojiData.skin = (skinIndex + 1) as SkinTone
  }

  if (skin.src) {
    emojiData.src = skin.src
  }

  if (emoji.aliases && emoji.aliases.length) {
    emojiData.aliases = emoji.aliases
  }

  if (emoji.emoticons && emoji.emoticons.length) {
    emojiData.emoticons = emoji.emoticons
  }

  return emojiData
}

// export const getEmojiDataFromNative = (nativeEmoji: string): EmojiData => {
//   const { nativeToEmojiDataMap } = _getEmojiData()
//   nativeToEmojiDataMap[nativeEmoji]
// }
