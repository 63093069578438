import { Box } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'
import { isEqual } from 'lodash'

import { useAppSelector } from 'modules/redux'
import {
  selectCardIdMap,
  selectDoc,
  selectIsStatic,
  selectMode,
} from 'modules/tiptap_editor/reducer'
import { EditorModeEnum } from 'modules/tiptap_editor/types'

import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { CardTOCItem } from './CardTOCItem'

export interface CardTOCAttributes {
  nested: boolean
}

export const CardTableOfContentsView = (nodeViewProps: NodeViewProps) => {
  const { node, editor } = nodeViewProps
  const { nested } = node.attrs as CardTOCAttributes
  const cardIdMap = useAppSelector(selectCardIdMap, (...args) =>
    isEqual(...args)
  )
  const doc = useAppSelector(selectDoc)
  const mode = useAppSelector(selectMode)
  const isStatic = useAppSelector(selectIsStatic)
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW

  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      as="div"
      className="card-toc"
    >
      <Box cursor="default" pointerEvents={isStatic ? 'none' : undefined}>
        {Object.entries(cardIdMap.tree).map(([card, children]) => {
          return (
            <CardTOCItem
              key={card}
              cardId={card}
              cards={children}
              editor={editor}
              level={0}
              showNested={nested}
              docId={doc?.id || ''}
              isPresentMode={isPresentMode}
            />
          )
        })}
      </Box>
    </AnnotatableNodeViewWrapper>
  )
}
