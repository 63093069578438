import { NodeWithPos } from '@tiptap/core'
import { round } from 'lodash'
import { ResolvedPos } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import { dispatchContainerResizeEvent } from 'utils/hooks/useContainerResizing'

import { UpdateNodeAttrsAnnotationEvent } from '../../Annotatable/AnnotationExtension/types'
import { rebalanceColWidths } from '../../tables/prosemirror-table/columnUtils'
import { getColIndex, getLayoutChildren } from '../utils'
import {
  getLayoutResizingState,
  resetResizingState,
  setDragging,
  updateHandle,
} from './commands'
import { DraggingState } from './LayoutResizingState'

const HANDLE_WIDTH = 5
export const COL_MIN_PERCENT = 15

export const handleMouseMove = (view: EditorView, event: MouseEvent) => {
  // dont handle mouse move stuff when the doc is not editable
  if (!view.editable || !event.target) {
    return
  }
  if ((event.target as HTMLElement).closest('.column-resize-handle')) {
    return
  }
  const pluginState = getLayoutResizingState(view)

  // no op if already dragging
  if (pluginState.dragging) {
    return
  }

  const cell: number | null = findHoveringGridCell(view, event)

  // cell is already the active resize handle, noop
  const activeHandle = pluginState.getActiveHandleAbs(view.state)
  if (cell === activeHandle) {
    return
  }

  updateHandle(view, cell)
}

function findHoveringGridCell(
  view: EditorView,
  event: MouseEvent
): number | null {
  const found = view.posAtCoords({ left: event.clientX, top: event.clientY })
  if (!found) {
    return null
  }

  const dom = view.domAtPos(found.inside)
  if (!dom) {
    return null
  }
  const foundDomNode = dom.node.childNodes[dom.offset] as HTMLElement
  if (!foundDomNode) {
    return null
  }

  const $cell = view.state.doc.resolve(found.inside)
  if ($cell.nodeAfter?.type.name !== 'gridCell') {
    return null
  }
  const { left, right } = foundDomNode.getBoundingClientRect()

  // is on the right side of the cell boundary
  if (event.clientX - left <= HANDLE_WIDTH) {
    const $layout = view.state.doc.resolve($cell.before($cell.depth))

    const result = findChildBefore(
      getLayoutChildren($layout),
      (a) => a.pos === $cell.pos
    )

    return result?.pos || null
  } else if (right - event.clientX <= HANDLE_WIDTH) {
    // is on left side of the cell boundary
    return $cell.pos
  }

  return null
}

const findChildBefore = (
  children: NodeWithPos[],
  predicate: (child: NodeWithPos) => boolean
): NodeWithPos | null => {
  for (let i = 0; i < children.length; i++) {
    if (predicate(children[i])) {
      if (i === 0) {
        return null
      }
      return children[i - 1] || null
    }
  }
  return null
}

export const isLastCell = ($cell: ResolvedPos): boolean => {
  const children = getLayoutChildren($cell)
  const ind = children.findIndex((c) => c.pos === $cell.pos)

  return ind === children.length - 1
}

const currentColWidth = (view: EditorView, pos: number) => {
  const dom = view.domAtPos(pos)
  const childNode = dom.node.childNodes[dom.offset] as HTMLElement
  return childNode.offsetWidth
}

/**
 * Gets layout dom element from any resolved position at layout or descendent of layout
 */
export const getLayoutElement = (
  view: EditorView,
  $pos: ResolvedPos
): HTMLElement => {
  const { node, offset } = view.domAtPos($pos.start())
  let layoutEl: HTMLElement = node.childNodes[offset] as HTMLElement
  do {
    if (
      layoutEl &&
      layoutEl.classList &&
      layoutEl.classList.contains('node-gridLayout')
    ) {
      return layoutEl
    }
  } while ((layoutEl = layoutEl.parentNode! as HTMLElement))

  return layoutEl
}

export function handleMouseDown(view: EditorView, event) {
  // dont handle mouse move stuff when the doc is not editable
  if (!view.editable) {
    return
  }
  const pluginState = getLayoutResizingState(view)!
  const activeHandle = pluginState.getActiveHandleAbs(view.state)
  if (activeHandle === null || pluginState.dragging) {
    return false
  }

  const $cell = view.state.doc.resolve(activeHandle)
  const layout = $cell.node()
  const colWidths = [...layout.attrs.colWidths]
  const colIndex = getColIndex($cell)
  const width = currentColWidth(view, activeHandle)
  const layoutEl = getLayoutElement(view, $cell)
  const tableWidth = layoutEl.offsetWidth

  setDragging(view, {
    startX: event.clientX,
    startWidth: width,
    colWidths,
    tableWidth,
    colIndex,
  })
  let resizingColWidths: number[] | null = null

  function finish(event: MouseEvent) {
    window.removeEventListener('mouseup', finish)
    window.removeEventListener('mousemove', move)
    const pluginState = getLayoutResizingState(view)!

    if (!pluginState.dragging) {
      // never started dragging
      return
    }

    if (resizingColWidths === null) {
      // started click / drag but did not actually move
      setDragging(view, null)
      return
    }

    if (pluginState.dragging) {
      try {
        const activeHandle = pluginState.getActiveHandleAbs(view.state)
        dispatchUpdatedColWidths(view, activeHandle!, resizingColWidths)
        setDragging(view, null)
      } catch (e) {
        // reset resize plugin state if the layout gets deleted.
        resetResizingState(view)
      }
    }

    resizingColWidths = null
  }

  function move(event: MouseEvent) {
    if (!event.which) {
      return finish(event)
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const pluginState = getLayoutResizingState(view)!
    const activeHandle = pluginState.getActiveHandleAbs(view.state)
    if (!pluginState.dragging || activeHandle === null) {
      return
    }
    const { colIndex, colWidths } = pluginState.dragging
    const percentChange = draggedPercentChange(pluginState.dragging, event)
    resizingColWidths = rebalanceColWidths(
      colWidths,
      colIndex,
      percentChange,
      COL_MIN_PERCENT
    )

    displayColumnWidth(layoutEl, resizingColWidths)
    // dispatch resize here to trigger the ImageView useResizeable to update
    // the bubble menu / image resize handles
    dispatchContainerResizeEvent(layoutEl)
  }

  window.addEventListener('mouseup', finish)
  window.addEventListener('mousemove', move)
  event.preventDefault()
  return true
}

function dispatchUpdatedColWidths(
  view: EditorView,
  cell: number,
  colWidths: number[]
) {
  const $cell = view.state.doc.resolve(cell)!
  const layoutPos = $cell.before()
  const tr = view.state.tr
    .setNodeMarkup(layoutPos, undefined, { colWidths: [...colWidths] })
    .setMeta('annotationEvent', <UpdateNodeAttrsAnnotationEvent>{
      type: 'update-node-attrs',
      pos: layoutPos,
    })
  view.dispatch(tr)

  removeDisplayColumnWidth(view, cell)
}

function draggedPercentChange(dragging: DraggingState, event): number {
  const { tableWidth } = dragging
  const offset = event.clientX - dragging.startX
  return round((100 * offset) / tableWidth, 0)
}

function displayColumnWidth(layoutEl: HTMLElement, colWidths: number[]) {
  const controls = layoutEl.querySelector('.grid-col-controls')! as HTMLElement
  if (!controls) {
    return
  }
  controls.style.gridTemplateColumns = colWidths.map((w) => `${w}%`).join(' ')
}

function removeDisplayColumnWidth(view: EditorView, cell: number) {
  const $cell = view.state.doc.resolve(cell)
  const layoutEl = getLayoutElement(view, $cell)
  const controls = layoutEl.querySelector('.grid-col-controls')! as HTMLElement
  if (!controls) {
    return
  }
  controls.style.gridTemplateColumns = ''
}

export function handleMouseLeave(view: EditorView) {
  const pluginState = getLayoutResizingState(view)
  const activeHandle = pluginState.getActiveHandleAbs(view.state)

  if (activeHandle !== null && !pluginState.dragging) {
    updateHandle(view, null)
  }
}
