import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor } from '@tiptap/core'

import { NodeInsertMethods } from 'modules/segment'
import { EventBusEvent, TiptapEventBus } from 'modules/tiptap_editor/eventBus'
import { isAnnotatableParent } from 'modules/tiptap_editor/extensions/Annotatable/utils'
import { isFootnoteEditor } from 'modules/tiptap_editor/extensions/Footnote/utils'

import { COMMANDS_MAP, trackItemInserted } from '../../../commands'
import { AlignmentCommands } from '../../../extensions/HorizontalAlign'
import { checkListActive } from '../../../extensions/lists/List'
import { ListVariant } from '../../../extensions/lists/ListTypes'
import {
  canChangeSelectedNodeType,
  canWrapSelection,
  findParentNodes,
  selectionAllowsMark,
  textBetweenFiltered,
} from '../../../utils'

export enum FilterKeyForTextFormattingCommands {
  enabledForTables = 'enabledForTables',
}
export type TextFormattingCommand = {
  key: string
  name: string
  icon: IconProp
  mark?: string
  node?: string
  shortcut?: string
  apply?: (editor: Editor) => void
  checkActive?: (editor: Editor) => boolean
  checkDisabled?: (editor: Editor) => boolean
  featureFlag?: string
  [FilterKeyForTextFormattingCommands.enabledForTables]?: boolean
}

export const TEXT_FORMATTING_COMMANDS: TextFormattingCommand[][] = [
  [
    {
      key: 'bold',
      name: 'Bold',
      mark: 'bold',
      icon: solid('bold'),
      shortcut: 'Mod+B',
      enabledForTables: true,
    },
    {
      key: 'italic',
      name: 'Italic',
      mark: 'italic',
      icon: regular('italic'),
      shortcut: 'Mod+I',
      enabledForTables: true,
    },
    {
      key: 'underline',
      name: 'Underline',
      mark: 'underline',
      icon: regular('underline'),
      shortcut: 'Mod+U',
      enabledForTables: true,
    },
    {
      key: 'strike',
      name: 'Strikethrough',
      mark: 'strike',
      icon: regular('strikethrough'),
      shortcut: 'Mod+Shift+X',
    },
    {
      key: 'code',
      name: 'Code',
      mark: 'code',
      icon: regular('code'),
      shortcut: 'Mod+E',
    },
    {
      key: 'math',
      name: 'Math',
      apply: (editor) => editor.commands.convertToMathInline(),
      icon: regular('sigma'),
      featureFlag: 'math',
      shortcut: '$$',
    },
    {
      key: 'link',
      name: 'Link',
      mark: 'link',
      icon: regular('link'),
      shortcut: 'Mod+K',
    },
    {
      key: 'textColor',
      name: 'Text color',
      icon: regular('font'),
      shortcut: 'Mod+Shift+H',
    },
    {
      key: 'removeFormatting',
      name: 'Remove formatting',
      icon: regular('remove-format'),
      // shortcut: 'Mod+\\', // todo: pull this into its own extension and add shortcuts
      apply: (editor) => editor.chain().unsetAllMarks().focus().run(),
      enabledForTables: true,
    },
  ],
  ...[AlignmentCommands],
  [
    {
      key: 'numbered',
      name: 'Numbered list',
      icon: regular('list-ol'),
      node: 'numbered',
      checkActive: (editor) => checkListActive(editor, ListVariant.Numbered),
      shortcut: 'Mod+/ or Mod+Shift+7',
      apply: (editor) =>
        editor
          .chain()
          .toggleListItems({ variant: ListVariant.Numbered })
          .focus()
          .run(),
    },
    {
      key: 'bullet',
      name: 'Bulleted list',
      icon: regular('list-ul'),
      node: 'bullet',
      checkActive: (editor) => checkListActive(editor, ListVariant.Bullet),
      apply: (editor) =>
        editor
          .chain()
          .toggleListItems({ variant: ListVariant.Bullet })
          .focus()
          .run(),
      shortcut: 'Mod+. or Mod+Shift+8',
    },
    {
      key: 'todo',
      name: 'Todo list',
      icon: regular('tasks'),
      node: 'todo',
      checkActive: (editor) => checkListActive(editor, ListVariant.Todo),
      shortcut: 'Mod+, or Mod+Shift+9',
      apply: (editor) => {
        const { from, to } = editor.state.selection
        editor.chain().toggleListItems({ variant: ListVariant.Todo }).run()
        /* I can't figure out why, but normal focus doesn't work here
         * I think the NodeView in todo needs time to rerender
         * And the order is fussy. If you swap focus and setTextSelection,
         * it'll barf.
         */
        requestAnimationFrame(() =>
          editor
            .chain()
            .focus()
            .setTextSelection({
              from,
              to,
            })
            .run()
        )
      },
    },
    {
      key: 'blockquote',
      name: 'Blockquote',
      icon: regular('block-quote'),
      shortcut: '> Quote',
      apply: (editor) => editor.chain().focus().toggleBlockquote().run(),
      checkDisabled: (editor) => !canWrapSelection(editor, 'blockquote'),
    },
  ],
  [
    {
      key: 'convertToNestedCard',
      name: 'Convert to nested card',
      icon: regular('diagram-subtask'),
      apply: (editor) => {
        trackItemInserted(
          COMMANDS_MAP.insertCardInside,
          NodeInsertMethods.FORMATTING_MENU
        )
        editor.chain().convertToNestedCard().run()
      },
      checkDisabled: (editor) =>
        !editor.schema.nodes.card || !canWrapSelection(editor, 'card'),
    },
    {
      key: 'footnote',
      name: 'Create footnote',
      icon: regular('superscript'),
      apply: (editor) => {
        trackItemInserted(
          COMMANDS_MAP.footnote,
          NodeInsertMethods.FORMATTING_MENU
        )
        editor.chain().toggleFootnote().run()
      },
      checkActive: (editor) => editor.isActive('footnoteLabel'),
      shortcut: 'Mod+Alt+F',
      checkDisabled: (editor) =>
        isFootnoteEditor(editor) || !canWrapSelection(editor, 'footnote'), // Dont allow footnotes inside footnotes
    },
    {
      key: 'comment',
      name: 'Add comment',
      icon: regular('comment'),
      checkDisabled: isFootnoteEditor, // Dont allow comments inside footnotes right now
      apply: (editor) => {
        const { selection } = editor.state
        const { from, to } = selection
        const [parentNode] = findParentNodes(
          editor.state.doc.resolve(from),
          (_node, parent) => isAnnotatableParent(parent)
        )
        if (!parentNode) {
          console.warn(
            '[TEXT_FORMATTING_COMMANDS] Add comment - Unable to find annotatable parent for this selection'
          )
          return
        }

        const text = textBetweenFiltered(
          editor.state.doc,
          from,
          to,
          (node) => node.type.name !== 'footnote',
          '__BLOCK_SEPARATOR_PLACEHOLDER'
        )
          .split('__BLOCK_SEPARATOR_PLACEHOLDER')
          .join('<br /><br />')

        TiptapEventBus.emit(EventBusEvent.CREATE_COMMENT_FROM_SELECTION, {
          selectionPos: from,
          parentPos: parentNode.pos,
          text,
        })
      },
    },
  ],
]

export const checkCommandDisabled = (
  editor: Editor,
  command: TextFormattingCommand
) => {
  if (command.checkDisabled?.(editor)) {
    return true
  }

  if (command.node) {
    return !canChangeSelectedNodeType(editor, command.node)
  }

  if (command.mark) {
    return !selectionAllowsMark(editor, command.mark)
  }
  return false
}
