import { forwardRef, IconButton, TooltipProps } from '@chakra-ui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import * as React from 'react'

const iconShadow = '0px 1px 0px rgba(0,0,0,0)'
const iconColor = 'trueblue.600'

type ToolbarIconProps = {
  label?: string
  icon: FontAwesomeIconProps['icon']
  onClick?: React.MouseEventHandler
  isActive?: boolean
  isDisabled?: boolean
  tooltipPlacement?: TooltipProps['placement']
}

export const ToolbarIcon = forwardRef(
  (
    { label, icon, tooltipPlacement = 'top', ...props }: ToolbarIconProps,
    ref
  ) => {
    return (
      <GammaTooltip
        placement={tooltipPlacement}
        label={label}
        isDisabled={!label}
      >
        <IconButton
          aria-label={label || ''}
          ref={ref}
          size="sm"
          variant=""
          icon={<FontAwesomeIcon icon={icon} />}
          color={iconColor}
          textShadow={iconShadow}
          fontWeight="500"
          borderRadius="md"
          borderWidth="1px"
          backdropFilter="blur(20px)"
          borderColor="transparent"
          _hover={{
            bg: 'gray.100',
            borderColor: 'gray.200',
          }}
          _focus={{
            bg: 'gray.100',
            borderColor: 'gray.200',
          }}
          _active={{
            bg: 'trueblue.100',
            borderColor: 'trueblue.200',
          }}
          {...props}
        />
      </GammaTooltip>
    )
  }
)
