import { getUnixTime } from 'date-fns'

import { config } from 'config'
import { ExistingWorkspace } from 'modules/api'
import { featureFlags } from 'modules/featureFlags'

import { GraphqlUser } from '../user/context/UserContext'

const boot = () => {
  const intercom = window.Intercom
  if (intercom) {
    intercom('boot', {
      app_id: config.INTERCOM_APP_ID,
      hide_default_launcher: true,
    })
  }
}

export const init = () => {
  let isBooted = false

  featureFlags.initializePromise.then(() => {
    if (featureFlags.get('intercom')) {
      boot()
      isBooted = true
    }

    featureFlags.subscribe('intercom', (isEnabled) => {
      if (isEnabled && !isBooted) {
        boot()
        isBooted = true
      }
    })
  })
}

export const updateIntercomUser = ({
  user,
  currentWorkspace,
}: {
  user: GraphqlUser
  currentWorkspace?: ExistingWorkspace
}) => {
  featureFlags.initializePromise.then(() => {
    const intercom = window.Intercom

    if (intercom && featureFlags.get('intercom')) {
      intercom('update', {
        email: user?.email,
        user_id: user?.id,
        name: user?.displayName,
        created_at: user.createdTime
          ? getUnixTime(user.createdTime)
          : undefined,
        ...(user?.profileImageUrl
          ? {
              avatar: {
                type: 'avatar',
                image_url: user?.profileImageUrl,
              },
            }
          : {}),
        company: {
          company_id: currentWorkspace?.id,
          name: currentWorkspace?.name || 'unknown company',
        },
      })
    }
  })
}

export const launchIntercomUI = () => {
  const intercom = window.Intercom
  if (intercom) {
    intercom('show')
  }
}
