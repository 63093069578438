import { findParentNode, mergeAttributes, Node } from '@tiptap/core'

import { findSelectionNearOrGapCursor } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

import { EMPTY_NODES } from '../../commands/emptyNodes'
import { ReactNodeViewRenderer } from '../../react'
import { isNodeEmpty } from '../../utils'
import { attrsOrDecorationsChanged } from '../updateFns'
import { ButtonView } from './ButtonView'

export type ButtonAttrs = {
  href: string
  variant: 'solid' | 'outline'
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    button: {
      addButtonToGroup: (pos: number) => ReturnType
      handleButtonDelete: (checkEmpty: boolean) => ReturnType
    }
  }
}

export const Button = Node.create({
  name: 'button',
  content: '(text | emoji)*',
  marks: '',
  defining: true,
  isolating: true,
  selectable: false,

  addNodeView() {
    return ReactNodeViewRenderer(ButtonView, {
      update: attrsOrDecorationsChanged,
    })
  },

  addAttributes() {
    return {
      href: {
        default: '',
      },
      variant: {
        default: 'solid',
      },
    }
  },

  addCommands() {
    return {
      addButtonToGroup:
        (pos) =>
        ({ state, chain }) => {
          const node = state.doc.nodeAt(pos)
          if (!node || node.type.name !== 'buttonGroup') return false
          const end = pos + node.nodeSize - 1 // Just inside the group, outside the last button
          chain()
            .insertContentAt(end, EMPTY_NODES.button)
            .selectInsertedNode()
            .run()

          return true
        },
      handleButtonDelete:
        (checkEmpty) =>
        ({ dispatch, state, chain }) => {
          if (!dispatch) return true
          const parentButton = findParentNode((n) => n.type.name === 'button')(
            state.selection
          )
          if (!parentButton || (checkEmpty && !isNodeEmpty(parentButton.node)))
            return false
          const parentGroup = findParentNode(
            (n) => n.type.name === 'buttonGroup'
          )(state.selection)
          if (!parentGroup) return false

          if (parentGroup.node.childCount === 1) {
            // If this is the last button, delete the whole group
            chain()
              .deleteNode('buttonGroup')
              .command(({ tr }) => {
                const sel = findSelectionNearOrGapCursor(
                  tr.doc.resolve(parentGroup.pos)
                )
                if (sel) {
                  tr.setSelection(sel)
                }
                return true
              })
              .focus()
              .run()
          } else {
            // Otherwise just delete the button
            chain()
              .deleteNode('button')
              .command(({ tr }) => {
                const sel = findSelectionNearOrGapCursor(
                  tr.doc.resolve(parentButton.pos - 1),
                  -1
                )
                if (sel) {
                  tr.setSelection(sel)
                }
                return true
              })
              .focus()
              .run()
          }

          return true
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'button',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['button', mergeAttributes(HTMLAttributes), 0]
  },
})
