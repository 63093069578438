import { Box, ButtonGroup, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor } from '@tiptap/core'
import isEqual from 'lodash/isEqual'
import { NodeSelection } from 'prosemirror-state'
import { useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'modules/redux'
import { getParentCardWidthPxFromSelection } from 'modules/tiptap_editor/extensions/Card/utils'
import { isFootnoteEditor } from 'modules/tiptap_editor/extensions/Footnote/utils'
import {
  DEFAULT_RESIZE_STATE,
  eventEmitter,
  Image as ImageNode,
  ImageAttrs,
  ResizeAttrs,
  selectClipType,
  selectIsCropping,
  setClipType,
  setCropping,
} from 'modules/tiptap_editor/extensions/media/Image'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { AlignmentCommands } from '../../../extensions/HorizontalAlign'
import {
  addImageComment,
  isNodeInGallery,
} from '../../../extensions/media/Gallery/utils'
import { useMediaZoom } from '../../../extensions/media/Zoomable'
import { useToggleMediaDrawer } from '../../drawers/MediaDrawer/MediaDrawer'
import { ToolbarButton } from '../ToolbarButton'
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
} from '../ToolbarDropdown'

type ImageFormattingMenuProps = {
  editor: Editor
  selection: NodeSelection
}

export const ImageFormattingMenu = ({
  editor,
  selection,
}: ImageFormattingMenuProps) => {
  const nodeName = selection.node.type.name // Could be image or mediaPlaceholder
  const isImageNode = nodeName === ImageNode.name
  const { resize } = selection.node.attrs as ImageAttrs
  const { isFullWidth } = editor.getAttributes(nodeName)
  const currentClipType = useAppSelector(selectClipType)
  const isCropping = useAppSelector(selectIsCropping)
  const dispatch = useAppDispatch()
  const hasFormattingApplied = !isEqual(
    {
      resize,
      isFullWidth,
    },
    {
      resize: DEFAULT_RESIZE_STATE,
      isFullWidth: false,
    }
  )

  const hasCroppingApplied = Boolean(resize?.clipPath && resize?.clipType)

  const toggleMediaDrawer = useToggleMediaDrawer()
  const editNode = useCallback(
    (ev) => {
      toggleMediaDrawer(true)
      ev.stopPropagation()
    },
    [toggleMediaDrawer]
  )
  const { enterZoom } = useMediaZoom(selection.node.attrs.id)
  useEffect(() => {
    if (!isImageNode || !resize?.clipType) return

    dispatch(setClipType({ clipType: resize.clipType }))
  }, [dispatch, resize, isImageNode])
  const addComment = useCallback(() => {
    addImageComment(selection)
  }, [selection])

  const isFullWidthOr100 =
    isFullWidth || resize?.width == getParentCardWidthPxFromSelection(selection)
  const inFootnote = isFootnoteEditor(editor)
  const inGallery = isNodeInGallery(selection.$from)
  const cropAndResizeEnabled = !inGallery && !inFootnote

  return (
    <>
      {cropAndResizeEnabled && (
        <ButtonGroup spacing={0} size="sm">
          {isCropping ? (
            <CropImageDropdown
              clipType={currentClipType}
              onChange={(clipType: ResizeAttrs['clipType']) => {
                if (clipType) {
                  dispatch(setClipType({ clipType }))
                }
              }}
            />
          ) : (
            <>
              <ToolbarButton
                label={'Full width'}
                icon={regular('arrows-h')}
                isActive={isFullWidthOr100}
                onClick={() =>
                  editor.commands.updateAttributes(nodeName, {
                    isFullWidth: !isFullWidth,
                  })
                }
              />
              {AlignmentCommands.map(({ name, icon, checkActive, apply }) => {
                if (!checkActive || !apply) return
                return (
                  <ToolbarButton
                    key={name}
                    label={name}
                    icon={icon}
                    onClick={() => {
                      apply(editor)
                      if (isFullWidthOr100) {
                        editor.commands.updateAttributes(nodeName, {
                          isFullWidth: false,
                          resize: {
                            ...resize,
                            width: null,
                          },
                        })
                      }
                    }}
                    isActive={!isFullWidthOr100 && checkActive(editor)}
                  />
                )
              })}
            </>
          )}
        </ButtonGroup>
      )}
      <ButtonGroup spacing={0} size="sm">
        {isCropping ? (
          <>
            <ToolbarButton
              label={'Cancel crop'}
              icon={regular('cancel')}
              onClick={() => {
                eventEmitter.emit('endClip', { confirm: false })
              }}
            />
            <ToolbarButton
              label={'Reset crop'}
              icon={regular('rotate-left')}
              disabled={!hasCroppingApplied}
              onClick={() => {
                eventEmitter.emit('endClip', { confirm: false })
                editor.commands.resetImageClip()
                editor.commands.refreshBubbleMenu?.()
              }}
            />
            <ToolbarButton
              label={'Save crop'}
              icon={regular('circle-check')}
              onClick={() => {
                dispatch(setCropping({ isCropping: false }))
              }}
            />
          </>
        ) : (
          <>
            {isImageNode && cropAndResizeEnabled && (
              <ToolbarButton
                label={'Crop'}
                icon={regular('crop')}
                isActive={isCropping}
                onClick={() => {
                  if (!resize?.clipType) {
                    dispatch(setClipType({ clipType: 'inset' }))
                  }
                  dispatch(setCropping({ isCropping: true }))
                }}
              />
            )}
            {isImageNode && cropAndResizeEnabled && (
              <ToolbarButton
                label={'Reset formatting'}
                icon={regular('rotate-left')}
                disabled={!hasFormattingApplied}
                onClick={() => {
                  editor
                    .chain()
                    .resetImageClip()
                    .resetImageScale()
                    .command(({ commands }) => !!commands.refreshBubbleMenu?.())
                    .run()
                }}
              />
            )}
            {isImageNode && (
              <ToolbarButton
                label={'Zoom'}
                icon={regular('magnifying-glass-plus')}
                onClick={enterZoom}
              />
            )}
            {isImageNode && !inFootnote && (
              <ToolbarButton
                label={'Add comment'}
                icon={regular('comment')}
                onClick={addComment}
              />
            )}
            <ToolbarButton
              label={'Edit'}
              icon={regular('pencil')}
              onClick={editNode}
              testId="edit-button"
            />
          </>
        )}
      </ButtonGroup>
    </>
  )
}

const OPTIONS = {
  inset: { title: 'Rectangle' },
  circle: { title: 'Circle' },
} as const

const CropImageDropdown = ({
  clipType,
  onChange,
}: {
  clipType: ResizeAttrs['clipType']
  onChange: (clipType: ResizeAttrs['clipType']) => void
}) => {
  if (!clipType) return null

  return (
    <Dropdown>
      <DropdownButton
        size="sm"
        borderRadius="full"
        // Maintain the same width regardless of the option
        minWidth="105px"
        rightIcon={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            transform="shrink-6"
          />
        }
        variant="toolbar"
        pl={2}
        onMouseDown={preventDefaultToAvoidBlur}
      >
        {OPTIONS[clipType].title}
      </DropdownButton>
      <DropdownList>
        {Object.entries(OPTIONS).map(([key, { title }]) => {
          return (
            <DropdownItem
              key={key}
              value={key}
              fontSize="sm"
              fontWeight={600}
              icon={
                <Box visibility={key === clipType ? 'visible' : 'hidden'}>
                  <FontAwesomeIcon icon={regular('check')} />
                </Box>
              }
              onMouseDown={preventDefaultToAvoidBlur}
              onClick={() => {
                onChange(key as ResizeAttrs['clipType'])
              }}
            >
              <Text>{title}</Text>
            </DropdownItem>
          )
        })}
      </DropdownList>
    </Dropdown>
  )
}
