import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
} from '@chakra-ui/react'
import { forwardRef, ReactNode, useEffect } from 'react'

import { BLOCK_COMMENTS_CONTAINER_SELECTOR } from './BaseCommentPopup'

type BlockCommentsPopupWrapperProps = {
  isMobile: boolean
  children: ReactNode
  closePopup: () => void
  drawerMinHeight?: string
}

export const BlockCommentsPopupWrapper = forwardRef<
  HTMLDivElement,
  BlockCommentsPopupWrapperProps
>(function BlockCommentsPopupWrapper(props, ref) {
  const { isMobile, children, closePopup, drawerMinHeight } = props

  useEffect(() => {
    // this is necessary to scroll the window to recalculate and fix the height
    // when dealing with the address bar disappearing in mobile
    if (isMobile) {
      setTimeout(() => {
        window.scrollTo(window.scrollY + 1, 0) // tickle me window
      }, 250)
    }
  }, [isMobile])

  if (isMobile) {
    const height =
      drawerMinHeight || 'calc(calc(100vh - calc(100vh - 100%)) - 1rem)'

    return (
      <Drawer placement="bottom" isOpen={true} onClose={closePopup}>
        <DrawerOverlay />
        <DrawerContent
          minH={height}
          maxH={height}
          borderTopRadius="xl"
          className="comments-wrapper"
          // used to not deactivate mobile comment popup when interacting
          // with this drawer
          data-block-comments-popup
        >
          <DrawerBody
            p={6}
            pb={2}
            className={BLOCK_COMMENTS_CONTAINER_SELECTOR}
          >
            {children}
          </DrawerBody>
          <DrawerCloseButton />
        </DrawerContent>
      </Drawer>
    )
  }
  return <Box ref={ref}>{children}</Box>
})
