import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  AirtableProvider,
  AmplitudeProvider,
  CustomImageProvider,
  CustomVideoProvider,
  FigmaProvider,
  GiphyProvider,
  GoogleDriveProvider,
  GoogleImageProvider,
  GradientProvider,
  LoomProvider,
  MediaProviderType,
  MicrosoftOfficeProvider,
  MiroProvider,
  PDFProvider,
  PowerBIProvider,
  SpotifyProvider,
  TikTokProvider,
  TwitterProvider,
  UnsplashProvider,
  VimeoProvider,
  WebpageProvider,
  WistiaProvider,
  YoutubeProvider,
} from 'modules/media'

import { ColorPanel } from '../../components/panels/ColorPanel'
import { BackgroundType } from '../../styles/backgroundStyles'

export const NO_BACKGROUND_SOURCE_KEY = 'default'
const SOLID_COLOR_SOURCE_KEY = 'color.solid'

export type MediaSourceType = MediaProviderType & {
  availableOffline?: boolean // If this can be used offline
  disabled?: boolean
  nodeName?: string // If this can be inserted as an node
  backgroundType?: BackgroundType // If this can be used as a background
  priority?: number // Controls search order. Higher is better, default 0
}

const EmptyPanel = () => <></>

export const MediaSources: Record<string, MediaSourceType[]> = {
  Default: [
    {
      label: 'None',
      availableOffline: true,
      key: NO_BACKGROUND_SOURCE_KEY,
      backgroundType: BackgroundType.NONE,
      Panel: EmptyPanel,
    },
  ],
  Image: [
    {
      ...CustomImageProvider,
      nodeName: 'image',
      backgroundType: BackgroundType.IMAGE,
      priority: 10, // Surface above galleries and diagrams when searching /im
    },
    {
      ...UnsplashProvider,
      nodeName: 'image',
      backgroundType: BackgroundType.IMAGE,
    },
    {
      ...GiphyProvider,
      nodeName: 'image',
      backgroundType: BackgroundType.IMAGE,
    },
    {
      ...GoogleImageProvider,
      nodeName: 'image',
      // Disabled until we figure out pricing
      disabled: true,
      backgroundType: BackgroundType.IMAGE,
    },
  ],
  Video: [
    {
      ...CustomVideoProvider,
      nodeName: 'video',
    },
    {
      ...LoomProvider,
      nodeName: 'video',
    },
    {
      ...YoutubeProvider,
      nodeName: 'video',
    },
    {
      ...VimeoProvider,
      nodeName: 'video',
    },
    {
      ...WistiaProvider,
      nodeName: 'video',
    },
    {
      ...TikTokProvider,
      nodeName: 'video',
    },
    {
      ...SpotifyProvider,
      nodeName: 'video',
    },
  ],
  Embed: [
    {
      ...WebpageProvider,
      nodeName: 'embed',
      priority: 10, // Surface above video/audio when searching "embed"
    },
    {
      ...GoogleDriveProvider,
      nodeName: 'embed',
    },
    {
      ...PDFProvider,
      nodeName: 'embed',
    },
    {
      ...FigmaProvider,
      nodeName: 'embed',
    },
    {
      ...TwitterProvider,
      nodeName: 'embed',
    },
    {
      ...MiroProvider,
      nodeName: 'embed',
    },
    {
      ...AirtableProvider,
      nodeName: 'embed',
    },
    {
      ...AmplitudeProvider,
      nodeName: 'embed',
    },
    {
      ...MicrosoftOfficeProvider,
      nodeName: 'embed',
    },
    {
      ...PowerBIProvider,
      nodeName: 'embed',
    },
  ],
  Color: [
    {
      label: 'Solid Color',
      key: SOLID_COLOR_SOURCE_KEY,
      availableOffline: true,
      icon: duotone('palette'),
      Panel: ColorPanel,
      backgroundType: BackgroundType.COLOR,
    },
    {
      ...GradientProvider,
      availableOffline: true,
      backgroundType: BackgroundType.GRADIENT,
    },
  ],
}

export const MediaSourcesMap = Object.fromEntries(
  Object.values(MediaSources)
    .flat()
    .map((source) => [source.key, source])
)
