import {
  AddReactionInput,
  AddReactionMutation,
  DocCommentsUpdateFragmentDoc,
  RemoveReactionInput,
  RemoveReactionMutation,
  TargetType,
  useAddReactionMutation,
  useRemoveReactionMutation,
} from 'modules/api'
import { GraphqlUser } from 'modules/user'

/**
 * Helper hook to provide apollo mutation functions with update functions and
 * optimistic cache updating for reactions to comments.
 */
export const useReactionUpdate = ({
  docId,
  cardId,
  commentId,
  parentCommentId,
  targetType,
}: {
  docId: string
  cardId?: string
  commentId?: string
  parentCommentId?: string
  targetType: TargetType
}) => {
  const [addReaction] = useAddReactionMutation()
  const [removeReaction] = useRemoveReactionMutation()

  function getConfig(
    mutationName: 'addReaction' | 'removeReaction',
    emoji: string,
    count: number,
    users: GraphqlUser[]
  ) {
    let reactionInput: RemoveReactionInput = {
      targetId:
        targetType === TargetType.Comment
          ? (commentId as string)
          : (cardId as string),
      docId,
      emoji,
      cardId,
    }
    if (mutationName === 'addReaction') {
      reactionInput = {
        ...reactionInput,
        targetType,
      } as AddReactionInput
    }

    if (targetType === TargetType.Comment) {
      return {
        variables: {
          reactionInput,
        },
        update: (cache, { data }) => {
          cache.writeFragment({
            id: `Doc:${docId}`,
            fragment: DocCommentsUpdateFragmentDoc,
            fragmentName: 'DocCommentsUpdate',
            data: {
              comments: [
                !parentCommentId // not a reply
                  ? {
                      id: commentId,
                      replies: [],
                      reactions: [data[mutationName]],
                      __typename: 'Comment',
                    }
                  : {
                      id: parentCommentId,
                      replies: [
                        {
                          id: commentId,
                          reactions: [data[mutationName]],
                          __typename: 'Comment',
                        },
                      ],
                      reactions: [],
                      __typename: 'Comment',
                    },
              ],
            },
          })
        },
        optimisticResponse: {
          [mutationName]: {
            commentId,
            count,
            emoji,
            users,
            cardId,
            __typename: 'Reaction',
          },
        },
      }
    } else {
      console.error(`[useReactionUpdate] Invalid targetType: ${targetType}`)
      return
    }
  }

  const addReactionFn = (
    emoji: string,
    count: number,
    users: GraphqlUser[]
  ) => {
    addReaction(
      // @ts-ignore HALP
      getConfig('addReaction', emoji, count, users) as AddReactionMutation
    )
  }

  const removeReactionFn = (
    emoji: string,
    count: number,
    users: GraphqlUser[]
  ) => {
    removeReaction(
      // @ts-ignore HALP
      getConfig('removeReaction', emoji, count, users) as RemoveReactionMutation
    )
  }

  return {
    addReactionFn,
    removeReactionFn,
  }
}
