import {
  useCallback,
  useState,
  useRef,
  DragEventHandler,
  MouseEventHandler,
} from 'react'

export const useDragAndMouseDownMonitor = (
  onDragStart: DragEventHandler<HTMLDivElement>,
  onDragEnd: DragEventHandler<HTMLDivElement>,
  onClick: MouseEventHandler<HTMLDivElement>
) => {
  const [isDragging, setDragging] = useState(false)
  const [isMouseDown, setMouseDown] = useState(false)
  const lastFocusRef = useRef<Element>()

  const handleDragStart = useCallback(
    (e) => {
      setDragging(true)
      onDragStart(e)
    },
    [onDragStart]
  )

  const handleDragEnd = useCallback(
    (e) => {
      setDragging(false)
      setMouseDown(false) // Ensure that we reset the mousedown state
      onDragEnd(e)
    },
    [onDragEnd]
  )

  const handleClick = useCallback(
    (e) => {
      // The act of mousing down on the insert widget steals
      // focus for the popover. This restores it to where
      // it was just before.
      if (lastFocusRef.current instanceof HTMLElement) {
        lastFocusRef.current.focus()
      }
      onClick(e)
    },
    [onClick]
  )

  const onMouseDown = useCallback(() => {
    setMouseDown(true)
    lastFocusRef.current = document.activeElement || undefined
  }, [])

  const onMouseUp = useCallback(() => {
    setMouseDown(false)
  }, [])

  return {
    isDragging,
    isMouseDown,
    handleDragStart,
    handleDragEnd,
    handleClick,
    onMouseDown,
    onMouseUp,
  }
}
