import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Portal,
  useBreakpointValue,
} from '@chakra-ui/react'
import { MobileDrawer } from '@gamma-app/ui'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useAppSelector } from 'modules/redux'
import { isMobileDevice } from 'utils/deviceDetection'

import { useGlobalSearchKeyboardShortcuts } from '../../hooks'
import { selectIsGlobalSearchOpen, setIsGlobalSearchOpen } from '../../reducer'
import { SearchWrapper } from './SearchWrapper'

type SearchModalProps = {
  // Callback function whose return value determines whether we show the SearchModal.
  // We need the SearchModal keydown listener to be responsive to changes in editor selection state.
  // We won't do anything if there is an editor selection.
  isEnabledFn?: () => boolean
}

export const GlobalSearch = ({ isEnabledFn }: SearchModalProps) => {
  const isOpen = useAppSelector(selectIsGlobalSearchOpen)
  useGlobalSearchKeyboardShortcuts({
    isEnabledFn,
    isOpen,
  })
  const dispatch = useDispatch()

  const modalSize = useBreakpointValue({
    base: 'sm',
    sm: 'md',
    md: 'lg',
    lg: '2xl',
  })

  const onClose = useCallback(() => {
    dispatch(setIsGlobalSearchOpen({ isOpen: false, initialQuery: '' }))
  }, [dispatch])

  //   TODO: Fix this when we get the drawer opening mechanism working. For now, just show the modal.
  //   if (isMobileDevice) {
  //     return (
  //       <Portal>
  //         <MobileDrawer isOpen={isOpen} onClose={onClose}>
  //           <SearchWrapper />
  //         </MobileDrawer>
  //       </Portal>
  //     )
  //   }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={modalSize}
      onOverlayClick={onClose}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <SearchWrapper />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
