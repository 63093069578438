import mimeTypes from '@uppy/utils/lib/mimeTypes'
import { last } from 'lodash'

export function isPDFFileType(mimeType: string): boolean {
  return mimeType === mimeTypes.pdf
}

export function isDocFileType(mimeType: string): boolean {
  return mimeType === mimeTypes.doc || mimeType === mimeTypes.docx
}

export function isPPTFileExtension(fileExt: string): boolean {
  fileExt = fileExt.toLocaleLowerCase()
  return fileExt === 'ppt' || fileExt === 'pptx'
}

export function isDocUpload(mimeType: string, fileExt: string): boolean {
  return (
    isPDFFileType(mimeType) ||
    isDocFileType(mimeType) ||
    isPPTFileExtension(fileExt)
  )
}

// https://stackoverflow.com/a/18650828
export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getFileExtension = (file: File) => {
  if (!file.name) return ''
  return last(file.name.split('.')) || ''
}
