import { useCallback, useEffect, useRef, useState } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'
import { selectContentEditable } from 'modules/tiptap_editor/reducer'
import { EditorModeEnum } from 'modules/tiptap_editor/types'

import { getBaseDocData } from './baseHoneycombDocData'
import {
  sendDownloadDocEvent,
  sendEditorRenderEvent,
  sendKeydownPerformanceEvent,
  sendModeChangePerformanceEvent,
  setHoneycombSampleRate,
} from './honeycombApi'

const shouldSend = (sampleRate: number) => {
  const r = Math.random()
  const threshold = 1 / sampleRate
  return r <= threshold
}

class DocLoadTimer {
  public t1SetupProvider: number | null = null

  public t2DownloadDoc: number | null = null

  public t3OnCreate: number | null = null

  public setupProvider() {
    if (this.t1SetupProvider) {
      return
    }
    this.t1SetupProvider = performance.now()
  }

  public downloadDoc() {
    if (this.t2DownloadDoc) {
      return
    }
    this.t2DownloadDoc = performance.now()
  }

  public onCreate() {
    if (this.t3OnCreate) {
      return
    }
    this.t3OnCreate = performance.now()
  }

  public report() {
    if (
      this.t1SetupProvider === null ||
      this.t2DownloadDoc === null ||
      this.t3OnCreate === null
    ) {
      return null
    }
    return {
      downloadDoc: this.t2DownloadDoc - this.t1SetupProvider,
      onCreate: this.t3OnCreate - this.t2DownloadDoc,
    }
  }
}

/**
 * Measure keydown performance as the first capture phase `keydown` event
 * as **start** and an animation frame as the **end**
 */
export const useMeasureKeydownPerformance = (
  enabled: boolean,
  sampleRate: number
) => {
  const editable = useAppSelector(selectContentEditable)
  const editableRef = useRef<boolean>(editable)
  useEffect(() => {
    editableRef.current = editable
  }, [editable])

  useEffect(() => {
    if (!enabled) {
      return
    }
    function keydownCb(event: KeyboardEvent) {
      const isOnProsemirror = (event.target as HTMLElement).closest(
        '.ProseMirror'
      )

      if (!isOnProsemirror || !editableRef.current) {
        return
      }
      if (!shouldSend(sampleRate)) {
        return
      }
      const start = performance.now()
      requestAnimationFrame(() => {
        let key = event.key
        if (key === ' ') {
          key = 'Space'
        }
        const duration = performance.now() - start

        sendKeydownPerformanceEvent({
          ...getBaseDocData(),
          key,
          duration,
        })
      })
    }
    window.addEventListener('keydown', keydownCb, true)

    return () => {
      window.removeEventListener('keydown', keydownCb, true)
    }
  }, [enabled, editableRef, sampleRate])
}

export const useHoneycombSampleRate = () => {
  const sampleRate = useFeatureFlag('rumSampleRate')
  useEffect(() => {
    setHoneycombSampleRate(sampleRate)
  }, [sampleRate])
}

export const useMeasureModeChangePerformance = (enabled: boolean) => {
  const recordEnterSlideModePerformance = useCallback(
    ({ isFullscreen = false }: { isFullscreen: boolean }) => {
      if (!enabled) {
        return
      }
      const start = performance.now()
      // use nested requestAnimationFrame because we want to measure
      // when the document is editable / uneditable which itself is
      // using requestAnimationFrame
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          const duration = performance.now() - start
          sendModeChangePerformanceEvent({
            ...getBaseDocData(),
            toMode: EditorModeEnum.SLIDE_VIEW,
            duration,
            isFullscreen,
          })
        })
      })
    },
    [enabled]
  )
  const recordExitSlideModePerformance = useCallback(
    ({ isFullscreen = false }: { isFullscreen: boolean }) => {
      if (!enabled) {
        return
      }
      const start = performance.now()
      // use nested requestAnimationFrame because we want to measure
      // when the document is editable / uneditable which itself is
      // using requestAnimationFrame
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          const duration = performance.now() - start
          sendModeChangePerformanceEvent({
            ...getBaseDocData(),
            toMode: EditorModeEnum.DOC_VIEW,
            duration,
            isFullscreen,
          })
        })
      })
    },
    [enabled]
  )
  return { recordExitSlideModePerformance, recordEnterSlideModePerformance }
}

export const useDocLoadTimer = () => {
  const [timer] = useState(() => new DocLoadTimer())
  const timeOnSetupProvider = useCallback(() => {
    timer.setupProvider()
  }, [timer])

  const timeOnDownloadDoc = useCallback(() => {
    timer.downloadDoc()
  }, [timer])

  const timeOnCreate = useCallback(() => {
    timer.onCreate()
    const report = timer.report()
    if (report === null) {
      console.warn(`[HoneycombDocLoadTimer] failed to generate doc timings`)
      return
    }

    sendEditorRenderEvent({
      ...getBaseDocData(),
      duration: report.onCreate,
    })
    sendDownloadDocEvent({
      ...getBaseDocData(),
      duration: report.downloadDoc,
    })
  }, [timer])

  return {
    timeOnCreate,
    timeOnSetupProvider,
    timeOnDownloadDoc,
  }
}
