import { gql } from '@apollo/client'

import { DEFAULT_THEME_FIELDS_FRAGMENT } from 'modules/theming/graphql'

export const SEARCH_MENTION_GQL = gql`
  query SearchMention($workspaceId: ID!, $query: String!) {
    search(orgId: $workspaceId, query: $query, types: [user, doc]) {
      __typename
      ... on DocResult {
        id
        title
        text
        archived
        previewUrl
        createdBy {
          id
          displayName
          profileImageUrl
        }
        organization {
          id
        }
        editors {
          user {
            id
            displayName
            profileImageUrl
          }
          lastViewed
          lastEdited
        }
        docUser {
          lastViewed
          lastEdited
          favorited
        }
        orgAccess
        publicAccess
        updatedTime
        createdTime
        editedTime
      }
      ... on User {
        id
        displayName
        email
        profileImageUrl
      }
    }
  }
`

const REACTION_FIELDS_FRAGMENT = gql`
  fragment ReactionFields on Reaction {
    emoji
    count
    targetId
    targetType
    docId
    users {
      id
      displayName
      profileImageUrl
    }
  }
`

export const COMMENT_AUTHOR_FIELDS_FRAGMENT = gql`
  fragment CommentAuthorFields on User {
    id
    displayName
    profileImageUrl
    email
  }
`

export const REPLY_FIELDS_FRAGMENT = gql`
  ${REACTION_FIELDS_FRAGMENT}
  ${COMMENT_AUTHOR_FIELDS_FRAGMENT}
  fragment ReplyFields on Comment {
    id
    commentId
    content
    createdTime
    updatedTime
    archived
    user {
      ...CommentAuthorFields
    }
    reactions {
      ...ReactionFields
    }
  }
`

export const COMMENTS_FRAGMENT = gql`
  ${REPLY_FIELDS_FRAGMENT}
  ${REACTION_FIELDS_FRAGMENT}
  ${COMMENT_AUTHOR_FIELDS_FRAGMENT}
  fragment CommentFields on Comment {
    id
    docId
    cardId
    content
    targetText
    targetHtml
    createdTime
    updatedTime
    archived
    targetId
    status
    user {
      ...CommentAuthorFields
    }
    replies {
      ...ReplyFields
    }
    reactions {
      ...ReactionFields
    }
  }
`

const DOC_COLLABORATORS_INVITATIONS_FRAGMENT = gql`
  fragment DocCollaboratorFields on DocCollaborator {
    docId
    user {
      id
      displayName
      email
      profileImageUrl
    }
    permission
    guest
    accessLinkId
  }

  fragment DocInvitationsFields on DocInvitation {
    id
    docId
    email
    permission
  }
`

const DOC_CHANNEL_FRAGMENT = gql`
  fragment DocChannelFields on DocChannel {
    id
    name
    slug
    permission
  }
`

const DOC_EDITORS_FRAGMENT = gql`
  fragment DocEditorsFields on DocEditor {
    user {
      id
      displayName
      email
      profileImageUrl
    }
    lastEdited
  }
`

export const ADD_COLLABORATORS_GQL = gql`
  ${DOC_COLLABORATORS_INVITATIONS_FRAGMENT}
  mutation AddCollaborators(
    $docId: ID!
    $collaborators: [DocCollaboratorInput!]!
  ) {
    addCollaborators(docId: $docId, collaborators: $collaborators) {
      id
      collaborators {
        ...DocCollaboratorFields
      }
      invitations {
        ...DocInvitationsFields
      }
    }
  }
`

export const REMOVE_COLLABORATORS_GQL = gql`
  ${DOC_COLLABORATORS_INVITATIONS_FRAGMENT}
  mutation RemoveCollaborators(
    $docId: ID!
    $collaborators: [DocCollaboratorRemovalInput!]!
  ) {
    removeCollaborators(docId: $docId, collaborators: $collaborators) {
      id
      collaborators {
        ...DocCollaboratorFields
      }
      invitations {
        ...DocInvitationsFields
      }
    }
  }
`

export const ADD_COLLABORATOR_BY_ACCESS_LINK_GQL = gql`
  ${DOC_COLLABORATORS_INVITATIONS_FRAGMENT}
  mutation AddCollaboratorByAccessLink($docId: ID!) {
    addCollaboratorByAccessLink(docId: $docId) {
      id
      collaborators {
        ...DocCollaboratorFields
      }
    }
  }
`

export const GET_DOC_GQL = gql`
  ${COMMENTS_FRAGMENT}
  ${DOC_COLLABORATORS_INVITATIONS_FRAGMENT}
  ${DOC_EDITORS_FRAGMENT}
  ${DOC_CHANNEL_FRAGMENT}
  ${DEFAULT_THEME_FIELDS_FRAGMENT}
  ${REACTION_FIELDS_FRAGMENT}
  query GetDoc($id: ID!) {
    doc(id: $id) {
      id
      title
      titleEdited
      archived
      createdTime
      updatedTime
      editedTime
      savedTime
      currentSnapshotId
      publicAccess
      comments {
        ...CommentFields
      }
      reactions {
        ...ReactionFields
      }
      accessLinks {
        id
        docId
        permission
        token
        url
      }
      orgAccess
      organization {
        id
        name
      }
      theme {
        ...DefaultThemeFields
      }
      collaborators {
        ...DocCollaboratorFields
      }
      invitations {
        ...DocInvitationsFields
      }
      editors {
        ...DocEditorsFields
      }
      createdBy {
        displayName
        id
        email
      }
      channels {
        ...DocChannelFields
      }
    }
  }
`

export const GET_DOC_ROBOTS_GQL = gql`
  query GetDocRobots($id: ID!) {
    doc(id: $id) {
      id
      title
      createdTime
      updatedTime
      currentSnapshot {
        id
        docId
        content
      }
      titleCard {
        previewUrl
      }
      organization {
        id
        name
      }
      createdBy {
        displayName
        id
        email
      }
    }
  }
`

export const DOC_SUBSCRIPTION_GQL = gql`
  ${COMMENTS_FRAGMENT}
  ${DOC_COLLABORATORS_INVITATIONS_FRAGMENT}
  ${DOC_EDITORS_FRAGMENT}
  ${DOC_CHANNEL_FRAGMENT}
  ${DEFAULT_THEME_FIELDS_FRAGMENT}
  ${REACTION_FIELDS_FRAGMENT}
  subscription SubscribeToDoc($id: ID!) {
    doc(id: $id) {
      ... on DocSubscription {
        id
        title
        titleEdited
        archived
        updatedTime
        editedTime
        savedTime
        accessLinks {
          id
          docId
          permission
          token
          url
        }
        channels {
          ...DocChannelFields
        }
        collaborators {
          ...DocCollaboratorFields
        }
        editors {
          ...DocEditorsFields
        }
        invitations {
          ...DocInvitationsFields
        }
        theme {
          ...DefaultThemeFields
        }
        reactions {
          ...ReactionFields
        }
        orgAccess
      }

      ... on DocCommentsSubscription {
        id
        comments {
          ...CommentFields
        }
      }
      ... on DocReactionsSubscription {
        id
        reactions {
          ...ReactionFields
        }
      }
    }
  }
`

export const UPDATE_DOC_TITLE_GQL = gql`
  mutation UpdateDocTitle($id: ID!, $title: String, $titleEdited: Boolean) {
    updateDoc(input: { id: $id, title: $title, titleEdited: $titleEdited }) {
      id
      title
      titleEdited
    }
  }
`

export const UPDATE_DOC_ORG_ACCESS_GQL = gql`
  mutation UpdateDocOrgAccess($id: ID!, $orgAccess: Permission) {
    updateDoc(input: { id: $id, orgAccess: $orgAccess }) {
      id
      orgAccess
    }
  }
`

export const UPDATE_DOC_PUBLIC_ACCESS_GQL = gql`
  mutation UpdateDocPublicAccess($id: ID!, $publicAccess: Permission) {
    updateDoc(input: { id: $id, publicAccess: $publicAccess }) {
      id
      publicAccess
    }
  }
`

export const UPDATE_DOC_ACCESS_LINKS_GQL = gql`
  mutation UpdateDocAccessLinks($id: ID!, $accessLinks: [AccessLinkInput!]) {
    updateDoc(input: { id: $id, accessLinks: $accessLinks }) {
      id
      accessLinks {
        permission
        id
      }
    }
  }
`

export const UPDATE_DOC_THEME_GQL = gql`
  ${DEFAULT_THEME_FIELDS_FRAGMENT}
  mutation UpdateDocTheme($id: ID!, $themeId: String) {
    updateDoc(input: { id: $id, themeId: $themeId }) {
      id
      theme {
        ...DefaultThemeFields
      }
    }
  }
`

export const SEND_INVITE_GQL = gql`
  mutation InviteMembers($workspaceId: ID!, $invitees: [InvitationInput!]!) {
    inviteMembers(orgId: $workspaceId, invitees: $invitees) {
      id
      name
      invitations {
        email
      }
    }
  }
`

export const CREATE_COMMENT_GQL = gql`
  ${REPLY_FIELDS_FRAGMENT}
  ${REACTION_FIELDS_FRAGMENT}
  ${COMMENT_AUTHOR_FIELDS_FRAGMENT}
  mutation CreateComment($input: CreateCommentInput) {
    createComment(input: $input) {
      id
      docId
      cardId
      content
      targetText
      targetHtml
      createdTime
      updatedTime
      commentId
      archived
      targetId
      status
      user {
        ...CommentAuthorFields
      }
      replies {
        ...ReplyFields
      }
      reactions {
        ...ReactionFields
      }
    }
  }
`

export const UPDATE_COMMENT_GQL = gql`
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
      docId
      content
      updatedTime
    }
  }
`

export const UPDATE_COMMENT_STATUS_GQL = gql`
  mutation UpdateCommentStatus($input: UpdateCommentStatusInput!) {
    updateCommentStatus(input: $input) {
      id
      status
      updatedTime
    }
  }
`

export const REMOVE_COMMENT_GQL = gql`
  mutation RemoveComment($id: ID!) {
    archiveComment(id: $id) {
      id
      archived
    }
  }
`
export const DOC_REACTIONS_FRAGMENT = gql`
  ${REACTION_FIELDS_FRAGMENT}
  fragment DocReactionsCreate on Doc {
    reactions {
      ...ReactionFields
    }
  }
`

export const DOC_COMMENTS_FRAGMENTS = gql`
  ${REPLY_FIELDS_FRAGMENT}
  ${COMMENT_AUTHOR_FIELDS_FRAGMENT}
  fragment DocCommentsCreate on Doc {
    comments {
      id
      docId
      cardId
      content
      targetText
      targetHtml
      createdTime
      updatedTime
      archived
      targetId
      status
      user {
        ...CommentAuthorFields
      }
    }
  }
  fragment DocRepliesCreate on Doc {
    comments {
      id
      replies {
        ...ReplyFields
      }
    }
  }
  fragment DocCommentsUpdate on Doc {
    comments {
      id
      docId
      content
      updatedTime
      reactions {
        ...ReactionFields
      }
    }
  }
  fragment DocRepliesUpdate on Doc {
    comments {
      id
      replies {
        id
        docId
        content
        updatedTime
        reactions {
          ...ReactionFields
        }
      }
    }
  }
  fragment DocCommentsRemove on Doc {
    comments {
      id
      archived
    }
  }
  fragment DocRepliesRemove on Doc {
    comments {
      id
      replies {
        id
        archived
      }
    }
  }
`

export const ADD_COMMENT_REACTION_GQL = gql`
  ${REACTION_FIELDS_FRAGMENT}
  mutation AddReaction($reactionInput: AddReactionInput!) {
    addReaction(input: $reactionInput) {
      ...ReactionFields
    }
  }
`

export const REMOVE_COMMENT_REACTION_GQL = gql`
  ${REACTION_FIELDS_FRAGMENT}
  mutation RemoveReaction($reactionInput: RemoveReactionInput!) {
    removeReaction(input: $reactionInput) {
      ...ReactionFields
    }
  }
`

export const GET_MEMO_STATS_GQL = gql`
  query GetMemoAnalytics($memoId: ID!) {
    memoStats(id: $memoId) {
      memoId
      uniqueViewers
      uniqueEditors
      cardCount
      lastOpened
      viewers {
        user {
          id
          displayName
          email
          profileImageUrl
        }
        visitorId
        lastOpened
        cardsViewed
      }
      dailyViews {
        dayCount
        timezone
        everyone {
          date
          uniqueVisitors
        }
        viewers {
          date
          uniqueVisitors
        }
      }
    }
  }

  query GetDocCardsAnalytics($docId: ID!, $userId: ID) {
    docCardsStats(docId: $docId, userId: $userId) {
      docId
      uniqueVisitors
      uniqueEditors
      cardCount
      everyone {
        cardId
        viewTimePercent
        viewersPercent
      }
      viewers {
        cardId
        viewTimePercent
        viewersPercent
      }
    }
  }
`
