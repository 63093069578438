/**
 * NB: This is a pared-down version of @tiptap/extension-mention.
 * Original source here: https://github.com/ueberdosis/tiptap/blob/ab4a0e2507b4b92c46d293a0bb06bb00a04af6e0/packages/extension-mention/src/mention.ts
 *
 * The only difference is that this version is totally decoupled from @tiptap/extension-suggestion.
 */

import { Kbd, Portal, Text } from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { GammaTooltip, ScreenshotPreview } from '@gamma-app/ui'
import { mergeAttributes, Node, NodeViewProps, Range } from '@tiptap/core'
import React, { useCallback, useEffect } from 'react'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { useGetCard } from 'modules/cards'
import { useFeatureFlag } from 'modules/featureFlags'
import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from 'modules/tiptap_editor/react'
import { useLightPopover } from 'utils/hooks'
import { getCardUrl } from 'utils/url'

import { ExtensionPriorityMap } from '../constants'
import { navigateToCardLink } from '../Link'
import { MENTION_SUGGESTION_CHARACTER } from '../MentionSuggestionMenu'
import { attrsOrDecorationsChanged } from '../updateFns'
import { getMentionPasteRule } from './helpers'
import { MentionTag } from './MentionTag'

export const CARD_TITLE_FALLBACK = 'Untitled'
const CARD_TITLE_DELETED = 'Cannot find referenced card'

const CardMentionNodeView = ({ node, editor }: NodeViewProps) => {
  const { id } = node.attrs as CardMentionArgs
  const screenshotsEnabled = useFeatureFlag('screenshotsEnabled')
  const cardData = useGetCard(id)
  const cardExistsInMemo = cardData?.archived === false
  const title = cardData?.title
  const thumbnailImageUrl = {
    src: screenshotsEnabled ? cardData?.previewUrl : placeholderBackground.src,
    fallbackSrc: placeholderBackground.src,
  }

  useEffect(() => {
    // This is defined in the cardMention extension's arbitrary `addStorage` POJO
    // We're keeping a map of cardId to title such that we have that information handy
    // when we need to call `getHTML()` or `getText()` (faciliated by the renderHTML
    // and renderText methods)
    editor.storage[CardMention.name][id] = title
  }, [title, editor.storage, id])

  const handleNavigateToCard = useCallback(
    (event: React.MouseEvent) => {
      navigateToCardLink(editor, event, id)
    },
    [editor, id]
  )

  const {
    popperRef,
    referenceRef,
    isHovering,
    onMouseOver,
    onMouseOut,
    getPopperProps,
  } = useLightPopover()

  const cardUrl = getCardUrl({ cardId: id, docId: cardData?.docId })
  return (
    <NodeViewWrapper as="span">
      {cardExistsInMemo ? (
        <MentionTag
          data-testid="card-mention"
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          ref={referenceRef}
          mentionLabel={title || CARD_TITLE_FALLBACK}
          mentionIcon={regular('rectangle')}
          mentionTargetUrl={cardUrl}
        />
      ) : (
        <GammaTooltip
          label={
            <Text>
              Please use the{' '}
              <Kbd fontSize="sm" color="blackAlpha.800">
                @
              </Kbd>{' '}
              key to recreate the card mention.
            </Text>
          }
        >
          <MentionTag
            data-testid="card-mention"
            hasError
            mentionLabel={CARD_TITLE_DELETED}
            mentionIcon={solid('rectangle-xmark')}
          />
        </GammaTooltip>
      )}

      {isHovering && (
        <Portal>
          <ScreenshotPreview
            shouldShow={cardExistsInMemo}
            src={thumbnailImageUrl?.src}
            fallbackSrc={thumbnailImageUrl?.fallbackSrc}
            onClick={handleNavigateToCard}
            ref={popperRef}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            {...getPopperProps()}
            data-target-name="card-mention-popup"
            minH="158px"
          />
        </Portal>
      )}
    </NodeViewWrapper>
  )
}

export type CardMentionOptions = {
  HTMLAttributes: Record<string, any>
  suggestion: { char: string }
}

type CardMentionArgs = {
  id: string
  docId: string
  mentionedById?: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    cardMention: {
      addCardMention: (args: CardMentionArgs, range: Range) => ReturnType
    }
  }
}

export const CardMention = Node.create<CardMentionOptions>({
  name: 'cardMention',

  addStorage() {
    // We'll use this to build an object where the keys are docIds, values are docTitles.
    // We need these to construct HTML and text strings for the clipboard.
    return {}
  },

  addOptions() {
    return {
      HTMLAttributes: {},
      suggestion: {
        // We are storing this in an option because it's better than hardcoding the character
        // into the Backspace command (see below)
        char: MENTION_SUGGESTION_CHARACTER,
      },
    }
  },

  priority: ExtensionPriorityMap.CardMention,
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },

      docId: {
        default: null,
      },

      mentionedById: {
        default: null,
      },
    }
  },

  addPasteRules() {
    return [
      getMentionPasteRule({
        // Only capture mentions that are for doc urls in this doc,
        // as that is the definition of what a CardMention is.
        // Mentions to cards in other docs are not yet supported.
        filterFn: (docId: string | null, cardId: string | null) =>
          docId === this.editor.gammaDocId && Boolean(cardId),
        getAttributesFn: ({ docId, cardId }) => ({ id: cardId, docId }),
      })(this.type),
    ]
  },

  addCommands() {
    return {
      addCardMention:
        ({ id, docId, mentionedById }: CardMentionArgs, range) =>
        ({ state, chain }) => {
          const nodeAfter = state.selection.$to.nodeAfter
          const overrideSpace = nodeAfter?.text?.startsWith(' ')

          if (overrideSpace) {
            range.to += 1
          }
          chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: { id, docId, mentionedById },
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run()

          return true
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: `a[data-type="${this.name}"]`,
        // Rules without a priority are counted as having a priority of 50. Rules with a higher
        // priority come first! This makes it so that any content with `data-type="docMention"`
        // will be handled here first before they are handled by, say, the Link extension.
        // See https://prosemirror.net/docs/ref/#model.ParseRule.priority
        // and https://github.com/ProseMirror/prosemirror-model/blob/b8c5166e9ac5c5cf87da3f13012b0044fd8a4bd9/src/from_dom.js#L240
        priority: 51,
      },
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    // We get the docTitle from this.storage, which is asynchronously updated as the
    // title updates. Fallback to CARD_TITLE_FALLBACK' if we don't have the title yet,
    // or if the user doesn't have access to the doc
    const { id, docId } = node.attrs
    const titleOrHole = this.storage[id] || CARD_TITLE_FALLBACK
    return [
      'a',
      mergeAttributes(
        {
          'data-type': this.name,
          href: getCardUrl({ docId, cardId: id }),
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      // Based on https://github.com/ueberdosis/tiptap/blob/ab4a0e2507b4b92c46d293a0bb06bb00a04af6e0/packages/extension-mention/src/mention.ts#L117-L120
      titleOrHole,
    ]
  },

  renderText({ node }) {
    // Fallback to CARD_TITLE_FALLBACK' if we don't have the title yet
    const { id, docId } = node.attrs
    const cardTitle = this.storage[id] || CARD_TITLE_FALLBACK
    const docUrl = getCardUrl({ docId, cardId: id })
    return `[${cardTitle}](${docUrl})`
  },

  addNodeView() {
    return ReactNodeViewRenderer(CardMentionNodeView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
