import TiptapHeading from '@tiptap/extension-heading'
import { NodeViewProps } from '@tiptap/react'

import { NodeViewContent, ReactNodeViewRenderer } from '../../react'
import { disallowParentsFromInputRule } from '../../utils/inputRules'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { attrsOrDecorationsChanged } from '../updateFns'

export const HeadingView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps
  const { level, horizontalAlign } = node.attrs
  const asEl = `h${level}` as React.ElementType
  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <NodeViewContent
        as={asEl}
        level={level}
        style={{ textAlign: horizontalAlign }}
        placeholder="Start typing..."
      ></NodeViewContent>
    </AnnotatableNodeViewWrapper>
  )
}

export const Heading = TiptapHeading.extend({
  selectable: false,

  addAttributes() {
    return {
      level: {
        default: 1,
        rendered: true, // Override Tiptap default behavior of hiding this
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(HeadingView, {
      update: attrsOrDecorationsChanged,
    })
  },

  addInputRules() {
    return this.options.levels.map((level) => {
      return disallowParentsFromInputRule(
        {
          find: new RegExp(`^(#{1,${level}})\\s$`),
          type: this.type,
          getAttributes: {
            level,
          },
        },
        [
          this.editor.state.schema.nodes.bullet,
          this.editor.state.schema.nodes.numbered,
          this.editor.state.schema.nodes.todo,
        ]
      )
    })
  },
}).configure({
  levels: [1, 2, 3],
})
