import { Button, Heading, Image, Text, VStack } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { nanoid } from 'nanoid'
import { useCallback, useRef } from 'react'

import { MediaPlaceholderImage } from 'modules/tiptap_editor/extensions/media/Placeholder/MediaPlaceholderImage'
import {
  getUploadedImageAttrs,
  handleImageUploadFailed,
  handleImageUploadSuccess,
} from 'modules/tiptap_editor/extensions/media/Upload'
import { dataURLtoFile, isHEICFileType } from 'utils/image'

import { useAppSelector } from '../../redux'
import { selectDocOrgId } from '../../tiptap_editor/reducer'
import { uploadFile, uploadFileFromUrl } from '../apis/transloadit'
import { OnUploadStartParams, UploadBox } from '../components/UploadBox'
import { URLFetcher } from '../components/URLFetcher'
import { ImageUploadResult, UploadStatus } from '../types/ImageUpload'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'

const CustomImagePanel = ({
  editor,
  editType,
  updateAttributes,
  currentAttributes,
  resetToPlaceholder,
}: MediaProviderPanelProps) => {
  const orgId = useAppSelector(selectDocOrgId)
  const uploadingTempUrl = useRef<string | null>(null)

  // sets attributes on Image node to be a placeholder image
  const setPlaceholderTempImage = (tempUrl: string | null = null) => {
    updateAttributes({
      uploadStatus: UploadStatus.Uploading,
      showPlaceholder: true,
      tempUrl,
      src: null,
    })
  }

  // sets attributes on Image node to be a temp image with local tempUrl
  const setTempUrl = (tempUrl: string) => {
    updateAttributes({
      uploadStatus: UploadStatus.Uploading,
      showPlaceholder: false,
      tempUrl,
      src: null,
    })
    uploadingTempUrl.current = tempUrl
  }

  // set attributes for uploaded / transcoded image
  const setUploadedImage = (image: ImageUploadResult, previousUrl?: string) => {
    const tempUrl = uploadingTempUrl.current
    const existingUrl = tempUrl || previousUrl
    if (!existingUrl) return

    if (editType === 'background' || !editor) {
      updateAttributes(
        getUploadedImageAttrs({
          result: image,
          isError: false,
        })
      )
      return
    }

    // If you copy/paste after closing media drawer, this node might have been moved or duplicated
    // so we need to mimic insertFiles.ts and find all matching images
    handleImageUploadSuccess(editor, existingUrl, image)

    uploadingTempUrl.current = null
  }

  const setErroredUpload = (error?: string) => {
    if (!uploadingTempUrl.current || !editor) {
      updateAttributes(
        getUploadedImageAttrs({
          isError: true,
        })
      )
      return
    }
    handleImageUploadFailed(editor, uploadingTempUrl.current, error)
  }

  const saveImageFromDataBlob = (dataBlob: string) => {
    if (!orgId) {
      console.error('[CustomImagePanel] saveImageFromDataBlob - No orgId!')
      return
    }

    setTempUrl(dataBlob)

    try {
      const file = dataURLtoFile(dataBlob, 'image')
      uploadFile(
        file,
        orgId,
        {
          onUploadComplete: setUploadedImage,
          onOriginalFileUpload: setUploadedImage,
          onUploadFailed: setErroredUpload,
        },
        editType
      )
    } catch (e) {
      setErroredUpload()
    }
  }

  const saveImageFromUrl = (remoteUrl: string) => {
    if (!orgId) {
      console.error('[CustomImagePanel] saveImageFromUrl - No orgId!')
      return
    }

    setTempUrl(remoteUrl)

    try {
      uploadFileFromUrl(
        remoteUrl,
        orgId,
        {
          onUploadComplete: setUploadedImage,
          onOriginalFileUpload: setUploadedImage,
          onUploadFailed: setErroredUpload,
        },
        editType
      )
    } catch (e) {
      setErroredUpload()
    }
  }

  const onUploadStart = useCallback(
    ({ file, tempUrl }: OnUploadStartParams) => {
      if (file.type && isHEICFileType(file.type)) {
        const tempHEICUrl = `HEIC_tempId_${nanoid()}`
        setPlaceholderTempImage(tempHEICUrl)
        setTempUrl(tempHEICUrl)
      } else {
        // is not HEIC file, can display temp url
        setTempUrl(tempUrl)
      }
    },
    [setPlaceholderTempImage, setTempUrl]
  )

  const setUploadFailed = useCallback(() => {
    updateAttributes(getUploadedImageAttrs({ isError: true }))
  }, [updateAttributes])

  const { src, tempUrl, meta, name, showPlaceholder, uploadStatus } =
    currentAttributes
  const hasCustomImage = Boolean(src || tempUrl || showPlaceholder)

  return hasCustomImage ? (
    <VStack align="flex-start" spacing={4}>
      {showPlaceholder ? (
        <MediaPlaceholderImage
          isLoading={uploadStatus === UploadStatus.Uploading}
          hasUploadError={uploadStatus === UploadStatus.Error}
          width={'100%'}
        />
      ) : (
        <Image
          src={src || tempUrl}
          alt=""
          ignoreFallback
          borderRadius="md"
        ></Image>
      )}
      {meta && meta.width && meta.height && (
        <Text>
          {meta.width}×{meta.height}px
        </Text>
      )}
      {name && <Text>{name}</Text>}
      <Button onClick={resetToPlaceholder} mt={2} w="100%">
        Replace Image
      </Button>
    </VStack>
  ) : (
    <VStack align="flex-start" spacing={4}>
      <Heading size="md">URL</Heading>
      <URLFetcher
        currentUrl=""
        placeholder="Paste or enter image URL"
        handleSubmit={saveImageFromUrl}
        handleDataSubmit={saveImageFromDataBlob}
        noHeader
      />
      <Heading size="md">Upload</Heading>
      {orgId && (
        <UploadBox
          onUploadSuccess={setUploadedImage}
          onUploadStart={onUploadStart}
          onUploadFailed={setUploadFailed}
          orgId={orgId}
          uploadType="image"
          editType={editType}
        />
      )}
    </VStack>
  )
}

export const CustomImageProvider: MediaProviderType = {
  label: 'Image upload or URL',
  key: 'image.custom',
  description: 'Tip: you can also drag or paste images directly into a card',
  icon: duotone('image'),
  Panel: CustomImagePanel,
}
