// https://iframely.com/keys

import { LinkMetadata } from '../types/LinkMetadata'

type Rel = string[]
type Player = {
  // SRC of embed.
  href?: string

  // functional and technical use cases.
  rel?: Rel

  // link’s MIME type, says "embed as iFrame".
  type?: string

  media?: {
    // Media query, e.g. aspects
    'aspect-ratio': number // 1.777778
    height?: number // 166,        // Exact dimensions here.
    width?: number //  295
  }

  // plus generated HTML code for simplicity of use:
  html?: string
}
type Thumbnail = {
  href: string // "http://cdn1.aka ... med_1381670134_00040.jpg",
  media?: {
    height?: number // 166,        // Exact dimensions here.
    width?: number //  295
  }
  rel?: Rel // ["thumbnail"],   // We repeat the same rel for consistency
  type?: string // "image",        // "use href as src of an image"
  content_length?: number // 12682  Image size in bytes
}

// Handmade loose typings based on example response: https://iframely.com/docs/iframely-api#api-response
type IframelyJSONResponse = {
  error?: Record<string, any>

  id: string
  // URL Content ID, if available
  // plus canonical URL
  url: string

  // rel use cases and html code for primary variant of embed,
  rel?: Rel // ["player", "ssl"], // check for `autoplay` if you requested it
  html?: string // that's the embed code we recommend

  // meta object with attribution & semantics
  meta?: {
    title: string // "Input/Output",
    description?: string // "A new short from Terri Timely and Park Pictures",
    author_url?: string // "https://vimeo.com/user1946955",
    author?: string // "Terri Timely",
    site?: string // "Vimeo",
    canonical?: string // "https://vimeo.com/141567420",
    duration?: number // 249,
    date?: string // "2015-10-06",
    medium?: string //  "video"
  }

  // Plus list of all media that we have for this URL:
  // embed src links with functional rels. For example,
  links: {
    player?: Player[] // List of player embed widgets
    thumbnail?: Thumbnail[]
    app?: Player[]
    image?: Rel
    reader?: Player[]
    survey?: Player[]
    file?: Player[]
    logo?: string
    icon?: Record<string, any>[]
  }
}

// This key is scoped to localhost:3000 or *.gamma.app so safe to include here
const IFRAMELY_HASHED_KEY = 'a68bac8b6624d46b6d0ba46e5b3f8971'

export const resetLinkMetadata = {
  thumbnail: undefined,
  embed: undefined,
  meta: undefined,
  sourceUrl: undefined,
}

export const fetchIframelyJSON = async (url: string): Promise<LinkMetadata> => {
  // https://iframely.com/docs/parameters
  // key is tied to our domain. Note: for local development you must be on localhost:3000
  // iframe=1 enables iframe helpers, required for Twitter: https://iframely.com/docs/iframes
  // omit_script=1 because we load the script tag in src/pages/_document.tsx. https://iframely.com/docs/omit-script
  const response = await fetch(
    `https://cdn.iframe.ly/api/iframely/?url=${encodeURIComponent(
      url
    )}&key=${IFRAMELY_HASHED_KEY}&iframe=1&omit_script=1`
  )
  const data = (await response.json()) as IframelyJSONResponse
  console.debug('[Iframely]', data)

  if (data.error) {
    return Promise.reject(data)
  }

  const { html, links, meta } = data
  const { icon, player, reader, app, thumbnail } = links

  const embeds = player || reader || app
  // Prefer embeds with type text/html, but other types might be valid too
  const preferredEmbed = embeds
    ? embeds.filter((e) => e.type === 'text/html' && e.href)[0] || embeds[0]
    : null

  return {
    sourceUrl: meta?.canonical || url,
    embed: preferredEmbed
      ? {
          url: preferredEmbed.href,
          html: html,
          height: preferredEmbed.media?.height,
          width:
            preferredEmbed.media?.width || preferredEmbed.media?.['max-width'],
          aspectRatio: preferredEmbed.media?.['aspect-ratio'],
        }
      : undefined,
    meta: { ...(meta || {}), icon: icon && icon[0] ? icon[0].href : undefined },
    thumbnail:
      thumbnail && thumbnail[0]
        ? {
            src: thumbnail[0].href,
            height: thumbnail[0].media?.height,
            width: thumbnail[0].media?.width,
          }
        : undefined,
  }
}
