import { Editor, findChildren, findParentNodeClosestToPos } from '@tiptap/core'
import { Node, Node as ProsemirrorNode } from 'prosemirror-model'
import { Selection } from 'prosemirror-state'

import { findParentNodes } from '../../utils'
import { CARD_NODE_NAME, CARD_WIDTHS } from './constants'

export const isCardNode = (node: ProsemirrorNode) =>
  node.type.name === CARD_NODE_NAME

export const findCardById = (editor: Editor, cardId?: string | null) => {
  if (!cardId) return null
  const result = findChildren(
    editor.state.doc,
    (n) => isCardNode(n) && n.attrs.id === cardId
  )
  return result.length ? result[0] : null
}

// Find the card node nearest the pos (inclusive of pos)
// The isCollapsed filter is optional
export const findCardNodeClosestToPos = (editor: Editor, pos: number) => {
  const nodeAtPos = editor.state.doc.nodeAt(pos)
  const $pos = editor.state.doc.resolve(pos)
  return nodeAtPos && isCardNode(nodeAtPos)
    ? { node: nodeAtPos, pos, start: $pos.start, depth: $pos.depth }
    : findParentNodeClosestToPos(editor.state.doc.resolve(pos), isCardNode)
}

export const getClosestParentContainerOption = (parents: Node[], key: string) =>
  parents.find((n) => n.attrs.container[key] !== undefined)?.attrs.container[
    key
  ]

export const isParentCardDark = (selection: Selection) => {
  const parentCards = findParentNodes(selection.$from, isCardNode).map(
    (c) => c.node
  )
  return getClosestParentContainerOption(parentCards, 'isDark')
}

export const getParentCardWidthPxFromSelection = (selection: Selection) => {
  const parentCards = findParentNodes(selection.$from, isCardNode).map(
    (c) => c.node
  )
  const width = getClosestParentContainerOption(parentCards, 'width')
  return CARD_WIDTHS[width || 'md'] * 16
}
