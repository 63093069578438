import { BoxProps, Box, HStack } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import React, { memo } from 'react'

import {
  YELLOW_BG_GRADIENT,
  YELLOW_HOVER_STYLES,
} from './BlockCommentsStack/BlockCommentsButtons/BlockCommentsButtons'
import { GenericBlockCommentButton } from './BlockCommentsStack/BlockCommentsButtons/GenericBlockCommentButton'

type MobileAddcommentsWidgetProps = {
  onClickAddComment: () => void
  onClickAddReaction: () => void
} & BoxProps

export const MobileAddCommentWidget: React.FC<MobileAddcommentsWidgetProps> =
  memo(({ onClickAddComment, onClickAddReaction, ...rest }) => {
    return (
      <Box
        className="mobile-add-comment-widget"
        pos="absolute"
        transform={'translate(-50%,-50%) scale(1)'}
        mt={-6}
        background="white"
        p="4px"
        borderRadius="full"
        shadow="lg"
        transitionProperty="common"
        transitionDuration="normal"
        transitionTimingFunction="cubic-bezier(0.34, 1.56, 0.64, 1);"
        zIndex={9}
        {...rest}
      >
        <HStack spacing={1}>
          <GenericBlockCommentButton
            key="fresh"
            isMobile={true}
            icon={solid('comment-plus') as IconProp}
            bg="white"
            _hover={YELLOW_HOVER_STYLES}
            color="gray.600"
            bgGradient={YELLOW_BG_GRADIENT}
            onClick={() => {
              onClickAddComment()
            }}
          />
          <GenericBlockCommentButton
            key="add-reaction"
            isMobile={true}
            icon={solid('smile-plus') as IconProp}
            bgGradient={YELLOW_BG_GRADIENT}
            onClick={() => {
              onClickAddReaction()
            }}
            bg="white"
            _hover={YELLOW_HOVER_STYLES}
            color="gray.600"
          />
        </HStack>
      </Box>
    )
  })

MobileAddCommentWidget.displayName = 'MobileAddCommentWidget'
