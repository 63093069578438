import { Extension } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { isNodeEmpty } from '../utils'

// Based on https://discuss.prosemirror.net/t/how-to-input-like-placeholder-behavior/705/16
// and https://github.com/ueberdosis/tiptap/blob/main/packages/extension-placeholder/src/placeholder.ts

export const TextPlaceholder = Extension.create({
  name: 'textPlaceholder',

  addOptions() {
    return {
      emptyNodeClass: 'is-empty',
      showOnlyWhenEditable: true,
      showOnlyCurrent: true,
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: ({ doc, selection }) => {
            const decorations: Decoration[] = []
            const active =
              this.editor.isEditable || !this.options.showOnlyWhenEditable
            const { anchor } = selection
            if (!active) return

            const decorate = (node, pos) => {
              const hasAnchor = anchor >= pos && anchor <= pos + node.nodeSize
              const isEmpty = isNodeEmpty(node)
              if ((hasAnchor || !this.options.showOnlyCurrent) && isEmpty) {
                decorations.push(
                  Decoration.node(pos, pos + node.nodeSize, {
                    class: this.options.emptyNodeClass,
                  })
                )
              }
            }

            doc.descendants(decorate)

            return DecorationSet.create(doc, decorations)
          },
        },
      }),
    ]
  },
})
