import { useDisclosure } from '@chakra-ui/hooks'
import { usePopper, UsePopperReturn } from '@chakra-ui/popper'
import { Button, ButtonProps, useMultiStyleConfig } from '@chakra-ui/react'
import { createContext } from '@chakra-ui/react-utils'
import { chakra, SystemStyleObject } from '@chakra-ui/system'
import { callAllHandlers } from '@chakra-ui/utils'
import {
  ListBox,
  ListBoxList,
  ListBoxItem,
  ListBoxProps,
  ListBoxListProps,
  ListBoxItemProps,
} from '@gamma-app/ui'
import { useMemo } from 'react'

type DropdownContext = {
  isOpen: boolean
  onClose: () => void
  onToggle: () => void
  popper: UsePopperReturn
}

export const [DropdownProvider, useDropdownContext] =
  createContext<DropdownContext>({
    strict: false,
    name: 'DropdownContext',
  })

const [DropdownStylesProvider, useDropdownStyles] = createContext<
  Record<string, SystemStyleObject>
>({
  name: `DropdownStylesContext`,
  errorMessage: `useDropdownStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Dropdown />" `,
})

// We implement our own Dropdown component using our ListBox
// to avoid issues with Chakra focus which cause the editor to blur
// See https://github.com/chakra-ui/chakra-ui/blob/bb99f3c0855455f0ad1343536ace2defcfbf492c/packages/menu/src
export const Dropdown = (props: ListBoxProps) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const styles = useMultiStyleConfig('Dropdown', props)
  const popper = usePopper({
    placement: 'bottom-start',
  })

  const ctx = useMemo(
    () => ({
      isOpen,
      onClose,
      onToggle,
      popper,
    }),
    [isOpen, onClose, onToggle, popper]
  )
  return (
    <DropdownProvider value={ctx}>
      <DropdownStylesProvider value={styles}>
        <ListBox {...props} />
      </DropdownStylesProvider>
    </DropdownProvider>
  )
}

export const DropdownButton = (props: ButtonProps) => {
  const { children, as: As, ...rest } = props
  const { popper, onToggle } = useDropdownContext()
  const Element = As || Button

  return (
    <Element
      {...rest}
      ref={popper.referenceRef}
      onClick={callAllHandlers(props.onClick, onToggle)}
    >
      <chakra.span
        __css={{
          pointerEvents: 'none',
          flex: '1 1 auto',
          justifyContent: 'flex-start',
          display: 'flex',
          minW: 0,
        }}
      >
        {children}
      </chakra.span>
    </Element>
  )
}

export const DropdownList = (props: ListBoxListProps) => {
  const { children, ...rest } = props
  const { isOpen, popper } = useDropdownContext()

  const styles = useDropdownStyles()

  if (!isOpen) return null

  const positionerProps = popper.getPopperProps({
    ...props,
    style: {
      visibility: isOpen ? 'visible' : 'hidden',
      ...props.style,
    },
  })

  return (
    <chakra.div
      {...rest}
      mt={2}
      minWidth="var(--chakra-sizes-48)"
      ref={popper.popperRef}
      {...positionerProps}
      __css={{ zIndex: props.zIndex ?? styles.list?.zIndex }}
    >
      <ListBoxList>{children}</ListBoxList>
    </chakra.div>
  )
}

export const DropdownItem = (props: ListBoxItemProps) => {
  const { onToggle } = useDropdownContext()

  return (
    <ListBoxItem
      {...props}
      onClick={callAllHandlers(props.onClick, onToggle)}
    />
  )
}
