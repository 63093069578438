import { relayStylePagination } from '@apollo/client/utilities'

import { User } from '../generated/graphql'
import {
  mergeArrayWithDelete,
  mergeIfValue,
  replaceIfDefined,
  replaceIfValue,
} from './merge'

export const cacheConfig = {
  typePolicies: {
    Channel: {
      fields: {
        name: {
          merge: mergeIfValue,
        },
        slug: {
          merge: mergeIfValue,
        },
        isMember: {
          merge: mergeIfValue,
        },
        memberCount: {
          merge: mergeIfValue,
        },
        members: {
          // Members (as ChannelMembers) are always returned in full from the API and subscriptions (no merge necessary)
          merge: replaceIfValue,
        },
        createdTime: {
          merge: mergeIfValue,
        },
        updatedTime: {
          merge: mergeIfValue,
        },
        activity: {
          merge: mergeIfValue,
        },
      },
    },
    Comment: {
      // Use mergeIfValue to avoid merging null properties
      // for the primitive fields here, as our subscription
      // doesnt re-fetch all of these for us
      fields: {
        createdTime: {
          merge: mergeIfValue,
        },
        user: {
          merge: mergeIfValue,
        },
        content: {
          merge: mergeIfValue,
        },
        commentId: {
          merge: mergeIfValue,
        },
        updatedTime: {
          merge: mergeIfValue,
        },
        reactions: {
          merge: mergeArrayWithDelete(false, (readField, data): string => {
            return readField<string>('emoji', data) as string
          }),
        },
        replies: {
          merge: mergeArrayWithDelete(true),
        },
        targetId: {
          merge: mergeIfValue,
        },
        targetHtml: {
          merge: mergeIfValue,
        },
        targetText: {
          merge: mergeIfValue,
        },
        status: {
          merge: replaceIfValue,
        },
      },
    },
    Doc: {
      fields: {
        accessLinks: {
          // Access Links are always returned in full from the API and subscriptions (no merge necessary)
          merge: replaceIfValue,
        },
        archived: {
          merge: mergeIfValue,
        },
        cards: { merge: mergeArrayWithDelete(true) },
        channels: {
          // Channels (as DocChannel) are always returned in full from the API and subscriptions (no merge necessary)
          merge: replaceIfValue,
        },
        collaborators: {
          // Collaborators are always returned in full from the API and subscriptions (no merge necessary)
          merge: replaceIfValue,
        },
        comments: {
          merge: mergeArrayWithDelete(true),
        },
        createdBy: {
          merge: mergeIfValue,
        },
        createdTime: {
          merge: mergeIfValue,
        },
        currentSnapshotId: {
          merge: mergeIfValue,
        },
        currentSnapshot: {
          merge: mergeIfValue,
        },
        docUser: {
          // docUser may be null
          merge: replaceIfDefined,
        },
        editedTime: {
          merge: mergeIfValue,
        },
        editors: {
          merge: mergeArrayWithDelete(true, (readField, data): string => {
            const user = readField<User>('user', data)
            return readField('id', user) as string
          }),
        },
        invitations: {
          // Invitations are always returned in full from the API and subscriptions (no merge necessary)
          merge: replaceIfValue,
        },
        orgAccess: {
          // orgAccess may be null
          merge: replaceIfDefined,
        },
        publicAccess: {
          // publicAccess may be null
          merge: replaceIfDefined,
        },
        reactions: {
          merge: mergeArrayWithDelete(false, (readField, data): string => {
            const targetId = readField<string>('targetId', data)
            const emoji = readField<string>('emoji', data)
            return `${emoji}:${targetId}`
          }),
        },
        theme: {
          // Theme content is returned in full from the API and subscriptions (no merge necessary)
          merge: replaceIfValue,
        },
        title: {
          // title may be null on intial load
          merge: replaceIfValue,
        },
        titleCard: {
          merge: mergeIfValue,
        },
        titleEdited: {
          merge: mergeIfValue,
        },
        updatedTime: {
          merge: mergeIfValue,
        },
      },
    },
    Card: {
      fields: {
        title: {
          // title may be null on intial load
          merge: replaceIfValue,
        },
        text: {
          // text may be null on intial load
          merge: replaceIfValue,
        },
        comments: {
          merge: mergeArrayWithDelete(true),
        },
      },
    },
    Theme: {
      fields: {
        name: {
          merge: mergeIfValue,
        },
        // null indicates that we should use the default in themes
        headingFont: {
          merge: replaceIfDefined,
        },
        bodyFont: {
          merge: replaceIfDefined,
        },
        accentColor: {
          merge: replaceIfDefined,
        },
        logoUrl: {
          merge: replaceIfDefined,
        },
        config: {
          merge: replaceIfValue,
        },
        createdTime: {
          merge: mergeIfValue,
        },
        updatedTime: {
          merge: mergeIfValue,
        },
      },
    },
    Query: {
      fields: {
        channels: {
          merge: mergeArrayWithDelete(true),
        },
        workspaceChannels: relayStylePagination(),
        channelDocActivity: relayStylePagination(['channelId']),
        docs: relayStylePagination([
          'workspaceId',
          'archived',
          'sortBy',
          'createdBy',
          'isPrivate',
          'channelId',
        ]),
        docCards: {
          merge: mergeArrayWithDelete(true),
        },
        snapshots: relayStylePagination(['docId']),
        themes: {
          merge: mergeArrayWithDelete(true),
        },
        workspaceMembers: relayStylePagination(),
      },
    },
  },
}
