import tinycolor from 'tinycolor2'

import {
  degreesToRadians,
  getAngleRad,
  getDistance,
  radiansToDegrees,
} from 'utils/math'

import { CSSGradient, VibeKey } from './types'
import { VibeOptions } from './vibes'

// Calculate pixel position given a color
export function colorToXYPosition(wheelElt: HTMLElement, color) {
  const w = wheelElt.offsetWidth
  const tColor = tinycolor(color)
  const a = degreesToRadians(tColor.toHsl().h)
  const l = tColor.toHsl().l
  const d = l * -w + w
  const x = w / 2 - d * Math.cos(a)
  const y = w / 2 - d * Math.sin(a)
  return { x: x, y: y }
}

export function XYPositionToColor(ref, x, y) {
  const w = ref.current.offsetWidth
  const h = ref.current.offsetHeight
  const cX = w / 2
  const cY = h / 2
  const distanceFromCenter = getDistance(x, y, cX, cY, w / 2)
  const angleFromCenter = getAngleRad(x, y, cX, cY)
  const aDeg = radiansToDegrees(angleFromCenter)

  const hue = aDeg + Math.ceil(-aDeg / 360) * 360 + 1
  const lightness = Math.abs(-distanceFromCenter / w + 1)

  return tinycolor.fromRatio({
    h: hue,
    s: 1,
    l: lightness,
  })
}

// Given a set of colors, generate a CSS radial gradient
export const generateGradient = (colors: tinycolor.Instance[]): CSSGradient => {
  let bgImage = ''
  const radials: string[] = []

  colors.forEach((baseColor, i) => {
    const color = baseColor.setAlpha(1)
    // Generate points based on the color. Should feel random but also move continuously
    const seed = color.toHsl().h * i
    const x = 50 * (((seed * 2) % 360) / 360)
    const y = 50 * (((seed * 3) % 360) / 360)
    const s = 50 * (((seed * 1) % 360) / 360) + 50
    const radial = `radial-gradient(circle at ${x}% ${y}%, ${color
      .setAlpha(0.5)
      .toHex8String()} 0, ${color.setAlpha(0).toHex8String()} ${s}%)`
    radials.push(radial)
  })

  const baseColor = colors[0].setAlpha(0.5).toHex8String()
  radials.push(`linear-gradient(0deg, ${baseColor} 0%, ${baseColor} 100%)`)

  bgImage = radials.join(',')
  return {
    backgroundImage: bgImage,
  }
}

// Given a vibe, generate a set of colors to match it
export const generateColors = (vibeKey: VibeKey, color: tinycolor.Instance) => {
  const vibe = VibeOptions[vibeKey]
  const colors: tinycolor.Instance[] = []

  // Calculate colors according to the vibe's `spin` setting
  vibe.spin.forEach((spin) => {
    const c = tinycolor(color.toHsv()).spin(spin)
    colors.push(c)
  })

  // Lighten the colors according to the vibe's `lighten` setting
  vibe.lighten.forEach((lighten, i) => {
    colors[i].lighten(lighten)
  })

  return colors
}

export const generateColorsAndGradient = (
  vibeKey: VibeKey,
  color: tinycolor.Instance
) => {
  const colors = generateColors(vibeKey, color)
  const gradient = generateGradient(colors)
  return { colors, gradient }
}

export const updatePointers = (
  colors: tinycolor.Instance[],
  pointerEls: (HTMLElement | null)[],
  wheelElt: HTMLElement | null
) => {
  // Reset pointers to be hidden, then we'll show just the ones we need
  pointerEls.forEach((dom) => {
    if (!dom) return
    dom.style.opacity = '0'
  })

  // Position the pointers on the color wheel
  colors.forEach((color, i) => {
    const pointerElt = pointerEls[i]
    if (!pointerElt || !wheelElt) return
    const pos = colorToXYPosition(wheelElt, color)
    pointerElt.style.transform = `translate(calc(${pos.x}px - 50%),calc(${pos.y}px - 50%))`
    pointerElt.style.backgroundColor = color.toHexString()
    pointerElt.style.opacity = '1'
  })
}
