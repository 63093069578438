// These are Union Types (instead of enums)
// Read more here:
//   https://stackoverflow.com/a/60041791
//   https://blog.bam.tech/developer-news/should-you-use-enums-or-union-types-in-typescript
export const DocEditorType = {
  COLLABORATIVE: 'collaborative',
  EXAMPLE_STATIC: 'example-static',
  PUBLIC_STATIC: 'public-static',
} as const
export type DocEditorTypeType = typeof DocEditorType[keyof typeof DocEditorType]

// TODO: Come up with a better word for "static". By "static" here, we mean that the editor
// doesn't connect with hocuspocus, and even solo editing isn't possible.
// Other possibilities:
// - uneditable
// - non-collaborative
