import { Extension } from '@tiptap/core'

import { ExtensionPriorityMap } from '../constants'

export const UndoInputRuleKeymap = Extension.create({
  name: 'UndoInputRuleKeymap',
  priority: ExtensionPriorityMap.UndoInputRuleKeymap,
  addKeyboardShortcuts() {
    return {
      // Override Cmd-a to select all content in a card
      // Enable selecting all document text with shift-mod-a
      'Shift-Mod-a': ({ editor }) => editor.commands.selectAll(),

      // Prioritize undoing input rules for these 3 keyboard inputs.
      // Note that undoInputRule handles checking if there is anything undoable:
      // https://github.com/ueberdosis/tiptap/blob/723b955cecc5c92c8aad897ce16c60fb62976571/packages/core/src/commands/undoInputRule.ts
      'Mod-z': ({ editor }) => editor.commands.undoInputRule(),
      Backspace: ({ editor }) => editor.commands.undoInputRule(),
      'Mod-Backspace': ({ editor }) => editor.commands.undoInputRule(),
    }
  },
})
