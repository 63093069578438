import { Flex, HStack, Text } from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Mark, Node } from 'prosemirror-model'

import { UploadStatus } from 'modules/media'

import {
  MediaPlaceholderImage,
  MediaPlaceholderSpinner,
} from '../Placeholder/MediaPlaceholderImage'
import { MediaEmbedAttrs } from '../types'

function getDurationLabel(durationSeconds) {
  const duration = moment.duration(durationSeconds, 'seconds')
  const h = duration.hours().toString().padStart(2, '0'),
    m = duration.minutes().toString().padStart(2, '0'),
    s = duration.seconds().toString().padStart(2, '0')

  if (duration >= moment.duration(1, 'hour')) return h + ':' + m + ':' + s
  else return m + ':' + s
}

type EmbedThumbnailProps = {
  node: Node | Mark
}

export const EmbedThumbnail = ({ node }: EmbedThumbnailProps) => {
  const { thumbnail, meta, source } = node.attrs as MediaEmbedAttrs
  const thumbnailUrl = thumbnail?.src || thumbnail?.tempUrl
  const isPDF = source === 'embed.pdf'

  return thumbnail?.showPlaceholder ? (
    <MediaPlaceholderImage
      hasUploadError={thumbnail.uploadStatus === UploadStatus.Error}
      isLoading={thumbnail.uploadStatus === UploadStatus.Uploading}
      width="100%"
      borderRadius="none"
      icon={isPDF ? regular('file-pdf') : regular('browser')}
    />
  ) : thumbnailUrl ? (
    <Flex
      flex={1}
      bgSize="cover"
      bgPosition={isPDF ? 'top' : 'center'}
      bgImage={`url(${thumbnailUrl})`}
      bgColor="white"
      color="white"
      position="relative"
    >
      {thumbnail?.uploadStatus === UploadStatus.Uploading && (
        <MediaPlaceholderSpinner />
      )}
      {meta?.duration && (
        <HStack
          fontSize="md"
          backgroundColor="blackAlpha.700"
          color="white"
          position="absolute"
          right={0}
          bottom={0}
          py={1}
          px={2}
        >
          <FontAwesomeIcon icon={solid('play')} />
          <Text fontSize="sm">{getDurationLabel(meta.duration)}</Text>
        </HStack>
      )}
    </Flex>
  ) : null
}
